/**
 * @generated SignedSource<<c7b9e0984178a29510ef9eef18940e7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type tags_AcademiesRooTagsConnectionFragment$data = {
  readonly AcademiesTag: {
    readonly AcademiesTags: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"tags_AcademiesRootTagFragment">;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "tags_AcademiesRooTagsConnectionFragment";
};
export type tags_AcademiesRooTagsConnectionFragment$key = {
  readonly " $data"?: tags_AcademiesRooTagsConnectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"tags_AcademiesRooTagsConnectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClickable",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTopic",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./tagsRefetchQuery.graphql')
    }
  },
  "name": "tags_AcademiesRooTagsConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AcademiesTagQueries",
      "kind": "LinkedField",
      "name": "AcademiesTag",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "before",
              "variableName": "before"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            },
            {
              "kind": "Variable",
              "name": "last",
              "variableName": "last"
            }
          ],
          "concreteType": "AcademiesRootTagsConnection",
          "kind": "LinkedField",
          "name": "AcademiesTags",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AcademiesRootTagsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AcademiesRootTag",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "tags_AcademiesRootTagFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canBeDeleted",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AcademiesTagData",
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AcademiesChildTagsConnection",
                          "kind": "LinkedField",
                          "name": "children",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AcademiesChildTagsEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AcademiesChildTag",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "AcademiesTagData",
                                      "kind": "LinkedField",
                                      "name": "data",
                                      "plural": false,
                                      "selections": [
                                        (v4/*: any*/),
                                        (v3/*: any*/),
                                        (v2/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "subId",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "4692290d1b0bea689a841743d5519c57";

export default node;
