import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as GraphLeft } from "../../images/graph-left.svg";
import { ReactComponent as GraphRight } from "../../images/graph-right.svg";

export const Wrapper = styled.div`
	${tw`
    flex 
    md:justify-center 
    overflow-y-auto 
    w-full
  `}

	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;

export const InnerWrapper = tw.div`
  relative 
  flex 
  flex-col 
  gap-[1.25rem] 
  xl:gap-[3.75rem] 
  min-w-[40.625rem] 
  md:min-w-full 
  xl:w-full 
  xl:min-w-[auto] 
  xl:max-w-[74.5rem]
`;

export const TopBoxes = tw.div`
  flex 
  justify-center 
`;

export const Box = tw.div`
  flex 
  flex-col 
  gap-20 
  items-center 
  flex-1 
  h-[15rem]
`;

export const BoxContent = tw.div`
  flex 
  flex-col 
  text-center 
  gap-8
`;

export const GraphLeftWrapper = styled(GraphLeft)`
	${tw`
    absolute 
    top-[58%] 
    md:top-[57.5%] 
    lg:top-[54%]
    xl:top-[57%] 
    left-[5%] 
    md:left-[6%] 
    lg:left-[12%] 
    xl:left-[16%] 
    w-1/3 
    h-[7.8125rem] 
    object-cover 
    -translate-y-1/2
  `}
`;

export const GraphRightWrapper = styled(GraphRight)`
	${tw`
    absolute 
    top-[58%]
    md:top-[57.5%]
    lg:top-[54%]
    xl:top-[57%] 
    right-[5%]
    md:right-[6%]
    lg:right-[12%] 
    xl:right-[16%] 
    w-1/3 
    h-[7.8125rem] 
    object-cover 
    -translate-y-1/2
  `}
`;
