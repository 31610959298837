import { createContext } from "react";

type AccountContext = {
	membersConnectionIds: string[];
	addMembersConnectionId: (id: string) => void;
	groupsConnectionIds: string[];
	addGroupsConnectionId: (id: string) => void;
};

export const AccountContext = createContext<AccountContext>({
	membersConnectionIds: [],
	addMembersConnectionId: () => {},
	groupsConnectionIds: [],
	addGroupsConnectionId: () => {},
});
