import { InputMask } from "primereact/inputmask";

import styled, { css } from "styled-components";
import tw from "twin.macro";
import { shade20, shade5, error100, shade40, shade100, info100 } from "@themes/colors";
import { TkaP3Label, TkaP2Typography, TkaL1Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	relative
`;

export const StyledLabel = tw(TkaP3Label)`
	block
	mb-4
`;

export const GrowDiv = styled.div`
	${tw`
		flex 
		w-full
	`}
	&.p-input-icon-left > i {
		margin-top: -0.75rem;
	}
	&.p-input-icon-left > .p-inputtext {
		padding-left: 2.5rem;
	}
`;

const InputCSS = css<{ error: boolean }>`
	${tw`w-full`}
	${TkaP2Typography}
	color: ${shade100};
	border-color: ${(props) => (props.error ? error100 : shade40)};
	border-radius: 0.25rem;

	&:disabled {
		background-color: ${shade5};
		color: ${shade20};
		border-style: none;
	}

	&:enabled:hover {
		border-color: ${(props) => (props.error ? error100 : shade20)};
		color: ${shade100};
	}

	&:enabled:focus {
		border-color: ${(props) => (props.error ? error100 : info100)};
		box-shadow: none;
	}

	::-webkit-input-placeholder {
		color: ${shade20};
	}

	:-moz-placeholder {
		color: ${shade20};
	}

	::-moz-placeholder {
		color: ${shade20};
	}

	:-ms-input-placeholder {
		color: ${shade20};
	}
`;

export const StyledInputMask = styled(InputMask)<{
	error: boolean;
}>`
	${InputCSS}
`;

export const SmallTextBelow = styled(TkaL1Span)`
	display: block;
	margin-top: 0.25rem;
`;

export const SuggestionsWrapper = styled.div<{ width: number }>`
	${tw`
		flex
		flex-col
		overflow-hidden
		mt-4
	`}
	width: ${({ width }) => width}px;
`;
