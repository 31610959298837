import { ChipsChangeEvent } from "primereact/chips";
import { HTMLProps } from "react";
import { brandMain100, error100, shade10, shade100, shade60 } from "@themes/colors";
import { TkaL1Span } from "@themes/font-tags";
import { ChipsWrapper, CloseChipWrapper, GrowDiv, StyledChips } from "./input-chips.styles";
import { InputChipsProps } from "./input-chips.types";
import { Icon } from "../icon";
import { SmallTextBelow, StyledLabel } from "../input-text/input-text.styles";

export const InputChips: React.FC<InputChipsProps> = ({
	label,
	name,
	icon,
	iconSizeInRem = 1.5,
	value,
	placeholder,
	allowDuplicates,
	addOnBlur,
	onChange,
	smallTextBelow,
	hasError,
}) => {
	const handleOnChange = (event: ChipsChangeEvent) => {
		onChange?.(event.value ?? []);
	};

	const iconColor = value?.length ? shade100 : shade60;
	return (
		<ChipsWrapper>
			<StyledLabel>{label}</StyledLabel>
			<GrowDiv className="p-input-icon-left">
				<i>
					<Icon tkaColor={iconColor} icon={icon} sizeInRem={iconSizeInRem} />
				</i>
				<StyledChips
					value={value || []}
					name={name}
					onChange={handleOnChange}
					placeholder={placeholder}
					itemTemplate={(label) => <TkaL1Span>{label}</TkaL1Span>}
					removeIcon={(props) => (
						<CloseChipWrapper {...(props.iconProps as HTMLProps<HTMLDivElement>)}>
							<Icon icon="close" tkaColor={shade10} sizeInRem={0.5} />
						</CloseChipWrapper>
					)}
					allowDuplicate={allowDuplicates}
					addOnBlur={addOnBlur}
				/>
			</GrowDiv>
			{smallTextBelow && (
				<SmallTextBelow tkaColor={hasError ? error100 : brandMain100}>
					{smallTextBelow}
				</SmallTextBelow>
			)}
		</ChipsWrapper>
	);
};
