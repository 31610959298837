import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { withSuspense } from "@components/with-suspense/with-suspense.component";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { IOffersScreenContext, OffersScreenContext } from "@screens/offers/offers.context";
import {
	Header,
	NoGrowWrapper,
	SearchAndFiltersWrapper,
	SearchBarWrapper,
} from "@screens/offers/offers.styles";
import { Filters } from "@screens/offers/parts/filters";
import { GridView } from "@screens/offers/parts/grid-view";
import { SearchBar } from "@screens/offers/parts/search-bar";
import { SearchBarMobile } from "@screens/offers/parts/search-bar/search-bar-mobile";
import { H1Span } from "@themes/font-tags";
import { OffersScreenSkeleton } from "./offers.skeleton";
import { OffersLocationState } from "./offers.types";

const OffersScreenComponent = () => {
	const { isMediumUp } = useWindowSize();
	const { state: locationState }: OffersLocationState = useLocation();
	const [state, setState] = useState<Omit<IOffersScreenContext, "helpers">>({
		filters: {
			tagIds: [],
			kinds: [],
			text: locationState?.search,
			...locationState?.filters,
		},
		productIds: [],
		activeIndex: 0,
		filtersAreVisible: false,
		searchIsVisibleOnMobile: false,
	});

	useEffect(() => {
		setState((previousState) => ({
			...previousState,
			filters: {
				...previousState.filters,
				kinds: locationState?.filters?.kinds ?? previousState.filters.kinds,
				text: locationState?.search ?? previousState.filters.text,
			},
		}));
	}, [locationState]);

	const helpers: Pick<IOffersScreenContext, "helpers"> = {
		helpers: {
			setText: (text) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, text },
				}));
			},
			setKinds: (kinds) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, kinds },
				}));
			},
			setTagIds: (tagIds) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, tagIds },
				}));
			},
			setProductIds: (productIds) => {
				setState((state) => ({
					...state,
					productIds: productIds,
				}));
			},
			setActiveIndex: (activeIndex) => {
				setState((state) => ({ ...state, activeIndex }));
			},
			toggleKind: (kind) => {
				const {
					filters: { kinds },
				} = { ...state };
				if (kinds.includes(kind)) {
					const newKinds = [...kinds].filter((k) => k !== kind);
					setState({ ...state, filters: { ...state.filters, kinds: newKinds } });
				} else {
					setState({
						...state,
						filters: { ...state.filters, kinds: [...state.filters.kinds, kind] },
					});
				}
			},
			toggleFiltersAreVisible: () => {
				setState((state) => ({ ...state, filtersAreVisible: !state.filtersAreVisible }));
			},
			toggleSearchIsVisibleOnMobile: () => {
				setState((state) => ({
					...state,
					searchIsVisibleOnMobile: !state.searchIsVisibleOnMobile,
				}));
			},
		},
	};
	const contextValue: IOffersScreenContext = { ...state, helpers: helpers.helpers };

	const handleToggleOnClick = () => {
		if (isMediumUp) return;
		helpers.helpers.toggleSearchIsVisibleOnMobile();
	};

	useEffect(() => {
		if (!isMediumUp) return;
		if (state.searchIsVisibleOnMobile) {
			helpers.helpers.toggleSearchIsVisibleOnMobile();
		}
	}, [isMediumUp, state.searchIsVisibleOnMobile]);

	return (
		<OffersScreenContext.Provider value={contextValue}>
			<ScreenWithNavbarLayout>
				<Header>
					<H1Span>Weiterentwicklung</H1Span>
					<SearchAndFiltersWrapper>
						<SearchBar onClick={handleToggleOnClick} />
						<NoGrowWrapper>
							<Filters />
						</NoGrowWrapper>
					</SearchAndFiltersWrapper>
				</Header>
				<SearchBarWrapper>
					{state.searchIsVisibleOnMobile && <SearchBarMobile />}
				</SearchBarWrapper>
				<GridView />
			</ScreenWithNavbarLayout>
		</OffersScreenContext.Provider>
	);
};

export const OffersScreen = withSuspense(OffersScreenComponent, OffersScreenSkeleton);
