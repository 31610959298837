import { forwardRef, useContext, useImperativeHandle, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { useWindowSize } from "@hooks/use-window-size";
import { InviteMemberModal } from "@screens/account/parts/members-tab/parts/invite-member-modal";
import { error100, shade80 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span } from "@themes/font-tags";

import { spacing0 } from "@themes/spacing";
import {
	QUERY_FRAGMENT,
	USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT,
} from "./group-context-menu.graphql";
import { ItemWrapper, TitleWrapper, OptionsWrapper, Wrapper } from "./group-context-menu.styles";
import { GroupContextMenuProps, GroupContextMenuRef } from "./group-context-menu.types";
import { GroupsTabContext } from "../../groups-tab.context";
import { EditGroupModal } from "../edit-group-modal";

export const GroupContextMenu = forwardRef<GroupContextMenuRef, GroupContextMenuProps>(
	function GroupContextMenu({ userInAccountGroupWrapperFragmentRef, queryFragmentRef }, ref) {
		const groupWrapper = useFragment(
			USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT,
			userInAccountGroupWrapperFragmentRef ?? null,
		);

		const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

		const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

		const [isVisibleEditGroupModal, setIsVisibleEditGroupModal] = useState(false);
		const [isVisibleInviteMemberModal, setIsVisibleInviteMemberModal] = useState(false);

		const { isMediumUp } = useWindowSize();

		const { deleteGroupIds } = useContext(GroupsTabContext);

		useImperativeHandle(ref, () => ({
			toggle: (event, target) => {
				overlayRef?.current?.toggle(event, target);
			},
		}));

		const groupName = groupWrapper?.group.name ?? "";
		const groupId = groupWrapper?.group.id ?? "";
		const canEditGroup = groupWrapper?.group.isBuiltIn === false;
		const canRemoveGroup = groupWrapper?.group.isBuiltIn === false;

		const handleAddUserClick = () => {
			overlayRef?.current?.hide();
			setIsVisibleInviteMemberModal(true);
		};

		const handleEditGroupClick = () => {
			overlayRef?.current?.hide();
			setIsVisibleEditGroupModal(true);
		};

		const handleDeleteGroupClick = () => {
			overlayRef?.current?.hide();
			const groupId = groupWrapper?.group.id;
			groupId && deleteGroupIds([groupId]);
		};

		const handleCloseOnClick = () => {
			overlayRef?.current?.hide();
		};

		const handleOnDismissEditGroupModal = () => {
			setIsVisibleEditGroupModal(false);
		};

		const handleOnDismissInviteMemberModal = () => {
			setIsVisibleInviteMemberModal(false);
		};

		const iconSizeRem = isMediumUp ? 1.5 : 1;
		const ItemLabelSpan = isMediumUp ? TkaP2Span : TkaP1Span;
		return (
			<>
				<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing0}>
					<Wrapper>
						{!isMediumUp && (
							<TitleWrapper>
								<H1Span>Aktion wählen</H1Span>
								<TkaP2Span tkaColor={shade80}>{groupName}</TkaP2Span>
							</TitleWrapper>
						)}
						<OptionsWrapper>
							<ItemWrapper onClick={handleAddUserClick}>
								<Icon icon={"add"} sizeInRem={iconSizeRem} />
								<ItemLabelSpan>Benutzer hinzufügen</ItemLabelSpan>
							</ItemWrapper>
							{!isMediumUp && canEditGroup && <Divider />}
							{canEditGroup && (
								<ItemWrapper onClick={handleEditGroupClick}>
									<Icon icon={"pencil"} sizeInRem={iconSizeRem} />
									<ItemLabelSpan>Gruppe bearbeiten</ItemLabelSpan>
								</ItemWrapper>
							)}
							{!isMediumUp && canRemoveGroup && <Divider />}
							{canRemoveGroup && (
								<ItemWrapper onClick={handleDeleteGroupClick}>
									<Icon
										tkaColor={error100}
										icon={"trash"}
										sizeInRem={iconSizeRem}
									/>
									<ItemLabelSpan tkaColor={error100}>
										Gruppe löschen
									</ItemLabelSpan>
								</ItemWrapper>
							)}
						</OptionsWrapper>
						{!isMediumUp && (
							<Button label="Schließen" fillParent onClick={handleCloseOnClick} />
						)}
					</Wrapper>
				</ResponsiveBottomSheetOverlayPanel>
				<EditGroupModal
					onDismiss={handleOnDismissEditGroupModal}
					isVisible={isVisibleEditGroupModal}
					userInAccountGroupFragmentRef={groupWrapper?.group}
				/>
				<InviteMemberModal
					onDismiss={handleOnDismissInviteMemberModal}
					isVisible={isVisibleInviteMemberModal}
					tabView={false}
					selectedGroups={[{ id: groupId, name: groupName }]}
					queryFragmentRef={queryFragment}
				/>
			</>
		);
	},
);
