import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment appointmentPresence_AvailabilityFragment on Availability {
		id
		data {
			startDateTime
			endDateTime
		}
		booking {
			... on BookedForGroup {
				participants {
					user {
						id
					}
					participationStatus
					...participantPresenceCheck_ParticipantFragment
				}
			}
			... on BookedOneToOne {
				participant {
					user {
						id
					}
					participationStatus
					...participantPresenceCheck_ParticipantFragment
				}
			}
		}
	}
`;
