/**
 * @generated SignedSource<<3e42b694fb25761a8c08871153b61fed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountTabRefetchQuery$variables = {};
export type accountTabRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"accountTab_QueryFragment">;
};
export type accountTabRefetchQuery = {
  response: accountTabRefetchQuery$data;
  variables: accountTabRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountTabRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accountTab_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountTabRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataQueries",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isbaseData"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "houseNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryCode",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billingOffice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyLegalForm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "invoiceEmail",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountData",
                    "kind": "LinkedField",
                    "name": "accountData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iban",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxData",
                    "kind": "LinkedField",
                    "name": "taxData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taxIdentificationNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taxNumber",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "BusinessBaseData",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salutation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  }
                ],
                "type": "PrivateBaseData",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountGroupAssociation",
                        "kind": "LinkedField",
                        "name": "groupAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountGroup",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e2d9c81f888d32ad6be96a5bb1b0577",
    "id": null,
    "metadata": {},
    "name": "accountTabRefetchQuery",
    "operationKind": "query",
    "text": "query accountTabRefetchQuery {\n  ...accountTab_QueryFragment\n}\n\nfragment accountContextMenu_AuthViewerSchemaFragment on AuthViewerSchema {\n  currentUser {\n    accounts {\n      name\n      id\n    }\n  }\n  ...deleteAccountModalsFlow_AuthViewerSchemaFragment\n  ...leaveAccountModal_authViewerSchemaFragmentRef\n}\n\nfragment accountTab_QueryFragment on Query {\n  AccountBaseData {\n    AccountBaseData {\n      __typename\n      ...businessAccountForm_BaseDataFragment\n      ...privateAccountForm_BaseDataFragment\n    }\n  }\n  Viewer {\n    Auth {\n      ...accountContextMenu_AuthViewerSchemaFragment\n      currentUser {\n        accounts {\n          groupAssociations {\n            group {\n              name\n              id\n            }\n          }\n          name\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment businessAccountForm_BaseDataFragment on baseData {\n  __isbaseData: __typename\n  street\n  houseNumber\n  postalCode\n  city\n  countryCode\n  __typename\n  ... on BusinessBaseData {\n    companyName\n    billingOffice\n    companyLegalForm\n    invoiceEmail\n    phoneNumber\n    accountData {\n      bic\n      iban\n    }\n    taxData {\n      taxIdentificationNumber\n      taxNumber\n    }\n  }\n}\n\nfragment deleteAccountModalsFlow_AuthViewerSchemaFragment on AuthViewerSchema {\n  currentUser {\n    accounts {\n      id\n      name\n    }\n  }\n}\n\nfragment leaveAccountModal_authViewerSchemaFragmentRef on AuthViewerSchema {\n  currentUser {\n    accounts {\n      id\n      name\n    }\n  }\n}\n\nfragment privateAccountForm_BaseDataFragment on baseData {\n  __isbaseData: __typename\n  street\n  houseNumber\n  postalCode\n  city\n  countryCode\n  __typename\n  ... on PrivateBaseData {\n    phoneNumber\n    firstName\n    lastName\n    salutation\n    title\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad9a76848134465e051277a8def6b259";

export default node;
