import React from "react";
import { useFragment } from "react-relay";
import { createSearchParams, useNavigate } from "react-router-dom";
import { CoachingOfferCard } from "@components/coaching-offer-card";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { myCoachings_QueryFragment$key } from "@relay/myCoachings_QueryFragment.graphql";
import { Path } from "@router/paths";
import { COACH_PROFILE_ID_KEY } from "@screens/book-appointment/book-appointment.consts";
import { QUERY_FRAGMENT } from "@screens/coach/parts/my-coachings/my-coachings.graphql";
import {
	CoachingCardWrapper,
	Gap12,
	Grid,
} from "@screens/coach/parts/my-coachings/my-coachings.styles";
import { shade100 } from "@themes/colors";
import { TkaH2Span } from "@themes/font-tags";
import { MyCoachingsProps } from "./my-coachings.types";

export const MyCoachings = ({ coachProfileId, queryFragmentRef }: MyCoachingsProps) => {
	const { t } = useTkaTranslation(["coach"]);
	const queryFragment = useFragment<myCoachings_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const navigate = useNavigate();

	const coachingFragments = queryFragment.Coaching.CoachingOffersToCoach.edges!.map(
		(e) => e!.node,
	);

	const createOnClickHandler = (offerId: string) => () => {
		navigate({
			pathname: Path.coaching.withId(offerId).path,
			search: createSearchParams({ [COACH_PROFILE_ID_KEY]: coachProfileId }).toString(),
		});
	};

	return (
		<div>
			<TkaH2Span tkaColor={shade100}>{t("Meine Coachings")}</TkaH2Span>
			<Gap12 />
			<Grid>
				{coachingFragments.map((fragment) => (
					<CoachingCardWrapper key={fragment.id}>
						<CoachingOfferCard
							coachingOfferFragmentRef={fragment}
							onClick={createOnClickHandler(fragment.id)}
						/>
					</CoachingCardWrapper>
				))}
			</Grid>
		</div>
	);
};
