import { FC } from "react";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { Wrapper } from "@screens/account/parts/account-tab/parts/delete-account-modals-flow/parts/delete-account-modal/delete-account-modal.styles";
import { DeleteExceptionModalProps } from "@screens/my-availabilities/parts/delete-exception-modal/delete-exception-modal.types";
import { H1Span, TkaP2Span } from "@themes/font-tags";

export const DeleteExceptionModal: FC<DeleteExceptionModalProps> = ({
	isVisible,
	onDismiss,
	onNext,
	calendarWeek,
}) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				secondaryButtonStretch={false}
				primaryButtonLabel={`${calendarWeek} löschen`}
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={onNext}
				primaryButtonStretch={true}
			>
				<Wrapper>
					<H1Span>Willst du die “{calendarWeek}” wirklich löschen?</H1Span>
					<TkaP2Span>
						Nach Bestätigung wird die "{calendarWeek}" und die damit zusammengehörenden
						Informationen gelöscht. Diese können dann auch nicht wiederhergestellt
						werden.
					</TkaP2Span>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
