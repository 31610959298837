/**
 * @generated SignedSource<<e10c5de9fc58a1258f1122078d878c75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type orientation_Query$variables = {};
export type orientation_Query$data = {
  readonly Viewer: {
    readonly PotentialAnalysis: {
      readonly MyPotentialAnalysisResult: {
        readonly categoryResults: ReadonlyArray<{
          readonly benchmark: number | null;
          readonly name: string;
          readonly result: number | null;
        }>;
        readonly isLongAnalysis: boolean;
        readonly name: string;
        readonly pdf: {
          readonly uploadDateTime: string;
          readonly url: string | null;
        } | null;
      } | null;
    };
  };
};
export type orientation_Query = {
  response: orientation_Query$data;
  variables: orientation_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "CategoryResult",
  "kind": "LinkedField",
  "name": "categoryResults",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "result",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "benchmark",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLongAnalysis",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploadDateTime",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "orientation_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PotentialAnalysisViewerSchema",
            "kind": "LinkedField",
            "name": "PotentialAnalysis",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PotentialAnalysisResult",
                "kind": "LinkedField",
                "name": "MyPotentialAnalysisResult",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "pdf",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "orientation_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PotentialAnalysisViewerSchema",
            "kind": "LinkedField",
            "name": "PotentialAnalysis",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PotentialAnalysisResult",
                "kind": "LinkedField",
                "name": "MyPotentialAnalysisResult",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "pdf",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16555ff7846b0fc8b0ac48d18652bbb5",
    "id": null,
    "metadata": {},
    "name": "orientation_Query",
    "operationKind": "query",
    "text": "query orientation_Query {\n  Viewer {\n    PotentialAnalysis {\n      MyPotentialAnalysisResult {\n        name\n        categoryResults {\n          name\n          result\n          benchmark\n        }\n        isLongAnalysis\n        pdf {\n          url\n          uploadDateTime\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f7ed9df1326ea24a9b69443190ed291";

export default node;
