import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment appointmentRating_AvailabilityFragment on Availability {
		id
		data {
			startDateTime
			endDateTime
		}
		coachProfile {
			coach {
				...participantPreview_UserFragment
			}
		}
		feedback {
			data {
				comment
				score
			}
		}
	}
`;

export const LEAVE_FEEDBACK_MUTATION = graphql`
	mutation appointmentRating_LeaveFeedbackMutation($input: LeaveFeedbackInput!) {
		Coaching {
			leaveFeedback(input: $input) {
				availability {
					...appointmentOverviewModal_AvailabilityFragment
				}
			}
		}
	}
`;
