import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment mostRecentLearnableCard_mostRecentLearnableFragment on LearnableWithLastUpdated {
		lastUpdated
		learnable {
			id
			... on PublishedCourseLearnable {
				id
				kind
				root {
					id
					image {
						url
					}
					structureDefinition {
						title
						... on LearnOpportunityRootStructureDefinition {
							progressPercentage
							extension {
								... on PublishedRootExtensionImpl {
									diploma
								}
							}
						}
					}
					nextContentNodeId
				}
			}
			... on PublishedMicroLearningLearnable {
				id
				kind
				root {
					id
					image {
						url
					}
					structureDefinition {
						title
						... on LearnOpportunityRootStructureDefinition {
							progressPercentage
							extension {
								... on PublishedRootExtensionImpl {
									diploma
								}
							}
						}
					}
					nextContentNodeId
				}
			}
			... on PublishedOneToOneCoachingLearnable {
				id
				kind
			}
		}
	}
`;
