/**
 * @generated SignedSource<<369a5145451cb60f3c68320bbae92b72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dashboard_points$data = {
  readonly sumGamificationPoints: number;
  readonly " $fragmentType": "dashboard_points";
};
export type dashboard_points$key = {
  readonly " $data"?: dashboard_points$data;
  readonly " $fragmentSpreads": FragmentRefs<"dashboard_points">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "dashboard_points",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sumGamificationPoints",
      "storageKey": null
    }
  ],
  "type": "AcademiesUserExtension",
  "abstractKey": null
};

(node as any).hash = "2ae4eb19a5c0ac6a05efbaa497c54386";

export default node;
