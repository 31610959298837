import { FC } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { CircularProgressbar } from "@components/circular-progressbar";
import { Icon } from "@components/icon";
import { mostRecentLearnableCard_mostRecentLearnableFragment$key } from "@relay/mostRecentLearnableCard_mostRecentLearnableFragment.graphql";
import { Path } from "@router/paths";
import { brandMain80 } from "@themes/colors";
import { TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { formatToTimeAgo } from "@utils/date-utils";
import { QUERY_FRAGMENT } from "./most-recent-learnable-card.graphql";
import {
	ButtonWrapper,
	CardDescription,
	CardHeader,
	CardWrapper,
	CertificateWrapper,
	ProgressWrapper,
	StyledTkaH3Span,
} from "./most-recent-learnable-card.styles";
import { MostRecentLearnableCardProps } from "./most-recent-learnable-card.types";

export const MostRecentLearnableCard: FC<MostRecentLearnableCardProps> = ({ queryFragmentRef }) => {
	const navigate = useNavigate();
	const mostRecentLearnable =
		useFragment<mostRecentLearnableCard_mostRecentLearnableFragment$key>(
			QUERY_FRAGMENT,
			queryFragmentRef ?? null,
		);

	if (!mostRecentLearnable?.learnable.root?.id) return null;
	const title = mostRecentLearnable?.learnable.root?.structureDefinition.title;
	const progress =
		mostRecentLearnable?.learnable.root?.structureDefinition?.progressPercentage || 0;
	const diploma = mostRecentLearnable?.learnable.root?.structureDefinition.extension?.diploma;
	const img = mostRecentLearnable?.learnable.root?.image?.url;
	const lastUpdated = formatToTimeAgo(mostRecentLearnable?.lastUpdated);
	const rootId = mostRecentLearnable?.learnable.root?.id;
	const nextContentNodeId = mostRecentLearnable?.learnable.root?.nextContentNodeId;

	const handleButtonOnClick = () => {
		nextContentNodeId &&
			navigate(Path.root.withId(rootId).branchNodes.withId(nextContentNodeId).path);
	};

	return (
		<CardWrapper img={img ?? ""}>
			<CardHeader>
				<CardDescription>
					{lastUpdated && <TkaP3Span>{lastUpdated}</TkaP3Span>}
					<StyledTkaH3Span>{title}</StyledTkaH3Span>
				</CardDescription>
				<ProgressWrapper>
					<TkaP3Span>{`${progress} %`}</TkaP3Span>
					<CircularProgressbar value={progress} />
				</ProgressWrapper>
			</CardHeader>
			{diploma && (
				<CertificateWrapper>
					<Icon icon="certifiedCertificateBold" tkaColor={brandMain80} sizeInRem={1} />
					<TkaP2Span tkaColor={brandMain80}>{diploma}</TkaP2Span>
				</CertificateWrapper>
			)}
			<ButtonWrapper>
				<Button
					label="Fortsetzen"
					iconName="arrowRight"
					fillParent
					onClick={handleButtonOnClick}
				/>
			</ButtonWrapper>
		</CardWrapper>
	);
};
