/**
 * @generated SignedSource<<b6cb790bea3ed9864eefc4df08938f86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GrantPlacementPermissionAndRedirectInput = {
  clientMutationId?: string | null;
};
export type infoModalCorporate_GrantPlacementPermissionAndRedirectMutation$variables = {
  input: GrantPlacementPermissionAndRedirectInput;
};
export type infoModalCorporate_GrantPlacementPermissionAndRedirectMutation$data = {
  readonly Placement: {
    readonly grantPlacementPermissionAndRedirect: {
      readonly placementUrl: string;
    } | null;
  };
};
export type infoModalCorporate_GrantPlacementPermissionAndRedirectMutation = {
  response: infoModalCorporate_GrantPlacementPermissionAndRedirectMutation$data;
  variables: infoModalCorporate_GrantPlacementPermissionAndRedirectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PlacementMutations",
    "kind": "LinkedField",
    "name": "Placement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "GrantPlacementPermissionAndRedirectPayload",
        "kind": "LinkedField",
        "name": "grantPlacementPermissionAndRedirect",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placementUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "infoModalCorporate_GrantPlacementPermissionAndRedirectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "infoModalCorporate_GrantPlacementPermissionAndRedirectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "66b10b0d2781b161796822e7c981d746",
    "id": null,
    "metadata": {},
    "name": "infoModalCorporate_GrantPlacementPermissionAndRedirectMutation",
    "operationKind": "mutation",
    "text": "mutation infoModalCorporate_GrantPlacementPermissionAndRedirectMutation(\n  $input: GrantPlacementPermissionAndRedirectInput!\n) {\n  Placement {\n    grantPlacementPermissionAndRedirect(input: $input) {\n      placementUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0bae4944119b1cb28907ab31ca9fd589";

export default node;
