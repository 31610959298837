import React from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { questionSection_PotentialAnalysisQuestionFragment$key } from "@relay/questionSection_PotentialAnalysisQuestionFragment.graphql";
import {
	QuestionTextActiveClass,
	QuestionTextInActiveClass,
	QuestionWrapperClass,
} from "@screens/avgs-potential-analysis/parts/question-section/question-section.styles";
import { QuestionSectionProps } from "@screens/avgs-potential-analysis/parts/question-section/question-section.types";
import { TextQuestion } from "@screens/avgs-potential-analysis/parts/text-question";
import { P1Span } from "@themes/font-tags";
import { POTENTIAL_ANALYSIS_QUESTION_FRAGMENT } from "./question-section.graphql";
import { isQuestionAnswered } from "../avgs-potential-analysis-navigation/avgs-potential-analysis-navigation.utils";
import { CheckboxQuestion } from "../checkbox-question";
import { ScaleQuestion } from "../scale-question";

export const QuestionSection = ({
	isActive,
	potentialAnalysisQuestionFragmentRef,
}: QuestionSectionProps) => {
	const question = useFragment<questionSection_PotentialAnalysisQuestionFragment$key>(
		POTENTIAL_ANALYSIS_QUESTION_FRAGMENT,
		potentialAnalysisQuestionFragmentRef,
	);

	const { questionText, kind, id } = question;

	const isAnswered = isQuestionAnswered(question);

	const questionTextClassName =
		isActive || isAnswered ? QuestionTextActiveClass : QuestionTextInActiveClass;

	const formComponent = match(kind)
		.with("TEXT", () => (
			<TextQuestion isActive={isActive} potentialAnalysisQuestionFragmentRef={question} />
		))
		.with("MULTIPLE_CHOICE", () => (
			<CheckboxQuestion isActive={isActive} potentialAnalysisQuestionFragmentRef={question} />
		))
		.with("MULTIPLE_CHOICE_FREETEXT", () => (
			<CheckboxQuestion isActive={isActive} potentialAnalysisQuestionFragmentRef={question} />
		))
		.with("SINGLE_CHOICE", () => (
			<CheckboxQuestion
				isActive={isActive}
				potentialAnalysisQuestionFragmentRef={question}
				isRadio
			/>
		))
		.with("SINGLE_CHOICE_FREETEXT", () => (
			<CheckboxQuestion
				isActive={isActive}
				potentialAnalysisQuestionFragmentRef={question}
				isRadio
			/>
		))
		.with("SCALE", () => (
			<ScaleQuestion isActive={isActive} potentialAnalysisQuestionFragmentRef={question} />
		))
		.exhaustive();

	return (
		<div className={QuestionWrapperClass} id={id}>
			<P1Span className={questionTextClassName}>{questionText}</P1Span>
			{formComponent}
		</div>
	);
};
