/**
 * @generated SignedSource<<93624d562fc13866ab9ee199935dbbb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentSubmissionNavigation_Query$variables = {};
export type contentSubmissionNavigation_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly " $fragmentSpreads": FragmentRefs<"profileMenu_AuthViewerSchemaFragment">;
    };
  };
};
export type contentSubmissionNavigation_Query = {
  response: contentSubmissionNavigation_Query$data;
  variables: contentSubmissionNavigation_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "contentSubmissionNavigation_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "profileMenu_AuthViewerSchemaFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "contentSubmissionNavigation_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "userExtensionKind",
                            "value": "Academies"
                          }
                        ],
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "thumbnail",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sumGamificationPoints",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              }
                            ],
                            "type": "AcademiesUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"Academies\")"
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7b23f163f5fbe1dfac887203d9b4be6",
    "id": null,
    "metadata": {},
    "name": "contentSubmissionNavigation_Query",
    "operationKind": "query",
    "text": "query contentSubmissionNavigation_Query {\n  Viewer {\n    Auth {\n      ...profileMenu_AuthViewerSchemaFragment\n    }\n  }\n}\n\nfragment accountsDropdown_CurrentUserFragment on CurrentUser {\n  accounts {\n    id\n    name\n  }\n}\n\nfragment profileMenu_AuthViewerSchemaFragment on AuthViewerSchema {\n  currentUser {\n    user {\n      name\n      extension(userExtensionKind: Academies) {\n        __typename\n        ... on AcademiesUserExtension {\n          avatar {\n            url\n            id\n          }\n          sumGamificationPoints\n        }\n      }\n      ...userAvatar_UserFragment\n      id\n    }\n    accounts {\n      id\n    }\n    ...accountsDropdown_CurrentUserFragment\n  }\n}\n\nfragment userAvatar_UserFragment on User {\n  extension(userExtensionKind: Academies) {\n    __typename\n    ... on AcademiesUserExtension {\n      firstName\n      lastName\n      avatar {\n        thumbnail\n        id\n      }\n      sumGamificationPoints\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e8155ef60f317203083eb8209b80038";

export default node;
