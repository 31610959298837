/**
 * @generated SignedSource<<4230c9134b526de347ab4ab7dd1664d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DiscountActionCalcTypeKind = "euro" | "percentage";
export type ItemType = "CartBulkDiscount" | "CartDiscount" | "CartGlobalDiscount" | "CartLimitedDiscount" | "CartProduct";
export type ProductExtensionKind = "Academies";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartProducts_OrderFragment$data = {
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly amount?: number;
      readonly code?: string;
      readonly itemType: ItemType;
      readonly product?: {
        readonly data: {
          readonly licenseDefinition?: {
            readonly data: {
              readonly coachingInfo?: {
                readonly contractPartner: {
                  readonly data: {
                    readonly linkToAGB: string | null;
                    readonly name: string;
                  };
                } | null;
                readonly image: {
                  readonly url: string | null;
                } | null;
              } | null;
              readonly rootInfo?: {
                readonly contractPartner: {
                  readonly data: {
                    readonly linkToAGB: string | null;
                    readonly name: string;
                  };
                } | null;
                readonly image: {
                  readonly url: string | null;
                } | null;
              } | null;
            };
          } | null;
        };
        readonly extensions: ReadonlyArray<{
          readonly atGrossPrice?: number;
          readonly chGrossPrice?: number;
          readonly deGrossPrice?: number;
          readonly kind: ProductExtensionKind;
        }>;
        readonly id: string;
        readonly isTaxFree: boolean;
        readonly netPrice: number;
        readonly title: string;
      } | null;
      readonly title?: string;
      readonly validUntil?: string | null;
      readonly value?: {
        readonly euro?: number;
        readonly kind: DiscountActionCalcTypeKind;
        readonly percentage?: number;
      };
    }>;
    readonly otherAvailableProducts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly data: {
            readonly licenseDefinition?: {
              readonly data: {
                readonly coachingInfo?: {
                  readonly contractPartner: {
                    readonly data: {
                      readonly linkToAGB: string | null;
                      readonly name: string;
                    };
                  } | null;
                  readonly image: {
                    readonly url: string | null;
                  } | null;
                } | null;
                readonly rootInfo?: {
                  readonly contractPartner: {
                    readonly data: {
                      readonly linkToAGB: string | null;
                      readonly name: string;
                    };
                  } | null;
                  readonly image: {
                    readonly url: string | null;
                  } | null;
                } | null;
              };
            } | null;
          };
          readonly id: string;
          readonly title: string;
        };
      } | null> | null;
    };
    readonly selection: {
      readonly selectedProducts: ReadonlyArray<{
        readonly amount: number;
        readonly product: {
          readonly id: string;
        } | null;
      }>;
    };
    readonly taxTotals: {
      readonly discountTotal: number;
      readonly grossPrice: number;
      readonly hasTaxFreeProducts: boolean;
      readonly netPrice: number;
      readonly taxTotal: number;
    } | null;
    readonly totals: {
      readonly appliedDiscountPercentage: number | null;
      readonly monthlyOptions: ReadonlyArray<{
        readonly monthlyRate: {
          readonly grossPrice: number;
        };
      }>;
    };
  } | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartProducts_OrderFragment";
};
export type shoppingCartProducts_OrderFragment$key = {
  readonly " $data"?: shoppingCartProducts_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartProducts_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "value",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        }
      ],
      "type": "DiscountActionPercentageCalcType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "euro",
          "storageKey": null
        }
      ],
      "type": "DiscountActionEuroCalcType",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  (v6/*: any*/)
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "File",
    "kind": "LinkedField",
    "name": "image",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ContractPartner",
    "kind": "LinkedField",
    "name": "contractPartner",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractPartnerData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkToAGB",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LicenseDefinition",
          "kind": "LinkedField",
          "name": "licenseDefinition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LicenseDefinitionRootInfo",
                      "kind": "LinkedField",
                      "name": "rootInfo",
                      "plural": false,
                      "selections": (v8/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "TreeLicenseDefinitionData",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LicenseDefinitionCoachingInfo",
                      "kind": "LinkedField",
                      "name": "coachingInfo",
                      "plural": false,
                      "selections": (v8/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "CoachingLicenseDefinitionData",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LicenseProductData",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartProducts_OrderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxTotals",
          "kind": "LinkedField",
          "name": "taxTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountTotal",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasTaxFreeProducts",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxTotal",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Selection",
          "kind": "LinkedField",
          "name": "selection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectedProduct",
              "kind": "LinkedField",
              "name": "selectedProducts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemType",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v7/*: any*/),
              "type": "CartLimitedDiscount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "validUntil",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v6/*: any*/)
              ],
              "type": "CartDiscount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v7/*: any*/),
              "type": "CartGlobalDiscount",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isTaxFree",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extensions",
                      "plural": true,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "deGrossPrice",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "atGrossPrice",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "chGrossPrice",
                              "storageKey": null
                            }
                          ],
                          "type": "AcademiesProductExtension",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "CartProduct",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appliedDiscountPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductsConnection",
          "kind": "LinkedField",
          "name": "otherAvailableProducts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "a3e7dd8458e631109ff591c46a5d7414";

export default node;
