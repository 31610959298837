/**
 * @generated SignedSource<<0e81fcc33e6c07499dc58ea8a4965728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountContextMenu_AuthViewerSchemaFragment$data = {
  readonly currentUser: {
    readonly accounts: ReadonlyArray<{
      readonly name: string;
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"deleteAccountModalsFlow_AuthViewerSchemaFragment" | "leaveAccountModal_authViewerSchemaFragmentRef">;
  readonly " $fragmentType": "accountContextMenu_AuthViewerSchemaFragment";
};
export type accountContextMenu_AuthViewerSchemaFragment$key = {
  readonly " $data"?: accountContextMenu_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountContextMenu_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "accountContextMenu_AuthViewerSchemaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "deleteAccountModalsFlow_AuthViewerSchemaFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leaveAccountModal_authViewerSchemaFragmentRef"
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "a1ff84fc1d5d2ae8b768b5c339bea134";

export default node;
