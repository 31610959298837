/**
 * @generated SignedSource<<d4a98723cfcc49cbed3f9e1bbd585c5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type trendsDetail_QueryFragment$data = {
  readonly node: {
    readonly coachingHeadingAndDescription?: {
      readonly description: string;
      readonly heading: string;
    };
    readonly coachingImage?: {
      readonly url: string | null;
    } | null;
    readonly id?: string;
    readonly learnables?: ReadonlyArray<{
      readonly id: string;
      readonly kind: LearnableKind;
      readonly " $fragmentSpreads": FragmentRefs<"trendsDetail_LearnableFragment">;
    }>;
    readonly potentialAnalysisHeadingAndDescription?: {
      readonly description: string;
      readonly heading: string;
    };
    readonly trendData?: {
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"feedbackQuote_CustomerFeedbackQueryFragment" | "trendMedia_TrendMediaFragment" | "trendOfferSection_TrendOfferFragment" | "trendUspBoxes_TrendUspBoxesFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"tags_AcademiesRooTagsConnectionFragment">;
  readonly " $fragmentType": "trendsDetail_QueryFragment";
};
export type trendsDetail_QueryFragment$key = {
  readonly " $data"?: trendsDetail_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendsDetail_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "heading",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./trendsDetailRefetchQuery.graphql')
    }
  },
  "name": "trendsDetail_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "trendMedia_TrendMediaFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "trendUspBoxes_TrendUspBoxesFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "feedbackQuote_CustomerFeedbackQueryFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "trendOfferSection_TrendOfferFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TrendData",
              "kind": "LinkedField",
              "name": "trendData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "HeadingAndDescription",
              "kind": "LinkedField",
              "name": "potentialAnalysisHeadingAndDescription",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "HeadingAndDescription",
              "kind": "LinkedField",
              "name": "coachingHeadingAndDescription",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "coachingImage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "learnables",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "trendsDetail_LearnableFragment",
                  "selections": [
                    (v2/*: any*/),
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "coachingLearnableCard_PublishedLearnableFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "educationalOfferCard_PublishedCourseLearnableFragment"
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Trend",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "tags_AcademiesRooTagsConnectionFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a92b8b7586f3acc1797a275eea0ee70b";

export default node;
