import styled from "styled-components";
import { spacing4, TkaSpacing } from "@themes/spacing";

interface Props {
	imageUrl: string;
	imageWidth: string;
	isAbsolute?: boolean;
	borderRadiusRem?: TkaSpacing;
}
export const DEFAULT_BORDER_RADIUS_IN_REM = spacing4;
export const ImageCardAtom = ({
	imageWidth,
	imageUrl,
	isAbsolute = false,
	borderRadiusRem,
}: Props) => {
	return (
		<StyledCardImage
			imageUrl={imageUrl}
			imageWidth={imageWidth}
			isAbsolute={isAbsolute}
			borderRadiusRem={borderRadiusRem}
		/>
	);
};

const StyledCardImage = styled.div<{
	imageUrl: string;
	imageWidth: string;
	isAbsolute: boolean;
	borderRadiusRem?: TkaSpacing;
}>`
	${({ isAbsolute }) =>
		isAbsolute
			? `
		position: absolute;
		top: 0;
		left: 0;
	`
			: ""}
	height: 100%;
	max-height: 200px;
	width: ${(p) => p.imageWidth};
	background-image: url(${(p) => p.imageUrl});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius: ${(props) =>
		props.borderRadiusRem ? props.borderRadiusRem.rem() : DEFAULT_BORDER_RADIUS_IN_REM.rem()};
`;
