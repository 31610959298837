/**
 * @generated SignedSource<<5efe1d385abaa969e60495146466b963>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordPackageList_WordInventoryFragment$data = {
  readonly packages: ReadonlyArray<{
    readonly wordPackage: {
      readonly id: string;
    } | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"wordPackageCard_WordInventoryFragment">;
  readonly " $fragmentType": "wordPackageList_WordInventoryFragment";
};
export type wordPackageList_WordInventoryFragment$key = {
  readonly " $data"?: wordPackageList_WordInventoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"wordPackageList_WordInventoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "wordPackageList_WordInventoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PackageUnlock",
      "kind": "LinkedField",
      "name": "packages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WordPackage",
          "kind": "LinkedField",
          "name": "wordPackage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "wordPackageCard_WordInventoryFragment"
    }
  ],
  "type": "WordInventory",
  "abstractKey": null
};

(node as any).hash = "f4cfbbc1e732e2045af7d3976a1ac2f1";

export default node;
