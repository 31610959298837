import { Skeleton } from "../skeleton";

export const TextParagraphSkeleton = () => {
	return (
		<div>
			<Skeleton width="10%" height="1.5rem" />
			<Skeleton width="100%" className="mt-16" />
			<Skeleton width="40%" className="mt-8" />
			<Skeleton width="60%" className="mt-8" />
			<Skeleton width="30%" className="mt-8" />
		</div>
	);
};
