import { useFormik } from "formik";
import { useContext, useMemo } from "react";
import { useMutation } from "react-relay";
import * as Yup from "yup";
import { Checkbox, CheckboxStatus } from "@components/checkbox";
import { DialogTemplate } from "@components/dialog-template";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { CREATE_REPORT_MUTATION } from "@components/report-modal-flow/parts/create-report-modal/create-report-modal.graphql";
import {
	CheckboxErrorMessage,
	CheckboxWrapper,
	Hr,
	IconWrapper,
	IconWrapperWrapper,
	InputsWrapper,
	RowSpan12,
	RowSpan6,
	TitleWrapper,
	Wrapper,
} from "@components/report-modal-flow/parts/create-report-modal/create-report-modal.styles";
import {
	CreateReportModalFormState,
	CreateReportModalProps,
} from "@components/report-modal-flow/parts/create-report-modal/create-report-modal.types";
import {
	getProblemTypeOptions,
	getReportTypeOptions,
} from "@components/report-modal-flow/parts/create-report-modal/create-report-modal.utils";
import { SystemEmail } from "@components/report-modal-flow/parts/system-email";
import { ReportModalFlowContext } from "@components/report-modal-flow/report-modal-flow.context";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { ValidatedDropdown } from "@components/validated-dropdown";
import { ValidatedInputText } from "@components/validated-input-text";
import { DATA_PROTECTION_URL } from "@consts/index";
import { createReportModal_createReportMutation } from "@relay/createReportModal_createReportMutation.graphql";
import { error100, shade10, shade100, shade80, success100 } from "@themes/colors";
import { H1Span, TkaH2Span, TkaP2Anchor, TkaP2Span, TkaP3Span } from "@themes/font-tags";

export const CreateReportModal = ({ isVisible, onDismiss, onSuccess }: CreateReportModalProps) => {
	const [createReport, isCreating] =
		useMutation<createReportModal_createReportMutation>(CREATE_REPORT_MUTATION);
	const { setReferenceNumber } = useContext(ReportModalFlowContext);
	const handleOnDismiss = () => {
		setReferenceNumber("");
		onDismiss?.();
	};
	const form = useFormik<Partial<CreateReportModalFormState>>({
		initialValues: {
			firstName: undefined,
			lastName: undefined,
			email: undefined,
			url: window.location.href,
			extra: undefined,
			reportType: "Feedback",
			problemType: "IllegalContents",
			isChecked: false,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			reportType: Yup.string().required("Bitte einen Meldungs-Typ auswählen."),
			problemType: Yup.string().required("Bitte einen Problem-Typ auswählen."),
			email: Yup.string().email("Bitte nutze ein valide E-Mail-Adresse."),
			isChecked: Yup.boolean()
				.test(
					"report-modal-isCecked",
					"Die AGBs müssen akzeptiert werden.",
					(value, context) => {
						if (form.touched["isChecked"] && value === false)
							return context.createError();
						return true;
					},
				)
				.required(),
		}),
		onSubmit: (values, formikHelpers) => {
			if (!values.isChecked) {
				formikHelpers.setFieldError("isChecked", "Du musst die AGB akzeptieren.");
				return;
			}
			createReport({
				variables: {
					input: {
						data: {
							url: values.url,
							firstName: values.firstName,
							lastName: values.lastName,
							extra: values.extra,
							email: values.email,
							reportType: values.reportType!,
							problemType: values.problemType!,
						},
					},
				},
				onCompleted: (response) => {
					setReferenceNumber(response.Reports.createReport?.referenceNumber);
					onSuccess?.();
				},
			});
		},
	});

	const handleSubmitOnClick = () => {
		form.submitForm();
	};

	const checkboxStatus = useMemo(
		() => (form.isValid ? CheckboxStatus.Default : CheckboxStatus.Error),
		[form.values, form.touched, form.isValid, form.isValidating],
	);

	return (
		<ResponsiveBottomSheetModal
			isVisible={isVisible}
			blocking={false}
			dismissable={true}
			onDismiss={handleOnDismiss}
		>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={handleOnDismiss}
				primaryButtonIconName="arrowRight"
				primaryButtonLabel="Meldung senden"
				primaryButtonLoading={isCreating}
				onPrimaryButtonClick={handleSubmitOnClick}
				maxWidthRem={50}
				primaryButtonStretch={false}
				secondaryButtonStretch={false}
			>
				<Wrapper>
					<IconWrapperWrapper>
						<IconWrapper>
							<Icon icon={"feedback2"} sizeInRem={2.5} tkaColor={success100} />
						</IconWrapper>
					</IconWrapperWrapper>

					<TitleWrapper>
						<H1Span>Wir freuen uns über Deine Meldung!</H1Span>
						<br />
						<TkaP3Span tkaColor={shade80}>
							In der Regel antworten wir innerhalb von 5 Werktagen.
						</TkaP3Span>
					</TitleWrapper>
					<TkaP2Span tkaColor={shade80}>
						Wir bearbeiten Deine Anfrage so schnell wie möglich. In der Regel erhältst
						du innerhalb von 5 Werktagen eine Antwort von uns. Deine persönlichen Daten
						sind optional und können hilfreich sein, um Dich auf dem Laufenden zu halten
						oder uns zu ermöglichen, bei Fragen auf Dich zuzukommen.
					</TkaP2Span>
					<form onSubmit={form.handleSubmit}>
						<InputsWrapper>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="firstName"
									label={"Name"}
									placeholder={"Dein Name, für Nachfragen..."}
								/>
							</RowSpan6>
							<RowSpan6>
								<ValidatedInputText
									formikConfig={form}
									name="lastName"
									label={"Nachname"}
									placeholder={"Dein Nachname, für Nachfragen..."}
								/>
							</RowSpan6>
							<RowSpan12>
								<ValidatedInputText
									formikConfig={form}
									name="email"
									label={"E-Mail"}
									placeholder={"Deine E-Mail, für Nachfragen (optional)..."}
								/>
							</RowSpan12>
							<Hr tkaColor={shade10} />
							<RowSpan12>
								<TkaH2Span>Wie können wir Dir helfen?</TkaH2Span>
							</RowSpan12>
							<RowSpan12>
								<ValidatedDropdown
									formikConfig={form}
									name="reportType"
									options={getReportTypeOptions()}
									label="Art der Meldung*"
								/>
							</RowSpan12>
							<RowSpan12>
								<ValidatedInputText
									formikConfig={form}
									name={"url"}
									label={"URL der Meldung"}
									placeholder={"Url der Meldung, für Nachfragen..."}
								/>
							</RowSpan12>
							<Hr tkaColor={shade10} />

							<RowSpan12>
								<ValidatedDropdown
									formikConfig={form}
									name="problemType"
									options={getProblemTypeOptions()}
									label="Problem*"
								/>
							</RowSpan12>

							<RowSpan12>
								<ValidatedInputText
									formikConfig={form}
									name="extra"
									label={"Erzähl uns mehr über Dein Anliegen"}
									placeholder={
										"Bitte beschreibe Dein Anliegen so detailliert wie möglich, damit wir Dir bestmöglich weiterhelfen können ..."
									}
									textArea
								/>
							</RowSpan12>

							<RowSpan12>
								<CheckboxWrapper>
									<Checkbox
										checked={form.values.isChecked ?? false}
										status={checkboxStatus}
										onChange={(e) => {
											form.setFieldValue("isChecked", e.checked);
										}}
									/>
									<TkaP2Span tkaColor={shade100}>
										Ich stimme zu, dass meine angegebenen Daten im Einklang mit
										der{" "}
										<TkaP2Anchor href={DATA_PROTECTION_URL} target="_blank">
											Datenschutzrichtlinie
										</TkaP2Anchor>{" "}
										verarbeitet werde
										{checkboxStatus === CheckboxStatus.Error && (
											<>
												<br />
												<CheckboxErrorMessage>
													<TkaP3Span tkaColor={error100}>
														{form.errors?.["isChecked"]}
													</TkaP3Span>
												</CheckboxErrorMessage>
											</>
										)}
									</TkaP2Span>
								</CheckboxWrapper>
							</RowSpan12>
							<RowSpan12 center>
								<TkaP3Span tkaColor={shade100}>
									Für Hilfe und Unterstützung kontaktieren Sie bitte{" "}
									<SystemEmail />
								</TkaP3Span>
							</RowSpan12>
						</InputsWrapper>
					</form>
				</Wrapper>
				<Divider />
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
