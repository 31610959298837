import { useWindowSize } from "@hooks/use-window-size";
import { ResponsiveBottomSheetModalProps } from "./responsive-bottom-sheet-modal.types";
import { BottomSheet } from "../bottom-sheet";
import { Modal } from "../modal";

export const ResponsiveBottomSheetModal = ({
	isVisible = false,
	dismissable = true,
	blocking,
	hasCloseIcon,
	onDismiss,
	children,
}: ResponsiveBottomSheetModalProps) => {
	const { isMediumUp } = useWindowSize();
	return isMediumUp ? (
		<Modal
			isVisible={isVisible}
			dismissable={dismissable}
			onHide={onDismiss}
			position="center"
			hasCloseIcon={hasCloseIcon}
		>
			{children}
		</Modal>
	) : (
		<BottomSheet
			blocking={blocking}
			isVisible={isVisible}
			dismissable={dismissable}
			onDismiss={onDismiss}
		>
			{children}
		</BottomSheet>
	);
};
