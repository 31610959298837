import { graphql } from "babel-plugin-relay/macro";

export const WORD_INVENTORY_QUERY = graphql`
	query wordInventoryDisplay_Query {
		Vocab {
			MyWordInventory {
				id
				...wordPackageList_WordInventoryFragment
				packages {
					unlockedAt
				}
			}
		}
	}
`;
