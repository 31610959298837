import tw from "twin.macro";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { TkaP2Span } from "@themes/font-tags";

export const ElementWrapper = tw(SideMenuContentPadding)`
    flex
    flex-col
`;

export const Subtitle = tw(TkaP2Span)`
    mt-32   
`;

export const List = tw.div`
    flex
    flex-col
    mt-12
`;

export const DraggableWrapper = tw.div`
    mb-12
`;

export const CardItemWrapper = tw.div`
    flex
    flex-row
    gap-16
    items-center
`;

export const Text = tw(TkaP2Span)`
    flex-1
`;
