/**
 * @generated SignedSource<<fb7ec0ebe370436aef9b03c8e2ff7a76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteOrAddUsersToUserInAccountGroupsInput = {
  clientMutationId?: string | null;
  emails: ReadonlyArray<string>;
  groupIds: ReadonlyArray<string>;
};
export type standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation$variables = {
  connections: ReadonlyArray<string>;
  input: InviteOrAddUsersToUserInAccountGroupsInput;
};
export type standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation$data = {
  readonly AccountMemberManagement: {
    readonly inviteOrAddUsersToUserInAccountGroups: {
      readonly newAccountMembers: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"invitationCard_InvitationFragment">;
        };
      }>;
      readonly updatedAccountMembers: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"userCard_UserInAccountWrapperFragment">;
      }>;
    } | null;
  };
};
export type standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation = {
  response: standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation$data;
  variables: standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserInAccountGroup",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "InviteOrAddUsersToUserInAccountGroupsPayload",
            "kind": "LinkedField",
            "name": "inviteOrAddUsersToUserInAccountGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "updatedAccountMembers",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "userCard_UserInAccountWrapperFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountMemberEdge",
                "kind": "LinkedField",
                "name": "newAccountMembers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "invitationCard_InvitationFragment"
                          }
                        ],
                        "type": "InvitationWrapper",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "InviteOrAddUsersToUserInAccountGroupsPayload",
            "kind": "LinkedField",
            "name": "inviteOrAddUsersToUserInAccountGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "updatedAccountMembers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isManager",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "userExtensionKind",
                                    "value": "Academies"
                                  }
                                ],
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "firstName",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "lastName",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "File",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "thumbnail",
                                            "storageKey": null
                                          },
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "sumGamificationPoints",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "AcademiesUserExtension",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": "extension(userExtensionKind:\"Academies\")"
                              }
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "UserInAccountWrapper",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountMemberEdge",
                "kind": "LinkedField",
                "name": "newAccountMembers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Invitation",
                                    "kind": "LinkedField",
                                    "name": "invitation",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "type": "InvitationWrapper",
                                "abstractKey": null
                              }
                            ],
                            "type": "AccountMember",
                            "abstractKey": "__isAccountMember"
                          }
                        ],
                        "type": "InvitationWrapper",
                        "abstractKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "newAccountMembers",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a16a1fea8afe57e825781895c9e5bd6f",
    "id": null,
    "metadata": {},
    "name": "standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation(\n  $input: InviteOrAddUsersToUserInAccountGroupsInput!\n) {\n  AccountMemberManagement {\n    inviteOrAddUsersToUserInAccountGroups(input: $input) {\n      updatedAccountMembers {\n        __typename\n        ...userCard_UserInAccountWrapperFragment\n        id\n      }\n      newAccountMembers {\n        node {\n          __typename\n          ... on InvitationWrapper {\n            ...invitationCard_InvitationFragment\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment invitationCard_InvitationFragment on AccountMember {\n  __isAccountMember: __typename\n  ... on InvitationWrapper {\n    invitation {\n      id\n      email\n    }\n    groups {\n      id\n      name\n    }\n  }\n}\n\nfragment userAvatar_UserFragment on User {\n  extension(userExtensionKind: Academies) {\n    __typename\n    ... on AcademiesUserExtension {\n      firstName\n      lastName\n      avatar {\n        thumbnail\n        id\n      }\n      sumGamificationPoints\n    }\n  }\n}\n\nfragment userCard_UserInAccountWrapperFragment on UserInAccountWrapper {\n  id\n  name\n  isManager\n  groups {\n    id\n    name\n  }\n  user {\n    user {\n      id\n      name\n      email\n      ...userAvatar_UserFragment\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "683dac0b8afc8e1caddeccd83b89eb19";

export default node;
