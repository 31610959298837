import { graphql } from "babel-plugin-relay/macro";

export const USER_FRAGMENT = graphql`
	fragment inputSuggestionUserItemTemplate_UserFragment on User {
		id
		name
		extension(userExtensionKind: Academies) {
			... on AcademiesUserExtension {
				avatar {
					thumbnail
				}
			}
		}
	}
`;
