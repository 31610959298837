import styled from "styled-components";
import tw from "twin.macro";
import { onBrandMain } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const TextWrapper = tw.div`
	max-w-[35rem]
	flex
	flex-col
	gap-24
`;

export const BottomBarWrapper = tw.div`
    flex 
	flex-row
	justify-end
	p-16
	gap-16
`;

export const CategoryWrapper = styled.div`
	${tw`pt-8 pl-20 pr-20 pb-8`};
	border-radius: 16px;
	background-color: ${onBrandMain};
`;

export const StatisticsWrapper = tw.div`
	flex
	flex-col
	gap-16
`;

export const StatisticsBarWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const LegendWrapper = tw.div`
	flex
	flex-row
	gap-32
`;

export const LegendItemWrapper = tw.div`
	flex
	flex-row
	items-center
	gap-8
`;

export const BarIndicator = tw.div`
	w-20
	h-4
	rounded-10
	bg-brand-strong-80
`;

export const InfoWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const BottomTextGrid = tw.div`
	grid
	grid-cols-12
	gap-24
`;

export const BottomTextLeft = styled.div<{ hasFullWidth?: boolean }>`
	${({ hasFullWidth }) =>
		hasFullWidth
			? tw`col-span-12`
			: tw`
				col-span-12
				lg:col-span-8
			`}
`;

export const BottomTextRight = tw.div`
	col-span-12
	lg:col-span-4
	flex
	flex-col
	gap-4
`;

export const UnorderedList = tw.ul`
	list-disc	
	list-inside
`;

export const HighlightedText = tw(TkaP2Span)`
	bg-brand-strong-40
	rounded-4
`;

export const SidemenuWrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const ExplanationTextWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const PDFDownloadWrapper = tw.div`
	bg-shade-0
	rounded-12
`;
