import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import WebappTeaserPNG from "@assets/auth_image.png";
import { ReactComponent as LogoSVG } from "@assets/logo.svg";
import { Footer } from "@components/footer/footer.component";
import { ReportModalFlow } from "@components/report-modal-flow/report-modal-flow.component";
import { AGB_URL, DATA_PROTECTION_URL, IMPRINT_URL } from "@consts/index";
import { AuthTemplateProps, FooterItem } from "./auth-template.interface";
import {
	BorderWrapper,
	Header,
	HeaderContentWrapper,
	LeftContent,
	WebappTeaser,
	Wrapper,
} from "./auth-template.styles";

export const AuthTemplate = ({ hideImage, children }: AuthTemplateProps) => {
	const { t } = useTranslation("authLayout");
	const [isShowingCreateReportFlow, setIsShowingCreateReportFlow] = useState(false);
	const handleReportOnClick = () => {
		setIsShowingCreateReportFlow(true);
	};
	const handleReportModalFlowDismissOnClick = () => {
		setIsShowingCreateReportFlow(false);
	};
	const footerItems: FooterItem[] = [
		{
			label: t("auth_layout.footerImprint"),
			link: IMPRINT_URL,
		},
		{ label: t("auth_layout.footerReport"), onClick: handleReportOnClick },
		{
			label: t("auth_layout.footerPrivacy"),
			link: DATA_PROTECTION_URL,
		},
		{
			label: t("auth_layout.footerAgb"),
			link: AGB_URL,
		},
	];

	return (
		<Fragment>
			<ReportModalFlow
				isVisible={isShowingCreateReportFlow}
				onDismiss={handleReportModalFlowDismissOnClick}
			/>
			<Wrapper hideImage={hideImage}>
				<LeftContent hideImage={hideImage}>
					<HeaderContentWrapper hideImage={hideImage}>
						<BorderWrapper hideImage={hideImage}>
							<Header>
								<LogoSVG />
							</Header>
							{children}
						</BorderWrapper>
					</HeaderContentWrapper>
					<Footer items={footerItems} />
				</LeftContent>
				{!hideImage && (
					<WebappTeaser style={{ backgroundImage: `url(${WebappTeaserPNG})` }} />
				)}
			</Wrapper>
		</Fragment>
	);
};
