import { CSSObject } from "styled-components";
import { type Recursive, type Token } from "@styled-system/types/composition";

/**
 * @deprecated This class should not be used
 */
export class TkaSpacing implements CSSObject {
	[key: string]: any;
	constructor(private readonly px: number) {}

	rem(): string {
		return `${this.remValue()}rem`;
	}

	remValue(): number {
		return this.px > 0 ? this.px / 16 : 0;
	}

	public toString = this.rem;
}
/**
 * @deprecated This spacing should not be used
 */
export const spacing0: TkaSpacing = new TkaSpacing(0);

/**
 * @deprecated This spacing should not be used
 */
export const spacing2: TkaSpacing = new TkaSpacing(2);

/**
 * @deprecated This spacing should not be used
 */
export const spacing4: TkaSpacing = new TkaSpacing(4);

/**
 * @deprecated This spacing should not be used
 */
export const spacing8: TkaSpacing = new TkaSpacing(8);

/**
 * @deprecated This spacing should not be used
 */
export const spacing10: TkaSpacing = new TkaSpacing(10);

/**
 * @deprecated This spacing should not be used
 */
export const spacing12: TkaSpacing = new TkaSpacing(12);

/**
 * @deprecated This spacing should not be used
 */
export const spacing16: TkaSpacing = new TkaSpacing(16);

/**
 * @deprecated This spacing should not be used
 */
export const spacing20: TkaSpacing = new TkaSpacing(20);

/**
 * @deprecated This spacing should not be used
 */
export const spacing24: TkaSpacing = new TkaSpacing(24);

/**
 * @deprecated This spacing should not be used
 */
export const spacing32: TkaSpacing = new TkaSpacing(32);

/**
 * @deprecated This spacing should not be used
 */
export const spacing40: TkaSpacing = new TkaSpacing(40);

/**
 * @deprecated This spacing should not be used
 */
export const spacing64: TkaSpacing = new TkaSpacing(64);

/**
 * @deprecated This spacing should not be used
 */
export const spacing80: TkaSpacing = new TkaSpacing(80);

/**
 * @deprecated This spacing should not be used
 */
export const spacing96: TkaSpacing = new TkaSpacing(96);

export const spacing: Recursive<Token<string | number>> = {
	0: { value: "0rem" },
	2: { value: "0.125rem" },
	4: { value: "0.25rem" },
	8: { value: "0.5rem" },
	10: { value: "0.625rem" },
	12: { value: "0.75rem" },
	16: { value: "1rem" },
	20: { value: "1.25rem" },
	24: { value: "1.5rem" },
	32: { value: "2rem" },
	40: { value: "2.5rem" },
	64: { value: "4rem" },
	80: { value: "5rem" },
	96: { value: "6rem" },
};
