import tw from "twin.macro";

export const CardWrapper = tw.div`
	flex
	w-[100%]
	max-w-[20.4rem]
	md:w-[17.5rem]
`;

export const Wrapper = tw.div`
	relative
	flex
	flex-col
	w-full
`;

export const Header = tw.div`
	h-[13.75rem] p-16 py-32 flex flex-col relative justify-between flex
	bg-shade-20
`;

export const Footer = tw.div`p-16 flex flex-col justify-between`;

export const DescriptionWrapper = tw.div`
	whitespace-normal 
	text-ellipsis 
	line-clamp-3 
	relative 
	flex-1
	mt-4
`;

export const IconWrapper = tw.div`
absolute top-[100%] translate-y-[-50%] right-[0] translate-x-[-0.5rem] z-[1]
`;
