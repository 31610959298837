/**
 * @generated SignedSource<<9d17496982e619a86b23d93b60ada126>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type gridView_PublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly " $fragmentSpreads": FragmentRefs<"coachingLearnableCard_PublishedLearnableFragment" | "educationalOfferCard_PublishedCourseLearnableFragment">;
  readonly " $fragmentType": "gridView_PublishedLearnableFragment";
};
export type gridView_PublishedLearnableFragment$key = {
  readonly " $data"?: gridView_PublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"gridView_PublishedLearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "gridView_PublishedLearnableFragment"
};

(node as any).hash = "bdad143fc3ab981199bc0e8c125daa23";

export default node;
