import { readInlineData } from "react-relay";
import { LicensesAccountGroupAssignmentCard } from "@components/licenses-account-group-assignment-card";
import { withSuspense } from "@components/with-suspense";
import { useToast } from "@hooks/useToast";
import { QUERY_FRAGMENT } from "./group-rules-tab.graphql";
import { GroupRulesTabComponentSkeleton } from "./group-rules-tab.skeleton";
import { AllocationListWrapper } from "./group-rules-tab.styles";
import { GroupRulesTabComponentProps } from "./group-rules-tab.types";

export const GroupRulesTabComponent = ({
	items,
	groupOptions,
	refetch,
}: GroupRulesTabComponentProps) => {
	const { showSuccess } = useToast();

	const handleDelete = () => {
		showSuccess({
			summary: "Zuweisung gelöscht",
		});

		refetch?.(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	return (
		<AllocationListWrapper>
			{items?.map((item) => (
				<LicensesAccountGroupAssignmentCard
					key={readInlineData(QUERY_FRAGMENT, item).id}
					onDelete={handleDelete}
					groupOptions={groupOptions}
					queryFragmentRef={item}
				/>
			))}
		</AllocationListWrapper>
	);
};

export const GroupRulesTab = withSuspense(GroupRulesTabComponent, GroupRulesTabComponentSkeleton);
