import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment tags_AcademiesRooTagsConnectionFragment on Query
	@refetchable(queryName: "tagsRefetchQuery")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: null }
		last: { type: "Int", defaultValue: null }
		before: { type: "String", defaultValue: null }
		after: { type: "String", defaultValue: null }
	) {
		AcademiesTag {
			AcademiesTags(first: $first, after: $after, before: $before, last: $last) {
				edges {
					node {
						...tags_AcademiesRootTagFragment
					}
				}
			}
		}
	}
`;

export const ACADEMIES_ROOT_TAG_FRAGMENT = graphql`
	fragment tags_AcademiesRootTagFragment on AcademiesRootTag @inline {
		id
		kind
		canBeDeleted
		data {
			name
			isClickable
			isTopic
		}
		children {
			edges {
				node {
					id
					kind
					data {
						isTopic
						isClickable
						name
					}
					subId
				}
			}
		}
	}
`;
