import { useWindowSize } from "@hooks/use-window-size";
import { brandStrong20, brandStrong80, info100, shade40 } from "@themes/colors";
import { TkaL1Span, TkaP3Span } from "@themes/font-tags";
import { Bar, BarWrapper, Benchmark, LegendWrapper, Wrapper } from "./statistics-bar.styles";
import { StatisticsBarColorVersion, StatisticsBarProps } from "./statistics-bar.types";
import { Icon } from "../icon";

export const StatisticsBar = ({
	step,
	maxSteps,
	benchmark = 0,
	label,
	colorVersion,
}: StatisticsBarProps) => {
	const { isMediumUp } = useWindowSize();
	const backgroundColor =
		colorVersion === StatisticsBarColorVersion.Blue ? info100 : brandStrong80;

	const percent = (step - 1) / (maxSteps - 1);
	const barWidth = maxSteps > 0 && percent > 0 ? percent * 100 : 0;
	const barOffset = isMediumUp ? 20 - percent * 20 : 0;

	const benchmarkPercent = Math.max(0, ((benchmark - 1) / (maxSteps - 1)) * 100);
	const dynamicLeftOffset = -(benchmarkPercent / 100) * 20 + 10;
	const leftOffset = benchmark <= 1 ? 16 : isMediumUp ? dynamicLeftOffset : -10;

	const formatedStep = parseFloat(step.toFixed(1));

	return (
		<Wrapper>
			{label && <TkaP3Span>{label}</TkaP3Span>}
			<BarWrapper>
				<Bar widthPercent={barWidth} offset={barOffset} backgroundColor={backgroundColor}>
					<TkaL1Span tkaColor={brandStrong20}>{formatedStep}</TkaL1Span>
				</Bar>
				<Benchmark leftPercent={benchmarkPercent} leftOffset={leftOffset}>
					<Icon sizeInRem={1.25} icon="arrowTop" />
				</Benchmark>
			</BarWrapper>
			<LegendWrapper>
				{Array.from(Array(maxSteps).keys()).map((step) => (
					<TkaP3Span key={step} tkaColor={shade40}>
						{step + 1}
					</TkaP3Span>
				))}
			</LegendWrapper>
		</Wrapper>
	);
};
