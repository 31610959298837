import { FooterProps } from "@components/footer/footer.interface";
import { FooterItem, FooterStyledBar } from "@components/footer/footer.styles";
import { shade100 } from "@themes/colors";

export const Footer = ({ items }: FooterProps) => {
	return (
		<FooterStyledBar className="my-footer">
			{items.map((item) => (
				<FooterItem
					key={item.label}
					tkaColor={shade100}
					{...(item.link
						? { href: item.link }
						: item.onClick
						? { onClick: item.onClick }
						: {})}
				>
					{item.label}
				</FooterItem>
			))}
		</FooterStyledBar>
	);
};
