import { Toast, ToastMessage } from "primereact/toast";
import { useRef } from "react";
import { ToastContext } from "./toast-provider.context";
import { ToastMessageWithoutSeverity, ToastProviderProps } from "./toast-provider.interface";
import { TkToast } from "./toast-provider.styles";

export const ToastProvider = ({ children }: ToastProviderProps) => {
	const ref = useRef<Toast>(null);

	const clear = () => ref.current?.clear();

	const replace = (message: ToastMessage) => {
		ref.current?.replace(message);
	};

	const remove = (message: ToastMessage) => {
		ref.current?.remove(message);
	};

	const showToast = (message: ToastMessage) => {
		ref.current?.show({ life: 5000, ...message });
	};

	const showSuccess = (message: ToastMessageWithoutSeverity) => {
		showToast({ ...message, severity: "success" });
	};

	const showInfo = (message: ToastMessageWithoutSeverity) => {
		showToast({ ...message, severity: "info" });
	};

	const showWarning = (message: ToastMessageWithoutSeverity) => {
		showToast({ ...message, severity: "warn" });
	};

	const showError = (message: ToastMessageWithoutSeverity) => {
		showToast({ ...message, severity: "error" });
	};

	return (
		<ToastContext.Provider
			value={{
				clear,
				replace,
				remove,
				showWarning,
				showError,
				showInfo,
				showSuccess,
			}}
		>
			{children}
			<TkToast ref={ref} position="top-center" />
		</ToastContext.Provider>
	);
};
