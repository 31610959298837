import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useFragment, useMutation } from "react-relay";
import { Card } from "@components/card/card.component";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider/content-submission-modal-context-provider.context";
import { Icon } from "@components/icon";
import { OrderElement_ContentSubmissionFragment$key } from "@relay/OrderElement_ContentSubmissionFragment.graphql";
import { OrderElement_SubmitOrderElementMutation } from "@relay/OrderElement_SubmitOrderElementMutation.graphql";
import { shade100 } from "@themes/colors";
import { TkaH3Span, H1Span } from "@themes/font-tags";
import { stripHtml } from "@utils/strip-html";
import { OrderElementMap, OrderElementProps, OrderElementStatus } from "./order-element.interface";

import {
	CardItemWrapper,
	DraggableWrapper,
	ElementWrapper,
	List,
	Subtitle,
	Text,
} from "./order-element.styles";
import {
	getBackgroundColor,
	getBorderColor,
	getDragingIconColor,
	getIconColor,
	getNumberColor,
	getTextColor,
} from "./order-element.util";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment OrderElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					... on InputAndIsCorrectElementState {
						isCorrect
						inputElementState {
							... on OrderInputElementState {
								selectedOrder
							}
						}
					}
					... on OrderShowAnswerElementState {
						correctOrder
						lastSelectedOrder
					}
					element {
						title
						id
						... on OrderLearnElement {
							id
							title
							answeringTypeText
							orderItems {
								id
								text
							}
						}
					}
				}
			}
		}
	}
`;

const SUBMIT_ORDER_ELEMENT_MUTATION = graphql`
	mutation OrderElement_SubmitOrderElementMutation($input: SubmitOrderElementInput!) {
		LearnV2 {
			submitOrderElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								kind
								... on InputAndIsCorrectElementState {
									isCorrect
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const OrderElement = ({ contentSubmissionFragmentRef }: OrderElementProps) => {
	const contentSubmission = useFragment<OrderElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitOrderElement] = useMutation<OrderElement_SubmitOrderElementMutation>(
		SUBMIT_ORDER_ELEMENT_MUTATION,
	);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;

	const currentElementStateKind =
		contentSubmission?.definition?.currentElementState?.kind || "Untouched";
	const isShowAnswer = currentElementStateKind === "ShowAnswer";
	const isInputCorrect = currentElementStateKind === "InputAndIsCorrect";
	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";

	const isCorrect = elementState?.isCorrect;

	const {
		isModalVisible,
		setCanGoNext,
		addGoToNextOnClickListener,
		setFailureModalVisible,
		setSuccessModalVisible,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [orderElementsId, setOrderElementsId] = useState<string[]>([]);

	const dispatch = useDispatch();

	const handleSubmitClick = useCallback(() => {
		setLoading(true);
		submitOrderElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
					orderElementId: element?.id!,
					order: orderElementsId,
				},
			},
			onCompleted: (response) => {
				setLoading(false);
				const currentElementState =
					response.LearnV2.submitOrderElement?.contentSubmission.definition
						?.currentElementState;
				if (!currentElementState) return;

				if (currentElementState.isCorrect) {
					setSuccessModalVisible(true);
				} else if (
					currentElementState.isCorrect === false ||
					currentElementState.kind === "UntouchedAndPreviouslyIncorrect"
				) {
					setFailureModalVisible(true);
				}
			},
		});
	}, [
		submitOrderElement,
		contentSubmission.id,
		element?.id,
		orderElementsId,
		setSuccessModalVisible,
		setFailureModalVisible,
	]);

	const handleGoToNextClicked = useCallback(() => {
		if (isUntouched) {
			handleSubmitClick();
			return true;
		}
		return false;
	}, [isUntouched, handleSubmitClick]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	useEffect(() => {
		setCanGoNext(true);
	}, []);

	const handleDrop = useCallback(
		(droppedItem: any) => {
			if (!droppedItem.destination) return;
			const updatedList = [...orderElementsId];
			const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
			updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
			setOrderElementsId(updatedList);
		},
		[orderElementsId, dispatch],
	);

	const elementsIdMap: OrderElementMap = useMemo(() => {
		return (
			element?.orderItems?.reduce(
				(current, value) => ({ ...current, [value.id]: value }),
				{},
			) || {}
		);
	}, [element?.orderItems]);

	const orderItemsIds = useMemo(
		() => element?.orderItems?.map((e) => e.id) || [],
		[element?.orderItems],
	);

	useEffect(() => {
		const selectedOrder = elementState?.inputElementState?.selectedOrder || [];
		const orderItems =
			isInputCorrect && selectedOrder.length > 0 ? selectedOrder : orderItemsIds;
		setOrderElementsId([...orderItems]);
	}, orderItemsIds);

	const correctList = elementState?.correctOrder || [];

	const useElemetsIdList = useMemo(() => {
		return isShowAnswer && correctList.length > 0 ? correctList : orderElementsId;
	}, [isShowAnswer, orderElementsId, correctList]);

	const getStatusForItem = (index: number, id: string) => {
		const wasCorrect = orderElementsId[index] === id;
		const revealStatus = wasCorrect
			? OrderElementStatus.Correct
			: OrderElementStatus.ActuallyCorrect;
		return isShowAnswer || isCorrect ? revealStatus : OrderElementStatus.Default;
	};

	const dragDisabled = isModalVisible || !isUntouched;

	return (
		<ElementWrapper>
			{element?.title && <H1Span>{stripHtml(element.title)}</H1Span>}
			{element?.answeringTypeText && (
				<Subtitle tkaColor={shade100}>{stripHtml(element?.answeringTypeText)}</Subtitle>
			)}

			<DragDropContext onDragEnd={handleDrop}>
				<Droppable droppableId="dnd-list">
					{(provided, listSnapshot) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							<List>
								{useElemetsIdList.map((id, index) => {
									const item = elementsIdMap[id];
									const status = getStatusForItem(index, item.id);
									const showIcon = status === OrderElementStatus.Correct;
									return (
										<Draggable
											key={item.id}
											draggableId={item.id}
											index={index}
											isDragDisabled={dragDisabled}
										>
											{(provided, snapshot) => (
												<DraggableWrapper
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<Card
														bodyPaddingInRem={1}
														backgroundColor={getBackgroundColor(status)}
														borderColor={getBorderColor(
															listSnapshot.isDraggingOver,
															snapshot.isDragging,
															isModalVisible,
															status,
														)}
													>
														<CardItemWrapper>
															<Icon
																sizeInRem={1}
																tkaColor={getDragingIconColor(
																	listSnapshot.isDraggingOver,
																	snapshot.isDragging,
																	isModalVisible,
																	status,
																)}
																icon="dragDrop"
															/>
															<TkaH3Span
																tkaColor={getNumberColor(
																	listSnapshot.isDraggingOver,
																	snapshot.isDragging,
																	isModalVisible,
																	status,
																)}
															>
																{index + 1}
															</TkaH3Span>
															<Text
																tkaColor={getTextColor(
																	isModalVisible,
																	status,
																)}
															>
																{item.text}
															</Text>
															{showIcon && (
																<Icon
																	sizeInRem={1.5}
																	icon="correct"
																	tkaColor={getIconColor(status)}
																/>
															)}
														</CardItemWrapper>
													</Card>
												</DraggableWrapper>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</List>
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</ElementWrapper>
	);
};
