import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ImageComponentWithLightbox } from "@components/image-with-lightbox/image-with-lightbox.component";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { ImageElement_ElearningContentSubmissionDefinitionFragment$key } from "@relay/ImageElement_ElearningContentSubmissionDefinitionFragment.graphql";
import { H1Span } from "@themes/font-tags";
import { Spacing32 } from "@utils/parse-html/parse-html.styles";
import { stripHtml } from "@utils/strip-html";
import { ImageElementProps } from "./image-element.interface";

const E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment ImageElement_ElearningContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				title
				id
				... on FileLearnElement {
					title
					file {
						url
					}
				}
			}
		}
	}
`;

export const ImageElement = ({ contentSubmissionDefinitionFragmentRef }: ImageElementProps) => {
	const contentSubmissionDefinition =
		useFragment<ImageElement_ElearningContentSubmissionDefinitionFragment$key>(
			E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);

	const element = contentSubmissionDefinition?.currentElementState?.element;
	const url = element?.file?.url;

	return (
		<SideMenuContentPadding>
			{element?.title && <H1Span>{stripHtml(element.title)}</H1Span>}
			{url && (
				<>
					<Spacing32 />
					<ImageComponentWithLightbox src={url} alt={element.title} />
				</>
			)}
		</SideMenuContentPadding>
	);
};
