import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment appointmentOverviewModal_AvailabilityFragment on Availability {
		id
		booking {
			... on BookedForGroup {
				offer {
					description {
						name
						tags {
							id
							data {
								name
							}
						}
					}
				}
				participants {
					participationStatus
				}
			}
			... on BookedOneToOne {
				offer {
					description {
						name
						tags {
							id
							data {
								name
							}
						}
					}
				}
				participant {
					participationStatus
				}
			}
		}
		data {
			startDateTime
			endDateTime
		}
		coachProfile {
			coach {
				id
			}
		}
		...appointmentDetails_AvailabilityFragment
		...appointmentStatusLabel_AvailabilityFragment
		...appointmentRating_AvailabilityFragment
		...appointmentPresence_AvailabilityFragment
	}
`;

export const QUERY = graphql`
	query appointmentOverviewModal_Query($filterByGroupIds: [ID!]!) {
		...appointmentDetails_QueryFragment @arguments(filterByGroupIds: $filterByGroupIds)
	}
`;
