/**
 * @generated SignedSource<<c5131856588f0beaad56df386f4b9e1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AcademiesTagKind = "Child" | "Root";
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type educationalOfferCard_PublishedCourseLearnableFragment$data = {
  readonly id?: string;
  readonly kind?: LearnableKind;
  readonly root?: {
    readonly description: string | null;
    readonly id: string;
    readonly image: {
      readonly url: string | null;
    } | null;
    readonly shortDescription: string | null;
    readonly structureDefinition: {
      readonly extension?: {
        readonly diploma?: string | null;
        readonly extension?: {
          readonly contractPartner?: {
            readonly data: {
              readonly name: string;
            };
            readonly id: string;
          } | null;
          readonly isMicroLearning?: boolean;
          readonly licenseAvailability?: {
            readonly numAvailable?: number;
          };
        };
        readonly product?: {
          readonly " $fragmentSpreads": FragmentRefs<"offerCard_ProductFragment">;
        } | null;
        readonly tags?: ReadonlyArray<{
          readonly data: {
            readonly isClickable: boolean;
            readonly isTopic: boolean;
            readonly name: string;
          };
          readonly id: string;
          readonly kind: AcademiesTagKind;
        }>;
        readonly unlockInfo?: {
          readonly firstContentId?: string;
          readonly kind: UnlockInfoKind;
        };
      };
      readonly progressPercentage?: number | null;
      readonly title?: string;
      readonly viewerTreeState?: {
        readonly headContentId?: string;
        readonly kind: ViewerTreeStateKind;
        readonly startedAt?: string;
      };
    };
    readonly typeDefinition: {
      readonly extension?: {
        readonly isUnlockNecessaryToStart?: boolean;
      };
    };
  } | null;
  readonly " $fragmentType": "educationalOfferCard_PublishedCourseLearnableFragment";
};
export type educationalOfferCard_PublishedCourseLearnableFragment$key = {
  readonly " $data"?: educationalOfferCard_PublishedCourseLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferCard_PublishedCourseLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "typeDefinition",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "extension",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isUnlockNecessaryToStart",
                  "storageKey": null
                }
              ],
              "type": "LearnContentExtensionImpl",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LearnAsyncContentTypeDefinition",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progressPercentage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headContentId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "extension",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "unlockInfo",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstContentId",
                  "storageKey": null
                }
              ],
              "type": "UnlockInfoDemo",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "diploma",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AcademiesTagData",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isClickable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isTopic",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "offerCard_ProductFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "extension",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMicroLearning",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "licenseAvailability",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numAvailable",
                          "storageKey": null
                        }
                      ],
                      "type": "LicenseAvailabilityFreeLicensesAvailable",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContractPartner",
                  "kind": "LinkedField",
                  "name": "contractPartner",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContractPartnerData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "PublishedConstellationRootExtensionExtension",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PublishedRootExtensionImpl",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "educationalOfferCard_PublishedCourseLearnableFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v6/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "viewerTreeState",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v8/*: any*/),
                            (v9/*: any*/)
                          ],
                          "type": "IsStartedViewerTreeState",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "type": "LearnOpportunityRootStructureDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PublishedCourseLearnable",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v6/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "viewerTreeState",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v9/*: any*/),
                            (v8/*: any*/)
                          ],
                          "type": "IsStartedViewerTreeState",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "type": "LearnOpportunityRootStructureDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PublishedMicroLearningLearnable",
      "abstractKey": null
    }
  ],
  "type": "PublishedLearnable",
  "abstractKey": "__isPublishedLearnable"
};
})();

(node as any).hash = "74f1f3a860e37ec76ad0c4c3fc35900e";

export default node;
