/**
 * @generated SignedSource<<76694a521310cb7c9147af2ccf321f45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionScreen_ContentSubmissionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AsyncContentSubmissionScreen_ContentSubmissionFragment" | "ELearningContentSubmissionScreen_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionScreen_ContentSubmissionFragment";
};
export type ContentSubmissionScreen_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionScreen_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionScreen_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionScreen_ContentSubmissionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ELearningContentSubmissionScreen_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AsyncContentSubmissionScreen_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "84d06f00e149403103dc4f5acf5c58aa";

export default node;
