/**
 * @generated SignedSource<<f94b9b21253fb128d204919137bed7a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ParticipationStatus = "Absent" | "AbsentExcused" | "NotConfirmed" | "Participated";
import { FragmentRefs } from "relay-runtime";
export type appointmentOverviewModal_AvailabilityFragment$data = {
  readonly booking: {
    readonly offer?: {
      readonly description: {
        readonly name: string;
        readonly tags: ReadonlyArray<{
          readonly data: {
            readonly name: string;
          };
          readonly id: string;
        }>;
      } | null;
    } | null;
    readonly participant?: {
      readonly participationStatus: ParticipationStatus;
    };
    readonly participants?: ReadonlyArray<{
      readonly participationStatus: ParticipationStatus;
    }>;
  };
  readonly coachProfile: {
    readonly coach: {
      readonly id: string;
    } | null;
  } | null;
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentDetails_AvailabilityFragment" | "appointmentPresence_AvailabilityFragment" | "appointmentRating_AvailabilityFragment" | "appointmentStatusLabel_AvailabilityFragment">;
  readonly " $fragmentType": "appointmentOverviewModal_AvailabilityFragment";
};
export type appointmentOverviewModal_AvailabilityFragment$key = {
  readonly " $data"?: appointmentOverviewModal_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentOverviewModal_AvailabilityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CoachingOffer",
  "kind": "LinkedField",
  "name": "offer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachingOfferDescription",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AcademiesTagData",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "participationStatus",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appointmentOverviewModal_AvailabilityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participants",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "type": "BookedForGroup",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participant",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "type": "BookedOneToOne",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachProfile",
      "kind": "LinkedField",
      "name": "coachProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "coach",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appointmentDetails_AvailabilityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appointmentStatusLabel_AvailabilityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appointmentRating_AvailabilityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appointmentPresence_AvailabilityFragment"
    }
  ],
  "type": "Availability",
  "abstractKey": null
};
})();

(node as any).hash = "308cb51ee81b7ec31a3a0293705b3929";

export default node;
