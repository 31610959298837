import { PathParams } from "@thekeytechnology/epic-ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "@hooks/use-auth-context";
import { useToast } from "@hooks/useToast";
import { acceptInvitation_InvitationQuery } from "@relay/acceptInvitation_InvitationQuery.graphql";
import { AcceptInvitationPath, Path } from "@router/paths";
import { selectIsLoggedIn } from "@slices/AuthSlice";
import { INVITATION_QUERY } from "./accept-invitation.graphql";

export const AcceptInvitationScreen = () => {
	const { token } = useParams<PathParams<typeof AcceptInvitationPath>>();
	const navigate = useNavigate();
	const { acceptInvitation } = useAuthContext();
	const { showSuccess, showError } = useToast();
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const { t } = useTranslation("inviteScreens");

	const {
		Auth: { InvitationByToken: invitation },
	} = useLazyLoadQuery<acceptInvitation_InvitationQuery>(INVITATION_QUERY, {
		token: token ?? "",
	});

	useEffect(() => {
		if (!invitation) {
			navigate(Path.acceptInvitation.withId(token ?? "").error.path, { replace: true });
		} else {
			if (isLoggedIn && token) {
				acceptInvitation(token)
					.then(() => {
						const toastText = t("invite_screens.inviteAcceptedSuccessToastText", {
							account: invitation.accountName ?? "",
							invitingUser: invitation.invitingUserName,
						});
						showSuccess({
							summary: t("invite_screens.inviteAcceptedSuccessToastTitle"),
							detail: toastText,
						});
						navigate(Path.progression.path, { replace: true });
					})
					.catch(() => {
						showError({
							summary: t("invite_screens.inviteAcceptedErrorToastTitle"),
							detail: t("invite_screens.inviteAcceptedErrorToastText"),
						});
					});
			} else {
				navigate(Path.login.path, {
					replace: true,
					state: token
						? {
								invitationToken: token,
								redirect: Path.acceptInvitation.withId(token).path,
						  }
						: undefined,
				});
			}
		}
	}, []);

	return null;
};
