import { useFormik } from "formik";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import { ValidatedInputMask } from "@components/validated-input-mask";
import { ValidatedInputText } from "@components/validated-input-text/validated-input-text.component";
import { countryValues } from "@screens/account/parts/account-tab/parts/business-account-form/business-account-form.consts";
import { ResidenceDropdown } from "@screens/account/parts/account-tab/parts/residence-dropdown";
import { DROPDOWN_OPTIONS_ALL } from "@screens/account/parts/account-tab/parts/residence-dropdown/residence-dropdown.consts";
import { Spacing12 } from "@utils/parse-html/parse-html.styles";
import { QUERY_FRAGMENT } from "./business-client-form.graphql";
import {
	InputsWrapper,
	RowSpan2,
	RowSpan4,
	RowSpan6,
	RowSpan6Wrapper,
} from "./business-client-form.styles";
import {
	BusinessClientForm as BusinessClientFormClass,
	BusinessClientFormProps,
	BusinessClientFormState,
	BusinessClientFormValues,
} from "./business-client-form.types";
import { BusinessTypeDropdown } from "../business-type-dropdown";
import { Message } from "../message";
//TODO: add-translations
const BusinessClientFormComponent = (
	{ initialValues, baseDataFragmentRef, onSubmit }: BusinessClientFormProps,
	ref: ForwardedRef<any>,
) => {
	const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef);

	const form = useFormik<BusinessClientFormState>({
		initialValues: {
			company: initialValues?.company || query?.companyName || "",
			recipient: initialValues?.recipient || query?.billingOffice || "",
			email: initialValues?.email || query?.invoiceEmail || "",
			street: initialValues?.street || query?.street || "",
			houseNumber: initialValues?.houseNumber || query?.houseNumber || "",
			city: initialValues?.city || query?.city || "",
			postCode: initialValues?.postCode || query?.postalCode || "",
			country: initialValues?.country || query?.countryCode || "DE",
			phoneNumber: initialValues?.phoneNumber || query?.phoneNumber || "",
			businessType: initialValues?.businessType || query?.companyLegalForm || undefined,
			taxIdentificationNumber:
				initialValues?.taxIdentificationNumber ||
				query?.taxData?.taxIdentificationNumber ||
				"",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			company: Yup.string().required("Bitte gib den Name des Unternehmens ein."),
			recipient: Yup.string().required("Bitt gib eine Rechnungsstelle ein."),
			businessType: Yup.string().required("Bitte wähle eine Unternehmensart aus."),
			email: Yup.string()
				.email("Bitte gib eine gültige E-Mail Adresse ein.")
				.required("Bitte gib eine E-Mail Adresse ein."),
			street: Yup.string().required("Bitte gib eine Straße ein."),
			houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
			city: Yup.string().required("Bitte gib einen Ort ein."),
			postCode: Yup.string()
				.when("country", {
					is: (value: string) => value === "CH" || value === "AT",
					then: (schema) =>
						schema
							.length(4, "Bitte gib eine gültige Postleitzahl ein")
							.matches(/^[0-9]{4}/, "Bitte gib eine gültige Postleitzahl ein"),
				})
				.when("country", {
					is: "DE",
					then: (schema) =>
						schema
							.length(5, "Bitte gib eine gültige Postleitzahl ein")
							.matches(/^[0-9]{5}/, "Bitte gib eine gültige Postleitzahl ein"),
				})
				.required("Bitte gib eine Postleitzahl ein."),
			country: Yup.mixed()
				.oneOf(["DE", "AT", "CH"], "Bitte wähle ein gültiges Land aus.")
				.test(
					"is-not-other",
					"Bestellungen derzeit nur aus Deutschland, Österreich und der Schweiz möglich.",
					(value) => value !== "OTHER",
				)
				.required("Bitte wähle ein Land aus."),
			phoneNumber: Yup.string()
				.phone("DE", "Bitte gib eine gültige Telefonnummer ein.")
				.required("Bitte gib eine Telefonnummer ein."),
			taxIdentificationNumber: Yup.string()
				.when("country", {
					is: "DE",
					then: (schema) =>
						schema.required("Bitte gib eine Umsatzsteuer-Identifikationsnummer ein."),
				})
				.when("country", {
					is: "CH",
					then: (schema) =>
						schema.required("Bitte gib eine Unternehmens-Identifikationsnummer ein."),
				})
				.when("country", {
					is: "AT",
					then: (schema) =>
						schema.required("Bitte gib eine Umsatzsteuer-Identifikationsnummer ein."),
				}),
		}),
		onSubmit: (values) => {
			onSubmit?.(values as BusinessClientFormValues);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
	}));

	const countryValue =
		form.values.country == "CH"
			? countryValues["CH"]
			: form.values.country == "AT"
			? countryValues["AT"]
			: countryValues["DE"];

	const taxIdentificationNumberLabel = countryValue.taxIdentificationNumberLabel;
	const taxIdentificationPlaceholder = countryValue.taxIdentificationPlaceholder;
	const taxIdentificationMask = countryValue.taxIdentificationMask;

	const previousCountryRef = useRef(form.values.country);
	useEffect(() => {
		if (previousCountryRef.current !== form.values.country) {
			form.setFieldValue("taxIdentificationNumber", "");
			previousCountryRef.current = form.values.country;
		}
	}, [form.values.country, query?.taxData?.taxIdentificationNumber]);

	return (
		<form onSubmit={form.handleSubmit}>
			<InputsWrapper>
				<RowSpan6>
					<ValidatedInputText
						formikConfig={form}
						name="company"
						label={"Name des Unternehmens*"}
						placeholder={"Name des Unternehmens (inkl. Geschäftsform)"}
					/>
				</RowSpan6>
				<RowSpan6>
					<ValidatedInputText
						formikConfig={form}
						name="recipient"
						label={"Rechnungsstelle oder Empfänger:in*"}
						placeholder={"Rechnungsstelle oder Empfänger:in"}
					/>
				</RowSpan6>
				<RowSpan6>
					<BusinessTypeDropdown
						formikConfig={form}
						name="businessType"
						label="Unternehmensart*"
						placeholder="Unternehmensart"
					/>
				</RowSpan6>
				<RowSpan6>
					<ValidatedInputText
						formikConfig={form}
						name="email"
						label={"E-Mail für Rechnung*"}
						placeholder={"E-Mail für Rechnung"}
					/>
				</RowSpan6>
				<RowSpan6Wrapper>
					<RowSpan4>
						<ValidatedInputText
							formikConfig={form}
							name="street"
							label={"Straße*"}
							placeholder={"Straße"}
						/>
					</RowSpan4>
					<RowSpan2>
						<ValidatedInputText
							formikConfig={form}
							name="houseNumber"
							label={"Hausnummer*"}
							placeholder={"Hausnummer"}
						/>
					</RowSpan2>
				</RowSpan6Wrapper>

				<RowSpan6Wrapper>
					<RowSpan4>
						<ValidatedInputText
							formikConfig={form}
							name="city"
							label={"Ort/Stadt*"}
							placeholder={"Ort/Stadt"}
						/>
					</RowSpan4>
					<RowSpan2>
						<ValidatedInputText
							formikConfig={form}
							name="postCode"
							label={"PLZ*"}
							placeholder={"PLZ"}
						/>
					</RowSpan2>
				</RowSpan6Wrapper>
				<RowSpan6>
					<ResidenceDropdown
						formikConfig={form}
						name="country"
						label="Land"
						disabled
						options={DROPDOWN_OPTIONS_ALL}
					/>
					<Spacing12 />
					{form.values.country === "OTHER" && (
						<Message
							highlighted
							severity="neutral"
							summary="Auslandsbestellungen"
							detail="Aktuell sind Bestellungen nur innerhalb Deutschlands, Österreichs und der Schweiz möglich. Möchtest du aus dem Ausland bestellen? Dann wende dich bitte an info@constellation.academy."
						/>
					)}
				</RowSpan6>
				<RowSpan6>
					<ValidatedInputText
						formikConfig={form}
						name="phoneNumber"
						label={"Telefonnummer*"}
						placeholder={"+4917611223344"}
					/>
				</RowSpan6>
				<RowSpan6>
					<ValidatedInputMask
						formikConfig={form}
						name="taxIdentificationNumber"
						label={taxIdentificationNumberLabel}
						placeholder={taxIdentificationPlaceholder}
						mask={taxIdentificationMask}
					/>
				</RowSpan6>
			</InputsWrapper>
		</form>
	);
};

export const BusinessClientForm = forwardRef(BusinessClientFormComponent);
export type BusinessClientForm = BusinessClientFormClass;
