import graphql from "babel-plugin-relay/macro";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment branchNodesTree_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					image {
						url
					}
					structureDefinition {
						title
					}
					indexPath
					typeDefinition {
						... on LearnOpportunityBranchTypeDefinition {
							children {
								id
								image {
									url
								}
								shortDescription
								structureDefinition {
									title
								}
								indexPath
								lengthInSecondsV2
								...contentCard_LearnOpportunityV2Fragment
							}
						}
					}
				}
			}
		}
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				viewerTreeState {
					kind
					... on IsStartedViewerTreeState {
						headContentId
					}
				}
				extension {
					... on PublishedRootExtensionImpl {
						unlockInfo {
							kind
							... on UnlockInfoDemo {
								firstContentId
								firstContentCoordinatesIndexPath
							}
						}
					}
				}
			}
		}
	}
`;
