import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { Icon } from "@components/icon";
import { startVocabTrainingButton_StartMutation } from "@relay/startVocabTrainingButton_StartMutation.graphql";
import { Path } from "@router/paths";
import { START_VOCAB_TRAINING_MUTATION } from "@screens/vocabulary/parts/start-vocab-training-button/start-vocab-training-button.graphql";
import { StartVocabTrainingButtonProps } from "@screens/vocabulary/parts/start-vocab-training-button/start-vocab-training-button.types";
import { parseCompoundKey } from "@screens/vocabulary/parts/word-card/word-card.utils";
import { selectAmountOfQuestions, selectIsLearnMode, setLearnMode } from "@slices/vocabulary.slice";
import { onBrandMain } from "@themes/colors";

//@TODO: contentful
export const StartVocabTrainingButton = ({ selectedItems }: StartVocabTrainingButtonProps) => {
	const isLearnMode = useSelector(selectIsLearnMode);
	const amountOfQuestions = useSelector(selectAmountOfQuestions);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [startVocabTraining, isStartingVocabTraining] =
		useMutation<startVocabTrainingButton_StartMutation>(START_VOCAB_TRAINING_MUTATION);

	const handleOnClick = () => () => {
		if (!isLearnMode) {
			dispatch(setLearnMode({ isLearnMode: true }));
		} else {
			startVocabTraining({
				variables: {
					input: {
						words: selectedItems.map((selectedItem) => {
							const parsedKey = parseCompoundKey(selectedItem);
							return {
								wordId: parsedKey.wordId,
								packageId: parsedKey.wordPackageId,
							};
						}),
						numWords: amountOfQuestions ?? 200,
					},
				},
				onCompleted: (e) => {
					navigate(
						Path.contentSubmission.withId(
							e.Vocab.startStandaloneVocabTraining?.contentSubmission.id ?? "",
						).path,
					);
					dispatch(setLearnMode({ isLearnMode: false }));
				},
			});
		}
	};

	const selectedAmountOfWords =
		amountOfQuestions && selectedItems.length > amountOfQuestions
			? amountOfQuestions
			: selectedItems.length;

	return (
		<Button
			disabled={(isLearnMode && selectedItems.length === 0) || isStartingVocabTraining}
			icon={<Icon tkaColor={onBrandMain} sizeInRem={0.8} icon="arrowRight" />}
			label={
				isLearnMode
					? `${selectedAmountOfWords} Vokabel${
							selectedAmountOfWords === 1 ? "" : "n"
					  } Lernen`
					: "Lernen"
			}
			onClick={handleOnClick()}
		/>
	);
};
