import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";

export const Wrapper = tw.div`
	flex
	flex-col
	justify-center
	items-center
   	gap-24
	overflow-hidden
`;

export const IconWrapper = styled.div<{ backgorundColor: TkaColor }>`
	${tw`
		relative
		min-w-[6rem]
		min-h-[6rem]
		rounded-[3rem]
		flex
		justify-center
		items-center
	`}
	background-color: ${({ backgorundColor }) => backgorundColor};
`;

export const TextWrapper = tw.div`
	flex
	flex-col
	gap-8
	text-center
	max-w-[20rem]
	whitespace-pre-wrap
`;

export const IconBottomNodeWrapper = tw.div`
	absolute
	bottom-0
	translate-y-1/4
`;
