import tw from "twin.macro";

export const List = tw.ul`
  space-y-8
`;

export const Item = tw.li`
  flex
  items-center
  gap-8
`;
