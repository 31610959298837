import { createContext } from "react";
import { SelectedGroup } from "./groups-tab.types";

type GroupsTabContext = {
	deletableGroupIds?: string[];
	deleteGroupIds: (ids: string[]) => void;
	selectedGroups: SelectedGroup[];
	toogleGroup: (group: SelectedGroup) => void;
	clearSelectedGroups: () => void;
	numberOfGroups?: number;
	setNumberOfGroups?: (numberOfGroups: number) => void;
};

export const GroupsTabContext = createContext<GroupsTabContext>({
	deleteGroupIds: () => {},
	selectedGroups: [],
	toogleGroup: () => {},
	clearSelectedGroups: () => {},
	numberOfGroups: 0,
	setNumberOfGroups: () => {},
});
