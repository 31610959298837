import { Carousel } from "primereact/carousel";
import styled from "styled-components";
import { shade80, shade10 } from "@themes/colors";

export const StyledCarousel = styled(Carousel)`
	.p-carousel-items-container {
		justify-content: unset;
		align-items: unset;
	}

	&.p-carousel .p-carousel-content .p-carousel-prev.p-link,
	&.p-carousel .p-carousel-content .p-carousel-next.p-link {
		display: none;
	}

	.p-carousel-indicators {
		padding-top: 1.25rem;
	}

	.p-carousel-indicators .p-carousel-indicator {
		margin-right: 0.25rem;
	}

	.p-carousel-indicators.p-reset .p-carousel-indicator button {
		background-color: ${shade10};
		width: 6px;
		height: 6px;
		border-radius: 100%;
	}

	.p-carousel-indicators.p-reset .p-carousel-indicator.p-highlight button {
		background-color: ${shade80};
	}
`;
