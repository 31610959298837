import { graphql } from "babel-plugin-relay/macro";

export const WORD_INVENTORY_FRAGMENT = graphql`
	fragment wordPackageCard_WordInventoryFragment on WordInventory {
		packages {
			wordPackage {
				id
				name
				language
				associatedWords {
					id
					...wordCard_WordFragment
				}
			}
		}
		proficiencies {
			proficiencyLevel
			wordId
			...wordCard_WordProficiencyFragment
		}
		notes {
			wordId
			...wordCard_WordNoteFragment
		}
	}
`;
