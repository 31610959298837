import styled from "styled-components";
import tw from "twin.macro";

export const ScreenWrapper = tw.div`
	flex
	flex-col
	h-full
	gap-32
	p-24
	md:p-40
	overflow-y-scroll
`;

export const HeaderWrapper = tw.div`
	flex
	flex-col
	gap-12
`;

export const ClientTypeWrapper = tw.div`
	flex
	flex-row
	gap-32
	items-center
`;

export const Divider = tw.div`	
	min-h-[1px]
	self-stretch
	bg-shade-10
`;

export const HideablePrivateWrapper = styled.div<{ hidden: boolean }>`
	${tw`flex flex-col gap-32`}
	${({ hidden }) => hidden && tw`hidden`}
`;

export const HideableWrapper = styled.div<{ hidden: boolean }>`
	${tw`flex flex-col gap-16`}
	${({ hidden }) => hidden && tw`hidden`}
`;

export const Center = tw.div`
	flex
	flex-col
	flex-1
	items-center
`;

export const MinWidth = tw.div`
	flex
	flex-col
	min-w-[min(20rem,100%)]
	md:min-w-[20rem]
`;
