/**
 * @generated SignedSource<<cafde56fbdc75db38e5b306b942a6be7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordPackageCard_WordInventoryFragment$data = {
  readonly notes: ReadonlyArray<{
    readonly wordId: string;
    readonly " $fragmentSpreads": FragmentRefs<"wordCard_WordNoteFragment">;
  }>;
  readonly packages: ReadonlyArray<{
    readonly wordPackage: {
      readonly associatedWords: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"wordCard_WordFragment">;
      }>;
      readonly id: string;
      readonly language: string;
      readonly name: string;
    } | null;
  }>;
  readonly proficiencies: ReadonlyArray<{
    readonly proficiencyLevel: number;
    readonly wordId: string;
    readonly " $fragmentSpreads": FragmentRefs<"wordCard_WordProficiencyFragment">;
  }>;
  readonly " $fragmentType": "wordPackageCard_WordInventoryFragment";
};
export type wordPackageCard_WordInventoryFragment$key = {
  readonly " $data"?: wordPackageCard_WordInventoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"wordPackageCard_WordInventoryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wordId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "wordPackageCard_WordInventoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PackageUnlock",
      "kind": "LinkedField",
      "name": "packages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WordPackage",
          "kind": "LinkedField",
          "name": "wordPackage",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "language",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Word",
              "kind": "LinkedField",
              "name": "associatedWords",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "wordCard_WordFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WordProficiency",
      "kind": "LinkedField",
      "name": "proficiencies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "proficiencyLevel",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "wordCard_WordProficiencyFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WordNote",
      "kind": "LinkedField",
      "name": "notes",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "wordCard_WordNoteFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WordInventory",
  "abstractKey": null
};
})();

(node as any).hash = "11d21145712854cbf4a7311c4679456d";

export default node;
