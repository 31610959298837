import { ButtonSkeleton } from "@components/button/button.skeleton";
import { Card } from "@components/card";

import { Skeleton } from "@components/skeleton";
import { spacing8 } from "@themes/spacing";
import { TimeWrapper, Wrapper } from "./time-slot-card.styles";

// TODO: add-translations
export const TimeSlotCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={spacing8.remValue()} borderRadiusRem={7.5}>
			<Wrapper>
				<TimeWrapper>
					<Skeleton width="4rem" height="1.5rem" />
				</TimeWrapper>
				<ButtonSkeleton />
			</Wrapper>
		</Card>
	);
};
