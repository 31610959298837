import { graphql } from "babel-plugin-relay/macro";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment accountSelectionPanel_AuthViewerSchemaFragment on AuthViewerSchema {
		currentUser {
			user {
				name
			}
			accounts {
				id
				name
			}
		}
	}
`;
