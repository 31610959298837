import { MiddlewareNextFn, RelayRequestAny } from "react-relay-network-modern";
import { ERROR_MESSAGES, LOGOUT_ERRORS } from "@i18n/ERROR_MESSAGES";
import { logout } from "@slices/AuthSlice";
import { ReduxStore } from "@store/index";

const resolveErrorMessage = (errorKey: string): string =>
	ERROR_MESSAGES[errorKey] ?? "Ein unbekannter Fehler ist aufgetreten.";

export const ErrorHandlingMiddleware =
	(
		next: MiddlewareNextFn,
		showErrorToast: (errorMessage: string) => void,
		t: (label: string) => string,
	) =>
	async (req: RelayRequestAny) => {
		const res = await next(req);
		const errors = res.errors?.map((error) => error.message?.toLowerCase()) || [];
		const needsToLogout = errors.some((error) => error in LOGOUT_ERRORS);
		if (needsToLogout) {
			ReduxStore.dispatch(logout());
		}
		errors?.forEach((errorKey) => {
			const message = resolveErrorMessage(errorKey);
			showErrorToast(t(message));
		});

		return res;
	};
