import { OverlayPanel as PROverlayPanel } from "primereact/overlaypanel";
import { ForwardedRef, forwardRef } from "react";
import { OverlayPanelStyle, StyledOverlayPanel } from "./overlay-panel.styles";
import { OverlayPanelProps } from "./overlay-panel.types";

const OverlayPanelComponent = (
	{ padding, borderRadius, children }: OverlayPanelProps,
	ref: ForwardedRef<PROverlayPanel>,
) => (
	<StyledOverlayPanel ref={ref} padding={padding} borderRadius={borderRadius}>
		{children}
		<OverlayPanelStyle />
	</StyledOverlayPanel>
);

export const OverlayPanel = forwardRef(OverlayPanelComponent);
export type OverlayPanel = PROverlayPanel;
