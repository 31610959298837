import graphql from "babel-plugin-relay/macro";

export const LEARN_POTENTIAL_ANALYSIS_FRAGMENT = graphql`
	fragment avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment on AVGSPotentialAnalysis_LearnPotentialAnalysis {
		modules {
			dimensions {
				dimensionId
				questions {
					id
					kind
					... on PotentialAnalysisMultipleChoiceQuestion {
						answerOptions {
							answerOptionId
						}
						givenAnswers {
							kind
							answerOptionId
						}
					}
					... on PotentialAnalysisMultipleChoiceFreetextQuestion {
						answerOptions {
							answerOptionId
							isFreeText
						}
						givenAnswers {
							kind
							answerOptionId
							freeText
						}
					}
					... on PotentialAnalysisSingleChoiceQuestion {
						answerOptions {
							answerOptionId
						}
						givenAnswer {
							kind
							answerOptionId
						}
					}
					... on PotentialAnalysisSingleChoiceFreetextQuestion {
						answerOptions {
							answerOptionId
							isFreeText
						}
						givenAnswer {
							kind
							answerOptionId
							freeText
						}
					}
					... on PotentialAnalysisScaleQuestion {
						answerOptions {
							answerOptionId
						}
						givenAnswer {
							kind
							answerOptionId
						}
					}
					... on PotentialAnalysisTextQuestion {
						givenAnswer {
							kind
							text
						}
					}
				}
			}
		}
	}
`;
