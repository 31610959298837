import { useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { Card } from "@components/card/card.component";
import { invitationCard_DeleteInvitationMutation } from "@relay/invitationCard_DeleteInvitationMutation.graphql";
import { AccountContext } from "@screens/account";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { shade20, shade5 } from "@themes/colors";
import { TkaH3Span } from "@themes/font-tags";
import { DELETE_INVITATION_MUTATION, INVITATION_FRAGMENT } from "./invitation-card.graphql";
import { DeleteIconWrapper, NameAndGroupsWrapper, Wrapper } from "./invitation-card.style";
import { InvitationCardProps } from "./invitation-card.types";
import { Checkbox, CheckboxStatus } from "../checkbox";
import { Icon } from "../icon";
import { Label } from "../label";
import { MaterialIcon } from "../material-icon";
import { LabelsWrapper } from "../user-card/user-card.styles";

export const InvitationCard = ({ invitationFragmentRef }: InvitationCardProps) => {
	const invitation = useFragment(INVITATION_FRAGMENT, invitationFragmentRef ?? null);
	const [deleteInvitation] = useMutation<invitationCard_DeleteInvitationMutation>(
		DELETE_INVITATION_MUTATION,
	);

	const { membersConnectionIds } = useContext(AccountContext);
	const { refetch } = useContext(RefetchContext);

	const handleDeleteOnClick = () => {
		invitation?.invitation?.id &&
			deleteInvitation({
				variables: {
					input: {
						id: invitation?.invitation.id,
					},
					connections: membersConnectionIds,
				},
				onCompleted: refetch,
			});
	};

	return (
		<Card bodyPaddingInRem={0.75} borderColor={shade20}>
			<Wrapper>
				<Checkbox checked={false} status={CheckboxStatus.Disabled} />
				<MaterialIcon
					icon="user"
					iconColor={shade20}
					showBorder={false}
					backgroundColor={shade5}
				/>
				<NameAndGroupsWrapper>
					<TkaH3Span tkaColor={shade20}>{invitation?.invitation?.email}</TkaH3Span>
					<LabelsWrapper>
						{invitation?.groups?.map((group: any) => (
							<Label key={group.id} size="smal" label={group.name} severity="info" />
						))}
					</LabelsWrapper>
				</NameAndGroupsWrapper>

				<DeleteIconWrapper>
					<Icon icon="wrong" sizeInRem={1} onClick={handleDeleteOnClick} />
				</DeleteIconWrapper>
			</Wrapper>
		</Card>
	);
};
