import graphql from "babel-plugin-relay/macro";

export const USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT = graphql`
	fragment groupContextMenu_UserInAccountGroupWrapperFragment on UserInAccountGroupWrapper {
		group {
			id
			name
			isBuiltIn
			...editGroupModal_UserInAccountGroupFragment
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment groupContextMenu_QueryFragment on Query
	@refetchable(queryName: "groupContextMenuRefetchQuery") {
		...inviteMemberModal_QueryFragment
	}
`;
