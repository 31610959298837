import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import RewardChestPNG from "@assets/reward-chest.svg";
import { DialogTemplate } from "@components/dialog-template";
import { MicroLearningRewardCard } from "@components/micro-learning-reward-card";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { microLearningReward_AcknowledgePotentialAnalysisMaxRewardMutation } from "@relay/microLearningReward_AcknowledgePotentialAnalysisMaxRewardMutation.graphql";
import { Path } from "@router/paths";
import { OffersLocationState } from "@screens/offers/offers.types";
import { selectFirstLogin, selectIsLoggedIn } from "@slices/AuthSlice";
import { shade80 } from "@themes/colors";
import { H1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import {
	ACKNOWLEDGE_POTENTIAL_ANALYSIS_MAX_REWARD_MUTATION,
	VIEWER_QUERY_TYPE_FRAGMENT,
} from "./micro-learning-reward.graphql";
import {
	ContentWrapper,
	HeaderImage,
	PaddingWrapper,
	TitleWrapper,
} from "./micro-learning-reward.styles";
import { MicroLearningRewardProps } from "./micro-learning-reward.types";

// TODO: add-translations
export const MicroLearningReward = ({ viewerQueryTypeFragmentRef }: MicroLearningRewardProps) => {
	const viewer = useFragment(VIEWER_QUERY_TYPE_FRAGMENT, viewerQueryTypeFragmentRef ?? null);
	const [acknowledgePotentialAnalysisMaxReward] =
		useMutation<microLearningReward_AcknowledgePotentialAnalysisMaxRewardMutation>(
			ACKNOWLEDGE_POTENTIAL_ANALYSIS_MAX_REWARD_MUTATION,
		);

	const navigate = useNavigate();

	const firstLogin = useSelector(selectFirstLogin);
	const isLoggedIn = useSelector(selectIsLoggedIn);

	const potentialAnalysisMaxRewardAcknowledged =
		viewer?.Auth.currentUser?.user?.extension?.potentialAnalysisMaxRewardAcknowledged ?? false;
	const hasLongAnalysis =
		viewer?.PotentialAnalysis.MyPotentialAnalysisResult?.isLongAnalysis === true;

	const isPotentialAnalysisMaxReward = !potentialAnalysisMaxRewardAcknowledged && hasLongAnalysis;
	const isSignUpReward = isLoggedIn && firstLogin;

	const showMicroLearningReward = isSignUpReward || isPotentialAnalysisMaxReward;

	const [isVisible, setIsVisible] = useState(showMicroLearningReward);

	useEffect(() => {
		if (showMicroLearningReward && !isVisible) {
			setIsVisible(true);
		}
	}, [showMicroLearningReward]);

	const handleOnHide = () => {
		setIsVisible(false);
		isPotentialAnalysisMaxReward &&
			acknowledgePotentialAnalysisMaxReward({ variables: { input: {} } });
	};

	const handleGoToLicensesOnClick = () => {
		handleOnHide();
		navigate(Path.profileMenu.licenses.path);
	};

	const handleUseLicenseOnClick = () => {
		handleOnHide();
		const state: Partial<OffersLocationState> = {
			state: { filters: { kinds: ["MicroLearning"] } },
		};
		navigate(Path.progression.path, state);
	};

	return (
		<ResponsiveBottomSheetModal hasCloseIcon isVisible={isVisible} onDismiss={handleOnHide}>
			<PaddingWrapper>
				<DialogTemplate
					hasSeperator
					secondaryButtonLabel="Zu meinen Lizenzen"
					onSecondaryButtonClick={handleGoToLicensesOnClick}
					primaryButtonLabel="Lizenz einlösen"
					onPrimaryButtonClick={handleUseLicenseOnClick}
				>
					<HeaderImage src={RewardChestPNG} />
					<ContentWrapper>
						<TitleWrapper>
							<H1Span>Neue Lizenz Verfügbar</H1Span>
							<TkaP3Span tkaColor={shade80}>Glückwunsch!</TkaP3Span>
						</TitleWrapper>
						<TkaP2Span tkaColor={shade80}>
							Dir wurde eine neue Lizenz zugewiesen, die jetzt bereit ist, eingelöst
							zu werden. Nutze diese Chance, um auf erweiterte Lerninhalte zuzugreifen
							und dein Wissen zu vertiefen.
						</TkaP2Span>
						<MicroLearningRewardCard />
					</ContentWrapper>
				</DialogTemplate>
			</PaddingWrapper>
		</ResponsiveBottomSheetModal>
	);
};
