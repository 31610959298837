import tw from "twin.macro";
import { Card } from "@components/card/card.component";

import { CardWrapper, Content, TagsRow, Wrapper } from "./root-card.styles";
import { LabelSkeleton } from "../label/label.skeleton";
import { Skeleton } from "../skeleton";

export const RootCardSkeleton = ({ maxWidth }: { maxWidth?: number }) => {
	const imageWidth = "4.375rem";

	return (
		<CardWrapper maxWidth={maxWidth}>
			<Card fillParent bodyPaddingInRem={0.75} hasOverflowHidden>
				<Wrapper>
					<AbsoluteWrapper>
						<Skeleton width={imageWidth} height="100%" />
					</AbsoluteWrapper>
					<Content>
						<TagsRow>
							<LabelSkeleton />
							<LabelSkeleton />
						</TagsRow>
						<Skeleton width="80%" />
					</Content>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};

const AbsoluteWrapper = tw.div`absolute h-full`;
