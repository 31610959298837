import { AppointmentCardSkeleton } from "@components/appointment-card/appointment-card.skeleton";
import { ProfileMenu } from "@components/profile-menu";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithNavbarSidemenuLayout } from "@layouts/screen-with-navbar-sidemenu-layout";

import { H1Span } from "@themes/font-tags";
import { AppointmentsWrapper, Wrapper } from "./appointments.styles";

// TODO: remove-translations
export const AppointmentsScreenSkeleton = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScreenWithNavbarSidemenuLayout canGoBack={!isMediumUp} sideMenu={<ProfileMenu />}>
			<Wrapper>
				<H1Span>Meine Termine</H1Span>
				<AppointmentsWrapper>
					<AppointmentCardSkeleton />
					<AppointmentCardSkeleton />
					<AppointmentCardSkeleton />
					<AppointmentCardSkeleton />
					<AppointmentCardSkeleton />
					<AppointmentCardSkeleton />
					<AppointmentCardSkeleton />
				</AppointmentsWrapper>
			</Wrapper>
		</ScreenWithNavbarSidemenuLayout>
	);
};
