/**
 * @generated SignedSource<<47b345a87480b039d5beaf188ade969d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type instructorAbout_InstructorFragment$data = {
  readonly description?: string | null;
  readonly image?: {
    readonly url: string | null;
  } | null;
  readonly name: string;
  readonly shortDescription?: string | null;
  readonly " $fragmentType": "instructorAbout_InstructorFragment";
};
export type instructorAbout_InstructorFragment$key = {
  readonly " $data"?: instructorAbout_InstructorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"instructorAbout_InstructorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "instructorAbout_InstructorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InstructorImpl",
      "abstractKey": null
    }
  ],
  "type": "Instructor",
  "abstractKey": "__isInstructor"
};

(node as any).hash = "21d5975469dbfa9f1b82c15873778e73";

export default node;
