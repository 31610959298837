import { FC, useEffect, useRef, cloneElement } from "react";
import { useWindowSize } from "@hooks/use-window-size";
import { Container } from "./scrollable-container.styles";
import { ScrollableContainerProps } from "./scrollable-container.types";

export const ScrollableContainer: FC<ScrollableContainerProps> = ({
	startAt = "left",
	children,
	...props
}) => {
	const { windowWidth } = useWindowSize();
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (containerRef.current) {
			switch (startAt) {
				case "center":
					containerRef.current.scrollLeft =
						containerRef.current.scrollWidth / 2 - containerRef.current.clientWidth / 2;
					break;
				case "right":
					containerRef.current.scrollLeft = containerRef.current.scrollWidth;
					break;
				default:
					containerRef.current.scrollLeft = 0;
			}
		}
	}, [startAt, windowWidth]);

	const newChildren = cloneElement(children, { ref: containerRef });

	return <Container {...props}>{newChildren}</Container>;
};
