/**
 * @generated SignedSource<<51fccc2c54d9a8fc1dbe896d9b584329>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editWordNoteModal_WordNoteFragment$data = {
  readonly note: string;
  readonly wordId: string;
  readonly " $fragmentType": "editWordNoteModal_WordNoteFragment";
};
export type editWordNoteModal_WordNoteFragment$key = {
  readonly " $data"?: editWordNoteModal_WordNoteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editWordNoteModal_WordNoteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editWordNoteModal_WordNoteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wordId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    }
  ],
  "type": "WordNote",
  "abstractKey": null
};

(node as any).hash = "a360a9232fd6a979db94136b85d534df";

export default node;
