import graphql from "babel-plugin-relay/macro";

export const USER_FRAGMENT = graphql`
	fragment userContextMenu_UserFragment on User {
		id
		name
		email
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment userContextMenu_QueryFragment on Query
	@refetchable(queryName: "userContextMenuRefetchQuery") {
		...assignGroupModal_QueryFragment
	}
`;
