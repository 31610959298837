import { useEffect, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import JobTalentImg from "@assets/talent-job-matching.png";
import { Button } from "@components/button";
import { usePermissions } from "@hooks/use-permissions";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { navbar_ClickoutMutation } from "@relay/navbar_ClickoutMutation.graphql";
import { placement_Query } from "@relay/placement_Query.graphql";
import { shade0 } from "@themes/colors";
import { TkaB1Span, H1Span, TkaP2Span } from "@themes/font-tags";
import { InfoModal } from "./parts/info-modal";
import { InfoModalCorporate } from "./parts/info-modal-corporate";
import { CLICKOUT_MUTATION, QUERY } from "./placement.graphql";
import { ContentWrapper, DecorativeBanner, Wrapper } from "./placement.styles";

//TODO: add-translations
export const PlacementScreen = () => {
	const { isBusinessAccount, hasPlacementPermission } = usePermissions();
	const placementEnabled = process.env.REACT_APP_PLACEMENT_ENABLED === "true" || false;
	const skip = !isBusinessAccount || !placementEnabled;

	const [redirect, setRedirect] = useState(false);

	const data = useLazyLoadQuery<placement_Query>(
		QUERY,
		{ skip: skip },
		{ fetchPolicy: "store-and-network" },
	);

	const [createClickOutForUserOrBusiness] =
		useMutation<navbar_ClickoutMutation>(CLICKOUT_MUTATION);

	// temporarily disable checking for potential analysis
	// const hasDonePotentialAnalysisMAX =
	// 	data?.Viewer?.PotentialAnalysis?.MyPotentialAnalysisResult?.isLongAnalysis;

	const redirectToPlacement = () => {
		createClickOutForUserOrBusiness({
			variables: {
				input: {},
			},
			onCompleted: (response) => {
				window.location.href =
					response.Placement.createClickOutForUserOrBusiness?.placementUrl || "/";
				window.history.pushState({}, "", "/progression");
			},
		});
	};

	useEffect(() => {
		if (
			(placementEnabled && isBusinessAccount && hasPlacementPermission) ||
			// (placementEnabled && !isBusinessAccount && hasDonePotentialAnalysisMAX)
			(placementEnabled && !isBusinessAccount)
		) {
			setRedirect(true);
			redirectToPlacement();
		}
	}, [placementEnabled, isBusinessAccount, hasPlacementPermission]);

	const handleOnClick = () => {
		window.location.href = "mailto:jobs@constellation.academy";
	};

	return (
		<ScreenWithNavbarLayout>
			<Wrapper>
				<DecorativeBanner>
					<TkaB1Span tkaColor={shade0}>
						SAVE THE <br /> DATE <br /> 22. & 24. <br /> Mai 2024
					</TkaB1Span>
				</DecorativeBanner>
				<ContentWrapper>
					<img src={JobTalentImg} alt="Job Talent Matching" />
					<H1Span>Talente und Wunschjob zusammenbringen!</H1Span>
					<TkaP2Span>
						Du suchst deinen Wunschjob? <br />
						Ihr sucht motivierte, kompetente Mitarbeitende für euer Team? <br />
						Wir bringen euch zusammen.
					</TkaP2Span>
					<TkaP2Span>
						Mit einem <b>digitalen Matching</b> finden wir ideale Passungen, die neben
						den klassischen Aspekten auch Lebensphasen, Zukunftskompetenzen und
						präferierte Arbeitsformen berücksichtigen. Menschen wie Unternehmen
						entscheiden, ob sie den
						<b> Perfect</b> oder <b>Imperfect Match</b> (inklusive einer Weiterbildung)
						wollen.
					</TkaP2Span>
					<TkaP2Span>
						Lernt alle potenziellen Matches persönlich kennen. Nach nur zwei Tagen ist
						die Entscheidung für eine gemeinsame Zukunft möglich.
					</TkaP2Span>
					<TkaP2Span bold>
						Interessiert?
						<br />
						<TkaB1Span>Jetzt anmelden und Platz sichern!</TkaB1Span>
					</TkaP2Span>
					<TkaP2Span bold>
						Berufsgruppen:
						<br />
						<TkaB1Span>
							Fachinformatik & Data Science <br />
							Marketing & Kommunikation <br />
							Business Administration <br />
							Gesundheit & Mental Health <br />
							Verwaltung
						</TkaB1Span>
					</TkaP2Span>
					<TkaP2Span>
						Suchst du in diesem Bereich deinen Wunschjob bzw. habt ihr einen solchen
						anzubieten? Meldet euch schon heute an und wir halten euch auf dem
						Laufenden!
					</TkaP2Span>
					<Button label="Kontaktiere uns" onClick={handleOnClick} />
				</ContentWrapper>
			</Wrapper>
			{placementEnabled && !redirect && (
				<>
					{isBusinessAccount ? (
						<InfoModalCorporate queryFragmentRef={data} />
					) : (
						<InfoModal />
					)}
				</>
			)}
		</ScreenWithNavbarLayout>
	);
};
