/**
 * @generated SignedSource<<4e1ded36e819a1b225e8a5b9ac4c3645>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PotentialAnalysisGivenAnswerKind = "SelectedWithFreetext" | "Text" | "selected";
export type PotentialAnalysisQuestionDataKind = "MULTIPLE_CHOICE" | "MULTIPLE_CHOICE_FREETEXT" | "SCALE" | "SINGLE_CHOICE" | "SINGLE_CHOICE_FREETEXT" | "TEXT";
import { FragmentRefs } from "relay-runtime";
export type avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment$data = {
  readonly modules: ReadonlyArray<{
    readonly dimensions: ReadonlyArray<{
      readonly dimensionId: number;
      readonly questions: ReadonlyArray<{
        readonly answerOptions?: ReadonlyArray<{
          readonly answerOptionId: number;
          readonly isFreeText: boolean;
        }>;
        readonly givenAnswer?: {
          readonly answerOptionId: number;
          readonly freeText?: string | null;
          readonly kind: PotentialAnalysisGivenAnswerKind;
          readonly text?: string;
        } | null;
        readonly givenAnswers?: ReadonlyArray<{
          readonly answerOptionId: number;
          readonly freeText?: string | null;
          readonly kind: PotentialAnalysisGivenAnswerKind;
        }>;
        readonly id: string;
        readonly kind: PotentialAnalysisQuestionDataKind;
      }>;
    }>;
  }>;
  readonly " $fragmentType": "avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment";
};
export type avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment$key = {
  readonly " $data"?: avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerOptionId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PotentialAnalysisSelectAnswerOption",
  "kind": "LinkedField",
  "name": "answerOptions",
  "plural": true,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PotentialAnalysisFreetextAnswerOption",
  "kind": "LinkedField",
  "name": "answerOptions",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFreeText",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "freeText",
    "storageKey": null
  }
],
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "PotentialAnalysisSelectedGivenAnswer",
    "kind": "LinkedField",
    "name": "givenAnswer",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AVGSPotentialAnalysis_Module",
      "kind": "LinkedField",
      "name": "modules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AVGSPotentialAnalysis_Dimension",
          "kind": "LinkedField",
          "name": "dimensions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dimensionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "questions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PotentialAnalysisSelectedGivenAnswer",
                      "kind": "LinkedField",
                      "name": "givenAnswers",
                      "plural": true,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "PotentialAnalysisMultipleChoiceQuestion",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PotentialAnalysisSelectedWithFreetextGivenAnswer",
                      "kind": "LinkedField",
                      "name": "givenAnswers",
                      "plural": true,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "PotentialAnalysisMultipleChoiceFreetextQuestion",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "PotentialAnalysisSingleChoiceQuestion",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PotentialAnalysisSelectedWithFreetextGivenAnswer",
                      "kind": "LinkedField",
                      "name": "givenAnswer",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "PotentialAnalysisSingleChoiceFreetextQuestion",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "PotentialAnalysisScaleQuestion",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PotentialAnalysisTextGivenAnswer",
                      "kind": "LinkedField",
                      "name": "givenAnswer",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "text",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "PotentialAnalysisTextQuestion",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AVGSPotentialAnalysis_LearnPotentialAnalysis",
  "abstractKey": null
};
})();

(node as any).hash = "eb12b6d3564192dbe26039c3f0759a04";

export default node;
