export const JobTitlesOptions = [
	{ label: "Life-Coach", value: "Life-Coach" },
	{ label: "Laufbahncoach", value: "Laufbahncoach" },
	{ label: "Businesscoach", value: "Businesscoach" },
	{ label: "Führungscoach", value: "Führungscoach" },
	{ label: "Gesundheitscoach", value: "Gesundheitscoach" },
	{ label: "Supervisor:in", value: "Supervisor:in" },
	{ label: "Mediator:in", value: "Mediator:in" },
	{ label: "Weitere", value: "Weitere" },
];
