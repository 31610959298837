import { FC } from "react";
import { useFragment } from "react-relay";
import { Icon } from "@components/icon";
import { trendUspBoxes_TrendUspBoxesFragment$key } from "@relay/trendUspBoxes_TrendUspBoxesFragment.graphql";
import { TkaP2Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./trend-usp-boxes.graphql";
import {
	IconWrapper,
	ItemTitle,
	ItemWrapper,
	TextWrapper,
	Wrapper,
} from "./trend-usp-boxes.styles";
import type { TrendUspBoxesProps } from "./trend-usp-boxes.types";

export const TrendUspBoxes: FC<TrendUspBoxesProps> = ({ queryFragmentRef }) => {
	const data = useFragment<trendUspBoxes_TrendUspBoxesFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef ?? null,
	);
	const advantages = data?.trendData?.advantages;
	return (
		<Wrapper>
			{advantages &&
				advantages.length > 0 &&
				advantages.map((advantage, index) => (
					<ItemWrapper key={advantage.heading} order={index + 1}>
						{advantage.icon && (
							<IconWrapper>
								<Icon icon={advantage.icon} sizeInRem={2.5} />
							</IconWrapper>
						)}
						<TextWrapper>
							<ItemTitle>{advantage.heading}</ItemTitle>
							<TkaP2Span>{advantage.description}</TkaP2Span>
						</TextWrapper>
					</ItemWrapper>
				))}
		</Wrapper>
	);
};
