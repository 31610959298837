import graphql from "babel-plugin-relay/macro";

export const DELETE_USER_IN_ACCOUNT_GROUP_MUTATION = graphql`
	mutation deleteGroupsModal_DeleteUserInAccountGroupMutation(
		$input: DeleteUserInAccountGroupsInput!
		$connectionIds: [ID!]!
	) {
		AccountMemberManagement {
			deleteUserInAccountGroups(input: $input) {
				removedIds @deleteEdge(connections: $connectionIds)
			}
		}
	}
`;
