import { TeamSizeTypeOptions } from "./team-size-dropdown.consts";
import { TeamSizeType, TeamSizeTypeOptionsRecord } from "./team-size-dropdown.types";

//TODO: add-translations
export const getTeamSizeOptions = () => {
	const translations: TeamSizeTypeOptionsRecord = {
		[TeamSizeTypeOptions.oneToTen]: "1-10",
		[TeamSizeTypeOptions.tenToTwentyFive]: "10-25",
		[TeamSizeTypeOptions.twentyFiveToFifty]: "25-50",
		[TeamSizeTypeOptions.fiftyToHundred]: "50-100",
		[TeamSizeTypeOptions.overHundred]: "100+",
	};
	return Object.keys(translations).map((key) => ({
		value: key,
		label: translations[key as unknown as TeamSizeTypeOptions],
	}));
};

export const getAvailableTeamSize = (teamSize?: string): TeamSizeType | undefined => {
	if (teamSize && Object.values(TeamSizeTypeOptions).includes(teamSize as TeamSizeTypeOptions))
		return teamSize as TeamSizeType;
	return undefined;
};
