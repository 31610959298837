/**
 * @generated SignedSource<<a7ed136213aadf043f7108e6cfac5903>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type groupCard_UserInAccountGroupWrapperFragment$data = {
  readonly group: {
    readonly id: string;
    readonly isBuiltIn: boolean;
    readonly name: string;
  };
  readonly id: string;
  readonly memberCount: any;
  readonly " $fragmentSpreads": FragmentRefs<"groupContextMenu_UserInAccountGroupWrapperFragment">;
  readonly " $fragmentType": "groupCard_UserInAccountGroupWrapperFragment";
};
export type groupCard_UserInAccountGroupWrapperFragment$key = {
  readonly " $data"?: groupCard_UserInAccountGroupWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupCard_UserInAccountGroupWrapperFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "groupCard_UserInAccountGroupWrapperFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBuiltIn",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "groupContextMenu_UserInAccountGroupWrapperFragment"
    }
  ],
  "type": "UserInAccountGroupWrapper",
  "abstractKey": null
};
})();

(node as any).hash = "c23157d37f16dd1150b1a7c952c10b3e";

export default node;
