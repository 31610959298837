import { useCallback, useState } from "react";
import { Icon } from "@components/icon/icon.component";
import { ImageCardAtom } from "@components/ImageCardAtom";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade100 } from "@themes/colors";
import { TkaP3Span, TkaH3Span } from "@themes/font-tags";
import { ImageWidth } from "./branch-node-card.const";
import { BranchNodeCardProps, BranchNodeStatus } from "./branch-node-card.interface";
import { IconsWrapper, InfoWrapper, ListWrapper, MainWrapper } from "./branch-node-card.styles";
import { Label } from "../label";

export const BranchNodeCard = ({
	title,
	imageUrl,
	moduleNumber,
	courseSize,
	status,
	children,
}: BranchNodeCardProps) => {
	const { t } = useTkaTranslation("modulesAndLessons");

	const [isExpanded, setIsExpanded] = useState(false);

	const handleOnExpandClick = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	const extraBadge =
		status === BranchNodeStatus.done ? (
			<Icon icon={"checkCircle"} tkaColor={shade100} sizeInRem={1} />
		) : status === BranchNodeStatus.locked ? (
			<Icon icon={"lock"} tkaColor={shade100} sizeInRem={1} />
		) : null;

	return (
		<ListWrapper onClick={handleOnExpandClick}>
			<MainWrapper isEnabled={status !== BranchNodeStatus.locked}>
				{imageUrl && <ImageCardAtom imageUrl={imageUrl} imageWidth={`${ImageWidth}rem`} />}
				<InfoWrapper>
					<TkaP3Span>
						{t("modules_and_lessons.module")} {moduleNumber}
					</TkaP3Span>
					<TkaH3Span>{title}</TkaH3Span>
					<Label label={`${moduleNumber}/${courseSize}`} />
				</InfoWrapper>
				<IconsWrapper>
					<Icon icon={isExpanded ? "arrowUp" : "arrowDown"} sizeInRem={1} />
					{extraBadge}
				</IconsWrapper>
			</MainWrapper>
			{isExpanded && children}
		</ListWrapper>
	);
};
