import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useFragment, useMutation } from "react-relay";

import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { GapInputDropdown } from "@components/gap-input-dropdown";
import { GapTextStatus } from "@components/gap-text/gap-text.interface";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { SideMenuLayoutContext } from "@layouts/sidemenu-layout/sidemenu-layout.context";
import { ClozeTextElement_ContentSubmissionFragment$key } from "@relay/ClozeTextElement_ContentSubmissionFragment.graphql";
import { ClozeTextElement_SubmitClozeTextElementMutation } from "@relay/ClozeTextElement_SubmitClozeTextElementMutation.graphql";
import { shade100 } from "@themes/colors";
import { H1Span } from "@themes/font-tags";
import { ClozeTextElementProps } from "./cloze-text-element.interface";
import {
	ContentWrapper,
	FixedBottomWrapper,
	GapTextWrapper,
	RelativeBottomWrapper,
	Spacing16,
	TextSpan,
	TextWrapper,
	Wrapper,
} from "./cloze-text-element.styles";
import { ClozeTextElementAnswers } from "./ClozeTextElementAnswers.component";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ClozeTextElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					... on InputAndIsCorrectElementState {
						isCorrect
						inputElementState {
							... on ClozeTextInputElementState {
								checkedAnswers
							}
						}
					}
					... on ClozeTextShowAnswerElementState {
						correctAnswers
						lastCheckedAnswers
					}
					... on ClozeTextInputElementState {
						checkedAnswers
					}
					element {
						... on ClozeTextLearnElement {
							id
							title
							parts {
								kind
								id
								... on TextPart {
									text
								}
							}
							shuffledAnswers {
								text
								id
							}
						}
					}
				}
			}
		}
	}
`;

const SUBMIT_CLOZE_TEXT_ELEMENT_MUTATION = graphql`
	mutation ClozeTextElement_SubmitClozeTextElementMutation(
		$input: SubmitClozeTextElementV2Input!
	) {
		LearnV2 {
			submitClozeTextElement(input: $input) {
				clientMutationId
				contentSubmission {
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								... on InputAndIsCorrectElementState {
									isCorrect
								}
								kind
							}
						}
					}
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const ClozeTextElement = ({ contentSubmissionFragmentRef }: ClozeTextElementProps) => {
	const contentSubmission = useFragment<ClozeTextElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);
	const [submitClozeTextElement] = useMutation<ClozeTextElement_SubmitClozeTextElementMutation>(
		SUBMIT_CLOZE_TEXT_ELEMENT_MUTATION,
	);

	const { bottomContentHeightPx } = useContext(SideMenuLayoutContext);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;
	const currentElementStateKind = elementState?.kind || "Untouched";

	const isShowAnswer = currentElementStateKind === "ShowAnswer";
	const isInputCorrect = currentElementStateKind === "InputAndIsCorrect";
	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";
	const isInput = currentElementStateKind === "Input";

	const correctAnswersId = elementState?.correctAnswers || [];
	const lastCheckedAnswers =
		elementState?.lastCheckedAnswers ||
		elementState?.inputElementState?.checkedAnswers ||
		elementState?.checkedAnswers ||
		undefined;
	const textParts = element?.parts || [];
	const shuffledAnswers = element?.shuffledAnswers || [];

	const {
		isModalVisible,
		setCanGoNext,
		addGoToNextOnClickListener,
		addTryAgainOnClickListener,
		setFailureModalVisible,
		setSuccessModalVisible,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [selectedCellIndex, setSelectedCellIndex] = useState<number | undefined>(0);
	const [selectedAnswersId, setSelectedAnswersId] = useState<string[]>([]);
	const availableAnswers = useMemo(
		() => shuffledAnswers.filter((a) => !selectedAnswersId.includes(a.id)),
		[selectedAnswersId],
	);

	const inputDisabled = isModalVisible || (!isUntouched && !isInput);

	const handleSubmitClick = useCallback(() => {
		setLoading(true);
		submitClozeTextElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id,
					clozeTextElementId: element?.id!,
					checkedAnswers: selectedAnswersId,
				},
			},
			onCompleted: (response) => {
				setLoading(false);
				const currentElementState =
					response.LearnV2.submitClozeTextElement?.contentSubmission.definition
						?.currentElementState;
				if (!currentElementState) return;

				if (currentElementState.isCorrect) {
					setSuccessModalVisible(true);
				} else if (
					currentElementState.isCorrect === false ||
					currentElementState.kind === "UntouchedAndPreviouslyIncorrect"
				) {
					setFailureModalVisible(true);
				}
			},
		});
	}, [
		submitClozeTextElement,
		contentSubmission.id,
		element?.id,
		selectedAnswersId,
		setSuccessModalVisible,
		setFailureModalVisible,
	]);

	const handleGoToNextClicked = useCallback(() => {
		setSelectedCellIndex(undefined);
		if (isUntouched || isInput) {
			handleSubmitClick();
			return true;
		}
		return false;
	}, [isUntouched, isInput, handleSubmitClick]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	const handleTryAgainClicked = useCallback(() => {
		setSelectedCellIndex(0);
		setSelectedAnswersId([]);
	}, []);

	const requiredSelections = useMemo(
		() => textParts.filter((part) => part.kind === "cloze").length,
		[textParts],
	);

	useEffect(() => addTryAgainOnClickListener(handleTryAgainClicked), [handleTryAgainClicked]);

	useEffect(() => {
		const requiredSelections = textParts.filter((part) => part.kind === "cloze").length;
		const canGoNext =
			selectedAnswersId.filter(Boolean).length === requiredSelections || !isUntouched;
		setCanGoNext(canGoNext);
	}, [textParts, selectedAnswersId, isUntouched]);

	useEffect(() => {
		if (!isUntouched && lastCheckedAnswers && lastCheckedAnswers.length > 0) {
			setSelectedAnswersId([...lastCheckedAnswers]);
		}
	}, [lastCheckedAnswers, isUntouched]);

	const clozePartIndexMap = useMemo(() => {
		const map: Record<number, number> = {};
		textParts.map((part, index) => {
			if (part.kind === "cloze") {
				map[index] = Object.keys(map).length;
			}
		});
		return map;
	}, [textParts]);

	const useAnswersId = useMemo(() => {
		return isShowAnswer && correctAnswersId.length > 0 ? correctAnswersId : selectedAnswersId;
	}, [isShowAnswer, selectedAnswersId, correctAnswersId]);

	const calculateGapTextStatus = (index: number): GapTextStatus => {
		if (isShowAnswer) {
			const isCorrect = correctAnswersId[index] === selectedAnswersId[index];
			return isCorrect ? GapTextStatus.Correct : GapTextStatus.ActuallyCorrect;
		}
		if (isInputCorrect) return GapTextStatus.Correct;
		return GapTextStatus.Default;
	};

	const handleTextGapOnClick = (index: number) => {
		if (inputDisabled) return;
		setSelectedCellIndex(index);
	};

	const handleAnswerOnClick = useCallback(
		(id: string) => {
			if (selectedCellIndex === undefined) return;
			if (selectedCellIndex < requiredSelections - 1) {
				setSelectedCellIndex(selectedCellIndex + 1);
			} else {
				setSelectedCellIndex(undefined);
			}
			setSelectedAnswersId((answerIds) => {
				const answerSlice = answerIds.slice();
				answerSlice[selectedCellIndex] = id;
				return answerSlice;
			});
		},
		[selectedCellIndex],
	);

	const renderTextPart = (text: string, index: number) => {
		return (
			<TextSpan key={`${text}_${index}`} tkaColor={shade100}>
				{text}
			</TextSpan>
		);
	};

	const ClozePart = ({ index }: any) => {
		const handleAnswerDrop = (item: any) => {
			if (!item && !selectedCellIndex) return;
			const targetCellIndex = clozePartIndexMap[index];
			setSelectedCellIndex(targetCellIndex);
			setSelectedAnswersId((answerIds) => {
				const updatedAnswerIds = [...answerIds];
				updatedAnswerIds[targetCellIndex] = item.id;
				return updatedAnswerIds;
			});
		};
		const [{ isOver }, drop] = useDrop(
			() => ({
				accept: "Answer",
				drop: (item) => handleAnswerDrop(item),

				collect: (monitor) => ({
					isOver: !!monitor.isOver(),
				}),
			}),
			[],
		);
		const clozeIndex = clozePartIndexMap[index] || 0;
		const clozeAnswerId = useAnswersId[clozeIndex];
		const answer = shuffledAnswers.find((answer) => answer.id === clozeAnswerId);
		return (
			<GapTextWrapper>
				<GapInputDropdown
					key={`${answer?.text}_${index}`}
					status={calculateGapTextStatus(clozeIndex)}
					selected={selectedCellIndex === clozeIndex}
					text={answer?.text}
					useMinWidth={!answer?.text}
					onClick={(e) => {
						e.stopPropagation();
						handleTextGapOnClick(clozeIndex);
					}}
					onAnswerSelect={handleAnswerOnClick}
					availableAnswers={availableAnswers}
					isOver={isOver}
					drop={drop}
				/>
			</GapTextWrapper>
		);
	};

	const renderClozeTextParts = textParts.map((part, index) => {
		switch (part.kind) {
			case "cloze":
				return <ClozePart index={index} />;
			case "text":
				return renderTextPart(part.text!, index);
			default:
				return null;
		}
	});

	const answersModal = (
		<ClozeTextElementAnswers answers={availableAnswers} answerOnClick={handleAnswerOnClick} />
	);

	return (
		<Wrapper>
			<ContentWrapper onClick={() => setSelectedCellIndex(undefined)}>
				<SideMenuContentPadding>
					<H1Span>{"Ergänze folgenden Text mit den vorgegebenen Bausteinen"}</H1Span>
					<Spacing16 />
					<TextWrapper>{renderClozeTextParts}</TextWrapper>
				</SideMenuContentPadding>
			</ContentWrapper>
			<RelativeBottomWrapper>{answersModal}</RelativeBottomWrapper>
			<FixedBottomWrapper bottomPx={bottomContentHeightPx}>{answersModal}</FixedBottomWrapper>
		</Wrapper>
	);
};
