import tw from "twin.macro";
import { TkaP2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
    flex
    flex-col
`;

export const TaskDescription = tw(TkaP2Span)`
    mt-12
    mb-32
`;
