import { Icon } from "@components/icon";
import { InputText } from "@components/input-text";
import { useWindowSize } from "@hooks/use-window-size";
import { shade100, shade40 } from "@themes/colors";
import { SearchBarProps } from "./search-bar.types";

export const SearchBar = ({
	onClick: handleOnClick,
	value,
	onChange,
	onEnterKeyDown,
}: SearchBarProps) => {
	const placeholder = "Suchen";

	const { isMediumUp } = useWindowSize();

	const handleOnChange = (e?: string) => {
		onChange?.(e ?? "");
	};

	const handleOnEnterKeyDown = (e: any) => {
		onEnterKeyDown?.(e);
	};

	const iconColor = isMediumUp ? shade40 : shade100;
	const iconSize = isMediumUp ? 1.5 : 0.75;
	if (!isMediumUp)
		return (
			<Icon
				icon={"search"}
				sizeInRem={iconSize}
				tkaColor={iconColor}
				onClick={handleOnClick}
			/>
		);

	return (
		<InputText
			icon={"search"}
			placeholder={placeholder + "..."}
			value={value}
			onChange={handleOnChange}
			onKeyPress={handleOnEnterKeyDown}
		/>
	);
};
