import styled from "styled-components";
import tw from "twin.macro";
import { TkaH3Span } from "@themes/font-tags";

export const CardWrapper = styled.div<{ img?: string }>`
	background: url(${(props) => props.img});
	background-size: cover;
	background-position: center;
	background-blend-mode: overlay;
	${tw`flex
flex-col
justify-between
md:w-[17rem]
md:h-[11.875rem]
relative
p-[0.75rem]
mt-[1rem]
bg-shade-10
rounded-[0.75rem]`};
`;

export const CardHeader = tw.div`
flex
justify-between
`;

export const CardDescription = tw.div`
flex
flex-col`;

export const StyledTkaH3Span = tw(TkaH3Span)`
h-[2.5rem]`;

export const CertificateWrapper = tw.div`
flex
gap-[0.25rem]
mb-[1rem]
`;

export const ProgressWrapper = tw.div`
flex
top-10
right-10
absolute
items-center
gap-[0.25rem]`;

export const ButtonWrapper = tw.div`
mt-auto`;
