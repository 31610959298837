import { shade100 } from "@themes/colors";
import { TkaH3Span } from "@themes/font-tags";
import { ParagraphWithBackgroundColorProps } from "./paragraph-with-background-color.interface";
import { ContentWrapper, Wrapper } from "./paragraph-with-background-color.styles";

export const ParagraphWithBackgroundColor = ({
	title,
	backgroundColor,
	children,
}: ParagraphWithBackgroundColorProps) => (
	<Wrapper>
		{title && <TkaH3Span tkaColor={shade100}>{title}</TkaH3Span>}
		<ContentWrapper backgroundColor={backgroundColor}>{children}</ContentWrapper>
	</Wrapper>
);
