import styled from "styled-components";
import tw from "twin.macro";
import { shade10 } from "@themes/colors";

export const WordPackageWrapper = styled.div<{ isExpanded?: boolean }>`
	${tw`flex cursor-pointer items-start`};
	${(props) => (props.isExpanded ? tw`mb-32` : "")}
`;

export const WordPackageIconWrapper = styled.div`
	${tw`p-8 mr-10`};
	border-radius: 12px;
	background-color: ${shade10.getColor()};
`;

export const WordPackageContentWrapper = tw.div`flex flex-col flex-grow`;

export const Spacing4 = tw.div`mb-4`;

export const TitleWrapper = tw.div`flex items-center`;

export const IconWrapper = tw.div`ml-auto`;

export const WordGrid = tw.div`
	grid 
	grid-cols-1 
	md:grid-cols-2 
	lg:grid-cols-4 
	xl:grid-cols-2
	xxl:grid-cols-4
	gap-12
`;
export const CheckboxWrapper = tw.div`mr-8`;
