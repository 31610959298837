/**
 * @generated SignedSource<<01a824d7d8cfed4822620adf1a7e1051>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAccountGroupAssignmentRuleInput = {
  active: boolean;
  clientMutationId?: string | null;
  licenseGroupingId: string;
  name: string;
  userInAccountGroupIds: ReadonlyArray<string>;
};
export type licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation$variables = {
  input: CreateAccountGroupAssignmentRuleInput;
};
export type licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation$data = {
  readonly LicenseManagement: {
    readonly createAccountGroupAssignmentRule: {
      readonly assignmentRule: {
        readonly accountRef: string;
        readonly id: string;
        readonly licenseGroupingId: string;
        readonly " $fragmentSpreads": FragmentRefs<"licensesAccountGroupAssignmentCard_QueryFragment">;
      };
    } | null;
  };
};
export type licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation = {
  response: licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation$data;
  variables: licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseGroupingId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountRef",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateAccountGroupAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "createAccountGroupAssignmentRule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "licensesAccountGroupAssignmentCard_QueryFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateAccountGroupAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "createAccountGroupAssignmentRule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amountEffectedUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccountGroup",
                        "kind": "LinkedField",
                        "name": "userInAccountGroups",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isBuiltIn",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "AccountGroupAssignmentRule",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5835bad644b716c61f80b9d315bd6846",
    "id": null,
    "metadata": {},
    "name": "licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation",
    "operationKind": "mutation",
    "text": "mutation licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation(\n  $input: CreateAccountGroupAssignmentRuleInput!\n) {\n  LicenseManagement {\n    createAccountGroupAssignmentRule(input: $input) {\n      assignmentRule {\n        __typename\n        id\n        licenseGroupingId\n        accountRef\n        ...licensesAccountGroupAssignmentCard_QueryFragment\n      }\n    }\n  }\n}\n\nfragment licensesAccountGroupAssignmentCard_QueryFragment on AccountGroupAssignmentRule {\n  __typename\n  id\n  name\n  active\n  licenseGroupingId\n  accountRef\n  amountEffectedUser\n  userInAccountGroups {\n    id\n    name\n    isBuiltIn\n  }\n}\n"
  }
};
})();

(node as any).hash = "37c73850bb976094022de001713455b9";

export default node;
