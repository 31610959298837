import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";

export const Text = styled(TkaP2Span)<{ invisible?: boolean }>`
	opacity: ${({ invisible }) => (invisible ? 0 : 1)};
	${tw`
        select-none 
        whitespace-nowrap
    `}
`;

export const Spacer = tw.div`w-[5rem]`;

export const Wrapper = styled.div<{ backgroundColor: TkaColor; borderColor: TkaColor }>`
	${tw`
        px-16
        py-12
        cursor-pointer
        rounded-10
        shadow-[inset_0_0_0_0.0625rem]
		shadow-shade-100
    `}
	background-color: ${({ backgroundColor }) => backgroundColor};
	box-shadow: 0 0 0 1px ${({ borderColor }) => borderColor} inset;
`;
