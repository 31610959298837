import { useEffect, useMemo, useState } from "react";
import { readInlineData, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AppointmentCard } from "@components/appointment-card";
import { AppointmentModalsFlow } from "@components/appointment-modals-flow";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { Message } from "@components/message";
import { withSuspense } from "@components/with-suspense/with-suspense.component";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import {
	appointments_AvailabilityFragment$data,
	appointments_AvailabilityFragment$key,
} from "@relay/appointments_AvailabilityFragment.graphql";
import { appointments_CoachAvailabilitiesFragment$key } from "@relay/appointments_CoachAvailabilitiesFragment.graphql";
import { appointments_CoachAvailabilitiesQueryFragmentRefetch } from "@relay/appointments_CoachAvailabilitiesQueryFragmentRefetch.graphql";
import { appointments_CoachCoachingAppointmentsQueryFragment$key } from "@relay/appointments_CoachCoachingAppointmentsQueryFragment.graphql";
import { appointments_CoachCoachingAppointmentsQueryFragmentRefetch } from "@relay/appointments_CoachCoachingAppointmentsQueryFragmentRefetch.graphql";
import { appointments_MyCoachingAppointmentsQueryFragment$key } from "@relay/appointments_MyCoachingAppointmentsQueryFragment.graphql";
import { appointments_MyCoachingAppointmentsQueryFragmentRefetch } from "@relay/appointments_MyCoachingAppointmentsQueryFragmentRefetch.graphql";
import { appointments_Query } from "@relay/appointments_Query.graphql";
import { Path } from "@router/paths";
import { H1Span } from "@themes/font-tags";
import { RERENDER_INTERVALL_MS, STEP_LIMIT } from "./appointments.consts";
import { AppointmentsScreenContext } from "./appointments.context";
import {
	AVAILABILITY_FRAGMENT,
	COACH_AVAILABILITIES_QUERY_FRAGMENT,
	COACH_COACHING_APPOINTMENTS_QUERY_FRAGMENT,
	MY_COACHING_APPOINTMENTS_QUERY_FRAGMENT,
	QUERY,
} from "./appointments.graphql";
import { AppointmentsScreenSkeleton } from "./appointments.skeleton";
import {
	AppointmentsWrapper,
	NeedConfirmationWrapper,
	UnconfirmedCardsWrapper,
	WarningWrapper,
	Wrapper,
} from "./appointments.styles";
import { sortAppointments } from "./appointments.utils";
import { UnconfirmedAppointmentCard } from "./parts/unconfirmed-appointment-card";

// TODO: add-translations
export const AppointmentsScreenComponent = () => {
	const query = useLazyLoadQuery<appointments_Query>(
		QUERY,
		{
			first: STEP_LIMIT,
			coachAvailabilitiesFirst: STEP_LIMIT,
			coachAvailabilitiesOnlyNeedConfirmation: true,
		},
		{ fetchPolicy: "store-and-network" },
	);

	const { data, hasNext, loadNext, refetch, isLoadingNext } = usePaginationFragment<
		appointments_MyCoachingAppointmentsQueryFragmentRefetch,
		appointments_MyCoachingAppointmentsQueryFragment$key
	>(MY_COACHING_APPOINTMENTS_QUERY_FRAGMENT, query);

	const {
		data: coachData,
		hasNext: coachHasNext,
		loadNext: coachLoadNext,
		refetch: coachRefetch,
		isLoadingNext: coachIsLoadingNext,
	} = usePaginationFragment<
		appointments_CoachCoachingAppointmentsQueryFragmentRefetch,
		appointments_CoachCoachingAppointmentsQueryFragment$key
	>(COACH_COACHING_APPOINTMENTS_QUERY_FRAGMENT, query);

	const {
		data: notConfirmedAvailabilites,
		hasNext: coachAvailabilitesHasNext,
		loadNext: coachAvailabilitesLoadNext,
		refetch: coachAvailabilitesRefetch,
		isLoadingNext: coachAvailabilitesIsLoadingNext,
	} = usePaginationFragment<
		appointments_CoachAvailabilitiesQueryFragmentRefetch,
		appointments_CoachAvailabilitiesFragment$key
	>(COACH_AVAILABILITIES_QUERY_FRAGMENT, query);

	const navigate = useNavigate();
	const { isMediumUp } = useWindowSize();
	const { isCoachAccount } = usePermissions();

	const [availabilityRef, setAvailabilityRef] =
		useState<appointments_AvailabilityFragment$data | null>();

	const [rendererKey, setRendererKey] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setRendererKey((key) => key + 1);
		}, RERENDER_INTERVALL_MS);
		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (hasNext && !isLoadingNext) {
			loadNext(STEP_LIMIT);
		}
	}, [isLoadingNext, hasNext]);

	useEffect(() => {
		if (coachHasNext && !coachIsLoadingNext) {
			coachLoadNext(STEP_LIMIT);
		}
	}, [coachIsLoadingNext, coachHasNext]);

	useEffect(() => {
		if (coachAvailabilitesHasNext && !coachAvailabilitesIsLoadingNext) {
			coachAvailabilitesLoadNext(STEP_LIMIT);
		}
	}, [coachAvailabilitesIsLoadingNext, coachAvailabilitesHasNext]);

	const handleRefreshMyAppointments = () => {
		refetch({ first: STEP_LIMIT }, { fetchPolicy: "store-and-network" });
	};

	const handleRefreshCoachingAppointments = () => {
		coachRefetch({ first: STEP_LIMIT }, { fetchPolicy: "store-and-network" });
	};

	const handleRefreshUnconfirmed = () => {
		coachAvailabilitesRefetch({ first: STEP_LIMIT }, { fetchPolicy: "store-and-network" });
	};

	const createAppointmentOnClickHandler =
		(availabilityRef: appointments_AvailabilityFragment$data) => () => {
			setAvailabilityRef(availabilityRef);
		};

	const handleOnDismiss = () => {
		setAvailabilityRef(null);
	};

	const handleGoToProgressionOnClick = () => {
		navigate(Path.progression.path);
	};

	const sortedAppointments = useMemo(() => {
		const allAppointments = [
			...(data.Viewer.Coaching.MyCoachingAppointments.edges ?? []),
			...(coachData.Viewer.Coaching.CoachCoachingAppointments.edges ?? []),
		];
		const appointments =
			allAppointments.map((edge) =>
				readInlineData<appointments_AvailabilityFragment$key>(
					AVAILABILITY_FRAGMENT,
					edge?.node!,
				),
			) ?? [];
		return sortAppointments(appointments);
	}, [
		data.Viewer.Coaching.MyCoachingAppointments.edges,
		coachData.Viewer.Coaching.CoachCoachingAppointments.edges,
		rendererKey,
	]);

	const sortedUnconfirmedAppointments = useMemo(() => {
		const appointments =
			notConfirmedAvailabilites.Coaching.CoachAvailabilities.edges?.map((edge) =>
				readInlineData<appointments_AvailabilityFragment$key>(
					AVAILABILITY_FRAGMENT,
					edge?.node!,
				),
			) ?? [];
		return sortAppointments(appointments);
	}, [notConfirmedAvailabilites.Coaching.CoachAvailabilities.edges, rendererKey]);

	const hasUnconfirmedAppointments = !!sortedUnconfirmedAppointments.length;

	return (
		<AppointmentsScreenContext.Provider
			value={{
				refreshUnconfirmed: handleRefreshUnconfirmed,
				refreshMyAppointments: handleRefreshMyAppointments,
				refreshCoachingAppointments: handleRefreshCoachingAppointments,
			}}
		>
			<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
				<Wrapper>
					<H1Span>Meine Termine</H1Span>
					<EmptyPlaceholder
						isVisible={!sortedAppointments.length && !hasUnconfirmedAppointments}
						iconName="calendar"
						title="Hier findest du später deine Termine"
						subtitle="Du kannst ganz einfach Termine finden oder buchen, indem du in der Weiterentwicklung nach passenden Angeboten suchst. Dort findest du verschiedene Kurse und Coaches, die dir helfen können, deine Lernziele zu erreichen."
						buttonLabel={!isCoachAccount ? "Zur Weiterentwicklung" : undefined}
						onClick={handleGoToProgressionOnClick}
					>
						{hasUnconfirmedAppointments && (
							<NeedConfirmationWrapper>
								<WarningWrapper>
									<Message
										highlighted
										severity="warn"
										summary="Offener Anwesenheitscheck"
										detail="Bitte vergiss nicht, die Anwesenheit der Teilnehmer zu bestätigen. Das ist wichtig für deine Honorierung und für die Qualitätssicherung unserer Plattform."
									/>
								</WarningWrapper>
								<UnconfirmedCardsWrapper>
									{sortedUnconfirmedAppointments.map((appointment) => (
										<UnconfirmedAppointmentCard
											key={appointment.id}
											availabilityFragmentRef={appointment}
											onClick={createAppointmentOnClickHandler(appointment)}
										/>
									))}
								</UnconfirmedCardsWrapper>
							</NeedConfirmationWrapper>
						)}
						<AppointmentsWrapper>
							{sortedAppointments.map((appointment) => (
								<AppointmentCard
									key={appointment.id}
									onClick={createAppointmentOnClickHandler(appointment)}
									availabilityFragmentRef={appointment}
								/>
							))}
						</AppointmentsWrapper>
					</EmptyPlaceholder>
				</Wrapper>
				{availabilityRef && (
					<AppointmentModalsFlow
						isVisible
						onDismiss={handleOnDismiss}
						availabilityFragmentRef={availabilityRef}
					/>
				)}
			</ScreenWithProfileMenuLayout>
		</AppointmentsScreenContext.Provider>
	);
};

export const AppointmentsScreen = withSuspense(
	AppointmentsScreenComponent,
	AppointmentsScreenSkeleton,
);
