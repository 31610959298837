import { TabView } from "primereact/tabview";
import { ReactNode } from "react";
import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	md:w-[25rem]
	border-b
	border-shade-10
`;

export const StyledTabView = styled(TabView)<{
	activeIndex?: number;
	onTabChange?: (e: any) => void;
	children: ReactNode;
}>`
	width: 100%;
	gap: 0;

	&.p-tabview .p-tabview-nav {
		border: none;
		border-bottom: none;
	}
	.p-tabview-nav-container {
		position: absolute;
		top: 25px;
		right: 25px;
	}
	&.p-tabview .p-tabview-panels {
		padding: 1.5rem 0;
	}
`;
