import CoachingImage from "@assets/coachingImage.png";
import PotentialAnalysisGraph from "@assets/potentialAnalysisGraph.png";
import { TextAndMediaSectionProps } from "@components/text-and-media-section/text-and-media.types";

export const potentialAnalysisSection: TextAndMediaSectionProps = {
	headline: "Eigene Potenziale erkennen",
	text: "Ob für sich selbst, ein Team oder ein Unternehmen: Wer die Potenziale kennt, kann gezielt auf die eigene Zukunftsfähigkeit hinarbeiten.",
	primaryButtonLabel: "Zur Potenzialanalyse",
	fileType: "image",
	fileURL: PotentialAnalysisGraph,
	mediaPosition: "right",
};

export const coachingDataSection: TextAndMediaSectionProps = {
	headline: "Beratung",
	text: "Du möchtest herausfinden, wie du deine Karriere-, Team- oder Unternehmensziele erreichst? Bei uns bekommst du eine kostenlose Erstberatung, denn uns ist wichtig, dass du deine Zeit und dein Geld in das Passende investierst.",
	primaryButtonLabel: "Zur Beratung",
	fileURL: CoachingImage,
	fileType: "image",
	mediaPosition: "left",
};

export const amountCustomers = "135213";

export const trendOfferSectionData = {
	heading: "Gezielte Weiterentwicklung",
	text: "Persönliche und berufliche Weiterentwicklung sind essenziell für individuelles Wachstum. Dabei spielen Haltungen, Kompetenzen und Gesundheit eine wichtige Rolle. Ein modulares Angebot ermöglicht es, die eigenen Schwerpunkte zu setzen und flexibel zu kombinieren. Durch eine wissenschaftlich fundierte Herangehensweise und Integration neuester Standards werden optimale Entwicklungsmöglichkeiten geschaffen.",
	cardInfo: [
		{
			heading: "Beratung",
			description:
				"Ob eine individuelle Beratung zur Gestaltung der eigenen Weiterentwicklung oder eine strategische Beratung für Unternehmen zu den Zukunftsthemen - wir sind für dich da.",
			kind: ["OneToOneCoaching", "GroupCoaching"],
		},
		{
			heading: "Coaching",
			description:
				"In unseren maßgeschneiderten Coachings begleiten und unterstützen dich unsere Expert*innen individuell bei deiner persönlichen und beruflichen Weiterentwicklung.",
			kind: ["OneToOneCoaching", "GroupCoaching"],
		},
		{
			heading: "Weiterbildungen",
			description:
				"Unsere Weiterbildungen bieten dir gezielte, umfangreiche Lernmöglichkeiten, um deine beruflichen Fähigkeiten zu erweitern und dich fachlich weiterzuentwickeln.",
			kind: ["Course"],
		},
		{
			heading: "Micro Learning",
			description:
				"In 10 Minuten Einheiten bekommst du zu konkreten Herausforderungen des Arbeitsalltags umsetzungsorientierte Lösungsansätze und hilfreiches Hintergrundwissen.",
			kind: ["MicroLearning"],
		},
	],
};
