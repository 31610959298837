import { TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AdvanceTreeNavigation } from "@components/advance-tree-navigation";
import { RootProgress } from "@components/root-progress";
import { TabBar } from "@components/tab-bar";
import { useCurrentPath } from "@hooks/use-current-path";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { ScreenWithNavbarSidemenuLayout } from "@layouts/screen-with-navbar-sidemenu-layout";
import { root_GetRootQuery } from "@relay/root_GetRootQuery.graphql";
import { BranchNodesPath, Path } from "@router/index";
import { BranchNodeTab } from "./branch-nodes-tab";
import { MaterialsTab } from "./materials-tab";
import { RootContainerId } from "./root-overview/root-overview.const";
import { RootOverviewTab } from "./root-overview-tab";
import { RootScreenContext } from "./root.context";
import { QUERY } from "./root.graphql";

import { RootScreenSkeleton } from "./root.skeleton";
import { SideMenuHeaderWrapper, SideMenuWrapper, StickyWrapper, Wrapper } from "./root.styles";
import { RootLocationState, RootScreenProps } from "./root.types";
import { getTabIndexForPath, useRootNavigation } from "./root.util";
import { gtmTrackViewCourseDetail } from "../../analytics/google-tag-manager";

export const RootScreenWithRootId = ({ rootId }: RootScreenProps) => {
	const { t } = useTkaTranslation("courseScreen");

	const navigate = useNavigate();
	const { state }: RootLocationState = useLocation();
	const currentPath = useCurrentPath();
	const { navigateToTab } = useRootNavigation();
	const { isXLargeUp } = useWindowSize();

	const [activeIndex, setActiveIndex] = useState(getTabIndexForPath(currentPath?.route.path));

	const query = useLazyLoadQuery<root_GetRootQuery>(
		QUERY,
		{ id: rootId },
		{ fetchPolicy: "store-and-network" },
	);

	const isRootPath = currentPath?.route.path === Path.root.withIdPlaceholder().path;

	useEffect(() => {
		if (isRootPath) {
			if (
				query?.node?.root?.structureDefinition?.extension?.product &&
				query?.node?.root?.structureDefinition.extension?.unlockInfo?.kind !== "FullAccess"
			) {
				const product = query?.node?.root?.structureDefinition?.extension?.product;
				gtmTrackViewCourseDetail(product.id, product?.title, state?.productIds ?? []);
			}
			navigate(Path.root.withId(rootId).overview.path, { replace: true });
		}
	}, [isRootPath]);

	const isContentBasePath = currentPath?.route.path === BranchNodesPath.pathName;

	useEffect(() => {
		if (isContentBasePath) {
			const nextContentId =
				query.node?.root?.structureDefinition.viewerTreeState?.headContentId ??
				query.node?.root?.typeDefinition?.children?.[0]?.typeDefinition.children?.[0]?.id;
			nextContentId &&
				navigate(Path.root.withId(rootId).branchNodes.withId(nextContentId).path, {
					replace: true,
				});
		}
	}, [isContentBasePath]);

	useEffect(() => {
		const pathIndex = getTabIndexForPath(currentPath?.route.path);
		if (pathIndex !== activeIndex) {
			setActiveIndex(pathIndex);
		}
	}, [currentPath?.route.path, activeIndex]);

	const handleOnTabChange = useCallback(
		(params: TabViewTabChangeEvent) => {
			navigateToTab(params.index);
		},
		[navigateToTab],
	);

	const rootName = query.node?.root?.structureDefinition?.title;
	const isIHK = query?.node?.root?.structureDefinition?.extension?.reducedData?.isIHK;
	const isDemo = query?.node?.root?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const hasTrailer = !!query?.node?.root?.structureDefinition?.extension?.trailer?.url;
	const hasGoals =
		(query?.node?.root?.structureDefinition?.extension?.learnGoals?.length ?? 0) > 0;
	const hasInstructors = (query?.node?.root?.instructorsV2.edges?.length ?? 0) > 0;
	const canOrderIHK =
		query?.node?.root?.structureDefinition?.extension?.ihkState === "ReadyToOrder";
	const receivablePoints =
		query?.node?.root?.structureDefinition?.extension?.reducedData
			?.receivableGamificationPoints ?? 0;

	const tabBar = (
		<TabBar activeIndex={activeIndex} onTabChange={handleOnTabChange}>
			<TabPanel header={t("course_screen.tabOverview")} />
			<TabPanel header={t("course_screen.tabModule")} />
			<TabPanel header={t("course_screen.tabMaterial")} />
		</TabBar>
	);

	return (
		<ScreenWithNavbarSidemenuLayout
			noSideMenuPadding
			canGoBack={!isXLargeUp}
			contentContainerId={RootContainerId}
			sideMenu={
				<SideMenuWrapper>
					<SideMenuHeaderWrapper>
						{query.node?.root && (
							<RootProgress learnOpportunityV2FragmentRef={query.node.root} />
						)}
						{tabBar}
					</SideMenuHeaderWrapper>
					<TabBar
						hideHeader
						renderActiveOnly={false}
						activeIndex={activeIndex}
						onTabChange={() => {
							//ignore just required from primereact to work with activeIndex
						}}
					>
						<TabPanel>
							<RootOverviewTab
								rootId={rootId}
								rootName={rootName}
								hasIHKCertificate={isIHK}
								isDemo={isDemo}
								hasTrailer={hasTrailer}
								hasGoals={hasGoals}
								hasInstructors={hasInstructors}
								canOrderIHK={canOrderIHK}
								receivablePoints={receivablePoints}
							/>
						</TabPanel>
						<TabPanel>
							{query.node?.root && (
								<BranchNodeTab learnOpportunityV2FragmentRef={query.node.root} />
							)}
						</TabPanel>
						<TabPanel>
							{query.node?.root && (
								<MaterialsTab learnOpportunityV2FragmentRef={query.node.root} />
							)}
						</TabPanel>
					</TabBar>
				</SideMenuWrapper>
			}
			bottomContent={<AdvanceTreeNavigation />}
		>
			<Wrapper>
				{!isXLargeUp && query.node?.root && (
					<RootProgress learnOpportunityV2FragmentRef={query.node.root} />
				)}
				{!isXLargeUp && <StickyWrapper>{tabBar}</StickyWrapper>}
				<Outlet />
			</Wrapper>
		</ScreenWithNavbarSidemenuLayout>
	);
};

export const RootScreen = () => {
	const { rootId } = useParams();
	const [spyEnabled, setSpyEnabled] = useState(false);
	const [lastContentId, setLastContentId] = useState<string>();

	return rootId ? (
		<RootScreenContext.Provider
			value={{ spyEnabled, setSpyEnabled, lastContentId, setLastContentId }}
		>
			<Suspense fallback={<RootScreenSkeleton />}>
				<RootScreenWithRootId rootId={rootId} />
			</Suspense>
		</RootScreenContext.Provider>
	) : null;
};
