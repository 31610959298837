import { useNavigate } from "react-router-dom";
import TalentJobMatchingImage from "@assets/talentJobMatchingImage.png";
import { Button } from "@components/button";
import { Path } from "@router/paths";
import { TkaP1Span } from "@themes/font-tags";
import { TrendCheckList } from "../trend-check-list";

export const TJMSection = () => {
	const navigate = useNavigate();
	const handleButtonOnClick = () => {
		navigate(Path.placement.path);
	};

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-32">
			<div>
				<img src={TalentJobMatchingImage} />
			</div>
			<div className="space-y-16 py-10 lg:px-[100px]">
				<h2 className="text-[40px] leading-[45.6px] font-bold">Talent Job Matching</h2>
				<div>
					<TkaP1Span>
						Als Unternehmen über ausreichend Fachkräfte verfügen - als Individuum den
						Wunschjob haben. Wir bringen beide zusammen.
					</TkaP1Span>
				</div>
				<TrendCheckList
					items={[
						"IT/Data Science",
						"Marketing/Kommunikation",
						"Business Administration",
						"Gesundheit/Mental Health",
					]}
				/>
				<Button
					label="Zum Talent Job Matching"
					iconName="arrowRight"
					onClick={handleButtonOnClick}
					colorVersion="outline"
				/>
			</div>
		</div>
	);
};
