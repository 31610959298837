import { useContext, useEffect, useRef } from "react";
import { AudioControls } from "@components/audio-controls";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { TkaP3Span } from "@themes/font-tags";
import { AudioPlayerContext } from "./audio-player.context";
import { AudioPlayerProps, AudioPlayerWithUrlProps } from "./audio-player.interface";
import {
	AudioPlayerWrapper,
	ProgressTimerWrapper,
	TrackProgress,
	TrackProgressWrapper,
} from "./audio-player.styles";
import { convertToReadableTime } from "./audio-player.utils";

export const AudioPlayerWithUrl = ({ url }: AudioPlayerWithUrlProps) => {
	const audioRef = useRef(new Audio(url));
	const intervalRef = useRef<any>();

	const { isPlaying, progress, start, end, duration, setIsPlaying, setProgress } =
		useContext(AudioPlayerContext);

	const startTimer = (): void => {
		clearInterval(intervalRef.current);

		intervalRef.current = setInterval(() => {
			if (audioRef.current.currentTime >= end) {
				setIsPlaying(false);
			}
			setProgress(audioRef.current.currentTime);
		}, 1000);
	};
	const initPlayer = (): void => {
		audioRef.current.volume = 0.05;
		audioRef.current.currentTime = start;

		startTimer();
	};

	const playOrThrow = (): void => {
		audioRef.current.play().catch(() => {
			// TODO: display error to user
		});
	};

	const playOrRestart = (): void => {
		if (audioRef.current.currentTime >= end) {
			audioRef.current.currentTime = start;
			setProgress(start);
			playOrThrow();
		} else {
			playOrThrow();
		}
	};

	const onRewind = (): void => {
		if (audioRef.current.currentTime - 10 <= start) audioRef.current.currentTime = start;
		else audioRef.current.currentTime -= 10;
	};
	const onForward = (): void => {
		if (audioRef.current.currentTime + 10 >= end) audioRef.current.currentTime = end;
		else audioRef.current.currentTime += 10;
	};

	const onScrub = (value: any): void => {
		audioRef.current.currentTime = value;
		setProgress(audioRef.current.currentTime);
	};

	const onScrubEnd = (): void => {
		if (!isPlaying) {
			setIsPlaying(true);
		}
	};

	const setVolume = (volume: number) => {
		audioRef.current.volume = volume / 100;
	};

	useEffect(() => {
		initPlayer();
		return () => {
			audioRef.current.pause();
			clearInterval(intervalRef.current);
		};
	}, []);

	useEffect(() => {
		if (isPlaying) playOrRestart();
		else audioRef.current.pause();
	}, [isPlaying]);

	return (
		<AudioPlayerWrapper>
			<AudioControls
				onPlayPauseClick={setIsPlaying}
				onForward={() => onForward()}
				onRewind={() => onRewind()}
				changeVolume={setVolume}
			/>
			<TrackProgressWrapper>
				<TrackProgress
					value={progress}
					min={start}
					max={end}
					step={1}
					className="progress"
					onChange={(e) => onScrub(e.value)}
					onMouseUp={onScrubEnd}
					onKeyUp={onScrubEnd}
				/>
				<ProgressTimerWrapper>
					<TkaP3Span>{convertToReadableTime(progress - start)}</TkaP3Span>
					<TkaP3Span>{convertToReadableTime(duration)}</TkaP3Span>
				</ProgressTimerWrapper>
			</TrackProgressWrapper>
		</AudioPlayerWrapper>
	);
};

export const AudioPlayer = ({ url }: AudioPlayerProps) => {
	const { t } = useTkaTranslation("audioPlayer");

	if (!url) return <div>{t("audio_player.noAudioFound")}</div>;
	return <AudioPlayerWithUrl url={url} />;
};
