import tw from "twin.macro";
import { TkaP2Span, H1Span } from "@themes/font-tags";

export const StyledElementContainer = tw.div`
    flex
    flex-col
    items-center
`;

export const Title = tw(H1Span)`
    mt-32
`;

export const Subtitle = tw(TkaP2Span)`
    mt-12
`;

export const SubmissionWrapper = tw.div`
    mt-32
    self-stretch
`;
