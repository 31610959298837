/**
 * @generated SignedSource<<8ad467cfb45a3e2d0612f0a83bcdbb15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type tagsRefetchQuery$variables = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
};
export type tagsRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"tags_AcademiesRooTagsConnectionFragment">;
};
export type tagsRefetchQuery = {
  response: tagsRefetchQuery$data;
  variables: tagsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClickable",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTopic",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "tagsRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "tags_AcademiesRooTagsConnectionFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "tagsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AcademiesTagQueries",
        "kind": "LinkedField",
        "name": "AcademiesTag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AcademiesRootTagsConnection",
            "kind": "LinkedField",
            "name": "AcademiesTags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AcademiesRootTagsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcademiesRootTag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canBeDeleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcademiesTagData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcademiesChildTagsConnection",
                        "kind": "LinkedField",
                        "name": "children",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AcademiesChildTagsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AcademiesChildTag",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AcademiesTagData",
                                    "kind": "LinkedField",
                                    "name": "data",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v5/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "subId",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc41380ecc5c31de243e9320b5a5ef6b",
    "id": null,
    "metadata": {},
    "name": "tagsRefetchQuery",
    "operationKind": "query",
    "text": "query tagsRefetchQuery(\n  $after: String = null\n  $before: String = null\n  $first: Int = null\n  $last: Int = null\n) {\n  ...tags_AcademiesRooTagsConnectionFragment_pbnwq\n}\n\nfragment tags_AcademiesRooTagsConnectionFragment_pbnwq on Query {\n  AcademiesTag {\n    AcademiesTags(first: $first, after: $after, before: $before, last: $last) {\n      edges {\n        node {\n          ...tags_AcademiesRootTagFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment tags_AcademiesRootTagFragment on AcademiesRootTag {\n  id\n  kind\n  canBeDeleted\n  data {\n    name\n    isClickable\n    isTopic\n  }\n  children {\n    edges {\n      node {\n        id\n        kind\n        data {\n          isTopic\n          isClickable\n          name\n        }\n        subId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4692290d1b0bea689a841743d5519c57";

export default node;
