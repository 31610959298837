import styled from "styled-components";
import tw from "twin.macro";
import { BreakpointInPx } from "@themes/breakpoint";
import { H1Span } from "@themes/font-tags";

export const Wrapper = styled.div<{ mediaPosition: "right" | "left" | undefined }>`
	${tw`flex
    flex-col
    lg:flex-row
    lg:justify-between
items-center
mb-[4.375rem]
gap-[2rem]
lg:gap-[5.625rem]
lg:mb-[5rem]`}
	@media screen and (min-width: ${BreakpointInPx.large}px) {
		flex-direction: ${(props) => (props.mediaPosition === "left" ? "row" : "row-reverse")};
	}
`;

export const TextWrapper = tw.div`
flex
flex-col
gap-[1rem]
md:w-[50%]
lg:w-[40%]
`;

export const ButtonWrapper = tw.div`
flex
gap-[1rem]`;

export const VideoPlayerWrapper = tw.div`
w-[50%]`;

export const StyledTkaH1Span = tw(H1Span)`
 text-4xl`;

export const Img = tw.img`
 h-auto
 max-w-[100%]
 md:max-w-[550px]
 xl:max-w-[700px]`;
