import { Dialog } from "primereact/dialog";
import { brandMain100 } from "@themes/colors";
import { contentStyle, style } from "./modal.const";
import { ChildrenWrapper, CloseIconWrapper } from "./modal.styles";
import { ModalProps } from "./modal.types";
import { Icon } from "../icon";

export const Modal = ({
	children,
	isVisible = false,
	onHide,
	modal = true,
	backgroundColor,
	dismissable,
	position = "bottom",
	fullWidth,
	marginRem = 0,
	padding,
	maximized,
	withShadow,
	borderRadiusRem,
	hasCloseIcon,
}: ModalProps) => {
	const borderRem = `${borderRadiusRem ?? 0.75}rem`;
	const borderRadius =
		position == "bottom" && fullWidth !== false
			? `${borderRem} ${borderRem} 0px 0px`
			: borderRem;
	const contentStyleCss = {
		...contentStyle,
		backgroundColor: backgroundColor?.getColor(),
		borderRadius,
	};
	const styleCss = {
		...style,
		width: position == "bottom" && fullWidth !== false ? "100%" : "unset",
		margin: `${marginRem}rem`,
		borderRadius,
		boxShadow: withShadow
			? `0.625rem 0.625rem 2.25rem 0 ${brandMain100.withAlpha(0.1).getColor()}`
			: "none",
	};

	const handleOnHide = () => {
		onHide?.();
	};

	return (
		<Dialog
			visible={isVisible}
			modal={modal}
			showHeader={false}
			position={position}
			contentStyle={contentStyleCss}
			style={styleCss}
			onHide={handleOnHide}
			dismissableMask={dismissable}
			draggable={false}
			resizable={false}
			maximized={maximized}
		>
			<ChildrenWrapper $padding={padding}>{children}</ChildrenWrapper>
			{hasCloseIcon && (
				<CloseIconWrapper>
					<Icon icon="close" sizeInRem={1} onClick={handleOnHide} />
				</CloseIconWrapper>
			)}
		</Dialog>
	);
};
