/**
 * @generated SignedSource<<04e376151136e68575d4e28501fa62ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionModalContextProvider_ContentSubmissionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionFailureModal_ContentSubmissionFragment" | "ContentSubmissionSuccessModal_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionModalContextProvider_ContentSubmissionFragment";
};
export type ContentSubmissionModalContextProvider_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionModalContextProvider_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionModalContextProvider_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionModalContextProvider_ContentSubmissionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentSubmissionFailureModal_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentSubmissionSuccessModal_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "c2d9a709c0f50d5d88b6279294935cee";

export default node;
