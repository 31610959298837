import { Button } from "@components/button";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { H1Span, TkaP1Span } from "@themes/font-tags";
import { PasswordResetSuccessStepProps } from "./password-reset-success-step.interface";
import { Wrapper, TextCenter } from "./password-reset-success-step.styles";

export const PasswordResetSuccessStep = ({ onGoToLogin }: PasswordResetSuccessStepProps) => {
	const { t } = useTkaTranslation("resetPasswordForm");

	return (
		<Wrapper>
			<TextCenter>
				<H1Span>{t("reset_password_form.successTitle")}</H1Span>
				<TkaP1Span>{t("reset_password_form.successBody")}</TkaP1Span>
			</TextCenter>
			<Button
				fillParent
				label={t("reset_password_form.successButtonText")}
				iconName="arrowRight"
				onClick={onGoToLogin}
			/>
		</Wrapper>
	);
};
