import { DayOfWeek } from "@js-joda/core";
import { TabPanel } from "primereact/tabview";
import { Message } from "@components/message";
import { TabBar } from "@components/tab-bar";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";

import { H1Span, TkaP2Span } from "@themes/font-tags";

import { TabWrapper, TitleWrapper, Wrapper } from "./my-availabilities.styles";
import { DayScheduleSkeleton } from "./parts/day-schedule/day-schedule.skeleton";

// TODO: add-translations
export const MyAvailabilitiesScreenSkeleton = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
			<Wrapper>
				<TitleWrapper>
					<H1Span>Meine Verfügbarkeiten</H1Span>
					<TkaP2Span>
						Lege deine Verfügbarkeiten so an, dass du dich damit wohlfühlst und optimal
						arbeiten kannst.
					</TkaP2Span>
				</TitleWrapper>
				<TabBar renderActiveOnly={true}>
					<TabPanel header="Verfügbarkeiten">
						<TabWrapper>
							<Message
								highlighted
								severity="warn"
								summary="Terminbuchungen"
								detail="Bestehende Terminbuchungen werden durch das Verändern deiner Verfügbarkeiten nicht beeinflusst."
							/>
							{DayOfWeek.values().map((day) => (
								<DayScheduleSkeleton key={day.toString()} />
							))}
						</TabWrapper>
					</TabPanel>
					<TabPanel header="Blockout Dates" />
				</TabBar>
			</Wrapper>
		</ScreenWithProfileMenuLayout>
	);
};
