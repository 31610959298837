import styled from "styled-components";
import tw from "twin.macro";
import { TkaP2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
    relative
    flex-1
    flex 
    flex-row
    items-start
`;

export const BackgroundWrapper = tw.div`
    lg:absolute
    lg:left-0
    lg:right-0
    lg:top-0
    flex 
    flex-row
    items-start
    gap-16 
    pl-16 
    py-24 
    pr-24
    bg-brand-strong-20
    rounded-8
`;

export const InplaceWrapper = tw.div`
    absolute
    h-full
`;

export const Text = styled(TkaP2Span)<{ lines: number }>`
	${tw`
        text-ellipsis
        overflow-hidden
        line-clamp-6
    `}
	-webkit-line-clamp: ${({ lines }) => lines};
`;
