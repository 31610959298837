import tw from "twin.macro";

export const ListWrapper = tw.div`
	flex
	flex-col
	gap-[1rem]
`;

export const CardWrapper = tw.div`
flex
h-[3rem]`;
