import { useState } from "react";
import { BottomSheet } from "@components/bottom-sheet";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { HubspotHiddenGlobalStyle } from "@components/hubspot-style/hubspot-style.styles";
import { Icon } from "@components/icon";
import { useWindowSize } from "@hooks/use-window-size";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import {
	ActionWrapper,
	BatchEditBar,
	CenterSpacer,
	HoverOpacity,
	ItemWrapper,
	OptionsWrapper,
	TitleWrapper,
	VerticalDivider,
} from "./batch-edit.styles";
import { BatchEditProps } from "./batch-edit.types";

export const BatchEdit = ({ isVisible, optionNodes, selectedCount, onClear }: BatchEditProps) => {
	const [isVisibleBottomSheet, setIsVisibleBottomSheet] = useState(false);
	const { isMediumUp } = useWindowSize();

	const handleChooseOptionOnClick = () => {
		setIsVisibleBottomSheet(true);
	};

	const handleCloseBottomSheetOnClick = () => {
		setIsVisibleBottomSheet(false);
	};

	const nodes = optionNodes(ItemWrapper);
	const options = (
		<OptionsWrapper>
			{nodes.map((option, index) => [
				option,
				index < nodes.length - 1 && !isMediumUp && <Divider />,
			])}
		</OptionsWrapper>
	);
	return (
		<>
			{isVisible && (
				<BatchEditBar>
					{isMediumUp ? (
						options
					) : (
						<ActionWrapper onClick={handleChooseOptionOnClick}>
							<Icon icon="arrowDown" sizeInRem={1} />
							<TkaP2Span>Aktion auswählen</TkaP2Span>
						</ActionWrapper>
					)}
					<VerticalDivider />
					<TkaP2Span>
						{isMediumUp ? "Ausgewählt " : ""}
						{selectedCount}
					</TkaP2Span>
					<HoverOpacity>
						<Icon icon="close" sizeInRem={1} onClick={onClear} />
					</HoverOpacity>
				</BatchEditBar>
			)}
			<BottomSheet
				isVisible={isVisible && isVisibleBottomSheet && !isMediumUp}
				onDismiss={handleCloseBottomSheetOnClick}
			>
				<TitleWrapper>
					<H1Span>Aktion auswählen</H1Span>
					<TkaP2Span>{selectedCount} Elemente ausgewählt</TkaP2Span>
				</TitleWrapper>
				<CenterSpacer>
					{options}
					<Divider />
				</CenterSpacer>
				<Button fillParent label="Schließen" onClick={handleCloseBottomSheetOnClick} />
			</BottomSheet>
			{!isMediumUp && isVisible && <HubspotHiddenGlobalStyle />}
		</>
	);
};
