import graphql from "babel-plugin-relay/macro";

export const USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT = graphql`
	fragment groupCard_UserInAccountGroupWrapperFragment on UserInAccountGroupWrapper {
		id
		memberCount
		group {
			id
			name
			isBuiltIn
		}
		...groupContextMenu_UserInAccountGroupWrapperFragment
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment groupCard_QueryFragment on Query @refetchable(queryName: "groupCardRefetchQuery") {
		...groupContextMenu_QueryFragment
	}
`;
