import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment appointmentStatusLabel_AvailabilityFragment on Availability {
		data {
			startDateTime
			endDateTime
		}
	}
`;
