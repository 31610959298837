import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const receivablePointsClass = css({
	fontSize: "20",
	fontWeight: "extraBold",
});

export const wrapperClass = flex({
	flexDirection: "row",
	justifyContent: "center",
	maxW: "[24rem]",
	alignItems: "center",
	p: "16",
	gap: "8",
	bg: "shade.10",
	borderRadius: "12",
});
