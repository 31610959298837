import { ZoneId, ZonedDateTime } from "@js-joda/core";
import { PathParams } from "@thekeytechnology/epic-ui";
import { useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { useParams, useSearchParams } from "react-router-dom";
import { BookCoachingCard } from "@components/book-coaching-card";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { Calendar } from "@components/calendar";
import { DropdownCoach } from "@components/dropdown-coach";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { withSuspense } from "@components/with-suspense";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";
import { bookAppointment_BookCoachingMutation } from "@relay/bookAppointment_BookCoachingMutation.graphql";
import { bookAppointment_GetCoachingQuery } from "@relay/bookAppointment_GetCoachingQuery.graphql";
import { bookAppointment_QueryFragment$key } from "@relay/bookAppointment_QueryFragment.graphql";
import { BookAppointment_QueryFragmentRefetch } from "@relay/BookAppointment_QueryFragmentRefetch.graphql";
import { BookAppointmentPath } from "@router/paths";
import { H1Span, TkaH2Span } from "@themes/font-tags";
import { getLocalDate } from "@utils/date-utils";
import { COACH_PROFILE_ID_KEY } from "./book-appointment.consts";
import { BOOK_COACHING_MUTATION, QUERY, QUERY_FRAGMENT } from "./book-appointment.graphql";
import { BookAppointmentScreenSkeleton } from "./book-appointment.skeleton";
import {
	BookCoachingPreviewWrapper,
	CalendarTimeSlotWrapper,
	CalendarWrapper,
	CoachWrapper,
	TimeSlotsWrapper,
	TitleContentWrapper,
	Wrapper,
} from "./book-appointment.styles";
import { AvailabililtyFragment, BookAppointmentScreenProps } from "./book-appointment.types";
import { BookingSuccessModal } from "./parts/booking-success-modal";
import { TimeSlotCard } from "./parts/time-slot-card";

// TODO: add-translations
const BookAppointmentScreenWithCoachingId = ({ coachingId }: BookAppointmentScreenProps) => {
	const { node: coaching, ...query } = useLazyLoadQuery<bookAppointment_GetCoachingQuery>(
		QUERY,
		{ id: coachingId },
		{ fetchPolicy: "store-and-network" },
	);

	const {
		data: { Coaching },
		refetch,
	} = usePaginationFragment<
		BookAppointment_QueryFragmentRefetch,
		bookAppointment_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const [bookCoaching, loadingBookCoaching] =
		useMutation<bookAppointment_BookCoachingMutation>(BOOK_COACHING_MUTATION);

	const [searchParams, setSearchParams] = useSearchParams();
	const coachProfileId = searchParams.get(COACH_PROFILE_ID_KEY) ?? undefined;

	const [bookedAvailability, setBookedAvailability] = useState<AvailabililtyFragment>();

	const [date, setDate] = useState<Date>(new Date());
	const [startZonedDateTime, setStartZonedDateTime] = useState<string>();
	const [endZonedDateTime, setEndZonedDateTime] = useState<string>();
	const [availabilityId, setAvailabilityId] = useState<string>();

	useEffect(() => {
		const selectedCoachProfile = coaching?.associatedCoaches?.find(
			(coachProfile) => coachProfile.id === coachProfileId,
		);
		if (!selectedCoachProfile?.coach?.id) return;
		const localDate = getLocalDate(date);
		const offset = new Date().getTimezoneOffset();
		const filterByMinDate = (offset < 0 ? localDate.minusDays(1) : localDate).toString();
		const filterByMaxDate = (offset < 0 ? localDate.plusDays(1) : localDate).toString();
		refetch(
			{
				filterByCoach: selectedCoachProfile.coach.id,
				filterByMinDate,
				filterByMaxDate,
				skipQuery: false,
			},
			{ fetchPolicy: "store-and-network" },
		);
	}, [coachProfileId, coaching?.associatedCoaches, date]);

	const handleCoachOnChange = (coachProfileId: string) => {
		setSearchParams({ [COACH_PROFILE_ID_KEY]: coachProfileId }, { replace: true });
	};

	const handleDateOnChange = (date?: Date) => {
		if (!date) return;
		setDate(date);
		setStartZonedDateTime(undefined);
		setEndZonedDateTime(undefined);
		setAvailabilityId(undefined);
	};

	const createDateTimeOnChangeHandler =
		(id: string) => (dateTimeStart: string, dateTimeEnd: string) => {
			setStartZonedDateTime(dateTimeStart);
			setEndZonedDateTime(dateTimeEnd);
			setAvailabilityId(id);
		};

	const handleOnActionButtonClick = () => {
		availabilityId &&
			bookCoaching({
				variables: {
					input: {
						availabilityId,
						coachingId,
					},
				},
				onCompleted: (response) => {
					setBookedAvailability(response.Coaching.bookCoaching?.availability);
				},
			});
	};

	const renderBookCoachingCard = (noBorder?: boolean) => (
		<BookCoachingCard
			noBorder={noBorder}
			imageUrl={coaching?.description?.image?.url}
			title={coaching?.description?.name ?? ""}
			zonedDateTimeStart={startZonedDateTime}
			zonedDateTimeEnd={endZonedDateTime}
		/>
	);

	const filteredSlots = useMemo(() => {
		return (
			Coaching?.FreeAvailabilities?.edges?.filter((slot) => {
				if (!slot) return false;
				const startDate = ZonedDateTime.parse(slot.node.data.startDateTime);
				const selectedDateStart = ZonedDateTime.parse(date.toISOString())
					.withZoneSameInstant(ZoneId.SYSTEM)
					.toLocalDate()
					.atStartOfDay()
					.atZone(ZoneId.SYSTEM);

				const selectedEndStart = selectedDateStart.plusDays(1);
				return (
					startDate.isAfter(selectedDateStart.minusMinutes(1)) &&
					startDate.isAfter(ZonedDateTime.now()) &&
					startDate.isBefore(selectedEndStart)
				);
			}) ?? []
		);
	}, [Coaching?.FreeAvailabilities?.edges]);

	const dayHasTimeSlots = !!filteredSlots.length;

	const availableLicensesCount = coaching?.licenseAvailability?.numAvailable ?? 0;
	const licensesText =
		availableLicensesCount > 0
			? `${availableLicensesCount} x Lizenz${
					availableLicensesCount > 1 ? "en" : ""
			  } verfügbar`
			: "Keine Lizenz verfügbar";

	const canBook = availableLicensesCount > 0 && startZonedDateTime && coachProfileId;

	return (
		<ScreenSidemenuLayout
			canGoBack
			headerLeftIconName="close"
			sideMenu={renderBookCoachingCard()}
			bottomContent={
				<>
					<BookCoachingPreviewWrapper>
						{renderBookCoachingCard(true)}
					</BookCoachingPreviewWrapper>
					<BottomNavigationTemplate
						canGoBack
						actionButtonLabel="Coaching buchen"
						actionButtonIconName="calendar"
						actionButtonDisabled={!canBook}
						actionButtonLoading={loadingBookCoaching}
						onActionButtonClick={handleOnActionButtonClick}
						bottomText={licensesText}
					/>
				</>
			}
		>
			<Wrapper>
				<H1Span>Termin Buchen</H1Span>
				<CoachWrapper>
					<DropdownCoach
						label="Coach"
						placeholder="Wähle einen Coach"
						selectedCoachProfileId={coachProfileId}
						onChange={handleCoachOnChange}
						coachProfileFragmentsRef={coaching?.associatedCoaches}
					/>
				</CoachWrapper>
				<CalendarTimeSlotWrapper>
					<CalendarWrapper>
						<TkaH2Span>Datum wählen</TkaH2Span>
						<Calendar inline value={date} onChange={handleDateOnChange} />
					</CalendarWrapper>
					<TitleContentWrapper>
						<EmptyPlaceholder
							isVisible={!dayHasTimeSlots}
							iconName="calendarDisable"
							title="Keine Termine mehr verfügbar"
							subtitle="An diesem Datum sind leider keine Termine mehr verfügbar. Wähle ein anderes Datum aus."
						>
							<TkaH2Span>Uhrzeit wählen</TkaH2Span>
							<TimeSlotsWrapper>
								{filteredSlots?.map(
									(slot) =>
										slot?.node.data.startDateTime && (
											<TimeSlotCard
												key={slot?.node.data.startDateTime}
												active={
													slot?.node.data.startDateTime ===
													startZonedDateTime
												}
												zonedDateTimeStart={slot?.node.data.startDateTime}
												zonedDateTimeEnd={slot?.node.data.endDateTime}
												onClick={createDateTimeOnChangeHandler(
													slot.node.id,
												)}
											/>
										),
								)}
							</TimeSlotsWrapper>
						</EmptyPlaceholder>
					</TitleContentWrapper>
				</CalendarTimeSlotWrapper>
				<BookingSuccessModal />
			</Wrapper>
			<BookingSuccessModal
				isVisible={!!bookedAvailability}
				availabilityFragmentRef={bookedAvailability}
			/>
		</ScreenSidemenuLayout>
	);
};

const BookAppointmentScreenComponent = () => {
	const { coachingId } = useParams<PathParams<typeof BookAppointmentPath>>();
	return coachingId ? <BookAppointmentScreenWithCoachingId coachingId={coachingId} /> : null;
};

export const BookAppointmentScreen = withSuspense(
	BookAppointmentScreenComponent,
	BookAppointmentScreenSkeleton,
);
