import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div<{ grid?: boolean }>`
	${(props) => props.grid && tw`grid-cols-4 gap-32 grid-rows-[min-content]`};
`;

export const HeaderWrapper = tw.div`
	flex
	flex-col
	col-span-4
`;

export const ContentPath = tw.div`
	mt-16
	mb-4
`;

export const DescriptionImageWrapper = tw.div`
	flex
	flex-col-reverse
	md:flex-row
	gap-y-32
	md:gap-x-16
	justify-between
	mt-12
`;

export const DescriptionWrapper = tw.div`

`;

export const ImageWrapper = styled.div<{ url: string }>`
	background-image: url(${({ url }) => url});
	${tw`
		md:w-[16rem]
		md:h-[9rem]
		lg:w-[24rem]
		lg:h-[13.5rem]
		bg-cover
		bg-center
		rounded-10
		aspect-video
	`}
`;

export const ExtimatePointsWrapper = tw.div`
	flex
	flex-row
	justify-between
`;
