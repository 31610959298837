/**
 * @generated SignedSource<<0b28078b450124b3e1a8d2add9f4eaeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type groupContextMenu_UserInAccountGroupWrapperFragment$data = {
  readonly group: {
    readonly id: string;
    readonly isBuiltIn: boolean;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"editGroupModal_UserInAccountGroupFragment">;
  };
  readonly " $fragmentType": "groupContextMenu_UserInAccountGroupWrapperFragment";
};
export type groupContextMenu_UserInAccountGroupWrapperFragment$key = {
  readonly " $data"?: groupContextMenu_UserInAccountGroupWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupContextMenu_UserInAccountGroupWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "groupContextMenu_UserInAccountGroupWrapperFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBuiltIn",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "editGroupModal_UserInAccountGroupFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserInAccountGroupWrapper",
  "abstractKey": null
};

(node as any).hash = "afb01526b302fa74442d27b6a969a391";

export default node;
