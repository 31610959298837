import { useFormik } from "formik";
import { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { Wrapper } from "@components/input-text/input-text.styles";
import { ValidatedInputChips } from "@components/validated-input-chips/validated-input-chips.component";
import { standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation } from "@relay/standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation.graphql";
import { AccountContext } from "@screens/account";
import { GroupsMultiSelect } from "@screens/account/parts/groups-multi-select";
import { Spacing24 } from "@screens/profile/profile.styles";
import { INVITE_MUTATION, QUERY_FRAGMENT } from "./standard-invite-form.graphql";
import { StandardInviteFormRef, StandardInviteFormProps } from "./standard-invite-form.types";
import { InviteMemberModalFormState } from "../invite-member-modal/invite-member-modal.types";

export const StandardInviteForm = forwardRef<StandardInviteFormRef, StandardInviteFormProps>(
	function StandardInviteForm(
		{
			selectedUsers,
			queryFragmentRef,
			selectedGroups,
			groupOptions,
			setInviteMembersMutationResponse,
			onSuccess,
		},
		ref,
	) {
		const data = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
		const { membersConnectionIds, addGroupsConnectionId } = useContext(AccountContext);

		const groupsConnectionId =
			data?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers
				.__id;

		useEffect(() => {
			groupsConnectionId && addGroupsConnectionId(groupsConnectionId);
		}, [groupsConnectionId]);

		const availableGroups =
			data?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers.edges
				?.map((edge) => edge?.node.group!)
				.filter(Boolean) ?? [];

		const selectedUserEmails = selectedUsers && selectedUsers.map((user) => user.email);
		const formattedSelectedGroups = selectedGroups?.map((group) => {
			return { id: group.id, name: group.name };
		});

		const [invite] =
			useMutation<standardInviteForm_inviteOrAddUsersToUserInAccountGroupsMutation>(
				INVITE_MUTATION,
			);

		const form = useFormik<InviteMemberModalFormState>({
			initialValues: {
				emails: selectedUserEmails ?? [],
				groups: formattedSelectedGroups ?? [],
			},
			validateOnChange: true,
			validateOnBlur: true,
			validationSchema: Yup.object().shape({
				emails: Yup.array()
					.of(Yup.string().email("Bitte gib eine gültige E-Mail Adresse ein"))
					.required("Bitte gib eine E-Mail Adresse ein"),
			}),
			onSubmit: (values) => {
				invite({
					variables: {
						input: {
							emails: values.emails,
							groupIds: values.groups?.map((group) => group.id) ?? [],
						},
						connections: membersConnectionIds,
					},
					onCompleted: (response) => {
						form.resetForm({});
						form.setErrors({});
						setInviteMembersMutationResponse &&
							setInviteMembersMutationResponse(
								response.AccountMemberManagement
									.inviteOrAddUsersToUserInAccountGroups,
							);
						onSuccess?.();
					},
				});
			},
		});
		useImperativeHandle(ref, () => ({
			submit: () => {
				form.submitForm();
			},
		}));

		const handleOnRemoveGroup = (id?: string) => {
			const updatedGroups = form?.values?.groups?.filter((group) => group.id !== id);
			form.setFieldValue("groups", updatedGroups);
		};

		return (
			<form onSubmit={form.handleSubmit}>
				<Wrapper>
					<ValidatedInputChips
						formikConfig={form}
						label={"Benutzer"}
						icon={"user"}
						iconSizeInRem={1.5}
						placeholder="Benutzer Email"
						name="emails"
						addOnBlur
					/>
					<Spacing24 />
					<GroupsMultiSelect
						formikConfig={form}
						label="Gruppenzugehörigkeit"
						options={availableGroups || groupOptions}
						optionLabel="name"
						value={form.values.groups}
						name="groups"
						onCloseClick={(id) => handleOnRemoveGroup(id)}
					/>
				</Wrapper>
			</form>
		);
	},
);
