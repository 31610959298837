/**
 * @generated SignedSource<<4c099b78b65d566dfc56de9ee9dc90d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CountryCode = "AT" | "CH" | "DE";
export type offerCard_Query$variables = {};
export type offerCard_Query$data = {
  readonly AccountBaseData: {
    readonly AccountBaseData: {
      readonly countryCode?: CountryCode | null;
    };
  };
};
export type offerCard_Query = {
  response: offerCard_Query$data;
  variables: offerCard_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countryCode",
    "storageKey": null
  }
],
v1 = {
  "kind": "InlineFragment",
  "selections": (v0/*: any*/),
  "type": "PrivateBaseData",
  "abstractKey": null
},
v2 = {
  "kind": "InlineFragment",
  "selections": (v0/*: any*/),
  "type": "BusinessBaseData",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "offerCard_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataQueries",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "offerCard_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataQueries",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "121344f3b0dc3e7ccd3dcb03a96b0bfc",
    "id": null,
    "metadata": {},
    "name": "offerCard_Query",
    "operationKind": "query",
    "text": "query offerCard_Query {\n  AccountBaseData {\n    AccountBaseData {\n      __typename\n      ... on PrivateBaseData {\n        countryCode\n      }\n      ... on BusinessBaseData {\n        countryCode\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a22fcc3ace8c15bf848154824bc88f8";

export default node;
