import { OrderElement_ContentSubmissionFragment$key } from "@relay/OrderElement_ContentSubmissionFragment.graphql";
import { OrderElement } from "./OrderElement.component";

export interface OrderElementProps {
	contentSubmissionFragmentRef: OrderElement_ContentSubmissionFragment$key;
}

export interface OrderElement {
	readonly id: string;
	readonly text: string;
}

export type OrderElementMap = { [key: string]: OrderElement };

export enum OrderElementStatus {
	Default,
	Correct,
	Wrong,
	ActuallyCorrect,
	Disabled,
}
