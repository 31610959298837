import graphql from "babel-plugin-relay/macro";

export const LICENSE_GROUPING_FRAGMENT = graphql`
	fragment licensesProductCard_QueryFragment on LicenseGrouping {
		kind
		... on LearnableLicenseGrouping {
			learnable {
				... on PublishedGroupCoachingLearnable {
					groupCoaching {
						id
						description {
							contractPartner {
								data {
									name
									linkToAGB
								}
							}
							name
							image {
								thumbnail
							}
						}
					}
				}
				... on PublishedOneToOneCoachingLearnable {
					oneToOneCoaching {
						id
						description {
							contractPartner {
								data {
									name
									linkToAGB
								}
							}
							name
							image {
								thumbnail
							}
						}
					}
				}
				... on PublishedCourseLearnable {
					id
					root {
						id
						description
						image {
							thumbnail
						}
						structureDefinition {
							title
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on PublishedRootExtensionImpl {
										extension {
											... on PublishedConstellationRootExtensionExtension {
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				... on PublishedMicroLearningLearnable {
					id
					root {
						id
						description
						image {
							thumbnail
						}
						structureDefinition {
							title
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on PublishedRootExtensionImpl {
										extension {
											... on PublishedConstellationRootExtensionExtension {
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
