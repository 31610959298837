import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
	items-center
	gap-12
`;

export const DeleteIconWrapper = tw.div`
	pr-12
`;

export const NameAndGroupsWrapper = tw.div`
flex
items-center
flex-1`;
