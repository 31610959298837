/**
 * @generated SignedSource<<715fb94b7894e8a652c8aca3f00002b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profileTab_UserFragment$data = {
  readonly extension: {
    readonly firstName?: string;
    readonly lastName?: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
  readonly " $fragmentType": "profileTab_UserFragment";
};
export type profileTab_UserFragment$key = {
  readonly " $data"?: profileTab_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileTab_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileTab_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "userExtensionKind",
          "value": "Academies"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "type": "AcademiesUserExtension",
          "abstractKey": null
        }
      ],
      "storageKey": "extension(userExtensionKind:\"Academies\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userAvatar_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0f206e0c06708264fb503fc58d6a2b98";

export default node;
