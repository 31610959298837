import tw from "twin.macro";

export const StyledGrid = tw.div`gap-24 grid grid-cols-12 auto-rows-fr`;
export const Gap = tw.div`h-24`;
export const GridItem = tw.div`
    col-span-full
    md:col-span-6
    xl:col-span-4  
    xxl:col-span-3  
    row-span-1
`;
