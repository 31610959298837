import tw from "twin.macro";

export const MenuWrapper = tw.div`
    flex
    flex-col
    md:w-[min(22rem, 90vw)]
    gap-24
    bg-shade-0
`;

export const AccountRow = tw.div`
    flex
    flex-row
    gap-16
    items-center
    cursor-pointer
    hover:opacity-70
`;

export const FlexGrow = tw.div`
    flex-grow
    flex
    flex-row
    gap-16
    items-center
`;

export const CurrentUserWrapper = tw.div`
    flex
    flex-col
    gap-12
    items-center
`;

export const SignOutRow = tw.div`
    flex
    flex-row
    gap-12
    items-center
    justify-center
    cursor-pointer
    hover:opacity-70
`;
