import graphql from "babel-plugin-relay/macro";

export const TREND_OVERVIEW_FRAGMENT = graphql`
	fragment trendOverview_trendFragment on Query {
		CustomerJourney {
			trend {
				edges {
					node {
						id

						trendData {
							name
							visible
						}
						...trendCard_trendFragment
					}
				}
			}
		}
	}
`;
