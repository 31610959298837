import { ValidatedMultiSelect } from "@components/validated-multi-select";
import { GroupsMultiSelectProps } from "./groups-multi-select.types";
import { getGroupName } from "../utils";

export const GroupsMultiSelect = <T,>({ options, ...props }: GroupsMultiSelectProps<T>) => {
	const renamedOptions = options?.map((option) => ({
		...option,
		name: getGroupName(option.id, option.name),
	}));
	return <ValidatedMultiSelect {...props} options={renamedOptions} />;
};
