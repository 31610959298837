import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { accountContextMenu_AuthViewerSchemaFragment$key } from "@relay/accountContextMenu_AuthViewerSchemaFragment.graphql";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "@screens/account/parts/account-tab/parts/account-context-menu/account-context-menu.graphql";
import { selectHasPermission } from "@slices/CurrentUserSlice";
import { error100 } from "@themes/colors";
import { spacing8 } from "@themes/spacing";
import { ItemWrapper, OptionsWrapper, Wrapper } from "./account-context-menu.styles";
import {
	type AccountContextMenuProps,
	type AccountContextMenuRef,
} from "./account-context-menu.types";
import { DeleteAccountModalsFlow } from "../delete-account-modals-flow";
import { LeaveAccountModal } from "../leave-account-modal/leave-account-modal.component";

export const AccountContextMenu = forwardRef<AccountContextMenuRef, AccountContextMenuProps>(
	function AccountContextMenu({ authViewerSchemaFragmentRef }, ref) {
		const authFragment = useFragment<accountContextMenu_AuthViewerSchemaFragment$key>(
			AUTH_VIEWER_SCHEMA_FRAGMENT,
			authViewerSchemaFragmentRef,
		);
		const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);
		const [showLeaveAccountModal, setShowLeaveAccountModal] = useState(false);
		const [showDeleteAccountFlow, setShowDeleteAccountFlow] = useState(false);

		useImperativeHandle(ref, () => ({
			toggle: (event, target) => {
				overlayRef?.current?.toggle(event, target);
			},
		}));

		const handleOnLeaveAccountClick = () => {
			overlayRef?.current?.hide();
			setShowLeaveAccountModal(true);
		};

		const handleOnDeleteAccountClick = () => {
			overlayRef?.current?.hide();
			setShowDeleteAccountFlow(true);
		};
		const isManager = useSelector(
			(state) => selectHasPermission(state, "UserInAccountPermission_System_Owner"), //TODO Test with this permission
		);
		const canLeaveAccount = true;
		const canDeleteAccount = isManager;

		return (
			<>
				<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing8}>
					<Wrapper>
						<OptionsWrapper>
							{canLeaveAccount && (
								<ItemWrapper onClick={handleOnLeaveAccountClick}>
									<Icon icon="logout" sizeInRem={1.5} />
									Konto verlassen
								</ItemWrapper>
							)}
							{canDeleteAccount && (
								<ItemWrapper onClick={handleOnDeleteAccountClick} dangerous>
									<Icon icon="trash" sizeInRem={1.5} tkaColor={error100} />
									Konto löschen
								</ItemWrapper>
							)}
						</OptionsWrapper>
					</Wrapper>
				</ResponsiveBottomSheetOverlayPanel>
				{showLeaveAccountModal && (
					<LeaveAccountModal
						isVisible
						onDismiss={() => setShowLeaveAccountModal(false)}
						onSuccess={() => setShowLeaveAccountModal(false)}
						authViewerSchemaFragmentRef={authFragment}
					/>
				)}
				{showDeleteAccountFlow && (
					<DeleteAccountModalsFlow
						isVisible
						onDismiss={() => setShowDeleteAccountFlow(false)}
						authViewerSchemaFragmentRef={authFragment}
					/>
				)}
			</>
		);
	},
);
