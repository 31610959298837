import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment appointmentModalsFlow_AvailabilityFragment on Availability {
		...appointmentOverviewModal_AvailabilityFragment
		...cancelAppointmentModal_AvailabilityFragment
		...cancelAppointmentSuccessModal_AvailabilityFragment
		...cancelAppointmentCoachModal_AvailabilityFragment
		...cancelAppointmentMessageCoachModal_AvailabilityFragment
	}
`;
