import { ValidatedInputChipsProps } from "./validated-input-chips.interface";
import { InputChips } from "../input-chips/input-chips.component";

export function ValidatedInputChips<State>({
	formikConfig,
	name,
	label,
	icon,
	iconSizeInRem,
	placeholder,
	helpText,
	...props
}: ValidatedInputChipsProps<State>) {
	const allErrorMessages = formikConfig.errors[name] as unknown as string | undefined;
	const errorMessage = Array.isArray(allErrorMessages) ? allErrorMessages[0] : allErrorMessages;

	const hasError = !!(errorMessage && formikConfig.touched[name]);

	const value = formikConfig.values[name] as unknown as string[] | [];
	const updateValue = (updatedValue: string[]) => {
		formikConfig.setFieldTouched(name, true);
		formikConfig.setFieldValue(name, updatedValue);
	};
	const textBelow = hasError ? errorMessage : helpText;

	return (
		<InputChips
			{...props}
			label={label}
			name={name}
			icon={icon}
			iconSizeInRem={iconSizeInRem}
			value={value}
			placeholder={placeholder}
			onChange={updateValue}
			hasError={hasError}
			smallTextBelow={textBelow}
		/>
	);
}
