import { ZonedDateTime } from "@js-joda/core";
import { appointments_AvailabilityFragment$data } from "@relay/appointments_AvailabilityFragment.graphql";
import { AppointmentStatus, getAppointmentStatus } from "@utils/appointment-utils";

export const sortAppointments = (appointments: appointments_AvailabilityFragment$data[]) => {
	const endedAppointments: appointments_AvailabilityFragment$data[] = [];
	const confirmedAppointments: appointments_AvailabilityFragment$data[] = [];
	const runningAppointments: appointments_AvailabilityFragment$data[] = [];
	appointments.forEach((appointment) => {
		const status = getAppointmentStatus(
			appointment.data.startDateTime,
			appointment.data.endDateTime,
		);
		if (status === AppointmentStatus.Ended) {
			endedAppointments.push(appointment);
		} else if (status === AppointmentStatus.Confirmed) {
			confirmedAppointments.push(appointment);
		} else if (status === AppointmentStatus.Running) {
			runningAppointments.push(appointment);
		}
	});

	return [
		...sortAppointmentsByDate(runningAppointments),
		...sortAppointmentsByDate(confirmedAppointments),
		...sortAppointmentsByDate(endedAppointments, true),
	];
};

export const sortAppointmentsByDate = (
	appointments: appointments_AvailabilityFragment$data[],
	descending?: boolean,
) => {
	return appointments.sort((a, b) => {
		const diff = ZonedDateTime.parse(a.data.startDateTime).compareTo(
			ZonedDateTime.parse(b.data.startDateTime),
		);
		return descending ? -diff : diff;
	});
};
