import {
	shade0,
	shade5,
	success100,
	brandStrong100,
	shade100,
	shade20,
	brandStrong20,
} from "@themes/colors";
import { GapTextStatus } from "./gap-text.interface";

export const getBorderColor = (status: GapTextStatus, hasText: boolean, selected: boolean) => {
	if (selected) return shade100;
	switch (status) {
		case GapTextStatus.Correct:
			return success100;
		case GapTextStatus.ActuallyCorrect:
			return brandStrong100;
		default:
			return hasText ? shade20 : shade5;
	}
};

export const getBackgroundColor = (status: GapTextStatus, hasText: boolean) => {
	switch (status) {
		case GapTextStatus.Correct:
			return success100;
		case GapTextStatus.ActuallyCorrect:
			return brandStrong20;
		default:
			return hasText ? shade0 : shade5;
	}
};

export const getTextColor = (status: GapTextStatus) => {
	switch (status) {
		case GapTextStatus.Correct:
			return shade0;
		case GapTextStatus.ActuallyCorrect:
		default:
			return shade100;
	}
};
