import { GrowDiv, SmallTextBelow, StyledLabel, Wrapper } from "./input-text.styles";
import { InputTextSkeletonProps } from "./input-text.types";
import { getLabelColor } from "./input-text.util";
import { Skeleton } from "../skeleton";

export const InputTextSkeleton = ({ label, smallTextBelow }: InputTextSkeletonProps) => {
	const labelColor = getLabelColor(false, false, false);
	const randomId = Math.random().toString();

	return (
		<Wrapper>
			{label && (
				<StyledLabel tkaColor={labelColor} htmlFor={randomId}>
					{label}
				</StyledLabel>
			)}
			<GrowDiv>
				<Skeleton width="100%" height="2.5rem" />
			</GrowDiv>
			{smallTextBelow && (
				<SmallTextBelow tkaColor={labelColor}>{smallTextBelow}</SmallTextBelow>
			)}
		</Wrapper>
	);
};
