import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
	md:max-w-[min(90vw,35rem)]
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const LabelsWrapper = tw.div`
	flex
	flex-row
	flex-wrap
	gap-8
`;

export const RowWrapper = tw.div`
	flex
	flex-row
	gap-16
	items-center
`;

export const UsersCountWrapper = tw.div`
	flex
	flex-row
	gap-8
	items-center
`;
