import { VideoTranscriptLanguage } from "./video-menu.const";

export const getLabelForTranscriptLanguage = (
	language: VideoTranscriptLanguage,
	t: (key: string) => string,
) => {
	switch (language) {
		case VideoTranscriptLanguage.Disabled:
			return t("video_player.transcriptDisabled");
		case VideoTranscriptLanguage.DE:
			return t("video_player.transcriptDe");
		case VideoTranscriptLanguage.EN:
			return t("video_player.transcriptEn");
		default:
			return language;
	}
};

export const getInitialTranscriptLanguage = (availableSubtitleLanguages?: string[]) => {
	if (availableSubtitleLanguages?.includes(VideoTranscriptLanguage.DE)) {
		return VideoTranscriptLanguage.DE;
	}
	if (availableSubtitleLanguages?.includes(VideoTranscriptLanguage.EN)) {
		return VideoTranscriptLanguage.EN;
	}
	return VideoTranscriptLanguage.Disabled;
};
