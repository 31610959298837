import graphql from "babel-plugin-relay/macro";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment branchNodesTab_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on PublishedRootExtensionImpl {
						reducedData {
							isIHK
						}
						ihkState
					}
				}
			}
		}
		...branchNodesTree_LearnOpportunityV2Fragment
	}
`;
