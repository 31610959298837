import { FC, useContext, useEffect } from "react";
import { usePaginationFragment } from "react-relay";
import { CoachingLearnableCard } from "@components/coaching-learnable-card";
import { EducationalOfferCard } from "@components/educational-offer-card";
import { learnableGrid_LearnableFragment$key } from "@relay/learnableGrid_LearnableFragment.graphql";
import { learnableGridRefetchQuery } from "@relay/learnableGridRefetchQuery.graphql";
import { DashboardScreenContext } from "@screens/dashboard/dashboard.context";
import { GridItem, StyledGrid } from "@screens/offers/parts/grid-view/grid-view.styles";
import { QUERY_FRAGMENT } from "./learnableGrid.graphql";
import { LearnableGridProps } from "./learnableGrid.types";

export const LearnableGrid: FC<LearnableGridProps> = ({ queryFragmentRef }) => {
	const { data, refetch } = usePaginationFragment<
		learnableGridRefetchQuery,
		learnableGrid_LearnableFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const { filters } = useContext(DashboardScreenContext);
	useEffect(() => {
		refetch({
			...filters,
		});
	}, [filters]);
	const sortedLearnables = data?.CustomerJourney?.Dashboard?.sortedLearnables?.edges;

	return (
		<StyledGrid>
			{sortedLearnables?.map((learnableWithLastUpdated) => {
				return (
					<GridItem key={learnableWithLastUpdated?.node.learnable.id}>
						{(learnableWithLastUpdated?.node.learnable.kind === "Course" ||
							learnableWithLastUpdated?.node.learnable.kind === "MicroLearning") && (
							<EducationalOfferCard
								publishedCourseLearnableFragmentRef={
									learnableWithLastUpdated!.node.learnable
								}
								isDashboardCard
								lastUpdated={learnableWithLastUpdated!.node.lastUpdated}
							/>
						)}
						{(learnableWithLastUpdated?.node.learnable.kind === "GroupCoaching" ||
							learnableWithLastUpdated?.node.learnable.kind ===
								"OneToOneCoaching") && (
							<CoachingLearnableCard
								coachingLearnableFragmentRef={
									learnableWithLastUpdated!.node.learnable
								}
								isDashboardCard
							/>
						)}
					</GridItem>
				);
			})}
		</StyledGrid>
	);
};
