import tw from "twin.macro";

export const AllocationListWrapper = tw.div`
  flex 
  flex-col
  items-start
  gap-8
`;

export const AllocationUserList = tw.div`
  flex 
  flex-col
  gap-8
  w-full
`;
