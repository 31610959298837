import { createContext } from "react";

type AudioPlayerContext = {
	progress: number;
	isPlaying: boolean;
	start: number;
	end: number;
	duration: number;
	setProgress: (progress: number) => void;
	setIsPlaying: (isPlaying: boolean) => void;
};

export const AudioPlayerContext = createContext<AudioPlayerContext>({
	progress: 0,
	isPlaying: false,
	start: 0,
	end: 0,
	duration: 0,
	setProgress: () => {},
	setIsPlaying: () => {},
});
