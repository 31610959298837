import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query feedbackQuote_CustomerFeedbackQuery($skip: Boolean!) {
		CustomerJourney {
			CustomerFeedback @skip(if: $skip) {
				edges {
					node {
						feedback
						id
						name
						position
					}
				}
			}
		}
	}
`;
export const QUERY_FRAGMENT = graphql`
	fragment feedbackQuote_CustomerFeedbackQueryFragment on Trend {
		customerData {
			amountCustomer
			customerFeedbacks {
				feedback
				id
				name
				position
			}
		}
	}
`;
