/**
 * @generated SignedSource<<4878d9f6c99bbe754d050f682fa6aebc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesAccountGroupAssignmentCard_QueryFragment$data = {
  readonly __typename: "AccountGroupAssignmentRule";
  readonly accountRef: string;
  readonly active: boolean;
  readonly amountEffectedUser: any;
  readonly id: string;
  readonly licenseGroupingId: string;
  readonly name: string;
  readonly userInAccountGroups: ReadonlyArray<{
    readonly id: string;
    readonly isBuiltIn: boolean;
    readonly name: string;
  }>;
  readonly " $fragmentType": "licensesAccountGroupAssignmentCard_QueryFragment";
};
export type licensesAccountGroupAssignmentCard_QueryFragment$key = {
  readonly " $data"?: licensesAccountGroupAssignmentCard_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesAccountGroupAssignmentCard_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licensesAccountGroupAssignmentCard_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "licenseGroupingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountEffectedUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountGroup",
      "kind": "LinkedField",
      "name": "userInAccountGroups",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBuiltIn",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountGroupAssignmentRule",
  "abstractKey": null
};
})();

(node as any).hash = "ff8c120873f5be799ca050be8eab59c0";

export default node;
