import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-32
	p-24
	pt-40
	md:px-80
	md:py-40
	max-w-[30rem]
	mx-auto
`;

export const IconBackground = tw.div`
	flex
	items-center
	justify-center
	self-center
	w-[7.5rem]
	h-[7.5rem]
	rounded-[3.75rem]
	bg-success-0
	mb-8
`;

export const TextWrapper = tw.div`
	flex
	flex-col
	gap-12
	items-center
	text-center
`;
