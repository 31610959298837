import styled from "styled-components";

export const InlineIconWrapper = styled.div`
    padding-left: 12px;
    margin-bottom: 8px;
    >span { 
        width: 100%;
        display: flex;
        align-items: center
    }
    > span > div { 
        display: inline-block;
        padding-left: 8px;
    }
  
`;
