import { Skeleton } from "../skeleton";

export const ButtonSkeleton = ({
	iconOnly,
	fillParent,
}: {
	iconOnly?: boolean;
	fillParent?: boolean;
}) => {
	if (iconOnly) return <Skeleton width="2.5rem" borderRadius="1.5rem" height="2.5rem" />;
	return <Skeleton width={fillParent ? "100%" : "12rem"} borderRadius="1.5rem" height="2.5rem" />;
};
