/**
 * @generated SignedSource<<fc4df3206a7fdcd4deec13d95a676f73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AccountMemberKind = "Invitation" | "UserInAccount";
import { FragmentRefs } from "relay-runtime";
export type groupDetails_QueryFragment$data = {
  readonly AccountMemberManagement: {
    readonly AccountMembers: {
      readonly __id: string;
      readonly accountMembers: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __id?: string;
            readonly id: string;
            readonly invitation?: {
              readonly email: string;
              readonly id: string;
            };
            readonly kind: AccountMemberKind;
            readonly user?: {
              readonly user: {
                readonly id: string;
                readonly " $fragmentSpreads": FragmentRefs<"userInGroupContextMenu_UserFragment">;
              };
            };
            readonly " $fragmentSpreads": FragmentRefs<"invitationCard_InvitationFragment" | "userCard_UserInAccountWrapperFragment">;
          };
        } | null> | null;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
        };
      };
      readonly invitationCount: any;
      readonly userCount: any;
    };
  };
  readonly " $fragmentType": "groupDetails_QueryFragment";
};
export type groupDetails_QueryFragment$key = {
  readonly " $data"?: groupDetails_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupDetails_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "AccountMemberManagement",
  "AccountMembers",
  "accountMembers"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByAccountMemberKind"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByNameOrEmail"
    },
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./groupDetailsAccountMembersRefetchQuery.graphql')
    }
  },
  "name": "groupDetails_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountMemberManagementQueries",
      "kind": "LinkedField",
      "name": "AccountMemberManagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterByAccountMemberKind",
              "variableName": "filterByAccountMemberKind"
            },
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "filterByGroupIds.0",
                  "variableName": "groupId"
                }
              ],
              "kind": "ListValue",
              "name": "filterByGroupIds"
            },
            {
              "kind": "Variable",
              "name": "filterByNameOrEmail",
              "variableName": "filterByNameOrEmail"
            }
          ],
          "concreteType": "AccountMembersResult",
          "kind": "LinkedField",
          "name": "AccountMembers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invitationCount",
              "storageKey": null
            },
            {
              "alias": "accountMembers",
              "args": null,
              "concreteType": "AccountMemberConnection",
              "kind": "LinkedField",
              "name": "__groupDetails_accountMembers_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountMemberEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserInAccount",
                              "kind": "LinkedField",
                              "name": "user",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "User",
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "args": null,
                                      "kind": "FragmentSpread",
                                      "name": "userInGroupContextMenu_UserFragment"
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "UserInAccountWrapper",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Invitation",
                              "kind": "LinkedField",
                              "name": "invitation",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "email",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v2/*: any*/)
                          ],
                          "type": "InvitationWrapper",
                          "abstractKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "userCard_UserInAccountWrapperFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "invitationCard_InvitationFragment"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "0e807ce6cbde2c98dbedc6f3c7ad2069";

export default node;
