import { error0, error100, success0, success100 } from "@themes/colors";
import { DialogTemplateProps } from "../dialog-template/dialog-template.types";
import { IconHeadlineButtonTemplateProps } from "../icon-headline-button-template/icon-headline-button-template.types";

type LicenseModalAttributes = DialogTemplateProps & IconHeadlineButtonTemplateProps;

// TODO: add-translations

export const hasLicenseAttributes: LicenseModalAttributes = {
	secondaryButtonLabel: "Abbrechen",
	primaryButtonLabel: "Lizenz einlösen",
	primaryButtonIconName: "arrowRight",
	iconName: "certifiedCertificate",
	iconColor: success100,
	iconBackgroundColor: success0,
	title: "Lizenz einlösen",
	subtitle:
		"Der Demo-Teil ist ab hier beendet, willst du deine Lizenz einlösen, um weiter zu machen?",
};

export const needsLicenseAttributes: LicenseModalAttributes = {
	secondaryButtonLabel: "Zurück zur Übersicht",
	primaryButtonLabel: "Lizenz erwerben",
	primaryButtonIconName: "shoppingBasket",
	iconName: "removeBadge",
	iconColor: error100,
	iconBackgroundColor: error0,
	title: "Lizenz benötigt",
	subtitle:
		"Der Demo-Teil ist ab hier beendet. Du benötigst nun eine Lizenz, um weiter zu machen.",
};
