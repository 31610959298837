import { Button as PrButton } from "primereact/button";
import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";
import { TkaB1Typography } from "@themes/font-tags";
import { ColorVersion } from "./button.types";
import {
	calcActiveBackgroundColor,
	calcActiveBorder,
	calcDefaultBackgroundColor,
	calcDefaultBorder,
	calcDefaultColor,
	calcDisabledBackgroundColor,
	calcDisabledBorder,
	calcDisabledOpacity,
	calcFocusBackgroundColor,
	calcFocusBorder,
	calcHoverBackgroundColor,
	calcHoverBorder,
	getColorWithTransparentFallback,
} from "./button.util";

export const Wrapper = styled.div<{ disabled?: boolean; fillParent?: boolean }>`
	${(p) => (p.disabled ? "cursor: not-allowed;" : "")}
	${({ fillParent }) => fillParent && tw`flex-1`};
`;

export const ContentWrapper = styled.div<{ disabled?: boolean; colorVersion: ColorVersion }>`
	opacity: ${({ disabled, colorVersion }) => (disabled ? calcDisabledOpacity(colorVersion) : 1)};
	${tw`
		flex
		flex-row
		flex-1
		gap-x-8
	`}
`;

export const TextWrapper = tw.div`
	flex
	flex-1
	flex-grow
	items-center
	justify-center
`;

export const LoadingWrapper = styled.div<{ tktColor?: TkaColor }>`
	${tw`
		w-16
		h-16
	`}
	& svg {
		path[stroke="rgb(0,0,0)"] {
			stroke: ${({ tktColor }) => tktColor};
		}
	}
`;

export const StyledButton = styled(PrButton)<{
	colorVersion: ColorVersion;
	widthRem?: number;
	minWidthRem?: number;
	fillParent?: boolean;
}>`
	${({ colorVersion }) => (colorVersion === "textlink" ? "padding: 0;" : "")}
	${({ widthRem }) => (widthRem !== undefined ? `width: ${widthRem}rem` : "")};
	${({ minWidthRem }) => (minWidthRem !== undefined ? `min-width: ${minWidthRem}rem` : "")};
	${({ fillParent }) => fillParent && tw`w-full`};
	height: 2.5rem;

	box-sizing: border-box;
	cursor: pointer;
	border-radius: 6.25rem;
	border-style: none;
	box-shadow: none;
	transition: 0.1s ease all;

	${tw`
		justify-center
	`}

	${TkaB1Typography}

	background: ${({ colorVersion }) =>
		getColorWithTransparentFallback(calcDefaultBackgroundColor(colorVersion))};
	${({ colorVersion }) => calcDefaultBorder(colorVersion)}

	&:enabled:hover {
		background-color: ${({ colorVersion }) =>
			getColorWithTransparentFallback(calcHoverBackgroundColor(colorVersion))} !important;
		${({ colorVersion }) => calcHoverBorder(colorVersion)}
	}

	&:enabled:focus {
		background-color: ${({ colorVersion }) =>
			getColorWithTransparentFallback(calcFocusBackgroundColor(colorVersion))};
		${() => calcFocusBorder()}
	}

	&:focus:active {
		background-color: ${({ colorVersion }) =>
			getColorWithTransparentFallback(calcActiveBackgroundColor(colorVersion))} !important;
		${({ colorVersion }) => calcActiveBorder(colorVersion)}
		color: ${({ colorVersion }) =>
			getColorWithTransparentFallback(calcDefaultColor(colorVersion))};
	}

	&:disabled {
		background-color: ${({ colorVersion }) =>
			getColorWithTransparentFallback(calcDisabledBackgroundColor(colorVersion))};
		${({ colorVersion }) => calcDisabledBorder(colorVersion)}
	}
`;
