import { useContext } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { AppointmentsScreenContext } from "@screens/appointments/appointments.context";
import { selectCurrentUserId } from "@slices/CurrentUserSlice";
import { shade60, success0, success100 } from "@themes/colors";
import { TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import { AVAILABILITY_FRAGMENT } from "./cancel-appointment-success-modal.graphql";
import {
	HeaderWrapper,
	RowWrapper,
	UsersCountWrapper,
	Wrapper,
} from "./cancel-appointment-success-modal.styles";
import { CancelAppointmentSuccessModalProps } from "./cancel-appointment-success-modal.types";
import { DialogTemplate } from "../dialog-template";
import { Icon } from "../icon";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";
import { UserAvatarsCollection } from "../user-avatars-collection/user-avatars-collection.component";

// TODO: add-translations
export const CancelAppointmentSuccessModal = ({
	isVisible = false,
	canceledByCoach,
	onAccept,
	availabilityFragmentRef,
}: CancelAppointmentSuccessModalProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const userId = useSelector(selectCurrentUserId);

	const { refreshMyAppointments, refreshCoachingAppointments } =
		useContext(AppointmentsScreenContext);

	const handleAcceptOnClick = () => {
		refreshMyAppointments();
		if (availability.coachProfile?.coach?.id === userId) {
			refreshCoachingAppointments?.();
		} else {
			refreshMyAppointments?.();
		}
		onAccept?.();
	};

	const offerName = availability.booking.offer?.description?.name;
	const message = canceledByCoach
		? `Die Absage wurde an alle Teilnehmer gesendet und der Termin “${offerName}” wie gewünscht abgesagt.`
		: "Wir haben dem Coach Bescheid gegeben und euch beiden eine Bestätigungsmail gesendet. Außerdem bekommst du deine Lizenz wieder gut geschrieben.";

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			canceledByCoach ?? false,
			"long",
		);
	};

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const users = participants.map((participant) => participant.user!).filter(Boolean);

	const participantsLabel = `${users.length} Teilnehmer`;

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<DialogTemplate
				primaryButtonStretch
				primaryButtonLabel="Verstanden"
				onPrimaryButtonClick={handleAcceptOnClick}
			>
				<Wrapper>
					<HeaderWrapper>
						<IconHeadlineButtonTemplate
							iconName="calendarDisable"
							iconColor={success100}
							iconBackgroundColor={success0}
							title="Termin abgesagt"
							subtitle={message}
						/>
					</HeaderWrapper>
					<RowWrapper>
						<Icon icon="calendar" sizeInRem={1.5} />
						<TkaP2Span>{renderTime()}</TkaP2Span>
					</RowWrapper>
					<RowWrapper>
						<Icon icon="participants" sizeInRem={1.5} />
						<UsersCountWrapper>
							<UserAvatarsCollection userFragmentsRef={users} />
							<TkaP3Span tkaColor={shade60}>{participantsLabel}</TkaP3Span>
						</UsersCountWrapper>
					</RowWrapper>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
