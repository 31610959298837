import { KeyPrefix, Namespace } from "i18next";
import { useTranslation, UseTranslationOptions } from "react-i18next";
import { DefaultNamespace } from "react-i18next/TransWithoutContext";

export function useTkaTranslation<
	N extends Namespace = DefaultNamespace,
	TKPrefix extends KeyPrefix<N> = undefined,
>(ns?: N | Readonly<N>, options?: UseTranslationOptions<TKPrefix>) {
	const { t, ...props } = useTranslation(ns, options);
	return {
		t: (key: string, options?: Partial<Parameters<typeof t>[1]>): string => {
			return t(key, { ...options, defaultValue: key.split(".").pop() || "" });
		},
		...props,
	};
}

export type TkaTranslation = (key: string) => string;
