import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/button";
import { ValidatedInputText } from "@components/validated-input-text/validated-input-text.component";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { brandStrong100 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP3Span } from "@themes/font-tags";
import {
	PasswordForgotEmailStepFormState,
	PasswordForgotEmailStepProps,
} from "./password-forgot-email-step.interface";
import { FormWrapper, InputsWrapper, TextCenter } from "./password-forgot-email-step.styles";

export const PasswordForgotEmailStep = ({ onGoToLogin, onNext }: PasswordForgotEmailStepProps) => {
	const { t } = useTkaTranslation("forgotPasswordForm");

	const form = useFormik<PasswordForgotEmailStepFormState>({
		initialValues: {
			email: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t("forgot_password_form.validationInvalidEmail"))
				.required(t("forgot_password_form.validationRequiredEmail")),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				<TextCenter>
					<H1Span>{t("forgot_password_form.title")}</H1Span>
					<TkaP1Span>{t("forgot_password_form.title2")}</TkaP1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="email"
						type="default"
						status="default"
						icon="email"
						placeholder={t("forgot_password_form.placeholderEmail")}
					/>
				</InputsWrapper>
				<Button fillParent label={t("forgot_password_form.buttonText")} />
				<TextCenter>
					<TkaP3Span tkaColor={brandStrong100} onClick={onGoToLogin}>
						{t("forgot_password_form.backToLogin")}
					</TkaP3Span>
				</TextCenter>
			</FormWrapper>
		</form>
	);
};
