import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Card } from "@components/card";
import { DialogTemplate } from "@components/dialog-template";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { Label } from "@components/label";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { Path } from "@router/paths";
import { success0, success100 } from "@themes/colors";

import { TkaH3Span } from "@themes/font-tags";
import { spacing12 } from "@themes/spacing";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import { AVAILABILITY_FRAGMENT } from "./booking-success-modal.graphql";
import { CardWrapper, HeaderWrapper, TagsWrapper, Wrapper } from "./booking-success-modal.styles";
import { BookingSuccessModalProps } from "./booking-success-modal.types";

// TODO: add-translations
export const BookingSuccessModal = ({
	isVisible,
	availabilityFragmentRef,
}: BookingSuccessModalProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef ?? null);

	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(Path.appointments.path);
	};

	const offer = availability?.booking.offer;
	if (!offer) return null;

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			false,
		);
	};

	const hasTags = !!offer.description?.tags.length;
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<DialogTemplate
				primaryButtonLabel={"Okay"}
				primaryButtonIconName="arrowRight"
				onPrimaryButtonClick={handleOnClick}
				secondaryButtonLabel="Zu meinen Buchungen"
				onSecondaryButtonClick={handleOnClick}
				maxWidthRem={28}
			>
				<Wrapper>
					<IconHeadlineButtonTemplate
						iconName="calendarCheck"
						iconColor={success100}
						iconBackgroundColor={success0}
						title="Termin gebucht"
						subtitle="Dein Coach freut sich bereits auf die Sitzung mit dir. "
					/>
					<Card bodyPaddingInRem={spacing12.remValue()}>
						<CardWrapper>
							<HeaderWrapper>
								{hasTags && (
									<TagsWrapper>
										{offer.description?.tags.map((tag) => (
											<Label
												key={tag.id}
												severity="brandStrong"
												label={tag.data.name}
											/>
										))}
									</TagsWrapper>
								)}
								<TkaH3Span>{offer.description?.name}</TkaH3Span>
							</HeaderWrapper>
							<TkaH3Span>{renderTime()}</TkaH3Span>
						</CardWrapper>
					</Card>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
