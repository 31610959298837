import { Image } from "primereact/image";
import styled from "styled-components";

export const StyledImage = styled(Image)<{ widthRem?: number; heightRem?: number }>`
	&.p-image.p-component {
		display: flex;
		${({ widthRem, heightRem }) =>
			widthRem && heightRem
				? "width: " + widthRem + "rem; height: " + heightRem + "rem;"
				: ""}
	}
`;
