import { FC, useMemo } from "react";
import { MultiDialogFlowContainer } from "@containers/multi-dialog-flow-container";
import { CalendarWeekAndYear } from "@screens/my-availabilities/my-availabilities.types";
import { ConfirmDeleteExceptionModal } from "@screens/my-availabilities/parts/confirm-delete-exception-modal";
import { DeleteExceptionModal } from "@screens/my-availabilities/parts/delete-exception-modal/delete-exception-modal.component";
import { ExceptionDeleteFlowProps } from "@screens/my-availabilities/parts/exception-delete-flow/exception-delete-flow.types";
import { SuccessDeleteExceptionModal } from "@screens/my-availabilities/parts/success-delete-exception-modal";

export const ExceptionDeleteFlow: FC<ExceptionDeleteFlowProps> = ({
	isVisible,
	onDismiss,
	startAtStep,
	calendarWeek,
	deleteException,
	onSubmit,
}) => {
	const createHandler = (handler: (step?: number) => void, step?: number) => {
		return () => {
			handler(step);
		};
	};

	const formatCalendarWeek = (cw: CalendarWeekAndYear) => `KW ${cw.calendarWeek}`;
	const formattedCalendarWeek = useMemo(() => formatCalendarWeek(calendarWeek), [calendarWeek]);

	return (
		<MultiDialogFlowContainer
			isVisible={isVisible}
			startAtStep={startAtStep}
			onDismiss={onDismiss}
		>
			{({ onNext, onClose }) => (
				<>
					<DeleteExceptionModal
						isVisible
						onDismiss={createHandler(onClose)}
						onNext={createHandler(onNext)}
						calendarWeek={formattedCalendarWeek}
					/>
					<ConfirmDeleteExceptionModal
						isVisible
						onSuccess={createHandler(onNext)}
						onDismiss={createHandler(onClose)}
						calendarWeek={formattedCalendarWeek}
						deleteException={deleteException}
						onSubmit={onSubmit}
					/>
					<SuccessDeleteExceptionModal
						isVisible
						onDismiss={createHandler(onClose)}
						calendarWeek={formattedCalendarWeek}
					/>
				</>
			)}
		</MultiDialogFlowContainer>
	);
};
