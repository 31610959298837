import React from "react";

export interface GapTextProps {
	text?: string;
	status?: GapTextStatus;
	selected?: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	useMinWidth?: boolean;
	onlyBoldIfSelected?: boolean;
	ref?: React.Ref<HTMLDivElement>;
	id?: string;
	draggable?: boolean;
}

export enum GapTextStatus {
	Correct,
	ActuallyCorrect,
	Wrong,
	Default,
}
