import { VideoTranscriptLanguage } from "@components/video-menu";
import { Subtitle } from "@components/video-player-subtitles";

export type VideoPlayerProps = {
	url: string;
	thumbnail?: string | null;
	onTranscriptLanguageChange?: (transcriptLanguage: VideoTranscriptLanguage) => void;
	subtitles?: readonly SubtitleLanguage[];
	onSelectedSubtitlesChanged?: (subtitles?: SubtitleLanguage) => void;
	onSubtitleIndexChanged?: (index: number) => void;
};

export type VideoPlayeWithProvidersProps = {
	onProgress: (progress: number) => void;
} & VideoPlayerProps;

export interface HlsPlayer {
	levels: {
		id: number;
		width: number;
		height: number;
	}[];
	currentLevel: number;
	nextLevel: number;
	loadLevel: number;
	autoLevelEnabled: boolean;
}

export type SubtitleLanguage = {
	language: string;
	subtitles: readonly Subtitle[];
};

export enum OverlayInitiator {
	Transcripts,
	Settings,
	Volume,
	OverlayButton,
	OutsideFocus,
}
