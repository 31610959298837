import { PathParams } from "@thekeytechnology/epic-ui";
import { Suspense, useEffect, useRef } from "react";
import { useFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { Chip } from "@components/chip";
import { Icon } from "@components/icon/icon.component";
import { TimeEstimate } from "@components/time-estimate";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { contentOverview_GetContentOverviewQuery } from "@relay/contentOverview_GetContentOverviewQuery.graphql";
import { contentOverview_LearnOpportunityV2Fragment$key } from "@relay/contentOverview_LearnOpportunityV2Fragment.graphql";
import { BranchNodesPath, Path, RootPath } from "@router/paths";
import { TkaP2Span, H1Span, TkaH3Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
} from "@utils/index-path.util";
import { parseHtml } from "@utils/parse-html";
import { CONTENT_OVERVIEW_QUERY, LEARN_OPPORTUNITY_V2_FRAGMENT } from "./content-overview.graphql";
import { ContentOverviewSkeleton } from "./content-overview.skeleton";
import {
	ContentPath,
	DescriptionImageWrapper,
	DescriptionWrapper,
	ExtimatePointsWrapper,
	HeaderWrapper,
	ImageWrapper,
	Wrapper,
} from "./content-overview.styles";
import {
	ContentOverviewProps,
	ContentOverviewWithPreloadedQueryProps,
} from "./content-overview.types";
import { AsyncContentOverview } from "../async-content-overview";
import { BranchNodeTab } from "../branch-nodes-tab";
import { ELearningContentOverview } from "../e-learning-content-overview";

export const ContentOverviewWithPreloadedQuery = ({
	showDetails,
	queryRef,
}: ContentOverviewWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<contentOverview_GetContentOverviewQuery>(
		CONTENT_OVERVIEW_QUERY,
		queryRef,
	);

	const node = useFragment<contentOverview_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		query.node,
	);

	const { t } = useTkaTranslation("modulesAndLessons");
	const navigate = useNavigate();
	const { rootId, branchNodeId } =
		useParams<PathParams<typeof RootPath | typeof BranchNodesPath>>();
	const { isXLargeUp } = useWindowSize();
	const sizeRef = useRef(isXLargeUp);

	const moduleNumber = node?.indexPath ? getBranchNodeNumberFromIndexPath(node.indexPath) : 1;
	const lessonNumber = node?.indexPath ? getContentNodeNumberFromIndexPath(node.indexPath) : 1;

	const imageUrl = node?.image?.url;
	const description = node?.description;
	const contentKind = node?.typeDefinition?.contentKind;

	const lessonName = `${t("modules_and_lessons.lesson")} ${moduleNumber}.${lessonNumber}`;
	const duration = Math.ceil((node?.lengthInSecondsV2 ?? 0) / 60);

	const receivablePoints = node?.typeDefinition.extension?.receivableGamificationPoints ?? 0;
	const receivedPoints = node?.typeDefinition.extension?.receivedGamificationPoints ?? 0;

	const renderElementOverview = () => {
		if (!node) return null;
		switch (contentKind) {
			case "Async":
				return <AsyncContentOverview learnOpportunityV2FragmentRef={node} />;
			case "ELearning":
			default:
				return <ELearningContentOverview learnOpportunityV2FragmentRef={node} />;
		}
	};

	useEffect(() => {
		if (!isXLargeUp && sizeRef.current && !showDetails && rootId && branchNodeId) {
			navigate(Path.root.withId(rootId).branchNodes.withId(branchNodeId).lessonOverview.path);
		}
		sizeRef.current = isXLargeUp;
	}, [isXLargeUp]);

	const content = (
		<Wrapper grid={contentKind !== "Async"}>
			<HeaderWrapper>
				<ExtimatePointsWrapper>
					{duration ? <TimeEstimate duration={duration} /> : <div />}
					{receivablePoints > 0 && (
						<Chip>
							<TkaH3Span>
								{receivedPoints}/{receivablePoints}
							</TkaH3Span>
							<Icon icon="coinStar" sizeInRem={1} />
						</Chip>
					)}
				</ExtimatePointsWrapper>
				<ContentPath>
					<TkaP2Span>{lessonName}</TkaP2Span>
				</ContentPath>
				<H1Span>{node?.structureDefinition?.title}</H1Span>
				<DescriptionImageWrapper>
					{description && (
						<DescriptionWrapper>
							<TkaP2Span>{parseHtml(description)}</TkaP2Span>
						</DescriptionWrapper>
					)}
					{imageUrl && <ImageWrapper url={imageUrl} />}
				</DescriptionImageWrapper>
			</HeaderWrapper>
			{renderElementOverview()}
		</Wrapper>
	);

	return isXLargeUp || showDetails ? (
		content
	) : node?.root ? (
		<BranchNodeTab learnOpportunityV2FragmentRef={node.root} />
	) : null;
};

export const ContentOverview = (props: ContentOverviewProps) => {
	const { branchNodeId } = useParams();
	const [queryReference, loadQuery] =
		useQueryLoader<contentOverview_GetContentOverviewQuery>(CONTENT_OVERVIEW_QUERY);

	useEffect(() => {
		branchNodeId && loadQuery({ id: branchNodeId });
	}, [loadQuery, branchNodeId]);

	return (
		<Suspense fallback={<ContentOverviewSkeleton showDetails={props.showDetails} />}>
			{queryReference && (
				<ContentOverviewWithPreloadedQuery {...props} queryRef={queryReference} />
			)}
		</Suspense>
	);
};
