import { useFragment } from "react-relay";
import { wordPackageList_WordInventoryFragment$key } from "@relay/wordPackageList_WordInventoryFragment.graphql";
import { WordPackageCard } from "@screens/vocabulary/parts/word-package-card/word-package-card.component";
import { WORD_PACKAGE_LIST_FRAGMENT } from "@screens/vocabulary/parts/word-package-list/word-package-list.graphql";
import {
	SelectionWrapper,
	WordPackageListWrapper,
} from "@screens/vocabulary/parts/word-package-list/word-package-list.styles";
import { WordPackageListProps } from "@screens/vocabulary/parts/word-package-list/word-package-list.types";
import { TkaP2Span } from "@themes/font-tags";

export const WordPackageList = ({
	wordInventoryFragmentRef,
	selectionModel,
}: WordPackageListProps) => {
	const inventory = useFragment<wordPackageList_WordInventoryFragment$key>(
		WORD_PACKAGE_LIST_FRAGMENT,
		wordInventoryFragmentRef,
	);

	return (
		<WordPackageListWrapper>
			{selectionModel && (
				<SelectionWrapper>
					<TkaP2Span>
						{selectionModel.selectedItems.length} Vokabel
						{selectionModel.selectedItems.length === 1 ? "" : "n"} ausgewählt
					</TkaP2Span>
				</SelectionWrapper>
			)}

			{inventory.packages.map((pkg) => {
				return pkg.wordPackage ? (
					<WordPackageCard
						key={pkg.wordPackage?.id}
						wordPackageId={pkg.wordPackage.id}
						wordInventoryFragmentRef={inventory}
						selectionModel={selectionModel}
					/>
				) : null;
			})}
		</WordPackageListWrapper>
	);
};
