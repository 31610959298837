/**
 * @generated SignedSource<<7be56297d68a71bbd870540a57927d69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementStateKind = "Input" | "InputAndIsCorrect" | "ShowAnswer" | "Untouched" | "UntouchedAndPreviouslyIncorrect";
import { FragmentRefs } from "relay-runtime";
export type OrderElement_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly correctOrder?: ReadonlyArray<string>;
      readonly element: {
        readonly answeringTypeText?: string | null;
        readonly id: string;
        readonly orderItems?: ReadonlyArray<{
          readonly id: string;
          readonly text: string;
        }>;
        readonly title: string;
      };
      readonly inputElementState?: {
        readonly selectedOrder?: ReadonlyArray<string>;
      };
      readonly isCorrect?: boolean;
      readonly kind: ElementStateKind;
      readonly lastSelectedOrder?: ReadonlyArray<string>;
    } | null;
  };
  readonly id: string;
  readonly " $fragmentType": "OrderElement_ContentSubmissionFragment";
};
export type OrderElement_ContentSubmissionFragment$key = {
  readonly " $data"?: OrderElement_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderElement_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderElement_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "answeringTypeText",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OrderItem",
                          "kind": "LinkedField",
                          "name": "orderItems",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "text",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "OrderLearnElement",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCorrect",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "inputElementState",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "selectedOrder",
                              "storageKey": null
                            }
                          ],
                          "type": "OrderInputElementState",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "InputAndIsCorrectElementState",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "correctOrder",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastSelectedOrder",
                      "storageKey": null
                    }
                  ],
                  "type": "OrderShowAnswerElementState",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "b895c135c3065f49317d53c7a9def08c";

export default node;
