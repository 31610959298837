import { FC, useMemo, useRef } from "react";
import { useFragment } from "react-relay";
import { BadgeV2 } from "@components/badge-v2";
import { Icon } from "@components/icon";
import { Label } from "@components/label";
import { ResponsiveBottomSheetOverlayPanel as ResponsiveBottomSheetOverlayPanelComponent } from "@components/responsive-bottom-sheet-overlay-panel";
import { HTMLMouseEvent } from "@customTypes/index";
import { useWindowSize } from "@hooks/use-window-size";
import { shade100, shade40 } from "@themes/colors";
import { useMembersFilterContext } from "./members-filter.context";
import { QUERY_FRAGMENT } from "./members-filter.graphql";
import { MembersFilterProps } from "./members-filter.types";
import { FilterModal } from "../filter-modal/filter-modal.component";
import {
	DesktopLabelWrapper,
	InputTextMock,
	MobileBadgeWrapper,
	Wrapper,
} from "../filter-modal/filter.styles";

export const MembersFilter: FC<MembersFilterProps> = ({ queryFragmentRef }) => {
	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanelComponent>(null);
	const { numberOfAppliedFilters } = useMembersFilterContext();
	const handleOpenClick = (event: HTMLMouseEvent) => {
		overlayRef.current?.show(event, event.currentTarget);
	};
	const handleCloseClick = () => {
		overlayRef.current?.hide();
	};
	const { isMediumUp } = useWindowSize();

	const badgeCount = numberOfAppliedFilters;
	const iconColor = useMemo(() => (isMediumUp ? shade40 : shade100), [isMediumUp]);
	const iconSize = useMemo(() => (isMediumUp ? 1.5 : 0.75), [isMediumUp]);
	return (
		<>
			<Wrapper>
				<InputTextMock onClick={handleOpenClick}>
					<Icon icon={"filter"} sizeInRem={iconSize} tkaColor={iconColor} />

					{isMediumUp && badgeCount ? (
						<DesktopLabelWrapper>
							<Label label={`+${badgeCount}`} />
						</DesktopLabelWrapper>
					) : (
						<></>
					)}
				</InputTextMock>
				{!isMediumUp && badgeCount ? (
					<MobileBadgeWrapper>
						<BadgeV2 value={badgeCount} severity={"brandStrong"} />
					</MobileBadgeWrapper>
				) : (
					<></>
				)}
			</Wrapper>
			<ResponsiveBottomSheetOverlayPanelComponent ref={overlayRef}>
				<FilterModal
					overlayRef={overlayRef}
					onClose={handleCloseClick}
					queryFragmentRef={queryFragment}
				/>
			</ResponsiveBottomSheetOverlayPanelComponent>
		</>
	);
};
