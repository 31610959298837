import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	items-end
	gap-8
	p-16
	bg-shade-0
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-row
	justify-end
	items-center
	gap-16
`;

export const BottomTextWrapper = tw.div`
	px-16
`;
