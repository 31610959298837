import { Card } from "@components/card";
import { Icon } from "@components/icon";
import {
	CardContentClass,
	CardsClass,
	WrapperClass,
} from "@screens/avgs-potential-analysis/parts/introductory-section/introductory-section.styles";
import { css } from "@styled-system/css";
import { onBrandMain } from "@themes/colors";
import { H1Span, P2Span } from "@themes/font-tags";

export const IntroductorySection = () => {
	return (
		<div className={WrapperClass}>
			<div>
				<H1Span className={css({ color: "shade.100" })}>
					Herzlich Willkommen zu Ihrer Job-Coaching Befragung!
				</H1Span>
				<P2Span className={css({ color: "shade.80", display: "inline-block", mt: "8" })}>
					Auf den folgenden Seiten finden Sie Fragen zu verschiedenen arbeits- und
					lebensrelevanten Themen.
				</P2Span>
			</div>
			<div className={CardsClass}>
				<Card showBorder={false} bodyPaddingInRem={0} backgroundColor={onBrandMain}>
					<div className={CardContentClass}>
						<Icon icon={"conversationQuestionWarning"} sizeInRem={2.5} />
						<P2Span className={css({ color: "primary.100" })}>
							Bei der Beantwortung zählen Ihre persönlichen Eindrücke, es gibt weder
							richtige noch falsche Antworten. Bitte beantworten Sie alle Fragen offen
							und ehrlich, um daraus im weiteren Verlauf den größtmöglichen Nutzen für
							Sie ziehen zu können.
						</P2Span>
					</div>
				</Card>
				<Card showBorder={false} bodyPaddingInRem={0} backgroundColor={onBrandMain}>
					<div className={CardContentClass}>
						<Icon icon={"synchronizeArrowClock"} sizeInRem={2.5} />
						<P2Span className={css({ color: "primary.100" })}>
							Die gegebenen Antworten werden laufend zwischengespeichert, Sie können
							die Befragung unterbrechen und zu einem späteren Zeitpunkt abschließen.
							Ihre Daten werden absolut vertraulich behandelt. Die Ergebnisse erhalten
							Sie im Nachgang von ihrem Job-Coach.
						</P2Span>
					</div>
				</Card>
			</div>
			<div>
				<P2Span className={css({ color: "shade.80" })}>
					Vielen Dank für Ihre Teilnahme!
				</P2Span>
			</div>
		</div>
	);
};
