import { useFragment } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { success0, success100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT } from "./edit-group-success-modal.graphql";
import { EditGroupSuccessModalProps } from "./edit-group-success-modal.types";

export const EditGroupSuccessModal = ({
	isVisible,
	userInAccountGroupWrapperFragmentRef,
	onDismiss,
}: EditGroupSuccessModalProps) => {
	const group = useFragment(
		USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT,
		userInAccountGroupWrapperFragmentRef ?? null,
	);

	const groupName = group?.group.name ?? "";
	const memberCount = group?.memberCount ?? 0;
	const newMembersCount = group?.newInvitations.edges?.length ?? 0;
	const membersBeforeCount = memberCount - newMembersCount;

	const memberInviteSuffix = newMembersCount === 1 ? "wurde" : "wurden";
	const membersInGroupSuffix = membersBeforeCount === 1 ? "war" : "waren";

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				primaryButtonStretch
				primaryButtonLabel="Okay"
				onPrimaryButtonClick={onDismiss}
			>
				<IconHeadlineButtonTemplate
					iconName="checkCircle"
					iconColor={success100}
					iconBackgroundColor={success0}
					title={`Die Gruppe ${groupName} wurde aktualisiert`}
					subtitle="Name geändert und Benutzer hinzugefügt"
				/>

				<TkaP2Span>
					Der Gruppenname wurde zu '{groupName}' geändert.
					<br />
					<TkaP2Span bold>{newMembersCount} Benutzer </TkaP2Span>
					{memberInviteSuffix} erfolgreich eingeladen.
					<br />
					<TkaP2Span bold>{membersBeforeCount} Benutzer</TkaP2Span> {membersInGroupSuffix}{" "}
					bereits in der Gruppe
					<br /> <br /> Alle Benutzer haben Zugang zu sämtlichen Ressourcen und
					Aktivitäten der Gruppe
				</TkaP2Span>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
