import React, { useRef, useState } from "react";
import { Icon } from "@components/icon";
import { HiddenInput, Wrapper } from "@components/upload-file-card/upload-file-card.styles";
import { UploadFileCardProps } from "@components/upload-file-card/upload-file-card.types";
import { brandStrong100, shade60 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";

// eslint-disable-next-line react/display-name
export const UploadFileCard = ({ onFileUpload, disabled }: UploadFileCardProps) => {
	const _ref = useRef<HTMLInputElement>(null);
	const [_, setFile] = useState<File | undefined>(undefined);

	function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
		const target = e.target as HTMLInputElement & {
			files: FileList;
		};
		const file = target.files[0];
		if (file === undefined) return;
		setFile(file);
		onFileUpload(file);
	}
	const handleOnClick = () => {
		!disabled && _ref.current?.click();
	};

	const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file === undefined) return;
		onFileUpload(file);
	};
	const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};
	return (
		<>
			<Wrapper
				onClick={handleOnClick}
				disabled={disabled}
				onDrop={handleOnDrop}
				onDragOver={onDragOverHandler}
			>
				<Icon icon="csv" sizeInRem={1.5} tkaColor={disabled ? shade60 : brandStrong100} />
				<TkaP2Span tkaColor={disabled ? shade60 : brandStrong100}>
					Zum Upload hier klicken, oder Daten auf den Bereich schieben
				</TkaP2Span>
			</Wrapper>
			<HiddenInput id="image" type="file" name="image" ref={_ref} onChange={handleOnChange} />
		</>
	);
};
