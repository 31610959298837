/**
 * @generated SignedSource<<7fd801c7b7d15efbd8697bb09c1836dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type groupsTab_UserInAccountGroupQueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"batchEditGroups_QueryFragment" | "groupCard_QueryFragment">;
  readonly " $fragmentType": "groupsTab_UserInAccountGroupQueryFragment";
};
export type groupsTab_UserInAccountGroupQueryFragment$key = {
  readonly " $data"?: groupsTab_UserInAccountGroupQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupsTab_UserInAccountGroupQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./groupsTab_UserInAccountGroupRefetchQuery.graphql')
    }
  },
  "name": "groupsTab_UserInAccountGroupQueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "batchEditGroups_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "groupCard_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0e7dce89aac08e10c5dd76296f42d2ed";

export default node;
