/**
 * @generated SignedSource<<fea162f5719cb853f55eda2287dd6bd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type avatarWithAccountSwitch_AuthViewerSchemaFragment$data = {
  readonly currentUser: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
    };
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"accountSwitchMenu_AuthViewerSchemaFragment">;
  readonly " $fragmentType": "avatarWithAccountSwitch_AuthViewerSchemaFragment";
};
export type avatarWithAccountSwitch_AuthViewerSchemaFragment$key = {
  readonly " $data"?: avatarWithAccountSwitch_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"avatarWithAccountSwitch_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "avatarWithAccountSwitch_AuthViewerSchemaFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "accountSwitchMenu_AuthViewerSchemaFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "userAvatar_UserFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "e4714b7f342965ca34494b662aa252af";

export default node;
