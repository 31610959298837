import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
	gap-8
	items-center
`;

export const NameWrapper = tw.div`
	flex
	flex-col
	flex-1
	whitespace-nowrap
`;
