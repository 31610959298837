import { ToastMessage } from "primereact/toast";
import { createContext } from "react";
import { ToastMessageWithoutSeverity } from "./toast-provider.interface";

type ToastContext = {
	clear: () => void;
	replace: (message: ToastMessage) => void;
	remove: (message: ToastMessage) => void;
	showError: (message: ToastMessageWithoutSeverity) => void;
	showWarning: (message: ToastMessageWithoutSeverity) => void;
	showInfo: (message: ToastMessageWithoutSeverity) => void;
	showSuccess: (message: ToastMessageWithoutSeverity) => void;
};

export const ToastContext = createContext<ToastContext>({
	clear: () => {},
	replace: () => {},
	remove: () => {},
	showError: () => {},
	showWarning: () => {},
	showInfo: () => {},
	showSuccess: () => {},
});
