import { ChipSkeleton } from "@components/chip/chip.skeleton";
import { Skeleton } from "@components/skeleton";
import { TextParagraphSkeleton } from "@components/text-paragraph/text-paragraph.skeleton";
import { TrailerSectionId } from "./root-overview.const";
import {
	ContentSection,
	TextParagraphWrapper,
	TitlePointsWrapper,
	TrailerSection,
	Wrapper,
} from "./root-overview.styles";

export const RootOverviewSkeleton = () => {
	return (
		<Wrapper>
			<ContentSection>
				<TitlePointsWrapper>
					<Skeleton height="2rem" width="20rem" />
					<ChipSkeleton />
				</TitlePointsWrapper>
				<TextParagraphWrapper>
					<TextParagraphSkeleton />
				</TextParagraphWrapper>
			</ContentSection>
			<TrailerSection name={TrailerSectionId} id={TrailerSectionId}>
				<Skeleton width="100%" height="20rem" borderRadius="0.75rem" />
			</TrailerSection>
		</Wrapper>
	);
};
