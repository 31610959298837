import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment myCoachings_QueryFragment on Query
	@argumentDefinitions(
		coachProfileId: { type: "ID!" }
		first: { type: "Int" }
		last: { type: "Int" }
		before: { type: "String" }
		after: { type: "String" }
	) {
		Coaching {
			CoachingOffersToCoach(
				coachProfileId: $coachProfileId
				first: $first
				last: $last
				before: $before
				after: $after
			) {
				edges {
					node {
						... on CoachingOffer {
							id
							...coachingOfferCard_CoachingOfferFragment
						}
					}
				}
			}
		}
	}
`;
