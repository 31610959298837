import { AcceptInviteError } from "@components/accept-invite-error";
import { AuthTemplate } from "@components/auth-template";
import { AuthTemplateStep } from "@components/auth-template-step";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";

export const AcceptInvitationErrorScreen = () => {
	return (
		<AuthTemplate hideImage>
			<MultiStepAuthContainer StepWrapper={AuthTemplateStep}>
				{() => <AcceptInviteError />}
			</MultiStepAuthContainer>
		</AuthTemplate>
	);
};
