import { graphql } from "babel-plugin-relay/macro";

export const START_VOCAB_TRAINING_MUTATION = graphql`
	mutation startVocabTrainingButton_StartMutation($input: StartStandaloneVocabTrainingInput!) {
		Vocab {
			startStandaloneVocabTraining(input: $input) {
				contentSubmission {
					id
				}
			}
		}
	}
`;
