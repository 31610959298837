import { useEffect, useRef, useState } from "react";
import { STEP_ANIMATION_TIME_MS } from "./timeline.consts";
import { StyledMarker, StyledTimeline } from "./timeline.styles";
import { TimelineProps } from "./timeline.types";

export const Timeline = <T,>({
	events,
	currentStep,
	orientation = "horizontal",
	progressBarOffestRem,
	hideContent,
	content,
	...props
}: TimelineProps<T>) => {
	const stepRef = useRef(currentStep);
	const [step, setStep] = useState(currentStep);
	const orientationRef = useRef(orientation);
	const [markerStep, setMarkerStep] = useState(currentStep);
	const intervalRef = useRef<NodeJS.Timeout | undefined>(undefined);

	const clearIntervalRef = () => {
		intervalRef.current && clearInterval(intervalRef.current);
		intervalRef.current = undefined;
	};

	useEffect(() => {
		stepRef.current = currentStep;
	}, [currentStep]);

	useEffect(() => {
		if (step < markerStep) {
			setMarkerStep(step);
		} else if (step > markerStep) {
			if (step === 1) {
				setMarkerStep(step);
				return;
			}
			setTimeout(() => setMarkerStep(step), STEP_ANIMATION_TIME_MS);
		}
	}, [step]);

	useEffect(() => {
		if (intervalRef.current) return;
		const updateSteps = () => {
			setStep((stateStep) => {
				if (stateStep === stepRef.current) {
					clearIntervalRef();
					return stateStep;
				}
				if (stateStep < stepRef.current) {
					return stateStep + 1;
				}
				return stateStep - 1;
			});
		};
		updateSteps();
		intervalRef.current = setInterval(updateSteps, STEP_ANIMATION_TIME_MS);
	}, [currentStep]);

	useEffect(() => {
		orientationRef.current = orientation;
	}, [orientation]);

	const renderContent = (item: T, index: number) => {
		return content?.(item, index);
	};

	const renderMarker = (_: T, index: number) => {
		return <StyledMarker accomplished={index < markerStep} />;
	};

	const animationTime = orientationRef.current !== orientation ? 0 : STEP_ANIMATION_TIME_MS;

	const progressBarOffestRemRef = hideContent ? 0 : progressBarOffestRem;
	const timelineContent = !hideContent ? renderContent : undefined;

	return (
		<StyledTimeline
			hideContent={hideContent}
			value={events}
			marker={renderMarker}
			layout={orientation}
			currentStep={step}
			progressBarOffestRem={progressBarOffestRemRef}
			animationTime={animationTime}
			content={timelineContent}
			{...props}
		/>
	);
};
