import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { useWindowSize } from "@hooks/use-window-size";
import { error100, shade80 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import { QUERY_FRAGMENT, USER_FRAGMENT } from "./user-context-menu.graphql";
import { ItemWrapper, OptionsWrapper, TitleWrapper, Wrapper } from "./user-context-menu.styles";
import { UserContextMenuProps, UserContextMenuRef } from "./user-context-menu.types";
import { AssignGroupModal } from "../assign-group-modal";
import { RemoveMemberModal } from "../remove-member-modal";

// TODO: add-translations
export const UserContextMenu = forwardRef<UserContextMenuRef, UserContextMenuProps>(
	function UserContextMenu({ userFragmentRef, queryFragmentRef }, ref) {
		const user = useFragment(USER_FRAGMENT, userFragmentRef ?? null);

		const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

		const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

		const { isMediumUp } = useWindowSize();

		const [isVisibleRemoveMemberModal, setIsVisibleRemoveMemberModal] = useState(false);
		const [isVisibleAssignGroupModal, setIsVisibleAssignGroupModal] = useState(false);

		useImperativeHandle(ref, () => ({
			toggle: (event, target) => {
				overlayRef?.current?.toggle(event, target);
			},
		}));

		const userName = user?.name ?? "";

		const handleAssignGroupClick = () => {
			overlayRef?.current?.hide();
			setIsVisibleAssignGroupModal(true);
		};

		const hanldeAssignGroupModalOnDismiss = () => {
			setIsVisibleAssignGroupModal(false);
		};

		const handleRemoveUserOnClick = () => {
			overlayRef?.current?.hide();
			setIsVisibleRemoveMemberModal(true);
		};

		const hanldeRemoveMemberModalOnDismiss = () => {
			setIsVisibleRemoveMemberModal(false);
		};

		const handleCloseMenueOnClick = () => {
			overlayRef?.current?.hide();
		};

		const iconSizeRem = isMediumUp ? 1.5 : 1;
		const ItemLabelSpan = isMediumUp ? TkaP2Span : TkaP1Span;

		return (
			<>
				<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing0}>
					<Wrapper>
						{!isMediumUp && (
							<TitleWrapper>
								<H1Span>Aktion wählen</H1Span>
								<TkaP2Span tkaColor={shade80}>{userName}</TkaP2Span>
							</TitleWrapper>
						)}
						<OptionsWrapper>
							<ItemWrapper onClick={handleAssignGroupClick}>
								<Icon icon={"folder"} sizeInRem={iconSizeRem} />
								<ItemLabelSpan>Gruppe zuweisen</ItemLabelSpan>
							</ItemWrapper>
							{!isMediumUp && <Divider />}
							<ItemWrapper onClick={handleRemoveUserOnClick}>
								<Icon tkaColor={error100} icon={"trash"} sizeInRem={iconSizeRem} />
								<ItemLabelSpan tkaColor={error100}>
									Benutzer entfernen
								</ItemLabelSpan>
							</ItemWrapper>
						</OptionsWrapper>
						{!isMediumUp && (
							<Button
								label="Schließen"
								fillParent
								onClick={handleCloseMenueOnClick}
							/>
						)}
					</Wrapper>
				</ResponsiveBottomSheetOverlayPanel>
				{user && (
					<AssignGroupModal
						isVisible={isVisibleAssignGroupModal}
						selectedMembers={[user]}
						onDismiss={hanldeAssignGroupModalOnDismiss}
						queryFragmentRef={queryFragment}
						onSuccess={hanldeAssignGroupModalOnDismiss}
					/>
				)}
				{user && (
					<RemoveMemberModal
						isVisible={isVisibleRemoveMemberModal}
						selectedMembers={[user]}
						onDismiss={hanldeRemoveMemberModalOnDismiss}
					/>
				)}
			</>
		);
	},
);
