import React from "react";

import { DividerList } from "@components/divider-list";
import { Skeleton } from "@components/skeleton";

export const BranchNodesTreeSkeleton = () => {
	return (
		<DividerList hasDivider>
			<Skeleton width="100%" height="3.5rem" borderRadius="0.625rem" />
			<Skeleton width="100%" height="3.5rem" borderRadius="0.625rem" />
			<Skeleton width="100%" height="3.5rem" borderRadius="0.625rem" />
			<Skeleton width="100%" height="3.5rem" borderRadius="0.625rem" />
		</DividerList>
	);
};
