/**
 * @generated SignedSource<<c450a3df257cec8902f0777640162331>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appointments_CoachAvailabilitiesFragment$data = {
  readonly Coaching: {
    readonly CoachAvailabilities: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"appointments_AvailabilityFragment">;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "appointments_CoachAvailabilitiesFragment";
};
export type appointments_CoachAvailabilitiesFragment$key = {
  readonly " $data"?: appointments_CoachAvailabilitiesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointments_CoachAvailabilitiesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Coaching",
  "CoachAvailabilities"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "onlyNeedConfirmation"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./appointments_CoachAvailabilitiesQueryFragmentRefetch.graphql')
    }
  },
  "name": "appointments_CoachAvailabilitiesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachingQueries",
      "kind": "LinkedField",
      "name": "Coaching",
      "plural": false,
      "selections": [
        {
          "alias": "CoachAvailabilities",
          "args": [
            {
              "kind": "Variable",
              "name": "onlyNeedConfirmation",
              "variableName": "onlyNeedConfirmation"
            }
          ],
          "concreteType": "AvailabilityConnection",
          "kind": "LinkedField",
          "name": "__appointments_CoachAvailabilities_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AvailabilityEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Availability",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "appointments_AvailabilityFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AvailabilityData",
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startDateTime",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "endDateTime",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "appointmentCard_AvailabilityFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "appointmentModalsFlow_AvailabilityFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "unconfirmedAppointmentCard_AvailabilityFragment"
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "62a3c8640c32ab832058bf4c847484f7";

export default node;
