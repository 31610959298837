import ReactCountryFlag from "react-country-flag";
import { DropdownOption } from "@components/dropdown/dropdown.types";
import { Icon } from "@components/icon";
import { ValidatedDropdown } from "@components/validated-dropdown";
import { TkaP2Span } from "@themes/font-tags";
import { DropdownItemWrapper } from "./residence-dropdown.styles";
import { CountryCode, ResidenceDropdownProps } from "./residence-dropdown.types";
import { getDropdownOption } from "./residence-dropdown.utils";

export const ResidenceDropdown = <T,>(props: ResidenceDropdownProps<T>) => {
	const renderDropdownItem = (item: DropdownOption) => (
		<DropdownItemWrapper>
			{item.value === "OTHER" ? (
				<Icon icon="question" sizeInRem={1} />
			) : (
				<ReactCountryFlag countryCode={item.value} svg />
			)}
			<TkaP2Span>{item.label}</TkaP2Span>
		</DropdownItemWrapper>
	);

	const value = props.formikConfig.values[props.name] as unknown as CountryCode | undefined;
	const status = props.status ?? (props.disabled ? "disabled" : undefined);

	return (
		<ValidatedDropdown
			{...props}
			status={status}
			options={props.options}
			itemTemplate={renderDropdownItem}
			valueTemplate={renderDropdownItem(
				(value ? getDropdownOption(value, props.options) : undefined) ?? props.options[0],
			)}
		/>
	);
};
