import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
	md:min-w-[25rem]
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const TagsWrapper = tw.div`
	flex
	flex-row
	flex-wrap
	items-center
	justify-center
	gap-4
`;

export const CardWrapper = tw.div`
	flex
	flex-col
	gap-16
	items-center
`;

export const HeaderWrapper = tw.div`
	flex
	flex-col
	gap-4
	items-center
	text-center
	self-stretch
`;
