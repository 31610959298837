import graphql from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment bookingSuccessModal_AvailabilityFragment on Availability {
		booking {
			... on BookedOneToOne {
				offer {
					description {
						name
						tags {
							id
							data {
								name
							}
						}
					}
				}
			}
			... on BookedForGroup {
				offer {
					description {
						name
						tags {
							id
							data {
								name
							}
						}
					}
				}
			}
		}
		data {
			startDateTime
			endDateTime
		}
	}
`;
