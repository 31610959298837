import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
	px-16
	py-8
	gap-8
	items-center
	bg-shade-0
	hover:bg-brand-strong-40
	hover:cursor-pointer
`;
