import { Card } from "@components/card";
import { Skeleton } from "@components/skeleton";
import { shade40 } from "@themes/colors";
import { Wrapper } from "./group-card.styles";

export const GroupCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={0.75} borderColor={shade40}>
			<Wrapper>
				<Skeleton width="2.5rem" height="2.5rem" borderRadius="2.5rem" />
				<Skeleton width="4rem" />
			</Wrapper>
		</Card>
	);
};
