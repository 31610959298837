import { useEffect } from "react";
import { AuthTemplate } from "@components/auth-template";
import { LoginSteps } from "@components/login-steps";
import { gtmTrackViewLogin } from "../../analytics/google-tag-manager";

export const LoginScreen = () => {
	useEffect(() => {
		gtmTrackViewLogin();
	}, []);
	return (
		<AuthTemplate>
			<LoginSteps />
		</AuthTemplate>
	);
};
