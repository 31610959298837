import { FC } from "react";
import { useFragment } from "react-relay";
import RewardChest from "@assets/reward-chest.svg";
import { RootCard } from "@components/root-card";
import { LicenseProductCardWrapper } from "./license-product-card.styles";
import { LicenseProductCardProps } from "./license-product-card.types";
import { LICENSE_GROUPING_FRAGMENT } from "./licenses-product-card.graphql";

export const LicenseProductCard: FC<LicenseProductCardProps> = ({ queryFragmentRef }) => {
	const licensesProductCardQueryFragment = useFragment(
		LICENSE_GROUPING_FRAGMENT,
		queryFragmentRef ?? null,
	);

	const isMicroLearning = licensesProductCardQueryFragment?.kind === "MicroLearning";

	const learnable = licensesProductCardQueryFragment?.learnable;
	const kind = isMicroLearning
		? "Micro Learning"
		: learnable?.groupCoaching || learnable?.oneToOneCoaching
		? "Coaching"
		: "Weiterbildung";

	const product = {
		id:
			learnable?.groupCoaching?.id ??
			learnable?.oneToOneCoaching?.id ??
			learnable?.root?.id ??
			"",
		title:
			learnable?.groupCoaching?.description?.name ??
			learnable?.oneToOneCoaching?.description?.name ??
			learnable?.root?.structureDefinition?.title ??
			"",
		imageUrl:
			learnable?.groupCoaching?.description?.image?.thumbnail ??
			learnable?.oneToOneCoaching?.description?.image?.thumbnail ??
			learnable?.root?.image?.thumbnail ??
			"",
		contractPartner:
			learnable?.groupCoaching?.description?.contractPartner?.data ??
			learnable?.oneToOneCoaching?.description?.contractPartner?.data ??
			learnable?.root?.structureDefinition?.extension?.extension?.contractPartner?.data,
	};

	return (
		<LicenseProductCardWrapper>
			<RootCard
				title={isMicroLearning ? "Such dir ein Micro Learning aus" : product?.title}
				topic={kind}
				imageUrl={isMicroLearning ? RewardChest : product.imageUrl}
				partnerName={product.contractPartner?.name}
				partnerUrl={product?.contractPartner?.linkToAGB ?? undefined}
			/>
		</LicenseProductCardWrapper>
	);
};
