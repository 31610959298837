import { TkaP2Span } from "@themes/font-tags";
import { InlineIconWrapper } from "./inline-icon.styles";
import { InlineIconProps } from "./inline-icon.types";
import { Icon } from "../icon";

export const InlineIcon: React.FC<InlineIconProps> = ({ text, icon, sizeInRem, iconColor }) => {
	return (
		<InlineIconWrapper>
			<TkaP2Span>
				{text}
				<Icon icon={icon} sizeInRem={sizeInRem} tkaColor={iconColor} />
			</TkaP2Span>
		</InlineIconWrapper>
	);
};
