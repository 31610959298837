export const convertToReadableTime = (seconds: number): string => {
	const numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
	const numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
	const numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;

	return (
		addZeroIfSingleValue(numHours) +
		":" +
		addZeroIfSingleValue(numMinutes) +
		":" +
		addZeroIfSingleValue(numSeconds)
	);
};

const addZeroIfSingleValue = (value: number) => {
	return value < 10 ? "0" + value : value;
};
