import { useNavigate } from "react-router-dom";
import PotentialAnalysisImage from "@assets/potential-analysis.png";
import { CardBanner } from "@components/card-banner";
import { Path } from "@router/paths";

export const PotentialAnalysisBanner = () => {
	const navigate = useNavigate();
	const handleButtonOnClick = () => {
		navigate(Path.orientation.path);
	};

	return (
		<CardBanner
			title="Bist du bereit, dein Potenzial maximal zu nutzen?"
			content={
				<span>
					Entdecke dein volles Potenzial mit unserer kostenlosen Potenzialanalyse MAX!
					Erhalte ein individuelles Persönlichkeitsprofil, vergleiche es mit dem Markt und
					nutze unsere persönliche Beratung, um deine eigene Weiterentwicklung zu planen.
					Der Einstieg ist einfach - registriere dich unverbindlich und profitiere
					zusätzlich von unseren kostenfreien Micro Learning-Einheiten.
				</span>
			}
			decorativeBannerText={
				<span>
					Jetzt
					<br />
					loslegen
				</span>
			}
			buttonLabel="Zur Potenzialanalyse MAX"
			buttonIconName="arrowRight"
			buttonOnClick={handleButtonOnClick}
			src={PotentialAnalysisImage}
			alt="Placeholder"
		/>
	);
};
