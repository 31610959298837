import { Card } from "primereact/card";
import { CSSProperties } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { DEFAULT_BORDER_RADIUS_IN_REM } from "@components/card/card.consts";
import { TkaColor } from "@themes/colors";

export const StyledCard = styled(Card)<{
	showBorder: boolean;
	borderColor: TkaColor;
	bodyPadding: string;
	style: CSSProperties;
	hasMinContent: boolean | undefined;
	fillParent?: boolean;
	fillHeight?: boolean;
	contentOverflowHidden?: boolean;
	clickable?: boolean;
	borderRadiusRem?: number;
}>`
	box-sizing: border-box;
	&.p-card {
		border-radius: ${(p) =>
			p.borderRadiusRem
				? `${p.borderRadiusRem}rem;`
				: `${DEFAULT_BORDER_RADIUS_IN_REM.rem()};`};
		${({ fillParent }) => fillParent && tw`flex flex-1 self-stretch`}
		box-shadow: ${({ borderColor, showBorder }) =>
			showBorder ? `0px 0px 0px 1px ${borderColor} inset` : "none"};
		transition: 0.3s ease-out all;
		${(p) => (p.hasMinContent ? "display: inline-block;" : "")};
		overflow: hidden;
		${({ fillHeight }) => fillHeight && tw`h-full`}
	}
	& > .p-card-body {
		padding: ${(p) => p.bodyPadding};
		${({ fillParent }) => fillParent && tw`flex flex-1 self-stretch`}
	}
	& > .p-card-body > .p-card-content {
		padding: 0;
		${(p) => (p.hasMinContent ? "display: flex;" : "")};
		${(p) =>
			p.contentOverflowHidden
				? `
		text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
		`
				: ""}
		${({ fillParent }) => fillParent && tw`flex flex-1 self-stretch`}
	}
	${({ clickable }) => clickable && "cursor: pointer;"}
`;
