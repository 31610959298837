import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { AccountMemberKind } from "@relay/membersList_Query.graphql";
import { Group } from "../filter-modal/filter-modal.types";

export type FilterContext = {
	selectedStatus: string;
	selectedGroups: Group[];
	setSelectedStatus: Dispatch<SetStateAction<string>>;
	setSelectedGroups: Dispatch<SetStateAction<Group[]>>;
	filterByAccountMemberKind?: AccountMemberKind | null | undefined;
	numberOfAppliedFilters?: number;
};
export const FilterContext = createContext<FilterContext>({
	selectedGroups: [],
	selectedStatus: "",
	setSelectedStatus: () => "",
	setSelectedGroups: () => [],
	filterByAccountMemberKind: undefined,
	numberOfAppliedFilters: undefined,
});

export type ProviderProps = {
	children: ReactNode;
};
export const FilterContextProvider: React.FC<ProviderProps> = ({ children }) => {
	const [selectedStatus, setSelectedStatus] = useState("");
	const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

	const filterByAccountMemberKind: AccountMemberKind | null | undefined =
		selectedStatus === "unconfirmed"
			? "Invitation"
			: selectedStatus === "confirmed"
			? "UserInAccount"
			: undefined;

	const numberOfAppliedFilters =
		(selectedGroups && selectedGroups.length > 0 ? 1 : 0) +
		(selectedStatus && selectedStatus.length > 0 ? 1 : 0);

	const value = {
		selectedStatus,
		setSelectedStatus,
		selectedGroups,
		setSelectedGroups,
		filterByAccountMemberKind,
		numberOfAppliedFilters,
	};

	return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useMembersFilterContext = () => {
	const context = useContext(FilterContext);
	if (!context) {
		throw new Error(
			"useAccountScreenContext must be used within AccountScreenContext.Provider",
		);
	}
	return context;
};
