import tw from "twin.macro";

export const Center = tw.div`
	flex
	flex-col
	flex-1
	items-center
`;

export const MinWidth = tw.div`
	flex
	flex-col
	min-w-[min(20rem,100%)]
	md:min-w-[20rem]
`;
