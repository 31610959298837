import { CountryCode as GraphQLCountryCode } from "@relay/accountTab_EditBusinessBaseDataMutation.graphql";
import { accountTab_QueryFragment$key } from "@relay/accountTab_QueryFragment.graphql";
import { BusinessAccountFormState } from "./parts/business-account-form/business-account-form.types";
import { PrivateAccountFormState } from "./parts/private-account-form/private-account-form.types";

export type AccountTabProps = {
	queryFragmentRef?: accountTab_QueryFragment$key | null;
	onLoadingStateChanged?: (isLoading: boolean) => void;
};

export type AccountBaseFormState = {
	accountName: string;
};

export type PrivateAccountSubformState = {
	accountType: AccountType.Private;
	subform?: PrivateAccountFormState;
} & AccountBaseFormState;

export type BusinessAccountSubformState = {
	accountType: AccountType.Bussiness;
	subform?: BusinessAccountFormState;
} & AccountBaseFormState;

export type AccountFormState = PrivateAccountSubformState | BusinessAccountSubformState;

export enum AccountType {
	Bussiness = "Bussiness",
	Private = "Private",
}

export type CountryCode = Exclude<GraphQLCountryCode, "%future added value">;

export type AccountTabRef = {
	save: () => void;
};
