import {
	SelectionWrapper,
	WordPackageListWrapper,
} from "@screens/vocabulary/parts/word-package-list/word-package-list.styles";
import { TkaP2Span } from "@themes/font-tags";
import { SelectionModel } from "@utils/selection";
import { WordPackageCardSkeleton } from "../word-package-card/word-package-card.skeleton";

export const WordPackageListSkeleton = ({
	selectionModel,
}: {
	selectionModel?: SelectionModel<string> | undefined;
}) => {
	return (
		<WordPackageListWrapper>
			{selectionModel && (
				<SelectionWrapper>
					<TkaP2Span>
						{selectionModel.selectedItems.length} Vokabel
						{selectionModel.selectedItems.length === 1 ? "" : "n"} ausgewählt
					</TkaP2Span>
				</SelectionWrapper>
			)}

			<WordPackageCardSkeleton selectionModel={selectionModel} />
			<WordPackageCardSkeleton selectionModel={selectionModel} />
			<WordPackageCardSkeleton selectionModel={selectionModel} />
			<WordPackageCardSkeleton selectionModel={selectionModel} />
			<WordPackageCardSkeleton selectionModel={selectionModel} />
			<WordPackageCardSkeleton selectionModel={selectionModel} />
		</WordPackageListWrapper>
	);
};
