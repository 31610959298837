import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div<{ disabled?: boolean }>`
	${tw`
		w-[1.5rem]
		h-[1.5rem]
		flex
		items-center
		justify-center
		bg-shade-5
		rounded-12
	`}
	${({ disabled }) => (disabled ? tw`hover:cursor-default` : tw`hover:cursor-pointer`)}
`;
