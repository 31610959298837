import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query shoppingCartSuccess_Query($orderId: ID!, $skip: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartSuccess_OrderFragment
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						email
					}
				}
			}
		}
	}
`;

export const SUCCESS_SCREEN_ORDER_FRAGMENT = graphql`
	fragment shoppingCartSuccess_OrderFragment on Order {
		id
		cart {
			items {
				itemType
				... on CartProduct {
					amount
					product {
						id
						title
					}
				}
			}
			totals {
				includingAllDiscounts {
					netPrice
				}
			}
		}
		...shoppingCartLegalForm_OrderFragment
		...shoppingCartTemplate_OrderFragment
	}
`;
