import styled from "styled-components";
import tw from "twin.macro";
import { BreakpointInPx } from "@themes/breakpoint";

export const Wrapper = tw.div`
	flex
	flex-col
	md:min-w-[25rem]
`;

export const GroupCardsWrapper = tw.div`
flex
flex-col
gap-[0.5rem]
mt-[0.5rem]
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	gap: 8px;
	> div > button { 
		margin-top: 12px;
		min-width: 150px;
	}
	@media screen and (min-width: ${BreakpointInPx.medium}px) {
		flex-grow: 0;
		> div > button { 
			min-width: auto;
			margin-top: 0px;
		}
	}
`;

export const LabelsWrapper = tw.div`
	flex
	flex-row
	flex-wrap
	mt-4
	md:flex-nowrap
	gap-8
`;

export const SearchWrapper = tw.div`flex gap-8 justify-between mr-8`;

export const MembersAndLabelsWrapper = tw.div`flex-col flex-grow`;

export const BottomBatchEditSpacer = tw.div`
	h-[4rem]
	md:hidden
`;
