/**
 * @generated SignedSource<<97d7fd01126d3c9c83ff7f60da9d1e32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CanShowElementAnswerInput = {
  clientMutationId?: string | null;
  contentSubmissionId: string;
};
export type ShowAnswerButton_CanShowElementAnswerMutation$variables = {
  input: CanShowElementAnswerInput;
};
export type ShowAnswerButton_CanShowElementAnswerMutation$data = {
  readonly ELearning: {
    readonly canShowElementAnswer: {
      readonly can: boolean;
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type ShowAnswerButton_CanShowElementAnswerMutation = {
  response: ShowAnswerButton_CanShowElementAnswerMutation$data;
  variables: ShowAnswerButton_CanShowElementAnswerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ELearningMutations",
    "kind": "LinkedField",
    "name": "ELearning",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "CanShowElementAnswerPayload",
        "kind": "LinkedField",
        "name": "canShowElementAnswer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "can",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShowAnswerButton_CanShowElementAnswerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShowAnswerButton_CanShowElementAnswerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c471b2537ce3cd8f9276e0d1ad32b4c6",
    "id": null,
    "metadata": {},
    "name": "ShowAnswerButton_CanShowElementAnswerMutation",
    "operationKind": "mutation",
    "text": "mutation ShowAnswerButton_CanShowElementAnswerMutation(\n  $input: CanShowElementAnswerInput!\n) {\n  ELearning {\n    canShowElementAnswer(input: $input) {\n      clientMutationId\n      can\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "63a7a98ee254338f7dd0bbdfb8c1f0ee";

export default node;
