import { ImageCardAtom } from "@components/ImageCardAtom";

import { TkaP3Span } from "@themes/font-tags";
import { spacing0, spacing12 } from "@themes/spacing";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import { Content, ImageWrapper, Title, Wrapper } from "./book-coaching-card.styles";
import { BookCoachingCardProps } from "./book-coaching-card.type";
import { Card } from "../card";

// TODO: add-translations
export const BookCoachingCard = ({
	imageUrl,
	title,
	zonedDateTimeStart,
	zonedDateTimeEnd,
	noBorder,
}: BookCoachingCardProps) => {
	const imageWidth = "4.375rem";

	const padding = noBorder ? spacing0 : spacing12;
	const maxLines = zonedDateTimeStart ? 1 : 2;

	const renderTime = () => {
		if (!zonedDateTimeStart || !zonedDateTimeEnd) return "";
		return formatAppointmentDateTimeRange(zonedDateTimeStart, zonedDateTimeEnd, false);
	};

	const content = (
		<Wrapper>
			{imageUrl && (
				<ImageWrapper>
					<ImageCardAtom imageUrl={imageUrl} imageWidth={imageWidth} />
				</ImageWrapper>
			)}
			<Content>
				<TkaP3Span>Termin buchen</TkaP3Span>
				<Title maxLines={maxLines}>{title}</Title>
				{zonedDateTimeStart && zonedDateTimeEnd && (
					<Title maxLines={maxLines}>{renderTime()}</Title>
				)}
			</Content>
		</Wrapper>
	);
	return noBorder ? content : <Card bodyPaddingInRem={padding.remValue()}>{content}</Card>;
};
