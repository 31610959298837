import { FC, useContext, useEffect, useMemo } from "react";
import { useRefetchableFragment } from "react-relay";
import { Button } from "@components/button";
import { Dropdown } from "@components/dropdown";
import { MultiSelect } from "@components/multi-select/multi-select.component";
import { AccountContext } from "@screens/account";
import { getGroupName } from "@screens/account/parts/utils";
import { Spacing24 } from "@screens/profile/profile.styles";
import { QUERY_FRAGMENT } from "./filter-modal.graphql";
import { FilterModalProps } from "./filter-modal.types";
import { ButtonWrapper, FilterHeadline, FilterModalWrapper } from "./filter.styles";
import { useMembersFilterContext } from "../members-filter-component/members-filter.context";

export const FilterModal: FC<FilterModalProps> = ({ overlayRef, queryFragmentRef }) => {
	const [data] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const { selectedStatus, setSelectedStatus, selectedGroups, setSelectedGroups } =
		useMembersFilterContext();

	const { addGroupsConnectionId } = useContext(AccountContext);

	const groupsConnectionId =
		data?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers.__id;

	useEffect(() => {
		groupsConnectionId && addGroupsConnectionId(groupsConnectionId);
	}, [groupsConnectionId]);

	const groupOptions = useMemo(() => {
		return data?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers.edges?.map(
			(edge) => {
				let groupName = "";
				if (edge?.node.group.id) {
					groupName = getGroupName(edge?.node.group.id, edge?.node.group.name);
				}
				return { id: edge?.node.group.id, name: groupName };
			},
		);
	}, []);

	const handleOnClose = () => {
		overlayRef?.current?.hide();
	};

	const handleOnStatusChange = (value: string) => {
		setSelectedStatus(value);
	};

	const handleOnRemoveGroupClick = (groupId: string) => {
		const updatedSelectedGroups = selectedGroups.filter((group) => group.id !== groupId);
		setSelectedGroups(updatedSelectedGroups);
	};

	return (
		<FilterModalWrapper>
			<FilterHeadline>Benutzer anzeigen</FilterHeadline>
			<Dropdown
				label="Status"
				options={[
					{ label: "Alle", value: "all" },
					{ label: "Zugesagt", value: "confirmed" },
					{ label: "Unbestätigt", value: "unconfirmed" },
				]}
				id="status"
				value={selectedStatus || "all"}
				onChange={handleOnStatusChange}
			/>
			<Spacing24 />
			<MultiSelect
				name="groups"
				options={groupOptions}
				value={selectedGroups}
				optionLabel="name"
				onChange={(e) => {
					setSelectedGroups(e.value);
				}}
				onCloseClick={(e) => e && handleOnRemoveGroupClick(e)}
				label="Gruppen"
				placeholder="Alle"
			/>
			<ButtonWrapper>
				<Button onClick={handleOnClose} colorVersion={"tertiary"} label="Schließen" />
			</ButtonWrapper>
		</FilterModalWrapper>
	);
};
