import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
	items-center
	gap-12
`;

export const NameWrapper = tw.div`
	flex-1	
	flex
	flex-col
`;

export const ContextMenuWrapper = tw.div`
	pr-12
	hover:opacity-60
`;

export const NameAndGroupsWrapper = tw.div`
flex
items-center`;

export const LabelsWrapper = tw.div`
flex
	ml-[16px]
	gap-8
	p-4
`;
