import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFragment } from "react-relay";

import { BranchNodeCard } from "@components/branch-node-card";
import { ContentCard } from "@components/content-card";
import { DividerList } from "@components/divider-list";
import { useWindowSize } from "@hooks/use-window-size";
import { branchNodesTree_LearnOpportunityV2Fragment$key } from "@relay/branchNodesTree_LearnOpportunityV2Fragment.graphql";
import { getBranchNodeNumberFromIndexPath, getBranchStatus } from "@utils/index-path.util";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./branch-nodes-tree.graphql";
import { ActiveContentWrapper } from "./branch-nodes-tree.styles";
import { BranchNode, BranchNodesTreeProps, ContentNode } from "./branch-nodes-tree.types";
import { UnlockWallItem } from "./parts/unlock-wall-item";

export const BranchNodesTree = ({
	onContentNodeClick,
	activeNodeId,
	learnOpportunityV2FragmentRef,
}: BranchNodesTreeProps) => {
	const {
		typeDefinition: { children: nodes },
		structureDefinition,
	} = useFragment<branchNodesTree_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef ?? null,
	);

	const { isXLargeUp } = useWindowSize();
	const lastActiveNodeIdRef = useRef<string | undefined>(activeNodeId);
	const [focusActiveNode, setFocusActiveNode] = useState(false);

	const headContentId = structureDefinition.viewerTreeState?.headContentId;
	const firstContentId = nodes?.[0]?.typeDefinition?.children?.[0]?.id;

	const headOrFirstContentId = headContentId ? headContentId : firstContentId;
	const isFinished = structureDefinition.viewerTreeState?.kind === "IsFinished";
	const hasFullAccess = structureDefinition.extension?.unlockInfo?.kind === "FullAccess";
	const isAtHeadPaywall =
		headContentId === structureDefinition.extension?.unlockInfo?.firstContentId;

	const headIndexPath = useMemo(() => {
		if (!nodes) return null;
		for (const node of nodes) {
			const foundContent = node.typeDefinition?.children?.find(
				(content) => content.id === headOrFirstContentId,
			);
			if (foundContent) {
				return foundContent.indexPath;
			}
		}
		return null;
	}, [headOrFirstContentId, nodes]);

	useEffect(() => {
		if (!lastActiveNodeIdRef.current && activeNodeId) {
			isXLargeUp && setFocusActiveNode(true);
		} else {
			setFocusActiveNode(false);
		}
		lastActiveNodeIdRef.current = activeNodeId;
	}, [activeNodeId]);

	const skipFocus = lastActiveNodeIdRef.current && lastActiveNodeIdRef.current !== activeNodeId;

	const lastUnlockedChildId = useMemo(() => {
		const unlockInfo = structureDefinition.extension?.unlockInfo;
		if (unlockInfo?.kind !== "Demo") return undefined;
		let lastUnlockedId: string | undefined = undefined;
		let lastCheckedId: string | undefined = undefined;
		nodes?.forEach((node) =>
			node.typeDefinition.children?.forEach((child) => {
				if (child.id === unlockInfo.firstContentId) {
					lastUnlockedId = lastCheckedId;
				}
				lastCheckedId = child.id;
			}),
		);
		return lastUnlockedId;
	}, [structureDefinition.extension?.unlockInfo, nodes]);

	const renderContentNodeCard = (node: ContentNode) => {
		const active = node.id === activeNodeId;

		const handleSetRef = (ref: HTMLDivElement | null) => {
			if (active && focusActiveNode && !skipFocus) {
				ref?.scrollIntoView();
			}
		};

		return (
			<React.Fragment key={node.id}>
				<ActiveContentWrapper ref={handleSetRef} active={active}>
					<ContentCard
						rootFinished={isFinished}
						hasFullAccess={hasFullAccess}
						headIndexPath={headIndexPath}
						isSelected={active}
						onClick={() => onContentNodeClick?.(node.id)}
						learnOpportunityV2FragmentRef={node}
					/>
				</ActiveContentWrapper>
				{node.id === lastUnlockedChildId && !isAtHeadPaywall && <UnlockWallItem />}
			</React.Fragment>
		);
	};

	const renderBranchNodeCard = (node: BranchNode) => {
		return (
			<BranchNodeCard
				key={node.id}
				title={node.structureDefinition.title}
				moduleNumber={getBranchNodeNumberFromIndexPath(node.indexPath)}
				imageUrl={node.image?.url ?? ""}
				courseSize={nodes?.length ?? 0}
				status={getBranchStatus(node.indexPath, headIndexPath, isFinished)}
			>
				{node.typeDefinition?.children?.map(renderContentNodeCard)}
			</BranchNodeCard>
		);
	};

	return nodes ? <DividerList hasDivider>{nodes.map(renderBranchNodeCard)}</DividerList> : null;
};
