import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { useWindowSize } from "@hooks/use-window-size";
import { DeleteGroupsModal } from "@screens/account/parts/groups-tab/parts/delete-groups-modal/delete-groups-modal.component";
import { EditGroupModal } from "@screens/account/parts/groups-tab/parts/edit-group-modal";
import { error100, shade80 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import { USER_IN_ACCOUT_GROUP_FRAGMENT } from "./group-details-context-menu.graphql";
import {
	ItemWrapper,
	OptionsWrapper,
	TitleWrapper,
	Wrapper,
} from "./group-details-context-menu.styles";
import {
	GroupDetailsContextMenuProps,
	GroupDetailsContextMenuRef,
} from "./group-details-context-menu.types";

// TODO: add-translations
export const GroupDetailsContextMenu = forwardRef<
	GroupDetailsContextMenuRef,
	GroupDetailsContextMenuProps
>(function GroupDetailsContextMenu(
	{ userInAccountGroupFragmentRef, groupMembersConnectionId },
	ref,
) {
	const group = useFragment(USER_IN_ACCOUT_GROUP_FRAGMENT, userInAccountGroupFragmentRef ?? null);

	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

	const navigate = useNavigate();
	const { isMediumUp } = useWindowSize();

	const [isVisibleEditGroupModal, setIsVisibleEditGroupModal] = useState(false);
	const [isVisibleDeleteGroupModal, setIsVisibleDeleteGroupModal] = useState(false);

	useImperativeHandle(ref, () => ({
		toggle: (event, target) => {
			overlayRef?.current?.toggle(event, target);
		},
	}));

	const groupId = group?.id;
	const groupName = group?.name ?? "";

	const handleCloseMenueOnClick = () => {
		overlayRef?.current?.hide();
	};

	const handleEditGroupClick = () => {
		overlayRef?.current?.hide();
		setIsVisibleEditGroupModal(true);
	};

	const handleOnDismissEditGroupModal = () => {
		setIsVisibleEditGroupModal(false);
	};

	const handleDeleteGroupClick = () => {
		overlayRef?.current?.hide();
		setIsVisibleDeleteGroupModal(true);
	};

	const handleOnDismissDeleteGroupModal = () => {
		setIsVisibleDeleteGroupModal(false);
	};

	const handleOnSuccessDeleteGroupModal = () => {
		navigate(-1);
	};

	const iconSizeRem = isMediumUp ? 1.5 : 1;
	const ItemLabelSpan = isMediumUp ? TkaP2Span : TkaP1Span;

	const canEditGroup = group?.isBuiltIn === false;
	const canRemoveGroup = group?.isBuiltIn === false;

	return (
		<>
			<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing0}>
				<Wrapper>
					{!isMediumUp && (
						<TitleWrapper>
							<H1Span>Aktion wählen</H1Span>
							<TkaP2Span tkaColor={shade80}>{groupName}</TkaP2Span>
						</TitleWrapper>
					)}
					<OptionsWrapper>
						{canEditGroup && (
							<ItemWrapper onClick={handleEditGroupClick}>
								<Icon icon={"pencil"} sizeInRem={iconSizeRem} />
								<ItemLabelSpan>Bearbeiten</ItemLabelSpan>
							</ItemWrapper>
						)}
						{!isMediumUp && canRemoveGroup && <Divider />}
						{canRemoveGroup && (
							<ItemWrapper onClick={handleDeleteGroupClick}>
								<Icon tkaColor={error100} icon={"trash"} sizeInRem={iconSizeRem} />
								<ItemLabelSpan tkaColor={error100}>Gruppe löschen</ItemLabelSpan>
							</ItemWrapper>
						)}
					</OptionsWrapper>
					{!isMediumUp && (
						<Button label="Schließen" fillParent onClick={handleCloseMenueOnClick} />
					)}
				</Wrapper>
			</ResponsiveBottomSheetOverlayPanel>
			<EditGroupModal
				onDismiss={handleOnDismissEditGroupModal}
				isVisible={isVisibleEditGroupModal}
				groupMembersConnectionId={groupMembersConnectionId}
				userInAccountGroupFragmentRef={group}
			/>
			<DeleteGroupsModal
				isVisible={isVisibleDeleteGroupModal}
				onDismiss={handleOnDismissDeleteGroupModal}
				onSuccess={handleOnSuccessDeleteGroupModal}
				groupIds={groupId ? [groupId] : undefined}
			/>
		</>
	);
});
