import { useCookies } from "@hooks/use-cookies";
import { CAOptinCookie, COOKIE_DOMAIN, COOKIE_MAX_AGE_SECONDS } from "./use-ca-optin-cookie.consts";
import { CAOptinCookieManager } from "./use-ca-optin-cookie.types";

export const useCAOptinCookie = (): CAOptinCookieManager => {
	const { cookie, setCookie, cookieExists } = useCookies<boolean>(CAOptinCookie);

	const handleSetCookie = (value: boolean) => {
		setCookie(value, { maxAge: COOKIE_MAX_AGE_SECONDS, domain: COOKIE_DOMAIN });
	};

	return {
		accepted: cookie === true,
		cookieExists,
		cookie,
		setCookie: handleSetCookie,
	};
};
