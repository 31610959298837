import { spacing12 } from "@themes/spacing";

import {
	Wrapper,
	ParticipantsWrapper,
	BottomWrapper,
	IconWrapper,
	CardWrapper,
	ColGroup,
	TopColGroup,
	Gap16,
} from "./appointment-card.styles";
import { ButtonSkeleton } from "../button/button.skeleton";
import { Card } from "../card";
import { Divider } from "../divider";
import { LabelSkeleton } from "../label/label.skeleton";
import { Skeleton } from "../skeleton";

// TODO: add-translations
export const AppointmentCardSkeleton = () => {
	return (
		<CardWrapper>
			<Card fillParent bodyPaddingInRem={spacing12.remValue()}>
				<Wrapper>
					<TopColGroup>
						<LabelSkeleton />
						<Gap16 />
						<Skeleton width="7rem" />
						<Skeleton width="100%" className="mt-8" />
					</TopColGroup>
					<ColGroup>
						<ParticipantsWrapper>
							<Skeleton width="1.5rem" height="1.5rem" borderRadius="50%" />
							<Skeleton width="4rem" />
						</ParticipantsWrapper>
						<Divider />
						<BottomWrapper className="justify-between">
							<IconWrapper disabled={false}>
								<Skeleton width="2rem" height="2rem" borderRadius="50%" />
							</IconWrapper>
							<ButtonSkeleton />
						</BottomWrapper>
					</ColGroup>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};
