/**
 * @generated SignedSource<<5ea6ba3b02b4e3a94bf239bc39a6f1b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CancelBookingAsParticipantInput = {
  availabilityId: string;
  clientMutationId?: string | null;
};
export type cancelAppointmentModal_CancelBookingAsParticipantMutation$variables = {
  input: CancelBookingAsParticipantInput;
};
export type cancelAppointmentModal_CancelBookingAsParticipantMutation$data = {
  readonly Coaching: {
    readonly cancelBookingAsParticipant: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type cancelAppointmentModal_CancelBookingAsParticipantMutation = {
  response: cancelAppointmentModal_CancelBookingAsParticipantMutation$data;
  variables: cancelAppointmentModal_CancelBookingAsParticipantMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachingMutations",
    "kind": "LinkedField",
    "name": "Coaching",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "CancelBookingAsParticipantPayload",
        "kind": "LinkedField",
        "name": "cancelBookingAsParticipant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cancelAppointmentModal_CancelBookingAsParticipantMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cancelAppointmentModal_CancelBookingAsParticipantMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f6c58bc85a5b1f688f3431deb75c8d3a",
    "id": null,
    "metadata": {},
    "name": "cancelAppointmentModal_CancelBookingAsParticipantMutation",
    "operationKind": "mutation",
    "text": "mutation cancelAppointmentModal_CancelBookingAsParticipantMutation(\n  $input: CancelBookingAsParticipantInput!\n) {\n  Coaching {\n    cancelBookingAsParticipant(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff24ebe69f9aeb44d4ffa02403ef2e47";

export default node;
