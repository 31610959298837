import { Icon } from "@components/icon";
import { ParagraphWithBackgroundColor } from "@components/paragraph-with-background-color";
import { info100, shade100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { AsyncContentSubmissionFilePreviewProps } from "./async-content-submission-file-preview.interface";

export const AsyncContentSubmissionFilePreview = ({
	title,
	fileName,
	url,
}: AsyncContentSubmissionFilePreviewProps) => {
	return (
		<ParagraphWithBackgroundColor title={title}>
			<Icon icon="materialComplete" tkaColor={shade100} sizeInRem={1.5} />
			<a href={url} target="_blank" rel="noreferrer">
				<TkaP2Span tkaColor={info100}>{fileName}</TkaP2Span>
			</a>
		</ParagraphWithBackgroundColor>
	);
};
