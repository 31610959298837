import { Suspense } from "react";
import { Loader } from "../Loader";

/**
 * Wraps component with suspense component.
 * @param Component
 */
export function withSuspense<T>(Component: React.FC<T>, Skeleton: React.FC | null) {
	const SuspendedComponent = (props: T) => (
		<Suspense fallback={Skeleton ? <Skeleton /> : <Loader />}>
			{/* @ts-ignore */}
			<Component {...props} />
		</Suspense>
	);
	return SuspendedComponent;
}
