import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
	md:min-w-[min(90vw,25rem)]
	md:max-w-[min(90vw,25rem)]
`;

export const HeaderWrapper = tw.div`
	py-40
`;

export const RowWrapper = tw.div`
	flex
	flex-row
	gap-16
	items-center
`;

export const UsersCountWrapper = tw.div`
	flex
	flex-row
	gap-8
	items-center
`;
