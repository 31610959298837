import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment licensesTab_LicensesTabFragment on Query
	@refetchable(queryName: "licensesTabRefetchQuery")
	@argumentDefinitions(
		kinds: { type: "[LearnableKind!]!" }
		licensesAvailable: { type: "Boolean" }
		licensesConsumed: { type: "Boolean" }
		text: { type: "String" }
	) {
		LicenseGrouping {
			MyLicenses(
				kinds: $kinds
				licensesAvailable: $licensesAvailable
				licensesConsumed: $licensesConsumed
				text: $text
			) {
				edges {
					node {
						id
						...licensesGroupCard_LicenseGroupingFragment
					}
				}
			}
		}
	}
`;
