/**
 * @generated SignedSource<<c363d1fd76cc6cdbfc41eea6e5853a81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordCard_WordProficiencyFragment$data = {
  readonly proficiencyLevel: number;
  readonly wordId: string;
  readonly " $fragmentType": "wordCard_WordProficiencyFragment";
};
export type wordCard_WordProficiencyFragment$key = {
  readonly " $data"?: wordCard_WordProficiencyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"wordCard_WordProficiencyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "wordCard_WordProficiencyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wordId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proficiencyLevel",
      "storageKey": null
    }
  ],
  "type": "WordProficiency",
  "abstractKey": null
};

(node as any).hash = "b04f5d4300ef5cead1d5199dddb076a1";

export default node;
