import graphql from "babel-plugin-relay/macro";

export const RESEND_ACTIVATION_MUTATION = graphql`
	mutation signUpConfirmEmailStep_ResendActivationMutation($input: ResendActivationInput!) {
		Auth {
			resendActivation(input: $input) {
				clientMutationId
			}
		}
	}
`;
