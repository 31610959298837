import { graphql } from "babel-plugin-relay/macro";

export const CREATE_REPORT_MUTATION = graphql`
	mutation createReportModal_createReportMutation($input: CreateReportInput!) {
		Reports {
			createReport(input: $input) {
				referenceNumber
			}
		}
	}
`;
