import { USER_IN_ACCOUNT_GROUP_OWNER_ID, USER_IN_ACCOUNT_GROUP_USER_ID } from "@consts/index";

export const getGroupName = (groupId: string, name?: string) => {
	if (groupId === USER_IN_ACCOUNT_GROUP_OWNER_ID) {
		return "Manager:In";
	}
	if (groupId === USER_IN_ACCOUNT_GROUP_USER_ID) {
		return "Benutzer:In";
	}
	return name ?? "";
};
