import { Kind } from "@relay/licenseDetails_Query.graphql";
import { AccountExtensionKind } from "@relay/licenseSettings_QueryFragment.graphql";

export type AccountData = {
	readonly id: string;
	readonly extension: {
		readonly kind: AccountExtensionKind;
		readonly licenseModelKind: Kind;
	};
}[];

export const getLicenseModelKindForAccount = (
	accountData: AccountData,
	currentAccountId?: string,
) => {
	if (accountData && currentAccountId) {
		const account = accountData.find((account) => account.id === currentAccountId);
		return account?.extension?.licenseModelKind;
	}
	return undefined;
};
