import graphql from "babel-plugin-relay/macro";

export const REMOVE_USERS_FROM_USER_IN_ACCOUNT_GROUP_MUTATION = graphql`
	mutation removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation(
		$input: RemoveUsersFromUserInAccountGroupInput!
		$connections: [ID!]!
	) {
		AccountMemberManagement {
			removeUsersFromUserInAccountGroup(input: $input) {
				removedIds @deleteEdge(connections: $connections)
			}
		}
	}
`;
