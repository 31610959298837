/**
 * @generated SignedSource<<c32eadfa78adb54a34cf5be53aacc8a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userRulesTab_UserQueryFragment$data = {
  readonly AccountMemberManagement: {
    readonly AccountMembers: {
      readonly accountMembers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"licensesSingleUserAssignmentCard_AccountMemberFragment" | "userRulesTab_AccountMemberInlineFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "userRulesTab_UserQueryFragment";
};
export type userRulesTab_UserQueryFragment$key = {
  readonly " $data"?: userRulesTab_UserQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userRulesTab_UserQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userRulesTab_UserQueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountMemberManagementQueries",
      "kind": "LinkedField",
      "name": "AccountMemberManagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filterByGroupIds",
              "value": []
            }
          ],
          "concreteType": "AccountMembersResult",
          "kind": "LinkedField",
          "name": "AccountMembers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountMemberConnection",
              "kind": "LinkedField",
              "name": "accountMembers",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountMemberEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "licensesSingleUserAssignmentCard_AccountMemberFragment"
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "userRulesTab_AccountMemberInlineFragment",
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "UserInAccount",
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "User",
                                      "kind": "LinkedField",
                                      "name": "user",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "id",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "UserInAccountWrapper",
                              "abstractKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "AccountMembers(filterByGroupIds:[])"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "bd068aa56bdc3affa48347e660c65106";

export default node;
