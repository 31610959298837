import { PanelHeaderTemplateOptions, PanelToggleEvent } from "primereact/panel";
import { ExpandedContentWrapper, HeaderWrapper, StyledPanel } from "./radio-button-panel.styles";
import { RadioButtonPanelProps } from "./radio-button-panel.types";
import { RadioButton, RadioButtonStatus } from "../radio-button";

export const RadioButtonPanel = ({
	header,
	checked,
	disabled,
	onChange,
	children,
}: RadioButtonPanelProps) => {
	const renderHeaderTemplate = (options: PanelHeaderTemplateOptions) => (
		<HeaderWrapper onClick={options.onTogglerClick} disabled={disabled}>
			{header}
			<RadioButton
				status={disabled ? RadioButtonStatus.Disabled : undefined}
				checked={!options.collapsed}
			/>
		</HeaderWrapper>
	);

	const handleOnToggle = (event: PanelToggleEvent) => {
		!disabled && onChange?.(!event.value);
	};
	const hasChildren = !!children;

	return (
		<StyledPanel
			toggleable
			disabled={disabled}
			collapsed={!checked}
			onToggle={handleOnToggle}
			headerTemplate={renderHeaderTemplate}
			hasChildren={hasChildren}
		>
			{children && <ExpandedContentWrapper>{children}</ExpandedContentWrapper>}
		</StyledPanel>
	);
};
