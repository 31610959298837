import { error100, error20, info100, info20, success100, success20 } from "@themes/colors";
import { ParticipationOption } from "./participant-presence-select-button.types";

// TODO: add-translations
export const getOptions = (): ParticipationOption[] => {
	return [
		{
			label: "Abwesend",
			value: "Absent",
			selectedBackgorundColor: error20,
			selectedTextColor: error100,
		},
		{
			label: "Entschuldigt",
			value: "AbsentExcused",
			selectedBackgorundColor: info20,
			selectedTextColor: info100,
		},
		{
			label: "Anwesend",
			value: "Participated",
			selectedBackgorundColor: success20,
			selectedTextColor: success100,
		},
	];
};
