import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/button";
import { ValidatedInputText } from "@components/validated-input-text/validated-input-text.component";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { brandStrong100 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import {
	SignUpPasswordEmailStepFormState,
	SignUpPasswordEmailStepProps,
} from "./sign-up-password-email-step.interface";
import {
	FormWrapper,
	InputsWrapper,
	InvitationTitleWrapper,
	TextCenter,
} from "./sign-up-password-email-step.styles";

export const SignUpPasswordEmailStep = ({
	currentStep,
	maxSteps,
	headerText,
	onGoToLogin,
	onNext,
}: SignUpPasswordEmailStepProps) => {
	const { t } = useTkaTranslation("registerForm");

	const form = useFormik<SignUpPasswordEmailStepFormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t("register_form.section1ValidationInvalidEmail"))
				.required(t("register_form.section1ValidationRequiredEmail")),
			password: Yup.string()
				.min(8, t("register_form.section1ValidationInvalidPassword"))
				.required(t("register_form.section1ValidationRequiredPassword")),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	const showStepInfo = maxSteps !== undefined && currentStep !== undefined;

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				{showStepInfo && (
					<TextCenter>
						<TkaP1Span>
							{currentStep}/{maxSteps}
						</TkaP1Span>
					</TextCenter>
				)}
				<TextCenter>
					<InvitationTitleWrapper>
						{headerText && <TkaP2Span>{headerText}</TkaP2Span>}
						<H1Span>{t("register_form.section1Title")}</H1Span>
					</InvitationTitleWrapper>
					<H1Span>{t("register_form.section1Title2")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="email"
						type="default"
						status="default"
						icon="email"
						label={t("register_form.section1PlaceholderEmail")}
						placeholder={t("register_form.section1PlaceholderEmail")}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="password"
						type="password"
						status="default"
						icon="lock"
						label={t("register_form.section1PlaceholderPassword")}
						placeholder={t("register_form.section1PlaceholderPassword")}
						helpText={t("register_form.section1HelperTextPassword")}
					/>
				</InputsWrapper>
				<Button
					fillParent
					label={t("register_form.section1ButtonText")}
					iconName="arrowRight"
					colorVersion={"default"}
				/>
				<TextCenter>
					<TkaP3Span>
						{t("register_form.section1AlreadyRegistered")}
						&nbsp;
						<TkaP3Span tkaColor={brandStrong100} onClick={onGoToLogin}>
							{t("register_form.section1ToLogin")}
						</TkaP3Span>
					</TkaP3Span>
				</TextCenter>
			</FormWrapper>
		</form>
	);
};
