import { useState } from "react";
import { useFragment } from "react-relay";
import { Card } from "@components/card";
import { Checkbox } from "@components/checkbox";
import { DialogTemplate } from "@components/dialog-template";
import { Divider } from "@components/divider";
import { Label } from "@components/label";
import { RadialProgressBar } from "@components/radial-progress-bar/radial-progress-bar.component";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { MultiDialogFlowContainer } from "@containers/multi-dialog-flow-container";
import { wordCard_WordFragment$key } from "@relay/wordCard_WordFragment.graphql";
import { wordCard_WordNoteFragment$key } from "@relay/wordCard_WordNoteFragment.graphql";
import { wordCard_WordProficiencyFragment$key } from "@relay/wordCard_WordProficiencyFragment.graphql";
import {
	WORD_CARD_FRAGMENT,
	WORD_CARD_PROFICIENCY_FRAGMENT,
	WORD_NOTE_FRAGMENT,
} from "@screens/vocabulary/parts/word-card/word-card.graphql";
import {
	CheckboxWrapper,
	Column,
	DescriptionAndNoticeWrapper,
	MarginLeftWrapper,
	ModalWrapper,
	Spacing8,
	WordCardHeaderWrapper,
	WordCardWrapper,
	WordModalHeaderWrapper,
	WordNoteWrapper,
	WordProficiencyWrapper,
	WordTitleWrapper,
} from "@screens/vocabulary/parts/word-card/word-card.styles";
import { WordCardProps } from "@screens/vocabulary/parts/word-card/word-card.types";
import { toCompoundKey } from "@screens/vocabulary/parts/word-card/word-card.utils";
import { shade40 } from "@themes/colors";
import { H1Span, TkaH2Span, TkaH3Span, TkaP1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { spacing12 } from "@themes/spacing";
import { EditWordNoteModal } from "../edit-word-note-modal/edit-word-note-modal.component";

//TODO: add-translations
export const WordCard = ({
	wordPackageId,
	language,
	wordFragmentRef,
	wordProficiencyFragmentRef,
	wordNoteFragmentRef,
	selectionModel,
}: WordCardProps) => {
	const word = useFragment<wordCard_WordFragment$key>(WORD_CARD_FRAGMENT, wordFragmentRef);
	const proficiency = useFragment<wordCard_WordProficiencyFragment$key>(
		WORD_CARD_PROFICIENCY_FRAGMENT,
		wordProficiencyFragmentRef ?? null,
	);
	const note = useFragment<wordCard_WordNoteFragment$key>(
		WORD_NOTE_FRAGMENT,
		wordNoteFragmentRef ?? null,
	);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOnClick = () => {
		setIsModalOpen(true);
	};

	const proficiencyLevelPercentage = (proficiency?.proficiencyLevel ?? 0) / 4;

	const compoundKey = toCompoundKey(wordPackageId, word.id);

	const handleOnDismiss = () => {
		setIsModalOpen(false);
	};

	return (
		<Card bodyPaddingInRem={spacing12.remValue()}>
			<WordCardWrapper>
				<WordCardHeaderWrapper>
					{selectionModel && (
						<CheckboxWrapper>
							<Checkbox
								checked={selectionModel.selectedItems.includes(compoundKey)}
								onChange={(e) => {
									const baseItems = selectionModel.selectedItems.filter(
										(s) => s !== compoundKey,
									);
									if (e.checked) {
										selectionModel?.onSelectionChange([
											...baseItems,
											compoundKey,
										]);
									} else {
										selectionModel?.onSelectionChange(baseItems);
									}
								}}
							/>
						</CheckboxWrapper>
					)}
					<WordTitleWrapper onClick={handleOnClick}>
						<TkaH3Span>{word.word}</TkaH3Span>
						<TkaP3Span>{word.translation}</TkaP3Span>
					</WordTitleWrapper>
					<div onClick={handleOnClick}>
						<RadialProgressBar
							sizeInRem={2}
							value={(proficiency?.proficiencyLevel ?? 0) / 4}
						/>
					</div>
				</WordCardHeaderWrapper>

				<MultiDialogFlowContainer isVisible={isModalOpen} onDismiss={handleOnDismiss}>
					{({ onClose, onNext }) => (
						<>
							<ResponsiveBottomSheetModal isVisible onDismiss={onClose}>
								<DialogTemplate
									maxWidthRem={45}
									secondaryButtonLabel="Schließen"
									secondaryButtonStretch={false}
									onSecondaryButtonClick={onClose}
									primaryButtonLabel="Notiz hinzufügen"
									primaryButtonIconName="add"
									primaryButtonStretch={false}
									onPrimaryButtonClick={onNext}
								>
									<ModalWrapper>
										<WordModalHeaderWrapper>
											<WordTitleWrapper>
												<H1Span>{word.word}</H1Span>
												<TkaP1Span>{word.translation}</TkaP1Span>
											</WordTitleWrapper>
											<MarginLeftWrapper>
												<Label size="big" label={language} />
											</MarginLeftWrapper>
										</WordModalHeaderWrapper>

										<WordProficiencyWrapper>
											<RadialProgressBar
												sizeInRem={4.625}
												value={proficiencyLevelPercentage}
											/>
											<TkaP2Span>
												Vokabelsicherheit{" "}
												{(proficiencyLevelPercentage * 100).toFixed(0)}%
											</TkaP2Span>
										</WordProficiencyWrapper>

										<DescriptionAndNoticeWrapper>
											<Column>
												<Spacing8>
													<TkaH2Span>Definition</TkaH2Span>
												</Spacing8>
												<TkaP2Span>{word.explanation}</TkaP2Span>
											</Column>
											<Column>
												<Spacing8>
													<TkaH2Span>Notizen</TkaH2Span>
												</Spacing8>
												<WordNoteWrapper>
													{note?.note ? (
														<TkaP2Span>{note?.note}</TkaP2Span>
													) : (
														<TkaP2Span tkaColor={shade40}>
															Keine Notizen
														</TkaP2Span>
													)}
												</WordNoteWrapper>
											</Column>
										</DescriptionAndNoticeWrapper>
									</ModalWrapper>
									<Divider />
								</DialogTemplate>
							</ResponsiveBottomSheetModal>
							<EditWordNoteModal
								isVisible
								onDismiss={onClose}
								language={language}
								wordNoteFragmentRef={note}
							/>
						</>
					)}
				</MultiDialogFlowContainer>
			</WordCardWrapper>
		</Card>
	);
};
