/**
 * @generated SignedSource<<ef075e1619718dcffb269a03a5a0592b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ParticipationStatus = "Absent" | "AbsentExcused" | "NotConfirmed" | "Participated";
import { FragmentRefs } from "relay-runtime";
export type appointmentPresence_AvailabilityFragment$data = {
  readonly booking: {
    readonly participant?: {
      readonly participationStatus: ParticipationStatus;
      readonly user: {
        readonly id: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"participantPresenceCheck_ParticipantFragment">;
    };
    readonly participants?: ReadonlyArray<{
      readonly participationStatus: ParticipationStatus;
      readonly user: {
        readonly id: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"participantPresenceCheck_ParticipantFragment">;
    }>;
  };
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly id: string;
  readonly " $fragmentType": "appointmentPresence_AvailabilityFragment";
};
export type appointmentPresence_AvailabilityFragment$key = {
  readonly " $data"?: appointmentPresence_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentPresence_AvailabilityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "participationStatus",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "participantPresenceCheck_ParticipantFragment"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appointmentPresence_AvailabilityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participants",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "BookedForGroup",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participant",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "BookedOneToOne",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Availability",
  "abstractKey": null
};
})();

(node as any).hash = "18332ce175048b10766dd8fb295da0d9";

export default node;
