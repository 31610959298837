import graphql from "babel-plugin-relay/macro";

export const USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT = graphql`
	fragment createGroupSuccessModal_UserInAccountGroupWrapperFragment on UserInAccountGroupWrapper {
		memberCount
		group {
			name
		}
	}
`;
