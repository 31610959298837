import styled from "styled-components";
import tw from "twin.macro";
import { DialogButtonsDirection } from "./dialog-template.types";

export const Wrapper = styled.div<{ maxWidthRem?: number }>`
	${tw`
		flex
		flex-col
		md:gap-[1.5rem]
	`}
	${({ maxWidthRem }) => (maxWidthRem ? `max-width: ${maxWidthRem}rem;` : "")}
`;

export const TextWrapper = tw.div`
	flex
	flex-col
	gap-16
`;

export const ButtonWrapper = styled.div<{
	onlyPrimaryButton: boolean;
	buttonsDirection?: DialogButtonsDirection;
}>`
	${tw`
		flex
		flex-col
		md:flex-row
		md:justify-between
		gap-8
	`}
	${({ onlyPrimaryButton }) => (onlyPrimaryButton ? tw`md:justify-end` : "")}
	${({ buttonsDirection }) =>
		buttonsDirection === DialogButtonsDirection.COLUMN ? tw`md:flex-col md:justify-start` : ""}
`;
