import { SelectButtonChangeEvent } from "primereact/selectbutton";
import { brandMain100, shade0 } from "@themes/colors";
import { Background, StyledButton, Wrapper } from "./select-button.styles";
import { SelectButtonProps } from "./select-button.types";

export const SelectButton = <T,>({
	value,
	disableChange,
	onChange,
	options,
}: SelectButtonProps<T>) => {
	const handleOnChange = (event: SelectButtonChangeEvent) => {
		(!disableChange || !value) && onChange?.(event.value);
	};

	const valueOption = options.find((option) => option.value === value);

	return (
		<Wrapper>
			<Background>
				<StyledButton
					disableUnselected={!!(disableChange && value)}
					selectedBackgorundColor={valueOption?.selectedBackgorundColor ?? brandMain100}
					selectedTextColor={valueOption?.selectedTextColor ?? shade0}
					options={options}
					optionLabel="label"
					value={value}
					onChange={handleOnChange}
				/>
			</Background>
		</Wrapper>
	);
};
