import {
	AccountFormState,
	AccountType,
	BusinessAccountSubformState,
	PrivateAccountSubformState,
} from "./account-tab.types";

export const isBusinessForm = (
	form: AccountFormState,
): form is Required<BusinessAccountSubformState> => {
	return (form as AccountFormState).accountType === AccountType.Bussiness && !!form.subform;
};

export const isPrivateForm = (
	form: AccountFormState,
): form is Required<PrivateAccountSubformState> => {
	return (form as AccountFormState).accountType === AccountType.Private && !!form.subform;
};
