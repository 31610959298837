import { graphql } from "babel-plugin-relay/macro";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment accountSwitchMenu_AuthViewerSchemaFragment on AuthViewerSchema {
		currentUser {
			user {
				...userAvatar_UserFragment
				name
			}
			accounts {
				id
				name
			}
		}
	}
`;
