/**
 * @generated SignedSource<<6be4d4f0702f2c345358d0feeceb6d64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartTemplate_OrderFragment$data = {
  readonly selectedPaymentMethod: {
    readonly paymentMethodId: PaymentMethodType;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"basketTimeline_OrderFragment" | "paypalButton_OrderFragment">;
  readonly " $fragmentType": "shoppingCartTemplate_OrderFragment";
};
export type shoppingCartTemplate_OrderFragment$key = {
  readonly " $data"?: shoppingCartTemplate_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartTemplate_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartTemplate_OrderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "basketTimeline_OrderFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "paypalButton_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "7bbf8690d6fe0d0d11b1d088ebc10838";

export default node;
