import { match } from "ts-pattern";
import { MixedQuestion } from "./avgs-potential-analysis-navigation.types";

export const isQuestionAnswered = (question: MixedQuestion): boolean => {
	const isAnswerWithFreeText = (answerId: number): boolean => {
		const answer = question.answerOptions?.find((option) => option.answerOptionId === answerId);
		return !!answer?.isFreeText;
	};
	return match(question.kind)
		.with("MULTIPLE_CHOICE_FREETEXT", "SINGLE_CHOICE_FREETEXT", () => {
			return !!(
				question.givenAnswers?.length &&
				question.givenAnswers?.every((answer) => {
					return isAnswerWithFreeText(answer.answerOptionId) ? !!answer.freeText : true;
				})
			);
		})
		.otherwise(() => {
			return !!(
				question.givenAnswer?.answerOptionId ||
				question.givenAnswer?.freeText ||
				question.givenAnswer?.text ||
				question.givenAnswers?.length
			);
		});
};
