import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment learnableGrid_LearnableFragment on Query
	@refetchable(queryName: "learnableGridRefetchQuery")
	@argumentDefinitions(
		kinds: { type: "[LearnableKind!]!" }
		filterByCompleted: { type: "Boolean", defaultValue: false }
		first: { type: "Int", defaultValue: null }
		last: { type: "Int", defaultValue: null }
		before: { type: "String", defaultValue: null }
		after: { type: "String", defaultValue: null }
	) {
		CustomerJourney {
			Dashboard(kinds: $kinds, filterByCompleted: $filterByCompleted) {
				sortedLearnables(first: $first, last: $last, before: $before, after: $after)
					@connection(key: "learnableGrid_sortedLearnables") {
					edges {
						node {
							learnable {
								id
								kind
								... on PublishedLearnable {
									...educationalOfferCard_PublishedCourseLearnableFragment
									...coachingLearnableCard_PublishedLearnableFragment
								}
							}
							lastUpdated
						}
					}
				}
			}
		}
	}
`;
