import tw from "twin.macro";

export const MenuWrapper = tw.div`
    flex
    flex-col
    md:w-[min(22rem, 90vw)]
    gap-24
`;

export const CurrentUserWrapper = tw.div`
    flex
    flex-col
    gap-24
    items-center
`;

export const AvatarWrapper = tw.div`
    relative
`;

export const PointsWrapper = tw.div`
    absolute
	bottom-0
	translate-y-2/4
    left-0
    right-0
    flex
    justify-center
`;
