import lodash from "lodash";
import { FC, useContext, useEffect, useMemo } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import { Spacing16 } from "@components/news-card/news-card.styles";
import { withSuspense } from "@components/with-suspense";
import { AccountContext } from "@screens/account/account.context";
import { useGroupsTabProps } from "@screens/account/account.hooks";
import { TkaH3Span } from "@themes/font-tags";
import { GroupsListSkeleton } from "./groups-list.skeleton";
import { GroupCardsWrapper } from "./groups-list.styles";
import { GroupsListProps } from "./groups-list.types";
import { GroupsTabContext } from "../../groups-tab.context";
import { QUERY_FRAGMENT, USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT } from "../../groups-tab.graphql";
import { GroupCard } from "../group-card";

export const GroupsListComponent: FC<GroupsListProps> = ({ searchByName }) => {
	const { queryFragmentRef, userInAccountGroupQueryFragmentRef } = useGroupsTabProps();
	const { setNumberOfGroups } = useContext(GroupsTabContext);
	const { addGroupsConnectionId } = useContext(AccountContext);

	const userInAccountGroupQueryFragment = useFragment(
		USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT,
		userInAccountGroupQueryFragmentRef ?? null,
	);
	const [query, refetch] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const groupsConnection =
		query?.AccountMemberManagement.UserInAccountGroupWrappers.userInAccountGroupWrappers;
	const groupsConnectionId = groupsConnection?.__id;
	const groupsEdge = groupsConnection?.edges;

	const [systemGroups, ownGroups] = useMemo(
		() => lodash.partition(groupsEdge, (item) => item?.node.group.isBuiltIn),
		[groupsEdge],
	);

	useEffect(() => {
		setNumberOfGroups?.(groupsEdge?.length ?? 0);
	}, [groupsEdge?.length]);

	useEffect(() => {
		refetch({}, { fetchPolicy: "store-and-network" });
	}, []);

	useEffect(() => {
		groupsConnectionId && addGroupsConnectionId(groupsConnectionId);
	}, [groupsConnectionId]);

	useEffect(() => {
		refetch({
			filterByName: searchByName,
		});
	}, [searchByName]);
	return (
		<>
			<TkaH3Span>System Gruppen</TkaH3Span>
			<GroupCardsWrapper>
				{systemGroups.map((systemGroup) => (
					<GroupCard
						key={systemGroup?.node.group.id}
						userInAccountGroupWrapperFragmentRef={systemGroup?.node}
						queryFragmentRef={userInAccountGroupQueryFragment}
					/>
				))}
			</GroupCardsWrapper>
			{!!ownGroups.length && (
				<>
					<Spacing16 />
					<TkaH3Span>Eigene Gruppen</TkaH3Span>
				</>
			)}
			<GroupCardsWrapper>
				{ownGroups.map((ownGroup) => (
					<GroupCard
						key={ownGroup?.node.group.id}
						userInAccountGroupWrapperFragmentRef={ownGroup?.node}
						queryFragmentRef={userInAccountGroupQueryFragment}
					/>
				))}
			</GroupCardsWrapper>
		</>
	);
};

export const GroupsList = withSuspense(GroupsListComponent, GroupsListSkeleton);
