import tw from "twin.macro";

export const Wrapper = tw.div`
    flex
    flex-col
    gap-64
`;

export const TitleDescriptionWrapper = tw.div`
    flex
    flex-col
    gap-32
`;

export const DescriptionWrapper = tw.div`
    flex
    flex-col
    gap-12
`;

export const DescriptionButtonWrapper = tw.div`
    flex
    flex-col
    gap-24
`;

export const SideMenuWrapper = tw.div`
    flex
    flex-col
    gap-32
`;

export const SideMenuPadding = tw.div`
    px-24
`;

export const CoachesWrapper = tw.div`
    flex
    flex-col
    gap-12
`;

export const CoachCardsWrapper = tw.div`
    flex
    flex-col
    md:flex-row
    flex-wrap
    gap-24
`;

export const BottomTextWrapper = tw.div`
    px-16
`;
