/**
 * @generated SignedSource<<86496387b4dff0785d6b15918d3bf549>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type coachingDetails_GetCoachingQuery$variables = {
  id: string;
};
export type coachingDetails_GetCoachingQuery$data = {
  readonly node: {
    readonly associatedCoaches?: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"coachCard_UserFragment">;
    }>;
    readonly cartClickout?: {
      readonly relativeLink: string;
    } | null;
    readonly description?: {
      readonly contents: string | null;
      readonly description: string | null;
      readonly image: {
        readonly url: string | null;
      } | null;
      readonly modules: string | null;
      readonly name: string;
      readonly product: {
        readonly netPrice: number;
      } | null;
    } | null;
    readonly licenseAvailability?: {
      readonly numAvailable?: number;
    };
  } | null;
};
export type coachingDetails_GetCoachingQuery = {
  response: coachingDetails_GetCoachingQuery$data;
  variables: coachingDetails_GetCoachingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contents",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modules",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numAvailable",
      "storageKey": null
    }
  ],
  "type": "LicenseAvailabilityFreeLicensesAvailable",
  "abstractKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "CartClickout",
  "kind": "LinkedField",
  "name": "cartClickout",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relativeLink",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "coachingDetails_GetCoachingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachingOfferDescription",
                "kind": "LinkedField",
                "name": "description",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachProfile",
                "kind": "LinkedField",
                "name": "associatedCoaches",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "coachCard_UserFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "licenseAvailability",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "CoachingOffer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "coachingDetails_GetCoachingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachingOfferDescription",
                "kind": "LinkedField",
                "name": "description",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachProfile",
                "kind": "LinkedField",
                "name": "associatedCoaches",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "coach",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "userExtensionKind",
                            "value": "Academies"
                          }
                        ],
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "position",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "thumbnail",
                                    "storageKey": null
                                  },
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sumGamificationPoints",
                                "storageKey": null
                              }
                            ],
                            "type": "AcademiesUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"Academies\")"
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileData",
                    "kind": "LinkedField",
                    "name": "profileData",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jobTitles",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "licenseAvailability",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "type": "CoachingOffer",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "091a84339aad01820c91e804c748c4ea",
    "id": null,
    "metadata": {},
    "name": "coachingDetails_GetCoachingQuery",
    "operationKind": "query",
    "text": "query coachingDetails_GetCoachingQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on CoachingOffer {\n      description {\n        name\n        description\n        contents\n        modules\n        image {\n          url\n          id\n        }\n        product {\n          netPrice\n          id\n        }\n      }\n      associatedCoaches {\n        id\n        ...coachCard_UserFragment\n      }\n      licenseAvailability {\n        __typename\n        ... on LicenseAvailabilityFreeLicensesAvailable {\n          numAvailable\n        }\n      }\n      cartClickout {\n        relativeLink\n      }\n    }\n    id\n  }\n}\n\nfragment coachCard_UserFragment on CoachProfile {\n  id\n  coach {\n    name\n    extension(userExtensionKind: Academies) {\n      __typename\n      ... on AcademiesUserExtension {\n        position\n        firstName\n        lastName\n      }\n    }\n    ...userAvatar_UserFragment\n    id\n  }\n  profileData {\n    description\n    avatar {\n      url\n      id\n    }\n    jobTitles\n  }\n}\n\nfragment userAvatar_UserFragment on User {\n  extension(userExtensionKind: Academies) {\n    __typename\n    ... on AcademiesUserExtension {\n      firstName\n      lastName\n      avatar {\n        thumbnail\n        id\n      }\n      sumGamificationPoints\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "af658fada3cb595722e2c1fc2ee9819c";

export default node;
