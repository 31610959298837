import { Wrapper } from "./navbar-item.styles";
import { Skeleton } from "../skeleton";

export const NavbarItemSkeleton = () => {
	return (
		<Wrapper>
			<Skeleton width="1rem" height="1rem" />
			<Skeleton width="3rem" />
		</Wrapper>
	);
};
