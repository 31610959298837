import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { ClozeTextElement } from "@components/cloze-text-element";
import { ContentElementsCollection } from "@components/content-elements-collection";
import { ContentSubmissionModalContextProvider } from "@components/content-submission-modal-context-provider";
import { ContentSubmissionNavigation } from "@components/content-submission-navigation";
import { ImageElement } from "@components/image-element";
import { MarkMistakesElement } from "@components/mark-mistakes-element";
import { MatrixElement } from "@components/matrix-element";
import { MultipleChoiceElement } from "@components/multiple-choice-element";
import { OrderElement } from "@components/order-element";
import { PodcastWithTimestamp } from "@components/podcast-with-timestamps-element";
import { TextElement } from "@components/text-element";
import { VideoElement } from "@components/video-element";
import { VOCAB_CONTENT_NODE_ID } from "@consts/index";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";
import {
	ELearningContentSubmissionScreen_ContentSubmissionFragment$key,
	ElementTypeV2,
} from "@relay/ELearningContentSubmissionScreen_ContentSubmissionFragment.graphql";
import { TkaP2Span, H1Span } from "@themes/font-tags";
import { spacing12, spacing32, spacing4 } from "@themes/spacing";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
} from "@utils/index-path.util";
import { parseHtml } from "@utils/parse-html";
import { FlexColumn, VerticalSpace } from "./elearning-content-submission.style";
import { ELearningContentSubmissionProps } from "./ELearningContentSubmission.interface";
import { ContentFinished } from "./parts/content-finished/content-finished.screen";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ELearningContentSubmissionScreen_ContentSubmissionFragment on ContentSubmission {
		id
		learnOpportunity {
			id
			nextContentNodeId
			structureDefinition {
				title
			}
			description
			...contentElementsCollection_LearnOpportunityV2Fragment
			indexPath
			root {
				structureDefinition {
					... on LearnOpportunityRootStructureDefinition {
						viewerTreeState {
							kind
						}
					}
				}
			}
		}
		definition {
			status
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
						elementType
						...PodcastWithTimestamp_PodcastWithTimestampElementFragment
					}
				}
				...TextElement_ElearningContentSubmissionDefinitionFragment
				...VideoElement_ContentSubmissionDefinitionFragment
				...ImageElement_ElearningContentSubmissionDefinitionFragment
			}
		}
		...MultipleChoiceElement_ContentSubmissionFragment
		...OrderElement_ContentSubmissionFragment
		...MarkMistakesElement_ContentSubmissionFragment
		...MatrixElement_ContentSubmissionFragment
		...contentSubmissionNavigation_ContentSubmissionFragment
		...ContentSubmissionModalContextProvider_ContentSubmissionFragment
		...ClozeTextElement_ContentSubmissionFragment
		...contentFinished_ContentSubmissionFragment
	}
`;

export const ELearningContentSubmission = ({
	contentSubmissionFragmentRef,
}: ELearningContentSubmissionProps) => {
	const contentSubmission =
		useFragment<ELearningContentSubmissionScreen_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const learnOpportunity = contentSubmission!.learnOpportunity;
	const indexPath = learnOpportunity!.indexPath;
	const title = learnOpportunity!.structureDefinition!.title;
	const description = parseHtml(learnOpportunity!.description || "", {}, "small");
	const path = `Lektion ${getBranchNodeNumberFromIndexPath(
		indexPath,
	)}.${getContentNodeNumberFromIndexPath(indexPath)}`;

	const elementId = contentSubmission?.definition?.currentElementState?.element.id;
	const elementType = contentSubmission?.definition?.currentElementState?.element
		.elementType as ElementTypeV2;

	const isVocabContent = contentSubmission.learnOpportunity?.id === VOCAB_CONTENT_NODE_ID;

	const element = (() => {
		if (!contentSubmission) return <React.Fragment />;
		if (
			contentSubmission.definition.status === "passed" ||
			contentSubmission.definition.status === "failed"
		) {
			return <ContentFinished contentSubmissionFragmentRef={contentSubmission} />;
		}
		switch (elementType) {
			case "matrix":
				return <MatrixElement contentSubmissionFragmentRef={contentSubmission} />;
			case "multipleChoice":
				return <MultipleChoiceElement contentSubmissionFragmentRef={contentSubmission} />;
			case "text":
			case "enhancedText":
				return (
					<TextElement
						contentSubmissionDefinitionFragmentRef={contentSubmission.definition}
					/>
				);
			case "podcastWithTimestamp":
				return (
					<PodcastWithTimestamp
						podcastWithTimestampFragmentRef={
							contentSubmission.definition.currentElementState?.element!
						}
					/>
				);
			case "video":
				return (
					<VideoElement
						contentSubmissionDefinitionFragmentRef={contentSubmission.definition}
					/>
				);
			case "markMistakes":
				return <MarkMistakesElement contentSubmissionFragmentRef={contentSubmission} />;
			case "file":
				return (
					<ImageElement
						contentSubmissionDefinitionFragmentRef={contentSubmission.definition}
					/>
				);
			case "clozeText":
				return <ClozeTextElement contentSubmissionFragmentRef={contentSubmission} />;
			case "order":
				return <OrderElement contentSubmissionFragmentRef={contentSubmission} />;

			default:
				return <React.Fragment />;
		}
	})();

	if (!contentSubmission) return <React.Fragment />;
	return (
		<ContentSubmissionModalContextProvider contentSubmissionFragmentRef={contentSubmission}>
			<ScreenSidemenuLayout
				canGoBack
				noContentPadding
				headerLeftIconName="close"
				sideMenu={
					<FlexColumn>
						{!isVocabContent && (
							<>
								<TkaP2Span>{path}</TkaP2Span>
								<VerticalSpace spacing={spacing4} />
							</>
						)}
						<H1Span>{title}</H1Span>
						<VerticalSpace spacing={spacing12} />
						<TkaP2Span>{description}</TkaP2Span>
						<VerticalSpace spacing={spacing32} />
						{learnOpportunity && (
							<ContentElementsCollection
								learnOpportunityV2FragmentRef={learnOpportunity}
							/>
						)}
					</FlexColumn>
				}
				bottomContent={
					<ContentSubmissionNavigation contentSubmissionFragmentRef={contentSubmission} />
				}
			>
				<React.Fragment key={elementId}>{element}</React.Fragment>
			</ScreenSidemenuLayout>
		</ContentSubmissionModalContextProvider>
	);
};
