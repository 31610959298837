import { useMemo } from "react";
import { useFragment } from "react-relay";
import { getAppointmentStatus } from "@utils/appointment-utils";
import { AVAILABILITY_FRAGMENT } from "./appointment-status-label.graphql";

import { AppointmentStatusLabelProps } from "./appointment-status-label.types";
import { getStatusLabel, getLabelSeverity } from "./appointment-status-label.utils";
import { Label } from "../label";

export const AppointmentStatusLabel = ({
	availabilityFragmentRef,
}: AppointmentStatusLabelProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const status = getAppointmentStatus(
		availability.data.startDateTime,
		availability.data.endDateTime,
	);

	const statusLabel = useMemo(() => getStatusLabel(status), [status]);
	const labelSeverity = useMemo(() => getLabelSeverity(status), [status]);

	return <Label label={statusLabel} severity={labelSeverity} />;
};
