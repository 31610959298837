import { useMemo } from "react";
import { useFragment } from "react-relay";
import { AsyncContentStatus } from "@components/async-content-status";
import { AsyncContentSubmissionStatus } from "@components/async-content-status/async-content-status.const";
import { AttachmentsCollection } from "@components/attachments-collection";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade100 } from "@themes/colors";
import { TkaH2Span, TkaP2Span } from "@themes/font-tags";
import { isAfterIndexPath, isEqualIndexPath } from "@utils/index-path.util";
import { parseHtml } from "@utils/parse-html";
import { LEARN_OPPORTUNITY_FRAGMENT } from "./async-content-overview.graphql";
import {
	AttachmentsWrapper,
	ResponsiveAttachmentWrapper,
	TaskWrapper,
	Wrapper,
} from "./async-content-overview.styles";
import { AsyncContentOverviewProps } from "./async-content-overview.types";

import { UploadAsyncContentOverview } from "../upload-async-content-overview";

export const AsyncContentOverview = ({
	learnOpportunityV2FragmentRef,
}: AsyncContentOverviewProps) => {
	const { t } = useTkaTranslation("asyncContentOverview");

	const node = useFragment(LEARN_OPPORTUNITY_FRAGMENT, learnOpportunityV2FragmentRef);

	const title = t("async_content_overview.taskTitle");
	const status: AsyncContentSubmissionStatus = useMemo(() => {
		if (!node?.typeDefinition.task) return AsyncContentSubmissionStatus.NotSubmitted;
		return node?.typeDefinition.task?.status === "Fresh"
			? AsyncContentSubmissionStatus.Submitted
			: AsyncContentSubmissionStatus.Reviewed;
	}, [node?.typeDefinition.task?.status]);

	const taskDescription = node.typeDefinition.taskDescription;
	const kind = node.typeDefinition.task?.elementKind;

	const headContentId = node.root?.structureDefinition.viewerTreeState?.headContentId;
	const firstLockedContentId =
		node.root?.structureDefinition.extension?.unlockInfo?.firstContentId;
	const noDemo = node.root?.structureDefinition.extension?.unlockInfo?.kind !== "Demo";

	const isStarted = node.root?.structureDefinition.viewerTreeState?.kind === "IsStarted";
	const isFinished = node.root?.structureDefinition.viewerTreeState?.kind === "IsFinished";

	const headIndexPath: readonly number[] | undefined = useMemo(() => {
		let indexPath: readonly number[] | undefined = undefined;
		node.root?.typeDefinition.children?.forEach((branch) => {
			const foundContent = branch.typeDefinition?.children?.find(
				(content) => content.id === headContentId,
			);
			if (foundContent) {
				indexPath = foundContent.indexPath;
			}
		});
		return indexPath;
	}, [headContentId, node.root?.typeDefinition.children]);

	const isUnlocked = useMemo(
		() =>
			(isStarted &&
				(isAfterIndexPath(node.indexPath, headIndexPath) ||
					(isEqualIndexPath(node.indexPath, headIndexPath) &&
						(headContentId !== firstLockedContentId || noDemo)))) ||
			isFinished,
		[
			isStarted,
			isFinished,
			node.indexPath,
			headIndexPath,
			headContentId,
			firstLockedContentId,
			noDemo,
		],
	);

	const renderTaskOverview = () => {
		if (!node.typeDefinition.task) return null;
		switch (kind) {
			case "Upload":
				return (
					<UploadAsyncContentOverview
						asyncElementTaskFragmentRef={node.typeDefinition.task}
					/>
				);
			default:
				return null;
		}
	};

	const hasAttachments = node.attachmentsV2.length > 0;

	return (
		<>
			<Wrapper>
				{hasAttachments && isUnlocked && (
					<AttachmentsWrapper>
						<ResponsiveAttachmentWrapper>
							<AttachmentsCollection attachmentsCollectionFragmentRef={node} />
						</ResponsiveAttachmentWrapper>
					</AttachmentsWrapper>
				)}
				<TaskWrapper>
					<TkaH2Span tkaColor={shade100}>{title}</TkaH2Span>
					<TkaP2Span tkaColor={shade100}>{parseHtml(taskDescription || "")}</TkaP2Span>
				</TaskWrapper>
				<TaskWrapper>
					<AsyncContentStatus status={status} />
				</TaskWrapper>
			</Wrapper>
			{renderTaskOverview()}
		</>
	);
};
