import { CalendarChangeEvent } from "primereact/calendar";
import { useId } from "react";
import { shade100 } from "@themes/colors";
import {
	GlobalCalendarStyle,
	GrowDiv,
	SmallTextBellow,
	StyledCalendar,
	StyledLabel,
} from "./calendar.styles";
import { CalendarProps } from "./calendar.types";
import { getLabelColor } from "./calendar.util";
import { Icon } from "../icon";

export const Calendar = ({
	label,
	name,
	onChange,
	placeholder,
	smallTextBellow,
	status = "default",
	value,
	inline,
	showTime,
}: CalendarProps) => {
	const error = status === "error";
	const disabled = status === "disabled";

	const labelColor = getLabelColor(error, disabled);

	const fieldId = useId();

	const handleOnChange = (params: CalendarChangeEvent) => {
		if (!(params.value instanceof Date)) return;
		onChange?.(params.value);
	};

	return (
		<div>
			{label && (
				<StyledLabel tkaColor={labelColor} htmlFor={fieldId}>
					{label}
				</StyledLabel>
			)}
			<GrowDiv>
				<StyledCalendar
					inline={inline}
					disabled={disabled}
					error={error}
					id={fieldId}
					name={name}
					onChange={handleOnChange}
					placeholder={placeholder}
					value={value}
					dateFormat="dd.mm.yy"
					hourFormat="24"
					showTime={showTime}
					prevIcon={<Icon icon="arrowLeft" tkaColor={shade100} sizeInRem={1} />}
					nextIcon={<Icon icon="arrowRight" tkaColor={shade100} sizeInRem={1} />}
				/>
			</GrowDiv>
			{smallTextBellow && (
				<SmallTextBellow tkaColor={labelColor}>{smallTextBellow}</SmallTextBellow>
			)}
			<GlobalCalendarStyle />
		</div>
	);
};
