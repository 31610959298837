import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { DialogTemplate } from "@components/dialog-template";
import { RadioButtonPanel } from "@components/radio-button-panel";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { useWindowSize } from "@hooks/use-window-size";
import { Path } from "@router/paths";
import {
	ModalWrapper,
	OptionWrapper,
	SelectionWrapper,
	TimeWrapper,
} from "@screens/orientation/parts/start-potential-analysis-modal/start-potential-analysis-modal.styles";
import { useStartPotentialAnalysis } from "@screens/orientation/parts/use-start-potential-analysis/use-start-potential-analysis.hook";
import { shade60 } from "@themes/colors";
import { TkaB1Span, H1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import {
	PotentialAnalysisVersion,
	StartPotentialAnalysisModalProps,
} from "./start-potential-analysis-modal.types";

export const StartPotentialAnalysisModalButton = ({
	currentAnalysisVersion,
}: StartPotentialAnalysisModalProps) => {
	const [isVisible, setVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedOption, setSelectedOption] = useState<PotentialAnalysisVersion>();

	const { isMediumUp } = useWindowSize();
	const navigate = useNavigate();
	const { goToAnalysis } = useStartPotentialAnalysis();

	const hasMiniAnalysis = currentAnalysisVersion === PotentialAnalysisVersion.Short;
	const hasMaxAnalysis = currentAnalysisVersion === PotentialAnalysisVersion.Long;

	const handleGoToAnalysisOnClick = (shortTest?: boolean) => {
		setIsLoading(true);
		goToAnalysis(shortTest ?? selectedOption === 0, () => {
			setVisible(false);
			setIsLoading(false);
		});
	};

	const handleOnClick = () => {
		if (currentAnalysisVersion === undefined) {
			setVisible(true);
		} else if (hasMiniAnalysis) {
			handleGoToAnalysisOnClick(false);
		} else {
			navigate(Path.progression.path);
		}
	};

	const handleOnDismiss = () => {
		setVisible(false);
	};

	const createSelectionOnChangeHandler =
		(option: PotentialAnalysisVersion) => (checked: boolean) => {
			checked && setSelectedOption(option);
		};

	const actionButtonPostfix = hasMiniAnalysis ? " MAX" : "";
	const actionButtonLabel = hasMaxAnalysis
		? "Zu den Angeboten"
		: `Zur Potenzialanalyse${actionButtonPostfix}`;

	return (
		<>
			<Button
				fillParent={!isMediumUp}
				iconName={!hasMaxAnalysis ? "shareExternal" : undefined}
				label={actionButtonLabel}
				onClick={handleOnClick}
			/>

			<ResponsiveBottomSheetModal
				dismissable={true}
				onDismiss={handleOnDismiss}
				isVisible={isVisible}
			>
				<ModalWrapper>
					<DialogTemplate
						secondaryButtonLabel="Schließen"
						secondaryButtonStretch={false}
						onSecondaryButtonClick={handleOnDismiss}
						primaryButtonLabel="Weiter"
						primaryButtonIconName="arrowRight"
						primaryButtonDisabled={isLoading}
						primaryButtonStretch={false}
						onPrimaryButtonClick={handleGoToAnalysisOnClick}
						maxWidthRem={40}
					>
						<H1Span>Wie viel Zeit möchtest du dir nehmen?</H1Span>
						<TkaP3Span>
							Je mehr Zeit du dir investierst, desto aufschlussreicher sind die
							Ergebnisse.
						</TkaP3Span>
						<SelectionWrapper>
							<RadioButtonPanel
								header={
									<OptionWrapper>
										<TkaP2Span>Nur kurz</TkaP2Span>
										<TkaP2Span tkaColor={shade60}>
											Mit der Potenzialanalyse mini bekommst du einen ersten
											Eindruck von deinen Potenzialen.
										</TkaP2Span>
										<TimeWrapper>
											<TkaB1Span extraBold>5 min</TkaB1Span>
										</TimeWrapper>
									</OptionWrapper>
								}
								checked={selectedOption === PotentialAnalysisVersion.Short}
								onChange={createSelectionOnChangeHandler(
									PotentialAnalysisVersion.Short,
								)}
							/>
							<RadioButtonPanel
								header={
									<OptionWrapper>
										<TkaP2Span>Ein wenig mehr</TkaP2Span>
										<TkaP2Span tkaColor={shade60}>
											Erwecke dein volles Potenzial und sieh, wo die Reise
											hingehen kann.
										</TkaP2Span>
										<TimeWrapper>
											<TkaB1Span extraBold>15 min</TkaB1Span>
										</TimeWrapper>
									</OptionWrapper>
								}
								checked={selectedOption === PotentialAnalysisVersion.Long}
								onChange={createSelectionOnChangeHandler(
									PotentialAnalysisVersion.Long,
								)}
							/>
						</SelectionWrapper>
					</DialogTemplate>
				</ModalWrapper>
			</ResponsiveBottomSheetModal>
		</>
	);
};
