import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
`;

export const BranchWrapper = tw.div`

`;

export const ActiveContentWrapper = styled.div<{ active?: boolean }>`
	${({ active }) =>
		active &&
		tw`
			xl:bg-shade-0
			xl:-mr-24
			xl:pr-24
			xl:rounded-tl-10
			xl:rounded-bl-10
		`}
`;

export const NoAttachmentsWrapper = tw.div`
	flex
	justify-center
	items-center
	text-center
	p-16
`;
