import { BranchTypeOptions } from "./branch-dropdown.consts";
import { BranchType, BranchTypeOptionsRecord } from "./branch-dropdown.types";

//TODO: add-translations
export const getBranchOptions = () => {
	const translations: BranchTypeOptionsRecord = {
		[BranchTypeOptions.adviceAndConsulting]: "Beratung / Consulting",
		[BranchTypeOptions.analysisAndStatistic]: "Analyse und Statistik",
		[BranchTypeOptions.financeAccountingAndControlling]:
			"Finanzen, Rechnungswesen und Controlling",
		[BranchTypeOptions.graphicsAndDesign]: "Grafik, Design",
		[BranchTypeOptions.marketingAndAdvertising]: "Marketing und Werbung",
		[BranchTypeOptions.healthMedicineAndSocialAffairs]: "Gesundheit, Medizin, Soziales",
		[BranchTypeOptions.personnelAndHr]: "Personalwesen und HR",
		[BranchTypeOptions.processPlanningAndQualityAssurance]:
			"Prozessplanung und Qualitätssicherung",
		[BranchTypeOptions.itAndSoftwareDevelopment]: "IT und Softwareentwicklung",
		[BranchTypeOptions.legalServices]: "Recht",
		[BranchTypeOptions.productManagement]: "Produktmanagement",
		[BranchTypeOptions.otherFieldsOfActivity]: "Sonstige Tätigkeitsfelder",
	};
	return Object.keys(translations).map((key) => ({
		value: key,
		label: translations[key as unknown as BranchTypeOptions],
	}));
};

export const getAvailableBranch = (branch?: string): BranchType | undefined => {
	if (branch && Object.values(BranchTypeOptions).includes(branch as BranchTypeOptions))
		return branch as BranchType;
	return undefined;
};
