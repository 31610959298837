import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query membersList_Query(
		$first: Int
		$after: String
		$filterByGroupIds: [ID!]!
		$filterByNameOrEmail: String
		$filterByAccountMemberKind: AccountMemberKind
	) {
		...membersList_QueryFragment
			@arguments(
				first: $first
				after: $after
				filterByGroupIds: $filterByGroupIds
				filterByNameOrEmail: $filterByNameOrEmail
				filterByAccountMemberKind: $filterByAccountMemberKind
			)
	}
`;

export const MEMBERS_LIST_QUERY_FRAGMENT = graphql`
	fragment membersList_QueryFragment on Query
	@refetchable(queryName: "membersList_QueryFragmentRefetch")
	@argumentDefinitions(
		first: { type: Int, defaultValue: 100 }
		after: { type: String, defaultValue: null }
		filterByGroupIds: { type: "[ID!]", defaultValue: [] }
		filterByNameOrEmail: { type: "String", defaultValue: null }
		filterByAccountMemberKind: { type: "AccountMemberKind" }
	) {
		AccountMemberManagement {
			AccountMembers(
				filterByGroupIds: $filterByGroupIds
				filterByNameOrEmail: $filterByNameOrEmail
				filterByAccountMemberKind: $filterByAccountMemberKind
			) {
				__id
				invitationCount
				userCount
				accountMembers(first: $first, after: $after)
					@connection(key: "membersList_accountMembers") {
					__id
					edges {
						node {
							...membersTab_AccountMemberFragment
						}
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
`;
