import tw from "twin.macro";

export const Wrapper = tw.div`
flex
flex-col
max-w-[33rem]
gap-16`;

export const ButtonWrapper = tw.div`
flex
justify-end
`;

export const StyledImg = tw.img`
max-w-[100%]
md:max-w-[33rem]`;
