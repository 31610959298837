/**
 * @generated SignedSource<<0e1c8cf7cfbd7159672b428f1bfab457>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type feedbackQuote_CustomerFeedbackQuery$variables = {
  skip: boolean;
};
export type feedbackQuote_CustomerFeedbackQuery$data = {
  readonly CustomerJourney: {
    readonly CustomerFeedback?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly feedback: string;
          readonly id: string;
          readonly name: string;
          readonly position: string;
        };
      } | null> | null;
    };
  };
};
export type feedbackQuote_CustomerFeedbackQuery = {
  response: feedbackQuote_CustomerFeedbackQuery$data;
  variables: feedbackQuote_CustomerFeedbackQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerJourneyQueries",
    "kind": "LinkedField",
    "name": "CustomerJourney",
    "plural": false,
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerFeedbackConnection",
            "kind": "LinkedField",
            "name": "CustomerFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerFeedbackEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerFeedback",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "feedback",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "feedbackQuote_CustomerFeedbackQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "feedbackQuote_CustomerFeedbackQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eda2f9071045256ef53199384bb0e3b3",
    "id": null,
    "metadata": {},
    "name": "feedbackQuote_CustomerFeedbackQuery",
    "operationKind": "query",
    "text": "query feedbackQuote_CustomerFeedbackQuery(\n  $skip: Boolean!\n) {\n  CustomerJourney {\n    CustomerFeedback @skip(if: $skip) {\n      edges {\n        node {\n          feedback\n          id\n          name\n          position\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c0ce3ebe65c0d233dd13bdde7832a281";

export default node;
