/**
 * @generated SignedSource<<2709d112274aebc58aade7072d225e1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MatrixElementCellKind = "Answer" | "Display";
import { FragmentRefs } from "relay-runtime";
export type MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment$data = {
  readonly currentElementState: {
    readonly element: {
      readonly cells?: ReadonlyArray<{
        readonly answers?: ReadonlyArray<{
          readonly content: string;
        }>;
        readonly kind?: MatrixElementCellKind;
        readonly xIdx: number;
        readonly yIdx: number;
      }>;
      readonly id: string;
    };
  } | null;
  readonly " $fragmentType": "MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment";
};
export type MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment$key = {
  readonly " $data"?: MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "currentElementState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "element",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "cells",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "xIdx",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "yIdx",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MatrixLearnElementAnswer",
                          "kind": "LinkedField",
                          "name": "answers",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "content",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        }
                      ],
                      "type": "AnswerMatrixLearnElementCell",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "MatrixLearnElement",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActiveELearningContentSubmissionDefinition",
  "abstractKey": null
};

(node as any).hash = "ba165fc80dfae09eb3ed81696e923102";

export default node;
