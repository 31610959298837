import parse, { DOMNode, Element, HTMLReactParserOptions } from "html-react-parser";
import styled from "styled-components";
import tw from "twin.macro";
import { ImageComponentWithLightbox } from "@components/image-with-lightbox/image-with-lightbox.component";
import { brandStrong100 } from "@themes/colors";
import {
	TkaH1Typography,
	TkaH2Typography,
	TkaH3Typography,
	TkaH4Typography,
	TkaP1Typography,
	TkaP2AnchorTypography,
	TkaP2Typography,
	TkaP3Typography,
} from "@themes/font-tags";

const Wrapper = styled.div<{ fontSize?: "small" | "large" }>`
	${(props) => (props.fontSize === "large" ? TkaP1Typography : TkaP2Typography)}
	h1 {
		${tw`pb-8`}
		${TkaH1Typography}
	}
	h2 {
		${tw`pb-8`}
		${TkaH2Typography}
	}
	h3 {
		${tw`pb-8`}
		${TkaH3Typography}
	}
	h4 {
		${tw`pb-12`}
		${TkaH4Typography}
	}
	ol {
		${tw`list-decimal`}
	}
	ul {
		${tw`list-disc`}
	}
	ul,
	ol {
		${tw`pl-16`}
	}
	figcaption {
		${tw`pt-8 pb-32`}
		${TkaP3Typography}
	}
	a {
		${TkaP2AnchorTypography};
		color: ${brandStrong100};
	}
`;
export const parseHtml = (
	html: string,
	options?: HTMLReactParserOptions,
	fontSize?: "small" | "large",
) => {
	const customConfig = {
		replace: (domNode: DOMNode) => {
			if (domNode instanceof Element && domNode.type === "tag" && domNode.name === "img") {
				return ImageComponentWithLightbox({
					src: domNode.attribs.src,
					alt: domNode.attribs.alt,
					width: domNode.attribs.width,
					height: domNode.attribs.height,
				});
			}
		},
		fontSize,
	};
	return <Wrapper fontSize={fontSize}>{parse(html, { ...options, ...customConfig })}</Wrapper>;
};
