import { useEffect, useMemo, useState } from "react";
import { usePaginationFragment } from "react-relay";
import { InputSuggestionUserItemTemplate } from "@components/input-suggestion-user-item-template";
import { InputText } from "@components/input-text";

import { inputUser_QueryFragment$key } from "@relay/inputUser_QueryFragment.graphql";
import { inputUser_QueryFragmentRefetch } from "@relay/inputUser_QueryFragmentRefetch.graphql";
import { QUERY_FRAGMENT } from "./input-user.graphql";

import { InputUserProps } from "./input-user.types";

export const InputUser = <T,>({
	onUserSelected,
	queryFragmentRef,
	...inputProps
}: InputUserProps<T>) => {
	const { data: usersInAccountFragment, refetch } = usePaginationFragment<
		inputUser_QueryFragmentRefetch,
		inputUser_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const [searchUser, setSearchUser] = useState("");

	useEffect(() => {
		refetch({ filterByNameOrEmail: searchUser, first: 10 });
	}, [searchUser]);

	const handleOnChange = (text?: string) => {
		setSearchUser(text ?? "");
	};

	const handleUserOnClick = (user: { user?: { user: { id: string } } }) => {
		onUserSelected?.(user.user?.user.id!);
		setSearchUser("");
	};

	const accountUsers = useMemo(
		() =>
			usersInAccountFragment?.AccountMemberManagement.AccountMembers.accountMembers.edges
				?.map((node) => node?.node!)
				.filter(Boolean) ?? [],
		[usersInAccountFragment?.AccountMemberManagement.AccountMembers.accountMembers.edges],
	);

	return (
		<InputText
			{...inputProps}
			value={searchUser}
			onChange={handleOnChange}
			suggestions={accountUsers}
			keyExtractor={(user) => user.id}
			renderSuggestionItem={(user) => (
				<InputSuggestionUserItemTemplate userFragmentRef={user.user?.user} />
			)}
			onSuggestedItemClick={handleUserOnClick}
		/>
	);
};
