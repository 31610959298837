/**
 * @generated SignedSource<<79a0c9c6f6e28868147f6760c2adcb4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dropdownCoach_CoachProfileFragment$data = {
  readonly coach: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"dropdownUser_UserFragment">;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "dropdownCoach_CoachProfileFragment";
};
export type dropdownCoach_CoachProfileFragment$key = {
  readonly " $data"?: dropdownCoach_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dropdownCoach_CoachProfileFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "dropdownCoach_CoachProfileFragment"
};

(node as any).hash = "96cfb141c664bfe8ebda707989995df5";

export default node;
