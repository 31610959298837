import tw from "twin.macro";

export const TextCenter = tw.div`
	flex
	flex-col
	items-center
	text-center
	gap-32
`;

export const Fill = tw.div`
	self-stretch
`;
