import { InputSwitchSkeleton } from "@components/input-switch/input-switch.skeleton";
import { LabelSkeleton } from "@components/label/label.skeleton";
import { Skeleton } from "@components/skeleton";
import { SCHEDULE_HOURS } from "./day-schedule.consts";

import { AvailabilityRow, HeaderWrapper, SlotsWrapper, SwitchWrapper } from "./day-schedule.styles";

// TODO: add-translations
export const DayScheduleSkeleton = () => {
	const renderLabel = () => {
		return <LabelSkeleton size="big" />;
	};

	return (
		<AvailabilityRow>
			<SwitchWrapper>
				<InputSwitchSkeleton />
			</SwitchWrapper>
			<HeaderWrapper>
				<Skeleton width="3rem" />
				<Skeleton width="5rem" className="mt-4" />
			</HeaderWrapper>
			<SlotsWrapper>{SCHEDULE_HOURS.map(renderLabel)}</SlotsWrapper>
		</AvailabilityRow>
	);
};
