import tw from "twin.macro";

export const Header = tw.header`
  flex
  justify-end
  flex-wrap
  md:flex-nowrap
  w-full
  mb-[1rem]
`;

export const OfferCardsWrapper = tw.div`
flex
gap-[0.5rem]
overflow-x-scroll
no-scrollbar
`;

export const OfferItem = tw.div`
   w-[16.25rem]
   flex-none
`;

export const Spacing80 = tw.div`
h-80`;

export const ContentWrapper = tw.div`
md:px-[2rem]
lg:px-[5rem]`;
