import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment cancelAppointmentModal_AvailabilityFragment on Availability {
		id
		booking {
			... on BookedForGroup {
				participants {
					user {
						id
						...userAvatarsCollection_UserFragment
					}
				}
				msTeamsLink
			}
			... on BookedOneToOne {
				participant {
					user {
						id
						...userAvatarsCollection_UserFragment
					}
				}
				msTeamsLink
			}
		}
		data {
			startDateTime
			endDateTime
		}
	}
`;

export const CANCEL_BOOKING_AS_PARTICIPANT_MUTATION = graphql`
	mutation cancelAppointmentModal_CancelBookingAsParticipantMutation(
		$input: CancelBookingAsParticipantInput!
	) {
		Coaching {
			cancelBookingAsParticipant(input: $input) {
				clientMutationId
			}
		}
	}
`;
