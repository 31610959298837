/**
 * @generated SignedSource<<200cf502bf26348e443eaf5b5540d5f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userAvatar_UserFragment$data = {
  readonly extension: {
    readonly avatar?: {
      readonly thumbnail: string | null;
    } | null;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly sumGamificationPoints?: number;
  } | null;
  readonly " $fragmentType": "userAvatar_UserFragment";
};
export type userAvatar_UserFragment$key = {
  readonly " $data"?: userAvatar_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userAvatar_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "userExtensionKind",
          "value": "Academies"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnail",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sumGamificationPoints",
              "storageKey": null
            }
          ],
          "type": "AcademiesUserExtension",
          "abstractKey": null
        }
      ],
      "storageKey": "extension(userExtensionKind:\"Academies\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "de50c8b4e31c3b9a0dba159822b67ec2";

export default node;
