import { graphql } from "babel-plugin-relay/macro";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button } from "@components/button";
import { GoToPreviousElementButton_ContentSubmissionFragment$key } from "@relay/GoToPreviousElementButton_ContentSubmissionFragment.graphql";
import { GoToPreviousElementButton_GoToPreviousElementMutation } from "@relay/GoToPreviousElementButton_GoToPreviousElementMutation.graphql";
import { GoToPreviousElementButtonProps } from "./go-to-previous-element.interface";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment GoToPreviousElementButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
						elementType
					}
				}
				canGoToPrevious
			}
		}
	}
`;

const GO_TO_PREVIOUS_ELEMENT_MUTATION = graphql`
	mutation GoToPreviousElementButton_GoToPreviousElementMutation(
		$input: GoToPreviousElementInput!
	) {
		ELearning {
			goToPreviousElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const GoToPreviousElementButton = ({
	contentSubmissionFragmentRef,
}: GoToPreviousElementButtonProps) => {
	const [dispatchGoToPreviousElement] =
		useMutation<GoToPreviousElementButton_GoToPreviousElementMutation>(
			GO_TO_PREVIOUS_ELEMENT_MUTATION,
		);

	const contentSubmission = useFragment<GoToPreviousElementButton_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const contentSubmissionId = contentSubmission.id;
	const canGoToPrevious = contentSubmission.definition.canGoToPrevious || false;

	const onClick = useCallback(() => {
		if (!canGoToPrevious) return;
		dispatchGoToPreviousElement({
			variables: {
				input: {
					contentSubmissionId,
				},
			},
		});
	}, [canGoToPrevious]);

	return (
		<Button
			colorVersion="tertiary"
			iconName="arrowLeft"
			disabled={!canGoToPrevious}
			onClick={onClick}
		/>
	);
};
