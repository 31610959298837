import React from "react";
import { CircularProgressbarProps } from "./circular-progressbar.types";
import "react-circular-progressbar/dist/styles.css";
import {
	buildStyles,
	CircularProgressbar as ThirdPartyCircularProgressbar,
} from "react-circular-progressbar";
import {
	DEFAULT_PATH_COLOR,
	DEFAULT_STROKE_WIDTH,
	DEFAULT_TRAIL_COLOR,
} from "@components/circular-progressbar/circular-progressbar.consts";

export const CircularProgressbar = ({
	strokeWidth = DEFAULT_STROKE_WIDTH,
	trailColor = DEFAULT_TRAIL_COLOR,
	pathColor = DEFAULT_PATH_COLOR,
	...props
}: CircularProgressbarProps) => {
	const customStyles = buildStyles({
		trailColor: trailColor.getColor(),
		pathColor: pathColor.getColor(),
	});
	return (
		<ThirdPartyCircularProgressbar
			strokeWidth={strokeWidth}
			styles={{
				...customStyles,
				root: {
					...customStyles.root,
					height: "2rem",
					width: "2rem",
				},
				path: {
					...customStyles.path,
				},
				trail: {
					...customStyles.trail,
				},
			}}
			{...props}
		/>
	);
};
