import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { FeedbackQuoteComponent } from "@components/feedback-quote";
import { TextAndMediaSection } from "@components/text-and-media-section";
import { withSuspense } from "@components/with-suspense";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { trends_Query } from "@relay/trends_Query.graphql";
import { Path } from "@router/paths";
import { SearchBarMobileWrapper } from "@screens/account/parts/members-tab/members-tab.styles";
import { SearchBar } from "@screens/account/parts/search-bar";
import { SearchBarMobile } from "@screens/account/parts/search-bar/search-bar-mobile";
import { Spacing24 } from "@screens/profile/profile.styles";
import { coachingLink } from "@screens/trends/trends.consts";
import { Spacing80 } from "@screens/trends-detail/trends-detail.styles";
import { H1Span } from "@themes/font-tags";
import { PotentialAnalysisBanner } from "./parts/potential-analysis-banner";
import { TJMSection } from "./parts/tjm-section/tjm-section.component";
import { TrendMarketingGraph } from "./parts/trend-marketing-graph";
import { TrendOfferSection } from "./parts/trend-offer-section/trend-offer-section.component";
import { TrendOverview } from "./parts/trend-overview";
import { coachingDataSection, potentialAnalysisSection } from "./trend-overview-data";
import { QUERY } from "./trends.graphql";
import { ContentWrapper, Header } from "./trends.styles";

const TrendsScreenComponent = () => {
	const query = useLazyLoadQuery<trends_Query>(QUERY, {});

	const [searchIsVisibleOnMobile, setSearchIsVisibleOnMobile] = useState(false);
	const [searchValue, setSearchValue] = useState();
	const { isMediumUp } = useWindowSize();
	const navigate = useNavigate();
	const handleToggleOnClick = () => {
		if (isMediumUp) return;
		setSearchIsVisibleOnMobile(!searchIsVisibleOnMobile);
	};

	const handleSearchOnChange = (e: any) => {
		setSearchValue(e);
	};

	const handleOnEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			navigate(Path.progression.path, { state: { search: searchValue } });
		}
	};

	const handlePotentialAnalysisOnClick = () => {
		// TODO: replace with correct path once Potentialanalyis is available again
		navigate(Path.orientation.path);
	};

	const handleCoachingOnClick = () => {
		window.open(coachingLink, "_blank", "noreferrer");
	};

	return (
		<ScreenWithNavbarLayout>
			<Header>
				<H1Span>Zukunftstrends</H1Span>
				<SearchBar
					onClick={handleToggleOnClick}
					onChange={handleSearchOnChange}
					onEnterKeyDown={handleOnEnterKeyDown}
				/>
				{!isMediumUp && (
					<SearchBarMobileWrapper>
						{searchIsVisibleOnMobile && (
							<SearchBarMobile
								onChange={handleSearchOnChange}
								onEnterKeyDown={handleOnEnterKeyDown}
							/>
						)}
					</SearchBarMobileWrapper>
				)}
			</Header>
			<TrendOverview queryFragmentRef={query} />
			<Spacing80 />
			<ContentWrapper>
				<TextAndMediaSection
					{...potentialAnalysisSection}
					primaryButtonOnClick={handlePotentialAnalysisOnClick}
				/>
				<Spacing24 />
				<TextAndMediaSection
					{...coachingDataSection}
					primaryButtonOnClick={handleCoachingOnClick}
				/>
				<FeedbackQuoteComponent quotesAreLeftAligned={false} />
				<Spacing80 />
				<TrendOfferSection />
				<Spacing80 />
				<TrendMarketingGraph />
				<Spacing80 />
				<TJMSection />
				<Spacing80 />
			</ContentWrapper>
			<PotentialAnalysisBanner />
		</ScreenWithNavbarLayout>
	);
};

export const TrendsScreen = withSuspense(TrendsScreenComponent, null);
