/**
 * @generated SignedSource<<69825ba6bace2f9e46d6e7c31cebe5c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUserInAccountGroupsInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type deleteGroupsModal_DeleteUserInAccountGroupMutation$variables = {
  connectionIds: ReadonlyArray<string>;
  input: DeleteUserInAccountGroupsInput;
};
export type deleteGroupsModal_DeleteUserInAccountGroupMutation$data = {
  readonly AccountMemberManagement: {
    readonly deleteUserInAccountGroups: {
      readonly removedIds: ReadonlyArray<string>;
    } | null;
  };
};
export type deleteGroupsModal_DeleteUserInAccountGroupMutation = {
  response: deleteGroupsModal_DeleteUserInAccountGroupMutation$data;
  variables: deleteGroupsModal_DeleteUserInAccountGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectionIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteGroupsModal_DeleteUserInAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DeleteUserInAccountGroupsPayload",
            "kind": "LinkedField",
            "name": "deleteUserInAccountGroups",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteGroupsModal_DeleteUserInAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DeleteUserInAccountGroupsPayload",
            "kind": "LinkedField",
            "name": "deleteUserInAccountGroups",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "removedIds",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connectionIds"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0403f50f082898ec137b3f3647488760",
    "id": null,
    "metadata": {},
    "name": "deleteGroupsModal_DeleteUserInAccountGroupMutation",
    "operationKind": "mutation",
    "text": "mutation deleteGroupsModal_DeleteUserInAccountGroupMutation(\n  $input: DeleteUserInAccountGroupsInput!\n) {\n  AccountMemberManagement {\n    deleteUserInAccountGroups(input: $input) {\n      removedIds\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b843d39acc8a8f2b18b804a5010d1b6e";

export default node;
