import { Content, ChildrenWidth } from "./auth-template-step.styles";
import { AuthTemplateStepProps } from "./auth-template-step.types";

export const AuthTemplateStep = ({ children }: AuthTemplateStepProps) => {
	return (
		<Content>
			<ChildrenWidth>{children}</ChildrenWidth>
		</Content>
	);
};
