/**
 * @generated SignedSource<<e296020d934124656050e285c355d938>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteOrAddUsersToUserInAccountGroupsInput = {
  clientMutationId?: string | null;
  emails: ReadonlyArray<string>;
  groupIds: ReadonlyArray<string>;
};
export type inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation$variables = {
  connections: ReadonlyArray<string>;
  input: InviteOrAddUsersToUserInAccountGroupsInput;
};
export type inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation$data = {
  readonly AccountMemberManagement: {
    readonly inviteOrAddUsersToUserInAccountGroups: {
      readonly newAccountMembers: ReadonlyArray<{
        readonly node: {
          readonly invitation?: {
            readonly email: string;
            readonly id: string;
          };
        };
      }>;
      readonly updatedAccountMembers: ReadonlyArray<{
        readonly id: string;
      }>;
    } | null;
  };
};
export type inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation = {
  response: inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation$data;
  variables: inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Invitation",
      "kind": "LinkedField",
      "name": "invitation",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvitationWrapper",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "InviteOrAddUsersToUserInAccountGroupsPayload",
            "kind": "LinkedField",
            "name": "inviteOrAddUsersToUserInAccountGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "updatedAccountMembers",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountMemberEdge",
                "kind": "LinkedField",
                "name": "newAccountMembers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "InviteOrAddUsersToUserInAccountGroupsPayload",
            "kind": "LinkedField",
            "name": "inviteOrAddUsersToUserInAccountGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "updatedAccountMembers",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountMemberEdge",
                "kind": "LinkedField",
                "name": "newAccountMembers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "newAccountMembers",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36a7238fe91cc59832db959192f1e911",
    "id": null,
    "metadata": {},
    "name": "inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation inviteMemberModal_inviteOrAddUsersToUserInAccountGroupsMutation(\n  $input: InviteOrAddUsersToUserInAccountGroupsInput!\n) {\n  AccountMemberManagement {\n    inviteOrAddUsersToUserInAccountGroups(input: $input) {\n      updatedAccountMembers {\n        __typename\n        id\n      }\n      newAccountMembers {\n        node {\n          __typename\n          ... on InvitationWrapper {\n            invitation {\n              id\n              email\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24e61e2e406fd024f1a66cba010001b3";

export default node;
