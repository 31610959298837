import { useSelector } from "react-redux";
import { useLazyLoadQuery } from "react-relay";
import { withSuspense } from "@components/with-suspense/with-suspense.component";
import { wordInventoryDisplay_Query } from "@relay/wordInventoryDisplay_Query.graphql";
import { TestConfiguration } from "@screens/vocabulary/parts/test-configuration/test-configuration.component";
import { VocabFilterButton } from "@screens/vocabulary/parts/vocab-filter-button/vocab-filter-button.component";
import { WORD_INVENTORY_QUERY } from "@screens/vocabulary/parts/word-inventory-display/word-inventory-display.graphql";
import {
	EmptyWrapper,
	FilterButtonWrapper,
	Spacing24,
	WordInventoryContainer,
	WordInventoryHeaderWrapper,
} from "@screens/vocabulary/parts/word-inventory-display/word-inventory-display.styles";
import { WordInventoryDisplayProps } from "@screens/vocabulary/parts/word-inventory-display/word-inventory-display.types";
import { WordInventoryEmpty } from "@screens/vocabulary/parts/word-inventory-empty/word-inventory-empty.component";
import { WordPackageList } from "@screens/vocabulary/parts/word-package-list/word-package-list.component";
import { selectIsLearnMode } from "@slices/vocabulary.slice";
import { H1Span } from "@themes/font-tags";
import { WordInventoryDisplaySkeleton } from "./word-inventory-display.skeleton";

//TODO: add-translations
export const WordInventoryDisplayComponent = ({ selectionModel }: WordInventoryDisplayProps) => {
	const query = useLazyLoadQuery<wordInventoryDisplay_Query>(
		WORD_INVENTORY_QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	const isLearnMode = useSelector(selectIsLearnMode);
	const wordInventory = query.Vocab.MyWordInventory;

	return (
		<WordInventoryContainer>
			<Spacing24>
				<WordInventoryHeaderWrapper>
					<H1Span>Vokabeln</H1Span>
					<FilterButtonWrapper>
						<VocabFilterButton />
					</FilterButtonWrapper>
				</WordInventoryHeaderWrapper>
			</Spacing24>

			{isLearnMode && (
				<Spacing24>
					<TestConfiguration />
				</Spacing24>
			)}

			{(wordInventory?.packages?.length ?? 0) > 0 ? (
				<WordPackageList
					selectionModel={selectionModel}
					wordInventoryFragmentRef={wordInventory!}
				/>
			) : (
				<EmptyWrapper>
					<WordInventoryEmpty />
				</EmptyWrapper>
			)}
		</WordInventoryContainer>
	);
};

export const WordInventoryDisplay = withSuspense(
	WordInventoryDisplayComponent,
	WordInventoryDisplaySkeleton,
);
