import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const TabWrapper = tw.div`
	flex
	flex-col
	gap-24
	pt-24
`;

export const AvailabilityRow = tw.div`
	grid
	grid-rows-[min-content_1fr]
	grid-cols-[min-content_1fr]
	gap-16
`;

export const SlotsWrapper = tw.div`
	col-start-2
	col-end-3
	flex
	flex-wrap
	gap-8
`;

export const AddBlockoutDateButtonWrapper = tw.div`
	flex
	flex-row
	justify-end
`;

export const ExceptionsTab = tw.div`
flex
rounded-[0.25rem]
bg-shade-5
h-[2.25rem]
p-[0.25rem]
gap-[0.5rem]
overflow-x-auto
`;

export const ExceptionsLabelWrapper = tw.div`
	flex-shrink-0
`;

export const ExceptionsTabAndMessageWrapper = tw.div`
 flex 
 flex-col
 gap-8
`;
