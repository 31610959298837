import { useFragment } from "react-relay";
import { MicroLearningReward } from "./parts/micro-learning-reward";
import { VIEWER_QUERY_TYPE_FRAGMENT } from "./user-notifications.graphql";

import { UserNotificationsProps } from "./user-notifications.types";

// TODO: add-translations
export const UserNotifications = ({ viewerQueryTypeFragmentRef }: UserNotificationsProps) => {
	const viewer = useFragment(VIEWER_QUERY_TYPE_FRAGMENT, viewerQueryTypeFragmentRef ?? null);

	return <MicroLearningReward viewerQueryTypeFragmentRef={viewer} />;
};
