import styled from "styled-components";
import tw from "twin.macro";
import { TkaH2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
    flex
	flex-col
    gap-12
`;

export const List = tw.div`grid grid-cols-2 gap-24`;

export const ListItem = tw.div`
    col-span-full
    lg:col-span-1
    xl:col-span-full
    xxl:col-span-1
`;

export const Title = styled(TkaH2Span)``;
