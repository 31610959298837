import tw from "twin.macro";
import { css } from "@styled-system/css";

export const CardWrapperClass = css({
	h: "[100%]",
});

export const WrapperClass = css({
	position: "relative",
	display: "flex",
	flexDir: "column",
	w: "[100%]",
});

export const HeaderClass = css({
	display: "flex",
	flexDir: "column",
	flexGrow: "1",
	position: "relative",
	padding: "16",
	justifyContent: "space-between",
	minH: "[12.5rem]",
});

export const JustifyBetweenClass = css({
	position: "relative",
	display: "flex",
	justifyContent: "space-between",
});

export const FooterClass = css({
	display: "flex",
	flexDir: "column",
	justifyContent: "space-between",
	flexGrow: 1,
	gap: "16",
	p: "16",
});

export const DescriptionWrapperClass = css({
	height: "[7.5rem]",
	"& span": {
		lineClamp: 6,
	},
});

export const TopRightWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "4",
});

export const TagsWrapperClass = css({
	display: "flex",
	gap: "4",
	position: "relative",
});

export const BackgroundClass = css({
	position: "absolute",
	top: "0",
	left: "0",
	right: "0",
	height: "3/4",
	bgImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.80) 3.88%, rgba(0, 0, 0, 0.00) 97.64%)",
});

export const LicensesTextWrapperClass = css({
	display: "flex",
	gap: "8",
	alignItems: "start",
});

export const CertificateWrapperClass = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "8",
	flexGrow: "0 1 auto",
});

export const DiplomaWrapperClass = css({
	display: "flex",
	gap: "8",
	justifySelf: "baseline",
});

export const CertificateAndPriceWrapperClass = css({
	display: "flex",
	flexDir: "column",
	justifyContent: "space-between",
});

export const PriceWrapperClass = css({
	display: "flex",
	flexDir: "column",
	mt: "auto",
});

export const DetailPriceWrapperClass = css({
	ml: "auto",
	textAlign: "right",
});

export const TimeWrapperClass = css({
	mr: "auto",
});

export const CertificateAndTimeWrapperClass = css({
	display: "flex",
	flexDir: "column",
});

export const NetPriceWrapper = tw.div`
ml-auto`;

export const titleClass = css({
	lineClamp: 3,
});

export const InfoTextClass = css({
	color: "warning.100",
});

export const ErrorTextClass = css({
	color: "error.100",
});
