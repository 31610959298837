import { success0, success100 } from "@themes/colors";
import { CanOrderIHKModalProps } from "./can-order-ihk-modal.types";
import { DialogTemplate } from "../dialog-template";
import { DialogButtonsDirection } from "../dialog-template/dialog-template.types";
import { Divider } from "../divider";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";

// TODO: add-translations
export const CanOrderIHKModal = ({
	isVisible = false,
	onAccept,
	onDismiss,
}: CanOrderIHKModalProps) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible}>
			<DialogTemplate
				buttonsDirection={DialogButtonsDirection.COLUMN}
				secondaryButtonLabel="Später"
				onSecondaryButtonClick={onDismiss}
				primaryButtonIconName="arrowRight"
				primaryButtonLabel="Adresse hinzufügen"
				onPrimaryButtonClick={onAccept}
			>
				<IconHeadlineButtonTemplate
					iconName="certifiedCertificate"
					iconColor={success100}
					iconBackgroundColor={success0}
					title="Zertifikat per Post erhalten?"
					subtitle="Herzlichen Glückwunsch zum Abschluss deiner IHK-Weiterbildung! Um dein IHK-Zertifikat per Post zu erhalten, gib bitte noch schnell deine Adresse ein."
				/>
				<Divider />
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
