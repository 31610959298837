import { Card } from "@components/card";
import { CheckboxStatus } from "@components/checkbox";
import { Icon } from "@components/icon";
import { LabelSkeleton } from "@components/label/label.skeleton";
import { ProgressBar } from "@components/progress-bar";
import { Skeleton } from "@components/skeleton";
import { TriStateCheckbox } from "@components/tristatecheckbox";

import {
	CheckboxWrapper,
	IconWrapper,
	Spacing4,
	TitleWrapper,
	WordPackageContentWrapper,
	WordPackageIconWrapper,
	WordPackageWrapper,
} from "@screens/vocabulary/parts/word-package-card/word-package-card.styles";
import { spacing12 } from "@themes/spacing";
import { SelectionModel } from "@utils/selection";

//@todo: contentful
export const WordPackageCardSkeleton = ({
	selectionModel,
}: {
	selectionModel: SelectionModel<string> | undefined;
}) => {
	return (
		<Card bodyPaddingInRem={spacing12.remValue()}>
			<WordPackageWrapper>
				{selectionModel && (
					<CheckboxWrapper>
						<TriStateCheckbox status={CheckboxStatus.Default} />
					</CheckboxWrapper>
				)}

				<WordPackageIconWrapper>
					<Icon icon={"group"} sizeInRem={2} />
				</WordPackageIconWrapper>

				<WordPackageContentWrapper>
					<Spacing4>
						<TitleWrapper>
							<LabelSkeleton />
							<IconWrapper>
								<Icon icon="arrowDown" sizeInRem={1} />
							</IconWrapper>
						</TitleWrapper>
					</Spacing4>
					<Skeleton width="4rem" />
					<Spacing4>
						<Skeleton width="7rem" className="mt-8" />
					</Spacing4>
					<ProgressBar labelRight={"0%"} value={0} colorVersion={"profile"} />
				</WordPackageContentWrapper>
			</WordPackageWrapper>
		</Card>
	);
};
