import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query appointments_Query(
		$filterByMinDate: LocalDate
		$filterByMaxDate: LocalDate
		$after: String
		$before: String
		$first: Int
		$last: Int
		$coachFilterByMinDate: LocalDate
		$coachFilterByMaxDate: LocalDate
		$coachAfter: String
		$coachBefore: String
		$coachFirst: Int
		$coachLast: Int
		$coachAvailabilitiesOnlyNeedConfirmation: Boolean
		$coachAvailabilitiesAfter: String
		$coachAvailabilitiesBefore: String
		$coachAvailabilitiesFirst: Int
		$coachAvailabilitiesLast: Int
	) {
		...appointments_MyCoachingAppointmentsQueryFragment
			@arguments(
				filterByMinDate: $filterByMinDate
				filterByMaxDate: $filterByMaxDate
				first: $first
				last: $last
				before: $before
				after: $after
			)
		...appointments_CoachCoachingAppointmentsQueryFragment
			@arguments(
				filterByMinDate: $coachFilterByMinDate
				filterByMaxDate: $coachFilterByMaxDate
				first: $coachFirst
				last: $coachLast
				before: $coachBefore
				after: $coachAfter
			)
		...appointments_CoachAvailabilitiesFragment
			@arguments(
				onlyNeedConfirmation: $coachAvailabilitiesOnlyNeedConfirmation
				first: $coachAvailabilitiesFirst
				last: $coachAvailabilitiesLast
				after: $coachAvailabilitiesAfter
				before: $coachAvailabilitiesBefore
			)
	}
`;

export const MY_COACHING_APPOINTMENTS_QUERY_FRAGMENT = graphql`
	fragment appointments_MyCoachingAppointmentsQueryFragment on Query
	@refetchable(queryName: "appointments_MyCoachingAppointmentsQueryFragmentRefetch")
	@argumentDefinitions(
		filterByMinDate: { type: "LocalDate" }
		filterByMaxDate: { type: "LocalDate" }
		first: { type: "Int" }
		last: { type: "Int" }
		after: { type: "String", defaultValue: null }
		before: { type: "String", defaultValue: null }
	) {
		Viewer {
			Coaching {
				MyCoachingAppointments(
					filterByMinDate: $filterByMinDate
					filterByMaxDate: $filterByMaxDate
					after: $after
					before: $before
					first: $first
					last: $last
				) @connection(key: "appointments_MyCoachingAppointments") {
					edges {
						cursor
						node {
							...appointments_AvailabilityFragment
						}
					}
				}
			}
		}
	}
`;

export const COACH_COACHING_APPOINTMENTS_QUERY_FRAGMENT = graphql`
	fragment appointments_CoachCoachingAppointmentsQueryFragment on Query
	@refetchable(queryName: "appointments_CoachCoachingAppointmentsQueryFragmentRefetch")
	@argumentDefinitions(
		filterByMinDate: { type: "LocalDate" }
		filterByMaxDate: { type: "LocalDate" }
		first: { type: "Int" }
		last: { type: "Int" }
		after: { type: "String", defaultValue: null }
		before: { type: "String", defaultValue: null }
	) {
		Viewer {
			Coaching {
				CoachCoachingAppointments(
					filterByMinDate: $filterByMinDate
					filterByMaxDate: $filterByMaxDate
					after: $after
					before: $before
					first: $first
					last: $last
				) @connection(key: "appointments_CoachCoachingAppointments") {
					edges {
						cursor
						node {
							...appointments_AvailabilityFragment
						}
					}
				}
			}
		}
	}
`;

export const COACH_AVAILABILITIES_QUERY_FRAGMENT = graphql`
	fragment appointments_CoachAvailabilitiesFragment on Query
	@refetchable(queryName: "appointments_CoachAvailabilitiesQueryFragmentRefetch")
	@argumentDefinitions(
		onlyNeedConfirmation: { type: "Boolean" }
		first: { type: "Int" }
		last: { type: "Int" }
		after: { type: "String", defaultValue: null }
		before: { type: "String", defaultValue: null }
	) {
		Coaching {
			CoachAvailabilities(
				onlyNeedConfirmation: $onlyNeedConfirmation
				after: $after
				before: $before
				first: $first
				last: $last
			) @connection(key: "appointments_CoachAvailabilities") {
				edges {
					cursor
					node {
						...appointments_AvailabilityFragment
					}
				}
			}
		}
	}
`;

export const AVAILABILITY_FRAGMENT = graphql`
	fragment appointments_AvailabilityFragment on Availability @inline {
		id
		data {
			startDateTime
			endDateTime
		}
		...appointmentCard_AvailabilityFragment
		...appointmentModalsFlow_AvailabilityFragment
		...unconfirmedAppointmentCard_AvailabilityFragment
	}
`;
