import { useFragment } from "react-relay";
import { MultiDialogFlowContainer } from "@containers/multi-dialog-flow-container";
import { AVAILABILITY_FRAGMENT } from "./appointment-modals-flow.graphql";

import { AppointmentModalsFlowProps } from "./appointment-modals-flow.types";
import { AppointmentOverviewModal } from "../appointment-overview-modal";
import { CancelAppointmentCoachModal } from "../cancel-appointment-coach-modal";
import { CancelAppointmentMessageCoachModal } from "../cancel-appointment-message-coach-modal";
import { CancelAppointmentModal } from "../cancel-appointment-modal";
import { CancelAppointmentSuccessModal } from "../cancel-appointment-success-modal";
import { RatingSuccessModal } from "../rating-success-modal";

// TODO: add-translations
export const AppointmentModalsFlow = ({
	isVisible,
	startAtStep,
	onDismiss,
	availabilityFragmentRef,
}: AppointmentModalsFlowProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const createHandler = (handler: (step?: number) => void, step?: number) => {
		return () => {
			handler(step);
		};
	};
	return (
		<MultiDialogFlowContainer
			isVisible={isVisible}
			startAtStep={startAtStep}
			onDismiss={onDismiss}
		>
			{({ onNext, onClose }) => (
				<>
					<AppointmentOverviewModal
						isVisible
						onCancelAppointmentAsParticipant={createHandler(onNext)}
						onCancelAppointmentAsCoach={createHandler(onNext, 2)}
						onAppointmentRated={createHandler(onNext, 3)}
						onDismiss={createHandler(onClose)}
						availabilityFragmentRef={availability}
					/>
					<MultiDialogFlowContainer isVisible onDismiss={createHandler(onClose)}>
						{({ onNext }) => (
							<>
								<CancelAppointmentModal
									isVisible
									onAppointmentCanceled={createHandler(onNext)}
									onCancel={createHandler(onClose)}
									availabilityFragmentRef={availability}
								/>
								<CancelAppointmentSuccessModal
									isVisible
									onAccept={createHandler(onClose)}
									availabilityFragmentRef={availability}
								/>
							</>
						)}
					</MultiDialogFlowContainer>
					<MultiDialogFlowContainer isVisible onDismiss={createHandler(onClose)}>
						{({ onNext }) => (
							<>
								<CancelAppointmentCoachModal
									isVisible
									onNext={createHandler(onNext)}
									onCancel={createHandler(onClose)}
									availabilityFragmentRef={availability}
								/>
								<CancelAppointmentMessageCoachModal
									isVisible
									onCancel={createHandler(onClose)}
									onAppointmentCancelled={createHandler(onNext)}
									availabilityFragmentRef={availability}
								/>
								<CancelAppointmentSuccessModal
									isVisible
									canceledByCoach
									onAccept={createHandler(onClose)}
									availabilityFragmentRef={availability}
								/>
							</>
						)}
					</MultiDialogFlowContainer>
					<RatingSuccessModal isVisible onAccept={createHandler(onClose)} />
				</>
			)}
		</MultiDialogFlowContainer>
	);
};
