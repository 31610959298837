import { useFragment } from "react-relay";
import { Avatar } from "@components/avatar";
import { CollapseText } from "@components/collapse-text";
import { instructorAbout_InstructorFragment$key } from "@relay/instructorAbout_InstructorFragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaH4Span, TkaP3Span, TkaP3Typography } from "@themes/font-tags";
import { stripHtml } from "@utils/strip-html";
import { DescriptionTruncateCount } from "./instructor-about.const";
import { INSTRUCTOR_FRAGMENT } from "./instructor-about.graphql";
import { AvatarNameWrapper, NameWrapper, Wrapper } from "./instructor-about.styles";
import { InstructorAboutProps } from "./instructor-about.types";

export const InstructorAbout = ({ instructorFragmentRef }: InstructorAboutProps) => {
	const instructor = useFragment<instructorAbout_InstructorFragment$key>(
		INSTRUCTOR_FRAGMENT,
		instructorFragmentRef,
	);

	const description = stripHtml(instructor.description ?? "");
	const shortDescription = stripHtml(instructor.shortDescription ?? "");
	return (
		<Wrapper>
			<AvatarNameWrapper>
				<Avatar imgURL={instructor.image?.url} name={instructor.name} />
				<NameWrapper>
					<TkaH4Span tkaColor={shade100}>{instructor.name}</TkaH4Span>
					{shortDescription && (
						<TkaP3Span tkaColor={shade100}>{shortDescription}</TkaP3Span>
					)}
				</NameWrapper>
			</AvatarNameWrapper>
			{description && (
				<CollapseText
					truncateCount={DescriptionTruncateCount}
					text={description}
					typography={TkaP3Typography}
				/>
			)}
		</Wrapper>
	);
};
