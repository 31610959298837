import styled from "styled-components";
import { warning20 } from "@themes/colors";
import { spacing16 } from "@themes/spacing";

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${() => warning20};
	border-radius: ${() => spacing16.rem()};
	width: 2rem;
`;
