import { useContext, useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { LicensesGroupCard } from "@components/licenses-group-card";
import { Path } from "@router/paths";
import { LicensesScreenContext } from "@screens/licenses/licenses.context";
import { QUERY_FRAGMENT } from "./manage-licenses-tab.graphql";
import { ListWrapper } from "./manage-licenses-tab.styles";
import { ManageLicensesTabProps } from "./manage-licenses-tab.types";
import { LicenseContextMenu } from "./parts/license-context-menu/license-context-menu.component";

export const ManageLicensesTab = ({ queryFragmentRef }: ManageLicensesTabProps) => {
	const navigate = useNavigate();
	const [data, refetch] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const { filters } = useContext(LicensesScreenContext);

	useEffect(() => {
		refetch(
			{
				kinds: filters.kinds,
				licensesAvailable: filters.licensesAvailable,
				licensesConsumed: filters.licensesConsumed,
				text: filters.text,
			},
			{ fetchPolicy: "store-and-network" },
		);
	}, [filters]);

	const handleOnClick = () => {
		navigate(Path.progression.path);
	};

	const handleLicenseCardOnClick = (id?: string) => {
		id && navigate(Path.profileMenu.licenses.detail.withId(id).path);
	};
	return (
		<ListWrapper>
			<EmptyPlaceholder
				title="Keine Lizenzen gefunden"
				subtitle="Schau dich gerne in unserem Angebotskatalog um, um die für dich passenden Angebote zu finden."
				iconName="loginKey"
				buttonLabel="Zu den Angeboten"
				onClick={handleOnClick}
			>
				{data?.LicenseGrouping.LicenseGroupings.edges?.map((edge) => (
					<LicensesGroupCard
						key={edge?.node.id}
						licensesGroupCardFragmentRef={edge?.node}
						onClick={handleLicenseCardOnClick}
						isAdminCard={true}
					>
						{(ref) => <LicenseContextMenu ref={ref} queryFragmentRef={edge?.node} />}
					</LicensesGroupCard>
				))}
			</EmptyPlaceholder>
		</ListWrapper>
	);
};
