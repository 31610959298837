import tw from "twin.macro";

export const ContentWrapper = tw.div`
	col-span-12
	flex
	flex-col
	gap-8
`;

export const Divider = tw.div`	
	h-[1px]
	self-stretch
	bg-shade-10
`;
