import { TabPanel } from "primereact/tabview";
import React from "react";
import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { TabBar } from "@components/tab-bar";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { Spacing24 } from "@screens/profile/profile.styles";
import { H1Span } from "@themes/font-tags";
import { ProfileTabSkeleton } from "./parts/profile-tab/profile-tab.skeleton";

export const ProfileScreenSkeleton = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScreenWithProfileMenuLayout
			canGoBack={!isMediumUp}
			bottomContent={<BottomNavigationTemplateSkeleton />}
		>
			<H1Span>Mein Profil</H1Span>
			<Spacing24 />

			<TabBar disableMaxParentHeight renderActiveOnly={false}>
				<TabPanel header="Profil">
					<ProfileTabSkeleton />
				</TabPanel>
				<TabPanel header="Personalbogen für Coaches"></TabPanel>
			</TabBar>
		</ScreenWithProfileMenuLayout>
	);
};
