import { P2Span } from "@thekeytechnology/epic-ui";
import { brandMain100 } from "@themes/colors";
import { wrapperClass } from "./free-demo-card.styles";
import { ReactComponent as TagFreeCircleIcon } from "../../assets/icons/tag-free-circle.svg";

export const FreeDemoCard = () => {
	return (
		<div className={wrapperClass}>
			<TagFreeCircleIcon />
			<P2Span tkaColor={brandMain100}>
				In die ersten Lektionen kannst du reinschnuppern, denn sie sind kostenlos.
			</P2Span>
		</div>
	);
};
