import { BadgeProps } from "primereact/badge";
import React from "react";
import { StyledBadge } from "@components/badge-v2/badge-v2.styles";
import { BadgeV2Props, BadgeV2Severity } from "./badge-v2.types";

export const BadgeV2 = ({ severity, ...props }: BadgeV2Props) => {
	const calculateSeverity = (severity: BadgeV2Severity): BadgeProps["severity"] => {
		if (severity === "brandStrong") return "success";
		return "info";
	};
	return <StyledBadge severity={calculateSeverity(severity)} {...props} />;
};
