import { AuthTemplate } from "@components/auth-template";
import { SignUpSteps } from "@components/sign-up-steps";

export const SignUpScreen = () => {
	return (
		<AuthTemplate>
			<SignUpSteps />
		</AuthTemplate>
	);
};
