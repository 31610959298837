import tw from "twin.macro";

export const MessageWrapper = tw.div`
mt-[8px]
bg-shade-5`;

export const UploadWrapper = tw.div`
flex
flex-col
gap-[8px]`;

export const StyledList = tw.ul`
list-disc
ml-[20px]`;

export const StyledListItem = tw.li`
leading-normal `;

export const Wrapper = tw.div`
flex
flex-col
gap-[1rem]
`;
