/**
 * @generated SignedSource<<c2bbfd37957463601a17a65f7a33d0c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StartStandaloneVocabTrainingInput = {
  clientMutationId?: string | null;
  numWords: number;
  words: ReadonlyArray<WordInPackageInput>;
};
export type WordInPackageInput = {
  packageId: string;
  wordId: string;
};
export type startVocabTrainingButton_StartMutation$variables = {
  input: StartStandaloneVocabTrainingInput;
};
export type startVocabTrainingButton_StartMutation$data = {
  readonly Vocab: {
    readonly startStandaloneVocabTraining: {
      readonly contentSubmission: {
        readonly id: string;
      };
    } | null;
  };
};
export type startVocabTrainingButton_StartMutation = {
  response: startVocabTrainingButton_StartMutation$data;
  variables: startVocabTrainingButton_StartMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VocabMutations",
    "kind": "LinkedField",
    "name": "Vocab",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "StartStandaloneVocabTrainingPayload",
        "kind": "LinkedField",
        "name": "startStandaloneVocabTraining",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentSubmission",
            "kind": "LinkedField",
            "name": "contentSubmission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "startVocabTrainingButton_StartMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "startVocabTrainingButton_StartMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "754974564c6be37a805a47480da513a4",
    "id": null,
    "metadata": {},
    "name": "startVocabTrainingButton_StartMutation",
    "operationKind": "mutation",
    "text": "mutation startVocabTrainingButton_StartMutation(\n  $input: StartStandaloneVocabTrainingInput!\n) {\n  Vocab {\n    startStandaloneVocabTraining(input: $input) {\n      contentSubmission {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "805f9f39c01ee7249e3a7a6d6167fc92";

export default node;
