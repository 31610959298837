import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Header } from "@components/header";
import { HubspotGlobalStyle, HubspotHiddenGlobalStyle } from "@components/hubspot-style";
import { Navbar } from "@components/navbar";
import { useWindowSize } from "@hooks/use-window-size";
import { selectIsAvgsClient } from "@slices/AuthSlice";
import {
	BottomContentWrapper,
	ChildrenWrapper,
	NavbarWrapper,
	screenWrapperClass,
	ScrollWrapper,
	wrapperClass,
} from "./screen-with-navbar-layout.styles";
import { ScreenWithNavbarLayoutProps } from "./screen-with-navbar-layout.types";

export const ScreenWithNavbarLayout = ({
	headerLeftIcon,
	canGoBack,
	noPadding = false,
	bottomContent,
	singlePageMode,
	isNavbarVisible = true,
	children,
}: ScreenWithNavbarLayoutProps) => {
	const bottomContentRef = useRef<HTMLDivElement>(null);
	const hasBottomContent = !!bottomContent;

	const isAvgsClient = useSelector(selectIsAvgsClient);
	const { isXLargeUp } = useWindowSize();
	const isNavbarHidden = !isNavbarVisible || (!isXLargeUp && !!bottomContent) || singlePageMode;

	const [bottomPadding, setBottomPadding] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (!hasBottomContent || !bottomContentRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setBottomPadding((padding) => {
				if (padding !== bottomContentRef.current?.clientHeight) {
					return bottomContentRef.current?.clientHeight;
				}
				return padding;
			});
		});
		resizeObserver.observe(bottomContentRef.current);
		return () => resizeObserver.disconnect();
	}, [hasBottomContent]);

	const hubspotBottomMargin = bottomContent ? (bottomPadding ?? 0) / 16 : 4.7;
	return (
		<div className={wrapperClass({ isVisibleNavbar: !isNavbarHidden })}>
			{!isNavbarHidden && (
				<NavbarWrapper>
					<Navbar />
				</NavbarWrapper>
			)}
			<div className={screenWrapperClass({ singlePageMode })}>
				<ScrollWrapper>
					<Header canGoBack={canGoBack} leftIcon={headerLeftIcon} />
					<ChildrenWrapper noPadding={noPadding}>{children}</ChildrenWrapper>
				</ScrollWrapper>
				{bottomContent && (
					<BottomContentWrapper ref={bottomContentRef}>
						{bottomContent}
					</BottomContentWrapper>
				)}
				{(!isXLargeUp || bottomContent) && !(singlePageMode && isXLargeUp) && (
					<HubspotGlobalStyle bottomMarginRem={hubspotBottomMargin} />
				)}
				{isAvgsClient && <HubspotHiddenGlobalStyle />}
			</div>
		</div>
	);
};
