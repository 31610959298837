import { createContext } from "react";
import { SelectedMember } from "./members-tab.types";

type MembersTabContext = {
	selectedMembers: SelectedMember[];
	toogleMember: (member: SelectedMember) => void;
	clearSelectedMembers: () => void;
	numberOfConfirmedMembers?: number;
	setNumberOfConfirmedMembers?: (number: number) => void;
	numberOfUnconfirmedMembers?: number;
	setNumberOfUnconfirmedMembers?: (number: number) => void;
};

export const MembersTabContext = createContext<MembersTabContext>({
	selectedMembers: [],
	toogleMember: () => {},
	clearSelectedMembers: () => {},
	numberOfConfirmedMembers: 0,
	numberOfUnconfirmedMembers: 0,
	setNumberOfConfirmedMembers: () => {},
	setNumberOfUnconfirmedMembers: () => {},
});
