import { BodyPaddingInRem } from "./card.interface";

export const bodyPaddingInRemToPaddingString = (bodyPaddingInRem: BodyPaddingInRem) => {
	if (!Array.isArray(bodyPaddingInRem)) return bodyPaddingInRem + "rem";
	if (bodyPaddingInRem.some((p) => typeof p !== "number"))
		throw new Error(
			`CardAtom took bodyPaddingInRem prop of invalid type: '${bodyPaddingInRem}'. Should be of type: number | number[]`,
		);
	return bodyPaddingInRem.map((bp) => bp.toString() + "rem").join(" ");
};
