import { PathBase, PathBaseWithId, PathWithId } from "@thekeytechnology/epic-ui";

export class SuccessPath extends PathBase {
	static readonly pathKey = "success";
}

export class ContentSubmissionBranchNodesPath extends PathBaseWithId {
	static readonly pathKey = "branchNodes";
	static readonly pathName = "modules";
	static readonly idKey = "branchNodeId";
}
export class ContentSubmissionPath extends PathWithId<typeof ContentSubmissionPath> {
	static readonly pathKey = "contentSubmission";
	static readonly pathName = "learn-view";
	static readonly idKey = "rootId";
	static readonly childPaths = [SuccessPath, ContentSubmissionBranchNodesPath];
}
