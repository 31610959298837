export type StatisticsBarProps = {
	label?: string;
	maxSteps: number;
	step: number;
	benchmark?: number;
	colorVersion?: StatisticsBarColorVersion;
};

export enum StatisticsBarColorVersion {
	Green = "green",
	Blue = "blue",
}
