import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-16
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-row
	justify-between
`;
