import React from "react";

import { ButtonSkeleton } from "@components/button/button.skeleton";
import { Skeleton } from "@components/skeleton";
import {
	DeleteButtonWrapper,
	DeleteButtonWrapperWrapper,
	FullWidthWrapper,
	Grid,
	HalfWidthWrapper,
	Hr,
	MobileHalfWidthWrapper,
	Wrapper,
} from "@screens/profile/parts/profile-tab/profile-tab.styles";
import { shade10 } from "@themes/colors";

export const ProfileTabSkeleton = () => {
	return (
		<Wrapper>
			<Skeleton width="9.135rem" height="9.135rem" borderRadius="9rem" />
			<Grid>
				<HalfWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</HalfWidthWrapper>
				<HalfWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</HalfWidthWrapper>
				<MobileHalfWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</MobileHalfWidthWrapper>
				<MobileHalfWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</MobileHalfWidthWrapper>
				<FullWidthWrapper>
					<Skeleton width="100%" height="7rem" />
				</FullWidthWrapper>
				<FullWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</FullWidthWrapper>
				<FullWidthWrapper>
					<Skeleton width="3rem" />
					<Skeleton width="100%" height="7" className="mt-8" />
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />
				<FullWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</FullWidthWrapper>
				<FullWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</FullWidthWrapper>
				<FullWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</FullWidthWrapper>
				<FullWidthWrapper>
					<Skeleton width="100%" height="2.5rem" />
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />
				<DeleteButtonWrapperWrapper>
					<DeleteButtonWrapper>
						<ButtonSkeleton />
					</DeleteButtonWrapper>
				</DeleteButtonWrapperWrapper>
			</Grid>
		</Wrapper>
	);
};
