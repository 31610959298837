import { RatingChangeEvent } from "primereact/rating";
import { Icon } from "@components/icon";
import { brandStrong100, brandStrong40, shade40, shade20, shade10 } from "@themes/colors";
import { StyledRating } from "./rating.styles";
import { RaitingProps } from "./rating.types";

export const Rating = ({ disabled, value, onChange, isMini }: RaitingProps) => {
	const handleOnChange = (event: RatingChangeEvent) => {
		onChange?.(event.value ?? 0);
	};

	const onIcon = (
		<Icon
			icon="ratingStar"
			style={{ fill: disabled ? shade10.toString() : brandStrong40.toString() }}
			tkaColor={disabled ? shade20 : brandStrong100}
			sizeInRem={isMini ? 0.75 : 1.5}
		/>
	);

	const offIcon = (
		<Icon
			icon="ratingStar"
			tkaColor={disabled ? shade20 : shade40}
			sizeInRem={isMini ? 0.75 : 1.5}
		/>
	);

	return (
		<StyledRating
			onIcon={onIcon}
			offIcon={offIcon}
			disabled={disabled}
			value={value}
			onChange={handleOnChange}
			cancel={false}
		/>
	);
};
