import graphql from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_MODULE_DATA_FRAGMENT = graphql`
	fragment moduleSection_AVGSPotentialAnalysisModuleFragment on AVGSPotentialAnalysis_Module {
		dimensions {
			dimensionId
			...dimensionSection_AVGSPotentialAnalysisDimensionFragment
		}
		moduleId
		moduleText
	}
`;
