import { FC, useContext, useState } from "react";
import { useFragment } from "react-relay";
import { Icon } from "@components/icon";
import { USER_IN_ACCOUNT_GROUP_OWNER_ID, USER_IN_ACCOUNT_GROUP_USER_ID } from "@consts/index";
import { useWindowSize } from "@hooks/use-window-size";
import { BatchEdit } from "@screens/account/parts/batch-edit";
import { InviteMemberModal } from "@screens/account/parts/members-tab/parts/invite-member-modal";
import { shade80, error100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./batch-edit-groups.graphql";
import { BatchEditGroupsProps } from "./batch-edit-groups.types";
import { GroupsTabContext } from "../../groups-tab.context";

export const BatchEditGroups: FC<BatchEditGroupsProps> = ({ queryFragmentRef }) => {
	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const { isMediumUp } = useWindowSize();
	const { deletableGroupIds, deleteGroupIds, selectedGroups, clearSelectedGroups } =
		useContext(GroupsTabContext);

	const [isVisibleInviteMembersModal, setIsVisibleInviteMembersModal] = useState(false);

	const isVisible = selectedGroups.length > 1;

	const handleAddMembersOnClick = () => {
		setIsVisibleInviteMembersModal(true);
	};

	const handleDeleteOnClick = () => {
		deleteGroupIds(selectedGroups.map((group) => group.id));
	};

	const handleOnDismiss = () => {
		setIsVisibleInviteMembersModal(false);
	};

	const deleteModalIsVisible = !!deletableGroupIds?.length;

	const batchEditVisible =
		isVisible &&
		((!isMediumUp && !deleteModalIsVisible && !isVisibleInviteMembersModal) || isMediumUp);

	const canRemoveBatchGroups = !selectedGroups.find(
		(group) =>
			group.id === USER_IN_ACCOUNT_GROUP_OWNER_ID ||
			group.id === USER_IN_ACCOUNT_GROUP_USER_ID,
	);
	return (
		<>
			{isVisible && (
				<BatchEdit
					isVisible={batchEditVisible}
					selectedCount={selectedGroups.length}
					onClear={clearSelectedGroups}
					optionNodes={(ItemWrapper) => [
						<ItemWrapper key="assign" onClick={handleAddMembersOnClick}>
							<Icon icon="add" sizeInRem={1} tkaColor={shade80} />
							<TkaP2Span tkaColor={shade80}>Benutzer hinzufügen</TkaP2Span>
						</ItemWrapper>,
						canRemoveBatchGroups ? (
							<ItemWrapper key="delete" onClick={handleDeleteOnClick}>
								<Icon
									icon="trash"
									sizeInRem={1}
									tkaColor={isMediumUp ? shade80 : error100}
								/>
								<TkaP2Span tkaColor={isMediumUp ? shade80 : error100}>
									Löschen
								</TkaP2Span>
							</ItemWrapper>
						) : null,
					]}
				/>
			)}
			<InviteMemberModal
				isVisible={isVisibleInviteMembersModal}
				tabView={false}
				selectedGroups={selectedGroups}
				onDismiss={handleOnDismiss}
				queryFragmentRef={queryFragment}
			/>
		</>
	);
};
