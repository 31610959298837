import { ReactComponent as LogoSVG } from "@assets/logo.svg";
import { Icon } from "@components/icon";
import { shade0, shade100 } from "@themes/colors";
import { HeaderWrapper, LeftItem } from "./header.styles";
import { HeaderProps } from "./header.types";

export const HeaderSkeleton = ({ canGoBack, leftIcon, leftItem }: HeaderProps) => {
	const showLeftItem = (canGoBack || leftIcon || leftItem) && canGoBack !== false;
	return (
		<HeaderWrapper>
			{showLeftItem && (
				<LeftItem>
					{leftItem ?? (
						<Icon
							icon={leftIcon ?? "arrowLeft"}
							tkaColor={shade100}
							sizeInRem={1}
							circleOutlineColor={shade0}
						/>
					)}
				</LeftItem>
			)}
			<LogoSVG />
		</HeaderWrapper>
	);
};
