import { useNavigate } from "react-router-dom";
import { Icon } from "@components/icon";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { Path } from "@router/paths";

//@TODO: contentful
export const WordInventoryEmpty = () => {
	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(Path.progression.path);
	};

	return (
		<IconHeadlineButtonTemplate
			icon={<Icon sizeInRem={2} icon="group" />}
			buttonLabel={"Zur Weiterentwicklung"}
			title={"Hier findest du später deine Vokabeln zum Trainieren"}
			onClick={handleOnClick}
			subtitle={
				"Keine Sorge, du kannst ganz einfach neue Vokabeln lernen, indem du dir Wordpakete kaufst. Diese findest du in den einzelnen Kursen, die zu deinem Sprachniveau und deinen Interessen passen. Wähle einfach die Kurse aus, die du machen möchtest. So kannst du deinen Wortschatz erweitern und deine Sprachkenntnisse verbessern."
			}
		/>
	);
};
