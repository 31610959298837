import tw from "twin.macro";

export const ModalWrapper = tw.div`
	md:w-[85vw]
	md:max-w-[22rem]
	flex
	flex-col
	gap-24
`;

export const WordModalHeaderWrapper = tw.div`
	flex 
	flex-col
	items-start
	gap-4
`;
