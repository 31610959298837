import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query bookAppointment_GetCoachingQuery($id: ID!) {
		node(id: $id) {
			... on CoachingOffer {
				description {
					name
					description
					image {
						url
					}
				}
				associatedCoaches {
					id
					coach {
						id
					}
					...dropdownCoach_CoachProfileFragment
				}
				licenseAvailability {
					... on LicenseAvailabilityFreeLicensesAvailable {
						numAvailable
					}
				}
			}
		}
		...bookAppointment_QueryFragment
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment bookAppointment_QueryFragment on Query
	@refetchable(queryName: "BookAppointment_QueryFragmentRefetch")
	@argumentDefinitions(
		skipQuery: { type: "Boolean", defaultValue: true }
		first: { type: "Int" }
		last: { type: "Int" }
		after: { type: "String", defaultValue: null }
		before: { type: "String", defaultValue: null }
		filterByCoach: { type: "ID" }
		filterByMinDate: { type: "LocalDate" }
		filterByMaxDate: { type: "LocalDate" }
	) {
		Coaching {
			FreeAvailabilities(
				filterByCoach: $filterByCoach
				filterByMinDate: $filterByMinDate
				filterByMaxDate: $filterByMaxDate
				after: $after
				before: $before
				first: $first
				last: $last
			) @skip(if: $skipQuery) @connection(key: "BookAppointment_FreeAvailabilities") {
				edges {
					node {
						id
						data {
							startDateTime
							endDateTime
						}
					}
				}
			}
		}
	}
`;

export const BOOK_COACHING_MUTATION = graphql`
	mutation bookAppointment_BookCoachingMutation($input: BookCoachingInput!) {
		Coaching {
			bookCoaching(input: $input) {
				availability {
					booking {
						... on BookedOneToOne {
							offer {
								id
								description {
									name
								}
							}
						}
					}
					...bookingSuccessModal_AvailabilityFragment
				}
			}
		}
	}
`;
