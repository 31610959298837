/**
 * @generated SignedSource<<bc585bb2373303fd4d3e5b300fc46fef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAccountGroupAssignmentRuleInput = {
  active: boolean;
  assignmentRuleId: string;
  clientMutationId?: string | null;
  name: string;
  userInAccountGroupIds: ReadonlyArray<string>;
};
export type licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation$variables = {
  input: EditAccountGroupAssignmentRuleInput;
};
export type licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation$data = {
  readonly LicenseManagement: {
    readonly editAccountGroupAssignmentRule: {
      readonly assignmentRule: {
        readonly " $fragmentSpreads": FragmentRefs<"licensesAccountGroupAssignmentCard_QueryFragment">;
      };
    } | null;
  };
};
export type licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation = {
  response: licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation$data;
  variables: licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditAccountGroupAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "editAccountGroupAssignmentRule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "licensesAccountGroupAssignmentCard_QueryFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditAccountGroupAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "editAccountGroupAssignmentRule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "active",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licenseGroupingId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accountRef",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amountEffectedUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccountGroup",
                        "kind": "LinkedField",
                        "name": "userInAccountGroups",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isBuiltIn",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "AccountGroupAssignmentRule",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7af78e3532795435363d4384237647db",
    "id": null,
    "metadata": {},
    "name": "licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation",
    "operationKind": "mutation",
    "text": "mutation licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation(\n  $input: EditAccountGroupAssignmentRuleInput!\n) {\n  LicenseManagement {\n    editAccountGroupAssignmentRule(input: $input) {\n      assignmentRule {\n        __typename\n        ...licensesAccountGroupAssignmentCard_QueryFragment\n        id\n      }\n    }\n  }\n}\n\nfragment licensesAccountGroupAssignmentCard_QueryFragment on AccountGroupAssignmentRule {\n  __typename\n  id\n  name\n  active\n  licenseGroupingId\n  accountRef\n  amountEffectedUser\n  userInAccountGroups {\n    id\n    name\n    isBuiltIn\n  }\n}\n"
  }
};
})();

(node as any).hash = "697270c7761e100aabfe16e837dc86ce";

export default node;
