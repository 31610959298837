import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query myCoachingOffers_Query {
		Viewer {
			Coaching {
				CoachingOffers {
					edges {
						node {
							id
							description {
								name
								image {
									url
								}
							}
						}
					}
				}
			}
		}
	}
`;
