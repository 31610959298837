import { ZonedDateTime } from "@js-joda/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { HTMLMouseEvent } from "@customTypes/index";
import { selectCurrentUserId } from "@slices/CurrentUserSlice";
import { shade20, shade60 } from "@themes/colors";
import { TkaP3Span } from "@themes/font-tags";
import { spacing12 } from "@themes/spacing";
import {
	AppointmentStatus,
	formatAppointmentDateTimeRange,
	getAppointmentStatus,
} from "@utils/appointment-utils";
import { AVAILABILITY_FRAGMENT } from "./appointment-card.graphql";

import {
	Wrapper,
	Title,
	ParticipantsWrapper,
	BottomWrapper,
	IconWrapper,
	CardWrapper,
	ColGroup,
	TopColGroup,
	Gap16,
} from "./appointment-card.styles";
import { AppointmentCardProps } from "./appointment-card.types";
import { AppointmentModalsFlow } from "../appointment-modals-flow";
import { AppointmentStatusLabel } from "../appointment-status-label";
import { Button } from "../button";
import { Card } from "../card";
import { Divider } from "../divider";
import { Icon } from "../icon";
import { UserAvatarsCollection } from "../user-avatars-collection";

// TODO: add-translations
export const AppointmentCard = ({ onClick, availabilityFragmentRef }: AppointmentCardProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const [showCancelStep, setShowCancelStep] = useState<number>();

	const userId = useSelector(selectCurrentUserId);

	const status = getAppointmentStatus(
		availability.data.startDateTime,
		availability.data.endDateTime,
	);

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const users = participants.map((participant) => participant.user!).filter(Boolean);

	const participantsLabel = `${users.length} Teilnehmer`;

	const isCoach = availability.coachProfile?.coach?.id === userId;
	const isAppointmentOwner = users[0]?.id === userId;

	const isInLessThan24Hours = ZonedDateTime.parse(availability.data.startDateTime).isBefore(
		ZonedDateTime.now().plusHours(24),
	);
	const canCancelAppointment =
		status === AppointmentStatus.Confirmed &&
		(isCoach || (isAppointmentOwner && !isInLessThan24Hours));

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			isCoach,
			"short",
		);
	};

	const msTeamsLink = availability.booking.msTeamsLink;

	const handleOnClick = () => {
		onClick?.();
	};

	const handleCancelAppointmentOnClick = (event: HTMLMouseEvent) => {
		event.stopPropagation();
		if (!canCancelAppointment) return;
		setShowCancelStep(isCoach ? 2 : 1);
	};

	const handleCancelAppointmentOnDismiss = () => {
		setShowCancelStep(undefined);
	};

	const handleJoinOnClick = (event: HTMLMouseEvent) => {
		event.stopPropagation();
		if (status === AppointmentStatus.Running) {
			window.open(msTeamsLink, "_blank", "noreferrer");
		} else {
			handleOnClick();
		}
	};

	const handleWrapperOnClick = (event: HTMLMouseEvent) => {
		event.stopPropagation();
	};

	const buttonTitle = status === AppointmentStatus.Running ? "Beitreten" : "Anzeigen";
	const buttonColorVersion = status === AppointmentStatus.Running ? "strong" : undefined;

	return (
		<CardWrapper>
			<Card fillParent bodyPaddingInRem={spacing12.remValue()} onClick={handleOnClick}>
				<Wrapper>
					<TopColGroup>
						<AppointmentStatusLabel availabilityFragmentRef={availability} />
						<Gap16 />
						<TkaP3Span tkaColor={shade60}>{renderTime()}</TkaP3Span>
						<Title>{availability.booking.offer?.description?.name}</Title>
					</TopColGroup>
					<ColGroup>
						<ParticipantsWrapper>
							<UserAvatarsCollection userFragmentsRef={users} />
							<TkaP3Span tkaColor={shade60}>{participantsLabel}</TkaP3Span>
						</ParticipantsWrapper>
						<Divider />
						<BottomWrapper onClick={handleWrapperOnClick}>
							<IconWrapper
								disabled={!canCancelAppointment}
								onClick={handleCancelAppointmentOnClick}
							>
								<Icon
									icon="calendarDisable"
									sizeInRem={1}
									tkaColor={!canCancelAppointment ? shade20 : undefined}
								/>
							</IconWrapper>
							<Button
								fillParent
								disabled={status === AppointmentStatus.Ended}
								colorVersion={buttonColorVersion}
								label={buttonTitle}
								iconName="arrowRight"
								onClick={handleJoinOnClick}
							/>
						</BottomWrapper>
					</ColGroup>
				</Wrapper>
			</Card>
			{showCancelStep !== undefined && (
				<AppointmentModalsFlow
					isVisible
					startAtStep={showCancelStep}
					onDismiss={handleCancelAppointmentOnDismiss}
					availabilityFragmentRef={availability}
				/>
			)}
		</CardWrapper>
	);
};
