/**
 * @generated SignedSource<<6f093b10a351292a156020e3557208ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userAvatarsCollection_UserFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
  readonly " $fragmentType": "userAvatarsCollection_UserFragment";
};
export type userAvatarsCollection_UserFragment$key = {
  readonly " $data"?: userAvatarsCollection_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatarsCollection_UserFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userAvatarsCollection_UserFragment"
};

(node as any).hash = "e853c9716808522bcb39997fadf6a4af";

export default node;
