import { UserCardSkeleton } from "@components/user-card/user-card.skeleton";
import { MemberCardsWrapper } from "./members-list.styles";

export const MembersListSkeleton = () => {
	return (
		<MemberCardsWrapper>
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
		</MemberCardsWrapper>
	);
};
