/**
 * @generated SignedSource<<8ff885a359beff489bbb42d4d1b4f016>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userInGroupContextMenu_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignGroupModal_QueryFragment">;
  readonly " $fragmentType": "userInGroupContextMenu_QueryFragment";
};
export type userInGroupContextMenu_QueryFragment$key = {
  readonly " $data"?: userInGroupContextMenu_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userInGroupContextMenu_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./userInGroupContextMenuRefetchQuery.graphql')
    }
  },
  "name": "userInGroupContextMenu_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "assignGroupModal_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "26e0a484745de41f324f34289b973213";

export default node;
