import { useMemo } from "react";
import { Image } from "@components/image";
import { shade20 } from "@themes/colors";
import { AvatarSize } from "./avatar.const";
import { Background, Text } from "./avatar.styles";
import { AvatarProps } from "./avatar.types";

export const Avatar = ({ firstName, lastName, name, imgURL, sizeInRem }: AvatarProps) => {
	const initials = useMemo(() => {
		if (!name && !firstName && !lastName) return "U";
		if (name) {
			return name
				.replace(/\s+/g, " ")
				.split(" ")
				.slice(0, 2)
				.map((s) => s?.[0]?.toUpperCase());
		}
		return `${firstName?.[0]?.toUpperCase() ?? ""}${lastName?.[0]?.toUpperCase() ?? ""}`;
	}, [firstName, lastName, name]);

	const size = sizeInRem ?? AvatarSize;
	const fontSize = size / 2.3;

	return imgURL ? (
		<Image
			imageStyle={{ objectFit: "cover" }}
			src={imgURL}
			borderRadius={`${size / 2}rem`}
			widthRem={size}
			heightRem={size}
		/>
	) : (
		<Background size={size}>
			<Text fontSize={fontSize} tkaColor={shade20}>
				{initials}
			</Text>
		</Background>
	);
};
