import { Button } from "@components/button";
import { Card } from "@components/card";

import { TkaH3Span } from "@themes/font-tags";
import { spacing8 } from "@themes/spacing";
import { formatToLocalTime } from "@utils/date-utils";
import { TimeWrapper, Wrapper } from "./time-slot-card.styles";
import { TimeSlotCardProps } from "./time-slot-card.type";

// TODO: add-translations
export const TimeSlotCard = ({
	zonedDateTimeStart,
	zonedDateTimeEnd,
	active,
	onClick,
}: TimeSlotCardProps) => {
	const displayTime = formatToLocalTime(zonedDateTimeStart);
	const handleOnClick = () => {
		onClick?.(zonedDateTimeStart, zonedDateTimeEnd);
	};

	const buttonLabel = active ? "Ausgewählt" : "Auswählen";
	const buttonColorVersion = active ? "strong" : undefined;
	const buttonIconName = active ? "correct" : undefined;

	return (
		<Card bodyPaddingInRem={spacing8.remValue()} borderRadiusRem={7.5} onClick={handleOnClick}>
			<Wrapper>
				<TimeWrapper>
					<TkaH3Span>{displayTime}</TkaH3Span>
				</TimeWrapper>
				<Button
					label={buttonLabel}
					iconName={buttonIconName}
					colorVersion={buttonColorVersion}
				/>
			</Wrapper>
		</Card>
	);
};
