import { Dropdown } from "@components/dropdown";
import { calcFormComponentStatus } from "@utils/form-utils";
import { ValidatedDropdownProps } from "./validated-dropdown.types";

export function ValidatedDropdown<State>({
	formikConfig,
	name,
	status = "default",
	options,
	...props
}: ValidatedDropdownProps<State>) {
	const errorMessage = formikConfig.errors[name as keyof State & string] as unknown as
		| string
		| undefined;
	const hasError = !!errorMessage;

	const value = formikConfig.values[name] as unknown as string | undefined;

	const updateValue = (updatedValue: string) => {
		formikConfig.setFieldTouched(name as keyof State & string, true);
		formikConfig.setFieldValue(name as keyof State & string, updatedValue);
	};

	return (
		<Dropdown
			helperText={errorMessage}
			value={value}
			onChange={updateValue}
			status={calcFormComponentStatus(hasError, status)}
			options={options}
			{...props}
		/>
	);
}
