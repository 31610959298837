import { InputSwitchChangeEvent } from "primereact/inputswitch";
import { InputSwitchProps } from "./input-switch.interface";
import { StyledInputSwitch } from "./input-switch.styles";

export const InputSwitch = ({ onChange, ...props }: InputSwitchProps) => {
	const handleOnChange = (event: InputSwitchChangeEvent) => {
		onChange?.(!!event.value);
	};
	return (
		<StyledInputSwitch
			className={props.error ? "p-invalid" : ""}
			onChange={handleOnChange}
			{...props}
		/>
	);
};
