import styled from "styled-components";
import tw from "twin.macro";
import { spacing4 } from "@themes/spacing";

export const Flex = tw.div`flex flex-col gap-20 lg:flex-row lg:justify-between lg:items-center`;

export const MessageContainer = tw.div`
	flex 
	flex-col 
	items-center 
	md:items-start 
	text-center
	md:text-left
`;

export const IconAndBadgeWrapper = styled.div`
	padding: ${spacing4.rem()};
`;

export const ContentWrapper = tw.div`
	flex 
	flex-col
	md:flex-row
	gap-20
	items-center 
`;

export const ButtonsWrapper = tw.div`
	flex 
	flex-col 
	md:flex-row 
	md:justify-end
	gap-8 
	md:gap-16
`;
