import { classNames } from "primereact/utils";
import { useEffect, useRef, useState } from "react";
import { spacing0, spacing4 } from "@themes/spacing";
import {
	GrowDiv,
	SmallTextBelow,
	StyledInputMask,
	StyledLabel,
	SuggestionsWrapper,
	Wrapper,
} from "./input-mask.styles";
import { InputMaskProps } from "./input-mask.types";
import { getIconColor, getLabelColor } from "./input-mask.util";
import { Icon } from "../icon";
import { OverlayPanel } from "../overlay-panel";

export const InputMask = <T,>({
	icon,
	label,
	name,
	onChange,
	placeholder,
	smallTextBelow,
	status = "default",
	type = "default",
	value,
	keyFilter,
	mask,
	suggestions,
	renderSuggestionItem,
	onSuggestedItemClick,
	keyExtractor,
}: InputMaskProps<T>) => {
	const suggestionsOverlayRef = useRef<OverlayPanel | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [width, setWidth] = useState<number>(100);

	const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		suggestionsOverlayRef.current?.show(event, event.target);
	};

	const handleOnBlur = () => {
		suggestionsOverlayRef.current?.hide();
	};

	const error = status === "error";
	const disabled = status === "disabled";

	const labelColor = getLabelColor(error, disabled);
	const iconColor = getIconColor(!!value, disabled);

	const randomId = Math.random().toString();

	const suggestionsList =
		renderSuggestionItem &&
		suggestions?.map((item, index) => (
			<div
				key={keyExtractor?.(item) ?? index}
				onMouseDown={(event) => {
					event.preventDefault();
					event.stopPropagation();
					onSuggestedItemClick?.(item);
					inputRef.current?.blur();
				}}
			>
				{renderSuggestionItem(item)}
			</div>
		));

	useEffect(() => {
		if (!inputRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setWidth(inputRef.current?.clientWidth ?? 100);
		});
		resizeObserver.observe(inputRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<Wrapper>
			{label && (
				<StyledLabel tkaColor={labelColor} htmlFor={randomId}>
					{label}
				</StyledLabel>
			)}
			<GrowDiv className={classNames({ "p-input-icon-left": !!icon })}>
				{icon && (
					<i>
						<Icon tkaColor={iconColor} icon={icon} sizeInRem={1.5} />
					</i>
				)}
				<StyledInputMask
					ref={(ref) => {
						inputRef.current = ref as HTMLInputElement | null;
					}}
					disabled={disabled}
					error={error}
					id={randomId}
					name={name}
					onChange={(e) => onChange?.(e.value ?? "")}
					placeholder={placeholder}
					type={type}
					value={value}
					keyfilter={keyFilter}
					mask={mask}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
				/>
			</GrowDiv>
			{suggestionsList && (
				<OverlayPanel
					ref={suggestionsOverlayRef}
					padding={spacing0}
					borderRadius={spacing4}
				>
					<SuggestionsWrapper width={width}>{suggestionsList}</SuggestionsWrapper>
				</OverlayPanel>
			)}
			{smallTextBelow && (
				<SmallTextBelow tkaColor={labelColor}>{smallTextBelow}</SmallTextBelow>
			)}
		</Wrapper>
	);
};
