import tw from "twin.macro";

export const Grid = tw.div`grid grid-cols-12 gap-24`;
export const CoachingCardWrapper = tw.div`
  col-span-12 
  lg:col-span-6 
  xl:col-span-4
  xxl:col-span-3
`;
export const Gap12 = tw.div`
  h-12
`;
