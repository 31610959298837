import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment inputUser_QueryFragment on Query
	@refetchable(queryName: "inputUser_QueryFragmentRefetch")
	@argumentDefinitions(
		filterByNameOrEmail: { type: "String" }
		filterByGroupIds: { type: "[ID!]!" }
		first: { type: "Int", defaultValue: 100 }
		after: { type: "String" }
	) {
		AccountMemberManagement {
			AccountMembers(
				filterByNameOrEmail: $filterByNameOrEmail
				filterByGroupIds: $filterByGroupIds
			) {
				accountMembers(first: $first, after: $after)
					@connection(key: "inputUser_accountMembers") {
					edges {
						node {
							id
							... on UserInAccountWrapper {
								user {
									user {
										id
										...inputSuggestionUserItemTemplate_UserFragment
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
