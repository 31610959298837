import graphql from "babel-plugin-relay/macro";

export const USER_IN_ACCOUT_GROUP_FRAGMENT = graphql`
	fragment groupDetailsContextMenu_UserInAccountGroupFragment on UserInAccountGroup {
		id
		name
		isBuiltIn
		...editGroupModal_UserInAccountGroupFragment
	}
`;
