import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query account_Query {
		...membersTab_AccountMembersQueryFragment
		...membersTab_UserInAccountGroupQueryFragment
		...groupsTab_QueryFragment
		...groupsTab_UserInAccountGroupQueryFragment
		...accountTab_QueryFragment
	}
`;
