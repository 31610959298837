import tw from "twin.macro";

export const Wrapper = tw.div`
flex
flex-col
gap-[1rem]
md:p-[2.5rem]
md:gap-[2rem]
`;
export const Header = tw.div`
flex
justify-between
`;

export const PointsWrapper = tw.div`
flex
ml-[1rem]
items-center
gap-[0.5rem]`;

export const UserWrapper = tw.div`
flex
flex-1
items-center
gap-[1rem]
bg-shade-5
p-[1.5rem]
rounded-[0.75rem]
mt-[1rem]
md:mr-[1rem]
md:h-[11.875rem]
`;

export const CardsWrapper = tw.div`
flex
flex-col
md:flex-row
md:justify-between`;

export const LearnableGridHeader = tw.div`
flex
justify-between
items-center
`;
