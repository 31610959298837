/**
 * @generated SignedSource<<ca5e9a668f4c7610f548027685595af9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSingleUserAssignmentRuleInput = {
  clientMutationId?: string | null;
  licenseGroupingId: string;
  maxLicenseAmount: number;
  userId: string;
};
export type licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation$variables = {
  input: CreateSingleUserAssignmentRuleInput;
};
export type licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation$data = {
  readonly LicenseManagement: {
    readonly createSingleUserAssignmentRule: {
      readonly assignmentRule: {
        readonly id: string;
        readonly licenseGroupingId: string;
        readonly " $fragmentSpreads": FragmentRefs<"licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment">;
      };
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation = {
  response: licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation$data;
  variables: licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseGroupingId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateSingleUserAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "createSingleUserAssignmentRule",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LicenseManagementMutations",
        "kind": "LinkedField",
        "name": "LicenseManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CreateSingleUserAssignmentRulePayload",
            "kind": "LinkedField",
            "name": "createSingleUserAssignmentRule",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignmentRule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "licensesUsedByUser",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxLicenseAmount",
                        "storageKey": null
                      }
                    ],
                    "type": "SingleUserAssignmentRule",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd5eb6994644323e36a032c26698f0ab",
    "id": null,
    "metadata": {},
    "name": "licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation",
    "operationKind": "mutation",
    "text": "mutation licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation(\n  $input: CreateSingleUserAssignmentRuleInput!\n) {\n  LicenseManagement {\n    createSingleUserAssignmentRule(input: $input) {\n      clientMutationId\n      assignmentRule {\n        __typename\n        id\n        licenseGroupingId\n        ...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment\n      }\n    }\n  }\n}\n\nfragment licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment on SingleUserAssignmentRule {\n  __typename\n  id\n  userId\n  licenseGroupingId\n  licensesUsedByUser\n  maxLicenseAmount\n}\n"
  }
};
})();

(node as any).hash = "fe940ee1456a643ef7ff58691c1fe9de";

export default node;
