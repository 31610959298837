/**
 * @generated SignedSource<<61e9358eddb5d0407536dc87a89c843b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookingKind = "BookedForGroup" | "BookedOneToOne" | "NotBooked";
import { FragmentRefs } from "relay-runtime";
export type appointmentDetails_AvailabilityFragment$data = {
  readonly booking: {
    readonly kind: BookingKind;
    readonly msTeamsLink?: string;
    readonly offer?: {
      readonly description: {
        readonly description: string | null;
      } | null;
      readonly licenseAvailability: {
        readonly numAvailable?: number;
      };
      readonly setting: {
        readonly maxParticipants?: number;
      };
    } | null;
    readonly participant?: {
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"participantPreview_UserFragment">;
      } | null;
    };
    readonly participants?: ReadonlyArray<{
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"participantPreview_UserFragment">;
      } | null;
    }>;
  };
  readonly coachProfile: {
    readonly coach: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"participantPreview_UserFragment">;
    } | null;
  } | null;
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly id: string;
  readonly " $fragmentType": "appointmentDetails_AvailabilityFragment";
};
export type appointmentDetails_AvailabilityFragment$key = {
  readonly " $data"?: appointmentDetails_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentDetails_AvailabilityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "CoachingOfferDescription",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "participantPreview_UserFragment"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "msTeamsLink",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appointmentDetails_AvailabilityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CoachingOffer",
              "kind": "LinkedField",
              "name": "offer",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "setting",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "maxParticipants",
                          "storageKey": null
                        }
                      ],
                      "type": "GroupCoachingSetting",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "licenseAvailability",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numAvailable",
                          "storageKey": null
                        }
                      ],
                      "type": "LicenseAvailabilityFreeLicensesAvailable",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participants",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "type": "BookedForGroup",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CoachingOffer",
              "kind": "LinkedField",
              "name": "offer",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participant",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "type": "BookedOneToOne",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachProfile",
      "kind": "LinkedField",
      "name": "coachProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "coach",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Availability",
  "abstractKey": null
};
})();

(node as any).hash = "17f18b397a5376737b804bb9880f2860";

export default node;
