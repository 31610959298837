/**
 * @generated SignedSource<<4f7adeaad691d6fa6504e2552da9c428>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type myCoachings_QueryFragment$data = {
  readonly Coaching: {
    readonly CoachingOffersToCoach: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"coachingOfferCard_CoachingOfferFragment">;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "myCoachings_QueryFragment";
};
export type myCoachings_QueryFragment$key = {
  readonly " $data"?: myCoachings_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"myCoachings_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "coachProfileId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "myCoachings_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachingQueries",
      "kind": "LinkedField",
      "name": "Coaching",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "before",
              "variableName": "before"
            },
            {
              "kind": "Variable",
              "name": "coachProfileId",
              "variableName": "coachProfileId"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            },
            {
              "kind": "Variable",
              "name": "last",
              "variableName": "last"
            }
          ],
          "concreteType": "CoachingOfferConnection",
          "kind": "LinkedField",
          "name": "CoachingOffersToCoach",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CoachingOfferEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CoachingOffer",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "coachingOfferCard_CoachingOfferFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c05913922a1cdd854f73600b4a865405";

export default node;
