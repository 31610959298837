/**
 * @generated SignedSource<<fa428a9a98b014b847a983dad9761a93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type microLearningReward_ViewerQueryTypeFragment$data = {
  readonly Auth: {
    readonly currentUser: {
      readonly user: {
        readonly extension: {
          readonly potentialAnalysisMaxRewardAcknowledged?: boolean | null;
        } | null;
      };
    } | null;
  };
  readonly PotentialAnalysis: {
    readonly MyPotentialAnalysisResult: {
      readonly isLongAnalysis: boolean;
    } | null;
  };
  readonly " $fragmentType": "microLearningReward_ViewerQueryTypeFragment";
};
export type microLearningReward_ViewerQueryTypeFragment$key = {
  readonly " $data"?: microLearningReward_ViewerQueryTypeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"microLearningReward_ViewerQueryTypeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "microLearningReward_ViewerQueryTypeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthViewerSchema",
      "kind": "LinkedField",
      "name": "Auth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUser",
          "kind": "LinkedField",
          "name": "currentUser",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "userExtensionKind",
                      "value": "Academies"
                    }
                  ],
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "potentialAnalysisMaxRewardAcknowledged",
                          "storageKey": null
                        }
                      ],
                      "type": "AcademiesUserExtension",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": "extension(userExtensionKind:\"Academies\")"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PotentialAnalysisViewerSchema",
      "kind": "LinkedField",
      "name": "PotentialAnalysis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisResult",
          "kind": "LinkedField",
          "name": "MyPotentialAnalysisResult",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isLongAnalysis",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerQueryType",
  "abstractKey": null
};

(node as any).hash = "e4707593be9d6dd2240f9062de6ee5bf";

export default node;
