/**
 * @generated SignedSource<<b23d202848b08d238cc8a40ecd83c9ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesNewSingleUserAssignmentForm_QueryFragment$data = {
  readonly AccountMemberManagement: {
    readonly AccountMembers: {
      readonly accountMembers: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment" | "licensesSingleUserAssignmentCard_AccountMemberFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "licensesNewSingleUserAssignmentForm_QueryFragment";
};
export type licensesNewSingleUserAssignmentForm_QueryFragment$key = {
  readonly " $data"?: licensesNewSingleUserAssignmentForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesNewSingleUserAssignmentForm_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "filterByGroupIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "licensesNewSingleUserAssignmentForm_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountMemberManagementQueries",
      "kind": "LinkedField",
      "name": "AccountMemberManagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterByGroupIds",
              "variableName": "filterByGroupIds"
            }
          ],
          "concreteType": "AccountMembersResult",
          "kind": "LinkedField",
          "name": "AccountMembers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountMemberConnection",
              "kind": "LinkedField",
              "name": "accountMembers",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountMemberEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "licensesSingleUserAssignmentCard_AccountMemberFragment"
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment",
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "kind",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "UserInAccount",
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "User",
                                      "kind": "LinkedField",
                                      "name": "user",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "UserInAccountWrapper",
                              "abstractKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "2e8a92ceedd74b38a6f21d4e96d5e212";

export default node;
