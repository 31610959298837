import { useState } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { Card } from "@components/card";
import { CheckboxStatus } from "@components/checkbox";
import { Icon } from "@components/icon";
import { Label } from "@components/label";
import { ProgressBar } from "@components/progress-bar";
import { TriStateCheckbox } from "@components/tristatecheckbox";
import { wordPackageCard_WordInventoryFragment$key } from "@relay/wordPackageCard_WordInventoryFragment.graphql";
import { WordCard } from "@screens/vocabulary/parts/word-card/word-card.component";
import {
	parseCompoundKey,
	toCompoundKey,
} from "@screens/vocabulary/parts/word-card/word-card.utils";
import { WORD_INVENTORY_FRAGMENT } from "@screens/vocabulary/parts/word-package-card/word-package-card.graphql";
import {
	CheckboxWrapper,
	IconWrapper,
	Spacing4,
	TitleWrapper,
	WordGrid,
	WordPackageContentWrapper,
	WordPackageIconWrapper,
	WordPackageWrapper,
} from "@screens/vocabulary/parts/word-package-card/word-package-card.styles";
import { WordPackageCardProps } from "@screens/vocabulary/parts/word-package-card/word-package-card.types";
import { selectFilterByProficiencyLevel } from "@slices/vocabulary.slice";
import { TkaH3Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { spacing12 } from "@themes/spacing";

//@todo: contentful
export const WordPackageCard = ({
	wordPackageId,
	wordInventoryFragmentRef,
	selectionModel,
}: WordPackageCardProps) => {
	const [isExpanded, setExpanded] = useState(false);

	const wordInventory = useFragment<wordPackageCard_WordInventoryFragment$key>(
		WORD_INVENTORY_FRAGMENT,
		wordInventoryFragmentRef,
	);

	const wordPackage = wordInventory.packages.find((p) => p.wordPackage?.id === wordPackageId)
		?.wordPackage!;

	const proficiencies = wordInventory.proficiencies ?? [];
	const notes = wordInventory.notes ?? [];

	const filterByProficiencyLevel = useSelector(selectFilterByProficiencyLevel);

	const totalProficiency = wordPackage.associatedWords
		.map((word) => proficiencies.find((p) => p.wordId === word.id)?.proficiencyLevel ?? 0)
		.reduce((x, y) => x + y, 0);

	const maximumProficiency = wordPackage.associatedWords.length * 4;
	const percentage = totalProficiency
		? Math.min(100, Math.round((totalProficiency / maximumProficiency) * 100))
		: 0;

	const handleOnClick = () => {
		setExpanded(!isExpanded);
	};

	const allSelectedWordsInPackage = wordPackage.associatedWords.filter((w) => {
		const compoundKey = toCompoundKey(wordPackage.id, w.id);
		return selectionModel?.selectedItems.includes(compoundKey);
	});

	const wordIds = wordPackage.associatedWords.map((w) => w.id);

	const filteredWords = wordPackage.associatedWords.filter((w) => {
		const wordProficiencyLevel =
			proficiencies.find((p) => p.wordId === w.id)?.proficiencyLevel ?? 0;
		return (
			filterByProficiencyLevel === undefined ||
			wordProficiencyLevel === filterByProficiencyLevel ||
			(!wordProficiencyLevel && filterByProficiencyLevel === 0)
		);
	});

	const allWordsSelected = allSelectedWordsInPackage.length === filteredWords.length;
	const someWordsSelected = allSelectedWordsInPackage.length > 0;

	const onSelectedChange = (checked: boolean | undefined) => {
		if (!selectionModel) return;

		const baseItems = selectionModel.selectedItems.filter(
			(compoundKey) => !wordIds.includes(parseCompoundKey(compoundKey).wordId),
		);
		if (checked) {
			selectionModel?.onSelectionChange([
				...baseItems,
				...filteredWords.map((w) => toCompoundKey(wordPackage.id, w.id)),
			]);
		} else {
			selectionModel?.onSelectionChange(baseItems);
		}
	};

	return (
		<Card bodyPaddingInRem={spacing12.remValue()}>
			<WordPackageWrapper isExpanded={isExpanded}>
				{selectionModel && (
					<CheckboxWrapper>
						<TriStateCheckbox
							status={CheckboxStatus.Default}
							checked={
								allWordsSelected && someWordsSelected
									? true
									: someWordsSelected
									? false
									: undefined
							}
							onChange={onSelectedChange}
						/>
					</CheckboxWrapper>
				)}

				<WordPackageIconWrapper onClick={handleOnClick}>
					<Icon icon={"group"} sizeInRem={2} />
				</WordPackageIconWrapper>

				<WordPackageContentWrapper onClick={handleOnClick}>
					<Spacing4>
						<TitleWrapper>
							<Label label={wordPackage.language} />
							<IconWrapper>
								{isExpanded ? (
									<Icon icon="arrowUp" sizeInRem={1} />
								) : (
									<Icon icon="arrowDown" sizeInRem={1} />
								)}
							</IconWrapper>
						</TitleWrapper>
					</Spacing4>
					<TkaP3Span>
						{wordPackage.associatedWords.length}{" "}
						{wordPackage.associatedWords.length === 1 ? "Vokabel" : "Vokabeln"}
					</TkaP3Span>
					<Spacing4>
						<TkaH3Span>{wordPackage.name}</TkaH3Span>
					</Spacing4>
					<ProgressBar
						labelRight={percentage.toFixed(0) + "%"}
						value={percentage}
						colorVersion={"profile"}
					/>
				</WordPackageContentWrapper>
			</WordPackageWrapper>

			{!filteredWords.length && filterByProficiencyLevel !== undefined && (
				<TkaP2Span>Keine Wörter die den Filtern entsprechen.</TkaP2Span>
			)}

			{isExpanded && (
				<WordGrid>
					{filteredWords.map((word) => {
						return (
							<WordCard
								key={word.id}
								wordPackageId={wordPackage.id}
								language={wordPackage.language}
								wordFragmentRef={word}
								wordProficiencyFragmentRef={proficiencies.find(
									(p) => p.wordId === word.id,
								)}
								wordNoteFragmentRef={notes.find((p) => p.wordId == word.id)}
								selectionModel={selectionModel}
							/>
						);
					})}
				</WordGrid>
			)}
		</Card>
	);
};
