import styled from "styled-components";
import tw from "twin.macro";
import { brandStrong100 } from "@themes/colors";

export const Wrapper = styled.div<{ sizeInRem: number }>`
	${tw`
		relative
		flex
		items-center
		justify-center
	`};
	width: ${(props) => props.sizeInRem}rem;
	height: ${(props) => props.sizeInRem}rem;
`;

export const FilledCircle = styled.div<{ sizeInRem: number }>`
	${tw`
		bg-shade-10
		rounded-full
	`};
	width: ${(props) => props.sizeInRem * 0.84}rem;
	height: ${(props) => props.sizeInRem * 0.84}rem;
`;

export const Progress = styled.div<{ decimalPercentage: number }>`
	${tw`
		absolute
		h-full
		w-full
		rounded-full
	`};
	background: conic-gradient(
		${brandStrong100} calc(${(props) => props.decimalPercentage * 100} * 1%),
		#0000 0
	);
`;
