/**
 * @generated SignedSource<<b35fda69fe6d9d906d4df9d43758d42e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type myAvailabilities_Query$variables = {};
export type myAvailabilities_Query$data = {
  readonly Viewer: {
    readonly Coaching: {
      readonly FreeCalendarWeeks: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"exceptionModal_FreeCalenderWeeksFragment">;
      }>;
      readonly MyAvailabilitySchedule: {
        readonly " $fragmentSpreads": FragmentRefs<"myAvailabilities_AvailabilityScheduleFragment">;
      } | null;
    };
  };
};
export type myAvailabilities_Query = {
  response: myAvailabilities_Query$data;
  variables: myAvailabilities_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayOfWeek",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeSlots",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarWeek",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarYear",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "myAvailabilities_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingViewerSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailabilitySchedule",
                "kind": "LinkedField",
                "name": "MyAvailabilitySchedule",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "myAvailabilities_AvailabilityScheduleFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CalendarWeekAndYear",
                "kind": "LinkedField",
                "name": "FreeCalendarWeeks",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "exceptionModal_FreeCalenderWeeksFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "myAvailabilities_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingViewerSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailabilitySchedule",
                "kind": "LinkedField",
                "name": "MyAvailabilitySchedule",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AvailabilityScheduleDayAndTimes",
                        "kind": "LinkedField",
                        "name": "daysAndTimes",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BlockoutDate",
                        "kind": "LinkedField",
                        "name": "blockoutDates",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "from",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "to",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DayAndTimesForCalendarWeek",
                        "kind": "LinkedField",
                        "name": "overrideDaysAndTimesForCalendarWeek",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CalendarWeekAndYear",
                "kind": "LinkedField",
                "name": "FreeCalendarWeeks",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4e85bf62ac38fb7a86014a6f25b9f1f",
    "id": null,
    "metadata": {},
    "name": "myAvailabilities_Query",
    "operationKind": "query",
    "text": "query myAvailabilities_Query {\n  Viewer {\n    Coaching {\n      MyAvailabilitySchedule {\n        ...myAvailabilities_AvailabilityScheduleFragment\n        id\n      }\n      FreeCalendarWeeks {\n        ...exceptionModal_FreeCalenderWeeksFragment\n      }\n    }\n  }\n}\n\nfragment blockoutDateCard_BlockoutDateFragment on BlockoutDate {\n  from\n  to\n  ...blockoutDateModal_BlockoutDateFragment\n}\n\nfragment blockoutDateModal_BlockoutDateFragment on BlockoutDate {\n  from\n  to\n}\n\nfragment exceptionModal_FreeCalenderWeeksFragment on CalendarWeekAndYear {\n  calendarWeek\n  calendarYear\n}\n\nfragment myAvailabilities_AvailabilityScheduleFragment on AvailabilitySchedule {\n  __typename\n  id\n  data {\n    __typename\n    daysAndTimes {\n      dayOfWeek\n      timeSlots\n    }\n    blockoutDates {\n      from\n      to\n      ...blockoutDateCard_BlockoutDateFragment\n    }\n    overrideDaysAndTimesForCalendarWeek {\n      ...myAvailabilities_OverrideDaysAndTimesFragment\n    }\n  }\n}\n\nfragment myAvailabilities_OverrideDaysAndTimesFragment on DayAndTimesForCalendarWeek {\n  calendarWeek\n  calendarYear\n  dayOfWeek\n  timeSlots\n}\n"
  }
};
})();

(node as any).hash = "70dab85d1e97117fb5f5c3cd77020304";

export default node;
