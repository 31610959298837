import graphql from "babel-plugin-relay/macro";

export const ACCOUNT_GROUP_ASSIGNMENT_RULE_FRAGMENT = graphql`
	fragment licensesAccountGroupAssignmentCard_QueryFragment on AccountGroupAssignmentRule {
		__typename
		id
		name
		active
		licenseGroupingId
		accountRef
		amountEffectedUser
		userInAccountGroups {
			id
			name
			isBuiltIn
		}
	}
`;

export const EDIT_ACCOUNT_GROUP_ASSIGNMENT_MUTATION = graphql`
	mutation licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation(
		$input: EditAccountGroupAssignmentRuleInput!
	) {
		LicenseManagement {
			editAccountGroupAssignmentRule(input: $input) {
				assignmentRule {
					...licensesAccountGroupAssignmentCard_QueryFragment
				}
			}
		}
	}
`;

export const DELETE_ACCOUNT_GROUP_ASSIGNMENT_MUTATION = graphql`
	mutation licensesAccountGroupAssignmentCard_deleteGroupAssignmentRuleMutation(
		$input: DeleteAssignmentRuleInput!
	) {
		LicenseManagement {
			deleteAssignmentRule(input: $input) {
				clientMutationId
			}
		}
	}
`;
