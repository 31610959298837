import { useAuthContext } from "@hooks/use-auth-context";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { useToast } from "@hooks/useToast";
import { brandStrong100 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span } from "@themes/font-tags";
import { PasswordForgotEmailSendStepProps } from "./password-forgot-email-send-step.interface";
import { TextCenter } from "./password-forgot-email-send-step.styles";

export const PasswordForgotEmailSendStep = ({ email }: PasswordForgotEmailSendStepProps) => {
	const { t } = useTkaTranslation("forgotPasswordForm");
	const { showSuccess } = useToast();
	const { forgotPassword } = useAuthContext();

	const handleResentEmailOnClick = () => {
		forgotPassword(email).then(() => {
			showSuccess({
				summary: t("forgot_password_form.emailSuccessfullySentToast"),
			});
		});
	};

	return (
		<TextCenter>
			<H1Span>{t("forgot_password_form.successTitle")}</H1Span>
			<TkaP1Span>{t("forgot_password_form.successBody1")}</TkaP1Span>
			<TkaP2Span>
				{t("forgot_password_form.successBody2Part1")}
				<TkaP2Span tkaColor={brandStrong100} onClick={handleResentEmailOnClick}>
					{t("forgot_password_form.successBody2LinkText")}
				</TkaP2Span>
				{t("forgot_password_form.successBody2Part2")}
			</TkaP2Span>
		</TextCenter>
	);
};
