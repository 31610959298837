import React, { PropsWithChildren } from "react";
import { TkaIconName } from "@components/icon";
import { ColorVersion } from "../button";

export type DialogTemplateProps = {
	title?: string;
	subtitle?: string;
	secondaryButton?: React.ReactNode;
	secondaryButtonLabel?: string;
	secondaryButtonIconName?: TkaIconName;
	secondaryButtonColorVersion?: ColorVersion;
	secondaryButtonStretch?: boolean;
	onSecondaryButtonClick?: () => void;
	primaryButton?: React.ReactNode;
	primaryButtonLabel?: string;
	primaryButtonIconName?: TkaIconName;
	primaryButtonColorVersion?: ColorVersion;
	primaryButtonStretch?: boolean;
	primaryButtonDisabled?: boolean;
	primaryButtonLoading?: boolean;
	onPrimaryButtonClick?: () => void;
	navigateTo?: string;
	maxWidthRem?: number;
	buttonsDirection?: DialogButtonsDirection;
	hasSeperator?: boolean;
} & PropsWithChildren;

export enum DialogButtonsDirection {
	ROW = "row",
	COLUMN = "column",
}
