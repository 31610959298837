import { createContext } from "react";

type AppointmentsScreenContext = {
	refreshUnconfirmed: () => void;
	refreshMyAppointments: () => void;
	refreshCoachingAppointments: () => void;
};
export const AppointmentsScreenContext = createContext<AppointmentsScreenContext>({
	refreshUnconfirmed: () => {},
	refreshMyAppointments: () => {},
	refreshCoachingAppointments: () => {},
});
