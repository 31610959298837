import { ImageCardAtom } from "@components/ImageCardAtom";

import { Content, ImageWrapper, Title, Wrapper } from "./offer-preview.styles";
import { OfferPreviewProps } from "./offer-preview.type";

export const OfferPreview = ({ imageUrl, title }: OfferPreviewProps) => {
	const imageWidth = "4.375rem";

	return (
		<Wrapper>
			{imageUrl && (
				<ImageWrapper>
					<ImageCardAtom imageUrl={imageUrl} imageWidth={imageWidth} />
				</ImageWrapper>
			)}
			<Content>
				<Title>{title}</Title>
			</Content>
		</Wrapper>
	);
};
