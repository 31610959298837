import { graphql } from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_CLICKOUT_MUTATION = graphql`
	mutation startPotentialAnalysisModal_ClickoutMutation(
		$input: CreatePotentialAnalysisClickoutInput!
	) {
		PotentialAnalysis {
			createPotentialAnalysisClickout(input: $input) {
				clickout
			}
		}
	}
`;
