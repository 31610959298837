import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query placement_Query($skip: Boolean!) {
		Viewer {
			PotentialAnalysis {
				MyPotentialAnalysisResult {
					isLongAnalysis
				}
			}
		}
		...infoModalCorporate_QueryFragment @skip(if: $skip)
	}
`;

export const CLICKOUT_MUTATION = graphql`
	mutation placement_ClickoutMutation($input: CreateClickOutForUserOrBusinessInput!) {
		Placement {
			createClickOutForUserOrBusiness(input: $input) {
				placementUrl
			}
		}
	}
`;
