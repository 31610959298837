import { useMemo } from "react";
import { useFragment } from "react-relay";
import { InstructorAbout } from "@components/instructor-about";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { instructorsComponent_InstructorsConnectionFragment$key } from "@relay/instructorsComponent_InstructorsConnectionFragment.graphql";
import { shade100 } from "@themes/colors";
import { INSTRUCTORS_CONNECTION_FRAGMENT } from "./instructors-component.graphql";
import { List, ListItem, Title, Wrapper } from "./instructors-component.styles";
import { InstructorsComponentProps } from "./instructors-component.types";

export const InstructorsComponent = ({
	instructorsConnectionFragmentRef,
}: InstructorsComponentProps) => {
	const { edges: instructors } =
		useFragment<instructorsComponent_InstructorsConnectionFragment$key>(
			INSTRUCTORS_CONNECTION_FRAGMENT,
			instructorsConnectionFragmentRef,
		);

	const { t } = useTkaTranslation("courseScreen");

	const instructorsList = useMemo(
		() =>
			instructors
				?.map((instructor) => {
					if (!instructor?.node) return;
					return (
						<ListItem key={instructor.node.id}>
							<InstructorAbout instructorFragmentRef={instructor.node} />
						</ListItem>
					);
				})
				.filter(Boolean) || [],
		[instructors],
	);

	return instructorsList.length > 0 ? (
		<Wrapper>
			<Title tkaColor={shade100}>{t("course_screen.trainers")}</Title>
			<List>{instructorsList}</List>
		</Wrapper>
	) : null;
};
