import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	justify-between
	flex-col
`;

export const HeaderWrapper = tw.div`
  flex
  flex-row
  justify-between
  w-full
`;

export const HeaderDataWrapper = tw.div`
  flex
  flex-row
  items-center
  gap-12
`;

export const HeaderNameWrapper = tw.div`
  flex
  flex-col
`;

export const ExpandButton = tw.button`
  flex
  items-center
  justify-center
  h-[54px]
  w-[54px]
  bg-transparent
  border-none
`;

export const ExpandedContentWrapperForm = tw.form`
  flex
  flex-col
  gap-24
  w-full
  mt-24
`;

export const FormWrapper = styled.div<{ background?: "white" | "grey" }>`
	${tw`
    flex
    flex-col
    gap-24
    w-full
    bg-shade-5
    py-24
    px-16
    rounded-8
  `}
	${({ background }) => background === "white" && tw`bg-shade-10`}
`;

export const FormButtonsWrapper = tw.div`
  flex
  flex-row
  justify-between
  w-full
  border-t
	border-shade-10
	md:border-none
	pt-24
	md:pt-0
`;

export const FormButtonsRight = styled.div<{ fullWidth?: boolean }>`
	${tw`
    flex
    flex-row
    gap-8
  `}
	${({ fullWidth }) => fullWidth && tw`w-full`}
`;
