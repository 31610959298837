import { Chip } from "@components/chip";
import { pointsWrapper } from "@components/gamification-points-chip/gamification-points-chip.styles";
import { GamificationPointsChipProps } from "@components/gamification-points-chip/gamification-points-chip.types";
import { Icon } from "@components/icon";
import { shade0 } from "@themes/colors";
import { H3Span } from "@themes/font-tags";

export const GamificationPointsChip = ({
	points,
	onGrayBackground = false,
}: GamificationPointsChipProps) => {
	const chipBackgroundColor = onGrayBackground ? shade0 : undefined;

	return (
		<div className={pointsWrapper}>
			<Chip backgroundColor={chipBackgroundColor}>
				<H3Span>{points ?? 0}</H3Span>
				<Icon icon="coinStar" sizeInRem={1} />
			</Chip>
		</div>
	);
};
