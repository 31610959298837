import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { QUERY } from "@components/report-modal-flow/parts/system-email/system-email.graphql";
import { SystemEmailProps } from "@components/report-modal-flow/parts/system-email/system-email.types";
import { systemEmail_Query } from "@relay/systemEmail_Query.graphql";
import { TkaP2Anchor, TkaP3Anchor } from "@themes/font-tags";

export const SystemEmail = ({ tkaColor, isBig }: SystemEmailProps) => {
	const fallback = "meldung@constellation.academy";
	const query = useLazyLoadQuery<systemEmail_Query>(QUERY, {});
	const Typography = isBig ? TkaP2Anchor : TkaP3Anchor;
	return (
		<Suspense fallback={fallback}>
			<Typography tkaColor={tkaColor} href={"mailto:" + query.Viewer.Reports.SystemEmail}>
				{query.Viewer.Reports.SystemEmail}
			</Typography>
		</Suspense>
	);
};
