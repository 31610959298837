import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
	justify-between
	items-center
	gap-16
`;

export const TimeWrapper = tw.div`
	pl-16
`;
