import { shade40 } from "@themes/colors";

import { copyTextToClipboard } from "@utils/clipboard.utils";
import { Wrapper, Name, Link } from "./link-display.styles";
import { LinkDisplayProps } from "./link-display.types";
import { Icon } from "../icon";

export const LinkDisplay = ({ link, disableCopy, name }: LinkDisplayProps) => {
	const handleCopyOnClick = () => {
		copyTextToClipboard(link);
	};
	return (
		<Wrapper>
			{name && <Name>{name}:</Name>}
			<Link tkaColor={shade40}>{link}</Link>
			{!disableCopy && <Icon icon="copy" sizeInRem={1} onClick={handleCopyOnClick} />}
		</Wrapper>
	);
};
