import { graphql } from "babel-plugin-relay/macro";
import React, { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { GapText } from "@components/gap-text";
import { MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment$key } from "@relay/MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment.graphql";
import { MatrixElementAnswersProps } from "./matrix-element.interface";
import { AnswersComponentWrapper, AnswersTitle, StyledScrollPanel } from "./matrix-element.styles";

const E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				id
				... on MatrixLearnElement {
					id
					cells {
						xIdx
						yIdx
						... on AnswerMatrixLearnElementCell {
							answers {
								content
							}
							kind
						}
					}
				}
			}
		}
	}
`;

export const MatrixElementAnswers = ({
	selectedCellCords,
	onIndexSelected,
	contentSubmissionDefinitionFragmentRef,
}: MatrixElementAnswersProps) => {
	const contentSubmissionDefinition =
		useFragment<MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment$key>(
			E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);
	const cells = contentSubmissionDefinition.currentElementState?.element.cells;

	const selectedCell = useMemo(
		() =>
			cells?.find(
				(cell) =>
					cell.xIdx === selectedCellCords?.xIdx && cell.yIdx === selectedCellCords?.yIdx,
			),
		[selectedCellCords, cells],
	);

	const handleIndexSelected = useCallback(
		(index: number) => {
			onIndexSelected?.(index);
		},
		[onIndexSelected],
	);

	if (!selectedCell) return <React.Fragment />;

	return (
		<AnswersComponentWrapper onClick={(e) => e.stopPropagation()}>
			<AnswersTitle>{"Wähle die passende Antwort aus."}</AnswersTitle>
			<StyledScrollPanel>
				{selectedCell?.answers?.map((answer, index) => (
					<GapText
						onClick={() => handleIndexSelected(index)}
						key={`${answer.content}-${index}`}
						text={answer.content}
					/>
				))}
			</StyledScrollPanel>
		</AnswersComponentWrapper>
	);
};
