/**
 * @generated SignedSource<<b61472817ad42084859a91b6e29a4b51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appointmentModalsFlow_AvailabilityFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"appointmentOverviewModal_AvailabilityFragment" | "cancelAppointmentCoachModal_AvailabilityFragment" | "cancelAppointmentMessageCoachModal_AvailabilityFragment" | "cancelAppointmentModal_AvailabilityFragment" | "cancelAppointmentSuccessModal_AvailabilityFragment">;
  readonly " $fragmentType": "appointmentModalsFlow_AvailabilityFragment";
};
export type appointmentModalsFlow_AvailabilityFragment$key = {
  readonly " $data"?: appointmentModalsFlow_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentModalsFlow_AvailabilityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appointmentModalsFlow_AvailabilityFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appointmentOverviewModal_AvailabilityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "cancelAppointmentModal_AvailabilityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "cancelAppointmentSuccessModal_AvailabilityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "cancelAppointmentCoachModal_AvailabilityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "cancelAppointmentMessageCoachModal_AvailabilityFragment"
    }
  ],
  "type": "Availability",
  "abstractKey": null
};

(node as any).hash = "974bb07ad696be8bc4c4be2cfc651d57";

export default node;
