import { GapText } from "@components/gap-text";
import { TkaP3Span } from "@themes/font-tags";
import { ClozeTextElementAnswersProps } from "./cloze-text-element.interface";
import { AnswersComponentWrapper, StyledScrollPanel } from "./cloze-text-element.styles";

export const ClozeTextElementAnswers = ({
	answers,
	answerOnClick,
}: ClozeTextElementAnswersProps) => {
	return (
		<AnswersComponentWrapper onClick={(e) => e.stopPropagation()}>
			<TkaP3Span>{"Wähle die passende Antwort aus."}</TkaP3Span>
			<StyledScrollPanel>
				{answers.map((answer, index) => (
					<GapText
						key={`${answer.text}_${index}`}
						onClick={() => answerOnClick?.(answer.id)}
						text={answer.text}
						id={answer.id}
						draggable={true}
					/>
				))}
			</StyledScrollPanel>
		</AnswersComponentWrapper>
	);
};
