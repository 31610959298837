import { DayOfWeek, LocalTime } from "@js-joda/core";
import { FC, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { Dropdown } from "@components/dropdown";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { exceptionModal_FreeCalenderWeeksFragment$key } from "@relay/exceptionModal_FreeCalenderWeeksFragment.graphql";
import { DayAndTimesForCalendarWeekInput } from "@relay/myAvailabilities_EditMyAvailabilityScheduleMutation.graphql";
import {
	OverrideDaysAndTimesForCalendarWeek,
	TimeSlotsMap,
} from "@screens/my-availabilities/my-availabilities.types";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { formatCalendarWeekAndYear } from "@utils/appointment-utils";
import { FREE_CALENDAR_WEEKS_FRAGMENT } from "./exception-modal.graphql";
import { TitleWrapper } from "./exception-modal.styles";
import { ExceptionModalProps } from "./exception-modal.types";
import { Wrapper } from "../blockout-date-card/blockout-date-card.styles";
import { DaySchedule } from "../day-schedule";

export const ExceptionModal: FC<ExceptionModalProps> = ({
	isVisible,
	onDismiss,
	onSubmit,
	queryFragmentRef,
}) => {
	const [selectedCalendarWeek, setSelectedCalendarWeek] = useState<string>("");
	const [temporaryOverrideState, setTemporaryOverrideState] = useState<
		OverrideDaysAndTimesForCalendarWeek[]
	>([]);

	const calendarWeeks = useFragment<exceptionModal_FreeCalenderWeeksFragment$key>(
		FREE_CALENDAR_WEEKS_FRAGMENT,
		queryFragmentRef ?? null,
	);

	const [schedule, setSchedule] = useState<TimeSlotsMap>({});

	const formattedCalendarWeeks = useMemo(() => {
		return (
			calendarWeeks?.map((item) => ({
				label: `KW${item.calendarWeek} ${item.calendarYear}`,
				value: `${item.calendarWeek}_${item.calendarYear}`,
			})) ?? []
		);
	}, [calendarWeeks]);

	const createScheduleOnChangeHandler = (dayOfWeek: DayOfWeek) => (timeSlots?: LocalTime[]) => {
		const formattedCalendarWeekAndYear = formatCalendarWeekAndYear(selectedCalendarWeek);
		const overrideDaysAndTimesForCalendarWeek: DayAndTimesForCalendarWeekInput[] = [
			...temporaryOverrideState,
		];

		const existingEntry = overrideDaysAndTimesForCalendarWeek.find(
			(entry) =>
				entry.dayOfWeek === dayOfWeek.name() &&
				entry.calendarWeek === formattedCalendarWeekAndYear.week &&
				entry.calendarYear === formattedCalendarWeekAndYear.year,
		);

		if (existingEntry) {
			existingEntry.timeSlots = timeSlots?.map((localTime) => localTime.toString()) || [];
		} else {
			if (timeSlots) {
				overrideDaysAndTimesForCalendarWeek.push({
					calendarWeek: formattedCalendarWeekAndYear.week,
					calendarYear: formattedCalendarWeekAndYear.year,
					dayOfWeek: dayOfWeek.name(),
					timeSlots: timeSlots?.map((localTime) => localTime.toString()),
				});
			}
		}
		setSchedule({
			...schedule,
			[dayOfWeek.name()]: timeSlots ?? [],
		});
		setTemporaryOverrideState(overrideDaysAndTimesForCalendarWeek);
	};

	const handleCalendarWeekOnChange = (value: string) => {
		setSelectedCalendarWeek(value);
	};

	const handleOnSave = () => {
		setSelectedCalendarWeek("");
		onDismiss();
		onSubmit(temporaryOverrideState);
		setTemporaryOverrideState([]);
		setSchedule({});
	};
	const handleOnClose = () => {
		setSelectedCalendarWeek("");
		onDismiss();
	};

	return (
		<ResponsiveBottomSheetModal
			isVisible={isVisible}
			onDismiss={handleOnClose}
			dismissable
			hasCloseIcon
		>
			<DialogTemplate
				primaryButtonLabel={"Speichern"}
				primaryButtonIconName={"arrowRight"}
				primaryButtonStretch={false}
				onPrimaryButtonClick={handleOnSave}
				secondaryButtonLabel="Abbrechen"
				secondaryButtonStretch={false}
				onSecondaryButtonClick={handleOnClose}
			>
				<Wrapper>
					<TitleWrapper>
						<H1Span>Ausnahme hinzufügen</H1Span>
						<TkaP2Span>
							Die wiederkehrende Einstellung wird für die ausgewählte{" "}
							<mark>Woche überschrieben</mark>. Bitte prüfe deine Angaben genau.
						</TkaP2Span>
					</TitleWrapper>
					<Dropdown
						label="Kalenderwoche"
						value={selectedCalendarWeek}
						options={formattedCalendarWeeks}
						onChange={handleCalendarWeekOnChange}
						placeholder="Wähle eine Kalenderwoche aus"
					/>
					{selectedCalendarWeek &&
						DayOfWeek.values().map((day) => (
							<DaySchedule
								key={"exception-" + day.name()}
								dayOfWeek={day}
								timeSlots={schedule[day.name()]}
								onChange={createScheduleOnChangeHandler(day)}
							/>
						))}
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
