import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { HubspotGlobalStyle, HubspotHiddenGlobalStyle } from "@components/hubspot-style";
import { selectIsAvgsClient } from "@slices/AuthSlice";
import { SideMenuLayoutContext } from "./sidemenu-layout.context";
import {
	BottomContentWrapper,
	ChildrenWrapper,
	StyledSideMenu,
	StyledSideMenuContent,
	Wrapper,
} from "./sidemenu-layout.styles";
import { SidemenuLayoutProps } from "./sidemenu-layout.types";

export const SidemenuLayout = ({
	noPadding = false,
	noSideMenuPadding = false,
	noContentPadding = false,
	contentContainerId,
	sideMenu,
	bottomContent,
	keepSidemenuContentOnMobile = false,
	children,
}: SidemenuLayoutProps) => {
	const bottomContentRef = useRef<HTMLDivElement>(null);
	const isAvgsClient = useSelector(selectIsAvgsClient);

	const [bottomPadding, setBottomPadding] = useState<number | undefined>(undefined);
	const hasBottomContent = !!bottomContent;

	useEffect(() => {
		if (!hasBottomContent || !bottomContentRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setBottomPadding((padding) => {
				if (padding !== bottomContentRef.current?.clientHeight) {
					return bottomContentRef.current?.clientHeight;
				}
				return padding;
			});
		});
		resizeObserver.observe(bottomContentRef.current);
		return () => resizeObserver.disconnect();
	}, [hasBottomContent]);

	const hubspotBottomMargin = (bottomPadding ?? 0) / 16;

	return (
		<SideMenuLayoutContext.Provider value={{ bottomContentHeightPx: bottomPadding ?? 0 }}>
			<Wrapper bottomPadding={bottomPadding}>
				<StyledSideMenu noPadding={noPadding || noContentPadding}>
					{sideMenu && (
						<StyledSideMenuContent
							noPadding={noPadding || noSideMenuPadding}
							hasBottomContent={hasBottomContent}
							showInSideMenu
							sideMenuOnly={!keepSidemenuContentOnMobile}
						>
							{sideMenu}
						</StyledSideMenuContent>
					)}
					<ChildrenWrapper
						noPadding={noPadding || noContentPadding}
						hasBottomContent={hasBottomContent}
						id={contentContainerId}
					>
						{children}
					</ChildrenWrapper>
					{bottomContent && (
						<BottomContentWrapper>
							<div ref={bottomContentRef}>{bottomContent}</div>
						</BottomContentWrapper>
					)}
					{bottomContent && <HubspotGlobalStyle bottomMarginRem={hubspotBottomMargin} />}
					{isAvgsClient && <HubspotHiddenGlobalStyle />}
				</StyledSideMenu>
			</Wrapper>
		</SideMenuLayoutContext.Provider>
	);
};
