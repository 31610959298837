import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { ProgressBar } from "@components/progress-bar";
import { colorPrimary100Class } from "@themes/color-classes";
import { H3Span } from "@themes/font-tags";
import {
	progressBarWrapperClass,
	spacerClass,
	trackerWrapperClass,
	wrapperClass,
} from "./avgs-potential-analysis-navigation.styles";

export const AvgsPotentialAnalysisNavigationSkeleton = () => {
	return (
		<div className={spacerClass}>
			<div className={wrapperClass}>
				<div className={progressBarWrapperClass}>
					<ProgressBar
						colorVersion={"course"}
						value={1}
						heightInRem={1.25}
						borderRadiusInRem={0.35}
					/>
				</div>
				<div className={trackerWrapperClass}>
					<H3Span className={colorPrimary100Class}>1 von 1</H3Span>
				</div>
				<BottomNavigationTemplateSkeleton />
			</div>
		</div>
	);
};
