import { success0, success100 } from "@themes/colors";
import { Wrapper } from "./confirm-participant-absent-modal.styles";
import { ConfirmParticipantAbsentModalProps } from "./confirm-participant-absent-modal.types";
import { DialogTemplate } from "../dialog-template";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";

// TODO: add-translations
export const ConfirmParticipantAbsentModal = ({
	name,
	isVisible = false,
	onDismiss,
	onConfirm,
}: ConfirmParticipantAbsentModalProps) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel="Abwesend markieren"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={onConfirm}
				maxWidthRem={26}
			>
				<Wrapper>
					<IconHeadlineButtonTemplate
						iconName="calendarCheck"
						iconColor={success100}
						iconBackgroundColor={success0}
						title="Teilnehmer als unentschuldigt abwesend markieren?"
						subtitle={`${name} wird als unentschuldigt markiert und hat kein Anspruch seine Lizenz zurück zu erhalten.`}
					/>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
