import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { TkaH3Span, TkaP2Span } from "@themes/font-tags";
import { IconWrapper, ItemWrapper, TextWrapper } from "./basket-timeline.styles";
import { BasketEvent, TimelineSkeletonProps } from "./basket-timeline.types";
import { Icon } from "../icon";
import { Timeline } from "../timeline";

// TODO: add-translations
export const BasketTimelineSkeleton = ({
	currentStep,
	orientation = "horizontal",
}: TimelineSkeletonProps) => {
	const { t } = useTkaTranslation("basketScreen");

	const events: BasketEvent[] = [
		{
			label: t("basket_screen.basketTimelineBasketLabel"),
			icon: "shoppingBasketThin",
		},
		{
			label: t("basket_screen.basketTimelineContractLabel"),
			icon: "paragraphTwoColumn",
		},
		{
			label: t("basket_screen.basketTimelinePaymentLabel"),
			icon: "creditCardMastercard1",
		},
		{
			label: t("basket_screen.basketTimelineOverviewLabel"),
			icon: "shoppingBasketCheck1",
		},
	];

	const renderContent = (event: BasketEvent, index: number) => {
		const finished = index < currentStep - 1;
		const icon = finished ? "checkCircle" : event.icon;
		return (
			<ItemWrapper>
				<IconWrapper finished={finished}>
					<Icon icon={icon} sizeInRem={1.5} />
				</IconWrapper>
				<TextWrapper>
					<TkaH3Span>{event.label}</TkaH3Span>
					{event.subtitle && <TkaP2Span>{event.subtitle}</TkaP2Span>}
				</TextWrapper>
			</ItemWrapper>
		);
	};

	return (
		<Timeline
			events={events}
			orientation={orientation}
			currentStep={currentStep}
			progressBarOffestRem={0.875}
			hideContent={orientation === "horizontal"}
			content={renderContent}
		/>
	);
};
