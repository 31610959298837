import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@components/button";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { StartVocabTrainingButton } from "@screens/vocabulary/parts/start-vocab-training-button/start-vocab-training-button.component";
import { WordInventoryDisplay } from "@screens/vocabulary/parts/word-inventory-display/word-inventory-display.component";
import { selectIsLearnMode, setLearnMode } from "@slices/vocabulary.slice";
import { BottomBarWrapper } from "./vocabulary.styles";

export const VocabularyScreen = () => {
	const dispatch = useDispatch();
	const isLearnMode = useSelector(selectIsLearnMode);

	const { isXLargeUp } = useWindowSize();

	const [selectedItems, setSelectedItems] = useState<string[]>([]);

	const handleBackOnClick = () => {
		dispatch(setLearnMode({ isLearnMode: false }));
	};

	return (
		<ScreenWithProfileMenuLayout
			canGoBack={!isXLargeUp}
			bottomContent={
				<BottomBarWrapper>
					{isLearnMode && (
						<Button
							colorVersion="tertiary"
							iconName="arrowLeft"
							onClick={handleBackOnClick}
						/>
					)}
					<StartVocabTrainingButton selectedItems={selectedItems} />
				</BottomBarWrapper>
			}
		>
			<WordInventoryDisplay
				selectionModel={
					isLearnMode
						? {
								selectedItems: selectedItems,
								onSelectionChange: setSelectedItems,
						  }
						: undefined
				}
			/>
		</ScreenWithProfileMenuLayout>
	);
};
