/**
 * @generated SignedSource<<5d1993599f23e24b3756833cc2f22ede>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userContextMenu_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignGroupModal_QueryFragment">;
  readonly " $fragmentType": "userContextMenu_QueryFragment";
};
export type userContextMenu_QueryFragment$key = {
  readonly " $data"?: userContextMenu_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userContextMenu_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./userContextMenuRefetchQuery.graphql')
    }
  },
  "name": "userContextMenu_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "assignGroupModal_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "757c8b5cf35b4dadd32a785f78848f1c";

export default node;
