import { brandMain100, brandStrong100, shade0, shade10, shade100 } from "@themes/colors";
import { Colors, ColorVersion } from "./progress-bar.interface";

const colorVersionMap: Record<ColorVersion, Colors> = {
	profile: {
		activeColor: shade100,
		inactiveColor: shade10,
		labelColor: shade100,
	},
	video: {
		activeColor: brandMain100,
		inactiveColor: shade0,
		labelColor: shade0,
	},
	course: {
		activeColor: brandStrong100,
		inactiveColor: shade10,
		labelColor: shade10,
	},
};
export const getColors = (colorVersion: ColorVersion): Colors => {
	return colorVersionMap[colorVersion];
};

export const getHasBorderRadius = (colorVersion: ColorVersion): boolean => {
	return colorVersion !== "course";
};
