import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { Path } from "@router/paths";
import { TkaP2Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import { LICENSE_GROUPING_FRAGMENT } from "./license-context-menu.grapqhl";
import { ItemWrapper, OptionsWrapper, Wrapper } from "./license-context-menu.styles";
import { LicenseContextMenuProps, LicenseContextMenuRef } from "./license-context-menu.types";

export const LicenseContextMenu = forwardRef<LicenseContextMenuRef, LicenseContextMenuProps>(
	function LicenseContextMenu({ queryFragmentRef }, ref) {
		const license = useFragment(LICENSE_GROUPING_FRAGMENT, queryFragmentRef ?? null);

		const navigate = useNavigate();
		const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

		const handleCreateGroupLicenseClick = () => {
			license?.id &&
				navigate(Path.profileMenu.licenses.detail.withId(license.id).createGroup.path);
		};

		const handleCreateSingleLicenseClick = () => {
			license?.id &&
				navigate(Path.profileMenu.licenses.detail.withId(license.id).createSingle.path);
		};

		const cartClickout =
			license?.learnable?.groupCoaching?.cartClickout?.relativeLink ||
			license?.learnable?.oneToOneCoaching?.cartClickout?.relativeLink ||
			license?.learnable?.root?.structureDefinition?.extension?.cartClickout?.relativeLink;

		const handleBuyLicensesClick = () => {
			cartClickout && navigate(cartClickout);
		};

		useImperativeHandle(ref, () => ({
			toggle: (event, target) => {
				overlayRef?.current?.toggle(event, target);
			},
		}));
		return (
			<>
				<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing0}>
					<Wrapper>
						<OptionsWrapper>
							<ItemWrapper onClick={handleCreateGroupLicenseClick}>
								<Icon icon={"website"} sizeInRem={1} />
								<TkaP2Span>Gruppe für Lizenz freischalten</TkaP2Span>
							</ItemWrapper>
							<ItemWrapper onClick={handleCreateSingleLicenseClick}>
								<Icon icon={"user"} sizeInRem={1} />
								<TkaP2Span>Nutzer für Lizenz freischalten</TkaP2Span>
							</ItemWrapper>
							{license?.learnable && (
								<ItemWrapper onClick={handleBuyLicensesClick}>
									<Icon icon={"loginKey"} sizeInRem={1} />
									<TkaP2Span>Lizenzen erwerben</TkaP2Span>
								</ItemWrapper>
							)}
						</OptionsWrapper>
					</Wrapper>
				</ResponsiveBottomSheetOverlayPanel>
			</>
		);
	},
);
