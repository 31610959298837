/**
 * @generated SignedSource<<f45dd2b8f89ad6e747e2110d90a56c19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordInventoryDisplay_Query$variables = {};
export type wordInventoryDisplay_Query$data = {
  readonly Vocab: {
    readonly MyWordInventory: {
      readonly id: string;
      readonly packages: ReadonlyArray<{
        readonly unlockedAt: string;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"wordPackageList_WordInventoryFragment">;
    } | null;
  };
};
export type wordInventoryDisplay_Query = {
  response: wordInventoryDisplay_Query$data;
  variables: wordInventoryDisplay_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unlockedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wordId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "wordInventoryDisplay_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VocabQueries",
        "kind": "LinkedField",
        "name": "Vocab",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WordInventory",
            "kind": "LinkedField",
            "name": "MyWordInventory",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "wordPackageList_WordInventoryFragment"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageUnlock",
                "kind": "LinkedField",
                "name": "packages",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "wordInventoryDisplay_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VocabQueries",
        "kind": "LinkedField",
        "name": "Vocab",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WordInventory",
            "kind": "LinkedField",
            "name": "MyWordInventory",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageUnlock",
                "kind": "LinkedField",
                "name": "packages",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackage",
                    "kind": "LinkedField",
                    "name": "wordPackage",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "language",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Word",
                        "kind": "LinkedField",
                        "name": "associatedWords",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "word",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "translation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "explanation",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WordProficiency",
                "kind": "LinkedField",
                "name": "proficiencies",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "proficiencyLevel",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WordNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6ca2c813f29166a8bce6a1abc804730",
    "id": null,
    "metadata": {},
    "name": "wordInventoryDisplay_Query",
    "operationKind": "query",
    "text": "query wordInventoryDisplay_Query {\n  Vocab {\n    MyWordInventory {\n      id\n      ...wordPackageList_WordInventoryFragment\n      packages {\n        unlockedAt\n      }\n    }\n  }\n}\n\nfragment editWordNoteModal_WordNoteFragment on WordNote {\n  wordId\n  note\n}\n\nfragment wordCard_WordFragment on Word {\n  id\n  word\n  translation\n  explanation\n}\n\nfragment wordCard_WordNoteFragment on WordNote {\n  wordId\n  note\n  ...editWordNoteModal_WordNoteFragment\n}\n\nfragment wordCard_WordProficiencyFragment on WordProficiency {\n  wordId\n  proficiencyLevel\n}\n\nfragment wordPackageCard_WordInventoryFragment on WordInventory {\n  packages {\n    wordPackage {\n      id\n      name\n      language\n      associatedWords {\n        id\n        ...wordCard_WordFragment\n      }\n    }\n  }\n  proficiencies {\n    proficiencyLevel\n    wordId\n    ...wordCard_WordProficiencyFragment\n  }\n  notes {\n    wordId\n    ...wordCard_WordNoteFragment\n  }\n}\n\nfragment wordPackageList_WordInventoryFragment on WordInventory {\n  packages {\n    wordPackage {\n      id\n    }\n  }\n  ...wordPackageCard_WordInventoryFragment\n}\n"
  }
};
})();

(node as any).hash = "66c850d9b41274294e24071331eda443";

export default node;
