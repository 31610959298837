import tw from "twin.macro";

export const AvailabilityRow = tw.div`
	grid
	grid-rows-[min-content_1fr]
	grid-cols-[1fr_min-content]
	md:grid-cols-[min-content_1fr]
	gap-16
`;

export const SwitchWrapper = tw.div`
	col-start-2
	md:col-start-1
	col-end-3
	md:col-end-2
	row-start-1
`;

export const HeaderWrapper = tw.div`
	col-start-1
	md:col-start-2
	col-end-2
	md:col-end-3
	row-start-1
	flex
	flex-col
`;

export const SlotsWrapper = tw.div`
	col-start-1
	md:col-start-2
	col-end-3
	flex
	flex-wrap
	gap-8
`;
