import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { Dropdown } from "@components/dropdown";
import { selectAmountOfQuestions, setAmountOfQuestions } from "@slices/vocabulary.slice";
import { shade5 } from "@themes/colors";
import { TkaP3Span } from "@themes/font-tags";

export const TestConfiguration = () => {
	const amountOfQuestions = useSelector(selectAmountOfQuestions) ?? -1;
	const dispatch = useDispatch();

	return (
		<TestConfigurationWrapper>
			<label>
				<TkaP3Span>Maximale Wortanzahl</TkaP3Span>
				<Dropdown
					value={amountOfQuestions?.toFixed()}
					options={[
						{ value: "-1", label: "Alle" },
						{ value: "5", label: "5" },
						{ value: "10", label: "10" },
						{ value: "25", label: "25" },
						{ value: "50", label: "50" },
					]}
					onChange={(e) => {
						if (e === "-1") {
							dispatch(setAmountOfQuestions({ amountOfQuestions: undefined }));
						} else {
							dispatch(setAmountOfQuestions({ amountOfQuestions: parseInt(e) }));
						}
					}}
				/>
			</label>
		</TestConfigurationWrapper>
	);
};

export const TestConfigurationWrapper = styled.div`
	${tw`p-16`};
	background-color: ${shade5.getColor()};
	border-radius: 12px;
`;
