import { useMemo } from "react";
import { readInlineData } from "react-relay";
import { dropdownUser_UserFragment$data } from "@relay/dropdownUser_UserFragment.graphql";
import { TkaP2Span } from "@themes/font-tags";
import { USER_FRAGMENT } from "./dropdown-user.graphql";
import { Wrapper } from "./dropdown-user.styles";
import { DropdownUserProps } from "./dropdown-user.types";
import { Dropdown } from "../dropdown";
import { DropdownOption } from "../dropdown/dropdown.types";
import { UserAvatar } from "../user-avatar";

export const DropdownUser = ({
	placeholder,
	label,
	selectedUserId,
	onChange,
	userFragmentsRef,
}: DropdownUserProps) => {
	const users = userFragmentsRef?.map((fragment) => readInlineData(USER_FRAGMENT, fragment));

	const options = useMemo(() => {
		return (
			users?.map((user) => ({
				label: user.name,
				value: user.id,
			})) ?? []
		);
	}, [users]);

	const selectedUser = users?.find((user) => user.id === selectedUserId);

	const renderItem = (user?: dropdownUser_UserFragment$data) => {
		if (!user) return null;
		return (
			user && (
				<Wrapper>
					<UserAvatar sizeInRem={1.25} userFragmentRef={user} />
					<TkaP2Span>{user.name}</TkaP2Span>
				</Wrapper>
			)
		);
	};

	const renderItemTemplate = (option: DropdownOption) => {
		const user = users?.find((user) => user.id === option.value);
		return renderItem(user);
	};

	return (
		<Dropdown
			label={label}
			placeholder={placeholder}
			options={options}
			value={selectedUserId}
			onChange={onChange}
			valueTemplate={renderItem(selectedUser)}
			itemTemplate={renderItemTemplate}
		/>
	);
};
