import { PathParams } from "@thekeytechnology/epic-ui";
import { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { useParams } from "react-router-dom";
import { Button } from "@components/button";
import { InvitationCard } from "@components/invitation-card";
import { UserCard } from "@components/user-card";
import { withSuspense } from "@components/with-suspense";
import { groupDetails_Query } from "@relay/groupDetails_Query.graphql";
import { groupDetails_QueryFragment$key } from "@relay/groupDetails_QueryFragment.graphql";
import { groupDetailsAccountMembersRefetchQuery } from "@relay/groupDetailsAccountMembersRefetchQuery.graphql";
import { GroupsPath } from "@router/paths/profile";
import { AccountContext } from "@screens/account/account.context";
import { GroupMembersListSkeleton } from "./group-members-list.skeleton";
import { GroupMembersListProps, GroupMembersListRef } from "./group-members-list.types";
import { GroupDetailsContext } from "../../group-details.context";
import { QUERY, QUERY_FRAGMENT } from "../../group-details.graphql";
import { MembersWrapper } from "../../group-details.styles";
import { UserInGroupContextMenu } from "../user-in-group-context-menu";

export const GroupMembersListComponent = forwardRef<GroupMembersListRef, GroupMembersListProps>(
	function GroupMembersList({ handleToggleMember, selectedMembers, debouncedSearchValue }, ref) {
		const { groupId: optionalGroupId } = useParams<PathParams<typeof GroupsPath>>();
		const groupId = optionalGroupId ?? "";
		const query = useLazyLoadQuery<groupDetails_Query>(
			QUERY,
			{ id: groupId },
			{ fetchPolicy: "network-only" },
		);
		const {
			data: fragment,
			refetch,
			loadNext,
			hasNext,
		} = usePaginationFragment<
			groupDetailsAccountMembersRefetchQuery,
			groupDetails_QueryFragment$key
		>(QUERY_FRAGMENT, query);
		const handleLoadNext = () => {
			loadNext(50);
		};

		const { addMembersConnectionId } = useContext(AccountContext);
		const { setNumberOfConfirmedMembers, setNumberOfUnconfirmedMembers } =
			useContext(GroupDetailsContext);

		const accountMembers =
			fragment.AccountMemberManagement.AccountMembers.accountMembers.edges
				?.filter(Boolean)
				?.map((edge) => edge?.node!) ?? [];

		const accountMembersConnection =
			fragment.AccountMemberManagement.AccountMembers.accountMembers;
		const accountMembersConnectionId = accountMembersConnection.__id;

		useEffect(() => {
			accountMembersConnectionId && addMembersConnectionId(accountMembersConnectionId);
		}, [accountMembersConnectionId]);

		const handleRefetch = () => {
			refetch(
				{ filterByNameOrEmail: debouncedSearchValue, groupId },
				{ fetchPolicy: "network-only" },
			);
		};

		useEffect(() => {
			setNumberOfConfirmedMembers &&
				setNumberOfConfirmedMembers(
					fragment.AccountMemberManagement.AccountMembers.userCount,
				);
			setNumberOfUnconfirmedMembers &&
				setNumberOfUnconfirmedMembers(
					fragment.AccountMemberManagement.AccountMembers.invitationCount,
				);
		}, [
			fragment.AccountMemberManagement.AccountMembers.invitationCount,
			fragment.AccountMemberManagement.AccountMembers.userCount,
		]);
		useEffect(() => {
			handleRefetch();
		}, [debouncedSearchValue]);

		useImperativeHandle(ref, () => ({
			refetch: () => {
				handleRefetch();
			},
		}));
		return (
			<>
				<MembersWrapper>
					{accountMembers.map((member) => {
						return member.kind === "UserInAccount" ? (
							<UserCard
								key={member.id}
								showContextMenu
								isSelected={selectedMembers.some(
									(m) => m.id === member.user?.user.id,
								)}
								onSelectToggeled={handleToggleMember}
								userFragmentRef={member}
							>
								{(ref) => (
									<UserInGroupContextMenu
										ref={ref}
										userFragmentRef={member.user?.user}
										groupId={groupId}
										groupMembersConnectionId={accountMembersConnectionId}
									/>
								)}
							</UserCard>
						) : (
							<InvitationCard key={member.id} invitationFragmentRef={member} />
						);
					})}
				</MembersWrapper>
				{hasNext && (
					<Button
						onClick={handleLoadNext}
						label="Mehr anzeigen"
						colorVersion="tertiary"
					/>
				)}
			</>
		);
	},
);

export const GroupMembersList = withSuspense(GroupMembersListComponent, GroupMembersListSkeleton);
