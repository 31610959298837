import { cva } from "@styled-system/css";

export const wrapperClass = cva({
	variants: {
		isBlured: {
			true: {
				opacity: "0.5",
				pointerEvents: "none",
				_hover: {
					cursor: "default",
				},
			},
		},
	},
});
