import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ActiveContentWrapper = styled.div<{ active?: boolean }>`
	${({ active }) =>
		active &&
		tw`
			xl:bg-shade-0
			xl:-mr-24
			xl:pr-24
			xl:rounded-tl-10
			xl:rounded-bl-10
		`}
`;
