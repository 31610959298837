import tw from "twin.macro";

export const ScreenWrapper = tw.div`
	flex
	flex-col
	h-full
	gap-32
	p-24
	md:p-40
	overflow-y-scroll
`;

export const HeaderWrapper = tw.div`
	flex
	flex-col
	gap-12
`;

export const ProductWrapper = tw.div`
	flex
	flex-row
`;

export const ProductItem = tw.div`
	flex
	flex-col
	md:flex-row
	self-stretch
	items-stretch
	md:items-center
	gap-y-16
`;

export const ProductCardWrapper = tw.div`
relative
md:static
	md:min-w-[22rem]
`;

export const IconWrapper = tw.div`
absolute
right-10
top-10`;

export const ControlsPriceWrapper = tw.div`
	flex
	flex-row
	flex-1
	items-center
	self-stretch
`;

export const CountControls = tw.div`
	flex
	flex-row
	flex-1
	items-center
	md:justify-center
	md:w-[8rem]
	gap-16
	pr-16
	md:px-16
`;

export const ItemPriceWrapper = tw.div`
	flex
	flex-col
	items-end
	whitespace-nowrap
	md:w-[7rem]
`;

export const Divider = tw.div`	
	min-h-[1px]
	self-stretch
	bg-shade-10
`;

export const DiscountWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	md:justify-between
	md:items-center
	gap-16
`;

export const DiscountTitleTagWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const DiscountTitleWrapper = tw.div`
	flex
	flex-row
	gap-8
	items-center
`;

export const DiscountPercentWrapper = tw.div`
	self-end
	flex
	flex-row
	md:flex-col
	gap-16
	items-end
`;

export const PriceWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const PriceRowsWrapper = tw.div`
	flex
	flex-row
	justify-between
	whitespace-nowrap
`;

export const PriceLeftGroupWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const PriceRightGroupWrapper = tw.div`
	flex
	flex-col
	items-end
	gap-8
	text-right
`;

export const PriceMonthlyWrapper = tw.div`
	flex
	self-end
`;

export const DiscountCodeWrapper = tw.div`
	flex
	flex-row
	gap-16
`;

export const MoreProductsWrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const ProductsList = tw.div`
	grid
	grid-cols-1
	md:grid-cols-2
	lg:grid-cols-3
	xl:grid-cols-2
	xxl:grid-cols-3
	gap-16
	justify-center
`;
