import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { Skeleton } from "@components/skeleton";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";

export const ContentSubmissionSkeleton = () => {
	return (
		<ScreenSidemenuLayout
			canGoBack
			headerLeftIconName="close"
			sideMenu={
				<>
					<Skeleton width="4rem" />
					<Skeleton width="15rem" height="1.5rem" className="mt-4" />
					<Skeleton width="100%" className="mt-12" />
					<Skeleton width="80%" className="mt-4" />
					<Skeleton width="90%" className="mt-4" />
					<Skeleton width="30%" className="mt-4" />
				</>
			}
			bottomContent={<BottomNavigationTemplateSkeleton />}
		>
			<Skeleton width="70%" height="1.5rem" />
			<Skeleton width="40%" className="mt-32" />
			<Skeleton width="100%" className="mt-12" />
			<Skeleton width="100%" className="mt-12" />
			<Skeleton width="100%" className="mt-12" />
			<Skeleton width="100%" className="mt-12" />
		</ScreenSidemenuLayout>
	);
};
