import { FC } from "react";
import { TkaH3Span, TkaP3Span } from "@themes/font-tags";
import { formatFileSize } from "@utils/file-size-utils";
import { FileInfoWrapper, Wrapper } from "./uploaded-file-card.styles";
import { UploadedFileCardProps } from "./uploaded-file-card.types";
import { Icon } from "../icon";

const UploadedFileCardComponent: FC<UploadedFileCardProps> = ({
	onDeleteClick,
	fileName,
	fileSize,
}) => {
	if (!fileSize || !fileName) return null;
	const formattedFileSize = formatFileSize(fileSize);
	return (
		<Wrapper>
			<Icon icon="csv" sizeInRem={1.5} />
			<FileInfoWrapper>
				<TkaH3Span>{fileName}</TkaH3Span>
				<TkaP3Span>{formattedFileSize}</TkaP3Span>
			</FileInfoWrapper>
			<Icon onClick={onDeleteClick} icon="trash" sizeInRem={1.25} />
		</Wrapper>
	);
};

export default UploadedFileCardComponent;
