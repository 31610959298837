import React from "react";
import { TkaIconName } from "@components/icon";
import { TkaColor } from "@themes/colors";

export interface MultipleChoiceGapTextProps {
	text: string;
	isRadio?: boolean;
	answerLetter?: string;
	status: MultipleChoiceGapTextStatus;
	checked: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export enum MultipleChoiceGapTextStatus {
	Default,
	Correct,
	Wrong,
	ActuallyCorrect,
	Disabled,
}

export interface MultipleChoiceGapTextStyle {
	backgroundColor: TkaColor;
	borderColor: TkaColor;
	textColor: TkaColor;
}

export interface MultipleChoiceGapTextIconStyle {
	icon?: TkaIconName;
	iconColor?: TkaColor;
	iconBackgroundColor?: TkaColor;
}
