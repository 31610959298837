import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { readInlineData, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { Button } from "@components/button";
import { InvitationCard } from "@components/invitation-card";
import { UserCard } from "@components/user-card";
import { withSuspense } from "@components/with-suspense";
import { membersList_Query } from "@relay/membersList_Query.graphql";
import { membersList_QueryFragment$key } from "@relay/membersList_QueryFragment.graphql";
import { membersList_QueryFragmentRefetch } from "@relay/membersList_QueryFragmentRefetch.graphql";
import { membersTab_AccountMemberFragment$key } from "@relay/membersTab_AccountMemberFragment.graphql";
import { AccountContext } from "@screens/account/account.context";
import { MEMBERS_LIST_QUERY_FRAGMENT, QUERY } from "./members-list.graphql";
import { MembersListSkeleton } from "./members-list.skeleton";
import { MemberCardsWrapper } from "./members-list.styles";
import { MembersListProps, MembersListRef } from "./members-list.types";
import { MembersTabContext } from "../../members-tab.context";
import { ACCOUNT_MEMBER_FRAGMENT } from "../../members-tab.graphql";
import { useMembersFilterContext } from "../members-filter-component/members-filter.context";
import { UserContextMenu } from "../user-context-menu";

export const MembersListComponent = forwardRef<MembersListRef, MembersListProps>(
	function MembersList(
		{
			selectedMembers,
			handleToggleMember,
			userInAccountGroupQueryFragment,
			debouncedSearchValue,
		},
		ref,
	) {
		const [paginationCount, setPaginationCount] = useState(10);
		const { filterByAccountMemberKind, selectedGroups } = useMembersFilterContext();

		const query = useLazyLoadQuery<membersList_Query>(
			QUERY,
			{ filterByGroupIds: [] },
			{ fetchPolicy: "store-and-network" },
		);

		const { data, refetch, hasNext, loadNext } = usePaginationFragment<
			membersList_QueryFragmentRefetch,
			membersList_QueryFragment$key
		>(MEMBERS_LIST_QUERY_FRAGMENT, query);

		const { setNumberOfConfirmedMembers, setNumberOfUnconfirmedMembers } =
			useContext(MembersTabContext);
		const { addMembersConnectionId } = useContext(AccountContext);

		const accountMembersConnection =
			data?.AccountMemberManagement.AccountMembers.accountMembers;
		const accountMembersConnectionId = accountMembersConnection?.__id;

		useEffect(() => {
			setNumberOfConfirmedMembers &&
				setNumberOfConfirmedMembers(data?.AccountMemberManagement.AccountMembers.userCount);
			setNumberOfUnconfirmedMembers &&
				setNumberOfUnconfirmedMembers(
					data?.AccountMemberManagement.AccountMembers.invitationCount,
				);
		}, [
			data?.AccountMemberManagement.AccountMembers.userCount,
			data?.AccountMemberManagement.AccountMembers.invitationCount,
		]);

		useEffect(() => {
			accountMembersConnectionId && addMembersConnectionId(accountMembersConnectionId);
		}, [accountMembersConnectionId]);

		const accountMembers =
			accountMembersConnection?.edges
				?.filter(Boolean)
				?.map((edge) =>
					readInlineData<membersTab_AccountMemberFragment$key>(
						ACCOUNT_MEMBER_FRAGMENT,
						edge?.node!,
					),
				) ?? [];

		const paginatedAccountMembers = accountMembers.slice(0, paginationCount);

		const handleRefetch = () => {
			refetch(
				{
					filterByNameOrEmail: debouncedSearchValue,
					filterByAccountMemberKind: filterByAccountMemberKind,
					filterByGroupIds: selectedGroups.map((group) => group.id),
				},
				{ fetchPolicy: "network-only" },
			);
		};

		useEffect(() => {
			handleRefetch();
		}, [debouncedSearchValue, filterByAccountMemberKind, selectedGroups]);

		useImperativeHandle(ref, () => ({
			refetch: () => {
				handleRefetch();
			},
		}));

		const handleShowMore = () => {
			if (paginationCount >= accountMembers.length && hasNext) {
				loadNext(10, {
					onComplete: (error) => {
						if (!error) {
							setPaginationCount((prevCount) => prevCount + 10);
						}
					},
				});
			}
			setPaginationCount(paginationCount + 10);
		};
		return (
			<>
				<MemberCardsWrapper>
					{paginatedAccountMembers.map((member) => {
						return member.kind === "UserInAccount" ? (
							<UserCard
								key={member.id}
								isSelected={selectedMembers.some(
									(m) => m.id === member.user?.user.id,
								)}
								onSelectToggeled={handleToggleMember}
								userFragmentRef={member}
							>
								{(ref) => (
									<UserContextMenu
										ref={ref}
										userFragmentRef={member.user?.user}
										queryFragmentRef={userInAccountGroupQueryFragment}
									/>
								)}
							</UserCard>
						) : (
							<InvitationCard key={member.id} invitationFragmentRef={member} />
						);
					})}
				</MemberCardsWrapper>
				{paginationCount < accountMembers.length && (
					<Button
						onClick={handleShowMore}
						label="Mehr anzeigen"
						colorVersion="tertiary"
					/>
				)}
			</>
		);
	},
);

export const MembersList = withSuspense(MembersListComponent, MembersListSkeleton);
