import { useContext } from "react";
import { Button } from "@components/button";
import { DialogTemplate } from "@components/dialog-template";
import { Icon } from "@components/icon";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { Hr } from "@components/report-modal-flow/parts/create-report-modal/create-report-modal.styles";
import {
	CenterWrapper,
	ListItem,
	ListWrapper,
	ReferenceNumberWrapper,
	Wrapper,
} from "@components/report-modal-flow/parts/create-report-success-modal/create-report-success-modal.styles";
import { CreateReportSuccessModalProps } from "@components/report-modal-flow/parts/create-report-success-modal/create-report-success-modal.types";
import { SystemEmail } from "@components/report-modal-flow/parts/system-email";
import { ReportModalFlowContext } from "@components/report-modal-flow/report-modal-flow.context";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { brandStrong120, shade10, success0, success100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";

export const CreateReportSuccessModal = ({
	isVisible = false,
	onDismiss,
}: CreateReportSuccessModalProps) => {
	const { setReferenceNumber, referenceNumber } = useContext(ReportModalFlowContext);
	const handleOnDismiss = () => {
		setReferenceNumber("");
		onDismiss?.();
	};
	return (
		<ResponsiveBottomSheetModal
			isVisible={isVisible}
			dismissable={true}
			onDismiss={handleOnDismiss}
		>
			<DialogTemplate maxWidthRem={27.5}>
				<Wrapper>
					<IconHeadlineButtonTemplate
						iconName="feedback2"
						iconColor={success100}
						iconBackgroundColor={success0}
						title="Vielen Dank"
						subtitle="Deine Meldung wurde erfolgreich eingereicht"
					/>
					<ListWrapper>
						<ListItem>
							<Icon icon={"checkBadge"} tkaColor={success100} sizeInRem={1.25} />
							<TkaP2Span>Deine Meldung wird nun von unserem Team geprüft.</TkaP2Span>
						</ListItem>
						<ListItem>
							<Icon icon={"checkBadge"} tkaColor={success100} sizeInRem={1.25} />
							<TkaP2Span>
								Wir bemühen uns, alle Anfragen zügig zu bearbeiten und werden Dich
								innerhalb der nächsten 5 Werktage kontaktieren.
							</TkaP2Span>
						</ListItem>
						<ListItem>
							<Icon icon={"checkBadge"} tkaColor={success100} sizeInRem={1.25} />
							<TkaP2Span>
								Solltest du in der Zwischenzeit weitere Fragen haben oder
								zusätzliche Informationen bereitstellen wollen, kontaktiere uns mit
								der Referenznummer{" "}
								<ReferenceNumberWrapper>
									[{referenceNumber ?? "Fehler"}]
								</ReferenceNumberWrapper>{" "}
								bitte unter: <SystemEmail tkaColor={brandStrong120} isBig />
							</TkaP2Span>
						</ListItem>
					</ListWrapper>
				</Wrapper>

				<Hr tkaColor={shade10} />
				<CenterWrapper>
					<Button label={"Okay"} onClick={handleOnDismiss} iconName={"arrowRight"} />
				</CenterWrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
