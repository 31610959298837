import graphql from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_QUESTION_FRAGMENT = graphql`
	fragment checkboxQuestion_PotentialAnalysisQuestionFragment on PotentialAnalysisQuestion {
		kind
		id
		... on PotentialAnalysisMultipleChoiceFreetextQuestion {
			questionText
			answerOptions {
				answerOptionId
				isFreeText
				text
			}
			givenAnswers {
				answerOptionId
				freeText
				kind
			}
		}
		... on PotentialAnalysisMultipleChoiceQuestion {
			questionText
			answerOptions {
				answerOptionId
				text
			}
			givenAnswers {
				answerOptionId
				kind
			}
		}
		... on PotentialAnalysisSingleChoiceFreetextQuestion {
			questionText
			answerOptions {
				answerOptionId
				isFreeText
				text
			}
			givenAnswer {
				answerOptionId
				freeText
				kind
			}
		}
		... on PotentialAnalysisSingleChoiceQuestion {
			kind
			questionText
			answerOptions {
				answerOptionId
				text
			}
			givenAnswer {
				answerOptionId
				kind
			}
		}
	}
`;

export const SET_ANSWER_IN_SINGLE_CHOICE_QUESTION_MUTATION = graphql`
	mutation checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation(
		$input: SetAnswerInSingleChoiceQuestionInput!
	) {
		AvgsPotentialAnalysis {
			setAnswerInSingleChoiceQuestion(input: $input) {
				question {
					id
					givenAnswer {
						answerOptionId
					}
				}
			}
		}
	}
`;

export const SET_ANSWER_IN_SINGLE_CHOICE_FREE_TEXT_QUESTION_MUTATION = graphql`
	mutation checkboxQuestion_SetAnswerInSingleChoiceFreeTextQuestionMutation(
		$input: SetAnswerInSingleChoiceFreeTextQuestionInput!
	) {
		AvgsPotentialAnalysis {
			setAnswerInSingleChoiceFreeTextQuestion(input: $input) {
				question {
					id
					givenAnswer {
						answerOptionId
						freeText
					}
				}
			}
		}
	}
`;

export const SET_ANSWER_IN_MULTIPLE_CHOICE_QUESTION_MUTATION = graphql`
	mutation checkboxQuestion_SetAnswerInMultipleChoiceQuestionMutation(
		$input: SetAnswerInMultipleChoiceQuestionInput!
	) {
		AvgsPotentialAnalysis {
			setAnswerInMultipleChoiceQuestion(input: $input) {
				question {
					id
					givenAnswers {
						answerOptionId
					}
				}
			}
		}
	}
`;

export const SET_ANSWER_IN_MULTIPLE_CHOICE_FREE_TEXT_QUESTION_MUTATION = graphql`
	mutation checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation(
		$input: SetAnswerInMultipleChoiceFreeTextQuestionInput!
	) {
		AvgsPotentialAnalysis {
			setAnswerInMultipleChoiceFreeTextQuestion(input: $input) {
				question {
					id
					givenAnswers {
						answerOptionId
						freeText
					}
				}
			}
		}
	}
`;
