import tw from "twin.macro";

export const Wrapper = tw.div`
	flex-none
	z-10
	bg-shade-0
	shadow-brand-main-100/10
	shadow-[0rem_-0.125rem_0.625rem_0rem]
`;

export const BottomTextWrapper = tw.div`
    px-16
`;
