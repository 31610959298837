import { Image } from "primereact/image";
import { FC } from "react";
import { brandMain100, shade0 } from "@themes/colors";
import { ImageWithLightboxProps } from "./image-with-lightbox.types";
import { Icon } from "../icon";

export const ImageComponentWithLightbox: FC<ImageWithLightboxProps> = ({
	src,
	alt,
	width,
	height,
}) => {
	return (
		<Image
			src={src}
			alt={alt}
			closeIcon={CustomCloseIcon}
			preview
			width={width}
			height={height}
		/>
	);
};

const CustomCloseIcon = () => {
	return <Icon icon="close" sizeInRem={1} circleOutlineColor={brandMain100} tkaColor={shade0} />;
};
