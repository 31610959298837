/**
 * @generated SignedSource<<6c76c1bfe8293b8ea46739ac7cd1d10b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AsyncElementTaskStatus = "Evaluated" | "Fresh";
import { FragmentRefs } from "relay-runtime";
export type UploadAsyncContentOverview_AsyncElementTaskFragment$data = {
  readonly evaluation?: {
    readonly file: {
      readonly name: string;
      readonly url: string | null;
    } | null;
    readonly text: string | null;
  };
  readonly file?: {
    readonly name: string;
    readonly url: string | null;
  } | null;
  readonly status: AsyncElementTaskStatus;
  readonly " $fragmentType": "UploadAsyncContentOverview_AsyncElementTaskFragment";
};
export type UploadAsyncContentOverview_AsyncElementTaskFragment$key = {
  readonly " $data"?: UploadAsyncContentOverview_AsyncElementTaskFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadAsyncContentOverview_AsyncElementTaskFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "file",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadAsyncContentOverview_AsyncElementTaskFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "FreshUploadAsyncElementTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UploadEvaluation",
          "kind": "LinkedField",
          "name": "evaluation",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "EvaluatedUploadAsyncElementTask",
      "abstractKey": null
    }
  ],
  "type": "AsyncElementTask",
  "abstractKey": "__isAsyncElementTask"
};
})();

(node as any).hash = "294e8da30bfc31a6105e56fbb449587b";

export default node;
