import { createContext } from "react";

type BottomSheetContext = {
	blockBackdrop: boolean;
	setBlockBackdrop: (block: boolean) => void;
};

export const BottomSheetContext = createContext<BottomSheetContext>({
	blockBackdrop: false,
	setBlockBackdrop: () => {},
});
