import styled from "styled-components";
import tw from "twin.macro";
import { AlternativeNavigationHeightRem } from "@components/AlternativeNavigationOrganism";
import { SideMenu } from "@containers/side-menu";
import { SideMenuContent } from "@containers/side-menu-content";
import { BreakpointInPx } from "@themes/breakpoint";

export const ScreenWrapper = tw.div`
	flex
	flex-col
	h-full
`;

export const HeaderWrapper = tw.div`
	flex
	flex-col
	bg-shade-5
`;

export const HomeButton = tw.div`
	absolute
	left-16
	my-auto
`;

export const AvatarButton = tw.div`
	absolute
	right-16
	my-auto
`;

export const TimelineHeaderWrapper = tw.div`
	p-24
	pt-12
`;

export const StyledSideMenu = tw(SideMenu)`
	overflow-y-hidden
	p-0
	flex-1
`;

export const StyledSideMenuPadding = tw(SideMenuContent)`
	p-24
`;

export const SideMenuContentWrapper = tw.div`
	h-full	
	flex
	flex-col
	gap-24
`;

export const SideMenuContentElement = styled(SideMenuContent)`
	${tw`
		p-0
		flex
		flex-col
		overflow-y-hidden
	`}
	padding-bottom: ${AlternativeNavigationHeightRem}rem;
`;

export const StyledFooterSection = styled(SideMenuContent)`
	${tw`
		absolute
		bottom-0
		left-0
		right-0
		p-0
		max-w-none
	`}
	@media screen and (min-width: ${BreakpointInPx.xlarge}px) {
		grid-row: 4 / -1;
	}
`;

export const AccountWrapper = tw.div`	
	flex
	flex-col
	flex-1
	justify-end
`;

export const SkeletonCardsWrapper = tw.div`
	flex
	flex-col
	mt-24
	gap-16
`;
