import tw from "twin.macro";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";

export const ElementWrapper = tw(SideMenuContentPadding)`
    flex
    flex-col
    min-h-full
    flex-1    
`;

export const Wrapper = tw.div`
    grid
    grid-cols-12
`;
export const TextWrapper = tw.div`col-span-12 mt-40`;

export const PlayerWrapper = tw.div`
    col-span-12
    md:col-span-8
    md:col-start-3
    xl:col-span-6
    xl:col-start-4
    xxl:col-span-6
    xxl:col-start-4
`;

export const ImageWrapper = tw.div`
    flex
    flex-col
    justify-center
    items-center
    max-h-[40vh]
    overflow-hidden
`;
