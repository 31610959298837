import styled from "styled-components";
import tw from "twin.macro";

export const ParentWrapper = styled.div<{ width?: number }>`
	${tw`
		flex
		flex-col
		gap-16
		p-24
		bg-shade-100
		bg-opacity-90
		rounded-10
		overflow-y-auto 
	`}
	width: ${({ width }) => (width !== undefined ? `${width}rem` : "initial")};
`;

export const HeaderWrapper = tw.div`
	relative
	flex
	justify-center
`;

export const BackWrapper = tw.div`
 	absolute
	left-0
	h-full
	flex
	items-center
`;
