import { ZonedDateTime } from "@js-joda/core";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { Suspense, useContext } from "react";
import { useSelector } from "react-redux";
import { useFragment, useMutation, usePreloadedQuery } from "react-relay";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { InputUser } from "@components/input-user";
import { Label } from "@components/label";
import { LinkDisplay } from "@components/link-display";
import { ParticipantPreview } from "@components/participant-preview";
import { appointmentDetails_AddParticipantToMyBookingMutation } from "@relay/appointmentDetails_AddParticipantToMyBookingMutation.graphql";
import { appointmentDetails_QueryFragment$key } from "@relay/appointmentDetails_QueryFragment.graphql";
import { appointmentDetails_RemoveParticipantFromBookingAsCoachMutation } from "@relay/appointmentDetails_RemoveParticipantFromBookingAsCoachMutation.graphql";
import { appointmentDetails_RemoveParticipantFromMyBookingMutation } from "@relay/appointmentDetails_RemoveParticipantFromMyBookingMutation.graphql";
import { AppointmentsScreenContext } from "@screens/appointments/appointments.context";
import { selectCurrentUserId } from "@slices/CurrentUserSlice";
import { shade80 } from "@themes/colors";
import { TkaH3Span, TkaP2Span } from "@themes/font-tags";
import {
	AppointmentStatus,
	formatAppointmentDateTimeRange,
	getAppointmentStatus,
} from "@utils/appointment-utils";
import { stripHtml } from "@utils/strip-html";
import {
	ADD_PARTICIPANT_TO_MY_BOOKING_MUTATION,
	AVAILABILITY_FRAGMENT,
	QUERY_FRAGMENT,
	REMOVE_PARTICIPANT_FROM_BOOKING_AS_COACH_MUTATION,
	REMOVE_PARTICIPANT_FROM_MY_BOOKING_MUTATION,
} from "./appointment-details.graphql";
import {
	ButtonsWrapper,
	DetailsParticipantsWrapper,
	DetailsTitle,
	DetailsWrapper,
	HideOnMobile,
	IconContentWrapper,
	ParticipantsGroupTitleWrapper,
	ParticipantsGroupWrapper,
	ParticipantsIconWrapper,
	ParticipantsListWrapper,
	ParticipantsWithIconWrapper,
	ParticipantsWrapper,
	RightButtonsWrapper,
	RowWrapper,
	Wrapper,
} from "./appointment-details.styles";
import { AppointmentDetailsProps } from "./appointment-details.types";
import { QUERY } from "../../appointment-overview-modal.graphql";

// TODO: add-translations
export const AppointmentDetails = ({
	onClose,
	onCancelAppointmentAsParticipant,
	onCancelAppointmentAsCoach,
	availabilityFragmentRef,
	queryFragmentRef,
}: AppointmentDetailsProps) => {
	const query = usePreloadedQuery(QUERY, queryFragmentRef);
	const queryFragment = useFragment<appointmentDetails_QueryFragment$key>(QUERY_FRAGMENT, query);

	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const [addParticipantToMyBooking] =
		useMutation<appointmentDetails_AddParticipantToMyBookingMutation>(
			ADD_PARTICIPANT_TO_MY_BOOKING_MUTATION,
		);

	const [removeParticipantFromMyBooking] =
		useMutation<appointmentDetails_RemoveParticipantFromMyBookingMutation>(
			REMOVE_PARTICIPANT_FROM_MY_BOOKING_MUTATION,
		);

	const [removeParticipantFromBookingAsCoach] =
		useMutation<appointmentDetails_RemoveParticipantFromBookingAsCoachMutation>(
			REMOVE_PARTICIPANT_FROM_BOOKING_AS_COACH_MUTATION,
		);

	const userId = useSelector(selectCurrentUserId);

	const { refreshMyAppointments } = useContext(AppointmentsScreenContext);

	const isCoach = availability.coachProfile?.coach?.id === userId;

	const msTeamsLink = availability.booking.msTeamsLink;

	const status = getAppointmentStatus(
		availability.data.startDateTime,
		availability.data.endDateTime,
	);

	const handleJoinOnClick = () => {
		window.open(msTeamsLink, "_blank", "noreferrer");
		onClose?.();
	};

	const handleAddUserOnClick = (id: string) => {
		addParticipantToMyBooking({
			variables: {
				input: {
					availabilityId: availability.id,
					participantId: id,
				},
			},
		});
	};

	const createRemoveUserOnClickHandler = (id: string) => () => {
		const removeParticipantMutation = isCoach
			? removeParticipantFromBookingAsCoach
			: removeParticipantFromMyBooking;
		removeParticipantMutation({
			variables: {
				input: {
					availabilityId: availability.id,
					participantId: id,
				},
			},
			onCompleted: () => {
				if (id === userId) {
					refreshMyAppointments?.();
					onClose?.();
				}
			},
		});
	};

	const handleOnCancelAppointment = () => {
		if (availability.coachProfile?.coach?.id === userId) {
			onCancelAppointmentAsCoach?.();
		} else {
			onCancelAppointmentAsParticipant?.();
		}
	};

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			isCoach,
			"long",
		);
	};

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];
	const participantsUsers = participants.map((participant) => participant.user!).filter(Boolean);

	const isGroupCoaching = availability.booking.kind === "BookedForGroup";

	const currentParticipantsCount = participantsUsers.length;
	const maxParticipantsCount = availability.booking.offer?.setting?.maxParticipants ?? 1;
	const availableLicensesCount =
		availability.booking.offer?.licenseAvailability?.numAvailable ?? 0;
	const hasFreeSeats = currentParticipantsCount < maxParticipantsCount;

	const canJoin = status === AppointmentStatus.Running;

	const isAppointmentOwner = participantsUsers[0]?.id === userId;

	const hasRightsToAddParticipants =
		isGroupCoaching &&
		isAppointmentOwner &&
		hasFreeSeats &&
		status === AppointmentStatus.Confirmed;

	const canAddParticipant = availableLicensesCount > 0;

	const isInLessThan24Hours = ZonedDateTime.parse(availability.data.startDateTime).isBefore(
		ZonedDateTime.now().plusHours(24),
	);
	const canCancelAppointment =
		status === AppointmentStatus.Confirmed &&
		(isCoach || (isAppointmentOwner && !isInLessThan24Hours));

	const getCanRemoveUser = (id: string) => {
		return (
			isGroupCoaching &&
			status === AppointmentStatus.Confirmed &&
			(isCoach ||
				(id !== userId && isAppointmentOwner) ||
				(!isAppointmentOwner && id === userId))
		);
	};

	const licensesText =
		availableLicensesCount > 0
			? `${availableLicensesCount} x Lizenz${
					availableLicensesCount > 1 ? "en" : ""
			  } verfügbar`
			: "Keine weitere Lizenz verfügbar";

	return (
		<Wrapper>
			<RowWrapper>
				<Icon icon="calendar" sizeInRem={1.5} />
				<TkaP2Span>{renderTime()}</TkaP2Span>
			</RowWrapper>
			<RowWrapper>
				<Icon icon="microsoftTeams" sizeInRem={1.5} />
				<LinkDisplay name="Teams Call" link={msTeamsLink ?? ""} />
			</RowWrapper>
			<DetailsParticipantsWrapper>
				<DetailsWrapper>
					<DetailsTitle>Details</DetailsTitle>
					<IconContentWrapper>
						<Icon icon="paragraphTwoColumn" sizeInRem={1.5} />
						<TkaP2Span tkaColor={shade80}>
							{availability.booking.offer?.description?.description &&
								stripHtml(availability.booking.offer?.description?.description)}
						</TkaP2Span>
					</IconContentWrapper>
				</DetailsWrapper>
				<ParticipantsWithIconWrapper>
					<ParticipantsIconWrapper>
						<Icon icon="participants" sizeInRem={1.5} />
					</ParticipantsIconWrapper>
					<ParticipantsWrapper>
						<ParticipantsGroupWrapper>
							<TkaH3Span>Coach</TkaH3Span>
							<ParticipantsListWrapper>
								<ParticipantPreview
									userFragmentRef={availability.coachProfile?.coach}
								/>
							</ParticipantsListWrapper>
						</ParticipantsGroupWrapper>
						<ParticipantsGroupWrapper>
							<ParticipantsGroupTitleWrapper>
								<TkaH3Span>Teilnehmer</TkaH3Span>
								{isGroupCoaching && (
									<Label
										label={`${currentParticipantsCount}/${maxParticipantsCount}`}
									/>
								)}
							</ParticipantsGroupTitleWrapper>
							<ParticipantsListWrapper>
								{participantsUsers.map((user) => (
									<ParticipantPreview
										key={user.id}
										canBeRemoved={getCanRemoveUser(user.id)}
										userFragmentRef={user}
										onRemoved={createRemoveUserOnClickHandler(user.id)}
									/>
								))}
								{hasRightsToAddParticipants && (
									<Suspense fallback={<Skeleton height="2rem" />}>
										<InputUser
											status={!canAddParticipant ? "disabled" : undefined}
											icon="participants"
											placeholder="Teilnehmer hinzufügen"
											smallTextBelow={licensesText}
											onUserSelected={handleAddUserOnClick}
											queryFragmentRef={queryFragment}
										/>
									</Suspense>
								)}
							</ParticipantsListWrapper>
						</ParticipantsGroupWrapper>
					</ParticipantsWrapper>
				</ParticipantsWithIconWrapper>
			</DetailsParticipantsWrapper>
			<Divider />
			<ButtonsWrapper>
				<HideOnMobile>
					<Button label="Schließen" colorVersion="tertiary" onClick={onClose} />
				</HideOnMobile>
				<RightButtonsWrapper>
					<Button
						fillParent
						disabled={!canCancelAppointment}
						label="Absagen"
						iconName="calendarDisable"
						colorVersion="error"
						onClick={handleOnCancelAppointment}
					/>
					<Button
						fillParent
						disabled={!canJoin}
						label="Beitreten"
						iconName="arrowRight"
						onClick={handleJoinOnClick}
					/>
				</RightButtonsWrapper>
			</ButtonsWrapper>
		</Wrapper>
	);
};
