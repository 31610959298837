import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { shade0 } from "@themes/colors";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { ContentWrapper, IconCircle, Wrapper } from "./licenses-remove-rule-modal.styles";
import { LicensesRemoveRuleModalProps } from "./licenses-remove-rule-modal.types";
import { DialogTemplate } from "../dialog-template";
import { Icon } from "../icon";

export const LicensesRemoveRuleModal = ({
	isVisible,
	content,
	onCancel,
	onSubmit,
}: LicensesRemoveRuleModalProps) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onCancel}>
			<DialogTemplate
				primaryButtonStretch={true}
				primaryButtonLabel="Regel löschen"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={onSubmit}
				secondaryButtonLabel="Abbrechen"
				secondaryButtonStretch={false}
				onSecondaryButtonClick={onCancel}
			>
				<Wrapper>
					<H1Span>Regel wirklich löschen?</H1Span>
					<TkaP2Span>
						Durch das Löschen der Regel gehen dir die folgenden Daten für immer verloren
						und können nicht wiederhergestellt werden:
					</TkaP2Span>
					<ContentWrapper>
						<IconCircle>
							<Icon icon="close" sizeInRem={0.75} tkaColor={shade0} />
						</IconCircle>
						{content}
					</ContentWrapper>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
