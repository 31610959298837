import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query dashboard_Query($kinds: [LearnableKind!]!, $filterByCompleted: Boolean = false) {
		Viewer {
			Auth {
				currentUser {
					user {
						name
						extension(userExtensionKind: Academies) {
							... on AcademiesUserExtension {
								...dashboard_points
								firstName
								lastName
								avatar {
									url
								}
							}
						}
					}
				}
			}
		}
		CustomerJourney {
			Dashboard(kinds: $kinds, filterByCompleted: $filterByCompleted) {
				mostRecentLearnable {
					...mostRecentLearnableCard_mostRecentLearnableFragment
				}
			}
		}
		...learnableGrid_LearnableFragment
			@arguments(kinds: $kinds, filterByCompleted: $filterByCompleted)
	}
`;

export const POINTS_FRAGMENT = graphql`
	fragment dashboard_points on AcademiesUserExtension {
		sumGamificationPoints
	}
`;
