import { useDrag } from "react-dnd";
import { GapTextProps, GapTextStatus } from "./gap-text.interface";
import { Spacer, Text, Wrapper } from "./gap-text.styles";
import { getBackgroundColor, getBorderColor, getTextColor } from "./gap-text.util";

export const GapText = ({
	text,
	status = GapTextStatus.Default,
	selected = false,
	useMinWidth = false,
	onlyBoldIfSelected,
	onClick,
	id,
	draggable,
}: GapTextProps) => {
	const [_, drag] = useDrag(
		() => ({
			type: "Answer",
			item: { id },
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[text],
	);

	const hasText = !!text;
	const backgroundColor = getBackgroundColor(status, hasText);
	const borderColor = getBorderColor(status, hasText, selected);
	const textColor = getTextColor(status);

	const bold = onlyBoldIfSelected ? selected : true;

	return (
		<Wrapper
			ref={draggable ? drag : null}
			backgroundColor={backgroundColor}
			borderColor={borderColor}
			onClick={onClick}
		>
			<Text bold={bold} invisible={!text} tkaColor={textColor}>
				{text ? text : "_"}
			</Text>
			{useMinWidth && <Spacer />}
		</Wrapper>
	);
};
