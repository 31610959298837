import { createContext } from "react";
import { LearnableKind } from "@relay/gridView_Query.graphql";

export type IDashboardScreenContext = {
	filters: {
		kinds: LearnableKind[];
		filterByCompleted: boolean;
	};
	helpers: {
		setKinds: (kinds: LearnableKind[]) => void;
		toggleKind: (kind: LearnableKind) => void;
		toggleFilterByCompleted: () => void;
	};
};
export const DashboardScreenContext = createContext<IDashboardScreenContext>({
	filters: {
		kinds: [],
		filterByCompleted: false,
	},

	helpers: {
		setKinds: (_) => {},
		toggleKind: () => {},
		toggleFilterByCompleted: () => {},
	},
});
