/**
 * @generated SignedSource<<b49ccb895867ae115951bdb93d1fbcf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type myDocuments_Query$variables = {};
export type myDocuments_Query$data = {
  readonly Viewer: {
    readonly Coaching: {
      readonly MyDocuments: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"myDocuments_FileInlineFragment">;
      }>;
    };
    readonly Learn: {
      readonly myNodeDocuments: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"myDocuments_FileInlineFragment">;
      }>;
    };
  };
};
export type myDocuments_Query = {
  response: myDocuments_Query$data;
  variables: myDocuments_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnail",
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "myDocuments_FileInlineFragment",
    "selections": (v0/*: any*/),
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "myDocuments_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingViewerSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "MyDocuments",
                "plural": true,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnViewerSchema",
            "kind": "LinkedField",
            "name": "Learn",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "myNodeDocuments",
                "plural": true,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "myDocuments_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingViewerSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "MyDocuments",
                "plural": true,
                "selections": (v0/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnViewerSchema",
            "kind": "LinkedField",
            "name": "Learn",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "myNodeDocuments",
                "plural": true,
                "selections": (v0/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e2ba080e7daf524c1290e8e1454ae1b",
    "id": null,
    "metadata": {},
    "name": "myDocuments_Query",
    "operationKind": "query",
    "text": "query myDocuments_Query {\n  Viewer {\n    Coaching {\n      MyDocuments {\n        ...myDocuments_FileInlineFragment\n        id\n      }\n    }\n    Learn {\n      myNodeDocuments {\n        ...myDocuments_FileInlineFragment\n        id\n      }\n    }\n  }\n}\n\nfragment myDocuments_FileInlineFragment on File {\n  id\n  name\n  url\n  thumbnail\n}\n"
  }
};
})();

(node as any).hash = "47281fc6d31030ef8e0135fbe4650e12";

export default node;
