import { useMatch } from "react-router-dom";
import { BasketTimelineSkeleton } from "@components/basket-timeline";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";
import { Path } from "@router/paths";
import { TkaH2Span } from "@themes/font-tags";

import {
	AccountWrapper,
	SideMenuContentWrapper,
	SkeletonCardsWrapper,
	TimelineHeaderWrapper,
} from "./shopping-cart-template.styles";
import { BottomNavigationTemplateSkeleton } from "../bottom-navigation-template/bottom-navigation-template.skeleton";
import { RootCardSkeleton } from "../root-card/root-card.skeleton";
import { Skeleton } from "../skeleton";

//TODO: add-translations
export const ShoppingCartTemplateSkeleton = () => {
	const { t } = useTkaTranslation("basketScreen");
	const { isXLargeUp } = useWindowSize();

	const currentPath = [
		useMatch(Path.shoppingCart.withIdPlaceholder().products.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().invoice.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().paymentMethod.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().overview.path),
	];

	const currentStepIndex = currentPath.findIndex(Boolean);
	const currentStep = currentStepIndex < 0 ? 1 : currentStepIndex + 1;

	const basketTimeline = (
		<BasketTimelineSkeleton
			currentStep={currentStep}
			orientation={isXLargeUp ? "vertical" : "horizontal"}
		/>
	);

	return (
		<ScreenSidemenuLayout
			headerLeftIconName="close"
			sideMenu={
				<SideMenuContentWrapper>
					<TkaH2Span>{t("basket_screen.basketTimelineTitle")}</TkaH2Span>
					{basketTimeline}
					<AccountWrapper>
						<Skeleton shape="circle" size="2.5rem" />
					</AccountWrapper>
				</SideMenuContentWrapper>
			}
			headerBottomContent={
				!isXLargeUp && <TimelineHeaderWrapper>{basketTimeline}</TimelineHeaderWrapper>
			}
			bottomContent={<BottomNavigationTemplateSkeleton />}
		>
			<Skeleton width="15rem" height="1.5rem" />
			<Skeleton width="85%" className="mt-16" />
			<Skeleton width="30%" className="mt-4" />
			<Skeleton width="60%" className="mt-4" />
			<SkeletonCardsWrapper>
				<RootCardSkeleton />
				<RootCardSkeleton />
				<RootCardSkeleton />
			</SkeletonCardsWrapper>
		</ScreenSidemenuLayout>
	);
};
