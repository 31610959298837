import graphql from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT = graphql`
	fragment dimensionSection_AVGSPotentialAnalysisDimensionFragment on AVGSPotentialAnalysis_Dimension {
		dimensionId
		dimensionText
		questions {
			id
			kind
			... on PotentialAnalysisMultipleChoiceQuestion {
				answerOptions {
					answerOptionId
				}
				givenAnswers {
					kind
					answerOptionId
				}
			}
			... on PotentialAnalysisMultipleChoiceFreetextQuestion {
				answerOptions {
					answerOptionId
					isFreeText
				}
				givenAnswers {
					kind
					answerOptionId
					freeText
				}
			}
			... on PotentialAnalysisSingleChoiceQuestion {
				answerOptions {
					answerOptionId
				}
				givenAnswer {
					kind
					answerOptionId
				}
			}
			... on PotentialAnalysisSingleChoiceFreetextQuestion {
				answerOptions {
					answerOptionId
					isFreeText
				}
				givenAnswer {
					kind
					answerOptionId
					freeText
				}
			}
			... on PotentialAnalysisScaleQuestion {
				answerOptions {
					answerOptionId
				}
				givenAnswer {
					kind
					answerOptionId
				}
			}
			... on PotentialAnalysisTextQuestion {
				givenAnswer {
					kind
					text
				}
			}
			...questionSection_PotentialAnalysisQuestionFragment
		}
	}
`;
