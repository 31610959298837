import { graphql } from "babel-plugin-relay/macro";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment deleteAccountModalsFlow_AuthViewerSchemaFragment on AuthViewerSchema {
		currentUser {
			accounts {
				id
				name
			}
		}
	}
`;
