import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment privateClientForm_BaseDataFragment on baseData {
		street
		houseNumber
		postalCode
		countryCode
		city
		__typename
		... on PrivateBaseData {
			phoneNumber
			firstName
			lastName
			title
			salutation
		}
	}
`;
