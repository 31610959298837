import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query avgsPotentialAnalysis_Query($id: ID!) {
		AvgsPotentialAnalysis {
			LearnPotentialAnalysis(learnPotentialAnalysisId: $id) {
				id
				modules {
					...moduleSection_AVGSPotentialAnalysisModuleFragment
				}
				isFinished
				...avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment
			}
		}
		Viewer {
			Auth {
				...avatarWithProfileMenu_AuthViewerSchemaFragment
			}
		}
	}
`;
