import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { DialogButtonsDirection } from "@components/dialog-template/dialog-template.types";
import { Divider } from "@components/divider";
import { InputText } from "@components/input-text";
import { Label } from "@components/label";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { editWordNoteModal_AddWordMutation } from "@relay/editWordNoteModal_AddWordMutation.graphql";

import { H1Span } from "@themes/font-tags";
import { ADD_WORD_NOTE_MUTATION, WORD_NOTE_FRAGMENT } from "./edit-word-note-modal.graphql";
import { ModalWrapper, WordModalHeaderWrapper } from "./edit-word-note-modal.styles";
import { EditWordNoteModalProps } from "./edit-word-note-modal.types";

//TODO: add-translations
export const EditWordNoteModal = ({
	isVisible,
	onDismiss,
	language,
	wordNoteFragmentRef,
}: EditWordNoteModalProps) => {
	const wordNote = useFragment(WORD_NOTE_FRAGMENT, wordNoteFragmentRef ?? null);
	const [addWordNote] = useMutation<editWordNoteModal_AddWordMutation>(ADD_WORD_NOTE_MUTATION);

	const [text, setText] = useState<string>(wordNote?.note ?? "");

	const handleSaveNoteOnClick = () => {
		wordNote?.wordId &&
			addWordNote({
				variables: {
					input: { note: text, wordId: wordNote.wordId },
				},
				onCompleted: onDismiss,
			});
	};

	const handleOnChange = (text?: string) => {
		setText(text ?? "");
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				maxWidthRem={22}
				buttonsDirection={DialogButtonsDirection.COLUMN}
				secondaryButtonLabel="Schließen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel="Speichern"
				primaryButtonIconName="arrowRight"
				onPrimaryButtonClick={handleSaveNoteOnClick}
			>
				<ModalWrapper>
					<WordModalHeaderWrapper>
						<H1Span>Meine Notiz</H1Span>
						<Label label={language} />
					</WordModalHeaderWrapper>

					<InputText
						textArea
						placeholder="Deine Notizen..."
						value={text}
						onChange={handleOnChange}
					/>
				</ModalWrapper>
				<Divider />
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
