import { AppointmentCardSkeleton } from "@components/appointment-card/appointment-card.skeleton";
import { ScreenWithNavbarLayoutSkeleton } from "@layouts/screen-with-navbar-layout/screen-with-navbar-layout.skeleton";

export const BaseScreenSkeleton = () => {
	return (
		<ScreenWithNavbarLayoutSkeleton>
			<div className="flex flex-wrap gap-24">
				<AppointmentCardSkeleton />
				<AppointmentCardSkeleton />
				<AppointmentCardSkeleton />
				<AppointmentCardSkeleton />
				<AppointmentCardSkeleton />
				<AppointmentCardSkeleton />
				<AppointmentCardSkeleton />
			</div>
		</ScreenWithNavbarLayoutSkeleton>
	);
};
