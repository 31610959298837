/**
 * @generated SignedSource<<61819c62eca545cdcc1183fbf2fe270e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type placement_Query$variables = {
  skip: boolean;
};
export type placement_Query$data = {
  readonly Viewer: {
    readonly PotentialAnalysis: {
      readonly MyPotentialAnalysisResult: {
        readonly isLongAnalysis: boolean;
      } | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"infoModalCorporate_QueryFragment">;
};
export type placement_Query = {
  response: placement_Query$data;
  variables: placement_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerQueryType",
  "kind": "LinkedField",
  "name": "Viewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PotentialAnalysisViewerSchema",
      "kind": "LinkedField",
      "name": "PotentialAnalysis",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisResult",
          "kind": "LinkedField",
          "name": "MyPotentialAnalysisResult",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isLongAnalysis",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "placement_Query",
    "selections": [
      (v1/*: any*/),
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "infoModalCorporate_QueryFragment"
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "placement_Query",
    "selections": [
      (v1/*: any*/),
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlacementQueries",
            "kind": "LinkedField",
            "name": "Placement",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissionCartClickOut",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "fafa54ea6501b39eed416c0bae6f00ad",
    "id": null,
    "metadata": {},
    "name": "placement_Query",
    "operationKind": "query",
    "text": "query placement_Query(\n  $skip: Boolean!\n) {\n  Viewer {\n    PotentialAnalysis {\n      MyPotentialAnalysisResult {\n        isLongAnalysis\n      }\n    }\n  }\n  ...infoModalCorporate_QueryFragment @skip(if: $skip)\n}\n\nfragment infoModalCorporate_QueryFragment on Query {\n  Placement {\n    permissionCartClickOut\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef4c5ce5162d9cc40b6b29dde91bc38f";

export default node;
