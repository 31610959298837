import { DayOfWeek, LocalTime } from "@js-joda/core";
import { epicPrimary20 } from "@thekeytechnology/epic-ui";
import moment from "moment-timezone";
import { TabPanel } from "primereact/tabview";
import {
	Fragment,
	MouseEventHandler,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { Label } from "@components/label";
import { Message } from "@components/message";
import { MessageProps } from "@components/message/message.types";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { TabBar } from "@components/tab-bar";
import { withSuspense } from "@components/with-suspense/with-suspense.component";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { myAvailabilities_AvailabilityScheduleFragment$key } from "@relay/myAvailabilities_AvailabilityScheduleFragment.graphql";
import {
	myAvailabilities_EditMyAvailabilityScheduleMutation,
	ScheduleDataInput,
} from "@relay/myAvailabilities_EditMyAvailabilityScheduleMutation.graphql";
import { myAvailabilities_OverrideDaysAndTimesFragment$key } from "@relay/myAvailabilities_OverrideDaysAndTimesFragment.graphql";
import { myAvailabilities_Query } from "@relay/myAvailabilities_Query.graphql";
import { PermanentActionsButton } from "@screens/account/parts/account-tab/account-tab.styles";
import {
	addOffset,
	removeOffset,
	sortByCalendarWeeks,
	toTimeSlotsMap,
} from "@screens/my-availabilities/my-availabilities.utils";
import { ExceptionContextMenu } from "@screens/my-availabilities/parts/exception-context-menu";
import { error100 } from "@themes/colors";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import {
	AVAILABILITY_SCHEDULE_FRAGMENT,
	EDIT_MY_AVAILABILITY_SCHEDULE_MUTATION,
	OVERRIDE_DAYS_AND_TIMES_FRAGMENT,
	QUERY,
} from "./my-availabilities.graphql";
import { MyAvailabilitiesScreenSkeleton } from "./my-availabilities.skeleton";
import {
	AddBlockoutDateButtonWrapper,
	ExceptionsLabelWrapper,
	ExceptionsTab,
	ExceptionsTabAndMessageWrapper,
	TabWrapper,
	TitleWrapper,
	Wrapper,
} from "./my-availabilities.styles";
import {
	CalendarWeekAndYear,
	DaysAndTimes,
	OverrideDaysAndTimesForCalendarWeek,
	TimeSlotsMap,
} from "./my-availabilities.types";
import { BlockoutDateCard } from "./parts/blockout-date-card";
import { BlockoutDateModal } from "./parts/blockout-date-modal";
import { DaySchedule } from "./parts/day-schedule";
import { ExceptionModal } from "./parts/exception-modal/exception-modal.component";

// TODO: add-translations
const MyAvailabilitiesScreenComponent = () => {
	const [scheduleId, setScheduleId] = useState<string>();
	const {
		Viewer: { Coaching },
	} = useLazyLoadQuery<myAvailabilities_Query>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network", fetchKey: scheduleId },
	);

	const scheduleData = useFragment<myAvailabilities_AvailabilityScheduleFragment$key>(
		AVAILABILITY_SCHEDULE_FRAGMENT,
		Coaching.MyAvailabilitySchedule,
	);

	const exceptions = useFragment<myAvailabilities_OverrideDaysAndTimesFragment$key>(
		OVERRIDE_DAYS_AND_TIMES_FRAGMENT,
		scheduleData?.data.overrideDaysAndTimesForCalendarWeek ?? null,
	);
	const mutableExceptions = useMemo(() => {
		return (
			addOffset(
				exceptions?.map((e) => ({
					calendarWeek: e.calendarWeek,
					calendarYear: e.calendarYear,
					timeSlots: [...e.timeSlots],
					dayOfWeek: e.dayOfWeek,
				})) ?? [],
			) ?? []
		);
	}, [exceptions]);

	const [editMyAvailabilitySchedule] =
		useMutation<myAvailabilities_EditMyAvailabilityScheduleMutation>(
			EDIT_MY_AVAILABILITY_SCHEDULE_MUTATION,
		);

	const [reocurringDaysAndTimes, setReocurringDaysAndTimes] = useState<TimeSlotsMap>(
		toTimeSlotsMap(
			addOffset(
				scheduleData?.data.daysAndTimes.map((e) => ({
					...e,
					timeSlots: [...e.timeSlots],
				})) ?? [],
			),
		),
	);

	const [calendarWeekDayAndTimes, setCalendarWeekDayAndTimes] = useState<
		TimeSlotsMap | undefined
	>();

	const [selectedCalendarWeekAndYear, setSelectedCalendarWeekAndYear] = useState<
		{ week: number; year: number } | undefined | null
	>();
	const selectedCalendarWeekAndYearIsInPast = useMemo(() => {
		const currentCalendarWeek = moment().isoWeek();
		const currentYear = new Date().getFullYear();
		const currentCalendarWeekAndYear: CalendarWeekAndYear = {
			calendarWeek: currentCalendarWeek,
			calendarYear: currentYear,
		};
		if (!selectedCalendarWeekAndYear) return undefined;
		if (selectedCalendarWeekAndYear.year > currentCalendarWeekAndYear.calendarYear) return true;
		return selectedCalendarWeekAndYear.week < currentCalendarWeekAndYear.calendarWeek;
	}, [selectedCalendarWeekAndYear]);

	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);
	const [createNum, setCreateNum] = useState(1);
	const [isVisible, setIsVisible] = useState(false);
	const [exceptionModalIsVisible, setExceptionModalIsVisible] = useState(false);

	const calendarWeekAndYears: CalendarWeekAndYear[] = useMemo(
		() =>
			Array.from(
				new Map(
					(exceptions ?? []).map((item) => [
						item.calendarWeek + "-" + item.calendarYear,
						{
							calendarWeek: item.calendarWeek,
							calendarYear: item.calendarYear,
						},
					]),
				).values(),
			),
		[exceptions],
	);

	const { isMediumUp } = useWindowSize();

	useEffect(() => {
		setReocurringDaysAndTimes(
			toTimeSlotsMap(
				addOffset(
					scheduleData?.data.daysAndTimes.map((e) => ({
						...e,
						timeSlots: [...e.timeSlots],
					})) ?? [],
				),
			),
		);
	}, [scheduleData?.data.daysAndTimes]);

	useEffect(() => {
		const selectedExceptionCalendarWeek = mutableExceptions?.filter(
			(exception) =>
				exception.calendarWeek === selectedCalendarWeekAndYear?.week &&
				exception.calendarYear === selectedCalendarWeekAndYear?.year,
		);
		if (!selectedExceptionCalendarWeek) return;

		// get with offset
		const daysAndTimesWithOffsets = toTimeSlotsMap(selectedExceptionCalendarWeek);

		// set state
		setCalendarWeekDayAndTimes(daysAndTimesWithOffsets);
	}, [selectedCalendarWeekAndYear]);

	const handleOnSave = (scheduleData: ScheduleDataInput) => {
		const input = {
			daysAndTimes: removeOffset(
				scheduleData.daysAndTimes.map((e) => ({ ...e, timeSlots: [...e.timeSlots] })) ?? [],
			),
			blockoutDates: scheduleData.blockoutDates,
			overrideDaysAndTimesForCalendarWeek: removeOffset(
				scheduleData.overrideDaysAndTimesForCalendarWeek.map((e) => ({
					...e,
					timeSlots: [...e.timeSlots],
				})) ?? [],
			),
		};
		editMyAvailabilitySchedule({
			variables: {
				input: {
					scheduleData: { ...input },
				},
			},
			onCompleted: (data) => {
				if (scheduleId) return;
				setScheduleId(data.Coaching.editMyAvailabilitySchedule?.schedule.id);
			},
		});
	};

	const prepareScheduleData = useCallback(() => {
		const daysAndTimes: DaysAndTimes[] = [];
		Object.keys(reocurringDaysAndTimes).forEach((dayOfWeek) => {
			daysAndTimes.push({
				dayOfWeek,
				timeSlots: reocurringDaysAndTimes[dayOfWeek].map((timeSlot) => timeSlot.toString()),
			});
		});
		const blockoutDates =
			scheduleData?.data.blockoutDates.map(({ from, to }) => ({
				from,
				to,
			})) ?? [];

		const returned = {
			daysAndTimes,
			blockoutDates,
			overrideDaysAndTimesForCalendarWeek: mutableExceptions,
		};
		return returned;
	}, [reocurringDaysAndTimes, scheduleData?.data.blockoutDates, mutableExceptions]);

	const createReocurringDaysAndTimesOnChangeHandler = useCallback(
		(dayOfWeek: DayOfWeek) => (timeSlots?: LocalTime[]) => {
			const scheduleData = prepareScheduleData();

			scheduleData.daysAndTimes = scheduleData.daysAndTimes.filter(
				(daySchedule) => !dayOfWeek.equals(DayOfWeek.valueOf(daySchedule.dayOfWeek)),
			);

			if (timeSlots) {
				scheduleData.daysAndTimes.push({
					dayOfWeek: dayOfWeek.name(),
					timeSlots: timeSlots?.map((localTime) => localTime.toString()),
				});
			}

			setReocurringDaysAndTimes({
				...reocurringDaysAndTimes,
				[dayOfWeek.name()]: timeSlots ?? [],
			});

			handleOnSave(scheduleData);
		},
		[[reocurringDaysAndTimes, scheduleData?.data.blockoutDates, mutableExceptions]],
	);

	const createCalendarWeekDayAndTimesOnChangeHandler = useCallback(
		(dayOfWeek: DayOfWeek) => (timeSlots?: LocalTime[]) => {
			if (!selectedCalendarWeekAndYear) return;
			const scheduleDataInput = prepareScheduleData();

			const existingOverrideForWeekAndDayIndex =
				scheduleDataInput.overrideDaysAndTimesForCalendarWeek.findIndex(
					(e) =>
						e.calendarWeek === selectedCalendarWeekAndYear.week &&
						e.calendarYear === selectedCalendarWeekAndYear.year &&
						e.dayOfWeek === dayOfWeek.name(),
				);
			if (timeSlots) {
				const override = {
					calendarWeek: selectedCalendarWeekAndYear!.week,
					calendarYear: selectedCalendarWeekAndYear!.year,
					dayOfWeek: dayOfWeek.name(),
					timeSlots: timeSlots.map((localTime) => localTime.toString()),
				};

				if (existingOverrideForWeekAndDayIndex >= 0) {
					scheduleDataInput.overrideDaysAndTimesForCalendarWeek[
						existingOverrideForWeekAndDayIndex
					] = override;
				} else {
					scheduleDataInput.overrideDaysAndTimesForCalendarWeek.push(override);
				}
			} else if (existingOverrideForWeekAndDayIndex >= 0) {
				scheduleDataInput.overrideDaysAndTimesForCalendarWeek.splice(
					existingOverrideForWeekAndDayIndex,
					1,
				);
			}

			setCalendarWeekDayAndTimes({
				...calendarWeekDayAndTimes,
				[dayOfWeek.name()]: timeSlots ?? [],
			});

			handleOnSave(scheduleDataInput);
		},
		[
			[
				calendarWeekDayAndTimes,
				reocurringDaysAndTimes,
				scheduleData?.data.blockoutDates,
				mutableExceptions,
			],
		],
	);
	const handleBlockoutDateOnCreate = useCallback(
		(from: Date, to: Date) => {
			const scheduleData = prepareScheduleData();
			scheduleData.blockoutDates.push({ from: from.toISOString(), to: to.toISOString() });

			handleOnSave(scheduleData);
		},
		[reocurringDaysAndTimes, scheduleData?.data.blockoutDates, mutableExceptions],
	);

	const createBlockoutDateOnChangeHandler = useCallback(
		(index: number) => (from?: Date, to?: Date) => {
			const scheduleData = prepareScheduleData();
			if (!scheduleData.blockoutDates[index]) return;
			if (!from || !to) {
				scheduleData.blockoutDates.splice(index, 1);
			} else {
				scheduleData.blockoutDates[index] = {
					from: from.toISOString(),
					to: to.toISOString(),
				};
			}
			handleOnSave(scheduleData);
		},
		[reocurringDaysAndTimes, scheduleData?.data.blockoutDates, mutableExceptions],
	);

	const handleAddBlockoutDateOnClick = () => {
		setCreateNum(createNum + 1);
		setIsVisible(true);
	};

	const handleOnDismiss = () => {
		setIsVisible(false);
	};

	const handleAddExceptionOnClick = () => {
		setExceptionModalIsVisible(true);
	};

	const handleExceptionModalOnDismiss = () => {
		setExceptionModalIsVisible(false);
	};

	const createCalendarWeekOnClickHandler = useCallback(
		(calendarWeekWithYear: { week: number; year: number }) => () => {
			setSelectedCalendarWeekAndYear({
				week: calendarWeekWithYear.week,
				year: calendarWeekWithYear.year,
			});
			const overrides = mutableExceptions.filter(
				(item) =>
					item.calendarWeek === calendarWeekWithYear.week &&
					item.calendarYear === calendarWeekWithYear.year,
			);

			const map = toTimeSlotsMap(overrides);
			setCalendarWeekDayAndTimes(map);
		},
		[mutableExceptions],
	);

	const ReocrurringSchedule = useMemo(
		() =>
			DayOfWeek.values().map((day) => (
				<DaySchedule
					key={`${day.name()}-${selectedCalendarWeekAndYear?.week}`}
					dayOfWeek={day}
					timeSlots={reocurringDaysAndTimes[day.name()]}
					onChange={createReocurringDaysAndTimesOnChangeHandler(day)}
				/>
			)),
		[reocurringDaysAndTimes, calendarWeekDayAndTimes, mutableExceptions],
	);

	const ExceptionSchedule = useMemo(
		() =>
			selectedCalendarWeekAndYear ? (
				DayOfWeek.values().map((day) => (
					<DaySchedule
						key={`${day.name()}-${selectedCalendarWeekAndYear?.week}-${
							selectedCalendarWeekAndYear?.year
						}`}
						dayOfWeek={day}
						timeSlots={calendarWeekDayAndTimes?.[day.name()]}
						onChange={createCalendarWeekDayAndTimesOnChangeHandler(day)}
					/>
				))
			) : (
				<Fragment />
			),
		[calendarWeekDayAndTimes, selectedCalendarWeekAndYear, mutableExceptions],
	);

	const handleOnSubmit = useCallback(
		(overrides: OverrideDaysAndTimesForCalendarWeek[]) => {
			const scheduleDataInput = prepareScheduleData();
			handleOnSave({
				...scheduleDataInput,
				overrideDaysAndTimesForCalendarWeek: [
					...scheduleDataInput.overrideDaysAndTimesForCalendarWeek,
					...overrides,
				],
			});
		},
		[reocurringDaysAndTimes, scheduleData, mutableExceptions],
	);
	const handleClearSelectedCalendarWeek = () => {
		setSelectedCalendarWeekAndYear(undefined);
		setCalendarWeekDayAndTimes(undefined);
	};
	const calculateSeverity = useCallback(
		(info: "reocurring" | "exception", selection?: CalendarWeekAndYear) => {
			if (info === "reocurring")
				return !selectedCalendarWeekAndYear ? "brandMain" : "default";
			if (info === "exception" && selection && selectedCalendarWeekAndYear)
				return selection.calendarWeek === selectedCalendarWeekAndYear?.week &&
					selection.calendarYear === selectedCalendarWeekAndYear.year
					? "brandMain"
					: "default";

			return "default";
		},
		[selectedCalendarWeekAndYear],
	);
	const formatCalendarWeekForMessage = (calendarWeek: CalendarWeekAndYear) =>
		`KW${calendarWeek.calendarWeek}`;
	const reoccuringMessageProps: MessageProps = useMemo(
		() => ({
			summary: "Einstellungen werden überschrieben",
			detail: `Die Einstellungen werden von ${sortByCalendarWeeks(calendarWeekAndYears)
				.map(formatCalendarWeekForMessage)
				.join(", ")} überschrieben.`,
			severity: "neutral",
		}),
		[calendarWeekAndYears],
	);

	const message = useMemo(() => {
		if (selectedCalendarWeekAndYear && selectedCalendarWeekAndYearIsInPast)
			return (
				<Message
					severity={"warn"}
					summary={"Die Kalenderwoche liegt in der Vergangenheit."}
				/>
			);
		if (selectedCalendarWeekAndYear) return <Fragment />;

		if (exceptions?.length && !selectedCalendarWeekAndYear)
			return <Message {...reoccuringMessageProps} />;
		return <Fragment />;
	}, [
		exceptions,
		selectedCalendarWeekAndYear,
		selectedCalendarWeekAndYearIsInPast,
		reoccuringMessageProps,
	]);

	const handleOnShowContextMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
		overlayRef.current?.toggle(event, event.currentTarget);
	};
	const handleDeleteExceptionBySelectedCalendarWeek = useCallback(() => {
		const scheduleDataInput = prepareScheduleData();
		const filteredExceptions = scheduleDataInput.overrideDaysAndTimesForCalendarWeek.filter(
			(exception) => {
				if (!selectedCalendarWeekAndYear) return true;
				const hasSameYear = exception.calendarYear === selectedCalendarWeekAndYear?.year;
				const hasSameWeek = exception.calendarWeek === selectedCalendarWeekAndYear.week;
				if (hasSameWeek && hasSameYear) return false;
				return true;
			},
		);
		handleOnSave({
			...scheduleDataInput,
			overrideDaysAndTimesForCalendarWeek: [...filteredExceptions],
		});
	}, [selectedCalendarWeekAndYear, mutableExceptions, reocurringDaysAndTimes, scheduleData]);
	return (
		<>
			<ScreenWithProfileMenuLayout
				canGoBack={!isMediumUp}
				bottomContent={
					<BottomNavigationTemplate
						actionButtonLabel={"Ausnahme hinzufügen"}
						actionButtonColorVersion="outline"
						actionButtonIconName="calendar"
						onActionButtonClick={handleAddExceptionOnClick}
					/>
				}
			>
				<Wrapper>
					<TitleWrapper>
						<H1Span>Meine Verfügbarkeiten</H1Span>
						<TkaP2Span>
							Lege deine Verfügbarkeiten so an, dass du dich damit wohlfühlst und
							optimal arbeiten kannst.
						</TkaP2Span>
					</TitleWrapper>
					<TabBar renderActiveOnly={true}>
						<TabPanel header="Verfügbarkeiten">
							<TabWrapper>
								<ExceptionsTabAndMessageWrapper>
									<ExceptionsTab>
										<ExceptionsLabelWrapper>
											<Label
												label="Wiederkehrend"
												severity={calculateSeverity("reocurring")}
												size="big"
												onClick={handleClearSelectedCalendarWeek}
											/>
										</ExceptionsLabelWrapper>

										{sortByCalendarWeeks(calendarWeekAndYears).map((item) => (
											<ExceptionsLabelWrapper
												key={`${item.calendarWeek}-${item.calendarYear}`}
											>
												<Label
													label={`KW ${item.calendarWeek} ${item.calendarYear}`}
													severity={calculateSeverity("exception", item)}
													size="big"
													onClick={createCalendarWeekOnClickHandler({
														week: item.calendarWeek,
														year: item.calendarYear,
													})}
													{...(selectedCalendarWeekAndYearIsInPast &&
													item.calendarWeek ===
														selectedCalendarWeekAndYear?.week &&
													item.calendarYear ===
														selectedCalendarWeekAndYear?.year
														? { lineThroughColor: epicPrimary20 }
														: {})}
												/>
											</ExceptionsLabelWrapper>
										))}
									</ExceptionsTab>
									{message}
								</ExceptionsTabAndMessageWrapper>

								{!selectedCalendarWeekAndYear
									? ReocrurringSchedule
									: ExceptionSchedule}

								{selectedCalendarWeekAndYear && (
									<>
										<Divider />
										<PermanentActionsButton onClick={handleOnShowContextMenu}>
											<Icon
												icon="contextMenu"
												sizeInRem={1.5}
												tkaColor={error100}
											/>
											Permanente Aktionen
										</PermanentActionsButton>
									</>
								)}
							</TabWrapper>
						</TabPanel>
						<TabPanel header="Abwesenheit & Urlaub">
							<TabWrapper>
								{scheduleData?.data.blockoutDates.map((blockoutDate, index) => (
									<BlockoutDateCard
										onChange={createBlockoutDateOnChangeHandler(index)}
										onDelete={createBlockoutDateOnChangeHandler(index)}
										key={blockoutDate.from + blockoutDate.to}
										blockoutDateFragmentRef={blockoutDate}
									/>
								))}
								<AddBlockoutDateButtonWrapper>
									<Button
										colorVersion="tertiary"
										label="Hinzufügen"
										iconName="add"
										onClick={handleAddBlockoutDateOnClick}
									/>
								</AddBlockoutDateButtonWrapper>
							</TabWrapper>
						</TabPanel>
					</TabBar>
					<BlockoutDateModal
						key={createNum}
						isVisible={isVisible}
						onDismiss={handleOnDismiss}
						onCreate={handleBlockoutDateOnCreate}
					/>
					{exceptionModalIsVisible && (
						<ExceptionModal
							isVisible={exceptionModalIsVisible}
							onDismiss={handleExceptionModalOnDismiss}
							queryFragmentRef={Coaching.FreeCalendarWeeks}
							overrideDayAndTimesFragmentRef={
								scheduleData?.data.overrideDaysAndTimesForCalendarWeek
							}
							onSubmit={handleOnSubmit}
						/>
					)}
				</Wrapper>
			</ScreenWithProfileMenuLayout>
			{selectedCalendarWeekAndYear && (
				<ExceptionContextMenu
					ref={overlayRef}
					calendarWeek={{
						calendarWeek: selectedCalendarWeekAndYear.week,
						calendarYear: selectedCalendarWeekAndYear.year,
					}}
					deleteException={handleDeleteExceptionBySelectedCalendarWeek}
					onSubmit={handleClearSelectedCalendarWeek}
				/>
			)}
		</>
	);
};

export const MyAvailabilitiesScreen = withSuspense(
	MyAvailabilitiesScreenComponent,
	MyAvailabilitiesScreenSkeleton,
);
