import { RadioButton } from "primereact/radiobutton";
import styled from "styled-components";
import tw from "twin.macro";
import {
	shade0,
	shade5,
	shade10,
	error100,
	error20,
	brandStrong100,
	TkaColor,
	shade40,
	brandStrong80,
	info100,
	onBrandStrong,
	shade20,
} from "@themes/colors";

export const IconContainer = styled.div<{ iconBackgroundColor?: TkaColor }>`
	${tw`
		w-[1.365rem]
		h-[1.365rem]
		rounded-[0.75rem]
		flex
		items-center
		justify-center
	`}
	background-color: ${({ iconBackgroundColor }) =>
		iconBackgroundColor ? iconBackgroundColor : shade0}
`;

export const StyledRadioButton = styled(RadioButton)`
	box-sizing: border-box;
	width: 1.5rem;
	height: 1.5rem;

	/* Default */
	&.p-radiobutton .p-radiobutton-box {
		width: 1.5rem;
		height: 1.5rem;
		border: none;

		background: transparent;
		box-shadow: 0 0 0 1px ${shade40} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box {
		background: ${brandStrong100};
		box-shadow: none;
		.p-radiobutton-icon {
			background-color: ${onBrandStrong};
			width: 0.5rem;
			height: 0.5rem;
		}
	}

	/* Focus */
	&.p-radiobutton .p-radiobutton-box.p-focus {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${info100} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box.p-focus {
		background: ${brandStrong100};
		.p-radiobutton-icon {
			background-color: ${onBrandStrong};
		}
	}

	/* Hover */
	&.p-radiobutton .p-radiobutton-box:hover {
		background: transparent;
		box-shadow: 0 0 0 1px ${shade20} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box:hover {
		background: ${brandStrong80};
		box-shadow: none;
		.p-radiobutton-icon {
			background-color: ${onBrandStrong};
		}
	}

	/* Disabled */
	&.p-radiobutton .p-radiobutton-box.p-disabled {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${shade10} inset;
	}

	&.p-radiobutton.p-radiobutton-checked .p-radiobutton-box.p-disabled {
		background: ${shade5};
		box-shadow: none;
		.p-radiobutton-icon {
			background-color: ${shade20};
		}
	}
	&.p-radiobutton.p-radiobutton-disabled:hover {
		cursor: default !important;
	}

	/* Error */
	&.p-radiobutton.p-invalid .p-radiobutton-box {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${error100} inset;
	}

	&.p-radiobutton.p-radiobutton-checked.p-invalid .p-radiobutton-box {
		background: ${error20};
		.p-radiobutton-icon {
			background-color: ${shade0};
		}
	}
	&.p-radiobutton.p-radiobutton-checked.p-invalid .p-radiobutton-box:hover {
		box-shadow: 0 0 0 1px ${error100} inset;
	}
`;
