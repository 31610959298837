import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-row
`;

export const AvatarsWrapper = styled.div<{
	firstItem: boolean;
	sizeInRem: number;
	zIndex: number;
}>`
	${({ firstItem, sizeInRem }) => (!firstItem ? `margin-left: -${sizeInRem * 0.3}rem;` : "")}
	${({ zIndex }) => `z-index: ${zIndex}`}
`;
