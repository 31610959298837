import { FC } from "react";
import { DialogTemplate } from "@components/dialog-template";
import { DialogButtonsDirection } from "@components/dialog-template/dialog-template.types";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { success0, success100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { SuccessModalProps, SuccessfulInvite } from "./success-invite.types";

const getSuccessContent = (
	successType: SuccessfulInvite,
	numberOfNewAccountMembers: number,
	numberOfUpdatedAccountMembers: number,
) => {
	const newAccountMembers =
		numberOfNewAccountMembers === 1
			? `${numberOfNewAccountMembers}`
			: `${numberOfNewAccountMembers}`;

	const updatedAccountMembers =
		numberOfUpdatedAccountMembers === 1
			? `${numberOfUpdatedAccountMembers} vorhandener`
			: `${numberOfUpdatedAccountMembers} vorhandene`;

	const newAccountMembersSuffix = numberOfNewAccountMembers === 1 ? "wurde" : "wurden";
	const updatedAccountMembersSuffix = numberOfUpdatedAccountMembers === 1 ? "wurde" : "wurden";
	const updatedMembersSuffix = numberOfUpdatedAccountMembers === 1 ? "war" : "waren";
	switch (successType) {
		case SuccessfulInvite.INVITEUSERS:
			return {
				subtitle: "Benutzerkonten erfolgreich aktualisiert",
				infotext: (
					<>
						<TkaP2Span bold>{newAccountMembers} Benutzer </TkaP2Span>
						{newAccountMembersSuffix} erfolgreich eingeladen.
						<br />
						{numberOfUpdatedAccountMembers > 0 && (
							<>
								<TkaP2Span bold>{numberOfUpdatedAccountMembers}</TkaP2Span>
								<TkaP2Span>
									{updatedAccountMembersSuffix} bereits im Konto.
								</TkaP2Span>
							</>
						)}
					</>
				),
			};
		case SuccessfulInvite.INVITEUSERSWITHGROUP:
			return {
				subtitle: "Aktualisierung der Benutzergruppen abgeschlossen",
				infotext: (
					<>
						<TkaP2Span bold>{newAccountMembers} Benutzer </TkaP2Span>
						<TkaP2Span>
							{newAccountMembersSuffix} erfolgreich eingeladen und den entsprechenden
							Gruppen zugeordnet.{" "}
						</TkaP2Span>
						<br />
						<TkaP2Span bold>{updatedAccountMembers} Benutzer </TkaP2Span>{" "}
						<TkaP2Span>
							{" "}
							{updatedMembersSuffix} den relevanten Gruppen zugewiesen.
						</TkaP2Span>
					</>
				),
			};
	}
};

export const SuccessInviteComponent: FC<SuccessModalProps> = ({
	isVisible,
	inviteMembersResponse,
	onDismiss,
}) => {
	let numberOfNewAccountMembers = 0;
	let numberOfUpdatedAccountMembers = 0;

	if (
		inviteMembersResponse &&
		"newAccountMembers" in inviteMembersResponse &&
		"updatedAccountMembers" in inviteMembersResponse
	) {
		numberOfNewAccountMembers = inviteMembersResponse.newAccountMembers?.length || 0;
		numberOfUpdatedAccountMembers = inviteMembersResponse.updatedAccountMembers?.length || 0;
	} else if (
		inviteMembersResponse?.data &&
		("newInvitationCount" in inviteMembersResponse.data ||
			"groupUpdateUsersInAccountCount" in inviteMembersResponse.data)
	) {
		numberOfNewAccountMembers = inviteMembersResponse.data.newInvitationCount || 0;
		numberOfUpdatedAccountMembers =
			inviteMembersResponse.data.groupUpdateUsersInAccountCount || 0;
	}

	const successContentType =
		numberOfUpdatedAccountMembers > 0
			? SuccessfulInvite.INVITEUSERSWITHGROUP
			: SuccessfulInvite.INVITEUSERS;

	const successContent = getSuccessContent(
		successContentType,
		numberOfNewAccountMembers,
		numberOfUpdatedAccountMembers,
	);

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible}>
			<DialogTemplate
				buttonsDirection={DialogButtonsDirection.COLUMN}
				primaryButtonLabel="Okay"
				primaryButtonStretch
				onPrimaryButtonClick={onDismiss}
			>
				<IconHeadlineButtonTemplate
					iconName="checkCircle"
					iconColor={success100}
					iconBackgroundColor={success0}
					title="Einladungen versendet"
					subtitle={successContent.subtitle}
				/>

				<TkaP2Span>{successContent.infotext}</TkaP2Span>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
