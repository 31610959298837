import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment trendUspBoxes_TrendUspBoxesFragment on Trend {
		trendData {
			advantages {
				heading
				description
				icon
			}
		}
	}
`;
