import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { shade20, shade5, shade100 } from "@themes/colors";
import { TkaP3Label, TkaP2Typography, TkaL1Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	relative
`;

export const StyledLabel = tw(TkaP3Label)`
	block
	mb-4
`;

export const GrowDiv = styled.div`
	${tw`
		flex 
		w-full
	`}
	&.p-input-icon-left > i {
		margin-top: -0.75rem;
	}
	&.p-input-icon-left > .p-inputtext {
		padding-left: 2.5rem;
	}
`;

const InputCSS = css<{ error: boolean; success: boolean }>`
	${tw`w-full`}
	${TkaP2Typography}
	color: ${shade100};
	${(props) => {
		if (props.error) {
			return tw`border border-error-100`;
		}
		if (props.success) {
			return tw`border border-success-100`;
		}

		return tw`border border-shade-40`;
	}};
	border-radius: 0.25rem;

	&:disabled {
		background-color: ${shade5};
		color: ${shade20};
		border-style: none;
	}

	&:enabled:hover {
		${(props) => {
			if (props.error) {
				return tw`border border-error-100`;
			}
			if (props.success) {
				return tw`border border-success-100`;
			}

			return tw`border border-shade-40`;
		}}
		color: ${shade100};
	}

	&:enabled:focus {
		${(props) => {
			if (props.error) {
				return tw`border border-error-100`;
			}
			if (props.success) {
				return tw`border border-success-100`;
			}

			return tw`border border-info-100`;
		}}
		box-shadow: none;
	}

	::-webkit-input-placeholder {
		color: ${shade20};
	}

	:-moz-placeholder {
		color: ${shade20};
	}

	::-moz-placeholder {
		color: ${shade20};
	}

	:-ms-input-placeholder {
		color: ${shade20};
	}
`;

export const StyledInputText = styled(InputText)<{
	error: boolean;
	success: boolean;
}>`
	${InputCSS}
`;

export const StyledInputTextarea = styled(InputTextarea)<{
	error: boolean;
	success: boolean;
}>`
	${InputCSS}
	${tw`h-auto!`}
`;

export const SmallTextBelow = styled(TkaL1Span)`
	display: block;
	margin-top: 0.25rem;
`;

export const SuggestionsWrapper = styled.div<{ width: number }>`
	${tw`
		flex
		flex-col
		overflow-hidden
		mt-4
	`}
	width: ${({ width }) => width}px;
`;
