import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-32
`;

export const TextCenter = tw.div`
	flex
	flex-col
	items-center
	text-center
	gap-32
`;
