/**
 * @generated SignedSource<<cb3a0f2a7c7d489651da4bf14759e1cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type branchNodesTree_LearnOpportunityV2Fragment$data = {
  readonly structureDefinition: {
    readonly extension?: {
      readonly unlockInfo?: {
        readonly firstContentCoordinatesIndexPath?: ReadonlyArray<number>;
        readonly firstContentId?: string;
        readonly kind: UnlockInfoKind;
      };
    };
    readonly title: string;
    readonly viewerTreeState?: {
      readonly headContentId?: string;
      readonly kind: ViewerTreeStateKind;
    };
  };
  readonly typeDefinition: {
    readonly children?: ReadonlyArray<{
      readonly id: string;
      readonly image: {
        readonly url: string | null;
      } | null;
      readonly indexPath: ReadonlyArray<number>;
      readonly structureDefinition: {
        readonly title: string;
      };
      readonly typeDefinition: {
        readonly children?: ReadonlyArray<{
          readonly id: string;
          readonly image: {
            readonly url: string | null;
          } | null;
          readonly indexPath: ReadonlyArray<number>;
          readonly lengthInSecondsV2: number;
          readonly shortDescription: string | null;
          readonly structureDefinition: {
            readonly title: string;
          };
          readonly " $fragmentSpreads": FragmentRefs<"contentCard_LearnOpportunityV2Fragment">;
        }>;
      };
    }>;
  };
  readonly " $fragmentType": "branchNodesTree_LearnOpportunityV2Fragment";
};
export type branchNodesTree_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: branchNodesTree_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"branchNodesTree_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "structureDefinition",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "indexPath",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "branchNodesTree_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeDefinition",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LearnOpportunityV2",
                          "kind": "LinkedField",
                          "name": "children",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "shortDescription",
                              "storageKey": null
                            },
                            (v3/*: any*/),
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lengthInSecondsV2",
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "contentCard_LearnOpportunityV2Fragment"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityBranchTypeDefinition",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityBranchTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "viewerTreeState",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "headContentId",
                      "storageKey": null
                    }
                  ],
                  "type": "IsStartedViewerTreeState",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "unlockInfo",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstContentId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstContentCoordinatesIndexPath",
                              "storageKey": null
                            }
                          ],
                          "type": "UnlockInfoDemo",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "PublishedRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "4e9ea39087e35a9a92ba42a83f930988";

export default node;
