import React from "react";
import { Icon } from "@components/icon";
import { InputText } from "@components/input-text";
import { useWindowSize } from "@hooks/use-window-size";
import { shade100, shade40 } from "@themes/colors";

//TODO: remove-translations
export const SearchBarSkeleton = () => {
	const placeholder = "Suchen";

	const { isMediumUp } = useWindowSize();
	const iconColor = isMediumUp ? shade40 : shade100;
	const iconSize = isMediumUp ? 1.5 : 0.75;
	if (!isMediumUp) return <Icon icon={"search"} sizeInRem={iconSize} tkaColor={iconColor} />;

	return <InputText icon={"search"} placeholder={placeholder + "..."} />;
};
