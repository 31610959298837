/**
 * @generated SignedSource<<e9cb40952b848c76c83ee4847361697e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type groupDetailsContextMenu_UserInAccountGroupFragment$data = {
  readonly id: string;
  readonly isBuiltIn: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"editGroupModal_UserInAccountGroupFragment">;
  readonly " $fragmentType": "groupDetailsContextMenu_UserInAccountGroupFragment";
};
export type groupDetailsContextMenu_UserInAccountGroupFragment$key = {
  readonly " $data"?: groupDetailsContextMenu_UserInAccountGroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupDetailsContextMenu_UserInAccountGroupFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "groupDetailsContextMenu_UserInAccountGroupFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBuiltIn",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editGroupModal_UserInAccountGroupFragment"
    }
  ],
  "type": "UserInAccountGroup",
  "abstractKey": null
};

(node as any).hash = "21b75d158d5dd2a55fd3c77a51ab0024";

export default node;
