import styled from "styled-components";
import tw from "twin.macro";
import { TkaSpacing, spacing24 } from "@themes/spacing";

export const ChildrenWrapper = styled.div<{ $padding?: TkaSpacing }>`
	padding: ${({ $padding }) => `${$padding?.rem() ?? spacing24.rem()};`};
	${tw`
		h-full
		flex
		flex-col
		overflow-y-auto
	`}
`;

export const CloseIconWrapper = tw.div`
	absolute
	top-16
	right-16
`;
