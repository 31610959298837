import { Card } from "@components/card/card.component";
import { ImageCardAtom } from "@components/ImageCardAtom";

import { TkaP3Span } from "@themes/font-tags";
import { CardWrapper, contentClass, TagsRow, Title, Wrapper } from "./root-card.styles";
import { NodeCardProps } from "./root-card.type";
import { Label } from "../label";

export const RootCard = ({
	imageUrl,
	partnerName,
	partnerUrl,
	tag,
	topic,
	title,
	maxWidth,
	onClick: handleOnClick,
}: NodeCardProps) => {
	const imageWidth = "4.375rem";

	const handlePartnerOnClick = () => {
		window.open(partnerUrl, "_blank");
	};

	const onClickHandler = partnerUrl ? handlePartnerOnClick : undefined;

	const hasImage = !!imageUrl;
	return (
		<CardWrapper maxWidth={maxWidth}>
			<Card fillParent bodyPaddingInRem={0.75} onClick={handleOnClick} hasOverflowHidden>
				<Wrapper>
					{imageUrl && (
						<ImageCardAtom imageUrl={imageUrl} imageWidth={imageWidth} isAbsolute />
					)}
					<div className={contentClass({ hasImage })}>
						<TagsRow>
							{partnerName && <Label label={partnerName} onClick={onClickHandler} />}
							{tag}
						</TagsRow>
						{topic && <TkaP3Span>{topic}</TkaP3Span>}
						<Title>{title}</Title>
					</div>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};
