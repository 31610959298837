import graphql from "babel-plugin-relay/macro";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment materialsTab_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					typeDefinition {
						... on LearnOpportunityBranchTypeDefinition {
							children {
								attachmentsV2 {
									id
								}
							}
						}
					}
				}
			}
		}
		...materialsTree_LearnOpportunityV2Fragment
	}
`;
