import { Divider } from "primereact/divider";
import { AccountSelectionPanelSkeleton } from "@components/account-selection-panel/account-selection-panel.skeleton";
import { Skeleton } from "@components/skeleton";
import {
	dividerClass,
	nameWrapperClass,
	panelClass,
	panelWrapperClass,
	textWrapperClass,
} from "@screens/account-selection/account-selection.styles";

export const AccountSelectionScreenSkeleton = () => {
	return (
		<div className={panelWrapperClass}>
			<div className={panelClass}>
				<Skeleton width="8rem" height="8rem" borderRadius="100%" />
				<Skeleton width="10rem" className={nameWrapperClass} />
				<Divider className={dividerClass} />
				<div className={textWrapperClass}>
					<Skeleton width="23rem" height="2rem" />
				</div>
				<AccountSelectionPanelSkeleton />
			</div>
		</div>
	);
};
