/**
 * @generated SignedSource<<80f9aefc83e167b92f49fc52376d2e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PotentialAnalysisGivenAnswerKind = "SelectedWithFreetext" | "Text" | "selected";
export type PotentialAnalysisQuestionDataKind = "MULTIPLE_CHOICE" | "MULTIPLE_CHOICE_FREETEXT" | "SCALE" | "SINGLE_CHOICE" | "SINGLE_CHOICE_FREETEXT" | "TEXT";
import { FragmentRefs } from "relay-runtime";
export type textQuestion_PotentialAnalysisQuestionFragment$data = {
  readonly givenAnswer?: {
    readonly kind: PotentialAnalysisGivenAnswerKind;
    readonly text: string;
  } | null;
  readonly id: string;
  readonly kind: PotentialAnalysisQuestionDataKind;
  readonly questionText?: string;
  readonly " $fragmentType": "textQuestion_PotentialAnalysisQuestionFragment";
};
export type textQuestion_PotentialAnalysisQuestionFragment$key = {
  readonly " $data"?: textQuestion_PotentialAnalysisQuestionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"textQuestion_PotentialAnalysisQuestionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "textQuestion_PotentialAnalysisQuestionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "questionText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisTextGivenAnswer",
          "kind": "LinkedField",
          "name": "givenAnswer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "PotentialAnalysisTextQuestion",
      "abstractKey": null
    }
  ],
  "type": "PotentialAnalysisQuestion",
  "abstractKey": "__isPotentialAnalysisQuestion"
};
})();

(node as any).hash = "27d292f6b1604edd17980aba8f419c2b";

export default node;
