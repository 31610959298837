import { useMutation } from "react-relay";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { useToast } from "@hooks/useToast";
import { signUpConfirmEmailStep_ResendActivationMutation } from "@relay/signUpConfirmEmailStep_ResendActivationMutation.graphql";
import { brandStrong100 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span } from "@themes/font-tags";
import { RESEND_ACTIVATION_MUTATION } from "./sign-up-confirm-email-step.graphql";
import { SignUpConfirmEmailStepProps } from "./sign-up-confirm-email-step.interface";
import { Fill, TextCenter } from "./sign-up-confirm-email-step.styles";
import { Button } from "../button";

export const SignUpConfirmEmailStep = ({
	email,
	showLoginButton,
	onGoToLogin,
}: SignUpConfirmEmailStepProps) => {
	const [resendActivation] = useMutation<signUpConfirmEmailStep_ResendActivationMutation>(
		RESEND_ACTIVATION_MUTATION,
	);

	const { t } = useTkaTranslation(["successfullyRegisteredScreen", "activateScreen"]);
	const { showSuccess } = useToast();

	const handleResentEmailOnClick = () => {
		resendActivation({
			variables: {
				input: {
					email,
				},
			},
			onCompleted: () => {
				showSuccess({
					summary: t(
						"successfullyRegisteredScreen:successfully_registered_screen.emailSuccessfullySentToast",
					),
				});
			},
		});
	};

	return (
		<TextCenter>
			<H1Span>
				{t("successfullyRegisteredScreen:successfully_registered_screen.title")}
			</H1Span>
			<TkaP1Span>
				{t("successfullyRegisteredScreen:successfully_registered_screen.body")}
			</TkaP1Span>
			<TkaP2Span>
				{t(
					"successfullyRegisteredScreen:successfully_registered_screen.resendActivationTextPart1",
				)}
				<TkaP2Span tkaColor={brandStrong100} onClick={handleResentEmailOnClick}>
					{t(
						"successfullyRegisteredScreen:successfully_registered_screen.resendActivationLinkText",
					)}
				</TkaP2Span>
				{t(
					"successfullyRegisteredScreen:successfully_registered_screen.resendActivationTextPart2",
				)}
			</TkaP2Span>
			{showLoginButton && (
				<Fill>
					<Button
						fillParent
						label={t("activateScreen:activate_screen.successButtonText")}
						iconName="arrowRight"
						onClick={onGoToLogin}
					/>
				</Fill>
			)}
		</TextCenter>
	);
};
