import { FC } from "react";
import { useWindowSize } from "@hooks/use-window-size";
import { TkaP1Span } from "@themes/font-tags";
import {
	ButtonWrapper,
	Img,
	StyledTkaH1Span,
	TextWrapper,
	VideoPlayerWrapper,
	Wrapper,
} from "./text-and-media.styles";
import { TextAndMediaSectionProps } from "./text-and-media.types";
import { Button } from "../button";
import { VideoPlayer } from "../video-player";

export const TextAndMediaSection: FC<TextAndMediaSectionProps> = ({
	headline,
	text,
	primaryButtonLabel,
	primaryButtonOnClick,
	fileURL,
	fileType,
	mediaPosition,
	secondaryButtonLabel,
	secondaryButtonOnClick,
}) => {
	const { isMediumUp } = useWindowSize();
	return (
		<Wrapper mediaPosition={mediaPosition}>
			{fileType === "image" && <Img src={fileURL} alt="placeholder" />}
			{fileType === "video" && fileURL && isMediumUp ? (
				<VideoPlayerWrapper>
					<VideoPlayer url={fileURL!} />
				</VideoPlayerWrapper>
			) : (
				fileType === "video" && fileURL && <VideoPlayer url={fileURL!} />
			)}
			<TextWrapper>
				<StyledTkaH1Span>{headline}</StyledTkaH1Span>
				<TkaP1Span>{text}</TkaP1Span>
				<ButtonWrapper>
					<Button
						label={primaryButtonLabel}
						iconName="arrowRight"
						onClick={primaryButtonOnClick}
					/>
					{secondaryButtonLabel && (
						<Button
							colorVersion="tertiary"
							label={secondaryButtonLabel}
							onClick={secondaryButtonOnClick}
						/>
					)}
				</ButtonWrapper>
			</TextWrapper>
		</Wrapper>
	);
};
