import { Divider } from "@components/divider";
import { InputTextSkeleton } from "@components/input-text/input-text.skeleton";
import { RadioOptionSkeleton } from "@components/radio-option";
import { AccountTypeWrapper, Wrapper } from "./account-tab.styles";
import { PrivateAccountFormSkeleton } from "./parts/private-account-form";

//TODO: add-translations
export const AccountTabSkeleton = () => {
	return (
		<Wrapper>
			<InputTextSkeleton />
			<Divider />
			<AccountTypeWrapper>
				<RadioOptionSkeleton label="Privatkonto" />
				<RadioOptionSkeleton label="Geschäftskonto" />
			</AccountTypeWrapper>
			<Divider />
			<PrivateAccountFormSkeleton />
		</Wrapper>
	);
};
