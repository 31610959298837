import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment licensesNewSingleUserAssignmentForm_QueryFragment on Query
	@argumentDefinitions(filterByGroupIds: { type: "[ID!]!", defaultValue: [] }) {
		AccountMemberManagement {
			AccountMembers(filterByGroupIds: $filterByGroupIds) {
				accountMembers {
					edges {
						node {
							...licensesSingleUserAssignmentCard_AccountMemberFragment
							...licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment
						}
					}
				}
			}
		}
	}
`;

export const ACCOUNT_MEMBER_INLINE_FRAGMENT = graphql`
	fragment licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment on AccountMember
	@inline {
		id
		name
		kind
		... on UserInAccountWrapper {
			user {
				user {
					id
				}
			}
		}
	}
`;

export const CREATE_NEW_SINGLE_USER_ASSIGNMENT_RULE_MUTATION = graphql`
	mutation licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation(
		$input: CreateSingleUserAssignmentRuleInput!
	) {
		LicenseManagement {
			createSingleUserAssignmentRule(input: $input) {
				clientMutationId
				assignmentRule {
					id
					licenseGroupingId
					...licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment
				}
			}
		}
	}
`;
