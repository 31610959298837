import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";

export const Wrapper = styled.div<{ backgroundColor?: TkaColor }>`
	${tw`
		flex
		flex-row
		gap-4
		bg-shade-5
		rounded-20
		px-8
		py-4
	`}
	${({ backgroundColor }) =>
		backgroundColor ? `background-color: ${backgroundColor.getColor()};` : ""}
`;
