import { css } from "@styled-system/css";

export const WrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "[1.5rem]",
	alignItems: "center",
	textAlign: "center",
	pt: "64",
	pb: "32",
});

export const CardsClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "[0.5rem]",
	lg: {
		flexDirection: "row",
	},
});
export const CardContentClass = css({
	display: "flex",
	gap: "16",
	alignItems: "start",
	bg: "onPrimary",
	px: "32",
	py: "24",
});
