import { graphql } from "babel-plugin-relay/macro";
import { TabPanel } from "primereact/tabview";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { VideoTranscriptLanguage } from "@components/video-menu";
import { getInitialTranscriptLanguage } from "@components/video-menu/video-menu.utils";
import { VideoPlayer } from "@components/video-player";
import { SubtitleLanguage } from "@components/video-player/video-player.interface";
import { formatSeconds } from "@components/video-player/video-player.utils";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { VideoElement_ContentSubmissionDefinitionFragment$key } from "@relay/VideoElement_ContentSubmissionDefinitionFragment.graphql";
import { shade20, shade100 } from "@themes/colors";
import { TkaP2Span, H1Span, TkaH3Span } from "@themes/font-tags";
import { stripHtml } from "@utils/strip-html";
import { VideoElementProps } from "./video-element.interface";
import {
	StyledTabBar,
	SubtitleItem,
	TranscriptsWrapper,
	VideoPlayerWrapper,
	Wrapper,
} from "./video-element.styles";

const ELEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment VideoElement_ContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				title
				id
				... on VideoLearnElement {
					streamFile {
						url
					}
					subtitles {
						language
						subtitles {
							startTimeInSec
							endTimeInSec
							text
						}
					}
					thumbnailFile {
						url
					}
				}
			}
		}
	}
`;

export const VideoElement = ({ contentSubmissionDefinitionFragmentRef }: VideoElementProps) => {
	const { t } = useTkaTranslation("videoPlayerElement");
	const highlightedSubtitle = useRef<HTMLDivElement>(null);
	const scrollContainer = useRef<HTMLDivElement>(null);
	const [selectedSubtitles, setSelectedSubtitles] = useState<SubtitleLanguage>();
	const contentSubmissionDefinition =
		useFragment<VideoElement_ContentSubmissionDefinitionFragment$key>(
			ELEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);
	const element = contentSubmissionDefinition.currentElementState?.element;
	const subtitles = element?.subtitles;
	const availableLanguages = subtitles?.map((subtitle) => subtitle.language) || [];
	const [transcriptsLanguage, setTranscriptsLanguage] = useState(
		getInitialTranscriptLanguage(availableLanguages),
	);
	const [subtitleIndex, setSubtitleIndex] = useState(-1);

	const url = element?.streamFile?.url;

	const sortedSubtitles = useMemo(
		() =>
			selectedSubtitles?.subtitles
				?.slice()
				.sort((a, b) => a.startTimeInSec - b.startTimeInSec) || [],
		[selectedSubtitles],
	);
	const subtitlesDisabled = transcriptsLanguage === VideoTranscriptLanguage.Disabled;

	useEffect(() => {
		if (!highlightedSubtitle.current) return;
		const top =
			highlightedSubtitle.current.offsetTop - (scrollContainer.current?.offsetTop ?? 0);
		scrollContainer.current?.scrollTo({
			top,
			behavior: "smooth",
		});
	}, [subtitleIndex, highlightedSubtitle.current]);

	return (
		<Wrapper>
			<H1Span>{stripHtml(element?.title || "")}</H1Span>
			{url && (
				<VideoPlayerWrapper>
					<VideoPlayer
						url={url}
						thumbnail={element.thumbnailFile?.url}
						subtitles={subtitles}
						onSelectedSubtitlesChanged={setSelectedSubtitles}
						onTranscriptLanguageChange={setTranscriptsLanguage}
						onSubtitleIndexChanged={setSubtitleIndex}
					/>
				</VideoPlayerWrapper>
			)}
			<StyledTabBar renderActiveOnly={true}>
				<TabPanel
					header={t("video_player_element.liveTranscriptTitle")}
					disabled={subtitlesDisabled}
				>
					<TranscriptsWrapper ref={scrollContainer}>
						{sortedSubtitles?.map((subtitle, index) => (
							<SubtitleItem
								key={subtitle.startTimeInSec}
								ref={index === subtitleIndex ? highlightedSubtitle : null}
							>
								<TkaH3Span tkaColor={index === subtitleIndex ? shade100 : shade20}>
									{formatSeconds(subtitle.startTimeInSec)}
								</TkaH3Span>
								<TkaP2Span tkaColor={index === subtitleIndex ? shade100 : shade20}>
									{subtitle.text}
								</TkaP2Span>
							</SubtitleItem>
						))}
					</TranscriptsWrapper>
				</TabPanel>
			</StyledTabBar>
		</Wrapper>
	);
};
