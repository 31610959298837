/**
 * @generated SignedSource<<7d4fd8c032a5b1e791ac604b2cd2a255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userInGroupContextMenu_UserFragment$data = {
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "userInGroupContextMenu_UserFragment";
};
export type userInGroupContextMenu_UserFragment$key = {
  readonly " $data"?: userInGroupContextMenu_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userInGroupContextMenu_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userInGroupContextMenu_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f8bc114bd40a08510a9344f887c107b8";

export default node;
