import styled from "styled-components";
import tw from "twin.macro";

export const ContentScrollview = styled.div<{
	width?: number;
}>`
	${tw`
		flex-1
		overflow-y-scroll
		overflow-x-hidden
	`}
	${({ width }) => width !== undefined && `width: ${width}px`};
`;

export const HideOverflow = styled.div<{
	height?: number;
	delay?: boolean;
	width?: number;
}>`
	${tw`
		flex
		flex-row
		items-start
		transition-all
		overflow-y-hidden
	`}
	${({ height }) => height !== undefined && `height: ${height}px`};
	${({ delay }) => (delay ? tw`delay-300` : "transition-none")};
	${({ width }) => width !== undefined && `width: ${width}px`};
`;
