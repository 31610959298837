import { graphql } from "babel-plugin-relay/macro";

export const WORD_CARD_FRAGMENT = graphql`
	fragment wordCard_WordFragment on Word {
		id
		word
		translation
		explanation
	}
`;

export const WORD_CARD_PROFICIENCY_FRAGMENT = graphql`
	fragment wordCard_WordProficiencyFragment on WordProficiency {
		wordId
		proficiencyLevel
	}
`;

export const WORD_NOTE_FRAGMENT = graphql`
	fragment wordCard_WordNoteFragment on WordNote {
		wordId
		note
		...editWordNoteModal_WordNoteFragment
	}
`;
