import { createContext, PropsWithChildren, useState } from "react";

export type IReportModalFlowContext = {
	setReferenceNumber: (referenceNumber?: string) => void;
	referenceNumber?: string;
};
export const ReportModalFlowContext = createContext<IReportModalFlowContext>({
	referenceNumber: "",
	setReferenceNumber: () => {},
});

export const ReportModalFlowContextProvider = (props: PropsWithChildren) => {
	const [referenceNumber, setReferenceNumber] = useState<string | undefined>(undefined);

	const value: IReportModalFlowContext = {
		referenceNumber,
		setReferenceNumber: (referenceNumber?: string) => {
			setReferenceNumber(referenceNumber);
		},
	};
	return (
		<ReportModalFlowContext.Provider value={value}>
			{props.children}
		</ReportModalFlowContext.Provider>
	);
};
