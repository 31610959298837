import { forwardRef, useContext, useImperativeHandle, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { useWindowSize } from "@hooks/use-window-size";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { shade100, shade80 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import { USER_FRAGMENT } from "./user-in-group-context-menu.graphql";
import {
	ItemWrapper,
	OptionsWrapper,
	TitleWrapper,
	Wrapper,
} from "./user-in-group-context-menu.styles";
import {
	UserInGroupContextMenuProps,
	UserInGroupContextMenuRef,
} from "./user-in-group-context-menu.types";
import { RemoveMembersFromGroupModal } from "../remove-members-from-group-modal";

// TODO: add-translations
export const UserInGroupContextMenu = forwardRef<
	UserInGroupContextMenuRef,
	UserInGroupContextMenuProps
>(function UserInGroupContextMenu({ userFragmentRef, groupMembersConnectionId, groupId }, ref) {
	const user = useFragment(USER_FRAGMENT, userFragmentRef ?? null);

	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

	const { isMediumUp } = useWindowSize();
	const { refetch } = useContext(RefetchContext);

	const [isVisibleRemoveMemberModal, setIsVisibleRemoveMemberModal] = useState(false);

	useImperativeHandle(ref, () => ({
		toggle: (event, target) => {
			overlayRef?.current?.toggle(event, target);
		},
	}));

	const userName = user?.name ?? "";

	const handleRemoveUserOnClick = () => {
		overlayRef?.current?.hide();
		setIsVisibleRemoveMemberModal(true);
	};

	const hanldeRemoveMemberModalOnDismiss = () => {
		setIsVisibleRemoveMemberModal(false);
	};

	const handleCloseMenueOnClick = () => {
		overlayRef?.current?.hide();
	};

	const handleRemoveMemberOnSuccess = () => {
		refetch();
	};

	const iconSizeRem = isMediumUp ? 1.5 : 1;
	const ItemLabelSpan = isMediumUp ? TkaP2Span : TkaP1Span;

	return (
		<>
			<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing0}>
				<Wrapper>
					{!isMediumUp && (
						<TitleWrapper>
							<H1Span>Aktion wählen</H1Span>
							<TkaP2Span tkaColor={shade80}>{userName}</TkaP2Span>
						</TitleWrapper>
					)}
					<OptionsWrapper>
						<ItemWrapper onClick={handleRemoveUserOnClick}>
							<Icon tkaColor={shade100} icon={"wrong"} sizeInRem={iconSizeRem} />
							<ItemLabelSpan tkaColor={shade100}>Aus Gruppe entfernen</ItemLabelSpan>
						</ItemWrapper>
					</OptionsWrapper>
					{!isMediumUp && (
						<Button label="Schließen" fillParent onClick={handleCloseMenueOnClick} />
					)}
				</Wrapper>
			</ResponsiveBottomSheetOverlayPanel>
			{user && (
				<RemoveMembersFromGroupModal
					isVisible={isVisibleRemoveMemberModal}
					selectedMembers={[user]}
					groupMembersConnectionId={groupMembersConnectionId}
					groupId={groupId}
					onDismiss={hanldeRemoveMemberModalOnDismiss}
					onSuccess={handleRemoveMemberOnSuccess}
				/>
			)}
		</>
	);
});
