import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment cancelAppointmentMessageCoachModal_AvailabilityFragment on Availability {
		id
		booking {
			... on BookedForGroup {
				participants {
					user {
						id
						...userAvatarsCollection_UserFragment
					}
				}
				offer {
					description {
						name
						tags {
							id
							data {
								name
							}
						}
					}
				}
				msTeamsLink
			}
			... on BookedOneToOne {
				participant {
					user {
						id
						...userAvatarsCollection_UserFragment
					}
				}
				offer {
					description {
						name
						tags {
							id
							data {
								name
							}
						}
					}
				}
				msTeamsLink
			}
		}
		data {
			startDateTime
			endDateTime
		}
		...appointmentStatusLabel_AvailabilityFragment
	}
`;

export const CANCEL_BOOKING_AS_COACH_MUTATION = graphql`
	mutation cancelAppointmentMessageCoachModal_CancelBookingAsCoachMutation(
		$input: CancelBookingAsCoachInput!
	) {
		Coaching {
			cancelBookingAsCoach(input: $input) {
				clientMutationId
			}
		}
	}
`;
