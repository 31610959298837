import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query orientation_Query {
		Viewer {
			PotentialAnalysis {
				MyPotentialAnalysisResult {
					name
					categoryResults {
						name
						result
						benchmark
					}
					isLongAnalysis
					pdf {
						url
						uploadDateTime
					}
				}
			}
		}
	}
`;
