/**
 * @generated SignedSource<<e1e791da08af38631b521c795222537c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type membersFilterRefetchQuery$variables = {};
export type membersFilterRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"membersFilter_QueryFragment">;
};
export type membersFilterRefetchQuery = {
  response: membersFilterRefetchQuery$data;
  variables: membersFilterRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "membersFilterRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "membersFilter_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "membersFilterRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementQueries",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "doCalcMemberCount",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "includeOwner",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "includeUser",
                "value": true
              }
            ],
            "concreteType": "UserInAccountGroupWrappersResult",
            "kind": "LinkedField",
            "name": "UserInAccountGroupWrappers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "UserInAccountGroupWrapperConnection",
                "kind": "LinkedField",
                "name": "userInAccountGroupWrappers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountGroupWrapperEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccountGroupWrapper",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInAccountGroup",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "userInAccountGroupWrappers(first:200)"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "filterModal_userInAccountGroupWrappers",
                "kind": "LinkedHandle",
                "name": "userInAccountGroupWrappers"
              }
            ],
            "storageKey": "UserInAccountGroupWrappers(doCalcMemberCount:true,includeOwner:true,includeUser:true)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b995a8e8ecd03eb224bcdf380073a882",
    "id": null,
    "metadata": {},
    "name": "membersFilterRefetchQuery",
    "operationKind": "query",
    "text": "query membersFilterRefetchQuery {\n  ...membersFilter_QueryFragment\n}\n\nfragment filterModal_QueryFragment on Query {\n  AccountMemberManagement {\n    UserInAccountGroupWrappers(includeOwner: true, includeUser: true, doCalcMemberCount: true) {\n      userInAccountGroupWrappers(first: 200) {\n        edges {\n          node {\n            group {\n              id\n              name\n            }\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment membersFilter_QueryFragment on Query {\n  ...filterModal_QueryFragment\n}\n"
  }
};
})();

(node as any).hash = "223489280f27434abc583fa1996b358a";

export default node;
