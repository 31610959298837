import { useCallback, useState } from "react";
import { Icon } from "@components/icon/icon.component";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { TkaP3Span, TkaH3Span } from "@themes/font-tags";
import {
	IconWrapper,
	TextWrapper,
	ListWrapper,
	MainWrapper,
} from "./materials-branch-node-card.styles";
import { MaterialsBranchNodeCardProps } from "./materials-branch-node-card.types";

export const MaterialsBranchNodeCard = ({
	branchNumber,
	branchName,
	children,
}: MaterialsBranchNodeCardProps) => {
	const { t } = useTkaTranslation("modulesAndLessons");

	const [isExpanded, setIsExpanded] = useState(false);

	const handleOnExpandClick = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	return (
		<ListWrapper>
			<MainWrapper onClick={handleOnExpandClick}>
				<TextWrapper>
					<TkaP3Span>
						{t("modules_and_lessons.module")} {branchNumber}
					</TkaP3Span>
					<TkaH3Span>{branchName}</TkaH3Span>
				</TextWrapper>
				<IconWrapper>
					<Icon icon={isExpanded ? "arrowUp" : "arrowDown"} sizeInRem={1} />
				</IconWrapper>
			</MainWrapper>
			{isExpanded && children}
		</ListWrapper>
	);
};
