import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query trendsDetail_Query($id: ID!) {
		...trendsDetail_QueryFragment @arguments(id: $id)
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment trendsDetail_QueryFragment on Query
	@refetchable(queryName: "trendsDetailRefetchQuery")
	@argumentDefinitions(id: { type: "ID!" }) {
		node(id: $id) {
			... on Trend {
				id
				...trendMedia_TrendMediaFragment
				...trendUspBoxes_TrendUspBoxesFragment
				...feedbackQuote_CustomerFeedbackQueryFragment
				...trendOfferSection_TrendOfferFragment
				trendData {
					name
				}
				potentialAnalysisHeadingAndDescription {
					description
					heading
				}
				coachingHeadingAndDescription {
					description
					heading
				}
				coachingImage {
					url
				}
				learnables {
					id
					kind
					...trendsDetail_LearnableFragment
				}
			}
		}
		...tags_AcademiesRooTagsConnectionFragment
	}
`;

export const LEARNABLE_FRAGMENT = graphql`
	fragment trendsDetail_LearnableFragment on PublishedLearnable @inline {
		kind
		id
		...coachingLearnableCard_PublishedLearnableFragment
		...educationalOfferCard_PublishedCourseLearnableFragment
	}
`;
