import styled from "styled-components";
import tw from "twin.macro";
import { TkaH2Span } from "@themes/font-tags";

export const Background = styled.div<{ size: number }>`
	width: ${({ size }) => size}rem;
	height: ${({ size }) => size}rem;
	border-radius: ${({ size }) => size / 2}rem;
	${tw`
		flex
		items-center
		justify-center
		bg-shade-80
	`}
`;

export const Text = styled(TkaH2Span)<{ fontSize: number }>`
	font-size: ${({ fontSize }) => fontSize}rem;
`;
