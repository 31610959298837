import {
	ForwardedRef,
	SyntheticEvent,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useWindowSize } from "@hooks/use-window-size";
import {
	ResponsiveBottomSheetOverlayPanelProps,
	ResponsiveBottomSheetOverlayPanel as ResponsiveBottomSheetOverlayPanelClass,
} from "./responsive-bottom-sheet-overlay-panel.types";
import { BottomSheet } from "../bottom-sheet";
import { OverlayPanel } from "../overlay-panel";

const ResponsiveBottomSheetOverlayPanelComponent = (
	{ children, padding }: ResponsiveBottomSheetOverlayPanelProps,
	ref: ForwardedRef<unknown>,
) => {
	const overlayPanelRef = useRef<OverlayPanel>(null);
	const { isMediumUp } = useWindowSize();

	const [isVisible, setIsVisible] = useState(false);

	const handleOnShow = (
		event: SyntheticEvent<Element, Event> | null | undefined,
		target: HTMLElement | EventTarget | null | undefined,
	) => {
		setIsVisible(true);
		overlayPanelRef.current?.show(event, target);
	};

	const handleOnHide = () => {
		setIsVisible(false);
		overlayPanelRef.current?.hide();
	};

	const handleOnToggle = (
		event: SyntheticEvent<Element, Event> | null | undefined,
		target?: HTMLElement | EventTarget | null | undefined,
	) => {
		setIsVisible((visible) => !visible);
		overlayPanelRef.current?.toggle(event, target);
	};

	useImperativeHandle(ref, () => ({
		show: handleOnShow,
		hide: handleOnHide,
		toggle: handleOnToggle,
	}));

	const handleOnDismiss = () => {
		setIsVisible(false);
	};

	useEffect(() => {
		if (!isMediumUp && isVisible) {
			setIsVisible(false);
		}
	}, [isMediumUp]);

	return isMediumUp ? (
		<OverlayPanel ref={overlayPanelRef} padding={padding}>
			{children}
		</OverlayPanel>
	) : (
		<BottomSheet isVisible={isVisible} onDismiss={handleOnDismiss}>
			{children}
		</BottomSheet>
	);
};

export const ResponsiveBottomSheetOverlayPanel = forwardRef(
	ResponsiveBottomSheetOverlayPanelComponent,
);
export type ResponsiveBottomSheetOverlayPanel = ResponsiveBottomSheetOverlayPanelClass;
