import tw from "twin.macro";

export const Wrapper = tw.div`
	relative
`;

export const BadgeWrapper = tw.div`
	absolute
	top-[-0.5rem]
	right-[-0.5rem]
`;

export const OverlayWrapper = tw.div`
	w-[16rem]
`;
