import { type FC } from "react";
import { Icon } from "@components/icon";
import { ScrollableContainer } from "@components/scrollable-container";
import { useWindowSize } from "@hooks/use-window-size";
import { TkaH2Span, TkaP1Span } from "@themes/font-tags";
import {
	Box,
	BoxContent,
	GraphLeftWrapper,
	GraphRightWrapper,
	InnerWrapper,
	TopBoxes,
	Wrapper,
} from "./trend-marketing-graph.styles";

export const TrendMarketingGraph: FC = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScrollableContainer startAt={isMediumUp ? "left" : "center"}>
			<Wrapper>
				<InnerWrapper>
					<TopBoxes>
						<Box>
							<Icon icon="growRibbon" sizeInRem={6.6875} />
							<BoxContent>
								<TkaH2Span>Trends</TkaH2Span>
								<TkaP1Span>
									Up-to-Date zu <br />
									Zukunftstrends
								</TkaP1Span>
							</BoxContent>
							<GraphLeftWrapper />
						</Box>
						<Box>
							<Icon icon="hatRibbon" sizeInRem={6.6875} />
							<BoxContent>
								<TkaH2Span>Weiterentwicklung</TkaH2Span>
								<TkaP1Span>
									Entwickle dich kontinuierlich <br />
									weiter - flexibel und modular.
								</TkaP1Span>
							</BoxContent>
						</Box>
						<Box>
							<Icon icon="placementRibbon" sizeInRem={6.6875} />
							<BoxContent>
								<TkaH2Span>Talent Job Matching</TkaH2Span>
								<TkaP1Span>
									Fachkräfte im <br />
									Wunschjob
								</TkaP1Span>
							</BoxContent>
							<GraphRightWrapper />
						</Box>
					</TopBoxes>
					<div className="flex flex-col items-center flex-1 h-[240px] mt-[65px] xl:mt-[25px]">
						<Icon icon="bulbRibbon" sizeInRem={6.6875} />
						<BoxContent>
							<TkaH2Span>Orientierung</TkaH2Span>
							<TkaP1Span>
								Potenzialanalyse, Persönliche Journey,
								<br />
								HR-Dashboard
							</TkaP1Span>
						</BoxContent>
					</div>
				</InnerWrapper>
			</Wrapper>
		</ScrollableContainer>
	);
};
