/**
 * @generated SignedSource<<6a1d7a1365cb4dc8cdd7b9b0d4930348>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bookingSuccessModal_AvailabilityFragment$data = {
  readonly booking: {
    readonly offer?: {
      readonly description: {
        readonly name: string;
        readonly tags: ReadonlyArray<{
          readonly data: {
            readonly name: string;
          };
          readonly id: string;
        }>;
      } | null;
    } | null;
  };
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly " $fragmentType": "bookingSuccessModal_AvailabilityFragment";
};
export type bookingSuccessModal_AvailabilityFragment$key = {
  readonly " $data"?: bookingSuccessModal_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"bookingSuccessModal_AvailabilityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachingOffer",
    "kind": "LinkedField",
    "name": "offer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoachingOfferDescription",
        "kind": "LinkedField",
        "name": "description",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AcademiesTagData",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "bookingSuccessModal_AvailabilityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "BookedOneToOne",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "BookedForGroup",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Availability",
  "abstractKey": null
};
})();

(node as any).hash = "fa2251217a7256e07fc08b0dc9f11e03";

export default node;
