/**
 * @generated SignedSource<<da95c01597f947d47c82930a828d4eac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordCard_WordFragment$data = {
  readonly explanation: string;
  readonly id: string;
  readonly translation: string | null;
  readonly word: string;
  readonly " $fragmentType": "wordCard_WordFragment";
};
export type wordCard_WordFragment$key = {
  readonly " $data"?: wordCard_WordFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"wordCard_WordFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "wordCard_WordFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "word",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "translation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "explanation",
      "storageKey": null
    }
  ],
  "type": "Word",
  "abstractKey": null
};

(node as any).hash = "53572a5524a18b4f772d886ac17c1777";

export default node;
