import { readInlineData } from "react-relay";
import { DEFAULT_AVATAR_SIZE_REM } from "./user-avatars-collection.consts";
import { USER_FRAGMENT } from "./user-avatars-collection.graphql";
import { AvatarsWrapper, Wrapper } from "./user-avatars-collection.styles";
import { UserAvatarsCollectionProps } from "./user-avatars-collection.types";
import { UserAvatar } from "../user-avatar/user-avatar.component";

export const UserAvatarsCollection = ({
	sizeInRem = DEFAULT_AVATAR_SIZE_REM,
	showUsersNum = 3,
	userFragmentsRef,
}: UserAvatarsCollectionProps) => {
	const users = userFragmentsRef
		?.map((fragment) => readInlineData(USER_FRAGMENT, fragment))
		.filter(Boolean);

	if (!users) return null;
	const userList = users.slice(0, showUsersNum);
	return (
		<Wrapper>
			{userList?.map((user, index) => (
				<AvatarsWrapper
					key={user.id}
					firstItem={index === 0}
					sizeInRem={sizeInRem}
					zIndex={userList.length - index}
				>
					<UserAvatar sizeInRem={sizeInRem} userFragmentRef={user} />
				</AvatarsWrapper>
			))}
		</Wrapper>
	);
};
