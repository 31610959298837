import { DialogTemplate } from "@components/dialog-template";
import { DialogButtonsDirection } from "@components/dialog-template/dialog-template.types";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";

import { InlineIcon } from "@components/inline-icon/inline-icon.component";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { error0, error100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { ErrorInviteComponentProps } from "./error-invite.types";
import { StyledList, StyledListItem } from "../invite-component/invite.component.styles";

enum ErrorTypes {
	DOMAIN_PROBLEM = "domainProblem",
	INVALID_GROUP = "invalidGroup",
	INVALID_FILE = "invalidFile",
}

type ErrorInfo = {
	invalidEmails: string[];
	notFoundGroupNames: string[];
	invalidDomains?: string[];
};

const getErrorContent = (errorType: ErrorTypes, errorInfo: ErrorInfo) => {
	switch (errorType) {
		case ErrorTypes.DOMAIN_PROBLEM:
			return {
				subtitle: "Domain-Problem in der CSV-Datei",
				infotext:
					"Einige Benutzer in der CSV-Datei sind Domains zugeordnet, die nicht mit der angegebenen Domain übereinstimmen. Bitte überprüfen Sie die folgenden fehlerhaften Domains:",
				additionalInfo: errorInfo.invalidDomains,
			};

		case ErrorTypes.INVALID_GROUP:
			return {
				subtitle: "Gruppen-Fehler in der CSV-Datei",
				infotext:
					"Einige Benutzer in der CSV-Datei sind Gruppen zugeordnet, die nicht mit den Gruppen im Konto übereinstimmen. Bitte überprüfen Sie die folgenden fehlerhaften Gruppen in der CSV:",
				additionalInfo: errorInfo.notFoundGroupNames,
			};

		case ErrorTypes.INVALID_FILE:
			return {
				subtitle: "Formatproblem erkannt",
				infotext:
					"Das hochgeladene CSV-Format ist nicht korrekt. Bitte überprüfe die Datei und stelle sicher, dass die CSV dem Format entspricht.",
			};
	}
};
export const ErrorInviteComponent = ({
	isVisible,
	handleOnClose,
	errorInfo,
	handleOnRetry,
}: ErrorInviteComponentProps) => {
	const errorType =
		errorInfo.invalidEmails.length > 0
			? ErrorTypes.INVALID_FILE
			: errorInfo.notFoundGroupNames.length > 0
			? ErrorTypes.INVALID_GROUP
			: ErrorTypes.DOMAIN_PROBLEM;
	const errorContent = getErrorContent(errorType, errorInfo);
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible}>
			<DialogTemplate
				buttonsDirection={DialogButtonsDirection.COLUMN}
				primaryButtonLabel="Korrigieren"
				secondaryButtonLabel="Abbrechen"
				onPrimaryButtonClick={() => {
					handleOnRetry();
				}}
				onSecondaryButtonClick={() => handleOnClose()}
			>
				<IconHeadlineButtonTemplate
					iconName="close"
					iconColor={error100}
					iconBackgroundColor={error0}
					title="Fehler beim CSV-Upload"
					subtitle={errorContent.subtitle}
				/>
				<TkaP2Span>{errorContent.infotext}</TkaP2Span>
				{errorContent.additionalInfo && (
					<StyledList>
						{errorContent.additionalInfo.map((invalidContent, i) => (
							<StyledListItem key={i}>{invalidContent}</StyledListItem>
						))}
					</StyledList>
				)}
				<a
					href={`${process.env.PUBLIC_URL}/demofile.csv`}
					target="_blank"
					rel="noopener noreferrer"
					download="CSV Demodatei"
				>
					<InlineIcon
						icon={"downloadBottom"}
						text={"Demodatei herunterladen"}
						sizeInRem={1}
					/>
				</a>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
