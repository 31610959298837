import styled from "styled-components";
import tw from "twin.macro";
import { spacing4 } from "@themes/spacing";

export const Flex = tw.div`flex flex-col md:items-center gap-20 md:flex-row md:justify-between overflow-hidden`;

export const MessageContainer = tw.div`flex flex-col items-center md:items-start text-center md:text-left md:flex-[2] `;

export const IconAndBadgeWrapper = styled.div`
	position: relative;
	padding: ${spacing4.rem()};
`;

export const BadgeWrapper = styled.div`
	position: absolute;
	right: -10%;
	top: 0;
	transform: translateY(25%);
`;

export const ContentWrapper = tw.div`
	flex 
	flex-col
	md:flex-row
	gap-20
	items-center
`;
