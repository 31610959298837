/**
 * @generated SignedSource<<8721f63d73feaed86693f489d2f7ac53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type shoppingCartOverview_OrderFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartLegalForm_OrderFragment" | "shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartOverview_OrderFragment";
};
export type shoppingCartOverview_OrderFragment$key = {
  readonly " $data"?: shoppingCartOverview_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartOverview_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartOverview_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartLegalForm_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "81b0cfe7c5a6ff24011ef4cfc57d62a2";

export default node;
