import { CSSProperties } from "react";
import { Link } from "react-scroll";
import styled, { css } from "styled-components";
import { shade0, shade10 } from "@themes/colors";
import { spacing12, spacing24 } from "@themes/spacing";

export const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${spacing12.rem()};
`;

export const LinkCss = css`
	margin: 0 ${spacing24.rem()} 0;
	padding: ${spacing12.rem()};
	border: 1px solid ${shade10};
	border-radius: 0.625rem;
	cursor: pointer;
`;

export const StyledLink = styled(Link)`
	${LinkCss}
`;

export const StyledLinkMock = styled.div`
	${LinkCss}
`;

export const ActiveItem: CSSProperties = {
	backgroundColor: shade0.getColor(),
	border: `1px solid ${shade0.getColor()}`,
	marginRight: 0,
	borderBottomRightRadius: 0,
	borderTopRightRadius: 0,
};
