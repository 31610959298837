/**
 * @generated SignedSource<<c7e851ead73e33f256482be5b8e69ff5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookAppointment_QueryFragmentRefetch$variables = {
  after?: string | null;
  before?: string | null;
  filterByCoach?: string | null;
  filterByMaxDate?: string | null;
  filterByMinDate?: string | null;
  first?: number | null;
  last?: number | null;
  skipQuery?: boolean | null;
};
export type BookAppointment_QueryFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"bookAppointment_QueryFragment">;
};
export type BookAppointment_QueryFragmentRefetch = {
  response: BookAppointment_QueryFragmentRefetch$data;
  variables: BookAppointment_QueryFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByCoach"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByMaxDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByMinDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": true,
    "kind": "LocalArgument",
    "name": "skipQuery"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v3 = {
  "kind": "Variable",
  "name": "filterByCoach",
  "variableName": "filterByCoach"
},
v4 = {
  "kind": "Variable",
  "name": "filterByMaxDate",
  "variableName": "filterByMaxDate"
},
v5 = {
  "kind": "Variable",
  "name": "filterByMinDate",
  "variableName": "filterByMinDate"
},
v6 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v7 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookAppointment_QueryFragmentRefetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "skipQuery",
            "variableName": "skipQuery"
          }
        ],
        "kind": "FragmentSpread",
        "name": "bookAppointment_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookAppointment_QueryFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoachingQueries",
        "kind": "LinkedField",
        "name": "Coaching",
        "plural": false,
        "selections": [
          {
            "condition": "skipQuery",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "AvailabilityConnection",
                "kind": "LinkedField",
                "name": "FreeAvailabilities",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailabilityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Availability",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AvailabilityData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startDateTime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endDateTime",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "filterByCoach",
                  "filterByMinDate",
                  "filterByMaxDate"
                ],
                "handle": "connection",
                "key": "BookAppointment_FreeAvailabilities",
                "kind": "LinkedHandle",
                "name": "FreeAvailabilities"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2721f9bb49b0fc10503cc13c45ece8e8",
    "id": null,
    "metadata": {},
    "name": "BookAppointment_QueryFragmentRefetch",
    "operationKind": "query",
    "text": "query BookAppointment_QueryFragmentRefetch(\n  $after: String = null\n  $before: String = null\n  $filterByCoach: ID\n  $filterByMaxDate: LocalDate\n  $filterByMinDate: LocalDate\n  $first: Int\n  $last: Int\n  $skipQuery: Boolean = true\n) {\n  ...bookAppointment_QueryFragment_5qKcc\n}\n\nfragment bookAppointment_QueryFragment_5qKcc on Query {\n  Coaching {\n    FreeAvailabilities(filterByCoach: $filterByCoach, filterByMinDate: $filterByMinDate, filterByMaxDate: $filterByMaxDate, after: $after, before: $before, first: $first, last: $last) @skip(if: $skipQuery) {\n      edges {\n        node {\n          id\n          data {\n            startDateTime\n            endDateTime\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f88e8e6212b37066052d06d3c5c39d8d";

export default node;
