import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQueryLoader } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { gtmTrackRegistration } from "@analytics/google-tag-manager";
import { useAuthContext_AcceptInvitationMutation } from "@relay/useAuthContext_AcceptInvitationMutation.graphql";
import {
	useAuthContext_AcceptInvitationWithNewUserMutation,
	useAuthContext_AcceptInvitationWithNewUserMutation$data,
} from "@relay/useAuthContext_AcceptInvitationWithNewUserMutation.graphql";
import { useAuthContext_ActivateUserMutation } from "@relay/useAuthContext_ActivateUserMutation.graphql";
import { useAuthContext_ForgotPasswordMutation } from "@relay/useAuthContext_ForgotPasswordMutation.graphql";
import {
	useAuthContext_LoginMutation,
	useAuthContext_LoginMutation$data,
} from "@relay/useAuthContext_LoginMutation.graphql";
import { useAuthContext_LogoutMutation } from "@relay/useAuthContext_LogoutMutation.graphql";
import { useAuthContext_PermissionQuery } from "@relay/useAuthContext_PermissionQuery.graphql";
import {
	RegisterUserInput,
	useAuthContext_RegisterMutation,
} from "@relay/useAuthContext_RegisterMutation.graphql";
import { useAuthContext_ResetPasswordMutation } from "@relay/useAuthContext_ResetPasswordMutation.graphql";
import { useAuthContext_SwitchAccountMutation } from "@relay/useAuthContext_SwitchAccountMutation.graphql";
import {
	JwtTokenData,
	logout as logoutDispatch,
	selectRefreshToken,
	setLoggedIn,
} from "@slices/AuthSlice";
import { useTypedDispatch } from "@store/index";
import {
	ACCEPT_INVITATION_MUTATION,
	ACCEPT_INVITATION_WITH_NEW_USER_MUTATION,
	ACTIVATE_USER_MUTATION,
	FORGOT_PASSWORD_MUTATION,
	LOGIN_MUTATION,
	LOGOUT_MUTATION,
	PERMISSION_QUERY,
	QUERY,
	REGISTER_MUTATION,
	RESET_PASSWORD_MUTATION,
	SWITCH_ACCOUNT_MUTATION,
} from "./use-auth-context.graphql";
import { SignUpFormValues } from "./use-auth-context.types";

export const useAuthContext = () => {
	const [login] = useMutation<useAuthContext_LoginMutation>(LOGIN_MUTATION);
	const [logout] = useMutation<useAuthContext_LogoutMutation>(LOGOUT_MUTATION);
	const [_queryReference, loadPermissionsQuery] =
		useQueryLoader<useAuthContext_PermissionQuery>(PERMISSION_QUERY);
	const [switchAccount] =
		useMutation<useAuthContext_SwitchAccountMutation>(SWITCH_ACCOUNT_MUTATION);
	const [register] = useMutation<useAuthContext_RegisterMutation>(REGISTER_MUTATION);
	const [acceptInvitation] = useMutation<useAuthContext_AcceptInvitationMutation>(
		ACCEPT_INVITATION_MUTATION,
	);
	const [acceptInvitationWithNewUser] =
		useMutation<useAuthContext_AcceptInvitationWithNewUserMutation>(
			ACCEPT_INVITATION_WITH_NEW_USER_MUTATION,
		);
	const [activateUser] = useMutation<useAuthContext_ActivateUserMutation>(ACTIVATE_USER_MUTATION);
	const [forgotPassword] =
		useMutation<useAuthContext_ForgotPasswordMutation>(FORGOT_PASSWORD_MUTATION);
	const [resetPassword] =
		useMutation<useAuthContext_ResetPasswordMutation>(RESET_PASSWORD_MUTATION);

	const [_, loadQuery] = useQueryLoader(QUERY);

	const dispatch = useTypedDispatch();
	const [searchParams] = useSearchParams();

	const refreshToken = useSelector(selectRefreshToken);

	const handleLogin = useCallback(
		(email: string, password: string) => {
			return new Promise<useAuthContext_LoginMutation$data>((resolve, reject) => {
				login({
					variables: {
						input: {
							email,
							password,
						},
					},
					onCompleted: (response) => {
						const loginResult = response.Auth.loginFromWebapp?.loginResult;

						if (loginResult) {
							const firstLogin = loginResult.firstLogin;
							const isAvgsClient = loginResult.isAVGSClient;
							const redirectUrl = loginResult.forwardToFrontendURL ?? undefined;

							dispatch(
								setLoggedIn({
									tokenData: loginResult?.jwtTokens as JwtTokenData,
									firstLogin,
									isAvgsClient,
									redirectUrl,
								}),
							);
							resolve(response);
						} else {
							reject();
						}
					},
					onError: reject,
				});
			});
		},
		[login, dispatch],
	);

	const handleSwitchAccount = useCallback(
		(accountId: string) => {
			return new Promise((resolve, reject) => {
				if (!refreshToken) return reject();
				switchAccount({
					variables: {
						input: {
							refreshToken: refreshToken,
							targetAccount: accountId,
						},
					},
					onCompleted: (response) => {
						if (response.Auth.switchAccount) {
							dispatch(
								setLoggedIn({
									tokenData: response.Auth.switchAccount
										?.jwtTokens as JwtTokenData,
								}),
							);
							resolve(response);
						} else {
							reject();
						}
					},
					onError: reject,
				});
			});
		},
		[switchAccount, dispatch, refreshToken],
	);

	const handleLogout = useCallback(() => {
		logout({
			variables: { input: {} },
			onCompleted: () => {
				dispatch(logoutDispatch());
				loadPermissionsQuery({}, { fetchPolicy: "store-and-network" });
			},
		});
	}, [logout, dispatch]);

	const handleSignUp = useCallback(
		(values: SignUpFormValues) => {
			const input: RegisterUserInput = {
				email: values.email,
				rawPassword: values.password,
				firstName: values.firstName,
				lastName: values.lastName,
				adsOptIn: values.adsAccepted,
				branch: values.branch,
				teamSize: values.teamSize,
				position: values.position,
			};
			const lge = searchParams.get("lge");
			const rvw = searchParams.get("rvw");
			const rvwchk = searchParams.get("rvwchk");
			const ptt = searchParams.get("ptt");
			const pttchk = searchParams.get("pttchk");
			if (lge && rvw && rvwchk && ptt && pttchk) {
				input.potentialAnalysisRegistrationData = {
					language: lge,
					rvw: Number(rvw),
					rvwchk,
					ptt: Number(ptt),
					pttchk,
				};
			}

			return new Promise((resolve, reject) => {
				register({
					variables: {
						input,
					},
					onError: reject,
					onCompleted: (response) => {
						if (response.Auth.registerUser) {
							resolve(response);
							gtmTrackRegistration(response.Auth.registerUser.userId);
							return;
						}
						reject();
					},
				});
			});
		},
		[register],
	);

	const handleAcceptInvitation = useCallback(
		(token: string) => {
			return new Promise((resolve, reject) => {
				acceptInvitation({
					variables: {
						input: {
							token,
						},
					},
					onCompleted: () => {
						loadQuery({}, { fetchPolicy: "store-and-network" });
						resolve(true);
					},
					onError: reject,
				});
			});
		},
		[acceptInvitation],
	);

	const handleAcceptInvitationWithNewUser = useCallback(
		(values: SignUpFormValues, token: string) => {
			return new Promise<useAuthContext_AcceptInvitationWithNewUserMutation$data>(
				(resolve, reject) => {
					acceptInvitationWithNewUser({
						variables: {
							input: {
								token,
								email: values.email,
								rawPassword: values.password,
								firstName: values.firstName,
								lastName: values.lastName,
								adsOptIn: values.adsAccepted,
								branch: values.branch,
								teamSize: values.teamSize,
								position: values.position,
							},
						},
						onCompleted: (response) => {
							if (response.Auth.acceptInvitationWithNewUser) {
								const isAvgsClient =
									response.Auth.acceptInvitationWithNewUser.loginResult
										.isAVGSClient;
								const redirectUrl =
									response.Auth.acceptInvitationWithNewUser.loginResult
										.forwardToFrontendURL ?? undefined;
								dispatch(
									setLoggedIn({
										tokenData: response.Auth.acceptInvitationWithNewUser
											.loginResult?.jwtTokens as JwtTokenData,
										isAvgsClient,
										redirectUrl,
									}),
								);
								resolve(response);
							} else {
								reject();
							}
						},
						onError: reject,
					});
				},
			);
		},
		[acceptInvitationWithNewUser, dispatch],
	);

	const handleActivateUser = useCallback(
		(token: string) => {
			return new Promise((resolve, reject) => {
				activateUser({
					variables: {
						input: {
							token,
						},
					},
					onError: reject,
					onCompleted: (response) => {
						if (response.Auth.activateUser) {
							resolve(response);
							return;
						}
						reject();
					},
				});
			});
		},
		[activateUser],
	);

	const handleForgotPassword = useCallback(
		(email: string) => {
			return new Promise((resolve, reject) => {
				forgotPassword({
					variables: {
						input: {
							email,
						},
					},
					onError: reject,
					onCompleted: (response) => {
						if (response.Auth.forgotPassword) {
							resolve(response);
							return;
						}
						reject();
					},
				});
			});
		},
		[forgotPassword],
	);

	const handleResetPassword = useCallback(
		(newPassword: string, token: string) => {
			return new Promise((resolve, reject) => {
				resetPassword({
					variables: {
						input: {
							newPassword,
							token,
						},
					},
					onError: reject,
					onCompleted: (response) => {
						if (response.Auth.resetPasswordAndActivate) {
							resolve(response);
							return;
						}
						reject();
					},
				});
			});
		},
		[resetPassword],
	);

	return {
		login: handleLogin,
		logout: handleLogout,
		switchAccount: handleSwitchAccount,
		signUp: handleSignUp,
		acceptInvitation: handleAcceptInvitation,
		acceptInvitationWithNewUser: handleAcceptInvitationWithNewUser,
		activateUser: handleActivateUser,
		forgotPassword: handleForgotPassword,
		resetPassword: handleResetPassword,
	};
};
