import { Button } from "@components/button";
import { HeaderFooterScreenTemplate } from "@components/header-footer-screen-template";
import { Icon } from "@components/icon";
import { Skeleton } from "@components/skeleton";
import { Path } from "@router/paths";
import { success100 } from "@themes/colors";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { IconBackground, TextWrapper, Wrapper } from "./shopping-cart-success.styles";

//TODO: add-translations
export const ShoppingCartSuccessScreenSkeleton = () => {
	const handleOnClick = () => {
		window.location.href = Path.progression.path;
	};

	return (
		<HeaderFooterScreenTemplate>
			<Wrapper>
				<IconBackground>
					<Icon icon="shoppingBasketCheck1" tkaColor={success100} sizeInRem={2.5} />
				</IconBackground>
				<TextWrapper>
					<H1Span>Vielen Dank für Deine Bestellung</H1Span>
					<TkaP2Span>
						Deine Bestellung ist bei uns eingegangen.&nbsp;
						<Skeleton width="100%" className="mt-4" />
					</TkaP2Span>
				</TextWrapper>
				<Button fillParent label="Zu deinen Lerninhalten" onClick={handleOnClick} />
			</Wrapper>
		</HeaderFooterScreenTemplate>
	);
};
