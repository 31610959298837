import tw from "twin.macro";

export const Header = tw.header`
flex
justify-between
flex-wrap
md:flex-nowrap
w-full
mb-[1rem]`;

export const ContentWrapper = tw.div`
md:px-[2rem]
lg:px-[5rem]`;
