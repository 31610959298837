import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	text-center
	gap-40
`;

export const IconWrapper = tw.div`
	w-[7.5rem]
	h-[7.5rem]
	flex
	items-center
	justify-center
	bg-error-0
	rounded-[3.75rem]
	self-center
`;

export const TextWrapper = tw.div`
	flex
	flex-col
	items-center
	text-center
	gap-12
`;
