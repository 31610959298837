import { Icon } from "@components/icon";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade20, shade100, success100 } from "@themes/colors";
import { TkaP2Span, TkaH2Span } from "@themes/font-tags";
import { AsyncContentSubmissionStatus } from "./async-content-status.const";
import { AsyncContentStatusProps } from "./async-content-status.interface";
import { IconLabelWrapper, LineLabel, LineWrapper, Wrapper } from "./async-content-status.styles";

export const AsyncContentStatus = ({
	status = AsyncContentSubmissionStatus.NotSubmitted,
}: AsyncContentStatusProps) => {
	const { t } = useTkaTranslation("asyncContentOverview");

	const submissionIcon =
		status === AsyncContentSubmissionStatus.NotSubmitted ? (
			<Icon icon="question" tkaColor={shade20} sizeInRem={1} />
		) : (
			<Icon icon="checkCircle" tkaColor={success100} sizeInRem={1} />
		);
	const submissionText =
		status === AsyncContentSubmissionStatus.NotSubmitted ? (
			<TkaP2Span tkaColor={shade20}>{t("async_content_overview.pending")}</TkaP2Span>
		) : (
			<TkaP2Span tkaColor={shade100}>{t("async_content_overview.submitted")}</TkaP2Span>
		);

	const reviewIcon =
		status === AsyncContentSubmissionStatus.Reviewed ? (
			<Icon icon="eyeCircle" tkaColor={success100} sizeInRem={1} />
		) : (
			<Icon icon="question" tkaColor={shade20} sizeInRem={1} />
		);
	const reviewText =
		status === AsyncContentSubmissionStatus.Reviewed ? (
			<TkaP2Span tkaColor={shade100}>{t("async_content_overview.reviewed")}</TkaP2Span>
		) : (
			<TkaP2Span tkaColor={shade20}>{t("async_content_overview.pending")}</TkaP2Span>
		);

	return (
		<Wrapper>
			<TkaH2Span>{t("async_content_overview.statusTitle")}</TkaH2Span>
			<LineWrapper>
				<LineLabel>{t("async_content_overview.statusLabel")}:</LineLabel>
				<IconLabelWrapper>
					{submissionIcon}
					{submissionText}
				</IconLabelWrapper>
			</LineWrapper>
			<LineWrapper>
				<LineLabel>{t("async_content_overview.reviewLabel")}:</LineLabel>
				<IconLabelWrapper>
					{reviewIcon}
					{reviewText}
				</IconLabelWrapper>
			</LineWrapper>
		</Wrapper>
	);
};
