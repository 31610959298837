import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button } from "@components/button";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { useWindowSize } from "@hooks/use-window-size";
import { GoToNextElementButton_ContentSubmissionFragment$key } from "@relay/GoToNextElementButton_ContentSubmissionFragment.graphql";
import { GoToNextElementButton_GoToNextElementMutation } from "@relay/GoToNextElementButton_GoToNextElementMutation.graphql";
import { GoToNextElementButton_SubmitDisplayElementMutation } from "@relay/GoToNextElementButton_SubmitDisplayElementMutation.graphql";
import { GoToNextElementButtonProps } from "./go-to-next-element-button.interface";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment GoToNextElementButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
					}
				}
				canGoToNext
			}
		}
	}
`;

const GO_TO_NEXT_ELEMENT_MUTATION = graphql`
	mutation GoToNextElementButton_GoToNextElementMutation($input: GoToNextElementInput!) {
		ELearning {
			goToNextElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

const SUBMIT_DISPLAY_ELEMENT_MUTATION = graphql`
	mutation GoToNextElementButton_SubmitDisplayElementMutation(
		$input: SubmitDisplayElementInput!
	) {
		LearnV2 {
			submitDisplayElement(input: $input) {
				clientMutationId
				contentSubmission {
					id
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const GoToNextElementButton = ({
	contentSubmissionFragmentRef,
}: GoToNextElementButtonProps) => {
	const [goToNextElement, loadingNext] =
		useMutation<GoToNextElementButton_GoToNextElementMutation>(GO_TO_NEXT_ELEMENT_MUTATION);

	const [submitDisplayElement, loadingSubmit] =
		useMutation<GoToNextElementButton_SubmitDisplayElementMutation>(
			SUBMIT_DISPLAY_ELEMENT_MUTATION,
		);
	const contentSubmission = useFragment<GoToNextElementButton_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const { isMediumUp } = useWindowSize();
	const {
		canGoNext,
		goToNextClicked: goToNextClickedInContext,
		onGoToNext,
		nextButtonText,
		loading: loadingElement,
	} = useContext(ContentSubmissionModalContext);

	const contentSubmissionId = contentSubmission.id!;
	const elementId = contentSubmission.definition.currentElementState?.element?.id!;
	const canGoToNextElement = contentSubmission.definition.canGoToNext || false;

	const label = nextButtonText || "Weiter";

	const goToNextClicked = useCallback(() => {
		const handled = goToNextClickedInContext();
		if (handled) return;
		onGoToNext();

		if (canGoToNextElement) {
			goToNextElement({
				variables: {
					input: {
						contentSubmissionId,
					},
				},
			});
		} else {
			submitDisplayElement({
				variables: {
					input: {
						contentSubmissionId,
						displayElementId: elementId,
					},
				},
			});
		}
	}, [
		goToNextClickedInContext,
		onGoToNext,
		canGoToNextElement,
		submitDisplayElement,
		goToNextElement,
		contentSubmissionId,
		elementId,
	]);

	const loading = loadingElement || loadingNext || loadingSubmit;

	return (
		<Button
			loading={loading}
			label={label}
			fillParent={!isMediumUp}
			iconName="arrowRight"
			disabled={!canGoNext}
			minWidthRem={10}
			onClick={goToNextClicked}
		/>
	);
};
