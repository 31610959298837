import tw from "twin.macro";

export const TabWrapper = tw.div`
    flex
    flex-col
    gap-32
    py-32
    overflow-y-scroll
    no-scrollbar
`;

export const CertificateWrapper = tw.div`px-24`;
