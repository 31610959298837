import { Chips } from "primereact/chips";
import styled from "styled-components";
import tw from "twin.macro";
import { shade20 } from "@themes/colors";

export const StyledChips = styled(Chips)`
	width: 100%;

	> ul {
		width: 100%;
		padding: 0.375rem 2.5rem !important;
	}

	> .p-chips {
		padding-left: 2.5rem;
		width: 100%;
		font-family: Roboto, sans-serif;
		font-size: 0.875rem;
	}
	input::placeholder {
		color: ${shade20};
		font-family: Roboto, sans-serif;
		font-size: 0.875rem;
	}
	&.p-chips .p-chips-multiple-container .p-chips-token {
		${tw`
			p-0
			m-2
			rounded-4
			overflow-hidden	
			bg-shade-10
		`};
	}
	&.p-chips .p-chips-multiple-container .p-chips-token > span {
		${tw`
			px-16
			py-8
		`};
	}
	&.p-chips-token p-highlight {
		margin: 2px !important;
	}
`;

export const ChipsWrapper = tw.div`
relative
`;

export const GrowDiv = styled.div`
	${tw`
		flex 
		w-full
	`}
	&.p-input-icon-left > i {
		margin-top: -0.75rem;
	}
`;

export const CloseChipWrapper = tw.div`
	bg-shade-40
	p-[0.5625rem]
`;
