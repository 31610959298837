import { FC, useContext, useState } from "react";
import { useFragment } from "react-relay";
import { Icon } from "@components/icon";
import { useWindowSize } from "@hooks/use-window-size";
import { BatchEdit } from "@screens/account/parts/batch-edit";
import { shade80, error100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./batch-edit-members.graphql";
import { BatchEditMembersProps } from "./batch-edit-members.types";
import { MembersTabContext } from "../../members-tab.context";
import { AssignGroupModal } from "../assign-group-modal";
import { RemoveMemberModal } from "../remove-member-modal";

export const BatchEditMembers: FC<BatchEditMembersProps> = ({ queryFragmentRef }) => {
	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const { isMediumUp } = useWindowSize();
	const { selectedMembers, clearSelectedMembers } = useContext(MembersTabContext);

	const [isVisibleRemoveMemberModal, setIsVisibleRemoveMemberModal] = useState(false);
	const [isVisibleAssignGroupModal, setIsVisibleAssignGroupModal] = useState(false);

	const isVisible = selectedMembers.length > 1;

	const handleOnClear = () => {
		clearSelectedMembers();
	};

	const handleAssignGroupClick = () => {
		setIsVisibleAssignGroupModal(true);
	};

	const handleAssignGroupModalOnDismiss = () => {
		setIsVisibleAssignGroupModal(false);
	};

	const handleAssignGroupOnSuccess = () => {
		handleOnClear();
		handleAssignGroupModalOnDismiss();
	};

	const handleDeleteOnClick = () => {
		setIsVisibleRemoveMemberModal(true);
	};

	const hanldeRemoveMemberModalOnDismiss = () => {
		setIsVisibleRemoveMemberModal(false);
	};

	const handleRemoveMemberOnSuccess = () => {
		handleOnClear();
		hanldeRemoveMemberModalOnDismiss();
	};

	const batchEditVisible =
		isVisible &&
		((!isMediumUp && !isVisibleRemoveMemberModal && !isVisibleAssignGroupModal) || isMediumUp);

	return (
		<>
			{isVisible && (
				<BatchEdit
					isVisible={batchEditVisible}
					selectedCount={selectedMembers.length}
					onClear={handleOnClear}
					optionNodes={(ItemWrapper) => [
						<ItemWrapper key="assign" onClick={handleAssignGroupClick}>
							<Icon icon="folder" sizeInRem={1} tkaColor={shade80} />
							<TkaP2Span tkaColor={shade80}>Gruppe zuweisen</TkaP2Span>
						</ItemWrapper>,
						<ItemWrapper key="delete" onClick={handleDeleteOnClick}>
							<Icon
								icon="trash"
								sizeInRem={1}
								tkaColor={isMediumUp ? shade80 : error100}
							/>
							<TkaP2Span tkaColor={isMediumUp ? shade80 : error100}>
								Benutzer entfernen
							</TkaP2Span>
						</ItemWrapper>,
					]}
				/>
			)}
			<RemoveMemberModal
				isVisible={isVisibleRemoveMemberModal}
				selectedMembers={selectedMembers}
				onDismiss={hanldeRemoveMemberModalOnDismiss}
				onSuccess={handleRemoveMemberOnSuccess}
			/>
			<AssignGroupModal
				isVisible={isVisibleAssignGroupModal}
				selectedMembers={selectedMembers}
				onDismiss={handleAssignGroupModalOnDismiss}
				onSuccess={handleAssignGroupOnSuccess}
				queryFragmentRef={queryFragment}
			/>
		</>
	);
};
