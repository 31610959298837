import { SideMenuProps } from "./side-menu.interface";
import { ContentBackground, Wrapper } from "./side-menu.styles";

export const SideMenu = ({ children, ...props }: SideMenuProps) => {
	return (
		<Wrapper {...props}>
			<ContentBackground />
			{children}
		</Wrapper>
	);
};
