import styled from "styled-components";
import { ReactComponent as IHK } from "@assets/IHK.svg";
import { spacing12, spacing16, spacing4, spacing8 } from "@themes/spacing";

export const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${spacing12.rem()};
`;

export const ImageTitle = styled.div`
	display: flex;
	gap: ${spacing8.rem()};
	position: relative;
`;

export const TaglineTitle = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	gap: ${spacing4.rem()};
`;

export const Tagline = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const IHKIcon = styled(IHK)`
	height: ${spacing16.rem()};
`;
