import React from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Card } from "@components/card";
import { Icon } from "@components/icon";
import { ImageCardAtom } from "@components/ImageCardAtom";
import { coachCard_UserFragment$key } from "@relay/coachCard_UserFragment.graphql";
import { Path } from "@router/paths";
import { brandStrong100, shade80, shade60, shade100 } from "@themes/colors";
import { TkaH3Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import { COACH_PROFILE_FRAGMENT } from "./coach-card.graphql";
import {
	CardWrapper,
	DescriptionWrapper,
	Footer,
	Header,
	IconWrapper,
	Wrapper,
} from "./coach-card.styles";
import { CoachCardProps } from "./coach-card.types";

export const CoachCard = ({ userFragmentRef, onClick }: CoachCardProps) => {
	const coachProfile = useFragment<coachCard_UserFragment$key>(
		COACH_PROFILE_FRAGMENT,
		userFragmentRef ?? null,
	);

	const navigate = useNavigate();

	if (!coachProfile) return null;
	const imageUrl = coachProfile?.profileData.avatar?.url;
	const bio = coachProfile.profileData.description ?? "";

	const handleOnClick = () => {
		if (onClick) return onClick();
		navigate(Path.coachProfile.withId(coachProfile.id).path);
	};

	//TODO add translations
	const coachTitle = "Coach";

	return (
		<CardWrapper>
			<Card fillParent bodyPaddingInRem={0} borderRadiusRem={0.75} onClick={handleOnClick}>
				<Wrapper>
					<Header>
						{imageUrl && (
							<>
								<ImageCardAtom
									isAbsolute
									imageUrl={imageUrl}
									imageWidth={"100%"}
									borderRadiusRem={spacing0}
								/>
								<IconWrapper>
									<Icon
										icon={"closeQuote"}
										sizeInRem={3}
										tkaColor={brandStrong100}
									/>
								</IconWrapper>
							</>
						)}
					</Header>
					<Footer>
						<TkaH3Span tkaColor={shade100}>{coachProfile.coach?.name}</TkaH3Span>
						<TkaP3Span tkaColor={shade60}>{coachTitle}</TkaP3Span>
						<DescriptionWrapper>
							<TkaP2Span tkaColor={shade80}>{bio}</TkaP2Span>
						</DescriptionWrapper>
					</Footer>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};
