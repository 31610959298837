import { graphql } from "babel-plugin-relay/macro";

import { useState } from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";
import { TextElement_ElearningContentSubmissionDefinitionFragment$key } from "@relay/TextElement_ElearningContentSubmissionDefinitionFragment.graphql";
import { brandStrong100 } from "@themes/colors";
import { TkaH4Span, H1Span } from "@themes/font-tags";
import { parseHtml } from "@utils/parse-html";
import { stripHtml } from "@utils/strip-html";
import { TextElementProps } from "./text-element.interface";
import { Spacing32 } from "./text-element.styles";

const E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment TextElement_ElearningContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				title
				id
				... on EnhancedTextLearnElement {
					text
					readMore
					readMoreButtonText
				}
			}
		}
	}
`;

export const TextElement = ({ contentSubmissionDefinitionFragmentRef }: TextElementProps) => {
	const contentSubmissionDefinition =
		useFragment<TextElement_ElearningContentSubmissionDefinitionFragment$key>(
			E_LEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);
	const [showMore, setShowMore] = useState(false);

	const element = contentSubmissionDefinition?.currentElementState?.element;
	const toggleShowMore = () => setShowMore((showMore) => !showMore);

	const showMoreButtonText = match({
		hasButtonText: !!element?.readMoreButtonText,
		showMore,
	})
		.with(
			{ hasButtonText: true, showMore: true },
			() => `${element?.readMoreButtonText} verbergen`,
		)
		.with(
			{ hasButtonText: true, showMore: false },
			() => `${element?.readMoreButtonText} anzeigen`,
		)
		.with({ hasButtonText: false, showMore: true }, () => "Weniger anzeigen")
		.with({ hasButtonText: false, showMore: false }, () => "Mehr anzeigen")
		.exhaustive();

	return (
		<SideMenuContentPadding>
			{element?.title && (
				<>
					<H1Span>{stripHtml(element.title)}</H1Span>
					<Spacing32 />
				</>
			)}
			{element?.text && parseHtml(element?.text)}
			{element?.readMore && (
				<TkaH4Span tkaColor={brandStrong100} onClick={toggleShowMore}>
					{showMoreButtonText}
				</TkaH4Span>
			)}
			{showMore && element?.readMore && parseHtml(element?.readMore)}
		</SideMenuContentPadding>
	);
};
