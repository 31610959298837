import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const AvatarNameWrapper = tw.div`
	flex
	gap-8
	items-center
`;

export const NameWrapper = tw.div`
	flex
	flex-col
`;
