/**
 * @generated SignedSource<<0763c5f9999ed9000e04e98a5b5d693e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type invitationCard_InvitationFragment$data = {
  readonly groups?: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly invitation?: {
    readonly email: string;
    readonly id: string;
  };
  readonly " $fragmentType": "invitationCard_InvitationFragment";
};
export type invitationCard_InvitationFragment$key = {
  readonly " $data"?: invitationCard_InvitationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"invitationCard_InvitationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "invitationCard_InvitationFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Invitation",
          "kind": "LinkedField",
          "name": "invitation",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInAccountGroup",
          "kind": "LinkedField",
          "name": "groups",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InvitationWrapper",
      "abstractKey": null
    }
  ],
  "type": "AccountMember",
  "abstractKey": "__isAccountMember"
};
})();

(node as any).hash = "00d3f736c8ef66c65042a2c3a5ace6c7";

export default node;
