/**
 * @generated SignedSource<<371a72159800d5e6c83a9f2cf8eb962d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AsyncElementTaskStatus = "Evaluated" | "Fresh";
export type ContentKind = "Async" | "ELearning";
import { FragmentRefs } from "relay-runtime";
export type contentCard_LearnOpportunityV2Fragment$data = {
  readonly id: string;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly indexPath: ReadonlyArray<number>;
  readonly lengthInSecondsV2: number;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly contentKind?: ContentKind;
    readonly extension?: {
      readonly isUnlockNecessaryToStart?: boolean;
      readonly receivableGamificationPoints?: number;
      readonly receivedGamificationPoints?: number;
    };
    readonly task?: {
      readonly status: AsyncElementTaskStatus;
    } | null;
  };
  readonly " $fragmentType": "contentCard_LearnOpportunityV2Fragment";
};
export type contentCard_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: contentCard_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentCard_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentKind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "extension",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "receivableGamificationPoints",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "receivedGamificationPoints",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUnlockNecessaryToStart",
          "storageKey": null
        }
      ],
      "type": "LearnContentExtensionImpl",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentCard_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "indexPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lengthInSecondsV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "task",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnAsyncContentTypeDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "1414ea558b0040123e374e2663b7d254";

export default node;
