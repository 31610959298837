import { flex } from "@styled-system/patterns";

export const avatarAndPointsWrapper = flex({
	w: "[fit-content]",
	flexDirection: "column",
});
export const pointsWrapper = flex({
	mt: "-16",
	mx: "auto",
});
