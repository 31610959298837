import { Badge } from "primereact/badge";
import styled from "styled-components";
import { brandStrong100 } from "@themes/colors";
import { TkaL1Typography } from "@themes/font-tags";

export const StyledBadge = styled(Badge)`
	width: 0.875rem;
	max-width: 0.875rem;
	min-width: 0.875rem;
	height: 0.875rem;
	text-align: center;
	${TkaL1Typography}
	line-height: 0.875rem;

	&.p-badge-success {
		background-color: ${brandStrong100.getColor(1)} !important;
	}
`;
