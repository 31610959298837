import { shade20 } from "@themes/colors";
import { CardProps } from "./card.interface";
import { StyledCard } from "./card.styles";
import { bodyPaddingInRemToPaddingString } from "./card.util";

export const Card = ({
	showBorder = true,
	borderColor = shade20,
	children,
	bodyPaddingInRem,
	onClick,
	backgroundColor,
	hasMinContent,
	fillParent,
	fillHeight,
	hasCursorPointer,
	onMouseEnter,
	onMouseLeave,
	contentOverflowHidden,
	tabIndex,
	borderRadiusRem,
	hasOverflowHidden,
}: CardProps) => {
	return (
		<StyledCard
			showBorder={showBorder}
			borderColor={borderColor}
			bodyPadding={bodyPaddingInRemToPaddingString(bodyPaddingInRem)}
			contentOverflowHidden={contentOverflowHidden}
			style={{
				backgroundColor: backgroundColor ? backgroundColor.getColor() : "unset",
				...(hasCursorPointer === true
					? { cursor: "pointer" }
					: hasCursorPointer === false
					? { cursor: "default" }
					: {}),
				...(hasOverflowHidden && { overflow: "hidden" }),
			}}
			onClick={onClick}
			clickable={!!onClick}
			fillParent={fillParent}
			fillHeight={fillHeight}
			hasMinContent={hasMinContent}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			tabIndex={tabIndex}
			borderRadiusRem={borderRadiusRem}
		>
			{children}
		</StyledCard>
	);
};
