import { hasLicenseAttributes, needsLicenseAttributes } from "./license-modal-consts";
import { Wrapper } from "./license-modal.styles";
import { LicenseModalProps } from "./license-modal.types";
import { DialogTemplate } from "../dialog-template";
import { DialogButtonsDirection } from "../dialog-template/dialog-template.types";
import { Divider } from "../divider";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";

export const LicenseModal = ({
	isVisible = false,
	onDismiss,
	onBuyClick,
	variant = "needsLicense",
}: LicenseModalProps) => {
	const props = variant === "needsLicense" ? needsLicenseAttributes : hasLicenseAttributes;
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				buttonsDirection={DialogButtonsDirection.COLUMN}
				secondaryButtonLabel={props.secondaryButtonLabel}
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel={props.primaryButtonLabel}
				primaryButtonIconName={props.primaryButtonIconName}
				onPrimaryButtonClick={onBuyClick}
			>
				<Wrapper>
					<IconHeadlineButtonTemplate
						iconName={props.iconName}
						iconColor={props.iconColor}
						iconBackgroundColor={props.iconBackgroundColor}
						title={props.title}
						subtitle={props.subtitle}
					/>
					<Divider />
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
