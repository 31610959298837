import { AxiosProgressEvent } from "axios";
import uploadFileService from "./upload-file.service";

class InviteOrAddUsersToUserInAccountGroupsViaCsvService {
	upload(file: File, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) {
		return uploadFileService.upload(
			"/invite-or-add-users-to-user-in-account-groups-via-csv",
			file,
			onUploadProgress,
		);
	}
}

export default new InviteOrAddUsersToUserInAccountGroupsViaCsvService();
