import { MultiSelect } from "primereact/multiselect";
import styled from "styled-components";
import tw from "twin.macro";
import { error100, shade40 } from "@themes/colors";

export const StyledMultiSelect = styled(MultiSelect)<{ error?: boolean }>`
	&.p-multiselect {
		height: 40px;
		display: flex;
		align-items: center;
		border-color: ${(props) => (props.error ? error100 : shade40)};
	}
	&.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
		display: flex;
		gap: 0.5rem;
	}
`;

export const Wrapper = tw.div`
flex
flex-col
gap-4`;
