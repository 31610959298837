import { useFragment } from "react-relay";
import { Card } from "@components/card";
import { Icon } from "@components/icon";
import { UserAvatarsCollection } from "@components/user-avatars-collection";
import { shade0, shade60, warning100 } from "@themes/colors";
import { TkaP3Span } from "@themes/font-tags";
import { spacing12 } from "@themes/spacing";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import { AVAILABILITY_FRAGMENT } from "./unconfirmed-appointment-card.graphql";

import {
	Wrapper,
	Title,
	ParticipantsWrapper,
	CardWrapper,
	ColGroup,
	TopColGroup,
	TimeIconWrapp,
} from "./unconfirmed-appointment-card.styles";
import { UnconfirmedAppointmentCardProps } from "./unconfirmed-appointment-card.types";

// TODO: add-translations
export const UnconfirmedAppointmentCard = ({
	onClick,
	availabilityFragmentRef,
}: UnconfirmedAppointmentCardProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const users = participants.map((participant) => participant.user!).filter(Boolean);

	const participantsLabel = `${users.length} Teilnehmer`;

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			true,
		);
	};

	return (
		<CardWrapper>
			<Card
				fillParent
				bodyPaddingInRem={spacing12.remValue()}
				backgroundColor={shade0}
				onClick={onClick}
			>
				<Wrapper>
					<TopColGroup>
						<TimeIconWrapp>
							<TkaP3Span tkaColor={shade60}>{renderTime()}</TkaP3Span>
							<Icon icon="warning" tkaColor={warning100} sizeInRem={1.5} />
						</TimeIconWrapp>
						<Title>{availability.booking.offer?.description?.name}</Title>
					</TopColGroup>
					<ColGroup>
						<ParticipantsWrapper>
							<UserAvatarsCollection userFragmentsRef={users} />
							<TkaP3Span tkaColor={shade60}>{participantsLabel}</TkaP3Span>
						</ParticipantsWrapper>
					</ColGroup>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};
