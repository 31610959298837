import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-4
    px-20
	pt-12
	pb-8
	bg-onBrandMain
	rounded-16
`;

export const BarWrapper = tw.div`
	relative
	flex
	justify-start
	rounded-10
	bg-shade-0
`;

export const Bar = styled.div<{ widthPercent: number; offset: number; backgroundColor?: TkaColor }>`
	${tw`
		rounded-10
		px-10
		py-8
		self-start
		bg-brand-strong-80
	`}
	${({ widthPercent, offset }) => `min-width: calc(${widthPercent}% + ${offset}px);`};
	background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const LegendWrapper = tw.div`
	px-10
	flex
	flex-row
	justify-between
`;

export const Benchmark = styled.div<{
	leftPercent: number;
	leftOffset: number;
}>`
	${tw`
		absolute
		top-12
	`}
	${({ leftPercent, leftOffset }) => `left: max(1rem,calc(${leftPercent}% + ${leftOffset}px));`};
`;
