import styled from "styled-components";

export const Image = styled.img`
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	aspect-ratio: 1.25/1;
	width: 100%;
	height: auto;
	object-fit: cover;
`;
export const ImagePlaceHolder = styled.div`
	width: 100%;
	aspect-ratio: 1.25/1;
`;
