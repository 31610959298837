import tw from "twin.macro";
import { css } from "@styled-system/css";

export const Wrapper = tw.div`
	flex
	flex-col
	text-center
	gap-32
`;

export const TitleClass = css({
	textStyle: "h1",
	color: "error.100",
});
