import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col	
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-row
	justify-end
	p-16
	gap-16
`;
