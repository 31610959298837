import { RadioButton, RadioButtonStatus } from "@components/radio-button";
import { shade20 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { RadioOptionProps } from "./radio-option.interface";
import { Wrapper } from "./radio-option.styles";

export const RadioOption = ({ label, tkaColor, ...props }: RadioOptionProps) => {
	const labelColor =
		tkaColor ?? props.status === RadioButtonStatus.Disabled ? shade20 : undefined;
	return (
		<Wrapper>
			<RadioButton {...props} />
			{label && <TkaP2Span tkaColor={labelColor}>{label}</TkaP2Span>}
		</Wrapper>
	);
};
