import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { RootCard } from "@components/root-card";
import { withSuspense } from "@components/with-suspense/with-suspense.component";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { myCoachingOffers_Query } from "@relay/myCoachingOffers_Query.graphql";
import { Path } from "@router/paths";

import { H1Span } from "@themes/font-tags";
import { QUERY } from "./my-coaching-offers.graphql";
import { MyCoachingOffersScreenSkeleton } from "./my-coaching-offers.skeleton";
import { ListWrapper, Wrapper } from "./my-coaching-offers.styles";

//TODO: add-translations
const MyCoachingOffersScreenComponent = () => {
	const {
		Viewer: { Coaching },
	} = useLazyLoadQuery<myCoachingOffers_Query>(QUERY, {}, { fetchPolicy: "store-and-network" });

	const navigate = useNavigate();
	const { isMediumUp, isLargeUp } = useWindowSize();

	const createCoachingOnClickHandler = (id: string) => () => {
		navigate(Path.coaching.withId(id).path);
	};

	return (
		<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
			<Wrapper>
				<H1Span>Meine Coaching-Angebote</H1Span>
				<ListWrapper>
					<EmptyPlaceholder
						title="Hier ist es aber leer ..."
						subtitle="Bewirb dich jetzt auf weitere Angebote als Coach und erhöhe dadurch deine Reichweite."
						iconName="coachingTeam"
					>
						{Coaching.CoachingOffers.edges?.map(
							(edge) =>
								edge?.node.description?.name && (
									<RootCard
										key={edge.node.id}
										title={edge.node.description.name}
										imageUrl={edge.node.description.image?.url}
										maxWidth={isLargeUp ? 50 : 30}
										onClick={createCoachingOnClickHandler(edge.node.id)}
									/>
								),
						)}
					</EmptyPlaceholder>
				</ListWrapper>
			</Wrapper>
		</ScreenWithProfileMenuLayout>
	);
};

export const MyCoachingOffersScreen = withSuspense(
	MyCoachingOffersScreenComponent,
	MyCoachingOffersScreenSkeleton,
);
