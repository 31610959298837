/**
 * @generated SignedSource<<bf8be6df62e2d33c188ee8268f815c56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type screenSidemenuLayout_AuthViewerSchemaFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"header_AuthViewerSchemaFragment">;
  readonly " $fragmentType": "screenSidemenuLayout_AuthViewerSchemaFragment";
};
export type screenSidemenuLayout_AuthViewerSchemaFragment$key = {
  readonly " $data"?: screenSidemenuLayout_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"screenSidemenuLayout_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "screenSidemenuLayout_AuthViewerSchemaFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "header_AuthViewerSchemaFragment"
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "52849342172c3232d02a0b83173ee74f";

export default node;
