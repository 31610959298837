import { shade100 } from "@themes/colors";
import { MaterialIconProps } from "./material-icon.interface";
import { Card } from "../card/card.component";
import { Icon } from "../icon";

export const MaterialIcon = ({
	icon,
	iconColor,
	showBorder = false,
	onClick,
	backgroundColor,
}: MaterialIconProps) => {
	return (
		<Card
			showBorder={showBorder}
			bodyPaddingInRem={0.5}
			hasMinContent={true}
			onClick={onClick}
			hasCursorPointer={true}
			backgroundColor={backgroundColor}
		>
			<Icon icon={icon} tkaColor={iconColor ?? shade100} sizeInRem={1.5} />
		</Card>
	);
};
