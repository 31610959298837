/**
 * @generated SignedSource<<967b2af1bd954fd84d4a736a39557374>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CountryCode = "AT" | "CH" | "DE";
export type coachingDetails_UserQuery$variables = {};
export type coachingDetails_UserQuery$data = {
  readonly AccountBaseData: {
    readonly AccountBaseData: {
      readonly countryCode?: CountryCode | null;
    };
  };
};
export type coachingDetails_UserQuery = {
  response: coachingDetails_UserQuery$data;
  variables: coachingDetails_UserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countryCode",
    "storageKey": null
  }
],
v1 = {
  "kind": "InlineFragment",
  "selections": (v0/*: any*/),
  "type": "PrivateBaseData",
  "abstractKey": null
},
v2 = {
  "kind": "InlineFragment",
  "selections": (v0/*: any*/),
  "type": "BusinessBaseData",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "coachingDetails_UserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataQueries",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "coachingDetails_UserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataQueries",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "AccountBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a51dff63d67e4a8ad78e2aaac3358678",
    "id": null,
    "metadata": {},
    "name": "coachingDetails_UserQuery",
    "operationKind": "query",
    "text": "query coachingDetails_UserQuery {\n  AccountBaseData {\n    AccountBaseData {\n      __typename\n      ... on PrivateBaseData {\n        countryCode\n      }\n      ... on BusinessBaseData {\n        countryCode\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ccdb00e5891c5efbe328370860a78749";

export default node;
