/**
 * @generated SignedSource<<4fa539cc3d0274ca1c42e7d128bda73e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TrendMediaKind = "image" | "video";
import { FragmentRefs } from "relay-runtime";
export type trendMedia_TrendMediaFragment$data = {
  readonly trendData: {
    readonly description: string;
    readonly name: string;
    readonly tags: ReadonlyArray<{
      readonly id: string;
    }>;
  };
  readonly trendMedia: {
    readonly file: {
      readonly url: string | null;
    } | null;
    readonly kind: TrendMediaKind;
  };
  readonly " $fragmentType": "trendMedia_TrendMediaFragment";
};
export type trendMedia_TrendMediaFragment$key = {
  readonly " $data"?: trendMedia_TrendMediaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendMedia_TrendMediaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "trendMedia_TrendMediaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TrendData",
      "kind": "LinkedField",
      "name": "trendData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "trendMedia",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "file",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Trend",
  "abstractKey": null
};

(node as any).hash = "c6ba217d17c51117befadf069db5541f";

export default node;
