import { FC } from "react";
import { FocusContentMenu } from "@components/focus-content-menu";
import { SideMenu } from "@containers/side-menu";
import { Spacing24 } from "@screens/profile/profile.styles";
import { LicenseSideMenuProps } from "./licenses-side-menu.types";
import { LicenseProductCard } from "../license-product-card/license-product-card.component";

export const LicenseSideMenu: FC<LicenseSideMenuProps> = ({ queryFragmentRef }) => {
	const items = [
		{ label: "Inhalt", id: "content" },
		{ label: "Zuweisungen", id: "assignments" },
		{ label: "Statistiken", id: "statistics" },
	];

	return (
		<>
			<LicenseProductCard queryFragmentRef={queryFragmentRef} />
			<Spacing24 />
			<SideMenu>
				<FocusContentMenu items={items} enableSpy={true} />
			</SideMenu>
		</>
	);
};
