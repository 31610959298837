import { useContext, useEffect } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { DividerList } from "@components/divider-list";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { Icon } from "@components/icon";
import { Message } from "@components/message";
import { ParticipantPresenceCheck } from "@components/participant-presence-check";
import { useWindowSize } from "@hooks/use-window-size";
import { AppointmentsScreenContext } from "@screens/appointments/appointments.context";
import { TkaP2Span } from "@themes/font-tags";
import { spacing12, spacing24 } from "@themes/spacing";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import { isDateInNextMinutes } from "@utils/date-utils";
import { AVAILABILITY_FRAGMENT } from "./appointment-presence.graphql";
import {
	ButtonsWrapper,
	MessageWrapper,
	PresenceCheckWrapper,
	PresenceContentWrapper,
	Wrapper,
	RowWrapper,
} from "./appointment-presence.styles";
import { AppointmentPresenceProps } from "./appointment-presence.types";

// TODO: add-translations
export const AppointmentPresence = ({
	onClose,
	availabilityFragmentRef,
}: AppointmentPresenceProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const { isMediumUp } = useWindowSize();
	const { refreshUnconfirmed } = useContext(AppointmentsScreenContext);

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			true,
			"long",
		);
	};

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const hasNotConfirmeParticipants =
		participants.some((participant) => participant.participationStatus === "NotConfirmed") ??
		true;

	useEffect(() => {
		if (!hasNotConfirmeParticipants) {
			refreshUnconfirmed();
		}
	}, [hasNotConfirmeParticipants]);

	const canEditPresence = isDateInNextMinutes(availability.data.startDateTime, 5);

	return (
		<Wrapper>
			<EmptyPlaceholder
				isVisible={!canEditPresence}
				iconName="star"
				title="Zu früh ..."
				subtitle="Du kannst die Anwesenheiten frühstens 5 Minuten vor dem Termin festlegen."
			>
				<PresenceContentWrapper>
					<MessageWrapper>
						<RowWrapper>
							<Icon icon="calendar" sizeInRem={1.5} />
							<TkaP2Span>{renderTime()}</TkaP2Span>
						</RowWrapper>
						<Message
							highlighted
							severity="success"
							detail="Bitte vergiss nicht, die Anwesenheit der Teilnehmer zu bestätigen. Das ist wichtig für deine Honorierung und für die Qualitätssicherung unserer Plattform."
						/>
					</MessageWrapper>
					<PresenceCheckWrapper>
						<DividerList
							hasDivider
							gapRem={(isMediumUp ? spacing12 : spacing24).remValue()}
						>
							{participants.map((participant) => (
								<ParticipantPresenceCheck
									key={participant.user?.id}
									availabilityId={availability.id}
									participantFragmentRef={participant}
								/>
							))}
						</DividerList>
					</PresenceCheckWrapper>
				</PresenceContentWrapper>
			</EmptyPlaceholder>
			<Divider />
			<ButtonsWrapper>
				<div />
				<Button
					fillParent={!isMediumUp}
					label="Zurück"
					colorVersion="tertiary"
					onClick={onClose}
				/>
			</ButtonsWrapper>
		</Wrapper>
	);
};
