import { useCookies as useReactCookies } from "react-cookie";
import { Cookie, CookieManager, CookieSetOptions } from "./use-cookies.types";

export const useCookies = <T>(cookie: Cookie): CookieManager<T> => {
	const [cookies, setCookie] = useReactCookies([cookie]);
	const selectedCookie = cookies[cookie];
	const hasCookie = selectedCookie !== undefined;

	const handleSetCookie = (value: T, options?: CookieSetOptions) => {
		setCookie(cookie, value, options);
	};

	return {
		cookie: selectedCookie,
		cookieExists: hasCookie,
		setCookie: handleSetCookie,
	};
};
