import React from "react";
import { Divider } from "@components/divider";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";

import { IntroductorySection } from "@screens/avgs-potential-analysis/parts/introductory-section";
import { navigationWrapperClass, wrapperClass } from "./avgs-potential-analysis.styles";
import { AvgsPotentialAnalysisNavigationSkeleton } from "./parts/avgs-potential-analysis-navigation";
import { ModuleSectionSkeleton } from "./parts/module-section";

export const AvgsPotentialAnalysisSkeleton = () => {
	return (
		<ScreenWithNavbarLayout
			singlePageMode
			bottomContent={
				<div className={navigationWrapperClass}>
					<AvgsPotentialAnalysisNavigationSkeleton />
				</div>
			}
		>
			<div className={wrapperClass}>
				<IntroductorySection />
				<Divider />
				<ModuleSectionSkeleton />
			</div>
		</ScreenWithNavbarLayout>
	);
};
