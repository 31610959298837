import tw from "twin.macro";

export const Content = tw.div`
	flex
	flex-col
	flex-1
	p-24
	md:pt-0
	xxl:px-80
	xxl:py-40
	w-[min(100vw,25rem)]
	md:w-auto
	m-auto
	md:m-0
	overflow-y-hidden
`;

export const ChildrenWidth = tw.div`
	flex
	flex-col
	flex-1
	md:w-[21rem]
	overflow-y-hidden
`;
