import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	pt-24
	gap-24
`;

export const LeftWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	gap-24
	md:gap-32
	md:items-start
	md:justify-center
`;
export const RowWrapper = tw.div`
	flex
	flex-row
	gap-16
	items-center
`;

export const RaitingInfoWrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const OverviewWrapper = tw.div`
	flex
	flex-col
	flex-1
	gap-12
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	justify-between
	gap-8
`;
