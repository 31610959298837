import tw from "twin.macro";

export const StyledOl = tw.ol`
	list-decimal
	pl-20
`;
export const StyledUl = tw.ol`
	list-disc
	pl-20
`;
export const Spacing32 = tw.div`mt-32`;
export const Spacing12 = tw.div`mt-12`;
export const Spacing8 = tw.div`mt-8`;

export const Image = tw.img`w-full rounded-10`;
