import { createContext } from "react";

type RootScreenContext = {
	spyEnabled: boolean;
	setSpyEnabled: (enable: boolean) => void;
	lastContentId?: string;
	setLastContentId: (id: string) => void;
};
export const RootScreenContext = createContext<RootScreenContext>({
	spyEnabled: false,
	setSpyEnabled: () => {},
	setLastContentId: () => {},
});
