import { UserCardSkeleton } from "@components/user-card/user-card.skeleton";
import { TkaH2Span } from "@themes/font-tags";
import { MemberCardsWrapper } from "./members-tab.styles";

//TODO: add-translations
export const MembersTabSkeleton = () => {
	return (
		<MemberCardsWrapper>
			<TkaH2Span>Mitglieder</TkaH2Span>
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
			<UserCardSkeleton />
		</MemberCardsWrapper>
	);
};
