import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	flexDirection: "row",
	maxW: "[24rem]",
	alignItems: "center",
	p: "16",
	gap: "16",
	bg: "shade.10",
	borderRadius: "12",
});
