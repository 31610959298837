import { ValidatedInputMaskProps } from "./validated-input-mask.types";
import { InputMask } from "../input-mask";

export const ValidatedInputMask = <S,>({
	formikConfig,
	helpText,
	icon,
	label,
	name,
	placeholder,
	status = "default",
	type = "default",
	mask,
}: ValidatedInputMaskProps<S>) => {
	const errorText = formikConfig.errors[name] as unknown as string | undefined;
	const hasError = !!(errorText && formikConfig.touched[name]);

	const value = formikConfig.values[name] as unknown as string | undefined;

	const updateValue = (updatedValue: string | undefined) => {
		formikConfig.setFieldTouched(name, true);
		formikConfig.setFieldValue(name, updatedValue);
	};

	const useStatus = hasError ? "error" : status;
	const textBelow = hasError ? errorText : helpText;
	return (
		<InputMask
			icon={icon}
			label={label}
			name={name}
			onChange={updateValue}
			placeholder={placeholder}
			smallTextBelow={textBelow}
			status={useStatus}
			type={type}
			value={value}
			mask={mask}
		/>
	);
};
