import styled from "styled-components";
import tw from "twin.macro";
import { shade100 } from "@themes/colors";

export const BatchEditBar = styled.div`
	${tw`
		flex
		flex-row
		items-center
		gap-24
		fixed
		md:static
		left-24
		right-24
		bottom-[6.125rem]
		md:bottom-0
		px-16
		py-12
		md:px-24
		md:py-16
		bg-shade-5
		rounded-[0.75rem]
		md:shadow-none 
		z-[100]
		border-[1px]
		border-shade-20
		md:mb-[1rem]
	`}
	box-shadow: 0.625rem 0.625rem 2.25rem 0rem ${shade100.withAlpha(
		0.1,
	)}, -0.0625rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.03);
`;

export const OptionsWrapper = tw.div`
	flex
	flex-col
	flex-1
	md:flex-row
	md:gap-24
`;

export const ItemWrapper = tw.div`
	flex
	flex-row
	gap-12
    md:gap-8
    py-16
    md:p-0
	hover:opacity-60
	hover:cursor-pointer
`;

export const VerticalDivider = tw.div`
	bg-shade-20
	w-[0.0625rem]
	h-[1rem]
	md:h-[1.5rem]
`;

export const ActionWrapper = tw.div`
	flex-1	
	flex
	flex-row
	gap-8
	hover:cursor-pointer
	hover:opacity-60
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
	mt-16
`;

export const CenterSpacer = tw.div`
	mt-8
	mb-24
`;

export const HoverOpacity = tw.div`
	hover:opacity-60
	hover:cursor-pointer
`;
