import { useNavigate, useOutletContext } from "react-router-dom";
import { useCurrentPath } from "@hooks/use-current-path";
import { TAB_INDEX_TO_ROUTES_LIST } from "./account.consts";
import { OutletContext } from "./account.types";

export const useTabIndexFromPath = () => {
	const currentPath = useCurrentPath();
	const path = currentPath?.pathname;
	const index = TAB_INDEX_TO_ROUTES_LIST.findIndex((route) => path?.startsWith(route));
	return index > -1 ? index : 0;
};

export const useNavigateToTab = () => {
	const navigate = useNavigate();
	const navigateToTab = (index: number) => {
		const tabPath = TAB_INDEX_TO_ROUTES_LIST[index];
		tabPath !== undefined && navigate(tabPath, { replace: true });
	};
	return navigateToTab;
};

export const useGroupsTabProps = () => {
	return useOutletContext<OutletContext>().groupsTabProps;
};
