import tw from "twin.macro";
import { TkaP2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	flex
	flex-row
	gap-4
	px-8
	py-4
	bg-shade-5
	rounded-8
	overflow-hidden
`;

export const Name = tw(TkaP2Span)`
	whitespace-nowrap
`;

export const Link = tw(TkaP2Span)`
	truncate
`;
