import { PathParams } from "@thekeytechnology/epic-ui";
import { useEffect } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { gtmTrackPurchase } from "@analytics/google-tag-manager";
import { Button } from "@components/button";
import { HeaderFooterScreenTemplate } from "@components/header-footer-screen-template";
import { Icon } from "@components/icon";
import { withSuspense } from "@components/with-suspense";
import { shoppingCartSuccess_OrderFragment$key } from "@relay/shoppingCartSuccess_OrderFragment.graphql";
import { shoppingCartSuccess_Query } from "@relay/shoppingCartSuccess_Query.graphql";
import { Path, ShoppingCartPath } from "@router/paths";
import { success100 } from "@themes/colors";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { QUERY, SUCCESS_SCREEN_ORDER_FRAGMENT } from "./shopping-cart-success.graphql";
import { ShoppingCartSuccessScreenSkeleton } from "./shopping-cart-success.skeleton";
import { IconBackground, TextWrapper, Wrapper } from "./shopping-cart-success.styles";

//TODO: add-translations
export const ShoppingCartSuccessScreenComponent = () => {
	const { shoppingCartId } = useParams<PathParams<typeof ShoppingCartPath>>();
	const {
		node,
		Viewer: {
			Auth: { currentUser },
		},
	} = useLazyLoadQuery<shoppingCartSuccess_Query>(
		QUERY,
		{
			orderId: shoppingCartId ?? "",
			skip: !shoppingCartId,
		},
		{ fetchPolicy: "network-only" },
	);

	const order = useFragment<shoppingCartSuccess_OrderFragment$key>(
		SUCCESS_SCREEN_ORDER_FRAGMENT,
		node ?? null,
	);

	useEffect(() => {
		if (!shoppingCartId) return;
		const products =
			order && order.cart?.items.filter((item) => item.itemType === "CartProduct");
		gtmTrackPurchase(
			products?.map((product) => {
				return {
					base64EncodedProductId: product.product?.id!,
					quantity: product.amount!,
					productName: product.product?.title ?? "",
				};
			}) ?? [],
			order?.cart?.totals.includingAllDiscounts.netPrice!,
			shoppingCartId,
		);
	}, [shoppingCartId, order]);

	const handleOnClick = () => {
		window.location.href = Path.progression.path;
	};

	const userEmail = currentUser?.user.email;

	const SuccessComponent = () => {
		return (
			<Wrapper>
				<TextWrapper>
					<IconBackground>
						<Icon icon="shoppingBasketCheck1" tkaColor={success100} sizeInRem={2.5} />
					</IconBackground>

					<H1Span>Vielen Dank für Deine Bestellung</H1Span>
					<TkaP2Span>
						Deine Bestellung ist bei uns eingegangen.&nbsp;
						{userEmail
							? `Du erhältst eine Bestätigung an folgende E-Mailadresse: ${userEmail}`
							: ""}
					</TkaP2Span>
				</TextWrapper>

				<Button fillParent label="Zu deinen Lerninhalten" onClick={handleOnClick} />
			</Wrapper>
		);
	};

	return (
		<HeaderFooterScreenTemplate>
			<SuccessComponent />
		</HeaderFooterScreenTemplate>
	);
};

export const ShoppingCartSuccessScreen = withSuspense(
	ShoppingCartSuccessScreenComponent,
	ShoppingCartSuccessScreenSkeleton,
);
