import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useEffect } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AsyncContentSubmissionFilePreview } from "@components/async-content-submission-file-preview";
import { AsyncContentSubmissionFileSuccess } from "@components/async-content-submission-file-success";
import { AsyncContentSubmissionFileUpload } from "@components/async-content-submission-file-upload";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { UploadElement_ContentSubmissionFragment$key } from "@relay/UploadElement_ContentSubmissionFragment.graphql";
import { UploadElement_SubmitUploadAsyncElementMutation } from "@relay/UploadElement_SubmitUploadAsyncElementMutation.graphql";
import { shade100 } from "@themes/colors";
import { H1Span } from "@themes/font-tags";
import { parseHtml } from "@utils/parse-html";
import { UploadElementProps } from "./upload-element.interface";
import { TaskDescription, Wrapper } from "./upload-element.styles";

const ACTIVE_ASYNC_CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment UploadElement_ContentSubmissionFragment on ContentSubmission {
		id
		learnOpportunity {
			structureDefinition {
				title
			}
			description
			indexPath
		}
		definition {
			... on PassedAsyncContentSubmissionDefinition {
				status
				element {
					... on UploadAsyncLearnElement {
						file {
							id
							name
							url
						}
					}
				}
			}
			... on ActiveAsyncContentSubmissionDefinition {
				status
				element {
					... on UploadAsyncLearnElement {
						file {
							id
							name
							url
						}
					}
					id
					taskDescription
				}
			}
		}
	}
`;

const SUBMIT_UPLOAD_ASYNC_ELEMENT_MUTATION = graphql`
	mutation UploadElement_SubmitUploadAsyncElementMutation(
		$input: SubmitUploadAsyncElementInput!
	) {
		AsyncContent {
			submitUploadAsyncElement(input: $input) {
				clientMutationId
				contentSubmission {
					id
					learnOpportunity {
						...contentOverview_LearnOpportunityV2Fragment
					}
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const UploadElement = ({ contentSubmissionFragmentRef, refetch }: UploadElementProps) => {
	const contentSubmission = useFragment<UploadElement_ContentSubmissionFragment$key>(
		ACTIVE_ASYNC_CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitUploadAsyncElement] = useMutation<UploadElement_SubmitUploadAsyncElementMutation>(
		SUBMIT_UPLOAD_ASYNC_ELEMENT_MUTATION,
	);

	const { t } = useTkaTranslation("uploadElementScreen");
	const navigate = useNavigate();

	const { setNextButtonText, setCanGoNext, addGoToNextOnClickListener } = useContext(
		ContentSubmissionModalContext,
	);

	const element = contentSubmission?.definition?.element;
	const status = contentSubmission?.definition?.status;

	useEffect(() => {
		status === "active" &&
			setNextButtonText(t("upload_element_screen.submitDocumentButtonLabel"));
	}, []);

	useEffect(() => {
		element?.file?.id && setCanGoNext(true);
	}, [element?.file?.id]);

	const handleGoToNextClicked = useCallback(() => {
		if (element?.file?.id && status === "active") {
			setCanGoNext(false);
			submitUploadAsyncElement({
				variables: {
					input: {
						contentSubmissionId: contentSubmission.id!,
						uploadAsyncElementId: element?.id!,
						fileId: element.file.id,
					},
				},
				onCompleted: () => {
					setCanGoNext(true);
					setNextButtonText(undefined);
				},
			});
			return true;
		}
		if (status === "passed") {
			navigate(-1);
			return true;
		}
		return true;
	}, [
		status,
		element?.file?.id,
		element?.id,
		contentSubmission.id,
		setCanGoNext,
		setNextButtonText,
		submitUploadAsyncElement,
	]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	return (
		<Wrapper>
			{status === "passed" ? (
				<AsyncContentSubmissionFileSuccess
					fileName={element?.file?.name}
					url={element?.file?.url || undefined}
				/>
			) : (
				<>
					<H1Span>{t("upload_element_screen.taskTitle")}</H1Span>
					<TaskDescription tkaColor={shade100}>
						{element?.taskDescription && parseHtml(element?.taskDescription)}
					</TaskDescription>
					{element?.file?.name ? (
						<AsyncContentSubmissionFilePreview
							title={t("upload_element_screen.fileUploadTitle")}
							fileName={element.file.name}
							url={element.file.url || undefined}
						/>
					) : (
						element?.id && (
							<AsyncContentSubmissionFileUpload
								asyncElementId={element?.id}
								onFileUploaded={refetch}
							/>
						)
					)}
				</>
			)}
		</Wrapper>
	);
};
