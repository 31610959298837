import { useMutation } from "react-relay";
import { useStartPotentialAnalysis_ClickoutMutation } from "@relay/useStartPotentialAnalysis_ClickoutMutation.graphql";
import { POTENTIAL_ANALYSIS_CLICKOUT_MUTATION } from "@screens/orientation/parts/start-potential-analysis-modal/start-potential-analysis-modal.graphql";
import {
	gtmTrackPotentialAnalysisMax,
	gtmTrackPotentialAnalysisMini,
} from "../../../../analytics/google-tag-manager";

export const useStartPotentialAnalysis = () => {
	const [generateClickout, isGeneratingClickout] =
		useMutation<useStartPotentialAnalysis_ClickoutMutation>(
			POTENTIAL_ANALYSIS_CLICKOUT_MUTATION,
		);

	const goToAnalysis = (shortTest: boolean, onCompleted?: () => void) => {
		if (isGeneratingClickout) return;
		generateClickout({
			variables: { input: { shortTest } },
			onCompleted: (e) => {
				if (e.PotentialAnalysis.createPotentialAnalysisClickout?.clickout) {
					if (shortTest) {
						gtmTrackPotentialAnalysisMini();
					} else {
						gtmTrackPotentialAnalysisMax();
					}
					window.open(
						e.PotentialAnalysis.createPotentialAnalysisClickout.clickout,
						"_blank",
						"noreferrer",
					);
					onCompleted?.();
				}
			},
		});
	};

	return { goToAnalysis };
};
