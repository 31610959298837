import React, { useEffect, useRef } from "react";
import { Icon } from "@components/icon";
import { useWindowSize } from "@hooks/use-window-size";
import { brandStrong120 } from "@themes/colors";
import { P2_LINE_HEIGHT_REM } from "@themes/font-tags";
import { VERTICAL_PADDING_PX } from "./quote-card.cosnts";
import { BackgroundWrapper, InplaceWrapper, Text, Wrapper } from "./quote-card.styles";
import { QuoteCardProps } from "./quote-card.types";

export const QuoteCard = ({ text }: QuoteCardProps) => {
	const { isLargeUp } = useWindowSize();
	const inplaceRef = useRef<HTMLDivElement>(null);
	const [lines, setLines] = React.useState(1);

	useEffect(() => {
		if (!inplaceRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			const height = (inplaceRef.current?.clientHeight ?? 1) - VERTICAL_PADDING_PX * 2;
			const lines = Math.max(1, Math.floor(height / (P2_LINE_HEIGHT_REM * 16)));
			setLines(lines);
		});
		resizeObserver.observe(inplaceRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<Wrapper>
			<BackgroundWrapper>
				<Icon icon={"closeQuote"} sizeInRem={1.5} tkaColor={brandStrong120} />
				<Text lines={!isLargeUp ? 6 : lines} tkaColor={brandStrong120}>
					{text}
				</Text>
			</BackgroundWrapper>
			<InplaceWrapper ref={inplaceRef} />
		</Wrapper>
	);
};
