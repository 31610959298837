/**
 * @generated SignedSource<<ea018018513a056eb756ae9d8b6953c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type blockoutDateCard_BlockoutDateFragment$data = {
  readonly from: string;
  readonly to: string;
  readonly " $fragmentSpreads": FragmentRefs<"blockoutDateModal_BlockoutDateFragment">;
  readonly " $fragmentType": "blockoutDateCard_BlockoutDateFragment";
};
export type blockoutDateCard_BlockoutDateFragment$key = {
  readonly " $data"?: blockoutDateCard_BlockoutDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"blockoutDateCard_BlockoutDateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "blockoutDateCard_BlockoutDateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "blockoutDateModal_BlockoutDateFragment"
    }
  ],
  "type": "BlockoutDate",
  "abstractKey": null
};

(node as any).hash = "ae29c8c0cd5b42591aa3adfd5090b9ad";

export default node;
