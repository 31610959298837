import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query gridView_Query(
		$tagIds: [ID!]!
		$kinds: [LearnableKind!]!
		$first: Int
		$last: Int
		$before: String
		$after: String
		$text: String
	) {
		...gridView_QueryFragment
			@arguments(
				kinds: $kinds
				tagIds: $tagIds
				first: $first
				last: $last
				before: $before
				after: $after
				text: $text
			)
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment gridView_QueryFragment on Query
	@refetchable(queryName: "gridViewRefetchQuery")
	@argumentDefinitions(
		tagIds: { type: "[ID!]!" }
		kinds: { type: "[LearnableKind!]!" }
		first: { type: "Int", defaultValue: null }
		last: { type: "Int", defaultValue: null }
		before: { type: "String", defaultValue: null }
		after: { type: "String", defaultValue: null }
		text: { type: "String", defaultValue: null }
	) {
		Learnable {
			PublishedLearnables(
				before: $before
				after: $after
				first: $first
				last: $last
				tagIds: $tagIds
				text: $text
				kinds: $kinds
			) @connection(key: "gridView_PublishedLearnables") {
				edges {
					node {
						id
						kind
						...gridView_PublishedLearnableFragment
					}
				}
			}
		}
		...tags_AcademiesRooTagsConnectionFragment
	}
`;

export const PUBLISHED_LEARNABLE_INLINE_FRAGMENT = graphql`
	fragment gridView_PublishedLearnableFragment on PublishedLearnable @inline {
		kind
		id

		...coachingLearnableCard_PublishedLearnableFragment
		...educationalOfferCard_PublishedCourseLearnableFragment
	}
`;
