import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { Path } from "@router/paths";
import { selectIsLoggedIn } from "@slices/AuthSlice";
import { error100 } from "@themes/colors";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { IconWrapper, TextWrapper, Wrapper } from "./accept-invite-error.styles";
import { Button } from "../button";
import { Icon } from "../icon";

export const AcceptInviteError = () => {
	const navigate = useNavigate();

	const { t } = useTkaTranslation("inviteScreens");

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const handleGoToOnClick = () => {
		if (isLoggedIn) {
			navigate(Path.progression.path);
		} else {
			navigate(Path.login.path);
		}
	};

	const ctaText = isLoggedIn
		? t("invite_screens.invalidInviteLoggedInCta")
		: t("invite_screens.invalidInviteLoggedOutCta");
	return (
		<Wrapper>
			<IconWrapper>
				<Icon sizeInRem={2.5} icon="close" tkaColor={error100} />
			</IconWrapper>
			<TextWrapper>
				<H1Span>{t("invite_screens.invalidInviteTitle")}</H1Span>
				<TkaP2Span>{t("invite_screens.invalidInviteText")}</TkaP2Span>
			</TextWrapper>
			<Button fillParent label={ctaText} onClick={handleGoToOnClick} />
		</Wrapper>
	);
};
