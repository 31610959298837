/**
 * @generated SignedSource<<74e5ef84a4e298fc8814242525f61adf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMyAvailabilityScheduleInput = {
  clientMutationId?: string | null;
  scheduleData: ScheduleDataInput;
};
export type ScheduleDataInput = {
  blockoutDates: ReadonlyArray<BlockoutDateInput>;
  daysAndTimes: ReadonlyArray<DayAndTimesInput>;
  overrideDaysAndTimesForCalendarWeek: ReadonlyArray<DayAndTimesForCalendarWeekInput>;
};
export type DayAndTimesInput = {
  dayOfWeek: string;
  timeSlots: ReadonlyArray<string>;
};
export type BlockoutDateInput = {
  from: string;
  to: string;
};
export type DayAndTimesForCalendarWeekInput = {
  calendarWeek: number;
  calendarYear: number;
  dayOfWeek: string;
  timeSlots: ReadonlyArray<string>;
};
export type myAvailabilities_EditMyAvailabilityScheduleMutation$variables = {
  input: EditMyAvailabilityScheduleInput;
};
export type myAvailabilities_EditMyAvailabilityScheduleMutation$data = {
  readonly Coaching: {
    readonly editMyAvailabilitySchedule: {
      readonly schedule: {
        readonly data: {
          readonly overrideDaysAndTimesForCalendarWeek: ReadonlyArray<{
            readonly calendarWeek: number;
            readonly calendarYear: number;
            readonly dayOfWeek: string;
            readonly timeSlots: ReadonlyArray<string>;
          }>;
        };
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"myAvailabilities_AvailabilityScheduleFragment">;
      };
    } | null;
  };
};
export type myAvailabilities_EditMyAvailabilityScheduleMutation = {
  response: myAvailabilities_EditMyAvailabilityScheduleMutation$data;
  variables: myAvailabilities_EditMyAvailabilityScheduleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayOfWeek",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeSlots",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "DayAndTimesForCalendarWeek",
  "kind": "LinkedField",
  "name": "overrideDaysAndTimesForCalendarWeek",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarYear",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "myAvailabilities_EditMyAvailabilityScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoachingMutations",
        "kind": "LinkedField",
        "name": "Coaching",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditMyAvailabilitySchedulePayload",
            "kind": "LinkedField",
            "name": "editMyAvailabilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailabilitySchedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "myAvailabilities_AvailabilityScheduleFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "myAvailabilities_EditMyAvailabilityScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoachingMutations",
        "kind": "LinkedField",
        "name": "Coaching",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditMyAvailabilitySchedulePayload",
            "kind": "LinkedField",
            "name": "editMyAvailabilitySchedule",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AvailabilitySchedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AvailabilityScheduleDayAndTimes",
                        "kind": "LinkedField",
                        "name": "daysAndTimes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BlockoutDate",
                        "kind": "LinkedField",
                        "name": "blockoutDates",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "from",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "to",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dcbe5d7e57f290c4eaa584fabba8d723",
    "id": null,
    "metadata": {},
    "name": "myAvailabilities_EditMyAvailabilityScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation myAvailabilities_EditMyAvailabilityScheduleMutation(\n  $input: EditMyAvailabilityScheduleInput!\n) {\n  Coaching {\n    editMyAvailabilitySchedule(input: $input) {\n      schedule {\n        id\n        data {\n          overrideDaysAndTimesForCalendarWeek {\n            calendarWeek\n            calendarYear\n            dayOfWeek\n            timeSlots\n          }\n        }\n        ...myAvailabilities_AvailabilityScheduleFragment\n      }\n    }\n  }\n}\n\nfragment blockoutDateCard_BlockoutDateFragment on BlockoutDate {\n  from\n  to\n  ...blockoutDateModal_BlockoutDateFragment\n}\n\nfragment blockoutDateModal_BlockoutDateFragment on BlockoutDate {\n  from\n  to\n}\n\nfragment myAvailabilities_AvailabilityScheduleFragment on AvailabilitySchedule {\n  __typename\n  id\n  data {\n    __typename\n    daysAndTimes {\n      dayOfWeek\n      timeSlots\n    }\n    blockoutDates {\n      from\n      to\n      ...blockoutDateCard_BlockoutDateFragment\n    }\n    overrideDaysAndTimesForCalendarWeek {\n      ...myAvailabilities_OverrideDaysAndTimesFragment\n    }\n  }\n}\n\nfragment myAvailabilities_OverrideDaysAndTimesFragment on DayAndTimesForCalendarWeek {\n  calendarWeek\n  calendarYear\n  dayOfWeek\n  timeSlots\n}\n"
  }
};
})();

(node as any).hash = "42e88b76d31a9165f1416597bf490ee3";

export default node;
