import tw from "twin.macro";

export const WordInventoryContainer = tw.div`flex flex-col`;

export const WordInventoryHeaderWrapper = tw.div`flex items-center`;

export const FilterButtonWrapper = tw.div`ml-auto`;

export const EmptyWrapper = tw.div`flex items-center justify-center`;

export const Spacing24 = tw.div`mb-24`;
