import styled, { css } from "styled-components";
import { TkaColor } from "@themes/colors";

export const IconWrapper = styled.div<{
	backgroundColor?: TkaColor;
	fillAlsoColor?: TkaColor;
	opacity?: number;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: min-content;
	${({ backgroundColor }) => backgroundColor && CircleCss};
	${({ fillAlsoColor }) => fillAlsoColor && PathFillAlsoCss}
	${({ opacity }) => (opacity !== undefined ? `opacity: ${opacity};` : "")};
`;

export const CircleCss = css<{ backgroundColor?: TkaColor }>`
	background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "initial")};
	padding: 0.75rem;
	border-radius: 100%;
`;

export const PathFillAlsoCss = css<{ fillAlsoColor?: TkaColor }>`
	path.fill-also {
		fill: ${({ fillAlsoColor }) => fillAlsoColor};
	}
`;
