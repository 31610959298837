import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor, shade5 } from "@themes/colors";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const ContentWrapper = styled.div<{ backgroundColor?: TkaColor }>`
	${tw`
		flex
		p-24
		rounded-8
		gap-12
		items-center
	`}
	background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : shade5)}
`;
