import { useContext, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { ColorVersion } from "@components/button";
import { TkaIconName } from "@components/icon";
import { ProgressBar } from "@components/progress-bar";
import { avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment$key } from "@relay/avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment.graphql";
import { AvgsPotentialAnalysisContext } from "@screens/avgs-potential-analysis/avgs-potential-analysis.context";
import { colorPrimary100Class } from "@themes/color-classes";
import { H3Span } from "@themes/font-tags";
import { LEARN_POTENTIAL_ANALYSIS_FRAGMENT } from "./avgs-potential-analysis-navigation.graphql";
import {
	progressBarWrapperClass,
	spacerClass,
	trackerWrapperClass,
	wrapperClass,
} from "./avgs-potential-analysis-navigation.styles";
import { AvgsPotentialAnalysisNavigationProps } from "./avgs-potential-analysis-navigation.types";
import { isQuestionAnswered } from "./avgs-potential-analysis-navigation.utils";
import { AvgsPotentialAnalysisFinishedModal } from "../avgs-potential-analysis-finished-modal";
import { DIMENSION_SECTION_ID } from "../dimension-section";

export const AvgsPotentialAnalysisNavigation = ({
	learnPotentialAnalysisFragmentRef,
}: AvgsPotentialAnalysisNavigationProps) => {
	const learnPotentialAnalysis =
		useFragment<avgsPotentialAnalysisNavigation_AVGSPotentialAnalysisLearnPotentialAnalysisFragment$key>(
			LEARN_POTENTIAL_ANALYSIS_FRAGMENT,
			learnPotentialAnalysisFragmentRef,
		);

	const { currentModuleIndex, currentDimensionIndex, setCurrentIndex } = useContext(
		AvgsPotentialAnalysisContext,
	);

	const [isFinishedModalVisible, setIsFinishedModalVisible] = useState(false);

	const questions =
		learnPotentialAnalysis?.modules[currentModuleIndex].dimensions[currentDimensionIndex]
			.questions;

	const totalDimensions = useMemo(
		() =>
			learnPotentialAnalysis?.modules.reduce(
				(acc, module) => acc + module.dimensions.length,
				0,
			) ?? 1,
		[learnPotentialAnalysis?.modules],
	);
	const currentTotalDimension = useMemo(
		() =>
			(learnPotentialAnalysis?.modules ?? [])
				.slice(0, currentModuleIndex)
				.reduce((acc, module) => acc + module.dimensions.length, 0) +
			currentDimensionIndex +
			1,
		[learnPotentialAnalysis?.modules, currentModuleIndex, currentDimensionIndex],
	);

	const progressBarValue = (currentTotalDimension / totalDimensions) * 100;
	const trackerText = `${currentTotalDimension} von ${totalDimensions}`;

	const backButtonHidden = currentTotalDimension === 1;
	const allQuestionsAnswered = questions?.every(isQuestionAnswered);

	const actionButtonIcon: TkaIconName = allQuestionsAnswered ? "arrowRight" : "arrowUp";
	const actionButtonVersion: ColorVersion = allQuestionsAnswered ? "default" : "outline";

	const isLastDimension = currentTotalDimension === totalDimensions;
	const actionButtonLabel = match({ allQuestionsAnswered, isLastDimension })
		.with(
			{
				isLastDimension: true,
				allQuestionsAnswered: true,
			},
			() => "Abschließen",
		)
		.with({ allQuestionsAnswered: true }, () => "Weiter")
		.otherwise(() => "Zur offenen Frage");

	const handleBackOnClick = () => {
		if (currentDimensionIndex === 0) {
			setCurrentIndex({
				moduleIndex: currentModuleIndex - 1,
				dimensionIndex:
					(learnPotentialAnalysis?.modules[currentModuleIndex - 1].dimensions.length ??
						0) - 1,
			});
		} else {
			setCurrentIndex({
				moduleIndex: currentModuleIndex,
				dimensionIndex: currentDimensionIndex - 1,
			});
		}
	};

	const focusUnansweredQuestion = () => {
		const firstUnansweredQuestion = questions?.find(
			(question) => !isQuestionAnswered(question),
		);
		if (firstUnansweredQuestion) {
			const element = document.getElementById(firstUnansweredQuestion.id);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	};

	const handleNextOrUpOnClick = () => {
		const module = learnPotentialAnalysis?.modules[currentModuleIndex];
		if (allQuestionsAnswered) {
			if (isLastDimension) {
				setIsFinishedModalVisible(true);
			} else if (currentDimensionIndex === (module?.dimensions.length ?? 0) - 1) {
				setCurrentIndex({
					moduleIndex: currentModuleIndex + 1,
					dimensionIndex: 0,
				});
			} else {
				setCurrentIndex({
					moduleIndex: currentModuleIndex,
					dimensionIndex: currentDimensionIndex + 1,
				});
				const element = document.getElementById(DIMENSION_SECTION_ID);
				if (element) {
					element.scrollIntoView();
				}
			}
		} else {
			focusUnansweredQuestion();
		}
	};

	return (
		<>
			<div className={spacerClass}>
				<div className={wrapperClass}>
					<div className={progressBarWrapperClass}>
						<ProgressBar
							colorVersion={"course"}
							value={progressBarValue}
							heightInRem={1.25}
							borderRadiusInRem={0.35}
						/>
					</div>
					<div className={trackerWrapperClass}>
						<H3Span className={colorPrimary100Class}>{trackerText}</H3Span>
					</div>
					<BottomNavigationTemplate
						canGoBack={!backButtonHidden}
						onBackClick={handleBackOnClick}
						actionButtonLabel={actionButtonLabel}
						actionButtonIconName={actionButtonIcon}
						actionButtonColorVersion={actionButtonVersion}
						onActionButtonClick={handleNextOrUpOnClick}
					/>
				</div>
			</div>
			<AvgsPotentialAnalysisFinishedModal isVisible={isFinishedModalVisible} />
		</>
	);
};
