import { createContext } from "react";

export type IProfileContext = {
	saveOnClick: () => void;
	setSaveOnClick: (fn: () => void) => void;
};
export const ProfileContext = createContext<IProfileContext>({
	saveOnClick: () => {},
	setSaveOnClick: () => {},
});
