import graphql from "babel-plugin-relay/macro";

export const REMOVE_USERS_FROM_ACCOUNT_MUTATION = graphql`
	mutation removeMemberModal_RemoveUsersFromAccountInputMutation(
		$input: RemoveUsersFromAccountInput!
		$connections: [ID!]!
	) {
		AccountMemberManagement {
			removeUsersFromAccount(input: $input) {
				removedIds @deleteEdge(connections: $connections)
			}
		}
	}
`;
