import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const ListWrapper = tw.div`
	grid
	grid-cols-1
	lg:grid-cols-2
	gap-16
`;
