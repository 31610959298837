/**
 * @generated SignedSource<<4068eb3926d1f3cd43a7406597294813>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Gender = "Diverse" | "Female" | "Male" | "NotDefined";
import { FragmentRefs } from "relay-runtime";
export type personnelSheetTab_CoachProfileFragment$data = {
  readonly baseData: {
    readonly address: {
      readonly city: string;
      readonly lineOne: string;
      readonly lineTwo: string;
      readonly postalCode: string;
    };
    readonly birthDate: string;
    readonly birthPlace: string;
    readonly gender: Gender;
    readonly nationality: string;
  };
  readonly businessData: {
    readonly bic: string | null;
    readonly employerNumber: string | null;
    readonly iban: string | null;
    readonly insuranceNumber: string | null;
    readonly taxNumber: string | null;
  };
  readonly profileData: {
    readonly avatar: {
      readonly id: string;
      readonly thumbnail: string | null;
      readonly url: string | null;
    } | null;
    readonly basicPersonal: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly phoneNumber: string | null;
      readonly salutation: string | null;
      readonly title: string | null;
    };
    readonly certificateAnswer: number | null;
    readonly certificateFileIds: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly coachingStrongPointsTopics: ReadonlyArray<string>;
    readonly description: string | null;
    readonly digitalAffinity1: boolean | null;
    readonly digitalAffinity2: boolean | null;
    readonly educationFreeText: string | null;
    readonly educationMoreThan150h: boolean | null;
    readonly experience: {
      readonly branches: ReadonlyArray<string>;
      readonly branchesSteps: ReadonlyArray<string>;
      readonly coachingFileIds: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly coachingHours: number | null;
    };
    readonly jobTitles: ReadonlyArray<string> | null;
    readonly languages: ReadonlyArray<string>;
    readonly methods: ReadonlyArray<string>;
    readonly personalPhilosophyCoachingStyle: string | null;
    readonly personalPhilosophyVisionMission: string | null;
    readonly quote: string | null;
    readonly referencesCoachingExperienceIds: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly referencesPublications: string | null;
    readonly socials: {
      readonly facebook: string | null;
      readonly linkedIn: string | null;
      readonly others: ReadonlyArray<string> | null;
      readonly twitter: string | null;
      readonly website: string | null;
    } | null;
    readonly studies: string | null;
  };
  readonly " $fragmentType": "personnelSheetTab_CoachProfileFragment";
};
export type personnelSheetTab_CoachProfileFragment$key = {
  readonly " $data"?: personnelSheetTab_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personnelSheetTab_CoachProfileFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personnelSheetTab_CoachProfileFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileData",
      "kind": "LinkedField",
      "name": "profileData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDataSocials",
          "kind": "LinkedField",
          "name": "socials",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkedIn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "facebook",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "website",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "others",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quote",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDataBasicPersonal",
          "kind": "LinkedField",
          "name": "basicPersonal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salutation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "certificateAnswer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "certificateFileIds",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coachingStrongPointsTopics",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "digitalAffinity1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "digitalAffinity2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "educationFreeText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "educationMoreThan150h",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "languages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "methods",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personalPhilosophyCoachingStyle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personalPhilosophyVisionMission",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referencesPublications",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "referencesCoachingExperienceIds",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDataExperience",
          "kind": "LinkedField",
          "name": "experience",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branches",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branchesSteps",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "coachingFileIds",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coachingHours",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessData",
      "kind": "LinkedField",
      "name": "businessData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "insuranceNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iban",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employerNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bic",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseData",
      "kind": "LinkedField",
      "name": "baseData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nationality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthPlace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lineOne",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lineTwo",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};
})();

(node as any).hash = "741d3f7157bbea3ae8663b2b18ca2706";

export default node;
