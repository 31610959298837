import {
	SalutationTypeOptionsRecord,
	SalutationType,
	TitleTypeOptionsRecord,
	TitleType,
} from "./order-ihk-modal.types";

//TODO: add-translations
export const getSalutationOptions = () => {
	const translations: SalutationTypeOptionsRecord = {
		[SalutationType.Mr]: "Herr",
		[SalutationType.Mrs]: "Frau",
		[SalutationType.Divers]: "Divers",
	};
	return Object.keys(translations)
		.sort()
		.map((key) => ({
			value: key,
			label: translations[key as unknown as SalutationType],
		}));
};

export const getTitleOptions = () => {
	const translations: TitleTypeOptionsRecord = {
		[TitleType.Prof]: "Prof.",
		[TitleType.Dr]: "Dr.",
	};
	return Object.keys(translations)
		.sort()
		.map((key) => ({
			value: translations[key as unknown as TitleType],
			label: translations[key as unknown as TitleType],
		}));
};

export const getAvailableSalutationType = (salutationType?: string): SalutationType | undefined => {
	if (salutationType && Object.values(SalutationType).includes(salutationType as SalutationType))
		return salutationType as SalutationType;
	return undefined;
};
