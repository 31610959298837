import * as React from "react";
import { ProgressBarProps } from "./progress-bar.interface";
import { ProgressBarValue, ProgressBarWrapper, StyledProgressBar } from "./progress-bar.styles";
import { getColors, getHasBorderRadius } from "./progress-bar.util";

export const ProgressBar = ({
	labelRight,
	labelLeft,
	colorVersion,
	value,
	heightInRem,
	borderRadiusInRem,
	labelColor,
	...props
}: ProgressBarProps) => {
	const { labelColor: themeLabelColor, inactiveColor, activeColor } = getColors(colorVersion);
	const showBorderRadius = getHasBorderRadius(colorVersion);
	return (
		<ProgressBarWrapper>
			{labelLeft && (
				<ProgressBarValue tkaColor={labelColor ?? themeLabelColor}>
					{labelLeft}
				</ProgressBarValue>
			)}
			<StyledProgressBar
				labelColor={labelColor}
				activeColor={activeColor}
				inactiveColor={inactiveColor}
				value={value}
				hasBorderRadius={!!borderRadiusInRem || showBorderRadius}
				borderRadius={borderRadiusInRem}
				heightInRem={heightInRem}
				{...props}
			/>
			{labelRight && (
				<ProgressBarValue tkaColor={labelColor ?? themeLabelColor}>
					{labelRight}
				</ProgressBarValue>
			)}
		</ProgressBarWrapper>
	);
};
