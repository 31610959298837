import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query accountSelection_Query {
		Viewer {
			Auth {
				currentUser {
					accounts {
						id
					}
					user {
						...userAvatar_UserFragment
						extension(userExtensionKind: Academies) {
							... on AcademiesUserExtension {
								sumGamificationPoints
								firstName
								lastName
							}
						}
					}
				}
				...accountSelectionPanel_AuthViewerSchemaFragment
			}
		}
	}
`;
