/**
 * @generated SignedSource<<427b7323e06b689c07bdc16a7768985a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type trendsDetail_LearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly " $fragmentSpreads": FragmentRefs<"coachingLearnableCard_PublishedLearnableFragment" | "educationalOfferCard_PublishedCourseLearnableFragment">;
  readonly " $fragmentType": "trendsDetail_LearnableFragment";
};
export type trendsDetail_LearnableFragment$key = {
  readonly " $data"?: trendsDetail_LearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendsDetail_LearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "trendsDetail_LearnableFragment"
};

(node as any).hash = "fe1e0f50d3bd9b2b46364cc0882f6656";

export default node;
