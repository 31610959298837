import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";

export const HeaderWrapper = tw.div`grid grid-cols-3 gap-24`;
export const VerticalWrapper = tw.div`col-span-3 lg:col-span-2 flex flex-col gap-20 lg:pr-32`;
//@ts-ignore
VerticalWrapper.displayName = "VerticalWrapper";
export const AboutMeWrapper = tw.div`overflow-hidden overflow-ellipsis`;
export const Spacing8 = tw.div`h-8`;

export const Gap24 = tw.div`h-24`;
export const Hr = styled.hr<{ tkaColor: TkaColor }>`
	${(p) => `color:${p.tkaColor};`}
`;
export const QuoteCardWrapper = tw.div`hidden lg:block grid col-span-1 overflow-hidden lg:basis-1/4
	lg:flex
	lg:flex-col`;
