/**
 * @generated SignedSource<<49cc35ba1266d9ec29af90c51d98bea1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type participantPreview_UserFragment$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
  readonly " $fragmentType": "participantPreview_UserFragment";
};
export type participantPreview_UserFragment$key = {
  readonly " $data"?: participantPreview_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"participantPreview_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "participantPreview_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userAvatar_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5143867bb157f0eda60c36175da6b611";

export default node;
