import { GroupCardsWrapper } from "./groups-list.styles";
import { GroupCardSkeleton } from "../group-card/group-card.skeleton";

export const GroupsListSkeleton = () => {
	return (
		<GroupCardsWrapper>
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
		</GroupCardsWrapper>
	);
};
