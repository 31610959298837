import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment privateAccountForm_BaseDataFragment on baseData {
		street
		houseNumber
		postalCode
		city
		countryCode
		__typename
		... on PrivateBaseData {
			phoneNumber
			firstName
			lastName
			salutation
			title
		}
	}
`;
