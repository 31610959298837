import { createContext, PropsWithChildren, useState } from "react";

type AvgsPotentialAnalysisContext = {
	currentModuleIndex: number;
	currentDimensionIndex: number;
	setCurrentIndex: (index: DimensionIndex) => void;
};
export const AvgsPotentialAnalysisContext = createContext<AvgsPotentialAnalysisContext>({
	currentModuleIndex: 0,
	currentDimensionIndex: 0,
	setCurrentIndex: () => {},
});

type DimensionIndex = {
	moduleIndex: number;
	dimensionIndex: number;
};

export const AvgsPotentialAnalysisContextProvider = ({ children }: PropsWithChildren) => {
	const [currentIndex, setCurrentIndex] = useState<DimensionIndex>({
		moduleIndex: 0,
		dimensionIndex: 0,
	});

	const value = {
		currentModuleIndex: currentIndex.moduleIndex,
		currentDimensionIndex: currentIndex.dimensionIndex,
		setCurrentIndex,
	};

	return (
		<AvgsPotentialAnalysisContext.Provider value={value}>
			{children}
		</AvgsPotentialAnalysisContext.Provider>
	);
};
