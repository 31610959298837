/**
 * @generated SignedSource<<8695209c4b643830dd84ffa331cc7a73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type moduleSection_AVGSPotentialAnalysisModuleFragment$data = {
  readonly dimensions: ReadonlyArray<{
    readonly dimensionId: number;
    readonly " $fragmentSpreads": FragmentRefs<"dimensionSection_AVGSPotentialAnalysisDimensionFragment">;
  }>;
  readonly moduleId: number;
  readonly moduleText: string;
  readonly " $fragmentType": "moduleSection_AVGSPotentialAnalysisModuleFragment";
};
export type moduleSection_AVGSPotentialAnalysisModuleFragment$key = {
  readonly " $data"?: moduleSection_AVGSPotentialAnalysisModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"moduleSection_AVGSPotentialAnalysisModuleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "moduleSection_AVGSPotentialAnalysisModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AVGSPotentialAnalysis_Dimension",
      "kind": "LinkedField",
      "name": "dimensions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dimensionId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "dimensionSection_AVGSPotentialAnalysisDimensionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "moduleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "moduleText",
      "storageKey": null
    }
  ],
  "type": "AVGSPotentialAnalysis_Module",
  "abstractKey": null
};

(node as any).hash = "a584cfa13794703d9f8bdea4fcea94eb";

export default node;
