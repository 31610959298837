import styled from "styled-components";
import tw from "twin.macro";

export const MainWrapper = styled.div<{ rows: number }>`
	grid-template-columns: repeat(${({ rows }) => rows}, 1fr);
	${tw`
		grid
		flex-col
		gap-x-16
		gap-y-12
	`}
`;
