import styled, { css } from "styled-components";
import { BreakpointInPx } from "@themes/breakpoint";
import { spacing24, spacing40 } from "@themes/spacing";

const SideMenuCss = css`
	padding: 0 ${spacing24.rem()} 0 ${spacing40.rem()};
`;

const ContentCss = css`
	padding: ${spacing40.rem()} ${spacing40.rem()} 0;
	grid-row: 1 / -1;
`;

const SideMenuOnlyCss = css`
	display: none;
	@media screen and (min-width: ${BreakpointInPx.xlarge}px) {
		display: block;
	}
`;

export const Wrapper = styled.div<{
	showInSideMenu?: boolean;
	sideMenuOnly?: boolean;
}>`
	z-index: 1;
	grid-column: 1 / -1;
	width: 100%;
	max-width: 57rem;
	margin: 0 auto 0;
	@media screen and (min-width: ${BreakpointInPx.xlarge}px) {
		${({ showInSideMenu, sideMenuOnly }) =>
			showInSideMenu || sideMenuOnly ? SideMenuCss : ContentCss};
		grid-column: ${({ showInSideMenu }) => (showInSideMenu ? "1 / 2" : "2 / -1")};
		max-width: 75rem;
	}
	${({ sideMenuOnly }) => sideMenuOnly && SideMenuOnlyCss}
`;
