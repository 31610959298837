import React, { useContext } from "react";
import { CertificateCard } from "@components/certificate-card";
import { FocusContentMenu } from "@components/focus-content-menu";
import { FreeDemoCard } from "@components/free-demo-card";
import { ReceivablePointsCard } from "@components/receivable-points-card";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { RootOverviewTabProps } from "./root-overview-tab.interface";
import { CertificateWrapper, TabWrapper } from "./root-overview-tab.styles";
import {
	ContentSectionId,
	GoalsSectionId,
	RootContainerId,
	TrailerSectionId,
	TrainerSectionId,
} from "../root-overview/root-overview.const";
import { RootScreenContext } from "../root.context";

export const RootOverviewTab = ({
	rootId,
	rootName,
	hasIHKCertificate,
	hasGoals,
	hasInstructors,
	hasTrailer,
	isDemo,
	canOrderIHK,
	receivablePoints,
}: RootOverviewTabProps) => {
	const { t } = useTkaTranslation("courseScreen");
	const { spyEnabled } = useContext(RootScreenContext);

	const contentTitle = t("course_screen.courseDescriptionTitle");
	const videoTitle = t("course_screen.trailerTitle");
	const goalsTitle = t("course_screen.goalsTitle");
	const trainerTitle = t("course_screen.trainers");

	const focusContentItems = [{ id: ContentSectionId, label: contentTitle }];

	if (hasTrailer) {
		focusContentItems.push({ id: TrailerSectionId, label: videoTitle });
	}

	if (hasGoals) {
		focusContentItems.push({ id: GoalsSectionId, label: goalsTitle });
	}
	if (hasInstructors) {
		focusContentItems.push({ id: TrainerSectionId, label: trainerTitle });
	}

	return (
		<TabWrapper>
			<FocusContentMenu
				items={focusContentItems}
				containerId={RootContainerId}
				enableSpy={spyEnabled}
			/>
			{hasIHKCertificate && rootName && (
				<CertificateWrapper>
					<CertificateCard rootName={rootName} canOrder={canOrderIHK} rootId={rootId} />
				</CertificateWrapper>
			)}
			{isDemo && (
				<CertificateWrapper>
					<FreeDemoCard />
				</CertificateWrapper>
			)}
			{receivablePoints && receivablePoints > 0 && (
				<CertificateWrapper>
					<ReceivablePointsCard receivablePoints={receivablePoints} />
				</CertificateWrapper>
			)}
		</TabWrapper>
	);
};
