import tw from "twin.macro";

export const RowWrapper = tw.div`
    flex
    flex-row
    gap-12
    items-center
    cursor-pointer
    hover:opacity-70
`;
export const DualRowWrapper = tw(RowWrapper)`justify-between cursor-default hover:opacity-100`;
export const ShortRowWrapper = tw(RowWrapper)`
lg:max-w-[8.5rem]`;
