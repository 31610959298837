import { TkaB1Span } from "@themes/font-tags";
import {
	ContentWrapper,
	LoadingWrapper,
	StyledButton,
	TextWrapper,
	Wrapper,
} from "./button.styles";
import { ButtonProps } from "./button.types";
import { calcDefaultColor, calcDisabledColor } from "./button.util";
import { Icon } from "../icon";
import { LottieControl } from "../lottie-control";

export const Button = ({
	label,
	iconName,
	icon,
	colorVersion = "default",
	disabled,
	type = "submit",
	textLinkColor,
	loading,
	...buttonProps
}: ButtonProps) => {
	const isDisabled = disabled || loading;
	const contentColor = isDisabled
		? calcDisabledColor(colorVersion, textLinkColor)
		: calcDefaultColor(colorVersion, textLinkColor);

	const extraBold = colorVersion !== "textlink";
	const underline = colorVersion === "textlink";

	const renderRightIcon = () => {
		if (loading)
			return (
				<LoadingWrapper tktColor={contentColor}>
					<LottieControl />
				</LoadingWrapper>
			);
		if (icon) return icon;
		return iconName ? <Icon tkaColor={contentColor} icon={iconName} sizeInRem={1} /> : null;
	};

	return (
		<Wrapper disabled={isDisabled} fillParent={buttonProps.fillParent}>
			<StyledButton
				colorVersion={colorVersion}
				type={type}
				disabled={isDisabled}
				{...buttonProps}
			>
				<ContentWrapper disabled={isDisabled} colorVersion={colorVersion}>
					{label && (
						<TextWrapper>
							<TkaB1Span
								extraBold={extraBold}
								underline={underline}
								tkaColor={contentColor}
							>
								{label}
							</TkaB1Span>
						</TextWrapper>
					)}
					{renderRightIcon()}
				</ContentWrapper>
			</StyledButton>
		</Wrapper>
	);
};
