export const VideoMenuRoute = {
	settings: {
		path: "settings",
		resolution: {
			path: "resolution",
		},
		speed: {
			path: "speed",
		},
	},
	transcripts: {
		path: "transcripts",
	},
};

export const HLSLevelAuto = -1;

export enum VideoSettingsSpeed {
	S0_5 = "0.5",
	S0_75 = "0.75",
	S1_0 = "1.0",
	S1_25 = "1.25",
	S1_5 = "1.5",
}

export enum VideoTranscriptLanguage {
	Disabled = "disabled",
	DE = "de",
	EN = "en",
}
