/**
 * @generated SignedSource<<8837805f583fdbee78d8c10dd5a9a124>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemType = "CartBulkDiscount" | "CartDiscount" | "CartGlobalDiscount" | "CartLimitedDiscount" | "CartProduct";
import { FragmentRefs } from "relay-runtime";
export type shoppingCartSuccess_OrderFragment$data = {
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly amount?: number;
      readonly itemType: ItemType;
      readonly product?: {
        readonly id: string;
        readonly title: string;
      } | null;
    }>;
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly netPrice: number;
      };
    };
  } | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartLegalForm_OrderFragment" | "shoppingCartTemplate_OrderFragment">;
  readonly " $fragmentType": "shoppingCartSuccess_OrderFragment";
};
export type shoppingCartSuccess_OrderFragment$key = {
  readonly " $data"?: shoppingCartSuccess_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shoppingCartSuccess_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shoppingCartSuccess_OrderFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemType",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "CartProduct",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartLegalForm_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shoppingCartTemplate_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "02af8ba734e88533716030ab4b9d4488";

export default node;
