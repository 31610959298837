import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query myAvailabilities_Query {
		Viewer {
			Coaching {
				MyAvailabilitySchedule {
					...myAvailabilities_AvailabilityScheduleFragment
				}
				FreeCalendarWeeks {
					...exceptionModal_FreeCalenderWeeksFragment
				}
			}
		}
	}
`;

export const EDIT_MY_AVAILABILITY_SCHEDULE_MUTATION = graphql`
	mutation myAvailabilities_EditMyAvailabilityScheduleMutation(
		$input: EditMyAvailabilityScheduleInput!
	) {
		Coaching {
			editMyAvailabilitySchedule(input: $input) {
				schedule {
					id
					data {
						overrideDaysAndTimesForCalendarWeek {
							calendarWeek
							calendarYear
							dayOfWeek
							timeSlots
						}
					}
					...myAvailabilities_AvailabilityScheduleFragment
				}
			}
		}
	}
`;

export const AVAILABILITY_SCHEDULE_FRAGMENT = graphql`
	fragment myAvailabilities_AvailabilityScheduleFragment on AvailabilitySchedule {
		__typename
		id
		data {
			__typename
			daysAndTimes {
				dayOfWeek
				timeSlots
			}
			blockoutDates {
				from
				to
				...blockoutDateCard_BlockoutDateFragment
			}
			overrideDaysAndTimesForCalendarWeek {
				...myAvailabilities_OverrideDaysAndTimesFragment
			}
		}
	}
`;
export const OVERRIDE_DAYS_AND_TIMES_FRAGMENT = graphql`
	fragment myAvailabilities_OverrideDaysAndTimesFragment on DayAndTimesForCalendarWeek
	@relay(plural: true) {
		calendarWeek
		calendarYear
		dayOfWeek
		timeSlots
	}
`;
