import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BadgeV2 } from "@components/badge-v2";
import { Dropdown } from "@components/dropdown";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { HTMLMouseEvent } from "@customTypes/index";
import {
	selectFilterByProficiencyLevel,
	setFilterByProficiencyLevel,
} from "@slices/vocabulary.slice";
import { TkaP3Span } from "@themes/font-tags";
import { BadgeWrapper, OverlayWrapper, Wrapper } from "./vocab-filter-button.styles";

// TODO: add-translations
export const VocabFilterButton = () => {
	const overlayPanelRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);
	const proficiencyLevel = useSelector(selectFilterByProficiencyLevel);
	const dispatch = useDispatch();

	const handleOnChange = (selected: string) => {
		if (selected === "-1") {
			dispatch(
				setFilterByProficiencyLevel({
					filterByProficiencyLevel: undefined,
				}),
			);
		} else {
			dispatch(
				setFilterByProficiencyLevel({
					filterByProficiencyLevel: parseInt(selected),
				}),
			);
		}
	};

	const handleFilterOnClick = (event: HTMLMouseEvent) => {
		overlayPanelRef.current!.toggle(event);
	};

	return (
		<>
			<Wrapper>
				<Icon icon={"filter"} sizeInRem={1} onClick={handleFilterOnClick} />
				{proficiencyLevel !== undefined && proficiencyLevel >= 0 && (
					<BadgeWrapper>
						<BadgeV2 value={1} severity={"brandStrong"} />
					</BadgeWrapper>
				)}
			</Wrapper>
			<ResponsiveBottomSheetOverlayPanel ref={overlayPanelRef}>
				<OverlayWrapper>
					<label>
						<TkaP3Span>Vokabelsicherheit</TkaP3Span>
						<Dropdown
							placeholder="Nach Vokabelsicherheit filtern"
							value={proficiencyLevel?.toFixed()}
							options={[
								{ value: "-1", label: "Alle" },
								{ value: "0", label: "0%" },
								{ value: "1", label: "25%" },
								{ value: "2", label: "50%" },
								{ value: "3", label: "75%" },
								{ value: "4", label: "100%" },
							]}
							onChange={handleOnChange}
						/>
					</label>
				</OverlayWrapper>
			</ResponsiveBottomSheetOverlayPanel>
		</>
	);
};
