import { FormComponentStatus, FormComponentStatusWithoutError } from "@customTypes/index";

/**
 * Helper function to facilitate single state of truth.
 * Any unwanted scenarios where "hasError" and "disabled" occur at the same time, will be prevented.
 * @param hasError {boolean}
 * @param status {FormComponentStatusWithoutError}
 * @returns {FormComponentStatus}
 */
export function calcFormComponentStatus(
	hasError: boolean,
	status: FormComponentStatusWithoutError,
): FormComponentStatus {
	if (hasError) return "error";
	if (status === "disabled") return "disabled";

	return status as FormComponentStatus;
}
