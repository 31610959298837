import { MouseEvent } from "react";
import { TkaL1Span } from "@themes/font-tags";
import { ClosableWrapper, IconLabelWrapper, StyledTag, Wrapper } from "./label.styles";
import { LabelProps } from "./label.types";
import { getCloseIconColor, getTextColor } from "./label.utils";
import { Icon } from "../icon";

export const Label = ({
	label,
	closable,
	iconName,
	severity = "default",
	size = "smal",
	minWidthRem,
	backgroundColor,
	closeIconName,
	onClick,
	onEventClick,
	onCloseClick,
	lineThroughColor,
}: LabelProps) => {
	const color = getTextColor(severity);
	const closeIconColor = getCloseIconColor(severity);
	const closeIconSize = size === "smal" ? 0.375 : 0.5;
	const handleCloseOnClick = (event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		onCloseClick?.();
	};

	const handleOnClick = (event: MouseEvent<HTMLSpanElement>) => {
		event.stopPropagation();
		onClick?.();
		onEventClick?.(event);
	};

	const onClickHandler = onClick || onEventClick ? handleOnClick : undefined;

	return (
		<StyledTag
			labelSeverity={severity}
			onClick={onClickHandler}
			backgroundColor={backgroundColor}
		>
			<Wrapper minWidthRem={minWidthRem}>
				<IconLabelWrapper size={size} lineThroughColor={lineThroughColor}>
					{iconName && <Icon icon={iconName} tkaColor={color} sizeInRem={0.5} />}
					{label && <TkaL1Span tkaColor={color}>{label}</TkaL1Span>}
				</IconLabelWrapper>
				{closable && (
					<ClosableWrapper
						size={size}
						labelSeverity={severity}
						onClick={handleCloseOnClick}
					>
						<Icon
							icon={closeIconName ?? "close"}
							sizeInRem={closeIconSize}
							tkaColor={closeIconColor}
						/>
					</ClosableWrapper>
				)}
			</Wrapper>
		</StyledTag>
	);
};
