import { FC } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { trendOfferSection_TrendOfferFragment$key } from "@relay/trendOfferSection_TrendOfferFragment.graphql";
import { Path } from "@router/paths";
import { QUERY_FRAGMENT } from "@screens/trends/parts/trend-offer-section/trend-offer-section.graphql";
import { TrendOfferSectionTypes } from "@screens/trends/parts/trend-offer-section/trend-offer-section.types";
import { trendOfferSectionData } from "@screens/trends/trend-overview-data";
import { H1Span, TkaP1Span, TkaP2Span } from "@themes/font-tags";
import {
	StyledTkaH1Span,
	Card,
	CardsWrapper,
	LeftWrapper,
	Wrapper,
} from "./trend-offer-section.styles";

export const TrendOfferSection: FC<TrendOfferSectionTypes> = ({ queryFragmentRef }) => {
	const navigate = useNavigate();

	const trendOfferFragment = useFragment<trendOfferSection_TrendOfferFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef ?? null,
	);
	const handleButtonOnClick = () => {
		navigate(Path.progression.path);
	};
	const handleCardOnClick = (index: number) => {
		navigate(Path.progression.path, {
			state: { filters: { kinds: trendOfferSectionData.cardInfo[index].kind } },
		});
	};
	return (
		<Wrapper>
			<LeftWrapper>
				<StyledTkaH1Span>
					{trendOfferFragment
						? trendOfferFragment.aboutOffersHeadingAndDescription.heading
						: trendOfferSectionData.heading}
				</StyledTkaH1Span>
				<TkaP1Span>
					{trendOfferFragment
						? trendOfferFragment.aboutOffersHeadingAndDescription.description
						: trendOfferSectionData.text}
				</TkaP1Span>
				<Button
					label="Alle Weiterbildungen"
					iconName="arrowRight"
					colorVersion="outline"
					onClick={handleButtonOnClick}
				/>
			</LeftWrapper>
			<CardsWrapper>
				{trendOfferSectionData.cardInfo.map((cardInfo, index) => {
					return (
						<Card
							key={`${index}_${cardInfo.heading}`}
							onClick={() => handleCardOnClick(index)}
						>
							<H1Span>{cardInfo.heading}</H1Span>
							<TkaP2Span>{cardInfo.description}</TkaP2Span>
						</Card>
					);
				})}
			</CardsWrapper>
		</Wrapper>
	);
};
