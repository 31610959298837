import React, { SVGAttributes } from "react";
import { ReactComponent as Add } from "@assets/icons/add.svg";
import { ReactComponent as ArchiveDrawer } from "@assets/icons/archive-drawer.svg";
import { ReactComponent as ArrowDown } from "@assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeft2 } from "@assets/icons/arrow-left-2.svg";
import { ReactComponent as ArrowLeft } from "@assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight2 } from "@assets/icons/arrow-right-2.svg";
import { ReactComponent as ArrowRight } from "@assets/icons/arrow-right.svg";
import { ReactComponent as ArrowTop } from "@assets/icons/arrow-top.svg";
import { ReactComponent as ArrowUp } from "@assets/icons/arrow-up.svg";
import { ReactComponent as ArrowLoop } from "@assets/icons/arrowLoop.svg";
import { ReactComponent as BlackCheckRibbon } from "@assets/icons/black-check-ribbon.svg";
import { ReactComponent as Book } from "@assets/icons/book.svg";
import { ReactComponent as BookmarkMarked } from "@assets/icons/bookmark-marked.svg";
import { ReactComponent as Bookmark } from "@assets/icons/bookmark.svg";
import { ReactComponent as BridgeOverRiver } from "@assets/icons/bridgeOverRiver.svg";
import { ReactComponent as BulbRibbon } from "@assets/icons/bulb-ribbon.svg";
import { ReactComponent as BurgerMenu } from "@assets/icons/burger-menu.svg";
import { ReactComponent as CalendarCheck } from "@assets/icons/calendar-check.svg";
import { ReactComponent as CalendarDisable } from "@assets/icons/calendar-disable.svg";
import { ReactComponent as Calendar } from "@assets/icons/calendar.svg";
import { ReactComponent as CertifiedCertificateBold } from "@assets/icons/certified-certificate-bold.svg";
import { ReactComponent as CertifiedCertificate } from "@assets/icons/certified-certificate.svg";
import { ReactComponent as CheckBadge } from "@assets/icons/check-badge.svg";
import { ReactComponent as CheckCircle } from "@assets/icons/check-circle.svg";
import { ReactComponent as Circle } from "@assets/icons/circle.svg";
import { ReactComponent as CloseFullscreen } from "@assets/icons/close-fullscreen.svg";
import { ReactComponent as CloseQuote } from "@assets/icons/close-quote.svg";
import { ReactComponent as Close } from "@assets/icons/close.svg";
import { ReactComponent as CoachingTeam } from "@assets/icons/coaching-team.svg";
import { ReactComponent as Cog } from "@assets/icons/cog.svg";
import { ReactComponent as CoinStar } from "@assets/icons/coin-star.svg";
import { ReactComponent as CommonFileTextDownload } from "@assets/icons/common-file-text-download.svg";
import { ReactComponent as ComputerWithHeart } from "@assets/icons/computerWithHeart.svg";
import { ReactComponent as ContextMenu } from "@assets/icons/context-menu.svg";
import { ReactComponent as ConversationQuestionWarning } from "@assets/icons/conversation-question-warning.svg";
import { ReactComponent as Copy } from "@assets/icons/copy.svg";
import { ReactComponent as Correct } from "@assets/icons/correct.svg";
import { ReactComponent as CreditCardMastercard1 } from "@assets/icons/credit-card-mastercard-1.svg";
import { ReactComponent as CSV } from "@assets/icons/csv.svg";
import { ReactComponent as Dashboard } from "@assets/icons/dashboard.svg";
import { ReactComponent as DataFileBarsDownload } from "@assets/icons/data-file-bars-download.svg";
import { ReactComponent as DataFileDownload } from "@assets/icons/data-file-download.svg";
import { ReactComponent as DiscountBubble1 } from "@assets/icons/discount-bubble-1.svg";
import { ReactComponent as DotsConnected } from "@assets/icons/dotsConnected.svg";
import { ReactComponent as DownloadBottom } from "@assets/icons/download-bottom.svg";
import { ReactComponent as DragDrop } from "@assets/icons/drag-drop.svg";
import { ReactComponent as Email } from "@assets/icons/email.svg";
import { ReactComponent as ExpandFull } from "@assets/icons/expand-full.svg";
import { ReactComponent as EyeCircle } from "@assets/icons/eye-circle.svg";
import { ReactComponent as Feedback2 } from "@assets/icons/feedback-2.svg";
import { ReactComponent as Filter } from "@assets/icons/filter.svg";
import { ReactComponent as Flags } from "@assets/icons/flags.svg";
import { ReactComponent as FlowerInCircle } from "@assets/icons/flowerInCircle.svg";
import { ReactComponent as Flowers } from "@assets/icons/flowers.svg";
import { ReactComponent as Folder } from "@assets/icons/folder.svg";
import { ReactComponent as Forward10 } from "@assets/icons/forward-10.svg";
import { ReactComponent as Fullscreen } from "@assets/icons/fullscreen.svg";
import { ReactComponent as GamingTrophyCheckFailed } from "@assets/icons/gaming-trophy-check-failed.svg";
import { ReactComponent as GamingTrophyCheckSuccess } from "@assets/icons/gaming-trophy-check-success.svg";
import { ReactComponent as GreenCheckRibbon } from "@assets/icons/green-check-ribbon.svg";
import { ReactComponent as Group } from "@assets/icons/Group.svg";
import { ReactComponent as GrowRibbon } from "@assets/icons/grow-ribbon.svg";
import { ReactComponent as HandsWithHeart } from "@assets/icons/handsWithHeart.svg";
import { ReactComponent as HandWithFlower } from "@assets/icons/handWithFlower.svg";
import { ReactComponent as HatRibbon } from "@assets/icons/hat-ribbon.svg";
import { ReactComponent as Home } from "@assets/icons/home.svg";
import { ReactComponent as ImageFileDownload } from "@assets/icons/image-file-download.svg";
import { ReactComponent as InformationCircle } from "@assets/icons/information-circle.svg";
import { ReactComponent as LightBulb } from "@assets/icons/lightBulb.svg";
import { ReactComponent as ListBullets } from "@assets/icons/list-bullets.svg";
import { ReactComponent as LocationPin } from "@assets/icons/location-pin.svg";
import { ReactComponent as LockUnlocked } from "@assets/icons/lock-unlock.svg";
import { ReactComponent as Lock } from "@assets/icons/lock.svg";
import { ReactComponent as LoginKey } from "@assets/icons/login-key.svg";
import { ReactComponent as Logout } from "@assets/icons/logout.svg";
import { ReactComponent as LoopArrow } from "@assets/icons/loop-arrow.svg";
import { ReactComponent as MaterialComplete } from "@assets/icons/material-complete.svg";
import { ReactComponent as MicrophonePodcast2 } from "@assets/icons/microphone-podcast-2.svg";
import { ReactComponent as MicrosoftTeams } from "@assets/icons/microsoft-teams.svg";
import { ReactComponent as Module } from "@assets/icons/module.svg";
import { ReactComponent as MoneyWallet } from "@assets/icons/money-wallet.svg";
import { ReactComponent as ParagraphTwoColumns } from "@assets/icons/paragraph-two-column.svg";
import { ReactComponent as Participants } from "@assets/icons/participants.svg";
import { ReactComponent as PauseArrow } from "@assets/icons/pause-arrow.svg";
import { ReactComponent as Paypal } from "@assets/icons/paypal.svg";
import { ReactComponent as Pencil } from "@assets/icons/pencil.svg";
import { ReactComponent as PeopleHighFive } from "@assets/icons/peopleHighFive.svg";
import { ReactComponent as PersonWithStars } from "@assets/icons/personWithStars.svg";
import { ReactComponent as Picture } from "@assets/icons/picture.svg";
import { ReactComponent as Pin } from "@assets/icons/pin.svg";
import { ReactComponent as PlacementRibbon } from "@assets/icons/placement-ribbon.svg";
import { ReactComponent as PlacementTeaser } from "@assets/icons/placement-teaser.svg";
import { ReactComponent as PlayArrow } from "@assets/icons/play-arrow.svg";
import { ReactComponent as Question } from "@assets/icons/question.svg";
import { ReactComponent as RatingStar } from "@assets/icons/rating-star.svg";
import { ReactComponent as ReadSearch } from "@assets/icons/read-search.svg";
import { ReactComponent as RemoveBadge } from "@assets/icons/remove-badge.svg";
import { ReactComponent as Remove } from "@assets/icons/remove.svg";
import { ReactComponent as RewardStars } from "@assets/icons/reward-stars.svg";
import { ReactComponent as Rewind_10 } from "@assets/icons/rewind-10.svg";
import { ReactComponent as Rocket } from "@assets/icons/rocket.svg";
import { ReactComponent as Search } from "@assets/icons/search.svg";
import { ReactComponent as Settings } from "@assets/icons/settings.svg";
import { ReactComponent as ShareExternal } from "@assets/icons/share-external.svg";
import { ReactComponent as Share } from "@assets/icons/share.svg";
import { ReactComponent as ShieldCheck } from "@assets/icons/shield-check.svg";
import { ReactComponent as ShoppingBasketCheck1 } from "@assets/icons/shopping-basket-check-1.svg";
import { ReactComponent as ShoppingBasketSad1 } from "@assets/icons/shopping-basket-sad-1.svg";
import { ReactComponent as ShoppingBasketThin } from "@assets/icons/shopping-basket-thin.svg";
import { ReactComponent as ShoppingBasket } from "@assets/icons/shopping-basket.svg";
import { ReactComponent as Facebook } from "@assets/icons/socials/facebook.svg";
import { ReactComponent as LinkedIn } from "@assets/icons/socials/linkedin.svg";
import { ReactComponent as Twitter } from "@assets/icons/socials/twitter.svg";
import { ReactComponent as Website } from "@assets/icons/socials/website.svg";
import { ReactComponent as Star } from "@assets/icons/star.svg";
import { ReactComponent as SynchronizeArrowClock } from "@assets/icons/synchronize-arrow-clock.svg";
import { ReactComponent as Tick } from "@assets/icons/tick.svg";
import { ReactComponent as TimeClockCircle } from "@assets/icons/time-clock-circle.svg";
import { ReactComponent as Transcripts } from "@assets/icons/transcripts.svg";
import { ReactComponent as Translate } from "@assets/icons/translate.svg";
import { ReactComponent as Trash } from "@assets/icons/trash.svg";
import { ReactComponent as UserMale } from "@assets/icons/user-male.svg";
import { ReactComponent as User } from "@assets/icons/user.svg";
import { ReactComponent as VideoFileDownload } from "@assets/icons/video-file-download.svg";
import { ReactComponent as VideoPlay } from "@assets/icons/video-play.svg";
import { ReactComponent as Video } from "@assets/icons/video.svg";
import { ReactComponent as Volume } from "@assets/icons/volume.svg";
import { ReactComponent as WarningInverted } from "@assets/icons/warning-inverted.svg";
import { ReactComponent as Warning } from "@assets/icons/warning.svg";
import { ReactComponent as WorkingDesk } from "@assets/icons/workingDesk.svg";
import { ReactComponent as Wrong } from "@assets/icons/wrong.svg";

export type TkaSocialsIconName = "linkedIn" | "facebook" | "twitter" | "website";

export type TkaIconName =
	| "add"
	| "book"
	| "home"
	| "pin"
	| "materialComplete"
	| "microphonePodcast2"
	| "module"
	| "moneyWallet"
	| "user"
	| "video"
	| "question"
	| "email"
	| "conversationQuestionWarning"
	| "cog"
	| "expandFull"
	| "eyeCircle"
	| "paragraphTwoColumn"
	| "paypal"
	| "picture"
	| "readSearch"
	| "remove"
	| "share"
	| "shoppingBasketCheck1"
	| "informationCircle"
	| "videoFileDownload"
	| "commonFileTextDownload"
	| "dataFileDownload"
	| "imageFileDownload"
	| "dataFileBarsDownload"
	| "checkBadge"
	| "checkCircle"
	| "loginKey"
	| "search"
	| "downloadBottom"
	| "filter"
	| "lock"
	| "lockUnlock"
	| "logout"
	| "dashboard"
	| "timeClockCircle"
	| "archiveDrawer"
	| "bookmark"
	| "bookmarkMarked"
	| "arrowLeft"
	| "arrowLeft2"
	| "arrowRight"
	| "arrowRight2"
	| "listBullets"
	| "arrowUp"
	| "arrowDown"
	| "contextMenu"
	| "close"
	| "creditCardMastercard1"
	| "burgerMenu"
	| "certifiedCertificate"
	| "certifiedCertificateBold"
	| "dragDrop"
	| "rewind10"
	| "forward10"
	| "fullscreen"
	| "closeFullscreen"
	| "playArrow"
	| "pauseArrow"
	| "settings"
	| "transcripts"
	| "trash"
	| "volume"
	| "videoPlay"
	| "wrong"
	| "correct"
	| "closeQuote"
	| "calendar"
	| "discountBubble1"
	| "calendarCheck"
	| "coachingTeam"
	| "translate"
	| "calendarDisable"
	| "microsoftTeams"
	| "copy"
	| "participants"
	| "tick"
	| "group"
	| "warning"
	| "warning-inverted"
	| "star"
	| "shoppingBasket"
	| "shoppingBasketThin"
	| "shoppingBasketSad1"
	| TkaSocialsIconName
	| "removeBadge"
	| "synchronizeArrowClock"
	| "coinStar"
	| "flags"
	| "gamingTrophyCheckSuccess"
	| "gamingTrophyCheckFailed"
	| "flowers"
	| "ratingStar"
	| "shareExternal"
	| "userMaler"
	| "placementTeaser"
	| "locationPin"
	| "arrowTop"
	| "folder"
	| "shieldCheck"
	| "pencil"
	| "csv"
	| "bulbRibbon"
	| "growRibbon"
	| "hatRibbon"
	| "placementRibbon"
	| "circle"
	| "rewardStars"
	| "greenCheckRibbon"
	| "blackCheckRibbon"
	| "loopArrow"
	| "feedback2"
	| "arrowLoop"
	| "bridgeOverRiver"
	| "computerWithHeart"
	| "dotsConnected"
	| "flowerInCircle"
	| "handsWithHeart"
	| "handWithFlower"
	| "lightBulb"
	| "peopleHighFive"
	| "personWithStars"
	| "rocket"
	| "workingDesk";

export type AdjustableStyles = "stroke" | "fill" | "fill-also";

export const IconMap: Record<
	TkaIconName,
	{
		icon: React.FunctionComponent<SVGAttributes<SVGElement>>;
		styles: AdjustableStyles[];
	}
> = {
	add: { icon: Add, styles: ["stroke"] },
	archiveDrawer: { icon: ArchiveDrawer, styles: ["stroke"] },
	arrowDown: { icon: ArrowDown, styles: ["stroke"] },
	arrowLeft: { icon: ArrowLeft, styles: ["fill"] },
	arrowLeft2: { icon: ArrowLeft2, styles: ["stroke"] },
	arrowRight: { icon: ArrowRight, styles: ["fill"] },
	arrowRight2: { icon: ArrowRight2, styles: ["stroke"] },
	arrowUp: { icon: ArrowUp, styles: ["stroke"] },
	book: { icon: Book, styles: ["stroke"] },
	bookmark: { icon: Bookmark, styles: ["stroke"] },
	bookmarkMarked: { icon: BookmarkMarked, styles: ["stroke"] },
	burgerMenu: { icon: BurgerMenu, styles: ["fill"] },
	certifiedCertificate: { icon: CertifiedCertificate, styles: ["stroke"] },
	certifiedCertificateBold: { icon: CertifiedCertificateBold, styles: ["stroke"] },
	checkBadge: { icon: CheckBadge, styles: ["stroke"] },
	checkCircle: { icon: CheckCircle, styles: ["stroke"] },
	close: { icon: Close, styles: ["stroke"] },
	cog: { icon: Cog, styles: ["stroke"] },
	commonFileTextDownload: { icon: CommonFileTextDownload, styles: ["stroke"] },
	contextMenu: { icon: ContextMenu, styles: ["fill"] },
	conversationQuestionWarning: {
		icon: ConversationQuestionWarning,
		styles: ["stroke", "fill-also"],
	},
	creditCardMastercard1: { icon: CreditCardMastercard1, styles: ["fill"] },
	dashboard: { icon: Dashboard, styles: ["stroke", "fill-also"] },
	dataFileBarsDownload: { icon: DataFileBarsDownload, styles: ["stroke"] },
	dataFileDownload: { icon: DataFileDownload, styles: ["stroke"] },
	downloadBottom: { icon: DownloadBottom, styles: ["stroke"] },
	discountBubble1: { icon: DiscountBubble1, styles: ["fill"] },
	email: { icon: Email, styles: ["stroke", "fill-also"] },
	expandFull: { icon: ExpandFull, styles: ["stroke"] },
	eyeCircle: { icon: EyeCircle, styles: ["stroke"] },
	filter: { icon: Filter, styles: ["stroke"] },
	home: { icon: Home, styles: ["stroke"] },
	imageFileDownload: { icon: ImageFileDownload, styles: ["stroke"] },
	informationCircle: { icon: InformationCircle, styles: ["stroke", "fill-also"] },
	listBullets: { icon: ListBullets, styles: ["stroke"] },
	lock: { icon: Lock, styles: ["stroke"] },
	lockUnlock: { icon: LockUnlocked, styles: ["stroke"] },
	loginKey: { icon: LoginKey, styles: ["stroke"] },
	logout: { icon: Logout, styles: ["stroke"] },
	materialComplete: { icon: MaterialComplete, styles: ["stroke", "fill-also"] },
	microphonePodcast2: { icon: MicrophonePodcast2, styles: ["stroke", "fill-also"] },
	module: { icon: Module, styles: ["stroke", "fill-also"] },
	moneyWallet: { icon: MoneyWallet, styles: ["stroke"] },
	paragraphTwoColumn: { icon: ParagraphTwoColumns, styles: ["stroke"] },
	paypal: { icon: Paypal, styles: [] },
	picture: { icon: Picture, styles: ["stroke"] },
	pin: { icon: Pin, styles: ["stroke", "fill-also"] },
	question: { icon: Question, styles: ["stroke", "fill-also"] },
	readSearch: { icon: ReadSearch, styles: ["stroke"] },
	remove: { icon: Remove, styles: ["stroke"] },
	search: { icon: Search, styles: ["stroke"] },
	share: { icon: Share, styles: ["stroke"] },
	shoppingBasketCheck1: { icon: ShoppingBasketCheck1, styles: ["stroke"] },
	timeClockCircle: { icon: TimeClockCircle, styles: ["stroke"] },
	video: { icon: Video, styles: ["stroke", "fill-also"] },
	user: { icon: User, styles: ["stroke", "fill-also"] },
	videoFileDownload: { icon: VideoFileDownload, styles: ["stroke"] },
	dragDrop: { icon: DragDrop, styles: ["fill"] },
	rewind10: { icon: Rewind_10, styles: ["fill"] },
	forward10: { icon: Forward10, styles: ["fill"] },
	fullscreen: { icon: Fullscreen, styles: ["stroke"] },
	closeFullscreen: { icon: CloseFullscreen, styles: ["stroke"] },
	playArrow: { icon: PlayArrow, styles: ["fill"] },
	pauseArrow: { icon: PauseArrow, styles: ["fill"] },
	settings: { icon: Settings, styles: ["fill"] },
	transcripts: { icon: Transcripts, styles: ["fill"] },
	trash: { icon: Trash, styles: ["stroke"] },
	volume: { icon: Volume, styles: ["fill"] },
	videoPlay: { icon: VideoPlay, styles: ["stroke", "fill-also"] },
	wrong: { icon: Wrong, styles: ["fill"] },
	correct: { icon: Correct, styles: ["fill"] },
	closeQuote: { icon: CloseQuote, styles: ["fill"] },
	calendar: { icon: Calendar, styles: ["stroke"] },
	calendarCheck: { icon: CalendarCheck, styles: ["stroke"] },
	coachingTeam: { icon: CoachingTeam, styles: ["stroke"] },
	translate: { icon: Translate, styles: ["stroke"] },
	group: { icon: Group, styles: ["stroke"] },
	calendarDisable: { icon: CalendarDisable, styles: ["stroke"] },
	microsoftTeams: { icon: MicrosoftTeams, styles: ["fill"] },
	copy: { icon: Copy, styles: ["stroke"] },
	participants: { icon: Participants, styles: ["stroke"] },
	tick: { icon: Tick, styles: ["stroke"] },
	warning: { icon: Warning, styles: ["fill"] },
	"warning-inverted": { icon: WarningInverted, styles: ["stroke", "fill-also"] },
	star: { icon: Star, styles: ["stroke"] },
	shoppingBasket: { icon: ShoppingBasket, styles: ["stroke"] },
	shoppingBasketThin: { icon: ShoppingBasketThin, styles: ["stroke"] },
	removeBadge: { icon: RemoveBadge, styles: ["stroke"] },
	synchronizeArrowClock: { icon: SynchronizeArrowClock, styles: ["stroke"] },
	coinStar: { icon: CoinStar, styles: ["stroke"] },
	shoppingBasketSad1: { icon: ShoppingBasketSad1, styles: ["stroke"] },
	facebook: { icon: Facebook, styles: ["stroke", "fill-also"] },
	twitter: { icon: Twitter, styles: ["stroke", "fill-also"] },
	linkedIn: { icon: LinkedIn, styles: ["stroke", "fill-also"] },
	flags: { icon: Flags, styles: ["stroke"] },
	gamingTrophyCheckSuccess: { icon: GamingTrophyCheckSuccess, styles: ["stroke"] },
	gamingTrophyCheckFailed: { icon: GamingTrophyCheckFailed, styles: ["stroke"] },
	website: { icon: Website, styles: ["fill"] },
	flowers: { icon: Flowers, styles: ["stroke"] },
	ratingStar: { icon: RatingStar, styles: ["stroke"] },
	shareExternal: { icon: ShareExternal, styles: ["stroke"] },
	userMaler: { icon: UserMale, styles: ["stroke"] },
	placementTeaser: { icon: PlacementTeaser, styles: ["stroke"] },
	locationPin: { icon: LocationPin, styles: ["stroke"] },
	arrowTop: { icon: ArrowTop, styles: ["fill"] },
	folder: { icon: Folder, styles: ["stroke"] },
	shieldCheck: { icon: ShieldCheck, styles: ["stroke"] },
	pencil: { icon: Pencil, styles: ["stroke"] },
	csv: { icon: CSV, styles: ["stroke"] },
	bulbRibbon: { icon: BulbRibbon, styles: [] },
	growRibbon: { icon: GrowRibbon, styles: [] },
	hatRibbon: { icon: HatRibbon, styles: [] },
	placementRibbon: { icon: PlacementRibbon, styles: [] },
	circle: { icon: Circle, styles: ["stroke", "fill-also"] },
	rewardStars: { icon: RewardStars, styles: ["stroke"] },
	greenCheckRibbon: { icon: GreenCheckRibbon, styles: ["fill"] },
	blackCheckRibbon: { icon: BlackCheckRibbon, styles: ["fill"] },
	loopArrow: { icon: LoopArrow, styles: ["stroke"] },
	feedback2: { icon: Feedback2, styles: ["stroke", "fill-also"] },
	arrowLoop: { icon: ArrowLoop, styles: ["stroke"] },
	bridgeOverRiver: { icon: BridgeOverRiver, styles: ["stroke"] },
	computerWithHeart: { icon: ComputerWithHeart, styles: ["stroke"] },
	dotsConnected: { icon: DotsConnected, styles: ["stroke"] },
	flowerInCircle: { icon: FlowerInCircle, styles: ["stroke"] },
	handsWithHeart: { icon: HandsWithHeart, styles: ["stroke"] },
	handWithFlower: { icon: HandWithFlower, styles: ["stroke"] },
	lightBulb: { icon: LightBulb, styles: ["stroke"] },
	peopleHighFive: { icon: PeopleHighFive, styles: ["stroke"] },
	personWithStars: { icon: PersonWithStars, styles: ["stroke"] },
	rocket: { icon: Rocket, styles: ["stroke"] },
	workingDesk: { icon: WorkingDesk, styles: ["stroke"] },
};
