import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query rootOverview_GetRootOverviewQuery($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				id
				description
				instructorsV2 {
					edges {
						node {
							id
						}
					}
					...instructorsComponent_InstructorsConnectionFragment
				}
				structureDefinition {
					title
					... on LearnOpportunityRootStructureDefinition {
						extension {
							... on PublishedRootExtensionImpl {
								trailer {
									url
								}
								learnGoals
								reducedData {
									receivableGamificationPoints
									isIHK
								}
								unlockInfo {
									kind
								}
								cartClickout {
									relativeLink
								}
								ihkState
								extension {
									... on PublishedConstellationRootExtensionExtension {
										licenseAvailability {
											... on LicenseAvailabilityFreeLicensesAvailable {
												numAvailable
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const BOOK_TREE_MUTATION = graphql`
	mutation rootOverview_BookTreeMutation($input: BookTreeInput!) {
		Billing {
			bookTree(input: $input) {
				clientMutationId
				root {
					...advanceTreeNavigation_LearnOpportunityV2Fragment
				}
			}
		}
	}
`;
