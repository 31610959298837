import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	justify-between
	flex-col
	md:flex-row
`;

export const UserWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	gap-[12px]
`;

export const UserDataWrapper = tw.div`
	flex
	flex-row
	items-center
	gap-12
`;

export const IconWrapper = tw.div`
	hidden
	md:flex
	justify-center
	items-center
	w-[40px]
	h-[40px]
	bg-shade-10
	rounded-[12px]
`;

export const NameWrapper = tw.div`
	flex
	flex-col
	items-start
	justify-between
	h-full
`;

export const LabelsWrapper = tw.div`
	flex
	items-end
	min-h-full
	gap-4
	md:ml-[4px]
`;

export const AllocationWrapper = tw.div`
	flex
	flex-row
	items-center
	justify-between
	md:justify-end
	gap-8
	border-t
	border-shade-10
	md:border-none
	mt-12
	pt-12
	md:mt-0
	md:pt-0
`;

export const AllocationButtonWrapper = tw.div`
	flex
	flex-row
	items-center
	gap-16
`;

export const AllocationButton = tw.button`
	flex
	items-center
	justify-center
	w-[24px]
	h-[24px]
	bg-shade-5
	rounded-full
	disabled:cursor-not-allowed
`;
