import graphql from "babel-plugin-relay/macro";
import React, { useContext, useEffect, useState } from "react";
import { useFragment } from "react-relay";
import { AudioPlayerContext } from "@components/audio-player/audio-player.context";
import { DynamicImageDisplayComponent_PodcastWithTimestampElementFragment$key } from "@relay/DynamicImageDisplayComponent_PodcastWithTimestampElementFragment.graphql";
import { DynamicImageDisplayProps } from "./dynamic-image-display.interface";
import { Image, ImagePlaceHolder } from "./dynamic-image-display.styles";

const PODCAST_WITH_TIMESTAMP_ELEMENT_FRAGMENT = graphql`
	fragment DynamicImageDisplayComponent_PodcastWithTimestampElementFragment on PodcastWithTimestampLearnElement {
		timestampsWithImage {
			start
			end
			imageFile {
				url
			}
		}
	}
`;

export const DynamicImageDisplayComponent = ({
	podcastWithTimestampFragmentRef,
}: DynamicImageDisplayProps) => {
	const podcastWithTimestamp =
		useFragment<DynamicImageDisplayComponent_PodcastWithTimestampElementFragment$key>(
			PODCAST_WITH_TIMESTAMP_ELEMENT_FRAGMENT,
			podcastWithTimestampFragmentRef,
		);

	const { progress } = useContext(AudioPlayerContext);

	const [imageToDisplay, setImageToDisplay] = useState<string | null>(null);

	type timestampWithImageType = typeof podcastWithTimestamp.timestampsWithImage[0];

	useEffect(() => {
		const file =
			podcastWithTimestamp.timestampsWithImage?.find(
				(twi: timestampWithImageType) => progress >= twi.start && progress <= twi.end,
			)?.imageFile?.url || null;

		setImageToDisplay(file);
	}, [progress]);

	if (!imageToDisplay) return <ImagePlaceHolder />;

	return <Image src={imageToDisplay} alt={"Kein Bild vorhanden"} />;
};
