/**
 * @generated SignedSource<<e823e3e872f89284a51619fe77abd9d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignGroupModal_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"standardInviteForm_QueryFragment">;
  readonly " $fragmentType": "assignGroupModal_QueryFragment";
};
export type assignGroupModal_QueryFragment$key = {
  readonly " $data"?: assignGroupModal_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignGroupModal_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./assignGroupModalRefetchQuery.graphql')
    }
  },
  "name": "assignGroupModal_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "standardInviteForm_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4feabe8f5fac5ef58338079df45d4d99";

export default node;
