import { MaterialsTreeSkeleton } from "@components/materials-tree/materials-tree.skeleton";
import { TabWrapper } from "./materials-tab.styles";

export const MaterialsTabSkeleton = () => {
	return (
		<TabWrapper>
			<MaterialsTreeSkeleton />
		</TabWrapper>
	);
};
