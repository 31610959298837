/**
 * @generated SignedSource<<072ab11b0229ab1b3c4c7b2174bf79b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteInvitationInput = {
  clientMutationId?: string | null;
  id: string;
};
export type invitationCard_DeleteInvitationMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteInvitationInput;
};
export type invitationCard_DeleteInvitationMutation$data = {
  readonly AccountMemberManagement: {
    readonly deleteInvitation: {
      readonly deletedId: string | null;
    } | null;
  };
};
export type invitationCard_DeleteInvitationMutation = {
  response: invitationCard_DeleteInvitationMutation$data;
  variables: invitationCard_DeleteInvitationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "invitationCard_DeleteInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DeleteInvitationPayload",
            "kind": "LinkedField",
            "name": "deleteInvitation",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "invitationCard_DeleteInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DeleteInvitationPayload",
            "kind": "LinkedField",
            "name": "deleteInvitation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "deletedId",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9eebfce0d52d096fcc56fcb0869a1905",
    "id": null,
    "metadata": {},
    "name": "invitationCard_DeleteInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation invitationCard_DeleteInvitationMutation(\n  $input: DeleteInvitationInput!\n) {\n  AccountMemberManagement {\n    deleteInvitation(input: $input) {\n      deletedId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "10ab487709e7c3a9c6450b4dd748fc05";

export default node;
