import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { cancelAppointmentMessageCoachModal_CancelBookingAsCoachMutation } from "@relay/cancelAppointmentMessageCoachModal_CancelBookingAsCoachMutation.graphql";
import { shade60 } from "@themes/colors";
import { H1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import {
	AVAILABILITY_FRAGMENT,
	CANCEL_BOOKING_AS_COACH_MUTATION,
} from "./cancel-appointment-message-coach-modal.graphql";
import {
	LabelsWrapper,
	RowWrapper,
	TitleWrapper,
	UsersCountWrapper,
	Wrapper,
} from "./cancel-appointment-message-coach-modal.styles";
import { CancelAppointmentMessageCoachModalProps } from "./cancel-appointment-message-coach-modal.types";
import { AppointmentStatusLabel } from "../appointment-status-label";
import { DialogTemplate } from "../dialog-template";
import { Divider } from "../divider";
import { Icon } from "../icon";
import { InputText } from "../input-text";
import { Label } from "../label";
import { LinkDisplay } from "../link-display";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";
import { UserAvatarsCollection } from "../user-avatars-collection/user-avatars-collection.component";

// TODO: add-translations
export const CancelAppointmentMessageCoachModal = ({
	isVisible = false,
	onAppointmentCancelled,
	onCancel,
	availabilityFragmentRef,
}: CancelAppointmentMessageCoachModalProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const [cancelBookingAsCoach] =
		useMutation<cancelAppointmentMessageCoachModal_CancelBookingAsCoachMutation>(
			CANCEL_BOOKING_AS_COACH_MUTATION,
		);

	const [message, setMessage] = useState("");

	const handleConfirmCancellationOnClick = () => {
		cancelBookingAsCoach({
			variables: {
				input: {
					availabilityId: availability.id,
					cancellationComment: message,
				},
			},
			onCompleted: onAppointmentCancelled,
		});
	};

	const handleMessageOnChange = (message?: string) => {
		setMessage(message ?? "");
	};

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			true,
			"long",
		);
	};

	const msTeamsLink = availability.booking.msTeamsLink;

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const users = participants.map((participant) => participant.user!).filter(Boolean);

	const participantsLabel = `${users.length} Teilnehmer`;

	const offerName = availability.booking.offer?.description?.name;

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				secondaryButtonStretch={false}
				onSecondaryButtonClick={onCancel}
				primaryButtonLabel="Absage senden"
				primaryButtonIconName="calendarDisable"
				primaryButtonColorVersion="error"
				primaryButtonStretch={false}
				onPrimaryButtonClick={handleConfirmCancellationOnClick}
				maxWidthRem={35}
			>
				<Wrapper>
					<TitleWrapper>
						<H1Span>{offerName}</H1Span>
						<LabelsWrapper>
							<AppointmentStatusLabel availabilityFragmentRef={availability} />
							{availability?.booking.offer?.description?.tags.map((tag) => (
								<Label key={tag.id} label={tag.data.name} />
							))}
						</LabelsWrapper>
					</TitleWrapper>
					<RowWrapper>
						<Icon icon="calendar" sizeInRem={1.5} />
						<TkaP2Span>{renderTime()}</TkaP2Span>
					</RowWrapper>
					<RowWrapper>
						<Icon icon="microsoftTeams" sizeInRem={1.5} />
						<LinkDisplay name="Teams Call" link={msTeamsLink ?? ""} />
					</RowWrapper>
					<RowWrapper>
						<Icon icon="participants" sizeInRem={1.5} />
						<UsersCountWrapper>
							<UserAvatarsCollection userFragmentsRef={users} />
							<TkaP3Span tkaColor={shade60}>{participantsLabel}</TkaP3Span>
						</UsersCountWrapper>
					</RowWrapper>
					<InputText
						textArea
						value={message}
						onChange={handleMessageOnChange}
						label="Nachricht an die Teilnehmer"
						placeholder="Deine Mitteilung an alle Teilnehmer..."
					/>
					<Divider />
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
