import { useMemo } from "react";
import { ParticipantPresenceSelectButtonProps } from "./participant-presence-select-button.types";
import { getOptions } from "./participant-presence-select-button.util";
import { SelectButton } from "../select-button";

export const ParticipantPresenceSelectButton = ({
	status,
	onChange,
}: ParticipantPresenceSelectButtonProps) => {
	const options = useMemo(getOptions, []);

	return <SelectButton disableChange value={status} options={options} onChange={onChange} />;
};
