import { Card } from "@components/card/card.component";

import {
	CardWrapper,
	Content,
	LicensesUsedWrapper,
	TagsRow,
	Wrapper,
} from "./licenses-group-card.styles";
import { LabelSkeleton } from "../label/label.skeleton";
import { Skeleton } from "../skeleton";

// TODO: add-translations
export const LicensesGroupCardSkeleton = () => {
	const imageWidth = "4.375rem";

	return (
		<CardWrapper>
			<Card fillParent bodyPaddingInRem={0.75} hasOverflowHidden>
				<Wrapper>
					<Skeleton width={imageWidth} height="100%" />
					<Content>
						<TagsRow>
							<LabelSkeleton />
						</TagsRow>
						<Skeleton width="10rem" />
					</Content>
					<LicensesUsedWrapper>
						<Skeleton height="1.25rem" width="4rem" borderRadius="1rem" />
						<Skeleton width="6rem" />
					</LicensesUsedWrapper>
					<LabelSkeleton />
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};
