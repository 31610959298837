import { TkaH3Span } from "@themes/font-tags";
import { FocusContentMenuProps } from "./focus-content-menu.interface";
import { ActiveItem, ListWrapper, StyledLink, StyledLinkMock } from "./focus-content-menu.styles";

export const FocusContentMenu = ({ items, containerId, enableSpy }: FocusContentMenuProps) => {
	const links = items.map((item) =>
		enableSpy ? (
			<StyledLink
				key={`${item.id}_spy`}
				activeStyle={ActiveItem}
				smooth
				spy={true}
				to={item.id}
				containerId={containerId}
			>
				<TkaH3Span>{item.label}</TkaH3Span>
			</StyledLink>
		) : (
			<StyledLinkMock key={`${item.id}_mock`}>
				<TkaH3Span>{item.label}</TkaH3Span>
			</StyledLinkMock>
		),
	);

	return <ListWrapper>{links}</ListWrapper>;
};
