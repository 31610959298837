import { success0, success100 } from "@themes/colors";
import { RatingSuccessModalProps } from "./order-ihk-certificate-success-modal.types";
import { DialogTemplate } from "../dialog-template";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";

// TODO: add-translations
export const OrderIHKCertificateSuccessModal = ({
	isVisible = false,
	onAccept,
}: RatingSuccessModalProps) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<DialogTemplate
				primaryButtonStretch
				primaryButtonLabel="Okay"
				primaryButtonIconName="arrowRight"
				onPrimaryButtonClick={onAccept}
			>
				<IconHeadlineButtonTemplate
					iconName="certifiedCertificate"
					iconColor={success100}
					iconBackgroundColor={success0}
					title="Dein Zertifikat ist auf dem Weg zu dir"
					subtitle="Herzlichen Glückwunsch zum Abschluss deiner IHK-Weiterbildung! Dein IHK-Zertifikat wird in den nächsten Tagen per Post bei dir eintreffen."
				/>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
