import RewardChestSmalPNG from "@assets/reward-chest-smal.svg";
import { Card } from "@components/card/card.component";

import {
	CardWrapper,
	ContentWrapper,
	RewardImage,
	Tagline,
	Title,
	TitleTaglineWrapper,
	Wrapper,
} from "./micro-learning-reward-card.styles";
import { MicroLearningRewardCardProps } from "./micro-learning-reward-card.type";
import { Label } from "../label";

export const MicroLearningRewardCard = ({
	onClick: handleOnClick,
}: MicroLearningRewardCardProps) => {
	return (
		<CardWrapper>
			<Card fillParent bodyPaddingInRem={0.75} onClick={handleOnClick} hasOverflowHidden>
				<Wrapper>
					<RewardImage src={RewardChestSmalPNG} />
					<ContentWrapper>
						<Label severity="brandStrong" label="Frei einlösbar" />
						<TitleTaglineWrapper>
							<Tagline>Micro Learning</Tagline>
							<Title>Such dir ein Micro Learning aus</Title>
						</TitleTaglineWrapper>
					</ContentWrapper>
				</Wrapper>
			</Card>
		</CardWrapper>
	);
};
