/**
 * @generated SignedSource<<95317bfd417875ecc6eca2d7f18f9b48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type myDocuments_FileInlineFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly thumbnail: string | null;
  readonly url: string | null;
  readonly " $fragmentType": "myDocuments_FileInlineFragment";
};
export type myDocuments_FileInlineFragment$key = {
  readonly " $data"?: myDocuments_FileInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"myDocuments_FileInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "myDocuments_FileInlineFragment"
};

(node as any).hash = "ebe9796260e2cf7b72b14554bb2368f0";

export default node;
