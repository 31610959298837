import { FC, useCallback, useState } from "react";
import { useMutation } from "react-relay";

import PotentialAnalysisImage from "@assets/potential-analysis-modal.svg";
import { Button } from "@components/button";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { infoModalCorporate_GrantPlacementPermissionAndRedirectMutation } from "@relay/infoModalCorporate_GrantPlacementPermissionAndRedirectMutation.graphql";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { Spacing12 } from "@utils/parse-html/parse-html.styles";
import { GRANT_PLACEMENT_PERMISSION_AND_REDIRECT_MUTATION } from "./info-modal-corporate.graphql";
import { ButtonWrapper, StyledImg, Wrapper } from "./info-modal-corporate.styles";
import { InfoModalCorporateProps } from "./info-modal-corporate.types";

export const InfoModalCorporate: FC<InfoModalCorporateProps> = () => {
	const [grantPermissionAndRedirect] =
		useMutation<infoModalCorporate_GrantPlacementPermissionAndRedirectMutation>(
			GRANT_PLACEMENT_PERMISSION_AND_REDIRECT_MUTATION,
		);
	const [isVisible, setVisible] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const handleGrantPermissionAndRedirect = useCallback(() => {
		setIsLoading(true);
		grantPermissionAndRedirect({
			variables: { input: {} },
			onCompleted: (result) => {
				window.location.href =
					result.Placement.grantPlacementPermissionAndRedirect?.placementUrl ?? "";
			},
		});
	}, []);

	const handleOnDismiss = () => {
		setVisible(false);
	};

	const handleClick = () => {
		handleGrantPermissionAndRedirect();
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleOnDismiss}>
			<StyledImg src={PotentialAnalysisImage} alt={"potential-analysis-image"} />
			<Spacing12 />
			<Wrapper>
				<H1Span>Jetzt Wunschkandidaten sichern!</H1Span>
				<TkaP2Span>
					Sichere dir frühzeitig deine Wunschkandidaten - schnell, vertrauensvoll und
					individuell.
				</TkaP2Span>
				<ButtonWrapper>
					<Button label="Jetzt loslegen" onClick={handleClick} loading={isLoading} />
				</ButtonWrapper>
			</Wrapper>
		</ResponsiveBottomSheetModal>
	);
};
