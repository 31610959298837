import tw from "twin.macro";

export const ScreenWrapper = tw.div`
	flex
	flex-col
	h-full
	gap-32
	p-24
	md:p-40
	overflow-y-scroll
`;

export const LicenseGroup = tw.div`
	flex
	flex-col
	md:flex-row
	gap-16
	md:gap-32
	md:justify-between
	md:items-center
`;

export const LicenseWrapper = tw.div`
	flex-1
	md:w-[24rem]
`;

export const LicenseCount = tw.div`
	min-w-[8rem]
	whitespace-nowrap
	flex
	justify-end
`;

export const Divider = tw.div`	
	min-h-[1px]
	self-stretch
	bg-shade-10
`;

export const DiscountWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	md:justify-between
	md:items-center
	gap-16
`;

export const DiscountTitleTagWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const DiscountTitleWrapper = tw.div`
	flex
	flex-row
	gap-8
	items-center
`;

export const DiscountPercentWrapper = tw.div`
	self-end
`;

export const PriceWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	justify-between
	gap-16
	md:gap-32
`;

export const PriceRow = tw.div`
	flex
	flex-col
	gap-8
`;

export const DiscountPrice = tw.div`
	flex
	flex-row
	gap-8
	whitespace-nowrap
	self-end
	md:self-start
`;

export const PaymentWrapper = tw.div`
	flex
	flex-col
	gap-16
`;

export const PanelContentWrapper = tw.div`
	bg-shade-5
	rounded-8
	px-16
	py-24
`;
