import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { decodeToken } from "react-jwt";
import { Permission } from "@relay/PermissionBasedNavigation_Query.graphql";
import { ReduxState } from "@store/index";

export interface JwtClaimData {
	userId: string;

	accountId: string;
	permissionsInAccount: Permission[];
	accountsWithAccess: string[];
}

export interface JwtTokenData {
	accessToken: string;
	refreshToken: string;
}

export interface UserData {
	isAvgsClient?: boolean;
	redirectUrl?: string;
}

export interface AuthState {
	isLoggedIn: boolean;
	loginData?: JwtTokenData;
	firstLogin: boolean;
	isAvgsClient: boolean;
	redirectUrl?: string;
}

let parsedLoginData: JwtTokenData | undefined = undefined;
let parsedUserData: UserData = {};
const LOCAL_STORAGE_LOGIN_DATA_KEY = "tkt-login-data";
const LOCAL_STORAGE_USER_DATA_KEY = "tkt-user-data";
try {
	const storedData = localStorage.getItem(LOCAL_STORAGE_LOGIN_DATA_KEY);
	parsedLoginData = storedData ? JSON.parse(storedData) : undefined;

	const userData = localStorage.getItem(LOCAL_STORAGE_USER_DATA_KEY);
	parsedUserData = userData ? JSON.parse(userData) : {};
} catch {}

const INITIAL_STATE: AuthState = {
	isLoggedIn: !!parsedLoginData,
	loginData: parsedLoginData,
	firstLogin: false,
	isAvgsClient: parsedUserData?.isAvgsClient ?? false,
	redirectUrl: parsedUserData?.redirectUrl,
};

const authSlice = createSlice({
	name: "auth",
	initialState: INITIAL_STATE,
	reducers: {
		setLoggedIn: (
			state,
			action: PayloadAction<{
				tokenData: JwtTokenData;
				redirect?: string;
				firstLogin?: boolean;
				isAvgsClient?: boolean;
				redirectUrl?: string;
			}>,
		) => {
			state.isLoggedIn = true;
			state.loginData = action.payload.tokenData;
			state.firstLogin = action.payload.firstLogin ?? false;
			state.isAvgsClient = action.payload.isAvgsClient ?? false;
			state.redirectUrl = action.payload.redirectUrl;
			localStorage.setItem(
				LOCAL_STORAGE_LOGIN_DATA_KEY,
				JSON.stringify(action.payload.tokenData),
			);
			parsedUserData.isAvgsClient = state.isAvgsClient;
			parsedUserData.redirectUrl = state.redirectUrl;
			localStorage.setItem(LOCAL_STORAGE_USER_DATA_KEY, JSON.stringify(parsedUserData));
		},
		refreshLogin: (state, action: PayloadAction<{ loginData: JwtTokenData }>) => {
			state.isLoggedIn = true;
			state.loginData = {
				accessToken: action.payload.loginData.accessToken,
				refreshToken: action.payload.loginData.refreshToken,
			};
			localStorage.setItem(
				LOCAL_STORAGE_LOGIN_DATA_KEY,
				JSON.stringify(action.payload.loginData),
			);
		},
		logout: (state) => {
			state.isLoggedIn = false;
			state.loginData = undefined;
			state.firstLogin = false;
			state.isAvgsClient = false;
			state.redirectUrl = undefined;
			parsedUserData = {};
			localStorage.removeItem(LOCAL_STORAGE_LOGIN_DATA_KEY);
			localStorage.removeItem(LOCAL_STORAGE_USER_DATA_KEY);
		},
	},
});

export const { setLoggedIn, refreshLogin, logout } = authSlice.actions;
export const AuthSliceReducer = authSlice.reducer;

const selectAuthSlice = (state: ReduxState) => state.auth;

export const selectJwtClaimData = createSelector(selectAuthSlice, (state) => {
	if (state.loginData) {
		return decodeToken<JwtClaimData>(state.loginData.accessToken);
	} else {
		return null;
	}
});
export const selectIsLoggedIn = createSelector(selectAuthSlice, (state) => state.isLoggedIn);
export const selectLoginData = createSelector(selectAuthSlice, (state) => {
	return state.loginData;
});

export const selectFirstLogin = createSelector(selectAuthSlice, (state) => {
	return state.firstLogin;
});

export const selectIsAvgsClient = createSelector(selectAuthSlice, (state) => {
	return state.isAvgsClient;
});

export const selectRedirectUrl = createSelector(selectAuthSlice, (state) => {
	return state.redirectUrl;
});

export const selectCurrentAccountId = createSelector(selectJwtClaimData, (jwtClaimData) => {
	return jwtClaimData?.accountId ? btoa(`Account:${jwtClaimData?.accountId}`) : undefined;
});

export const selectRefreshToken = createSelector(selectLoginData, (loginData) => {
	return loginData?.refreshToken;
});
