import { useCallback, useContext } from "react";
import { DynamicSlideMenuContext } from "@components/dynamic-slide-menu-provider";
import { Icon } from "@components/icon";
import { shade0 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { DynamicSlideMenuNavigateItemProps } from "./dynamic-slide-menu-navigate-item.interface";
import { NavigateTitle, NavigateWrapper } from "./dynamic-slide-menu-navigate-item.styles";

export const DynamicSlideMenuNavigateItem = ({
	title,
	content,
	path,
	children,
}: DynamicSlideMenuNavigateItemProps) => {
	const context = useContext(DynamicSlideMenuContext);
	const { path: currentPath, navigate, depth } = context;
	const handleOnClick = useCallback(() => {
		navigate(path);
	}, [navigate, path]);
	if (currentPath.length > depth && !currentPath.includes(path)) return null;

	return currentPath.includes(path) ? (
		<DynamicSlideMenuContext.Provider value={{ ...context, depth: depth + 1 }}>
			{children}
		</DynamicSlideMenuContext.Provider>
	) : (
		<NavigateWrapper onClick={handleOnClick}>
			<NavigateTitle tkaColor={shade0}>{title}</NavigateTitle>
			{content && <TkaP2Span tkaColor={shade0}>{content}</TkaP2Span>}
			<Icon icon="arrowRight2" sizeInRem={0.75} tkaColor={shade0} />
		</NavigateWrapper>
	);
};
