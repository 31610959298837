import React, { useContext, useEffect, useState } from "react";
import { AudioPlayerContext } from "@components/audio-player/audio-player.context";
import { PlayerControlWrapper } from "@components/audio-player/audio-player.styles";
import { Icon } from "@components/icon";
import { useWindowSize } from "@hooks/use-window-size";
import { shade100 } from "@themes/colors";
import { AudioControlsProps } from "./audio-controls.interface";
import {
	AudioControlWrapper,
	IconPlaceholder,
	PlayIconWrapper,
	TooltipWrapper,
	VolumeSlider,
} from "./audio-controls.styles";

export const AudioControls = ({
	onPlayPauseClick,
	changeVolume,
	onForward,
	onRewind,
}: AudioControlsProps) => {
	const [volumeTooltipActive, setVolumeTooltipActive] = useState<boolean>(false);

	const { isPlaying } = useContext(AudioPlayerContext);

	const { isMediumUp } = useWindowSize();

	const [volume, setVolume] = useState(10);

	useEffect(() => changeVolume(volume), [volume]);

	return (
		<div className="audio-controls">
			<TooltipWrapper
				target="#volume-adjust"
				autoHide={false}
				position={"top"}
				onHide={() => setVolumeTooltipActive(false)}
			>
				<VolumeSlider
					value={volume}
					onChange={(e) => setVolume(e.value as number)}
					orientation="vertical"
				/>
			</TooltipWrapper>
			<AudioControlWrapper>
				{isMediumUp ? (
					<Icon
						icon="volume"
						sizeInRem={1.5}
						tkaColor={shade100}
						id="volume-adjust"
						active={volumeTooltipActive}
						onClick={() => setVolumeTooltipActive(true)}
						onMouseEnter={() => setVolumeTooltipActive(true)}
					/>
				) : (
					<IconPlaceholder />
				)}
				<PlayerControlWrapper>
					<Icon icon="rewind10" sizeInRem={1.5} tkaColor={shade100} onClick={onRewind} />
					<PlayIconWrapper onClick={() => onPlayPauseClick(!isPlaying)}>
						<Icon
							icon={isPlaying ? "pauseArrow" : "playArrow"}
							sizeInRem={1}
							active={isPlaying}
							style={{ transform: "translate(2,0)" }}
							tkaColor={shade100}
						/>
					</PlayIconWrapper>
					<Icon
						icon="forward10"
						sizeInRem={1.5}
						tkaColor={shade100}
						onClick={onForward}
					/>
				</PlayerControlWrapper>
				<IconPlaceholder />
			</AudioControlWrapper>
		</div>
	);
};
