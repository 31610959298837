import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/button";
import { ValidatedInputText } from "@components/validated-input-text/validated-input-text.component";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { brandStrong100 } from "@themes/colors";
import { H1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import {
	LoginEmailPasswordFormState,
	LoginEmailPasswordProps,
} from "./login-email-password.interface";
import {
	FormWrapper,
	InputsWrapper,
	InvitationTitleWrapper,
	TextCenter,
	TextRight,
} from "./login-email-password.styles";

export const LoginEmailPassword = ({
	headerText,
	onSubmit,
	onGoToSignUp,
	onGoToForgotPassword,
}: LoginEmailPasswordProps) => {
	const { t } = useTkaTranslation("loginForm");

	const form = useFormik<LoginEmailPasswordFormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t("login_form.validationInvalidEmail") ?? "")
				.required(t("login_form.validationRequiredEmail") ?? ""),
			password: Yup.string()
				.min(8, t("login_form.validationInvalidPassword") ?? "")
				.required(t("login_form.validationRequiredPassword") ?? ""),
		}),
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				<TextCenter>
					<InvitationTitleWrapper>
						{headerText && <TkaP2Span>{headerText}</TkaP2Span>}
						<H1Span>{t("login_form.title")}</H1Span>
					</InvitationTitleWrapper>
					<H1Span>{t("login_form.title2")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						icon={"email"}
						name={"email"}
						placeholder={t("login_form.placeholderEmail")}
						status={"default"}
						type={"default"}
					/>
					<ValidatedInputText
						formikConfig={form}
						helpText={t("login_form.helperTextPassword")}
						icon={"lock"}
						name={"password"}
						placeholder={t("login_form.placeholderPassword")}
						status={"default"}
						type={"password"}
					/>
					<TextRight>
						<TkaP3Span tkaColor={brandStrong100} onClick={onGoToForgotPassword}>
							{t("login_form.forgotPassword")}
						</TkaP3Span>
					</TextRight>
				</InputsWrapper>

				<Button fillParent label={t("login_form.buttonText")} />

				<TextCenter>
					<TkaP3Span>
						{t("login_form.newHere")}&nbsp;
						<TkaP3Span tkaColor={brandStrong100} onClick={onGoToSignUp}>
							{t("login_form.createAccount")}
						</TkaP3Span>
					</TkaP3Span>
				</TextCenter>
			</FormWrapper>
		</form>
	);
};
