import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";
import { useAuthContext } from "@hooks/use-auth-context";
import { Path } from "@router/paths";
import { InlineWrapper } from "./forgot-password-steps.styles";
import { ForgotPasswordStepsProps, ForgotPasswordValues } from "./forgot-password-steps.types";
import { AuthTemplateStep } from "../auth-template-step";
import { PasswordForgotEmailSendStep } from "../password-forgot-email-send-step";
import { PasswordForgotEmailStep } from "../password-forgot-email-step";

export const ForgotPasswordSteps = ({ inline, onGoToLogin }: ForgotPasswordStepsProps) => {
	const navigate = useNavigate();
	const { forgotPassword } = useAuthContext();
	const [forgotEmail, setForgotEmail] = useState<string | undefined>();

	const handleOnSubmit = (values: ForgotPasswordValues, goToNext: () => void) => {
		forgotPassword(values.email).then(() => {
			setForgotEmail(values.email);
			goToNext();
		});
	};

	const handleGoToLogin = () => {
		if (onGoToLogin) return onGoToLogin();
		navigate(Path.login.path);
	};

	const wrapper = inline ? InlineWrapper : AuthTemplateStep;

	return (
		<MultiStepAuthContainer<ForgotPasswordValues>
			submitStep={1}
			StepWrapper={wrapper}
			onSubmit={handleOnSubmit}
		>
			{({ onNext }) => (
				<>
					<PasswordForgotEmailStep onNext={onNext} onGoToLogin={handleGoToLogin} />
					<PasswordForgotEmailSendStep email={forgotEmail ?? ""} />
				</>
			)}
		</MultiStepAuthContainer>
	);
};
