import { PropsWithChildren } from "react";

export type BranchNodeCardProps = PropsWithChildren<{
	title: string;
	imageUrl?: string;
	moduleNumber: number;
	courseSize: number;
	status: BranchNodeStatus;
}>;

export enum BranchNodeStatus {
	locked = "locked",
	unlocked = "unlocked",
	done = "done",
}
