import type { CarouselResponsiveOption } from "primereact/carousel";
import { UseWindowSizeHelpers } from "@hooks/use-window-size";
import { BreakpointInPx } from "@themes/breakpoint";
import { CarouselOptions } from "./carousel.interface";

export const CarouselOption = (numVisible: number, numScroll: number) => {
	return { numVisible, numScroll };
};
export const getCarouselOptions = ({
	isMediumUp,
	isXLargeUp,
	is2XLargeUp,
}: UseWindowSizeHelpers): CarouselOptions => {
	if (is2XLargeUp) return CarouselOption(4, 4);
	if (isXLargeUp) return CarouselOption(3, 3);
	if (isMediumUp) return CarouselOption(2, 2);
	return CarouselOption(1, 1);
};

export const getCarouselOptionsHalfWidth = ({
	isMediumUp,
	isXLargeUp,
}: UseWindowSizeHelpers): CarouselOptions => {
	if (isXLargeUp) return CarouselOption(3, 3);
	if (isMediumUp) return CarouselOption(2, 2);
	return CarouselOption(1, 1);
};

export const getCarouselResponsiveOptions = (halfWidth = false): CarouselResponsiveOption[] => {
	// Because PrimeReact doesn't use Mobile First, we need to set the last breakpoint to a very high number
	// to ensure that the last breakpoint is always active.
	const biggestBreakpoint = "5000";

	if (halfWidth) {
		return [
			{
				breakpoint: BreakpointInPx.medium.toString(),
				numVisible: 1,
				numScroll: 1,
			},
			{
				breakpoint: BreakpointInPx.large.toString(),
				numVisible: 2,
				numScroll: 2,
			},
			{
				breakpoint: biggestBreakpoint,
				numVisible: 3,
				numScroll: 3,
			},
		];
	}

	return [
		{
			breakpoint: BreakpointInPx.medium.toString(),
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: BreakpointInPx.large.toString(),
			numVisible: 2,
			numScroll: 2,
		},
		{
			breakpoint: BreakpointInPx.xlarge.toString(),
			numVisible: 3,
			numScroll: 3,
		},
		{
			breakpoint: biggestBreakpoint,
			numVisible: 4,
			numScroll: 4,
		},
	];
};
