import { FilledCircle, Progress, Wrapper } from "./radial-progress-bar.styles";
import { RadialProgressBarProps } from "./radial-progress-bar.types";

export const RadialProgressBar = ({ value, sizeInRem }: RadialProgressBarProps) => {
	return (
		<Wrapper sizeInRem={sizeInRem}>
			<FilledCircle sizeInRem={sizeInRem}></FilledCircle>
			<Progress decimalPercentage={value} />
		</Wrapper>
	);
};
