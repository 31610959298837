import tw from "twin.macro";

export const ContentWrapper = tw.div`
	flex
	flex-1
	flex-col
	lg:flex-row
	gap-12
	justify-between
	items-center
`;
