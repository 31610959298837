import { OverlayPanel } from "primereact/overlaypanel";
import styled, { createGlobalStyle } from "styled-components";
import tw from "twin.macro";
import { TkaSpacing, spacing12, spacing24 } from "@themes/spacing";

export const StyledOverlayPanel = styled(OverlayPanel)<{
	padding?: TkaSpacing;
	borderRadius?: TkaSpacing;
}>`
	${tw`
		max-h-[80vh] 
		xl:max-h-[90vh] 
		m-0 
		overflow-y-scroll 
		bg-shade-0
	`}
	${({ padding }) => `padding: ${(padding ?? spacing24).rem()};`}
	${({ borderRadius }) => `border-radius: ${(borderRadius ?? spacing12).rem()};`}
`;

export const OverlayPanelStyle = createGlobalStyle`
	.p-overlaypanel {
		box-shadow: 0px 0px 40px 0px rgba(16, 24, 32, 0.1);
	}
	.p-overlaypanel .p-overlaypanel-content {
		padding: 0 !important;
	}
	.p-overlaypanel:after {
		display: none;
		border-top-color: transparent !important;
		border-bottom-color: transparent !important;
	}
	.p-overlaypanel:before {
		display: none;
		border-top-color: transparent !important;
		border-bottom-color: transparent !important;
	}
`;
