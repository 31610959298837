import { TkaH2Span } from "@themes/font-tags";
import { GroupCardsWrapper } from "./groups-tab.styles";
import { GroupCardSkeleton } from "./parts/group-card/group-card.skeleton";

export const GroupsTabSkeleton = () => {
	return (
		<GroupCardsWrapper>
			<TkaH2Span>Gruppen</TkaH2Span>
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
			<GroupCardSkeleton />
		</GroupCardsWrapper>
	);
};
