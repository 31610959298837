import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
	min-w-[min(45rem, 85vw)]
	p-16
`;

export const IconWrapperWrapper = tw.div`
  flex justify-center
`;
export const IconWrapper = tw.div`
  p-40
  rounded-full
  bg-success-100/10
  flex-grow-0
  max-w-fit
`;
export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const InputsWrapper = tw.div`
	grid
	grid-cols-12
	md:gap-x-16
	lg:gap-x-32
	gap-y-24
`;

export const RowSpan6Wrapper = tw.div`
	col-span-12
	lg:col-span-6
	grid
	grid-cols-12
	lg:grid-cols-6
	gap-y-24
	md:gap-16
`;

export const RowSpan6 = tw.div`
	col-span-12
	md:col-span-6
`;

export const RowSpan10 = tw.div`
	col-span-12
	md:col-span-6
	lg:col-span-10
`;

export const RowSpan12 = styled.div<{ center?: boolean }>`
	${(p) => (p.center ? tw`flex justify-center` : "")}
	${tw`col-span-12`}
`;

export const RowSpan2 = tw.div`
	col-span-12
	md:col-span-6
	lg:col-span-2
`;

export const Hr = styled.hr<{ tkaColor: TkaColor }>`
	${tw`col-span-12`}
	${(p) => `color:${p.tkaColor};`}
`;
export const CheckboxWrapper = tw.div`flex gap-12 items-center`;
export const CheckboxErrorMessage = tw.div``;
