import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment appointmentDetails_AvailabilityFragment on Availability {
		id
		booking {
			kind
			... on BookedForGroup {
				offer {
					description {
						description
					}
					setting {
						... on GroupCoachingSetting {
							maxParticipants
						}
					}
					licenseAvailability {
						... on LicenseAvailabilityFreeLicensesAvailable {
							numAvailable
						}
					}
				}
				participants {
					user {
						id
						...participantPreview_UserFragment
					}
				}
				msTeamsLink
			}
			... on BookedOneToOne {
				offer {
					description {
						description
					}
				}
				participant {
					user {
						id
						...participantPreview_UserFragment
					}
				}
				msTeamsLink
			}
		}
		data {
			startDateTime
			endDateTime
		}
		coachProfile {
			coach {
				id
				...participantPreview_UserFragment
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment appointmentDetails_QueryFragment on Query
	@argumentDefinitions(filterByGroupIds: { type: "[ID!]!" }) {
		...inputUser_QueryFragment @arguments(filterByGroupIds: $filterByGroupIds)
	}
`;

export const ADD_PARTICIPANT_TO_MY_BOOKING_MUTATION = graphql`
	mutation appointmentDetails_AddParticipantToMyBookingMutation(
		$input: AddParticipantToMyBookingInput!
	) {
		Coaching {
			addParticipantToMyBooking(input: $input) {
				availability {
					...appointmentOverviewModal_AvailabilityFragment
				}
			}
		}
	}
`;

export const REMOVE_PARTICIPANT_FROM_MY_BOOKING_MUTATION = graphql`
	mutation appointmentDetails_RemoveParticipantFromMyBookingMutation(
		$input: RemoveParticipantFromMyBookingInput!
	) {
		Coaching {
			removeParticipantFromMyBooking(input: $input) {
				availability {
					...appointmentOverviewModal_AvailabilityFragment
				}
			}
		}
	}
`;

export const REMOVE_PARTICIPANT_FROM_BOOKING_AS_COACH_MUTATION = graphql`
	mutation appointmentDetails_RemoveParticipantFromBookingAsCoachMutation(
		$input: RemoveParticipantFromBookingAsCoachInput!
	) {
		Coaching {
			removeParticipantFromBookingAsCoach(input: $input) {
				availability {
					...appointmentOverviewModal_AvailabilityFragment
				}
			}
		}
	}
`;
