import tw from "twin.macro";

export const Wrapper = tw.div`
    flex
    flex-col
    gap-24
`;

export const AppointmentsWrapper = tw.div`
    flex
    flex-col
    md:flex-row
    flex-wrap
    gap-16
`;

export const NeedConfirmationWrapper = tw.div`
    flex
    flex-col
    gap-8
    p-8
    rounded-12
    bg-shade-5
`;

export const UnconfirmedCardsWrapper = tw.div`
    flex
    flex-col
    md:flex-row
    flex-wrap
    gap-8
`;

export const WarningWrapper = tw.div`
    flex
    flex-row
`;
