import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";
import { H1Span } from "@themes/font-tags";

export const InputTextMock = tw.div`
	flex
	gap-4
	items-center
	md:border-shade-40
	md:border
	rounded-4
	h-40
	p-8
	cursor-pointer
`;

export const Wrapper = tw.div`relative`;
export const FilterTextWrapper = tw.div`	
	overflow-hidden 
  text-ellipsis
  whitespace-nowrap
  `;
export const MobileBadgeWrapper = tw.div`absolute top-0 right-[2px] translate-y-[40%] translate-x-[10%] md:relative md:translate-y-[0] md:translate-x-[0]`;
export const DesktopLabelWrapper = tw.div`shrink-0`;

export const InputSwitchWrapper = tw.div`flex w-full items-center justify-between`;
export const FilterModalWrapper = tw.div`w-full md:w-[18.5rem] flex flex-col z-[1000]`;

export const ButtonWrapper = tw.div`flex items-center justify-center md:hidden`;
export const Hr = styled.hr<{ tkaColor: TkaColor }>`
	${(p) => `color:${p.tkaColor};`}
`;
export const FilterHeadline = tw(H1Span)`md:hidden`;
