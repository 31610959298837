import { useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { Card } from "@components/card";
import { Divider } from "@components/divider";
import { HTMLMouseEvent } from "@customTypes/index";
import { TkaH3Span } from "@themes/font-tags";
import { spacing12 } from "@themes/spacing";
import { BLOCKOUT_DATE_FRAGMENT } from "./blockout-date-card.graphql";

import { ButtonsWrapper, Wrapper } from "./blockout-date-card.styles";
import { BlockoutDateCardProps } from "./blockout-date-card.types";
import { formatDateTimeRange } from "./blockout-date-card.utils";
import { BlockoutDateModal } from "../blockout-date-modal";

// TODO: add-translations
export const BlockoutDateCard = ({
	onChange,
	onDelete,
	blockoutDateFragmentRef = null,
}: BlockoutDateCardProps) => {
	const blockoutDate = useFragment(BLOCKOUT_DATE_FRAGMENT, blockoutDateFragmentRef);

	const [isVisible, setIsVisible] = useState(false);

	const handleOnClick = () => {
		setIsVisible(true);
	};

	const handleOnDismiss = () => {
		setIsVisible(false);
	};

	const handleOnSave = (from: Date, to: Date) => {
		onChange?.(from, to);
		setIsVisible(false);
	};

	const handleOnDelete = (event: HTMLMouseEvent) => {
		event.stopPropagation();
		onDelete?.();
	};

	if (!blockoutDate) return null;

	return (
		<>
			<Card bodyPaddingInRem={spacing12.remValue()} onClick={handleOnClick}>
				<Wrapper>
					<TkaH3Span>{formatDateTimeRange(blockoutDate.from, blockoutDate.to)}</TkaH3Span>
					<Divider />
					<ButtonsWrapper>
						<Button
							colorVersion="tertiary"
							iconName="calendarDisable"
							onClick={handleOnDelete}
						/>
						<Button iconName="arrowRight" label="Anzeigen" />
					</ButtonsWrapper>
				</Wrapper>
			</Card>
			<BlockoutDateModal
				isVisible={isVisible}
				onDismiss={handleOnDismiss}
				blockoutDateFragmentRef={blockoutDate}
				onSave={handleOnSave}
			/>
		</>
	);
};
