import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "@store/index";

export interface VocabolaryState {
	isLearnMode: boolean;
	amountOfQuestions?: number;
	filterByProficiencyLevel?: number;
}

const INITIAL_STATE: VocabolaryState = {
	isLearnMode: false,
};

const vocabularySliceReducer = createSlice({
	name: "vocabulary",
	initialState: INITIAL_STATE,
	reducers: {
		setLearnMode: (state, action: PayloadAction<{ isLearnMode: boolean }>) => {
			state.isLearnMode = action.payload.isLearnMode;
		},
		setAmountOfQuestions: (state, action: PayloadAction<{ amountOfQuestions?: number }>) => {
			state.amountOfQuestions = action.payload.amountOfQuestions;
		},
		setFilterByProficiencyLevel: (
			state,
			action: PayloadAction<{ filterByProficiencyLevel?: number }>,
		) => {
			state.filterByProficiencyLevel = action.payload.filterByProficiencyLevel;
		},
	},
});

export const { setLearnMode, setAmountOfQuestions, setFilterByProficiencyLevel } =
	vocabularySliceReducer.actions;
export const VocabularySliceReducer = vocabularySliceReducer.reducer;

const selectVocabularySlice = (state: ReduxState) => state.vocabulary;

export const selectIsLearnMode = createSelector(selectVocabularySlice, (state) => {
	return state.isLearnMode;
});

export const selectAmountOfQuestions = createSelector(selectVocabularySlice, (state) => {
	return state.amountOfQuestions;
});

export const selectFilterByProficiencyLevel = createSelector(selectVocabularySlice, (state) => {
	return state.filterByProficiencyLevel;
});
