import styled from "styled-components";
import tw from "twin.macro";

export const Flex = styled.div<{ disabled?: boolean }>`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	${({ disabled }) => disabled && tw`opacity-50`}
`;
export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;
