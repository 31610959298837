import tw from "twin.macro";

export const WordCardWrapper = tw.div`flex flex-col cursor-pointer`;

export const WordCardHeaderWrapper = tw.div`flex  items-start`;

export const ModalWrapper = tw.div`
	md:w-[85vw]
	md:max-w-[45rem]
`;

export const WordModalHeaderWrapper = tw.div`flex items-start mb-8`;
export const WordTitleWrapper = tw.div`flex flex-col flex-1`;
export const MarginLeftWrapper = tw.div`ml-auto`;

export const WordProficiencyWrapper = tw.div`
	p-16 
	flex 
	flex-col 
	items-center 
	justify-center 
	rounded-12
	bg-shade-5
	gap-8
`;

export const Spacing8 = tw.div`mb-8`;

export const DescriptionAndNoticeWrapper = tw.div`
	mt-24 
	grid 
	grid-cols-1
	md:grid-cols-2 
	gap-24
`;

export const Column = tw.div`flex flex-col`;

export const CheckboxWrapper = tw.div`mr-8`;

export const WordNoteWrapper = tw.div`
	min-h-[5.8rem]
	rounded-8
	border
	border-shade-20
	p-12
	whitespace-pre-wrap
`;
