import { Checkbox } from "primereact/checkbox";
import styled from "styled-components";
import {
	TkaColor,
	shade0,
	shade5,
	shade10,
	error20,
	shade20,
	brandStrong100,
	shade40,
	info100,
	brandStrong80,
	error100,
	onBrandStrong,
} from "@themes/colors";

export const StyledCheckbox = styled(Checkbox)<{
	backgroundColor: TkaColor;
}>`
	box-sizing: border-box;
	width: 1.5rem;
	height: 1.5rem;

	/* Default */
	&.p-checkbox .p-checkbox-box {
		width: 1.5rem;
		height: 1.5rem;
		border: none;

		background: transparent;
		box-shadow: 0 0 0 1px ${shade40} inset;
	}

	&.p-checkbox.p-checkbox-checked .p-checkbox-box {
		background: ${brandStrong100};
		box-shadow: none;
		.p-checkbox-icon {
			color: ${onBrandStrong};
		}
	}

	/* Focus */
	&.p-checkbox .p-checkbox-box.p-focus {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${info100} inset;
	}

	&.p-checkbox.p-checkbox-checked .p-checkbox-box.p-focus {
		background: ${brandStrong100};
		.p-checkbox-icon {
			color: ${onBrandStrong};
		}
	}

	/* Hover */
	&.p-checkbox .p-checkbox-box:hover {
		background: transparent;
		box-shadow: 0 0 0 1px ${shade20} inset;
	}

	&.p-checkbox.p-checkbox-checked .p-checkbox-box:hover {
		background: ${brandStrong80};
		box-shadow: none;
		.p-checkbox-icon {
			color: ${onBrandStrong};
		}
	}

	/* Disabled */
	&.p-checkbox .p-checkbox-box.p-disabled {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${shade10} inset;
	}

	&.p-checkbox.p-checkbox-checked .p-checkbox-box.p-disabled {
		background: ${shade5};
		box-shadow: none;
		.p-checkbox-icon {
			color: ${shade20};
		}
	}

	/* Error */
	&.p-checkbox.p-invalid .p-checkbox-box {
		border: none;
		background: transparent;
		box-shadow: 0 0 0 1px ${error100} inset;
	}

	&.p-checkbox.p-checkbox-checked.p-invalid .p-checkbox-box {
		background: ${error20};
		.p-checkbox-icon {
			color: ${shade0};
		}
	}
	&.p-checkbox.p-checkbox-checked.p-invalid .p-checkbox-box:hover {
		box-shadow: 0 0 0 1px ${error100} inset;
	}
`;
