import { BranchNodesTreeSkeleton } from "@components/branch-nodes-tree/branch-nodes-tree.skeleton";
import { TabWrapper } from "./branch-nodes-tab.styles";

export const BranchNodeTabSkeleton = () => {
	return (
		<TabWrapper>
			<BranchNodesTreeSkeleton />
		</TabWrapper>
	);
};
