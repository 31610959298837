import { ZonedDateTime, DayOfWeek, LocalDate, ChronoUnit } from "@js-joda/core";

export const parseDateTime = (dateTime: string): Date => {
	const date: ZonedDateTime = ZonedDateTime.parse(dateTime);
	return new Date(date.toOffsetDateTime().toString());
};

export const parseLocalDate = (localDate: string): Date => {
	const date = LocalDate.parse(localDate);
	return new Date(date.year(), date.monthValue() - 1, date.dayOfMonth());
};

export const formatDateTime = (zonedDateTime: string): string => {
	return new Intl.DateTimeFormat("de-DE", {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	}).format(parseDateTime(zonedDateTime));
};

export const formatDateToLocaleString = (date: string) => {
	return parseDateTime(date).toLocaleDateString();
};

export const convertToUTC = (date: Date) => {
	return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const convertFromUTC = (date: Date) => {
	return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const getDayOfWeekName = (dayOfWeek: DayOfWeek): string => {
	return new Intl.DateTimeFormat("de-DE", { weekday: "long" }).format(
		new Date(Date.UTC(0, 0, dayOfWeek.value())),
	);
};

export const getLocalDate = (date: Date): LocalDate => {
	return LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
};

export const isDateInNextMinutes = (date: string, minutes = 0) => {
	return ZonedDateTime.parse(date).minusMinutes(minutes).isBefore(ZonedDateTime.now());
};

export const formatToLocalTime = (zonedDateTime: string): string => {
	return new Intl.DateTimeFormat("de-DE", {
		hour: "2-digit",
		minute: "2-digit",
	}).format(parseDateTime(zonedDateTime));
};

export const formatRelativeTime = (zonedDateTime: ZonedDateTime): string => {
	const daysDiff = ZonedDateTime.now().until(zonedDateTime, ChronoUnit.DAYS);
	if (daysDiff === 0) {
		const hoursDiff = ZonedDateTime.now().until(zonedDateTime, ChronoUnit.HOURS);
		if (hoursDiff === 0) {
			const minutesDiff = ZonedDateTime.now().until(zonedDateTime, ChronoUnit.MINUTES);
			return new Intl.RelativeTimeFormat("de-DE", { style: "short" }).format(
				minutesDiff,
				"minutes",
			);
		}
		return new Intl.RelativeTimeFormat("de-DE", { style: "short" }).format(hoursDiff, "hours");
	}
	return new Intl.RelativeTimeFormat("de-DE", { style: "short" }).format(daysDiff, "day");
};

export const formatToTimeAgo = (lastUpdated?: string | null) => {
	if (!lastUpdated) return "Letzte Durchführung unbekannt";

	const isoDateString = lastUpdated.replace(/\[UTC\]$/, "");
	const lastUpdatedTime = new Date(isoDateString).getTime();
	const currentTime = new Date().getTime();
	const difference = currentTime - lastUpdatedTime;

	const seconds = Math.floor(difference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	const isToday = days === 0;
	const isYesterday = days === 1;

	const formatNumber = (num: number) => {
		return num < 10 ? `0${num}` : num.toString();
	};

	if (isToday) {
		if (hours < 1) {
			return `vor ${minutes} ${minutes === 1 ? "Minute" : "Minuten"}`;
		} else {
			return `vor ${hours} ${hours === 1 ? "Stunde" : "Stunden"}`;
		}
	} else if (isYesterday) {
		const yesterdayDate = new Date(currentTime - 86400000);
		const formattedTime =
			formatNumber(yesterdayDate.getHours()) + ":" + formatNumber(yesterdayDate.getMinutes());
		return `Gestern ${formattedTime} Uhr`;
	} else {
		const formattedDate = new Date(lastUpdatedTime).toLocaleDateString("de-DE", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
		const formattedTime =
			formatNumber(new Date(lastUpdatedTime).getHours()) +
			":" +
			formatNumber(new Date(lastUpdatedTime).getMinutes());
		return `${formattedDate} ${formattedTime}`;
	}
};
