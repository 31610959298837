import { useTranslation } from "react-i18next";
import { Footer } from "@components/footer";
import { Header } from "@components/header";
import { Wrapper, ContentWrapper } from "./header-footer-screen-template.styles";
import { HeaderFooterScreenTemplateProps } from "./header-footer-screen-template.types";

export const HeaderFooterScreenTemplate = ({ children }: HeaderFooterScreenTemplateProps) => {
	const { t } = useTranslation("authLayout");

	const footerItems = [
		{
			label: t("auth_layout.footerImprint"),
			link: "https://www.thekey.academy/impressum",
		},
		{
			label: t("auth_layout.footerPrivacy"),
			link: "https://www.thekey.academy/datenschutzerklaerung/",
		},
		{
			label: t("auth_layout.footerAgb"),
			link: "https://www.thekey.academy/agbs/",
		},
	];

	return (
		<Wrapper>
			<Header />
			<ContentWrapper>{children}</ContentWrapper>
			<Footer items={footerItems} />
		</Wrapper>
	);
};
