import { PathParams } from "@thekeytechnology/epic-ui";
import { type FC } from "react";
import { readInlineData, useFragment, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { CoachingLearnableCard } from "@components/coaching-learnable-card";
import { EducationalOfferCard } from "@components/educational-offer-card";
import { FeedbackQuoteComponent } from "@components/feedback-quote";
import { TextAndMediaSection } from "@components/text-and-media-section";
import { FileType } from "@components/text-and-media-section/text-and-media.types";
import { withSuspense } from "@components/with-suspense";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { trendsDetail_LearnableFragment$key } from "@relay/trendsDetail_LearnableFragment.graphql";
import { trendsDetail_Query } from "@relay/trendsDetail_Query.graphql";
import { trendsDetail_QueryFragment$key } from "@relay/trendsDetail_QueryFragment.graphql";
import { TrendsDetailPath } from "@router/paths";
import { PotentialAnalysisBanner } from "@screens/trends/parts/potential-analysis-banner";
import { coachingLink } from "@screens/trends/trends.consts";
import { TrendMedia } from "./parts/trend-media";
import { TrendUspBoxes } from "./parts/trend-usp-boxes";
import { LEARNABLE_FRAGMENT, QUERY, QUERY_FRAGMENT } from "./trends-detail.graphql";
import { OfferItem, OfferCardsWrapper, Spacing80, ContentWrapper } from "./trends-detail.styles";

const TrendsDetailScreen: FC = () => {
	const { trendId } = useParams<PathParams<typeof TrendsDetailPath>>();

	const query = useLazyLoadQuery<trendsDetail_Query>(
		QUERY,
		{
			id: trendId ?? "",
		},
		{ fetchPolicy: "store-and-network" },
	);

	const data = useFragment<trendsDetail_QueryFragment$key>(QUERY_FRAGMENT, query);

	const learnables = data.node?.learnables?.map((e) =>
		readInlineData<trendsDetail_LearnableFragment$key>(LEARNABLE_FRAGMENT, e),
	);

	const trend = useFragment<trendsDetail_QueryFragment$key>(QUERY_FRAGMENT, query);

	const coachingSectionProps = {
		headline: trend.node?.coachingHeadingAndDescription?.heading ?? "",
		text: trend.node?.coachingHeadingAndDescription?.description ?? "",
		fileURL: trend.node?.coachingImage?.url ?? "",
		fileType: "image" as FileType,
	};

	const handleCoachingOnClick = () => {
		window.open(coachingLink, "_blank", "noreferrer");
	};

	return (
		<ScreenWithNavbarLayout>
			<ContentWrapper>
				<TrendMedia queryFragmentRef={data.node} />
				<TrendUspBoxes title="Die Vorteile auf einen Blick" queryFragmentRef={data.node} />
				<Spacing80 />
				<TextAndMediaSection
					mediaPosition="left"
					{...coachingSectionProps}
					primaryButtonLabel="Zur Beratung"
					primaryButtonOnClick={handleCoachingOnClick}
				/>
				<Spacing80 />
				<FeedbackQuoteComponent queryFragmentRef={data.node} quotesAreLeftAligned={true} />
				<Spacing80 />
				<PotentialAnalysisBanner />
				<Spacing80 />
				<OfferCardsWrapper>
					{learnables?.map((learnable) => (
						<OfferItem key={learnable.id}>
							{learnable.kind === "Course" || learnable.kind === "MicroLearning" ? (
								<EducationalOfferCard
									publishedCourseLearnableFragmentRef={learnable}
								/>
							) : learnable.kind === "OneToOneCoaching" ||
							  learnable.kind === "GroupCoaching" ? (
								<CoachingLearnableCard coachingLearnableFragmentRef={learnable} />
							) : null}
						</OfferItem>
					))}
				</OfferCardsWrapper>
			</ContentWrapper>
		</ScreenWithNavbarLayout>
	);
};

export default withSuspense(TrendsDetailScreen, null);
