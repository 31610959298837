import { useContext } from "react";
import { DynamicSlideMenuContext } from "@components/dynamic-slide-menu-provider";
import { Icon } from "@components/icon";
import { shade0 } from "@themes/colors";
import { TkaH2Span } from "@themes/font-tags";
import { DynamicSlideMenuParentProps } from "./dynamic-slide-menu-parent.interface";
import { BackWrapper, HeaderWrapper, ParentWrapper } from "./dynamic-slide-menu-parent.styles";

export const DynamicSlideMenuParent = ({
	title,
	width,
	path,
	children,
}: DynamicSlideMenuParentProps) => {
	const context = useContext(DynamicSlideMenuContext);
	const { path: currentPath, goBack, depth } = context;

	const canGoBack = currentPath.length > 1;

	if (!currentPath.includes(path)) return null;

	return currentPath.includes(path) && depth === currentPath.length ? (
		<ParentWrapper width={width} onClick={(e) => e.stopPropagation()}>
			{(canGoBack || title) && (
				<HeaderWrapper>
					{canGoBack && (
						<BackWrapper>
							<Icon
								icon={"arrowLeft2"}
								tkaColor={shade0}
								sizeInRem={0.75}
								onClick={goBack}
							/>
						</BackWrapper>
					)}
					{title && <TkaH2Span tkaColor={shade0}>{title}</TkaH2Span>}
				</HeaderWrapper>
			)}
			{children}
		</ParentWrapper>
	) : (
		<>{children}</>
	);
};
