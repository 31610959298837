/**
 * @generated SignedSource<<68794f9eefbea05445dee9cdfc71fa88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PotentialAnalysisGivenAnswerKind = "SelectedWithFreetext" | "Text" | "selected";
export type PotentialAnalysisQuestionDataKind = "MULTIPLE_CHOICE" | "MULTIPLE_CHOICE_FREETEXT" | "SCALE" | "SINGLE_CHOICE" | "SINGLE_CHOICE_FREETEXT" | "TEXT";
import { FragmentRefs } from "relay-runtime";
export type scaleQuestion_PotentialAnalysisQuestionFragment$data = {
  readonly answerOptions?: ReadonlyArray<{
    readonly answerOptionId: number;
    readonly text: string;
  }>;
  readonly givenAnswer?: {
    readonly answerOptionId: number;
    readonly kind: PotentialAnalysisGivenAnswerKind;
  } | null;
  readonly id: string;
  readonly kind: PotentialAnalysisQuestionDataKind;
  readonly questionText?: string;
  readonly " $fragmentType": "scaleQuestion_PotentialAnalysisQuestionFragment";
};
export type scaleQuestion_PotentialAnalysisQuestionFragment$key = {
  readonly " $data"?: scaleQuestion_PotentialAnalysisQuestionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"scaleQuestion_PotentialAnalysisQuestionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerOptionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "scaleQuestion_PotentialAnalysisQuestionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "questionText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisSelectedGivenAnswer",
          "kind": "LinkedField",
          "name": "givenAnswer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisSelectAnswerOption",
          "kind": "LinkedField",
          "name": "answerOptions",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PotentialAnalysisScaleQuestion",
      "abstractKey": null
    }
  ],
  "type": "PotentialAnalysisQuestion",
  "abstractKey": "__isPotentialAnalysisQuestion"
};
})();

(node as any).hash = "c262b4669b8c8aaa562df058e2ee4bd3";

export default node;
