import { PathParams } from "@thekeytechnology/epic-ui";
import { FC, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "@components/icon";
import { useWindowSize } from "@hooks/use-window-size";
import { GroupsPath } from "@router/paths/profile";
import { BatchEdit } from "@screens/account/parts/batch-edit";
import { shade80, error100, shade100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { BatchEditGroupMembersProps } from "./batch-edit-group-members.types";
import { GroupDetailsContext } from "../../group-details.context";
import { RemoveMembersFromGroupModal } from "../remove-members-from-group-modal";

export const BatchEditGroupMembers: FC<BatchEditGroupMembersProps> = ({
	groupMembersConnectionId,
}) => {
	const { groupId } = useParams<PathParams<typeof GroupsPath>>();

	const { isMediumUp } = useWindowSize();
	const { selectedMembers, clearSelectedMembers } = useContext(GroupDetailsContext);

	const [isVisibleRemoveMemberModal, setIsVisibleRemoveMemberModal] = useState(false);

	const isVisible = selectedMembers.length > 1;

	const handleOnClear = () => {
		clearSelectedMembers();
	};

	const handleDeleteOnClick = () => {
		setIsVisibleRemoveMemberModal(true);
	};

	const hanldeRemoveMemberModalOnDismiss = () => {
		setIsVisibleRemoveMemberModal(false);
	};

	const handleRemoveMemberOnSuccess = () => {
		handleOnClear();
		hanldeRemoveMemberModalOnDismiss();
	};

	const batchEditVisible =
		isVisible && ((!isMediumUp && !isVisibleRemoveMemberModal) || isMediumUp);

	if (!groupId) return null;
	return (
		<>
			{isVisible && (
				<BatchEdit
					isVisible={batchEditVisible}
					selectedCount={selectedMembers.length}
					onClear={handleOnClear}
					optionNodes={(ItemWrapper) => [
						<ItemWrapper key="delete" onClick={handleDeleteOnClick}>
							<Icon
								icon="wrong"
								sizeInRem={1}
								tkaColor={isMediumUp ? shade100 : error100}
							/>
							<TkaP2Span tkaColor={isMediumUp ? shade80 : error100}>
								Aus Gruppe entfernen
							</TkaP2Span>
						</ItemWrapper>,
					]}
				/>
			)}
			<RemoveMembersFromGroupModal
				isVisible={isVisibleRemoveMemberModal}
				selectedMembers={selectedMembers}
				groupId={groupId}
				groupMembersConnectionId={groupMembersConnectionId}
				onDismiss={hanldeRemoveMemberModalOnDismiss}
				onSuccess={handleRemoveMemberOnSuccess}
			/>
		</>
	);
};
