export enum BusinessTypeOptions {
	Ag = "Ag",
	Einzelunternehmen = "Einzelunternehmen",
	Ev = "Ev",
	Gbr = "Gbr",
	Gmbh = "Gmbh",
	GmbhCoKg = "GmbhCoKg",
	Kg = "Kg",
	OeffentlicheEinrichtung = "OeffentlicheEinrichtung",
	Ohg = "Ohg",
	Ug = "Ug",
	PartmbH = "PartmbH",
	Unknown = "Unknown",
}
