/**
 * @generated SignedSource<<14da3e57ff709c5cd0dede1270891a82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cancelAppointmentModal_AvailabilityFragment$data = {
  readonly booking: {
    readonly msTeamsLink?: string;
    readonly participant?: {
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"userAvatarsCollection_UserFragment">;
      } | null;
    };
    readonly participants?: ReadonlyArray<{
      readonly user: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"userAvatarsCollection_UserFragment">;
      } | null;
    }>;
  };
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly id: string;
  readonly " $fragmentType": "cancelAppointmentModal_AvailabilityFragment";
};
export type cancelAppointmentModal_AvailabilityFragment$key = {
  readonly " $data"?: cancelAppointmentModal_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cancelAppointmentModal_AvailabilityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "InlineDataFragmentSpread",
        "name": "userAvatarsCollection_UserFragment",
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "userAvatar_UserFragment"
          }
        ],
        "args": null,
        "argumentDefinitions": ([]/*: any*/)
      }
    ],
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "msTeamsLink",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "cancelAppointmentModal_AvailabilityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participants",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "BookedForGroup",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participant",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "BookedOneToOne",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Availability",
  "abstractKey": null
};
})();

(node as any).hash = "b7515b51ff5e5a2e30beef6a38d69fdd";

export default node;
