import tw from "twin.macro";

export const Wrapper = tw.div`
	md:min-w-[25rem]
	flex
	flex-col
	flex-wrap
	gap-12
	pb-12
`;

export const TitleWrapper = tw.div`
	flex
	flex-wrap
`;
