import { convert, ZonedDateTime } from "@js-joda/core";

export const getAppointmentStatus = (startDate: string, endDate: string): AppointmentStatus => {
	const startDateTime = ZonedDateTime.parse(startDate).minusMinutes(10);
	const endDateTime = ZonedDateTime.parse(endDate).minusMinutes(15);
	if (endDateTime.isBefore(ZonedDateTime.now())) return AppointmentStatus.Ended;
	if (startDateTime.isAfter(ZonedDateTime.now())) return AppointmentStatus.Confirmed;
	return AppointmentStatus.Running;
};

export enum AppointmentStatus {
	Ended = "ended",
	Confirmed = "confirmed",
	Running = "running",
}

export const formatAppointmentDateTimeRange = (
	start: string,
	end: string,
	forCoach: boolean,
	weekday?: "short" | "long",
) => {
	const startDateTime = ZonedDateTime.parse(start);
	const endDateTime = forCoach
		? ZonedDateTime.parse(end)
		: ZonedDateTime.parse(end).minusMinutes(15);

	const fromDateTimeFormated = formatDateTime(startDateTime, weekday);

	const sameDay = startDateTime.toLocalDate().equals(endDateTime.toLocalDate());
	if (sameDay) {
		const toTimeFormated = formatTime(endDateTime);
		return `${fromDateTimeFormated} bis ${toTimeFormated} Uhr`;
	} else {
		const toDateTimeFormated = formatDateTime(endDateTime, weekday);
		return `${fromDateTimeFormated} Uhr bis ${toDateTimeFormated} Uhr`;
	}
};

export const formatDateTime = (zonedDateTime: ZonedDateTime, weekday?: "short" | "long") => {
	return new Intl.DateTimeFormat("de-DE", {
		weekday,
		year: "2-digit",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
	}).format(convert(zonedDateTime).toDate());
};

export const formatTime = (zonedDateTime: ZonedDateTime) => {
	return new Intl.DateTimeFormat("de-DE", {
		hour: "2-digit",
		minute: "2-digit",
	}).format(convert(zonedDateTime).toDate());
};

export const formatCalendarWeekAndYear = (calendarWeekAndYear: string) => {
	const [week, year] = calendarWeekAndYear.split("_").map(Number);
	return {
		week,
		year,
	};
};
