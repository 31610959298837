import { useRef } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import RewardChestSmalPNG from "@assets/reward-chest-smal.svg";
import { Card } from "@components/card/card.component";
import { HTMLMouseEvent } from "@customTypes/index";
import { useWindowSize } from "@hooks/use-window-size";
import { Path } from "@router/paths";

import { LicenseContextMenuRef } from "@screens/licenses/parts/manage-licenses-tab/parts/license-context-menu/license-context-menu.types";
import { OffersLocationState } from "@screens/offers/offers.types";
import { error100, shade60, info100, warning100 } from "@themes/colors";
import { TkaP3Span } from "@themes/font-tags";
import { LICENSE_GROUPING_FRAGMENT } from "./licenses-group-card.graphql";
import {
	BarWrapper,
	CardImage,
	CardWrapper,
	Content,
	LabelContainer,
	LabelWrapper,
	LicenseBarContainer,
	StatusInfo,
	StatusWrapper,
	StyledTkaP3Span,
	TagsRow,
	Title,
	TitleSubtitleWrapper,
	UsedLicensesBar,
	Wrapper,
} from "./licenses-group-card.styles";
import { LicensesGroupCardProps } from "./licenses-group-card.type";
import { Button } from "../button";
import { Divider } from "../divider";
import { Icon } from "../icon";
import { Label } from "../label";
import { ContextMenuWrapper } from "../user-card/user-card.styles";

// TODO: add-translations
export const LicensesGroupCard = ({
	licensesGroupCardFragmentRef,
	isAdminCard = false,
	onClick,
	children,
}: LicensesGroupCardProps) => {
	const ref = useRef<LicenseContextMenuRef>(null);
	const licenseGroup = useFragment(
		LICENSE_GROUPING_FRAGMENT,
		licensesGroupCardFragmentRef ?? null,
	);

	const navigate = useNavigate();
	const { isMediumUp } = useWindowSize();

	if (!licenseGroup) return null;
	const totalLicenseAmount = isAdminCard
		? licenseGroup.totalLicenseAmount
		: licenseGroup.totalLicenseAmountForUser;
	const usedLicenseAmount = isAdminCard
		? licenseGroup.usedLicenseAmount
		: licenseGroup.usedLicenseAmountForUser;
	const isChooseMicroLearningCard = licenseGroup.kind === "MicroLearning";

	const learnableKind = licenseGroup.learnable?.kind;
	const isCoaching = learnableKind === "GroupCoaching" || learnableKind === "OneToOneCoaching";
	const isMicroLearning =
		licenseGroup.learnable?.root?.structureDefinition?.extension?.extension?.isMicroLearning;
	const isTreeLicense = learnableKind === "Course" || learnableKind === "MicroLearning";

	const imageUrl =
		licenseGroup.learnable?.groupCoaching?.description?.image?.thumbnail ??
		licenseGroup.learnable?.oneToOneCoaching?.description?.image?.thumbnail ??
		licenseGroup.learnable?.root?.image?.thumbnail;

	const numberOfFreeLicenses = totalLicenseAmount - usedLicenseAmount;
	const freeLicensesLabel =
		numberOfFreeLicenses > 1
			? `${numberOfFreeLicenses}/${totalLicenseAmount} Verfügbar`
			: "Verfügbar";

	const usedLicensesLabel =
		usedLicenseAmount === 1
			? "Eingelöst"
			: usedLicenseAmount > 1
			? `${usedLicenseAmount}/${usedLicenseAmount} Eingelöst`
			: `${totalLicenseAmount}/${totalLicenseAmount} Eingelöst`;

	const licensesStillAvailable = numberOfFreeLicenses > 0;
	const licensesLabelSeverity = numberOfFreeLicenses > 0 ? "success" : "info";

	const usedLicensesPercentage = (usedLicenseAmount / totalLicenseAmount) * 100;

	const noAssignmentRules = licenseGroup.assignmentRules.length === 0;
	const showStatusInfo = noAssignmentRules || usedLicensesPercentage > 49;
	const allLicensesUsed = usedLicensesPercentage === 100;

	const contractPartner =
		licenseGroup?.learnable?.groupCoaching?.description?.contractPartner ||
		licenseGroup?.learnable?.oneToOneCoaching?.description?.contractPartner ||
		licenseGroup?.learnable?.root?.structureDefinition?.extension?.extension?.contractPartner;

	const handlePartnerOnClick = () => {
		contractPartner?.data.linkToAGB && window.open(contractPartner.data.linkToAGB, "_blank");
	};

	const partnerOnClickHandler = contractPartner?.data.linkToAGB
		? handlePartnerOnClick
		: undefined;

	const handleOnClick = () => {
		if (isAdminCard) {
			onClick?.(licenseGroup.id);
		}
		if (isTreeLicense && !isAdminCard) {
			const rootId = licenseGroup?.learnable?.root?.id;
			rootId && navigate(Path.root.withId(rootId).path);
		} else if (isCoaching && !isAdminCard) {
			const coachingId =
				licenseGroup?.learnable?.groupCoaching?.id ??
				licenseGroup?.learnable?.oneToOneCoaching?.id;
			coachingId && navigate(Path.coaching.withId(coachingId).path);
		}
	};

	const handleOnMicroLearningCardClick = () => {
		if (isAdminCard) {
			onClick?.(licenseGroup.id);
		} else {
			const state: Partial<OffersLocationState> = {
				state: { filters: { kinds: ["MicroLearning"] } },
			};
			navigate(Path.progression.path, state);
		}
	};

	const title = isChooseMicroLearningCard
		? "Such dir ein Micro Learning aus"
		: licenseGroup?.learnable?.root?.structureDefinition?.title ??
		  licenseGroup?.learnable?.groupCoaching?.description?.name ??
		  licenseGroup?.learnable?.oneToOneCoaching?.description?.name;
	const subtitle = match({ isMicroLearning, isChooseMicroLearningCard, isCoaching })
		.with(
			{
				isMicroLearning: true,
			},
			() => "Micro Learning",
		)
		.with({ isCoaching: true }, () => "Coachings")
		.with({ isChooseMicroLearningCard: true }, () => "Micro Learning")
		.otherwise(() => "Weiterbildungen");

	const handleOpenContextMenu = (event: HTMLMouseEvent) => {
		ref.current?.toggle(event, event?.currentTarget);
	};

	const statusTkaColor = allLicensesUsed ? error100 : noAssignmentRules ? warning100 : info100;

	const getStatusText = () => {
		return allLicensesUsed
			? "Lizenzen aufgebraucht"
			: noAssignmentRules
			? "Keine Zuweisungsregel(n)"
			: "Lizenzen auffüllen";
	};

	return (
		<>
			<CardWrapper>
				<Card
					fillParent
					bodyPaddingInRem={0.75}
					onClick={
						isChooseMicroLearningCard ? handleOnMicroLearningCardClick : handleOnClick
					}
				>
					<Wrapper>
						{(isChooseMicroLearningCard || imageUrl) && (
							<CardImage
								src={isChooseMicroLearningCard ? RewardChestSmalPNG : imageUrl!}
								alt=""
								aria-hidden="true"
							/>
						)}
						<Content>
							{isChooseMicroLearningCard ? (
								<TagsRow>
									<Label
										label="Frei einlösbar"
										severity="brandStrong"
										onClick={partnerOnClickHandler}
									/>
								</TagsRow>
							) : (
								contractPartner && (
									<TagsRow>
										<Label
											label={contractPartner.data.name}
											severity="default"
											onClick={partnerOnClickHandler}
										/>
									</TagsRow>
								)
							)}
							<TitleSubtitleWrapper>
								<TkaP3Span>{subtitle}</TkaP3Span>
								<Title>{title}</Title>
							</TitleSubtitleWrapper>
						</Content>
						{isAdminCard ? (
							<>
								{showStatusInfo && (
									<>
										<StatusInfo onClick={(e) => e.stopPropagation()}>
											<Icon
												icon="informationCircle"
												sizeInRem={0.75}
												tkaColor={statusTkaColor}
											/>
											<StyledTkaP3Span tkaColor={statusTkaColor}>
												{getStatusText()}
											</StyledTkaP3Span>
										</StatusInfo>
									</>
								)}
								<StatusWrapper>
									{!isMediumUp && <Divider />}
									<BarWrapper>
										<LicenseBarContainer>
											<UsedLicensesBar width={usedLicensesPercentage} />
										</LicenseBarContainer>
										<TkaP3Span tkaColor={shade60}>
											{licensesStillAvailable
												? freeLicensesLabel
												: usedLicensesLabel}
										</TkaP3Span>
									</BarWrapper>
								</StatusWrapper>
							</>
						) : (
							<LabelWrapper hasPaddingBottom={!isChooseMicroLearningCard}>
								<LabelContainer>
									<Label
										size="smal"
										severity={licensesLabelSeverity}
										label={
											licensesStillAvailable
												? freeLicensesLabel
												: usedLicensesLabel
										}
									/>
									{isChooseMicroLearningCard && (
										<Button
											label="Micro Learning aussuchen"
											iconName="arrowRight"
											colorVersion="outline"
											onClick={handleOnMicroLearningCardClick}
										/>
									)}
								</LabelContainer>
							</LabelWrapper>
						)}
						{isAdminCard && isMediumUp && (
							<ContextMenuWrapper
								onClick={(e) => {
									e.stopPropagation();
									handleOpenContextMenu(e);
								}}
							>
								<Icon icon="contextMenu" sizeInRem={1} />
							</ContextMenuWrapper>
						)}
					</Wrapper>
				</Card>
			</CardWrapper>
			{children?.(ref)}
		</>
	);
};
