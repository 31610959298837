import { css, cva } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const scaleWrapperClass = stack({
	direction: "row",
	gap: "4",
	justifyContent: "center",
	mt: "12",
});

export const poleWrapperClass = stack({
	alignItems: "center",
	gap: "8",
	w: "[3rem]",
	md: {
		w: "[4rem]",
		minW: "[4rem]",
	},
});

export const poleSpacerClass = css({
	w: "[2.375rem]",
	h: "[2.375rem]",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
});

export const poleClass = cva({
	base: {
		w: "[2rem]",
		h: "[2rem]",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "100",
		borderColor: "secondary.80",
		borderStyle: "solid",
		borderWidth: "2px",
		_hover: {
			opacity: 0.6,
			cursor: "pointer",
		},
	},
	variants: {
		isAnswered: {
			true: {
				bg: "onPrimary",
				borderStyle: "none",
			},
		},
		selected: {
			true: {
				bg: "secondary.120",
				w: "[2.375rem]",
				h: "[2.375rem]",
				borderStyle: "none",
				_hover: {
					opacity: 1,
					cursor: "default",
				},
			},
		},
		isBlured: {
			true: {
				opacity: 0.5,
				pointerEvents: "none",
				_hover: {
					cursor: "default",
				},
			},
		},
	},
});

export const labelClass = cva({
	variants: {
		isBlured: {
			true: {
				opacity: 0.5,
			},
		},
	},
});
