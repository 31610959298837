import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query navbar_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						groupAssociations {
							group {
								id
								name
								permissions
							}
						}
					}
				}
				...navbar_AuthViewerSchemaFragment
			}
			PotentialAnalysis {
				MyPotentialAnalysisResult {
					isLongAnalysis
				}
			}
		}
	}
`;

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment navbar_AuthViewerSchemaFragment on AuthViewerSchema {
		...avatarWithProfileMenu_AuthViewerSchemaFragment
	}
`;

export const CLICKOUT_MUTATION = graphql`
	mutation navbar_ClickoutMutation($input: CreateClickOutForUserOrBusinessInput!) {
		Placement {
			createClickOutForUserOrBusiness(input: $input) {
				placementUrl
			}
		}
	}
`;
