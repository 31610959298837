import { RootCardSkeleton } from "@components/root-card/root-card.skeleton";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";

import { H1Span } from "@themes/font-tags";
import { ListWrapper, Wrapper } from "./my-coaching-offers.styles";

//TODO: add-translations
export const MyCoachingOffersScreenSkeleton = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
			<Wrapper>
				<H1Span>Meine Coaching-Angebote</H1Span>
				<ListWrapper>
					<RootCardSkeleton />
					<RootCardSkeleton />
					<RootCardSkeleton />
					<RootCardSkeleton />
					<RootCardSkeleton />
					<RootCardSkeleton />
					<RootCardSkeleton />
					<RootCardSkeleton />
					<RootCardSkeleton />
				</ListWrapper>
			</Wrapper>
		</ScreenWithProfileMenuLayout>
	);
};
