import styled from "styled-components";
import tw from "twin.macro";
import { Button } from "@components/button";
import { brandStrong100, shade20 } from "@themes/colors";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-[2rem]
`;

export const LicenseInfoWrapper = tw.div`
	w-[100%]
	flex
	flex-col
`;

export const LicenseBarContainer = tw.div`
	relative
	flex
	w-[100%]
	h-[1rem]
	mt-[0.5rem]
	mb-[0.5rem]
	rounded-[5.875rem]
	bg-shade-10
	after:absolute
	after:z-10
	after:w-full
	after:h-full
	after:rounded-[5.875rem]
	after:shadow-[inset_-1px_1px_15px_0px_rgba(9,49,71,0.2)]
	after:mix-blend-multiply
	before:absolute
	before:z-10
	before:w-full
	before:h-full
	before:rounded-[5.875rem]
	before:shadow-[inset_0_0_1px_0_rgba(6,31,42,0.3)]
	before:mix-blend-darken
`;

export const UsedLicensesBar = styled.div<{ width: number }>`
	width: ${(props) => props.width}%;
	${tw`
		h-[100%]
		bg-brand-strong-100
		rounded-[5.875rem]
		z-[1]
`};
`;

export const LegendLicenseBar = styled.div<{ status: "used" | "remaining" }>`
	background-color: ${(props) => (props.status === "used" ? brandStrong100 : shade20)};
	${tw`
		h-[0.25rem]
		w-[1.25rem]
		rounded-[0.625rem]
	`};
`;

export const LicenseLabels = tw.div`
	flex
	items-center
	gap-[0.75rem]
`;

export const LicenseLabel = tw.div`
	flex
	items-center
	gap-[0.5rem]
`;

export const StyledButton = tw(Button)`
	ml-[-1rem]
	underline
	decoration-brand-strong-100
`;

export const Breadcrumbs = tw.div`
flex
gap-[0.25rem]`;
