import React from "react";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { Header, NoGrowWrapper, SearchAndFiltersWrapper } from "@screens/offers/offers.styles";
import { H1Span } from "@themes/font-tags";
import { FiltersSkeleton } from "./parts/filters/filters.skeleton";
import { GridViewSkeleton } from "./parts/grid-view/grid-view.skeleton";
import { SearchBarSkeleton } from "./parts/search-bar/search-bar.skeleton";

export const OffersScreenSkeleton = () => {
	return (
		<ScreenWithNavbarLayout>
			<Header>
				<H1Span>Weiterentwicklung</H1Span>
				<SearchAndFiltersWrapper>
					<SearchBarSkeleton />
					<NoGrowWrapper>
						<FiltersSkeleton />
					</NoGrowWrapper>
				</SearchAndFiltersWrapper>
			</Header>
			<GridViewSkeleton />
		</ScreenWithNavbarLayout>
	);
};
