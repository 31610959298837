import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query groupDetails_Query($id: ID!) {
		AccountMemberManagement {
			UserInAccountGroupWrapper(id: $id) {
				group {
					name
					isBuiltIn
					...groupDetailsContextMenu_UserInAccountGroupFragment
				}
			}
		}
		... on Query {
			...groupDetails_QueryFragment @arguments(groupId: $id)
			...userInGroupContextMenu_QueryFragment
			...inviteMemberModal_QueryFragment
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment groupDetails_QueryFragment on Query
	@refetchable(queryName: "groupDetailsAccountMembersRefetchQuery")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 100 }
		after: { type: "String", defaultValue: null }
		groupId: { type: "ID!" }
		filterByNameOrEmail: { type: "String", defaultValue: null }
		filterByAccountMemberKind: { type: "AccountMemberKind" }
	) {
		AccountMemberManagement {
			AccountMembers(
				filterByGroupIds: [$groupId]
				filterByNameOrEmail: $filterByNameOrEmail
				filterByAccountMemberKind: $filterByAccountMemberKind
			) {
				__id
				userCount
				invitationCount
				accountMembers(first: $first, after: $after)
					@connection(key: "groupDetails_accountMembers") {
					__id
					edges {
						node {
							id
							kind
							... on UserInAccountWrapper {
								user {
									user {
										id
										...userInGroupContextMenu_UserFragment
									}
								}
							}
							... on InvitationWrapper {
								__id
								invitation {
									id
									email
								}
							}
							...userCard_UserInAccountWrapperFragment
							...invitationCard_InvitationFragment
						}
					}
					pageInfo {
						hasNextPage
					}
				}
			}
		}
	}
`;
