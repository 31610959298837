import { ActivateSuccessStep } from "@components/activate-success-step/activate-success-step.component";
import { AuthTemplate } from "@components/auth-template";
import { AuthTemplateStep } from "@components/auth-template-step";
import { MultiStepAuthContainer } from "@containers/multi-step-auth-container";

export const ActivateSuccessScreen = () => {
	return (
		<AuthTemplate hideImage>
			<MultiStepAuthContainer StepWrapper={AuthTemplateStep}>
				{() => <ActivateSuccessStep />}
			</MultiStepAuthContainer>
		</AuthTemplate>
	);
};
