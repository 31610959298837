/**
 * @generated SignedSource<<ead204f82d2c3cc11897799a9029bbc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userContextMenuRefetchQuery$variables = {};
export type userContextMenuRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"userContextMenu_QueryFragment">;
};
export type userContextMenuRefetchQuery = {
  response: userContextMenuRefetchQuery$data;
  variables: userContextMenuRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userContextMenuRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "userContextMenu_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userContextMenuRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementQueries",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "doCalcMemberCount",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "includeOwner",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "includeUser",
                "value": true
              }
            ],
            "concreteType": "UserInAccountGroupWrappersResult",
            "kind": "LinkedField",
            "name": "UserInAccountGroupWrappers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "UserInAccountGroupWrapperConnection",
                "kind": "LinkedField",
                "name": "userInAccountGroupWrappers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountGroupWrapperEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccountGroupWrapper",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInAccountGroup",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "userInAccountGroupWrappers(first:200)"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "filterModal_userInAccountGroupWrappers",
                "kind": "LinkedHandle",
                "name": "userInAccountGroupWrappers"
              }
            ],
            "storageKey": "UserInAccountGroupWrappers(doCalcMemberCount:true,includeOwner:true,includeUser:true)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8fc576dfa3dfe267776560773f4cf1b",
    "id": null,
    "metadata": {},
    "name": "userContextMenuRefetchQuery",
    "operationKind": "query",
    "text": "query userContextMenuRefetchQuery {\n  ...userContextMenu_QueryFragment\n}\n\nfragment assignGroupModal_QueryFragment on Query {\n  ...standardInviteForm_QueryFragment\n}\n\nfragment standardInviteForm_QueryFragment on Query {\n  AccountMemberManagement {\n    UserInAccountGroupWrappers(includeOwner: true, includeUser: true, doCalcMemberCount: true) {\n      userInAccountGroupWrappers(first: 200) {\n        edges {\n          node {\n            group {\n              id\n              name\n            }\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment userContextMenu_QueryFragment on Query {\n  ...assignGroupModal_QueryFragment\n}\n"
  }
};
})();

(node as any).hash = "757c8b5cf35b4dadd32a785f78848f1c";

export default node;
