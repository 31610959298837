import React, { Children, useEffect, useRef, useState } from "react";
import { useWindowSize } from "@hooks/use-window-size";
import { MultiStepAuthContainerProps } from "./multi-step-auth-container.interface";
import { ContentScrollview, HideOverflow } from "./multi-step-auth-container.styles";

export const MultiStepAuthContainer = <T,>({
	submitStep,
	StepWrapper,
	onSubmit,
	children,
}: MultiStepAuthContainerProps<T>) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const hideOverflowRef = useRef<HTMLDivElement>(null);
	const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
	const [step, setStep] = useState(0);
	const [maxSteps, setMaxSteps] = useState(0);
	const [_, setStepHeight] = useState(0);
	const [formValues, setFormValues] = useState<Partial<T>>({});

	const { windowWidth, windowHeight } = useWindowSize();

	const scrollToElement = (element?: HTMLDivElement | null) => {
		const offsetLeft = scrollRef.current?.offsetLeft ?? 0;
		scrollRef.current?.scrollTo({
			left: (element?.offsetLeft ?? 0) - offsetLeft,
			// behavior: "smooth",
		});
	};

	const handleOnNext = (values: Partial<T>) => {
		const newFormValues = { ...formValues, ...values } as T;
		setFormValues(newFormValues);
		const goToNext = () => {
			scrollToElement(stepRefs.current[step + 1]);
			setStep(step + 1);
		};
		if (step + 1 === (submitStep ?? maxSteps)) {
			onSubmit?.(newFormValues, goToNext);
		} else {
			goToNext();
		}
	};

	const handleOnPrevious = () => {
		scrollToElement(stepRefs.current[step - 1]);
		setStep(step - 1);
	};

	useEffect(() => {
		scrollToElement(stepRefs.current[step]);
	}, [windowWidth, windowHeight]);

	const renderedChildren = Children.map(
		children?.({
			onNext: handleOnNext,
			onPrevious: handleOnPrevious,
			currentStep: step + 1,
		}),
		(child) => {
			if (child?.type === React.Fragment) {
				return child?.props.children;
			}
			return child;
		},
	);

	const wrappedChildren = renderedChildren?.map((child, key) => (
		<div
			key={key}
			ref={(el) => {
				stepRefs.current[key] = el;
			}}
		>
			{StepWrapper ? <StepWrapper>{child}</StepWrapper> : child}
		</div>
	));

	const childrenLength = wrappedChildren?.length || 0;

	useEffect(() => {
		if (maxSteps !== childrenLength) {
			setMaxSteps(childrenLength);
		}
	}, [childrenLength]);

	useEffect(() => {
		const stepRef = stepRefs.current[step];
		if (!stepRef) return;
		const resizeObserver = new ResizeObserver(() => {
			setStepHeight(stepRef.clientHeight);
		});
		resizeObserver.observe(stepRef);
		return () => resizeObserver.disconnect();
	}, [stepRefs.current[step]]);

	const childrenWidth = stepRefs.current.reduce((p, c) => p + (c?.clientWidth ?? 0), 0);

	const stepWidth = stepRefs.current[step]?.clientWidth;
	const stepHeight = stepRefs.current[step]?.clientHeight ?? 0;
	const delay = (hideOverflowRef.current?.clientHeight ?? 0) > stepHeight;

	return (
		<ContentScrollview ref={scrollRef} width={stepWidth}>
			<HideOverflow
				ref={hideOverflowRef}
				height={stepHeight}
				width={childrenWidth}
				delay={delay}
			>
				{wrappedChildren}
			</HideOverflow>
		</ContentScrollview>
	);
};
