import { useContext, useEffect, useState } from "react";
import { useFragment, useMutation, useQueryLoader } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { CanOrderIHKModal } from "@components/can-order-ihk-modal";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { GoToNextElementButton } from "@components/go-to-next-element-button";
import { GoToPreviousElementButton } from "@components/go-to-previous-element-button";
import { LicenseModal } from "@components/license-modal";
import { OrderIhkFlow } from "@components/order-ihk-flow";
import { ProgressBar } from "@components/progress-bar";
import { VOCAB_CONTENT_NODE_ID } from "@consts/index";
import { MultiDialogFlowContainer } from "@containers/multi-dialog-flow-container";
import { contentSubmissionNavigation_BookTreeMutation } from "@relay/contentSubmissionNavigation_BookTreeMutation.graphql";
import { contentSubmissionNavigation_ContentSubmissionFragment$key } from "@relay/contentSubmissionNavigation_ContentSubmissionFragment.graphql";
import { contentSubmissionNavigation_RestartContentNodeAfterFailedMutation } from "@relay/contentSubmissionNavigation_RestartContentNodeAfterFailedMutation.graphql";
import { contentSubmissionNavigation_RestartContentNodeAfterPassedMutation } from "@relay/contentSubmissionNavigation_RestartContentNodeAfterPassedMutation.graphql";
import { Path } from "@router/paths";
import {
	BOOK_TREE_MUTATION,
	CONTENT_SUBMISSION_FRAGMENT,
	QUERY,
	RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
	RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
} from "./content-submission-navigation.graphql";
import { ContentSubmissionNavigationProps } from "./content-submission-navigation.interface";
import { ButtonsWrapper, Wrapper } from "./content-submission-navigation.styles";

// TODO: add-translations

export const ContentSubmissionNavigation = ({
	contentSubmissionFragmentRef,
	...props
}: ContentSubmissionNavigationProps) => {
	const contentSubmission =
		useFragment<contentSubmissionNavigation_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const [restartContentNodeAfterPassed, loadingRestartAfterPassed] =
		useMutation<contentSubmissionNavigation_RestartContentNodeAfterPassedMutation>(
			RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
		);

	const [restartContentNodeAfterFailed, loadingRestartAfterFailed] =
		useMutation<contentSubmissionNavigation_RestartContentNodeAfterFailedMutation>(
			RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
		);

	const [bookTree] =
		useMutation<contentSubmissionNavigation_BookTreeMutation>(BOOK_TREE_MUTATION);

	const [_, loadQuery] = useQueryLoader(QUERY);

	const navigate = useNavigate();

	const { backButtonHidden } = useContext(ContentSubmissionModalContext);

	const rootExtension = contentSubmission.learnOpportunity?.root?.structureDefinition.extension;

	const [modalIsVisible, setModalIsVisible] = useState(false);
	const [licenseModalShowed, setLicenseModalShowed] = useState(false);

	const [isOrderIHKModalVisible, setIsOderIHKModalVisible] = useState(false);
	const [orderIHKModalShowed, setOrderIHKModalShowed] = useState(
		rootExtension?.ihkState === "ReadyToOrder",
	);

	const contentId = contentSubmission.learnOpportunity?.id;
	const rootId = contentSubmission.learnOpportunity?.root?.id;

	const cartLink = rootExtension?.cartClickout?.relativeLink;
	const isFinishedRoot =
		contentSubmission.learnOpportunity?.root?.structureDefinition.viewerTreeState?.kind ===
		"IsFinished";

	const progressBarValue = contentSubmission.definition.progress?.percentage;

	const hasLicense =
		contentSubmission.learnOpportunity?.root?.structureDefinition.extension?.extension
			?.licenseAvailability?.numAvailable;

	const nextContentNode = contentSubmission.learnOpportunity?.nextContentNodeId;
	const hasNextContentNode = !!nextContentNode;
	const failedContentNode = contentSubmission.definition.status === "failed";
	const passedContentNode = contentSubmission.definition.status === "passed";
	const finishedContentNode = passedContentNode || failedContentNode;
	const contentNodeStatus =
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.status;
	const canBeRestartedAfterFailed = contentNodeStatus === "CanBeRestartedAfterFailed";
	const canBeRestartedAfterPassed = contentNodeStatus === "CanBeRestartedAfterPassed";

	const isVocabContent = contentSubmission.learnOpportunity?.id === VOCAB_CONTENT_NODE_ID;

	const canBeRestartet = canBeRestartedAfterFailed || canBeRestartedAfterPassed;

	const hasFullAccess = rootExtension?.unlockInfo?.kind === "FullAccess";
	const nextContentNodeNeedsLicense =
		contentSubmission.learnOpportunity?.nextContentNode?.typeDefinition.extension
			?.isUnlockNecessaryToStart && !hasFullAccess;

	const canOrderIHK = rootExtension?.ihkState === "ReadyToOrder";

	const handleFinishOnClick = () => {
		if (isVocabContent) {
			return navigate(Path.profileMenu.vocabulary.path);
		}
		if (!rootId) return;
		if (canOrderIHK && !orderIHKModalShowed) {
			setIsOderIHKModalVisible(true);
			setOrderIHKModalShowed(true);
			return;
		}
		if (nextContentNodeNeedsLicense && !licenseModalShowed && passedContentNode) {
			setModalIsVisible(true);
			setLicenseModalShowed(true);
			return;
		}
		if (contentId && (failedContentNode || !hasNextContentNode)) {
			return navigate(Path.root.withId(rootId).branchNodes.withId(contentId).path, {
				replace: true,
			});
		}
		if (hasNextContentNode) {
			navigate(Path.root.withId(rootId).branchNodes.withId(nextContentNode).path, {
				replace: true,
			});
		}
	};

	const handleRepeatOnClick = () => {
		if (!contentId) return;
		if (canBeRestartedAfterFailed) {
			restartContentNodeAfterFailed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterFailed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path, { replace: true });
				},
			});
		} else if (canBeRestartedAfterPassed) {
			restartContentNodeAfterPassed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterPassed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path, { replace: true });
				},
			});
		}
	};

	const handleModalOnDismiss = () => {
		setModalIsVisible(false);
		return rootId && navigate(Path.root.withId(rootId).overview.path);
	};

	const handleOderIHKOnDismiss = () => {
		setIsOderIHKModalVisible(false);
	};

	const handleBuyLicenseOnClick = () => {
		setModalIsVisible(false);
		cartLink && navigate(cartLink);
	};

	const handleUseLicenseOnClick = () => {
		if (!rootId) return;
		setModalIsVisible(false);
		bookTree({
			variables: {
				input: {
					rootId,
				},
			},
			onCompleted: handleFinishOnClick,
		});
	};

	useEffect(() => {
		if (finishedContentNode) {
			loadQuery({}, { fetchPolicy: "store-and-network" });
		}
	}, [finishedContentNode]);

	const finishedContentNodeButtonLabel = failedContentNode
		? "Zur Übersicht"
		: isVocabContent
		? "Fertig"
		: "Nächste Lektion";

	const finishedButtonLabel =
		!hasNextContentNode && isFinishedRoot ? "Abschließen" : finishedContentNodeButtonLabel;

	const isLoadingRestart = loadingRestartAfterPassed || loadingRestartAfterFailed;
	return (
		<Wrapper {...props}>
			<ProgressBar colorVersion={"course"} value={progressBarValue || 0} />
			<ButtonsWrapper>
				{finishedContentNode ? (
					<>
						{canBeRestartet && (
							<Button
								loading={isLoadingRestart}
								iconName="synchronizeArrowClock"
								colorVersion="tertiary"
								onClick={handleRepeatOnClick}
							/>
						)}
						<Button
							disabled={isLoadingRestart}
							label={finishedButtonLabel}
							iconName="arrowRight"
							onClick={handleFinishOnClick}
						/>
					</>
				) : (
					<>
						{!backButtonHidden && (
							<GoToPreviousElementButton
								contentSubmissionFragmentRef={contentSubmission}
							/>
						)}
						<GoToNextElementButton contentSubmissionFragmentRef={contentSubmission} />
					</>
				)}
			</ButtonsWrapper>
			<LicenseModal
				isVisible={modalIsVisible}
				onDismiss={handleModalOnDismiss}
				onBuyClick={hasLicense ? handleUseLicenseOnClick : handleBuyLicenseOnClick}
				variant={hasLicense ? "hasLicense" : "needsLicense"}
			/>
			{rootId && (
				<MultiDialogFlowContainer
					isVisible={isOrderIHKModalVisible}
					onDismiss={handleOderIHKOnDismiss}
				>
					{({ onNext, onCancel, onClose }) => (
						<>
							<CanOrderIHKModal isVisible onAccept={onNext} onDismiss={onCancel} />
							<OrderIhkFlow rootId={rootId} isVisible onDismiss={onClose} />
						</>
					)}
				</MultiDialogFlowContainer>
			)}
		</Wrapper>
	);
};
