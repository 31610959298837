import { TkaP2Span } from "@themes/font-tags";
import { RadioOptionProps } from "./radio-option.interface";
import { Wrapper } from "./radio-option.styles";
import { Skeleton } from "../skeleton";

export const RadioOptionSkeleton = ({ label, tkaColor }: RadioOptionProps) => {
	return (
		<Wrapper>
			<Skeleton width="1.5rem" height="1.5rem" shape="circle" />
			{label && <TkaP2Span tkaColor={tkaColor}>{label}</TkaP2Span>}
		</Wrapper>
	);
};
