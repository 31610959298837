import tw from "twin.macro";
import { TkaH3Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
	pt-24
`;

export const RowWrapper = tw.div`
	flex
	flex-row
	gap-16
	items-center
`;

export const DetailsParticipantsWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	gap-24
	md:gap-32
`;

export const DetailsWrapper = tw.div`
	flex
	flex-col
	flex-1
	gap-12
`;

export const DetailsTitle = tw(TkaH3Span)`
	hidden
	md:inline-block
`;

export const IconContentWrapper = tw.div`
	flex
	flex-row
	items-start
	gap-16
`;

export const ParticipantsWrapper = tw.div`
	flex
	flex-col
	gap-16
	flex-1
	md:flex-none
	min-w-[15rem]
`;

export const ParticipantsWithIconWrapper = tw.div`
	flex
	flex-row
	gap-16
`;

export const ParticipantsIconWrapper = tw.div`
	md:hidden
`;

export const ParticipantsGroupWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const ParticipantsGroupTitleWrapper = tw.div`
	flex
	flex-row
	gap-4
`;

export const ParticipantsListWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	justify-between
	gap-8
`;

export const RightButtonsWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	gap-8
`;

export const HideOnMobile = tw.div`
	hidden
	md:block
`;
