/**
 * @generated SignedSource<<f60711a2568635e3b1d673e553dcbbbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LicenseGroupingKind = "Learnable" | "MicroLearning";
import { FragmentRefs } from "relay-runtime";
export type licensesProductCard_QueryFragment$data = {
  readonly kind: LicenseGroupingKind;
  readonly learnable?: {
    readonly groupCoaching?: {
      readonly description: {
        readonly contractPartner: {
          readonly data: {
            readonly linkToAGB: string | null;
            readonly name: string;
          };
        } | null;
        readonly image: {
          readonly thumbnail: string | null;
        } | null;
        readonly name: string;
      } | null;
      readonly id: string;
    } | null;
    readonly id?: string;
    readonly oneToOneCoaching?: {
      readonly description: {
        readonly contractPartner: {
          readonly data: {
            readonly linkToAGB: string | null;
            readonly name: string;
          };
        } | null;
        readonly image: {
          readonly thumbnail: string | null;
        } | null;
        readonly name: string;
      } | null;
      readonly id: string;
    } | null;
    readonly root?: {
      readonly description: string | null;
      readonly id: string;
      readonly image: {
        readonly thumbnail: string | null;
      } | null;
      readonly structureDefinition: {
        readonly extension?: {
          readonly extension?: {
            readonly contractPartner?: {
              readonly data: {
                readonly linkToAGB: string | null;
                readonly name: string;
              };
            } | null;
          };
        };
        readonly title: string;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "licensesProductCard_QueryFragment";
};
export type licensesProductCard_QueryFragment$key = {
  readonly " $data"?: licensesProductCard_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesProductCard_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractPartner",
  "kind": "LinkedField",
  "name": "contractPartner",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractPartnerData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkToAGB",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachingOfferDescription",
    "kind": "LinkedField",
    "name": "description",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "LearnOpportunityV2",
    "kind": "LinkedField",
    "name": "root",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "structureDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "extension",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "type": "PublishedConstellationRootExtensionExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "PublishedRootExtensionImpl",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LearnOpportunityRootStructureDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licensesProductCard_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "learnable",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CoachingOffer",
                  "kind": "LinkedField",
                  "name": "groupCoaching",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "PublishedGroupCoachingLearnable",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CoachingOffer",
                  "kind": "LinkedField",
                  "name": "oneToOneCoaching",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "PublishedOneToOneCoachingLearnable",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v5/*: any*/),
              "type": "PublishedCourseLearnable",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v5/*: any*/),
              "type": "PublishedMicroLearningLearnable",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LearnableLicenseGrouping",
      "abstractKey": null
    }
  ],
  "type": "LicenseGrouping",
  "abstractKey": "__isLicenseGrouping"
};
})();

(node as any).hash = "b80c486303ef5b52f097b45580ce1380";

export default node;
