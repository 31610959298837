import { ProductExtensionKind } from "@relay/offerCard_ProductFragment.graphql";
import { ResidenceDropdownOptions } from "@screens/account/parts/account-tab/parts/residence-dropdown/residence-dropdown.consts";

export const getGrossPriceByCountryCode = (
	countryCode: ResidenceDropdownOptions,
	product:
		| {
				readonly kind: ProductExtensionKind;
				readonly deGrossPrice?: number;
				readonly atGrossPrice?: number;
				readonly chGrossPrice?: number;
		  }[]
		| undefined
		| null,
): number | undefined => {
	if (!product) {
		return undefined;
	}

	const extension = product.find((e) => e?.kind === "Academies");

	if (!extension) {
		return undefined;
	}

	switch (countryCode) {
		case "DE":
			return extension.deGrossPrice;
		case "AT":
			return extension.atGrossPrice;
		case "CH":
			return extension.chGrossPrice;
		default:
			return extension.deGrossPrice;
	}
};
