import graphql from "babel-plugin-relay/macro";

export const USER_FRAGMENT = graphql`
	fragment userInGroupContextMenu_UserFragment on User {
		id
		name
		email
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment userInGroupContextMenu_QueryFragment on Query
	@refetchable(queryName: "userInGroupContextMenuRefetchQuery") {
		...assignGroupModal_QueryFragment
	}
`;
