import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useCAOptinCookie } from "@hooks/use-ca-optin-cookie";
import { gtmTrackPageView } from "./google-tag-manager";

export const PageViewTracker = () => {
	const location = useLocation();
	const { accepted } = useCAOptinCookie();

	useEffect(() => {
		if (accepted) {
			const trackers = document.createElement("script");

			trackers.src = "/tracker.js";
			trackers.async = true;

			document.body.appendChild(trackers);

			return () => {
				document.body.removeChild(trackers);
			};
		}
	}, [accepted]);

	useEffect(() => {
		try {
			gtmTrackPageView();
		} catch (e) {
			Sentry.captureException(e);
		}
	}, [location, accepted]);

	return <></>;
};
