/**
 * @generated SignedSource<<c34ff79aab2018d6c3ce58dc5f0ee27d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionSuccessModal_ContentSubmissionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GoToNextElementButton_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionSuccessModal_ContentSubmissionFragment";
};
export type ContentSubmissionSuccessModal_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionSuccessModal_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionSuccessModal_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionSuccessModal_ContentSubmissionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GoToNextElementButton_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "8f303280af198240aa9b4861d8bec17d";

export default node;
