/**
 * @generated SignedSource<<7c7701a8b2513ebbc3e07b1bd3902528>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CountryCode = "AT" | "CH" | "DE";
export type SalutationType = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type privateClientForm_BaseDataFragment$data = {
  readonly __typename: string;
  readonly city: string | null;
  readonly countryCode: CountryCode | null;
  readonly firstName?: string | null;
  readonly houseNumber: string | null;
  readonly lastName?: string | null;
  readonly phoneNumber?: string | null;
  readonly postalCode: string | null;
  readonly salutation?: SalutationType | null;
  readonly street: string | null;
  readonly title?: string | null;
  readonly " $fragmentType": "privateClientForm_BaseDataFragment";
};
export type privateClientForm_BaseDataFragment$key = {
  readonly " $data"?: privateClientForm_BaseDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"privateClientForm_BaseDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "privateClientForm_BaseDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salutation",
          "storageKey": null
        }
      ],
      "type": "PrivateBaseData",
      "abstractKey": null
    }
  ],
  "type": "baseData",
  "abstractKey": "__isbaseData"
};

(node as any).hash = "2dd3d65e33dfad38286856dc240b626f";

export default node;
