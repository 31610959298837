/**
 * @generated SignedSource<<30111f33ebd6345e7ae9dcbf1ce1d688>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveUsersFromUserInAccountGroupInput = {
  clientMutationId?: string | null;
  groupId: string;
  userIds: ReadonlyArray<string>;
};
export type removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation$variables = {
  connections: ReadonlyArray<string>;
  input: RemoveUsersFromUserInAccountGroupInput;
};
export type removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation$data = {
  readonly AccountMemberManagement: {
    readonly removeUsersFromUserInAccountGroup: {
      readonly removedIds: ReadonlyArray<string>;
    } | null;
  };
};
export type removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation = {
  response: removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation$data;
  variables: removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "RemoveUsersFromUserInAccountGroupPayload",
            "kind": "LinkedField",
            "name": "removeUsersFromUserInAccountGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMemberManagementMutations",
        "kind": "LinkedField",
        "name": "AccountMemberManagement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "RemoveUsersFromUserInAccountGroupPayload",
            "kind": "LinkedField",
            "name": "removeUsersFromUserInAccountGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "removedIds",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f704d712d601e3438d80c6713f5fff0e",
    "id": null,
    "metadata": {},
    "name": "removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation",
    "operationKind": "mutation",
    "text": "mutation removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation(\n  $input: RemoveUsersFromUserInAccountGroupInput!\n) {\n  AccountMemberManagement {\n    removeUsersFromUserInAccountGroup(input: $input) {\n      removedIds\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5e8e28053c8f344fa6508a7f2c678b0";

export default node;
