import { createContext } from "react";
import { LearnableKind } from "@relay/gridView_Query.graphql";

export type IOffersScreenContext = {
	activeIndex: number;
	filtersAreVisible: boolean;
	searchIsVisibleOnMobile: boolean;
	filters: {
		first?: number;
		last?: number;
		after?: string;
		before?: string;
		tagIds: string[];
		kinds: LearnableKind[];
		text?: string;
	};
	productIds: string[];
	helpers: {
		setText: (text?: string) => void;
		setTagIds: (tagIds: string[]) => void;
		setKinds: (kinds: LearnableKind[]) => void;
		setActiveIndex: (num: number) => void;
		toggleKind: (kind: LearnableKind) => void;
		toggleFiltersAreVisible: () => void;
		toggleSearchIsVisibleOnMobile: () => void;
		setProductIds: (ids: string[]) => void;
	};
};
export const OffersScreenContext = createContext<IOffersScreenContext>({
	activeIndex: 0,
	filtersAreVisible: false,
	searchIsVisibleOnMobile: false,
	filters: {
		tagIds: [],
		kinds: [],
	},
	productIds: [],
	helpers: {
		setText: (_) => {},
		setTagIds: (_) => {},
		setKinds: (_) => {},
		setActiveIndex: () => {},
		toggleKind: () => {},
		toggleFiltersAreVisible: () => {},
		toggleSearchIsVisibleOnMobile: () => {},
		setProductIds: (_) => {},
	},
});
