import graphql from "babel-plugin-relay/macro";

export const USER_IN_ACCOUT_GROUP_FRAGMENT = graphql`
	fragment editGroupModal_UserInAccountGroupFragment on UserInAccountGroup {
		id
		name
	}
`;

export const EDIT_USER_IN_ACCOUNT_GROUP_AND_ADD_OR_INVITE_USER_MUTATION = graphql`
	mutation editGroupModal_EditUserInAccountGroupAndAddOrInviteUsersMutation(
		$input: EditUserInAccountGroupAndAddOrInviteUsersMutationInput!
		$membersConnectionIds: [ID!]!
	) {
		AccountMemberManagement {
			editUserInAccountGroupAndAddOrInviteUsersMutation(input: $input) {
				group {
					...groupCard_UserInAccountGroupWrapperFragment
					...editGroupSuccessModal_UserInAccountGroupWrapperFragment
					newInvitations {
						edges @appendEdge(connections: $membersConnectionIds) {
							node {
								...membersTab_AccountMemberFragment
							}
						}
					}
				}
			}
		}
	}
`;
