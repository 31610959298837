import { graphql } from "babel-plugin-relay/macro";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment inlineAuthGuard_AuthViewerSchemaFragment on AuthViewerSchema {
		...accountSwitchMenu_AuthViewerSchemaFragment
		currentUser {
			accounts {
				id
			}
		}
	}
`;
