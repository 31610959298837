import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { Icon } from "@components/icon";
import { InputText } from "@components/input-text";
import { ParticipantPreview } from "@components/participant-preview";
import { Rating } from "@components/rating";
import { useWindowSize } from "@hooks/use-window-size";
import { appointmentRating_LeaveFeedbackMutation } from "@relay/appointmentRating_LeaveFeedbackMutation.graphql";
import { TkaH3Span, TkaP2Span } from "@themes/font-tags";
import {
	AppointmentStatus,
	formatAppointmentDateTimeRange,
	getAppointmentStatus,
} from "@utils/appointment-utils";
import { AVAILABILITY_FRAGMENT, LEAVE_FEEDBACK_MUTATION } from "./appointment-rating.graphql";
import {
	ButtonsWrapper,
	LeftWrapper,
	RaitingInfoWrapper,
	Wrapper,
	TitleWrapper,
	OverviewWrapper,
	RowWrapper,
} from "./appointment-rating.styles";
import { AppointmentRatingProps } from "./appointment-rating.types";

// TODO: add-translations
export const AppointmentRating = ({
	onClose,
	onAppointmentRated,
	availabilityFragmentRef,
}: AppointmentRatingProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const [leaveFeedback] =
		useMutation<appointmentRating_LeaveFeedbackMutation>(LEAVE_FEEDBACK_MUTATION);

	const { isMediumUp } = useWindowSize();

	const [stars, setStars] = useState(availability.feedback?.data.score ?? 0);
	const [comment, setComment] = useState(availability.feedback?.data.comment ?? "");

	const status = getAppointmentStatus(
		availability.data.startDateTime,
		availability.data.endDateTime,
	);

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			false,
			"long",
		);
	};

	const handleSendRatingOnClick = () => {
		leaveFeedback({
			variables: {
				input: {
					availabilityId: availability.id,
					feedbackData: {
						score: stars,
						comment: comment || undefined,
					},
				},
			},
			onCompleted: onAppointmentRated,
		});
	};

	const handleStarsOnChange = (stars: number) => {
		setStars(stars);
	};

	const handleCommentOnChange = (comment?: string) => {
		setComment(comment ?? "");
	};

	const canRate = status === AppointmentStatus.Ended;

	const alreadyRated = !!availability.feedback?.data.score;

	const ratingSelected = stars >= 1;

	return (
		<Wrapper>
			<LeftWrapper>
				<EmptyPlaceholder
					isVisible={!canRate}
					iconName="star"
					title="Zu früh ..."
					subtitle="Du kannst die Session erst nach dem Termin bewerten und deinem Coach helfen die Session zukünftig zu verbessern."
				>
					<RaitingInfoWrapper>
						<RowWrapper>
							<Icon icon="calendar" sizeInRem={1.5} />
							<TkaP2Span>{renderTime()}</TkaP2Span>
						</RowWrapper>
						<RowWrapper>
							<Icon icon="participants" sizeInRem={1.5} />
							<ParticipantPreview
								subtitle="Coach"
								userFragmentRef={availability.coachProfile?.coach}
							/>
						</RowWrapper>
					</RaitingInfoWrapper>
					<OverviewWrapper>
						<TitleWrapper>
							<TkaH3Span>Wie würdest du die Session bewerten?</TkaH3Span>
							<Rating
								disabled={alreadyRated}
								value={stars}
								onChange={handleStarsOnChange}
							/>
						</TitleWrapper>
						<InputText
							textArea
							status={alreadyRated ? "disabled" : undefined}
							label="Begründung (optional)"
							placeholder="Super Session, weiter so!"
							value={comment}
							onChange={handleCommentOnChange}
						/>
					</OverviewWrapper>
				</EmptyPlaceholder>
			</LeftWrapper>
			<Divider />
			<ButtonsWrapper>
				<Button
					fillParent={!isMediumUp}
					label="Abbrechen"
					onClick={onClose}
					colorVersion="tertiary"
				/>
				<Button
					disabled={!canRate || alreadyRated || !ratingSelected}
					fillParent={!isMediumUp}
					label="Bewertung senden"
					iconName="arrowRight"
					onClick={handleSendRatingOnClick}
				/>
			</ButtonsWrapper>
		</Wrapper>
	);
};
