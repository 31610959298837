import graphql from "babel-plugin-relay/macro";

export const ORDER_FRAGMENT = graphql`
	fragment basketTimeline_OrderFragment on Order {
		id
		cart {
			totals {
				includingAllDiscounts {
					grossPrice
				}
			}
		}
		billingDetails {
			customerType
		}
		selectedPaymentMethod {
			selectedPaymentMethodType
			... on MonthlySelectedPaymentMethod {
				chosenOption {
					rateCount
				}
			}
		}
	}
`;
