import { FC } from "react";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { Wrapper } from "@screens/account/parts/account-tab/parts/delete-account-modals-flow/parts/confirm-delete-account-modal/confirm-delete-account-modal.styles";
import { SuccessDeleteExceptionModalProps } from "@screens/my-availabilities/parts/success-delete-exception-modal/success-delete-exception-modal.types";
import { H1Span, TkaP2Span } from "@themes/font-tags";

export const SuccessDeleteExceptionModal: FC<SuccessDeleteExceptionModalProps> = ({
	isVisible,
	onDismiss,
	calendarWeek,
}) => {
	const handleOnDismiss = () => {
		onDismiss();
	};
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleOnDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				primaryButtonLabel="Okay"
				onPrimaryButtonClick={handleOnDismiss}
				primaryButtonStretch={true}
			>
				<Wrapper>
					<H1Span>KW gelöscht</H1Span>
					<TkaP2Span>
						Die “{calendarWeek}” wurde erfolgreich gelöscht und kann nicht
						wiederhergestellt werden.
					</TkaP2Span>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
