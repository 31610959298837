import styled from "styled-components";
import tw from "twin.macro";
import { Divider } from "@components/divider/divider.component";
import { DividerListProps } from "./divider-list.interface";

export const DividerList = ({ hasDivider, gapRem, children }: DividerListProps) => {
	if (!hasDivider || !Array.isArray(children) || children.length < 2) {
		return <ListWrapper>{children}</ListWrapper>;
	}
	const result: JSX.Element[] = [];

	children.forEach((c, index) => {
		if (!c) return;
		result.push(c);
		result.push(<Divider key={`divider-${index}`} />);
	});
	result.pop();

	return <ListWrapper gapRem={gapRem}>{result}</ListWrapper>;
};

const ListWrapper = styled.div<{ gapRem?: number }>`
	${tw`grid`}
	gap: ${({ gapRem }) => gapRem ?? 0.625}rem;
`;
