import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	md:min-w-[25rem]
	flex
	flex-col
	gap-16
`;

export const InputWrapper = tw.div`
  relative
`;

export const InputIcon = styled.div<{ background?: "error" | "success" }>`
	${tw`
    absolute
    z-10
    top-1/2
    -mt-12
    left-[0.35rem]
    transform
    -translate-y-1/2
    p-[6px]
    rounded-full
  `}
	${({ background }) => (background === "error" ? tw`bg-error-100` : tw`bg-success-100`)}
`;
