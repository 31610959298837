import { useSelector } from "react-redux";
import { TestConfiguration } from "@screens/vocabulary/parts/test-configuration/test-configuration.component";
import { VocabFilterButton } from "@screens/vocabulary/parts/vocab-filter-button/vocab-filter-button.component";
import {
	FilterButtonWrapper,
	Spacing24,
	WordInventoryContainer,
	WordInventoryHeaderWrapper,
} from "@screens/vocabulary/parts/word-inventory-display/word-inventory-display.styles";
import { WordInventoryDisplayProps } from "@screens/vocabulary/parts/word-inventory-display/word-inventory-display.types";
import { selectIsLearnMode } from "@slices/vocabulary.slice";
import { H1Span } from "@themes/font-tags";
import { WordPackageListSkeleton } from "../word-package-list/word-package-list.skeleton";

//TODO: add-translations
export const WordInventoryDisplaySkeleton = ({ selectionModel }: WordInventoryDisplayProps) => {
	const isLearnMode = useSelector(selectIsLearnMode);

	return (
		<WordInventoryContainer>
			<Spacing24>
				<WordInventoryHeaderWrapper>
					<H1Span>Vokabeln</H1Span>
					<FilterButtonWrapper>
						<VocabFilterButton />
					</FilterButtonWrapper>
				</WordInventoryHeaderWrapper>
			</Spacing24>

			{isLearnMode && (
				<Spacing24>
					<TestConfiguration />
				</Spacing24>
			)}
			<WordPackageListSkeleton selectionModel={selectionModel} />
		</WordInventoryContainer>
	);
};
