import { success0, success100 } from "@themes/colors";
import { HeaderWrapper } from "./rating-success-modal.styles";
import { RatingSuccessModalProps } from "./rating-success-modal.types";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";

// TODO: add-translations
export const RatingSuccessModal = ({ isVisible = false, onAccept }: RatingSuccessModalProps) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<HeaderWrapper>
				<IconHeadlineButtonTemplate
					iconName="calendarDisable"
					iconColor={success100}
					iconBackgroundColor={success0}
					title="Danke"
					subtitle="Deine Bewertung hilft uns die Sessions weiter zu verbessern."
					buttonLabel="Okay"
					buttonWidthRem={13}
					onClick={onAccept}
				/>
			</HeaderWrapper>
		</ResponsiveBottomSheetModal>
	);
};
