import { parseDateTime } from "@utils/date-utils";

//TODO add translations
export const formatDateTimeStamp = (date: Date) => {
	const weekDayDate = new Intl.DateTimeFormat("de-DE", {
		weekday: "short",
		year: "2-digit",
		month: "2-digit",
		day: "2-digit",
	}).format(date);
	const time = new Intl.DateTimeFormat("de-DE", {
		hour: "2-digit",
		minute: "2-digit",
	}).format(date);
	return `${weekDayDate} • ${time}`;
};

export const formatDateTimeRange = (from: string, to: string) => {
	const fromFormated = formatDateTimeStamp(parseDateTime(from));
	const toFormated = formatDateTimeStamp(parseDateTime(to));
	return `${fromFormated} bis ${toFormated}`;
};
