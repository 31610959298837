import tw from "twin.macro";

export const TranscriptsWrapper = tw.div`
	flex
	p-8
	bg-shade-100
	items-center
	overflow-y-auto 
	no-scrollbar
	fixed
	bottom-[20%]
	md:bottom-[30%]
	lg:bottom-[15%]
	left-0
	md:left-auto
	text-center
`;
