import tw from "twin.macro";

export const Wrapper = tw.div`
  relative
  grid
  mt-[2rem]
  mb-[2rem]
`;

export const TrendWrapper = tw.div`
  grid
  grid-cols-2
  md:grid-cols-4
  xl:grid-cols-5
  gap-[0.5rem]
`;

export const ButtonContainer = tw.div`
  justify-self-center
  self-center
  after:content-['']
  after:absolute
  after:left-0
  after:right-0
  after:bottom-[2.5rem]
  after:h-[5.5rem]
  after:w-full
  after:bg-gradient-to-t 
  after:from-shade-0 
  after:to-transparent 
  after:to-75%
`;
