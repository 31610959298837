import {
	CreateOrderActions,
	CreateOrderData,
	OnApproveData,
	OnApproveActions,
} from "@paypal/paypal-js";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useFragment } from "react-relay";
import { useToast } from "@hooks/useToast";
import { paypalButton_OrderFragment$key } from "@relay/paypalButton_OrderFragment.graphql";
import { ORDER_FRAGMENT } from "./paypal-button.graphql";
import { GlobalPaypalButtonStyle } from "./paypal-button.styles";
import { PaypalButtonProps } from "./paypal-button.types";

//TODO: add-translations
export const PaypalButton = ({
	disabled,
	onClick,
	onPaymentCompleted,
	orderFragmentRef,
}: PaypalButtonProps) => {
	const order = useFragment<paypalButton_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	const { showError } = useToast();

	const handleOnCreateOrder = (_: CreateOrderData, actions: CreateOrderActions) => {
		const totalValue = order.cart?.totals.includingAllDiscounts.grossPrice;
		if (!totalValue) return Promise.reject("Kein Gesamtpreis gefunden");
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						currency_code: "EUR",
						value: totalValue.toFixed(2),
					},
					invoice_id: order.id!,
					custom_id: order.purchaser?.userId!,
					description: "thekey.academy Bestellung",
				},
			],
			application_context: {
				shipping_preference: "NO_SHIPPING",
			},
		});
	};

	const handleOnApprove = (_: OnApproveData, actions: OnApproveActions) => {
		if (!actions.order) return Promise.reject("Keine Bestellung gefunden");
		return actions.order?.capture().then((details: any) => {
			if (details.error) {
				showError({
					summary: "Zahlung fehlgeschlagen",
					detail: "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode.",
				});
			} else {
				onPaymentCompleted?.();
			}
		});
	};

	const handleOnError = () => {
		showError({
			summary: "Zahlung fehlgeschlagen",
			detail: "Deine Zahlung konnte nicht abgeschlossen werden. Bitte überprüfe die Zahlungsmethode.",
		});
	};

	return (
		<>
			<GlobalPaypalButtonStyle />
			<PayPalButtons
				disabled={disabled}
				onClick={onClick}
				createOrder={handleOnCreateOrder}
				onApprove={handleOnApprove}
				onError={handleOnError}
				style={{ color: "blue", shape: "pill" }}
			/>
		</>
	);
};
