import { graphql } from "babel-plugin-relay/macro";

export const LICENSE_GROUPING_FRAGMENT = graphql`
	fragment licensesGroupCard_LicenseGroupingFragment on LicenseGrouping {
		id
		kind
		totalLicenseAmount
		usedLicenseAmount
		totalLicenseAmountForUser
		usedLicenseAmountForUser
		assignmentRules {
			licenseGroupingId
		}
		... on MicroLearningLicenseGrouping {
			id
		}
		... on LearnableLicenseGrouping {
			learnable {
				kind
				... on PublishedGroupCoachingLearnable {
					groupCoaching {
						id
						description {
							contractPartner {
								data {
									name
									linkToAGB
								}
							}
							name
							image {
								thumbnail
							}
						}
					}
				}
				... on PublishedOneToOneCoachingLearnable {
					oneToOneCoaching {
						id
						description {
							contractPartner {
								data {
									name
									linkToAGB
								}
							}
							name
							image {
								thumbnail
							}
						}
					}
				}
				... on PublishedCourseLearnable {
					root {
						structureDefinition {
							title
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on PublishedRootExtensionImpl {
										extension {
											... on PublishedConstellationRootExtensionExtension {
												isMicroLearning
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
									}
								}
							}
						}
						id
						description
						image {
							thumbnail
						}
					}
				}
				... on PublishedMicroLearningLearnable {
					root {
						structureDefinition {
							title
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on PublishedRootExtensionImpl {
										extension {
											... on PublishedConstellationRootExtensionExtension {
												isMicroLearning
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
									}
								}
							}
						}
						id
						description
						image {
							thumbnail
						}
					}
				}
			}
		}
	}
`;
