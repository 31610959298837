import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment unconfirmedAppointmentCard_AvailabilityFragment on Availability {
		id
		booking {
			... on BookedOneToOne {
				participant {
					user {
						...userAvatarsCollection_UserFragment
					}
				}
				offer {
					description {
						name
					}
				}
			}
			... on BookedForGroup {
				participants {
					user {
						...userAvatarsCollection_UserFragment
					}
				}
				offer {
					description {
						name
					}
				}
			}
		}
		data {
			startDateTime
			endDateTime
		}
		...appointmentModalsFlow_AvailabilityFragment
	}
`;
