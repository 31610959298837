import { Icon } from "@components/icon";
import { TkaH3Span } from "@themes/font-tags";
import { RowWrapper, ShortRowWrapper } from "./profile-menu-row.styled";
import { ProfileMenuRowProps } from "./profile-menu-row.types";

export const ProfileMenuRow = ({ icon, label, color, onClick, isShort }: ProfileMenuRowProps) => {
	const Component = isShort ? ShortRowWrapper : RowWrapper;
	return (
		<Component onClick={onClick}>
			<Icon tkaColor={color} icon={icon} sizeInRem={1.5} />
			<TkaH3Span tkaColor={color}>{label}</TkaH3Span>
		</Component>
	);
};
