import graphql from "babel-plugin-relay/macro";
import { ReactElement, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { BrowserRouter } from "react-router-dom";
import { UserNotifications } from "@components/user-notifications";
import { PermissionBasedNavigation_Query } from "@relay/PermissionBasedNavigation_Query.graphql";
import { CookieBannerScreen } from "@screens/cookie-banner";
import { PlacementContext } from "@screens/placement/placement.context";
import { selectIsLoggedIn, selectLoginData } from "@slices/AuthSlice";
import { CurrentUserData, setCurrentUser } from "@slices/CurrentUserSlice";
import { setActiveFeatureToggles } from "@slices/feature-toggles-slice";
import { BaseScreenSkeleton } from "./base-screen.skeleton";
import { FilteredRoutes } from "./FilteredRoutes";
import { RouteDefinition } from "./RouteDefinition";
import { PageViewTracker } from "../analytics/PageViewTracker";

const PERMISSION_QUERY = graphql`
	query PermissionBasedNavigation_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						id
					}
					permissionsInAccount
				}
				...accountSwitchMenu_AuthViewerSchemaFragment
				...navbar_AuthViewerSchemaFragment
				...profileMenu_AuthViewerSchemaFragment
			}
			...userNotifications_ViewerQueryTypeFragment
		}
		FeatureToggle {
			ActiveFeatureToggleIds
		}
	}
`;

interface OwnProps {
	routes: RouteDefinition[];
	forbiddenRoute?: ReactElement;
}

export interface OwnPropsWithQuery extends OwnProps {
	queryRef: PreloadedQuery<PermissionBasedNavigation_Query>;
}

export function PermissionBasedNavigationWithPreloadedQuery({
	routes,
	forbiddenRoute,
	queryRef,
}: OwnPropsWithQuery) {
	const query = usePreloadedQuery<PermissionBasedNavigation_Query>(PERMISSION_QUERY, queryRef);
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(selectIsLoggedIn);

	const [placementLinkId, setPlacementLinkId] = useState<string>("");

	useEffect(() => {
		if (query.Viewer.Auth.currentUser && isLoggedIn) {
			dispatch(setCurrentUser(query.Viewer.Auth.currentUser as any as CurrentUserData));
		}
	}, [query.Viewer.Auth.currentUser, dispatch, isLoggedIn]);

	useEffect(() => {
		if (query.FeatureToggle.ActiveFeatureToggleIds) {
			dispatch(setActiveFeatureToggles(query.FeatureToggle.ActiveFeatureToggleIds));
		}
	}, [query.FeatureToggle.ActiveFeatureToggleIds, dispatch]);

	const permissions = query.Viewer.Auth.currentUser?.permissionsInAccount ?? [];

	const contextValue = { placementLinkId, setPlacementLinkId };
	return (
		<PlacementContext.Provider value={contextValue}>
			<BrowserRouter>
				<FilteredRoutes
					routes={routes}
					forbiddenRoute={forbiddenRoute}
					permissions={permissions}
				/>
				<CookieBannerScreen />
				<PageViewTracker />
				<UserNotifications viewerQueryTypeFragmentRef={query.Viewer} />
			</BrowserRouter>
		</PlacementContext.Provider>
	);
}

export const PermissionBasedNavigation = (props: OwnProps) => {
	const [queryReference, loadQuery] =
		useQueryLoader<PermissionBasedNavigation_Query>(PERMISSION_QUERY);
	const loginData = useSelector(selectLoginData);

	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery, loginData]);

	return (
		<Suspense fallback={<BaseScreenSkeleton />}>
			{queryReference && (
				<PermissionBasedNavigationWithPreloadedQuery queryRef={queryReference} {...props} />
			)}
		</Suspense>
	);
};
