import styled from "styled-components";
import tw from "twin.macro";
import { TkaH3Span } from "@themes/font-tags";

export const CardWrapper = tw.div`
	flex
	md:w-[22rem]
	max-w-[22rem]
`;

export const Wrapper = tw.div`
	relative
	flex
	flex-col
	gap-16
	w-full
	justify-between
`;

export const TopColGroup = tw.div`
	flex
	flex-col
	items-start
`;

export const ColGroup = tw.div`
	flex
	flex-col
	gap-16
`;

export const TitleUsersWrapper = tw.div`
	flex
	flex-col
`;

export const Title = tw(TkaH3Span)`
	mt-2
`;

export const ParticipantsWrapper = tw.div`
	flex
	flex-row
	gap-8
`;

export const BottomWrapper = tw.div`
	flex
	flex-row
	gap-8
`;

export const IconWrapper = styled.div<{ disabled: boolean }>`
	${tw`
		px-16
		py-8
	`}
	${({ disabled }) => (disabled ? tw`cursor-default` : tw`cursor-pointer`)}
`;

export const Gap16 = tw.div`h-16`;
