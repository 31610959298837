import { FC } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { feedbackQuote_CustomerFeedbackQuery } from "@relay/feedbackQuote_CustomerFeedbackQuery.graphql";
import { feedbackQuote_CustomerFeedbackQueryFragment$key } from "@relay/feedbackQuote_CustomerFeedbackQueryFragment.graphql";
import { amountCustomers } from "@screens/trends/trend-overview-data";
import { brandStrong100 } from "@themes/colors";
import { TkaP1Span } from "@themes/font-tags";
import { formatNumberWithDots } from "@utils/numberFormatter";
import { QUERY, QUERY_FRAGMENT } from "./feedback-quote.graphql";
import {
	AvatarInfoWrapper,
	AvatarWrapper,
	CarouselWrapper,
	FeedbackWrapper,
	HeadlineWrapper,
	IconWrapper,
	HeadlineContentWrapper,
	StyledIcon,
	StyledTkaH1Span,
} from "./feedback-quote.styles";
import { CustomerFeedback, FeedbackQuoteProps } from "./feedback-quote.types";
import { Avatar } from "../avatar";
import { Carousel } from "../carousel";
import { FeedbackCardComponent } from "../feedback-card";
import { Icon } from "../icon";
import { Spacing16 } from "../news-card/news-card.styles";

export const FeedbackQuoteComponent: FC<FeedbackQuoteProps> = ({
	queryFragmentRef,
	quotesAreLeftAligned,
}) => {
	const generalFeedback = useLazyLoadQuery<feedbackQuote_CustomerFeedbackQuery>(QUERY, {
		skip: queryFragmentRef !== undefined,
	});
	const trendFeedbackData = useFragment<feedbackQuote_CustomerFeedbackQueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef ?? null,
	);

	const transformFeedback = (feedback: CustomerFeedback) => ({
		feedback: feedback?.feedback,
		id: feedback?.id,
		name: feedback?.name,
		position: feedback?.position,
	});

	const numberOfCustomers = trendFeedbackData?.customerData?.amountCustomer ?? amountCustomers;
	const feedbacks =
		trendFeedbackData?.customerData?.customerFeedbacks.map((feedback) =>
			transformFeedback(feedback),
		) ??
		generalFeedback?.CustomerJourney?.CustomerFeedback?.edges?.map(
			(feedback) => feedback && transformFeedback(feedback.node),
		) ??
		[];

	const HeadlineContent = (
		<HeadlineContentWrapper>
			<HeadlineWrapper>
				<StyledIcon>
					<Icon icon={"closeQuote"} sizeInRem={3} tkaColor={brandStrong100} />
				</StyledIcon>
				<StyledTkaH1Span>
					Stimmen aus <br />
					der Community
				</StyledTkaH1Span>
			</HeadlineWrapper>
			<Spacing16 />
			<AvatarInfoWrapper>
				<AvatarWrapper>
					{feedbacks.slice(0, 3).map((feedback, index) => (
						<IconWrapper key={index} index={index}>
							<Avatar firstName={feedback?.name} sizeInRem={2.5} />
						</IconWrapper>
					))}
				</AvatarWrapper>
				<TkaP1Span>
					Schließe dich mehr als {formatNumberWithDots(numberOfCustomers)} Lernenden an
				</TkaP1Span>
			</AvatarInfoWrapper>
		</HeadlineContentWrapper>
	);

	const FeedbackContent = (
		<CarouselWrapper>
			<Carousel
				halfWidth
				value={feedbacks}
				itemTemplate={(feedbacks) => <FeedbackCardComponent {...feedbacks} />}
			/>
		</CarouselWrapper>
	);

	return (
		<FeedbackWrapper>
			{quotesAreLeftAligned ? (
				<>
					{FeedbackContent}
					{HeadlineContent}
				</>
			) : (
				<>
					{HeadlineContent}
					{FeedbackContent}
				</>
			)}
		</FeedbackWrapper>
	);
};
