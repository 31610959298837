import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { readInlineData, useFragment, useMutation } from "react-relay";
import { type licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment$key } from "@relay/licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment.graphql";
import { type licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation } from "@relay/licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation.graphql";
import { Spacing24 } from "@screens/profile/profile.styles";
import { success100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import {
	ACCOUNT_MEMBER_INLINE_FRAGMENT,
	CREATE_NEW_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
	QUERY_FRAGMENT,
} from "./licenses-new-single-user-assignment-form.graphql";
import { Wrapper } from "./licenses-new-single-user-assignment-form.styles";
import {
	type SelectedUser,
	type LicensesNewSingleUserAssignmentFormProps,
	type LicensesNewSingleUserAssignmentFormRef,
} from "./licenses-new-single-user-assignment-form.types";
import { LicensesSingleUserAssignmentCard } from "../licenses-single-user-assignment-card";
import { Message } from "../message";
import { MultiSelect } from "../multi-select/multi-select.component";

export const LicensesNewSingleUserAssignmentForm = forwardRef<
	LicensesNewSingleUserAssignmentFormRef,
	LicensesNewSingleUserAssignmentFormProps
>(function LicensesNewSingleUserAssignmentForm(
	{ licenseGroupingId, queryFragmentRef, onSuccess },
	ref,
) {
	const data = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const [createLicenseRule] =
		useMutation<licensesNewSingleUserAssignmentForm_createNewSingleUserAssignmentRuleMutation>(
			CREATE_NEW_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
		);

	const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);

	const handleSubmit = () => {
		selectedUsers.forEach((user) => {
			if (!user.userId) return;
			createLicenseRule({
				variables: {
					input: {
						licenseGroupingId,
						userId: user.userId,
						maxLicenseAmount: user.licenseCount,
					},
				},
				onCompleted: () => {
					onSuccess?.();
				},
			});
		});
	};

	useImperativeHandle(ref, () => ({
		submit: () => {
			handleSubmit();
		},
	}));

	const options = useMemo(() => {
		return (
			data?.AccountMemberManagement.AccountMembers.accountMembers.edges
				?.map((edge) => {
					if (!edge) return null;
					const userRef = edge.node;
					const data =
						readInlineData<licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment$key>(
							ACCOUNT_MEMBER_INLINE_FRAGMENT,
							userRef,
						);

					if (data.kind !== "UserInAccount") return false;
					return {
						id: data.id,
						name: data.name,
						licenseCount: 1,
						userId: data?.user?.user.id,
						userRef,
					};
				})
				.filter(Boolean) ?? []
		);
	}, [data]);

	const handleOnRemoveUser = (id?: string) => {
		if (id) {
			setSelectedUsers((prev) => prev.filter((user) => user.id !== id));
		}
	};

	const handleOnAddUser = (users: SelectedUser[]) => {
		setSelectedUsers(users.map((user) => ({ ...user, licenseCount: 1 })));
	};

	const handleOnLicenseCountChange = (id: string, newLicenseCount: number) => {
		setSelectedUsers((prev) =>
			prev.map((user) =>
				user.id === id ? { ...user, licenseCount: newLicenseCount } : user,
			),
		);
	};

	return (
		<form>
			<Message
				highlighted
				severity="success"
				content={
					<TkaP2Span tkaColor={success100}>
						Einzelzuweisungen gewähren Zugriff auf X Lizenzen in dem Lizenz-Pool. Dieser
						kann nur so lange ausgeschöpft werden, wie sich darin Lizenzen befinden.
					</TkaP2Span>
				}
			/>
			<Spacing24 />
			<MultiSelect
				label="Zugewiesene Nutzer"
				options={options}
				optionLabel="name"
				value={selectedUsers}
				onChange={(e) => {
					handleOnAddUser(e.value);
				}}
				onCloseClick={(id) => {
					handleOnRemoveUser(id);
				}}
			/>
			<Wrapper>
				{selectedUsers.map((user) => {
					return (
						<LicensesSingleUserAssignmentCard
							key={user.id}
							userRef={user.userRef}
							licenseCount={user.licenseCount}
							onDelete={() => {
								handleOnRemoveUser(user.id);
							}}
							onLicenseCountChange={(newLicenseCount) => {
								handleOnLicenseCountChange(user.id, newLicenseCount);
							}}
							showSubmitButton={false}
							showUserIcon={false}
							showModalOnDelete={false}
							showGroups={false}
						/>
					);
				})}
			</Wrapper>
		</form>
	);
});
