/**
 * @generated SignedSource<<d85c463b50a057e5ff67916906d594b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appointmentDetails_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"inputUser_QueryFragment">;
  readonly " $fragmentType": "appointmentDetails_QueryFragment";
};
export type appointmentDetails_QueryFragment$key = {
  readonly " $data"?: appointmentDetails_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentDetails_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByGroupIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "appointmentDetails_QueryFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filterByGroupIds",
          "variableName": "filterByGroupIds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "inputUser_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c7c40e0e4ad28aa1e2744c7dfdd4adfb";

export default node;
