export type SelectionModel<T> = {
	selectedItems: T[];
	onSelectionChange: (selectedItems: T[]) => void;
};

export const indexToAlpha = (index: number) => {
	// ASCII value of first character
	const A = "A".charCodeAt(0);
	const numberToCharacter = (number: number) => {
		return String.fromCharCode(A + number);
	};
	return numberToCharacter(index);
};
