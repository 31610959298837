import { useFragment } from "react-relay";
import { Avatar } from "@components/avatar";
import { GamificationPointsChip } from "@components/gamification-points-chip/gamification-points-chip.component";
import { avatarAndPointsWrapper, pointsWrapper } from "@components/user-avatar/user-avatar.styles";
import { userAvatar_UserFragment$key } from "@relay/userAvatar_UserFragment.graphql";
import { USER_FRAGMENT } from "./user-avatar.graphql";
import { UserAvatarProps } from "./user-avatar.types";

export const UserAvatar = ({
	sizeInRem,
	userFragmentRef,
	showGamificationPoints = false,
	onGrayBackground = false,
}: UserAvatarProps) => {
	const user = useFragment<userAvatar_UserFragment$key>(USER_FRAGMENT, userFragmentRef);

	return (
		<div className={avatarAndPointsWrapper}>
			<Avatar
				sizeInRem={sizeInRem}
				firstName={user.extension?.firstName}
				lastName={user.extension?.lastName}
				imgURL={user.extension?.avatar?.thumbnail}
			/>
			{showGamificationPoints && (
				<div className={pointsWrapper}>
					<GamificationPointsChip
						points={user.extension?.sumGamificationPoints}
						onGrayBackground={onGrayBackground}
					/>
				</div>
			)}
		</div>
	);
};
