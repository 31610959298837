import styled from "styled-components";
import tw from "twin.macro";

export const CardWrapper = styled.div``;

export const ListWrapper = tw.div`
	grid
	gap-8
	cursor-pointer
`;

export const MainWrapper = tw.div`
	flex
	flex-row
	items-center
	gap-8
`;

export const TextWrapper = tw.div`
	flex
	flex-col
	flex-1
`;

export const IconWrapper = tw.div`
	flex
	flex-col
	items-center
	pr-12
`;
