import React from "react";
import { ProfileMenu } from "@components/profile-menu";
import { ScreenWithNavbarSidemenuLayout } from "@layouts/screen-with-navbar-sidemenu-layout";

import { ScreenWithProfileMenuLayoutProps } from "./screen-with-profile-menu-layout .types";

export const ScreenWithProfileMenuLayout = (props: ScreenWithProfileMenuLayoutProps) => {
	return (
		<ScreenWithNavbarSidemenuLayout sideMenu={<ProfileMenu onGrayBackground />} {...props} />
	);
};
