import { graphql } from "babel-plugin-relay/macro";

export const PRODUCT_FRAGMENT = graphql`
	fragment offerCard_ProductFragment on Product {
		isTaxFree
		netPrice
		extensions {
			kind
			... on AcademiesProductExtension {
				deGrossPrice
				atGrossPrice
				chGrossPrice
			}
		}
	}
`;

export const QUERY = graphql`
	query offerCard_Query {
		AccountBaseData {
			AccountBaseData {
				... on PrivateBaseData {
					countryCode
				}
				... on BusinessBaseData {
					countryCode
				}
			}
		}
	}
`;
