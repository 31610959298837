import { Rating as PrRating } from "primereact/rating";
import styled from "styled-components";

export const StyledRating = styled(PrRating)`
	&.p-rating .p-rating-item:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: none;
	}
`;
