/**
 * @generated SignedSource<<f00d3daec573aa18de7cddfd6359a62b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment$data = {
  readonly __typename: "SingleUserAssignmentRule";
  readonly id: string;
  readonly licenseGroupingId: string;
  readonly licensesUsedByUser: number;
  readonly maxLicenseAmount: number;
  readonly userId: string;
  readonly " $fragmentType": "licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment";
};
export type licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment$key = {
  readonly " $data"?: licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "licenseGroupingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "licensesUsedByUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxLicenseAmount",
      "storageKey": null
    }
  ],
  "type": "SingleUserAssignmentRule",
  "abstractKey": null
};

(node as any).hash = "1821a999027753c0087d367b7a95ea14";

export default node;
