import { useFragment } from "react-relay";
import { useMatch } from "react-router-dom";
import { BasketTimeline } from "@components/basket-timeline";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";
import { shoppingCartTemplate_AuthViewerSchemaFragment$key } from "@relay/shoppingCartTemplate_AuthViewerSchemaFragment.graphql";
import { shoppingCartTemplate_OrderFragment$key } from "@relay/shoppingCartTemplate_OrderFragment.graphql";
import { Path } from "@router/paths";
import { TkaH2Span } from "@themes/font-tags";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, ORDER_FRAGMENT } from "./shopping-cart-template.graphql";

import {
	AccountWrapper,
	SideMenuContentWrapper,
	TimelineHeaderWrapper,
} from "./shopping-cart-template.styles";
import { ShoppingCartTemplateProps } from "./shopping-cart-template.types";
import { AvatarWithAccountSwitch } from "../avatar-with-account-switch";
import { BottomNavigationTemplate } from "../bottom-navigation-template";
import { PaypalButton } from "../paypal-button";

//TODO: add-translations
export const ShoppingCartTemplate = ({
	disableNext,
	onNext,
	onPrevious,
	onPaymentCompleted,
	authViewerSchemaFragmentRef,
	orderFragmentRef,
	children,
	showNextButton = true,
}: ShoppingCartTemplateProps) => {
	const auth = useFragment<shoppingCartTemplate_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);
	const order = useFragment<shoppingCartTemplate_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	const { t } = useTkaTranslation("basketScreen");
	const { isXLargeUp } = useWindowSize();

	const currentPath = [
		useMatch(Path.shoppingCart.withIdPlaceholder().products.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().invoice.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().paymentMethod.path),
		useMatch(Path.shoppingCart.withIdPlaceholder().overview.path),
	];

	const currentStepIndex = currentPath.findIndex(Boolean);
	const currentStep = currentStepIndex < 0 ? 1 : currentStepIndex + 1;

	const basketTimeline = (
		<BasketTimeline
			currentStep={currentStep}
			orientation={isXLargeUp ? "vertical" : "horizontal"}
			orderFragmentRef={order}
		/>
	);

	const isPayStep = currentStep === 4;
	const nextButtonText = isPayStep
		? "Kostenpflichtig bestellen"
		: t("basket_screen.nextButtonLabel");

	const isPaypal = order.selectedPaymentMethod?.paymentMethodId === "Paypal";

	const nextButton =
		isPayStep && isPaypal ? (
			<PaypalButton
				disabled={disableNext}
				onClick={onNext}
				onPaymentCompleted={onPaymentCompleted}
				orderFragmentRef={order}
			/>
		) : undefined;

	return (
		<ScreenSidemenuLayout
			headerLeftIconName="close"
			showAvatar={!isXLargeUp}
			authViewerSchemaFragmentRef={auth}
			sideMenu={
				<SideMenuContentWrapper>
					<TkaH2Span>{t("basket_screen.basketTimelineTitle")}</TkaH2Span>
					{basketTimeline}
					<AccountWrapper>
						<AvatarWithAccountSwitch authViewerSchemaFragmentRef={auth} />
					</AccountWrapper>
				</SideMenuContentWrapper>
			}
			headerBottomContent={
				!isXLargeUp && <TimelineHeaderWrapper>{basketTimeline}</TimelineHeaderWrapper>
			}
			bottomContent={
				<BottomNavigationTemplate
					onBackClick={onPrevious}
					canGoBack={currentStep > 1}
					actionButtonDisabled={disableNext}
					actionButtonLabel={showNextButton ? nextButtonText : undefined}
					actionButtonIconName={showNextButton ? "arrowRight" : undefined}
					onActionButtonClick={showNextButton ? onNext : undefined}
					actionButton={showNextButton ? nextButton : undefined}
				/>
			}
		>
			{children}
		</ScreenSidemenuLayout>
	);
};
