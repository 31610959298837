import { SelectItemOptionsType } from "primereact/selectitem";
import { FieldName } from "@screens/profile/parts/personnel-sheet-tab/personnel-sheet-tab.types";

export const placeholder: Record<FieldName, string> = {
	email: "...",
	phoneNumber: "...",
	langugages: "...",
	studies: "...",
	certificateFiles: "...",
	certificateAnswer: "...",
	experienceCoachingHours: "...",
	experienceCoachingFiles: "...",
	experienceBranches: "...",
	experienceBranchesSteps: "...",
	educationFreeText: "...",
	educationMoreThan150h: "...",
	coachingStrongPointsTopics: "...",
	methods: "...",
	digitalAffinity1: "...",
	digitalAffinity2: "...",
	personalPhilosophyCoachingStyle: "...",
	personalPhilosophyVisionMission: "...",
	referencesCoachingExperience: "...",
	referencesPublications: "...",
	dateOfBirth: "...",
	placeOfBirth: "...",
	nationality: "...",
	insuranceNumber: "...",
	workeeNumber: "...",
	addressCountry: "...",
	addressStreet: "...",
	addressHouseNumber: "...",
	addressCity: "...",
	addressPostalCode: "...",
	iban: "...",
};
export const label: Record<FieldName, string> = {
	email: "E-Mail*",
	phoneNumber: "Telefonnummer*",
	langugages: "Sprachen",
	studies:
		"Welche formale Ausbildung (Berufsbildung und/oder (Hochschul-)Studium) bringst du mit?",
	certificateFiles:
		"Welche Coaching-Zertifikate oder -akkreditierungen hast du (z.B. International Coaching Federation ICF)?",
	certificateAnswer: "Wie viele Stunden umfasst deine Ausbildung (erforderlich sind mind. 125h)?",
	experienceCoachingHours:
		"Wie viele Stunden Erfahrung hast du im Coaching (erforderlich sind mindestens 300 h)?",
	experienceCoachingFiles: "Bitte belege diese (Upload)",
	experienceBranches: "In welchen Branchen hast du Führungserfahrung?",
	experienceBranchesSteps: "Stufenerfahrung*",
	educationFreeText: "Welche relevanten Weiterbildungen hast du besucht?",
	educationMoreThan150h: "Umfassen diese mind. 150h (erforderliches Minimum)?",
	coachingStrongPointsTopics: "In welchen Coaching-Themen hast du Expertise?",
	methods: "Welche Coaching-Methoden setzt du ein?",
	digitalAffinity1:
		"Bist du vertraut in der Anwendung von Teams als Kommunikationsinstrument und der Nutzung von digitalen Plattformen?",
	digitalAffinity2: "Hast du Erfahrung im Durchführen von virtuellen Coachings?",
	personalPhilosophyCoachingStyle: "Wie würdest du deinen Coaching-Stil beschreiben?",
	personalPhilosophyVisionMission: "Was ist deine Vision oder Mission als Coach?​",
	referencesCoachingExperience:
		"Bitte belege diese (Upload) Zum Upload hier klicken, oder Daten auf den Bereich schieben",
	referencesPublications: "Hast du Bücher oder Artikel zum Thema Coaching veröffentlicht?",
	dateOfBirth: "Geburtsdatum*",
	placeOfBirth: "Geburtsort*",
	nationality: "Staatsangehörigkeit",
	insuranceNumber: "Versicherungsnummer / Steuernummer",
	iban: "IBAN",
	workeeNumber: "Arbeitnehmernummer",
	addressCountry: "Land",
	addressStreet: "Straße*",
	addressHouseNumber: "Hausnummer*",
	addressCity: "Ort/Stadt",
	addressPostalCode: "PLZ*",
};
export const header: Record<FieldName, string> = {
	email: "...",
	phoneNumber: "...",
	langugages: "Sprachen",
	studies: "Ausbildung/Studium",
	certificateFiles: "Zertifikate & Quallifikationen",
	certificateAnswer: "...",
	experienceCoachingHours: "Coachingpraxis*",
	experienceCoachingFiles: "...",
	experienceBranches: "Branchenerfahung*",
	experienceBranchesSteps: "...",
	educationFreeText: "Weiterbildungen",
	educationMoreThan150h: "...",
	coachingStrongPointsTopics: "Coaching-Schwerpunkte*",
	methods: "Methoden*",
	digitalAffinity1: "Digitale Affinität*",
	digitalAffinity2: "...",
	personalPhilosophyCoachingStyle: "Coaching-Stil*",
	personalPhilosophyVisionMission: "Vision/Mission*",
	referencesCoachingExperience: "Coachingpraxis*",
	referencesPublications: "Publikationen*",
	dateOfBirth: "Steuerdaten",
	placeOfBirth: "...",
	nationality: "...",
	insuranceNumber: "...",
	iban: "...",
	workeeNumber: "...",
	addressCountry: "Wohnort*",
	addressStreet: "...",
	addressHouseNumber: "...",
	addressCity: "...",
	addressPostalCode: "...",
};
export const options: Partial<Record<FieldName, SelectItemOptionsType>> = {
	langugages: [
		{ label: "Deutsch", value: "Deutsch" },
		{ label: "Englisch", value: "Englisch" },
		{ label: "Spanisch", value: "Spanisch" },
		{ label: "Italienisch", value: "Italienisch" },
		{ label: "Französisch", value: "Französisch" },
		{ label: "Portugiesisch", value: "Portugiesisch" },
		{ label: "Arbabisch", value: "Arbabisch" },
		{ label: "Chinesisch", value: "Chinesisch" },
		{ label: "Weitere", value: "Weitere" },
	],
	experienceBranches: [
		{ label: "IT und Softwareentwicklung (1412)", value: "IT und Softwareentwicklung (1412)" },
		{ label: "Analyse und Statistik (3)", value: "Analyse und Statistik (3)" },
		{ label: "Produktmanagement (30)", value: "Produktmanagement (30)" },
		{
			label: "Management und Unternehmensentwicklung (548)",
			value: "Management und Unternehmensentwicklung (548)",
		},
		{ label: "Projektmanagement (660)", value: "Projektmanagement (660)" },
		{
			label: "Administration, Sachbearbeitung und Verwaltung (3014)",
			value: "Administration, Sachbearbeitung und Verwaltung (3014)",
		},
		{ label: "Personalwesen und HR (520)", value: "Personalwesen und HR (520)" },
		{ label: "Marketing und Werbung (480)", value: "Marketing und Werbung (480)" },
		{ label: "Grafik, Design (46)", value: "Grafik, Design (46)" },
		{
			label: "Finanzen, Rechnungswesen und Controlling (1000)",
			value: "Finanzen, Rechnungswesen und Controlling (1000)",
		},
		{
			label: "Gesundheit, Medizin, Soziales (265)",
			value: "Gesundheit, Medizin, Soziales (265)",
		},
		{ label: "Beratung / Consulting (228)", value: "Beratung / Consulting (228)" },
		{
			label: "Prozessplanung und Qualitätssicherung (139)",
			value: "Prozessplanung und Qualitätssicherung (139)",
		},
		{ label: "Recht (60)", value: "Recht (60)" },
		{ label: "Sonstige Tätigkeitsfelder (776)", value: "Sonstige Tätigkeitsfelder (776)" },
	],
	experienceBranchesSteps: [
		{
			label: "Basiskader",
			value: "Basiskader",
		},
		{
			label: "Mittleres Kader",
			value: "Mittleres Kader",
		},
		{
			label: "Top Kader",
			value: "Top Kader",
		},
	],
	coachingStrongPointsTopics: [
		{
			label: "Gesundheitsprävention",
			value: "Gesundheitsprävention",
		},
		{
			label: "Zusammenarbeit",
			value: "Zusammenarbeit",
		},
		{
			label: "Führung",
			value: "Führung",
		},
		{
			label: "Laufbahnberatung",
			value: "Laufbahnberatung",
		},
		{
			label: "Wellbeing, Lifestyle",
			value: "Wellbeing, Lifestyle",
		},
		{
			label: "Weitere",
			value: "Weitere",
		},
	],
	methods: [
		{
			label: "NLP",
			value: "NLP",
		},
		{
			label: "systemisches Coaching",
			value: "systemisches Coaching",
		},
		{
			label: "Kognitive Verhaltenstherapie",
			value: "Kognitive Verhaltenstherapie",
		},
		{
			label: "Weitere",
			value: "Weitere",
		},
	],
};
