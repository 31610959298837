import graphql from "babel-plugin-relay/macro";

export const CREATE_NEW_ACCOUNT_GROUP_ASSIGNMENT_RULE_MUTATION = graphql`
	mutation licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation(
		$input: CreateAccountGroupAssignmentRuleInput!
	) {
		LicenseManagement {
			createAccountGroupAssignmentRule(input: $input) {
				assignmentRule {
					id
					licenseGroupingId
					accountRef
					...licensesAccountGroupAssignmentCard_QueryFragment
				}
			}
		}
	}
`;
