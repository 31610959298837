import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { useCallback, useContext, useMemo, useState } from "react";
import { DynamicSlideMenuNavigateItem } from "@components/dynamic-slide-menu-navigate-item";
import { DynamicSlideMenuParent } from "@components/dynamic-slide-menu-parent";
import { DynamicSlideMenuContext } from "@components/dynamic-slide-menu-provider/dynamic-slide-menu-provider.context";
import { RadioOption } from "@components/radio-option";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade0 } from "@themes/colors";
import {
	HLSLevelAuto,
	VideoMenuRoute,
	VideoSettingsSpeed,
	VideoTranscriptLanguage,
} from "./video-menu.const";
import { VideoMenuProps } from "./video-menu.interface";
import { getInitialTranscriptLanguage, getLabelForTranscriptLanguage } from "./video-menu.utils";

export const VideoMenu = ({
	availableHeights,
	onResolutionChanged,
	onSpeedChanged,
	onTranscriptLanguageChange,
	availableSubtitleLanguages,
}: VideoMenuProps) => {
	const { t } = useTkaTranslation("videoPlayer");
	const { goBack } = useContext(DynamicSlideMenuContext);
	const [resolution, setResolution] = useState(HLSLevelAuto);
	const [speed, setSpeed] = useState(VideoSettingsSpeed.S1_0);
	const [language, setLanguage] = useState(
		getInitialTranscriptLanguage(availableSubtitleLanguages),
	);

	const handleResolutionOnChange = useCallback(
		(event: RadioButtonChangeEvent) => {
			event.checked && setResolution(event.value);
			onResolutionChanged && onResolutionChanged(event.value);
			goBack();
		},
		[goBack, onResolutionChanged],
	);
	const handleSpeedOnChange = useCallback(
		(event: RadioButtonChangeEvent) => {
			event.checked && setSpeed(event.value);
			onSpeedChanged && onSpeedChanged(event.value);
			goBack();
		},
		[goBack, onSpeedChanged],
	);

	const handleLanguageOnChange = useCallback(
		(event: RadioButtonChangeEvent) => {
			if (event.checked) {
				setLanguage(event.value);
				onTranscriptLanguageChange && onTranscriptLanguageChange(event.value);
			}
		},
		[onTranscriptLanguageChange],
	);

	const useLevels = useMemo(() => {
		const slice = (availableHeights || []).slice();
		slice.unshift(HLSLevelAuto);
		return slice;
	}, [availableHeights]);

	const subtitleOptions = useMemo(() => {
		const allOptions = availableSubtitleLanguages
			? [...availableSubtitleLanguages, VideoTranscriptLanguage.Disabled]
			: [VideoTranscriptLanguage.Disabled];
		return allOptions.map((value) => (
			<RadioOption
				key={value}
				label={getLabelForTranscriptLanguage(value as VideoTranscriptLanguage, t)}
				value={value}
				checked={language === value}
				tkaColor={shade0}
				onChange={handleLanguageOnChange}
			/>
		));
	}, [availableSubtitleLanguages, language, t, handleLanguageOnChange]);

	return (
		<>
			<DynamicSlideMenuParent width={20} path={VideoMenuRoute.settings.path}>
				<DynamicSlideMenuNavigateItem
					title={t("video_player.playbackRateTitle")}
					path={VideoMenuRoute.settings.speed.path}
					content={speed}
				>
					<DynamicSlideMenuParent
						width={20}
						path={VideoMenuRoute.settings.speed.path}
						title={t("video_player.playbackRateTitle")}
					>
						{Object.values(VideoSettingsSpeed).map((value) => (
							<RadioOption
								key={value}
								label={value}
								value={value}
								checked={speed === value}
								tkaColor={shade0}
								onChange={handleSpeedOnChange}
							/>
						))}
					</DynamicSlideMenuParent>
				</DynamicSlideMenuNavigateItem>
				<DynamicSlideMenuNavigateItem
					title={t("video_player.resolutionTitle")}
					path={VideoMenuRoute.settings.resolution.path}
					content={resolution === HLSLevelAuto ? "Auto" : `${resolution}p`}
				>
					<DynamicSlideMenuParent
						width={20}
						path={VideoMenuRoute.settings.resolution.path}
						title={t("video_player.resolutionTitle")}
					>
						{useLevels.map((value) => (
							<RadioOption
								key={value}
								label={value === HLSLevelAuto ? "Auto" : `${value}p`}
								value={value}
								checked={resolution === value}
								tkaColor={shade0}
								onChange={handleResolutionOnChange}
							/>
						))}
					</DynamicSlideMenuParent>
				</DynamicSlideMenuNavigateItem>
			</DynamicSlideMenuParent>
			<DynamicSlideMenuParent
				title={t("video_player.transcriptsTitle")}
				path={VideoMenuRoute.transcripts.path}
			>
				{subtitleOptions}
			</DynamicSlideMenuParent>
		</>
	);
};
