import { useFragment } from "react-relay";
import { Header } from "@components/header";
import { SidemenuLayout } from "@layouts/sidemenu-layout";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./screen-sidemenu-layout.graphql";
import { ScreenWrapper, ScrollWrapper } from "./screen-sidemenu-layout.styles";
import { ScreenWithSidemenuLayoutProps } from "./screen-sidemenu-layout.types";

export const ScreenSidemenuLayout = ({
	noPadding = false,
	noContentPadding,
	sideMenu,
	headerBottomContent,
	bottomContent,
	canGoBack,
	headerLeftIconName,
	children,
	showAvatar,
	authViewerSchemaFragmentRef,
}: ScreenWithSidemenuLayoutProps) => {
	const auth = useFragment(AUTH_VIEWER_SCHEMA_FRAGMENT, authViewerSchemaFragmentRef ?? null);
	return (
		<ScreenWrapper>
			<ScrollWrapper>
				<Header
					canGoBack={canGoBack}
					leftIcon={headerLeftIconName}
					showAvatar={!!(showAvatar && auth)}
					authViewerSchemaFragmentRef={auth!}
				/>
				{headerBottomContent}
				<SidemenuLayout
					noContentPadding={noContentPadding}
					noPadding={noPadding}
					sideMenu={sideMenu}
					bottomContent={bottomContent}
				>
					{children}
				</SidemenuLayout>
			</ScrollWrapper>
		</ScreenWrapper>
	);
};
