import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div<{ hideImage?: boolean }>`
	${tw`
		flex
		flex-col
		justify-center
		xxl:justify-start
		xxl:flex-row
		h-screen
	`}
	${({ hideImage }) => hideImage && tw`xxl:flex-col xxl:justify-center`}
`;

export const LeftContent = styled.div<{ hideImage?: boolean }>`
	${tw`
		flex
		flex-col
		flex-1
		xxl:flex-none
		overflow-y-hidden
	`}
	${({ hideImage }) => hideImage && tw`xxl:flex-1`}
`;

export const WebappTeaser = tw.div`
	xxl:flex-grow
	h-screen
	bg-cover
	bg-center
	hidden
	xxl:block
`;

export const HeaderContentWrapper = styled.div<{ hideImage?: boolean }>`
	${tw`
		flex
		flex-col
		flex-1
		md:p-40
		xxl:p-0
		overflow-y-hidden
	`}
	${({ hideImage }) => hideImage && tw`xxl:p-40`}
`;

export const Header = tw.div`
	flex
	justify-center
	items-center
	px-16
	py-24
`;

export const BorderWrapper = styled.div<{ hideImage?: boolean }>`
	${tw`
		flex
		flex-col
		flex-1
		md:flex-none
		xxl:flex-1
		items-center
		rounded-10
		border-shade-10
		border-solid
		md:border
		xxl:border-0
		md:m-auto
		xxl:m-0
		overflow-y-hidden
		max-h-full
	`}
	${({ hideImage }) => hideImage && tw`xxl:flex-none xxl:border xxl:m-auto`}
`;
