import { ReceivablePointsCardProps } from "@components/receivable-points-card/receivable-points-card.types";
import { receivablePointsClass, wrapperClass } from "./receivable-points-card.styles";
import { ReactComponent as KeyMedalPointsIcon } from "../../assets/icons/key-medal-points.svg";

// TODO: add-translations
export const ReceivablePointsCard = ({ receivablePoints }: ReceivablePointsCardProps) => {
	return (
		<div className={wrapperClass}>
			<div className={receivablePointsClass}>+{receivablePoints}</div>
			<KeyMedalPointsIcon />
		</div>
	);
};
