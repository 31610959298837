import tw from "twin.macro";
import { SideMenuContentPadding } from "@containers/side-menu-content-padding";

export const Wrapper = tw(SideMenuContentPadding)`
	min-h-full
	flex
	flex-col
	items-center
	justify-center
`;

export const PlaceholderWrapper = tw.div`
	mt-[-2rem]
`;
