import { FocusContentMenuSkeleton } from "@components/focus-content-menu/focus-content-menu.skeleton";
import { TabWrapper } from "./root-overview-tab.styles";

export const RootOverviewTabSkeleton = () => {
	return (
		<TabWrapper>
			<FocusContentMenuSkeleton />
		</TabWrapper>
	);
};
