import React, { useContext } from "react";
import { InputText } from "@components/input-text";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { OffersScreenContext } from "@screens/offers/offers.context";

export const SearchBarMobile = () => {
	const { t } = useTkaTranslation("offers");
	const placeholder = t("Suchen");
	const {
		filters: { text: value },
		helpers: { setText },
	} = useContext(OffersScreenContext);
	const handleOnChange = (e?: string) => {
		setText(e);
	};

	return (
		<InputText
			icon={"search"}
			placeholder={placeholder + "..."}
			value={value}
			onChange={handleOnChange}
		/>
	);
};
