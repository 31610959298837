import { orderIhkModal_BaseDataFragment$key } from "@relay/orderIhkModal_BaseDataFragment.graphql";

export type OrderIHKModalProps = {
	rootId: string;
	isVisible?: boolean;
	onOrdered?: () => void;
	onDismiss?: () => void;
	baseDataFragmentRef?: orderIhkModal_BaseDataFragment$key | null;
};

export type OrderIHKModalFormState = {
	salutation?: string;
	title?: string;
	firstName: string;
	lastName: string;
	street: string;
	houseNumber: string;
	city: string;
	postCode: string;
	dateOfBirth?: Date;
	country: string;
};

export type OrderIHKModalFormValues = Override<
	OrderIHKModalFormState,
	{
		dateOfBirth: Date;
		salutation: string;
	}
>;

export enum SalutationType {
	Divers = "Divers",
	Mr = "Mr",
	Mrs = "Mrs",
}

export type SalutationTypeOptionsRecord = Record<SalutationType, string>;

export enum TitleType {
	Dr = "Dr",
	Prof = "Prof",
}

export type TitleTypeOptionsRecord = Record<TitleType, string>;
