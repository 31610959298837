import tw from "twin.macro";

export const Wrapper = tw.div`	
	col-span-4
	grid
	grid-cols-4
	gap-x-16
	gap-y-32
`;

export const TaskWrapper = tw.div`
	flex
	flex-col
	gap-8
	col-span-4
	lg:col-span-2
	xl:col-span-4
	xxl:col-span-2
`;

export const AttachmentsWrapper = tw.div`
	col-span-4
	grid
	grid-cols-4
`;

export const ResponsiveAttachmentWrapper = tw.div`
	col-span-4
	lg:col-span-2
`;
