import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { Avatar } from "@components/avatar";
import { Divider } from "@components/divider";
import { DividerList } from "@components/divider-list";
import { Icon } from "@components/icon";
import { useAuthContext } from "@hooks/use-auth-context";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { accountSwitchMenu_AuthViewerSchemaFragment$key } from "@relay/accountSwitchMenu_AuthViewerSchemaFragment.graphql";
import { selectCurrentAccountId } from "@slices/AuthSlice";
import { brandStrong100, error100 } from "@themes/colors";
import { TkaB1Span, H1Span, TkaH2Span } from "@themes/font-tags";

import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./account-switch-menu.graphql";
import {
	AccountRow,
	CurrentUserWrapper,
	FlexGrow,
	MenuWrapper,
	SignOutRow,
} from "./account-switch-menu.styles";
import { AccountSwitchMenuProps } from "./account-switch-menu.types";
import { UserAvatar } from "../user-avatar";

export const AccountSwitchMenu = ({
	authViewerSchemaFragmentRef,
	hideSelectedAccount,
	onAccountSelected,
}: AccountSwitchMenuProps) => {
	const auth = useFragment<accountSwitchMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const { t } = useTkaTranslation("navbar");
	const { logout, switchAccount } = useAuthContext();

	const currentAccountId = useSelector(selectCurrentAccountId);
	const accounts = auth.currentUser?.accounts ?? [];

	const handleAccountOnClick = (id: string) => () => {
		switchAccount(id);
		onAccountSelected?.();
	};

	const handleLogoutOnClick = () => {
		logout();
		window.location.reload();
	};

	const name = auth.currentUser?.user.name;
	const hasMoreThenOneAccount = accounts.length > 1;

	const accountsList = hasMoreThenOneAccount ? (
		<DividerList hasDivider gapRem={1}>
			{[
				<Fragment key="first-divider" />,
				...accounts.map((account) => (
					<AccountRow key={account.id} onClick={handleAccountOnClick(account.id)}>
						<FlexGrow>
							<Avatar name={account.name} />
							<TkaH2Span>{account.name}</TkaH2Span>
						</FlexGrow>
						{!hideSelectedAccount && account.id === currentAccountId && (
							<Icon icon="correct" sizeInRem={1.5} tkaColor={brandStrong100} />
						)}
					</AccountRow>
				)),
				<Fragment key="last-divider" />,
			]}
		</DividerList>
	) : (
		<Divider />
	);

	return (
		<MenuWrapper>
			{name && (
				<CurrentUserWrapper>
					{name && <UserAvatar userFragmentRef={auth.currentUser.user} />}
					<H1Span>{name}</H1Span>
				</CurrentUserWrapper>
			)}
			{accountsList}
			<SignOutRow onClick={handleLogoutOnClick}>
				<Icon tkaColor={error100} icon="logout" sizeInRem={1} />
				<TkaB1Span extraBold tkaColor={error100}>
					{t("navbar.logout")}
				</TkaB1Span>
			</SignOutRow>
		</MenuWrapper>
	);
};
