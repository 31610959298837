import { useContext } from "react";
import { useMutation } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { removeMemberModal_RemoveUsersFromAccountInputMutation } from "@relay/removeMemberModal_RemoveUsersFromAccountInputMutation.graphql";
import { AccountContext } from "@screens/account";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { REMOVE_USERS_FROM_ACCOUNT_MUTATION } from "./remove-member-modal.graphql";
import { Wrapper } from "./remove-member-modal.styles";
import { RemoveMemberModalProps } from "./remove-member-modal.types";

// TODO: add-translations
export const RemoveMemberModal = ({
	isVisible,
	selectedMembers,
	onDismiss,
	onSuccess,
}: RemoveMemberModalProps) => {
	const [removeUsersFromAccount] =
		useMutation<removeMemberModal_RemoveUsersFromAccountInputMutation>(
			REMOVE_USERS_FROM_ACCOUNT_MUTATION,
		);

	const { membersConnectionIds } = useContext(AccountContext);
	const { refetch } = useContext(RefetchContext);

	if (!selectedMembers || selectedMembers.length === 0) return null;

	const handleRemoveUsersOnClick = () => {
		if (selectedMembers.length > 0) {
			removeUsersFromAccount({
				variables: {
					input: {
						userIds: selectedMembers.map((selectedMembers) => selectedMembers.id),
					},
					connections: membersConnectionIds,
				},
				onCompleted: () => {
					refetch();
					onSuccess?.();
				},
			});
		}
	};

	const title =
		selectedMembers.length > 1
			? `${selectedMembers.length} Mitglieder aus dem Konto entfernen?`
			: `Mitglied “${selectedMembers[0].name}” aus dem Konto entfernen?`;
	const subtitle =
		selectedMembers.length > 1
			? `Willst du wirklich ${selectedMembers.length} Mitglieder entfernen? Die Mitglieder können dann nicht mehr auf Lerninhalte und Lizenzen des Kontos zugreifen.`
			: `Willst du “${selectedMembers[0].name}” wirklich entfernen? Das Mitglied kann dann nicht mehr auf Lerninhalte und Lizenzen des Kontos zugreifen.`;

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel={
					selectedMembers.length > 1 ? "Mitglieder entfernen" : "Mitglied entfernen"
				}
				primaryButtonIconName="trash"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={handleRemoveUsersOnClick}
			>
				<Wrapper>
					<H1Span>{title}</H1Span>
					<TkaP2Span>{subtitle}</TkaP2Span>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
