/**
 * @generated SignedSource<<681f6308d41d683a056ed612c872f006>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exceptionModal_FreeCalenderWeeksFragment$data = ReadonlyArray<{
  readonly calendarWeek: number;
  readonly calendarYear: number;
  readonly " $fragmentType": "exceptionModal_FreeCalenderWeeksFragment";
}>;
export type exceptionModal_FreeCalenderWeeksFragment$key = ReadonlyArray<{
  readonly " $data"?: exceptionModal_FreeCalenderWeeksFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"exceptionModal_FreeCalenderWeeksFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "exceptionModal_FreeCalenderWeeksFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarYear",
      "storageKey": null
    }
  ],
  "type": "CalendarWeekAndYear",
  "abstractKey": null
};

(node as any).hash = "2b94898bdd845f5fb1f6e1e692d34a55";

export default node;
