import { graphql } from "babel-plugin-relay/macro";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { MultipleChoiceGapText } from "@components/multiple-choice-gap-text/multiple-choice-gap-text.component";
import { MultipleChoiceGapTextStatus } from "@components/multiple-choice-gap-text/multiple-choice-gap-text.interface";
import { MultipleChoiceElement_ContentSubmissionFragment$key } from "@relay/MultipleChoiceElement_ContentSubmissionFragment.graphql";
import { MultipleChoiceElement_SubmitMultipleChoiceElementMutation } from "@relay/MultipleChoiceElement_SubmitMultipleChoiceElementMutation.graphql";
import { shade100 } from "@themes/colors";
import { H1Span } from "@themes/font-tags";
import { parseHtml } from "@utils/parse-html";
import { stripHtml } from "@utils/strip-html";
import { MultipleChoiceElementProps } from "./multiple-choice-element.interface";
import { Content, InstructionText, Wrapper } from "./multiple-choice-element.styles";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment MultipleChoiceElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					... on InputAndIsCorrectElementState {
						isCorrect
						inputElementState {
							... on MultipleChoiceInputElementState {
								checkedAnswerIndices
							}
						}
					}
					... on MultipleChoiceShowAnswerElementState {
						correctAnswerIndices
						lastCheckedAnswerIndices
					}
					... on MultipleChoiceInputElementState {
						checkedAnswerIndices
					}
					element {
						title
						id
						... on MultipleChoiceLearnElement {
							question
							answeringType
							answeringTypeText
							answerOptions {
								answer
								image {
									url
								}
							}
						}
					}
				}
			}
		}
	}
`;

const SUBMIT_MULTIPLE_CHOICE_ELEMENT_MUTATION = graphql`
	mutation MultipleChoiceElement_SubmitMultipleChoiceElementMutation(
		$input: SubmitMultipleChoiceElementV2Input!
	) {
		LearnV2 {
			submitMultipleChoiceElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								... on InputAndIsCorrectElementState {
									isCorrect
								}
								kind
							}
						}
					}
				}
			}
		}
	}
`;

export const MultipleChoiceElement = ({
	contentSubmissionFragmentRef,
}: MultipleChoiceElementProps) => {
	const contentSubmission = useFragment<MultipleChoiceElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitMultipleChoiceElement] =
		useMutation<MultipleChoiceElement_SubmitMultipleChoiceElementMutation>(
			SUBMIT_MULTIPLE_CHOICE_ELEMENT_MUTATION,
		);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;
	const currentElementStateKind = elementState?.kind || "Untouched";

	const question = element?.question;
	const answers = element?.answerOptions;
	let answerTypeText = element?.answeringTypeText;
	const useRadio = element?.answeringType === "allowOnlyOneAnswer";

	const isShowAnswer = currentElementStateKind === "ShowAnswer";
	const isInputCorrect = currentElementStateKind === "InputAndIsCorrect";
	const isInput = currentElementStateKind === "Input";
	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";

	const lastCheckedIndices =
		elementState?.lastCheckedAnswerIndices ||
		elementState?.inputElementState?.checkedAnswerIndices ||
		elementState?.checkedAnswerIndices ||
		undefined;

	const alphabet = "ABCDEFGHIJKLMNPQRSTUVWXYZ".split("");

	if (!answerTypeText || answerTypeText.length === 0) {
		switch (element?.answeringType) {
			case "allowOnlyOneAnswer": {
				answerTypeText = "Wähle die passende Antwort aus.";
				break;
			}
			case "allowMultipleAnswersAllCorrectMustBeGiven": {
				answerTypeText = "Wähle alle passenden Antworten aus.";
				break;
			}
			default: {
				answerTypeText = "Wähle die passende Antwort aus.";
			}
		}
	}

	const {
		isModalVisible,
		setCanGoNext,
		addGoToNextOnClickListener,
		addTryAgainOnClickListener,
		setFailureModalVisible,
		setSuccessModalVisible,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [checkedIndexes, setCheckedIndexes] = useState<number[]>([]);

	const handleSubmitClick = useCallback(() => {
		setLoading(true);
		submitMultipleChoiceElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
					multipleChoiceElementId: element?.id!,
					checkedAnswerIndices: checkedIndexes,
				},
			},
			onCompleted: (response) => {
				setLoading(false);
				const currentElementState =
					response.LearnV2.submitMultipleChoiceElement?.contentSubmission.definition
						?.currentElementState;
				if (!currentElementState) return;

				if (currentElementState.isCorrect) {
					setSuccessModalVisible(true);
				} else if (
					currentElementState.isCorrect === false ||
					currentElementState.kind === "UntouchedAndPreviouslyIncorrect"
				) {
					setFailureModalVisible(true);
				}
			},
		});
	}, [
		submitMultipleChoiceElement,
		contentSubmission.id,
		element?.id,
		checkedIndexes,
		setSuccessModalVisible,
		setFailureModalVisible,
	]);

	const handleGoToNextClicked = useCallback(() => {
		if (isUntouched || isInput) {
			handleSubmitClick();
			return true;
		}
		return false;
	}, [isUntouched, isInput, handleSubmitClick]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	useEffect(() => {
		setCanGoNext(checkedIndexes.length > 0 || isShowAnswer);
	}, [checkedIndexes, isShowAnswer]);

	const handleTryAgainClicked = useCallback(() => {
		setCheckedIndexes([]);
	}, []);

	useEffect(() => addTryAgainOnClickListener(handleTryAgainClicked), [handleTryAgainClicked]);

	useEffect(() => {
		if (!isUntouched && lastCheckedIndices && lastCheckedIndices.length > 0) {
			setCheckedIndexes([...lastCheckedIndices]);
		}
	}, [lastCheckedIndices, isUntouched]);

	const isCorrect = elementState?.isCorrect;

	const calculateGapTextStatus = (index: number): MultipleChoiceGapTextStatus => {
		const isSelected = checkedIndexes.includes(index);

		if (isInputCorrect) {
			if (isSelected && isCorrect) {
				return MultipleChoiceGapTextStatus.Correct;
			}
			return MultipleChoiceGapTextStatus.Disabled;
		}

		if (isShowAnswer) {
			const isCorrectAnswer = elementState?.correctAnswerIndices?.includes(index);
			if (isCorrectAnswer) {
				if (isSelected) {
					return MultipleChoiceGapTextStatus.Correct;
				} else {
					return MultipleChoiceGapTextStatus.ActuallyCorrect;
				}
			}
			return MultipleChoiceGapTextStatus.Disabled;
		}
		return MultipleChoiceGapTextStatus.Default;
	};

	const inputDisabled = isModalVisible || (!isUntouched && !isInput);

	const handleAnswerOnClick = useCallback(
		(index: number) => {
			if (inputDisabled) return;
			if (useRadio) {
				setCheckedIndexes([index]);
			} else {
				setCheckedIndexes((indexes) => {
					const isSelected = indexes.includes(index);
					if (isSelected) {
						return indexes.filter((i) => i !== index);
					}
					return [...indexes, index];
				});
			}
		},
		[inputDisabled, useRadio],
	);

	const correctList = elementState?.correctAnswerIndices || [];

	const useElemetsIdList = useMemo(() => {
		return isShowAnswer && correctList.length > 0 ? correctList : checkedIndexes;
	}, [isShowAnswer, checkedIndexes, correctList]);

	return (
		<Content>
			<H1Span>{stripHtml(question!)}</H1Span>
			<InstructionText tkaColor={shade100}>{parseHtml(answerTypeText)}</InstructionText>
			<Wrapper>
				{answers?.map((answer, index) => {
					const status = calculateGapTextStatus(index);
					const checked = useElemetsIdList.includes(index);
					return (
						<MultipleChoiceGapText
							key={answer.answer}
							text={stripHtml(answer.answer)}
							isRadio={useRadio}
							checked={checked}
							answerLetter={alphabet[index]}
							status={status}
							onClick={() => handleAnswerOnClick(index)}
						/>
					);
				})}
			</Wrapper>
		</Content>
	);
};
