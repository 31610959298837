import { graphql } from "babel-plugin-relay/macro";

export const CURRENT_USER_FRAGMENT = graphql`
	fragment accountsDropdown_CurrentUserFragment on CurrentUser {
		accounts {
			id
			name
		}
	}
`;
