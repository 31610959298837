/**
 * @generated SignedSource<<19171df41c56d49b89ef9999203c9f65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
export type LicenseGroupingKind = "Learnable" | "MicroLearning";
import { FragmentRefs } from "relay-runtime";
export type licenseDetails_LicenseGroupingFragment$data = {
  readonly LicenseGrouping: {
    readonly LicenseGrouping: {
      readonly assignmentRules: ReadonlyArray<{
        readonly __typename: string;
        readonly " $fragmentSpreads": FragmentRefs<"groupRulesTab_QueryFragment" | "licensesAccountGroupAssignmentCard_QueryFragment" | "licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment" | "userRulesTab_QueryFragment">;
      }>;
      readonly id: string;
      readonly kind: LicenseGroupingKind;
      readonly learnable?: {
        readonly groupCoaching?: {
          readonly description: {
            readonly description: string | null;
            readonly name: string;
          } | null;
          readonly id: string;
        } | null;
        readonly id?: string;
        readonly kind: LearnableKind;
        readonly oneToOneCoaching?: {
          readonly description: {
            readonly description: string | null;
            readonly name: string;
          } | null;
          readonly id: string;
        } | null;
        readonly root?: {
          readonly description: string | null;
          readonly id: string;
          readonly structureDefinition: {
            readonly title: string;
          };
        } | null;
      } | null;
      readonly totalLicenseAmount: number;
      readonly usedLicenseAmount: number;
      readonly " $fragmentSpreads": FragmentRefs<"licensesProductCard_QueryFragment">;
    };
  };
  readonly " $fragmentType": "licenseDetails_LicenseGroupingFragment";
};
export type licenseDetails_LicenseGroupingFragment$key = {
  readonly " $data"?: licenseDetails_LicenseGroupingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseDetails_LicenseGroupingFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachingOfferDescription",
    "kind": "LinkedField",
    "name": "description",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v4 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "LearnOpportunityV2",
    "kind": "LinkedField",
    "name": "root",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "structureDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "licenseGroupingId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./licenseDetails_RefetchQuery.graphql')
    }
  },
  "name": "licenseDetails_LicenseGroupingFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LicenseGroupingQueries",
      "kind": "LinkedField",
      "name": "LicenseGrouping",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "licenseGroupingId"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "LicenseGrouping",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usedLicenseAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalLicenseAmount",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "learnable",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CoachingOffer",
                          "kind": "LinkedField",
                          "name": "groupCoaching",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "PublishedGroupCoachingLearnable",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CoachingOffer",
                          "kind": "LinkedField",
                          "name": "oneToOneCoaching",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "PublishedOneToOneCoachingLearnable",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v4/*: any*/),
                      "type": "PublishedCourseLearnable",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v4/*: any*/),
                      "type": "PublishedMicroLearningLearnable",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnableLicenseGrouping",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "licensesProductCard_QueryFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "assignmentRules",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "licensesAccountGroupAssignmentCard_QueryFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "licensesSingleUserAssignmentCard_SingleUserAssignmentRuleFragment"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "userRulesTab_QueryFragment",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "userId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "licensesUsedByUser",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "maxLicenseAmount",
                          "storageKey": null
                        }
                      ],
                      "type": "SingleUserAssignmentRule",
                      "abstractKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "groupRulesTab_QueryFragment",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "type": "AccountGroupAssignmentRule",
                      "abstractKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "969aefa155af13ee333603a6ff3c9066";

export default node;
