import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	pt-24
	gap-24
`;

export const RowWrapper = tw.div`
	flex
	flex-row
	gap-16
	items-center
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	justify-between
	gap-8
`;

export const PresenceContentWrapper = tw.div`
	flex
	flex-col
	lg:flex-row
	gap-24
	lg:gap-32
	items-start
`;

export const MessageWrapper = tw.div`
	basis-1/3
	shrink
	flex
	flex-col
	gap-24
`;

export const PresenceCheckWrapper = tw.div`
	flex
	flex-col
	flex-1
	gap-12
	self-stretch
	overflow-y-scroll
`;
