import tw from "twin.macro";

export const Wrapper = tw.div`
flex
relative
justify-center
text-center
`;

export const DecorativeBanner = tw.div`
absolute
top-[-1.5rem]
md:top-[-2.5rem]
flex  
items-center
justify-center
text-center
bg-brand-main-100
p-[1.5rem]
rounded-[20px_20px_100px_100px]

`;

export const ContentWrapper = tw.div`
	flex
	flex-col
	min-h-full
	gap-24
	items-center
	justify-center
	max-w-[30rem]
	mt-[6rem]

`;

export const IconWrapper = tw.div`
	relative
	w-[7.5rem]
	h-[7.5rem]
	flex
	justify-center
	items-center
	rounded-full
	bg-shade-5
`;

export const IconBottomNodeWrapper = tw.div`
	absolute
	bottom-0
	translate-y-1/4
`;

export const AbsoluteIconCenter = tw.div`
	absolute
	inset-0
	flex
	justify-center
	items-center
`;
