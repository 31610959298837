import tw from "twin.macro";
import { H1Span } from "@themes/font-tags";

export const Wrapper = tw.div`
flex
flex-col
lg:flex-row
gap-[1rem]
lg:items-center
`;

export const LeftWrapper = tw.div`
flex
flex-col
gap-[1rem]
p-[1.5rem]
lg:w-[40%]
lg:ml-[1.75rem]
lg:ml-[3.5rem]`;

export const StyledTkaH1Span = tw(H1Span)`
text-4xl
`;

export const CardsWrapper = tw.div`
flex
w-full
overflow-x-scroll
lg:grid
lg:grid-cols-2
gap-[0.5rem]
lg:w-[60%]
xl:w-[55%]
no-scrollbar

`;

export const Card = tw.div`
flex
flex-col
min-w-[18rem]
xl:max-h-[14rem]
overflow-y-scroll
rounded-[1rem]
border-[1px]
border-shade-40
p-[1.5rem]
gap-[0.5rem]
md:min-h-[14.5rem]
lg:min-w-0
lg:min-h-[11.5rem]
no-scrollbar
cursor-pointer
`;
