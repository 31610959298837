/**
 * @generated SignedSource<<d6610de37d77e4773f9f85d4c563838e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type blockoutDateModal_BlockoutDateFragment$data = {
  readonly from: string;
  readonly to: string;
  readonly " $fragmentType": "blockoutDateModal_BlockoutDateFragment";
};
export type blockoutDateModal_BlockoutDateFragment$key = {
  readonly " $data"?: blockoutDateModal_BlockoutDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"blockoutDateModal_BlockoutDateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "blockoutDateModal_BlockoutDateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    }
  ],
  "type": "BlockoutDate",
  "abstractKey": null
};

(node as any).hash = "6d96d3aa8fcf0adb1d7539541ded12a5";

export default node;
