import { PathBase, PathWithId } from "@thekeytechnology/epic-ui";

export class RootOverviewPath extends PathBase {
	static readonly pathKey = "overview";
}

export class LessonOverviewPath extends PathBase {
	static readonly pathKey = "lessonOverview";
	static readonly pathName = "lesson-overview";
}

export class BranchNodesPath extends PathWithId<typeof BranchNodesPath> {
	static readonly pathKey = "branchNodes";
	static readonly pathName = "modules";
	static readonly idKey = "branchNodeId";
	static readonly childPaths = [LessonOverviewPath];
}
export class MaterialsPath extends PathWithId<typeof MaterialsPath> {
	static readonly pathKey = "materials";
	static readonly idKey = "materialId";
}

export class RootPath extends PathWithId<typeof RootPath> {
	static readonly pathKey = "root";
	static readonly pathName = "course";
	static readonly idKey = "rootId";
	static readonly childPaths = [RootOverviewPath, BranchNodesPath, MaterialsPath];
}
