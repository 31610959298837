import { DividerList } from "@components/divider-list";

import { Skeleton } from "@components/skeleton";

export const MaterialsTreeSkeleton = () => {
	return (
		<DividerList hasDivider>
			<Skeleton width="100%" height="2.2rem" borderRadius="0.625rem" />
			<Skeleton width="100%" height="2.2rem" borderRadius="0.625rem" />
			<Skeleton width="100%" height="2.2rem" borderRadius="0.625rem" />
			<Skeleton width="100%" height="2.2rem" borderRadius="0.625rem" />
		</DividerList>
	);
};
