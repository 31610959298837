import { brandMain100, brandMain40, brandStrong100, shade20 } from "@themes/colors";
import { SliderStyleProps } from "./slider.types";

export const setBackgroundColor = ({ colorVersion = "brandMain" }: SliderStyleProps) => {
	switch (colorVersion) {
		case "brandMain":
			return brandMain40;
		default:
			return shade20;
	}
};

export const setFillColor = ({ colorVersion = "brandMain" }: SliderStyleProps) => {
	switch (colorVersion) {
		case "brandMain":
			return brandMain100;
		default:
			return brandStrong100;
	}
};
