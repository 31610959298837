/**
 * @generated SignedSource<<dd42abc9aaf3f4bded737a1592a16526>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type profileTab_CoachProfileFragment$data = {
  readonly profileData: {
    readonly avatar: {
      readonly id: string;
      readonly thumbnail: string | null;
      readonly url: string | null;
    } | null;
    readonly basicPersonal: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly phoneNumber: string | null;
      readonly salutation: string | null;
      readonly title: string | null;
    };
    readonly certificateAnswer: number | null;
    readonly certificateFileIds: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly coachingStrongPointsTopics: ReadonlyArray<string>;
    readonly description: string | null;
    readonly digitalAffinity1: boolean | null;
    readonly digitalAffinity2: boolean | null;
    readonly educationFreeText: string | null;
    readonly educationMoreThan150h: boolean | null;
    readonly experience: {
      readonly branches: ReadonlyArray<string>;
      readonly branchesSteps: ReadonlyArray<string>;
      readonly coachingFileIds: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly coachingHours: number | null;
    };
    readonly jobTitles: ReadonlyArray<string> | null;
    readonly languages: ReadonlyArray<string>;
    readonly methods: ReadonlyArray<string>;
    readonly personalPhilosophyCoachingStyle: string | null;
    readonly personalPhilosophyVisionMission: string | null;
    readonly quote: string | null;
    readonly referencesCoachingExperienceIds: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly referencesPublications: string | null;
    readonly socials: {
      readonly facebook: string | null;
      readonly linkedIn: string | null;
      readonly others: ReadonlyArray<string> | null;
      readonly twitter: string | null;
      readonly website: string | null;
    } | null;
    readonly studies: string | null;
  };
  readonly " $fragmentType": "profileTab_CoachProfileFragment";
};
export type profileTab_CoachProfileFragment$key = {
  readonly " $data"?: profileTab_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"profileTab_CoachProfileFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "profileTab_CoachProfileFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileData",
      "kind": "LinkedField",
      "name": "profileData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDataSocials",
          "kind": "LinkedField",
          "name": "socials",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkedIn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "facebook",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitter",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "website",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "others",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quote",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDataBasicPersonal",
          "kind": "LinkedField",
          "name": "basicPersonal",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salutation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "certificateAnswer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "certificateFileIds",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coachingStrongPointsTopics",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "digitalAffinity1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "digitalAffinity2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "educationFreeText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "educationMoreThan150h",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "languages",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "methods",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personalPhilosophyCoachingStyle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personalPhilosophyVisionMission",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referencesPublications",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "referencesCoachingExperienceIds",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileDataExperience",
          "kind": "LinkedField",
          "name": "experience",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branches",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branchesSteps",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "coachingFileIds",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coachingHours",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};
})();

(node as any).hash = "e2db7f11b361b2bdeb2a49540dda5a66";

export default node;
