import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

export const Loader = () => {
	return (
		<div tw="flex grow justify-center items-center">
			<ProgressSpinner style={{ width: 100, height: 100 }} />
		</div>
	);
};
