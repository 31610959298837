import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment trendMedia_TrendMediaFragment on Trend {
		trendData {
			name
			description
			tags {
				id
			}
		}
		trendMedia {
			file {
				url
			}
			kind
		}
	}
`;
