import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import {
	ItemWrapper,
	OptionsWrapper,
	Wrapper,
} from "@screens/account/parts/account-tab/parts/account-context-menu/account-context-menu.styles";
import { AccountContextMenuRef } from "@screens/account/parts/account-tab/parts/account-context-menu/account-context-menu.types";
import { CalendarWeekAndYear } from "@screens/my-availabilities/my-availabilities.types";
import { ExceptionDeleteFlow } from "@screens/my-availabilities/parts/exception-delete-flow/exception-delete-flow.component";
import { error100 } from "@themes/colors";
import { spacing8 } from "@themes/spacing";

export const ExceptionContextMenu = forwardRef<
	AccountContextMenuRef,
	{ calendarWeek: CalendarWeekAndYear; deleteException: () => void; onSubmit: () => void }
>(function ExceptionContextMenu({ calendarWeek, deleteException, onSubmit }, ref) {
	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);
	const [showDeleteFlow, setShowDeleteFlow] = useState(false);

	useImperativeHandle(ref, () => ({
		toggle: (event, target) => {
			overlayRef?.current?.toggle(event, target);
		},
	}));

	const handleOnDeleteClick = () => {
		overlayRef?.current?.hide();
		setShowDeleteFlow(true);
	};

	const formatCalendarWeek = (cw: CalendarWeekAndYear) => `KW ${cw.calendarWeek}`;
	return (
		<>
			<ResponsiveBottomSheetOverlayPanel ref={overlayRef} padding={spacing8}>
				<Wrapper>
					<OptionsWrapper>
						<ItemWrapper onClick={handleOnDeleteClick} dangerous>
							<Icon icon="trash" sizeInRem={1.5} tkaColor={error100} />
							{formatCalendarWeek(calendarWeek)} löschen
						</ItemWrapper>
					</OptionsWrapper>
				</Wrapper>
			</ResponsiveBottomSheetOverlayPanel>
			{showDeleteFlow && (
				<ExceptionDeleteFlow
					isVisible
					onDismiss={() => setShowDeleteFlow(false)}
					calendarWeek={calendarWeek}
					deleteException={deleteException}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
});
