import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { CalendarSkeleton } from "@components/calendar";
import { DropdownCoachSkeleton } from "@components/dropdown-coach";
import { RootCardSkeleton } from "@components/root-card/root-card.skeleton";
import { ScreenSidemenuLayout } from "@layouts/screen-sidemenu-layout";
import { H1Span, TkaH2Span } from "@themes/font-tags";
import {
	BookCoachingPreviewWrapper,
	CalendarTimeSlotWrapper,
	CalendarWrapper,
	CoachWrapper,
	TimeSlotsWrapper,
	TitleContentWrapper,
	Wrapper,
} from "./book-appointment.styles";
import { BookingSuccessModal } from "./parts/booking-success-modal";
import { TimeSlotCardSkeleton } from "./parts/time-slot-card";

// TODO: add-translations
export const BookAppointmentScreenSkeleton = () => {
	return (
		<ScreenSidemenuLayout
			canGoBack
			headerLeftIconName="close"
			sideMenu={<RootCardSkeleton />}
			bottomContent={
				<>
					<BookCoachingPreviewWrapper>{<RootCardSkeleton />}</BookCoachingPreviewWrapper>
					<BottomNavigationTemplateSkeleton />
				</>
			}
		>
			<Wrapper>
				<H1Span>Termin Buchen</H1Span>
				<CoachWrapper>
					<DropdownCoachSkeleton />
				</CoachWrapper>
				<CalendarTimeSlotWrapper>
					<CalendarWrapper>
						<TkaH2Span>Datum wählen</TkaH2Span>
						<CalendarSkeleton inline />
					</CalendarWrapper>
					<TitleContentWrapper>
						<TkaH2Span>Uhrzeit wählen</TkaH2Span>
						<TimeSlotsWrapper>
							<TimeSlotCardSkeleton />
							<TimeSlotCardSkeleton />
							<TimeSlotCardSkeleton />
							<TimeSlotCardSkeleton />
							<TimeSlotCardSkeleton />
							<TimeSlotCardSkeleton />
						</TimeSlotsWrapper>
					</TitleContentWrapper>
				</CalendarTimeSlotWrapper>
				<BookingSuccessModal />
			</Wrapper>
		</ScreenSidemenuLayout>
	);
};
