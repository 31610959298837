import { readInlineData, useFragment } from "react-relay";
import { Label } from "@components/label";
import { LicensesSingleUserAssignmentCard } from "@components/licenses-single-user-assignment-card";
import { withSuspense } from "@components/with-suspense";
import { useToast } from "@hooks/useToast";
import { type userRulesTab_AccountMemberInlineFragment$key } from "@relay/userRulesTab_AccountMemberInlineFragment.graphql";
import {
	ACCOUNT_MEMBER_INLINE_FRAGMENT,
	SINGLE_USER_ASSIGNMENT_RULE_INLINE_FRAGMENT,
	QUERY_FRAGMENT,
} from "./user-rules-tab.graphql";
import { UserRulesTabSkeleton } from "./user-rules-tab.skeleton";
import { AllocationListWrapper, AllocationUserList } from "./user-rules-tab.styles";
import {
	type SingleUserFragmentsWithId,
	type UserRulesTabComponentProps,
} from "./user-rules-tab.types";

export const UserRulesTabComponent = ({
	userFragmentRef,
	items,
	refetch,
}: UserRulesTabComponentProps) => {
	const users = useFragment(QUERY_FRAGMENT, userFragmentRef);
	const { showSuccess } = useToast();

	const groupedItems = items?.reduce<{
		consumed: SingleUserFragmentsWithId[];
		available: SingleUserFragmentsWithId[];
	}>(
		(acc, item) => {
			const itemData = readInlineData(SINGLE_USER_ASSIGNMENT_RULE_INLINE_FRAGMENT, item);
			const licensesConsumed = itemData.licensesUsedByUser >= itemData.maxLicenseAmount;
			const userRef =
				users?.AccountMemberManagement.AccountMembers.accountMembers.edges?.find((edge) => {
					const data = readInlineData<userRulesTab_AccountMemberInlineFragment$key>(
						ACCOUNT_MEMBER_INLINE_FRAGMENT,
						edge?.node!,
					);
					return data?.user?.user?.id === itemData.userId;
				})?.node;

			if (!userRef) return acc;

			if (licensesConsumed) {
				acc.consumed.push({
					id: itemData.id,
					userRef: userRef,
					fragmentRef: item,
				});
			}
			if (!licensesConsumed) {
				acc.available.push({
					id: itemData.id,
					userRef: userRef,
					fragmentRef: item,
				});
			}
			return acc;
		},
		{
			consumed: [],
			available: [],
		},
	);

	const handleDelete = () => {
		showSuccess({
			summary: "Zuweisung gelöscht",
		});

		refetch?.(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	return (
		<AllocationListWrapper>
			{groupedItems?.available && groupedItems?.available.length > 0 && (
				<>
					<Label label="Verfügbar" size="smal" severity="success" />
					<AllocationUserList>
						{groupedItems?.available?.map((item) => (
							<LicensesSingleUserAssignmentCard
								key={item.id}
								userRef={item.userRef}
								queryFragmentRef={item.fragmentRef}
								onDelete={handleDelete}
							/>
						))}
					</AllocationUserList>
				</>
			)}
			{groupedItems?.consumed && groupedItems?.consumed.length > 0 && (
				<>
					<Label label="Verbraucht" size="smal" severity="info" />
					<AllocationUserList>
						{groupedItems.consumed.map((item) => (
							<LicensesSingleUserAssignmentCard
								key={item.id}
								userRef={item.userRef}
								queryFragmentRef={item.fragmentRef}
								onDelete={handleDelete}
							/>
						))}
					</AllocationUserList>
				</>
			)}
		</AllocationListWrapper>
	);
};

export const UserRulesTab = withSuspense(UserRulesTabComponent, UserRulesTabSkeleton);
