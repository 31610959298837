/**
 * @generated SignedSource<<d5bace644108b8cc6f4e91aea3d76af5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PotentialAnalysisGivenAnswerKind = "SelectedWithFreetext" | "Text" | "selected";
export type PotentialAnalysisQuestionDataKind = "MULTIPLE_CHOICE" | "MULTIPLE_CHOICE_FREETEXT" | "SCALE" | "SINGLE_CHOICE" | "SINGLE_CHOICE_FREETEXT" | "TEXT";
import { FragmentRefs } from "relay-runtime";
export type dimensionSection_AVGSPotentialAnalysisDimensionFragment$data = {
  readonly dimensionId: number;
  readonly dimensionText: string;
  readonly questions: ReadonlyArray<{
    readonly answerOptions?: ReadonlyArray<{
      readonly answerOptionId: number;
      readonly isFreeText: boolean;
    }>;
    readonly givenAnswer?: {
      readonly answerOptionId: number;
      readonly freeText?: string | null;
      readonly kind: PotentialAnalysisGivenAnswerKind;
      readonly text?: string;
    } | null;
    readonly givenAnswers?: ReadonlyArray<{
      readonly answerOptionId: number;
      readonly freeText?: string | null;
      readonly kind: PotentialAnalysisGivenAnswerKind;
    }>;
    readonly id: string;
    readonly kind: PotentialAnalysisQuestionDataKind;
    readonly " $fragmentSpreads": FragmentRefs<"questionSection_PotentialAnalysisQuestionFragment">;
  }>;
  readonly " $fragmentType": "dimensionSection_AVGSPotentialAnalysisDimensionFragment";
};
export type dimensionSection_AVGSPotentialAnalysisDimensionFragment$key = {
  readonly " $data"?: dimensionSection_AVGSPotentialAnalysisDimensionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dimensionSection_AVGSPotentialAnalysisDimensionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerOptionId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PotentialAnalysisSelectAnswerOption",
  "kind": "LinkedField",
  "name": "answerOptions",
  "plural": true,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PotentialAnalysisFreetextAnswerOption",
  "kind": "LinkedField",
  "name": "answerOptions",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFreeText",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "freeText",
    "storageKey": null
  }
],
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "PotentialAnalysisSelectedGivenAnswer",
    "kind": "LinkedField",
    "name": "givenAnswer",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "dimensionSection_AVGSPotentialAnalysisDimensionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dimensionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dimensionText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "questions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PotentialAnalysisSelectedGivenAnswer",
              "kind": "LinkedField",
              "name": "givenAnswers",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "type": "PotentialAnalysisMultipleChoiceQuestion",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PotentialAnalysisSelectedWithFreetextGivenAnswer",
              "kind": "LinkedField",
              "name": "givenAnswers",
              "plural": true,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "type": "PotentialAnalysisMultipleChoiceFreetextQuestion",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v6/*: any*/),
          "type": "PotentialAnalysisSingleChoiceQuestion",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PotentialAnalysisSelectedWithFreetextGivenAnswer",
              "kind": "LinkedField",
              "name": "givenAnswer",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "type": "PotentialAnalysisSingleChoiceFreetextQuestion",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v6/*: any*/),
          "type": "PotentialAnalysisScaleQuestion",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PotentialAnalysisTextGivenAnswer",
              "kind": "LinkedField",
              "name": "givenAnswer",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "PotentialAnalysisTextQuestion",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "questionSection_PotentialAnalysisQuestionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AVGSPotentialAnalysis_Dimension",
  "abstractKey": null
};
})();

(node as any).hash = "f246cf1b11b5be5a3cd017c0dfa9c21a";

export default node;
