import { ScrollPanel } from "primereact/scrollpanel";
import styled from "styled-components";
import tw from "twin.macro";
import { TkaP3Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	relative
	w-full	
	h-full
	flex
	flex-col
	overflow-y-hidden
`;

export const ContentWrapper = tw.div`
	flex-1
	flex
	flex-col
	overflow-y-scroll
`;

export const TitleWrapper = tw.div``;

export const Spacing12 = tw.div`h-12`;
export const Spacing40 = tw.div`h-40`;

export const Table = tw.table`
	w-max 
	border-separate 
	border-spacing-0
`;
export const FirstTableHeadTh = styled.th<{ withBorders?: boolean }>`
	${tw`
		sticky 
		left-0 
		top-0 
		z-10 
		bg-shade-0
	`}
	${({ withBorders }) =>
		withBorders &&
		tw`
			border-t 
			border-r 
			border-b 
			border-t-shade-10 
			border-r-shade-10 
			border-b-shade-10
		`}
`;

export const TableHeadTh = styled.th<{ hasBorders: boolean }>`
	${tw`
	sticky 
	top-0 
	bg-shade-0 
	p-12 
	`}
	${(p) =>
		p.hasBorders
			? tw`
			border-b 
			border-t
			border-b-shade-10
			border-t-shade-10
			box-border
	`
			: ""}
`;
export const TableBodyTh = styled.th<{ hasBorders: boolean }>`
	${tw`sticky 
	 left-0 
	 bg-shade-0 
	 p-12
	 `}
	${(p) =>
		p.hasBorders
			? tw`
			border-r
			border-r-shade-10`
			: ""}
`;
export const GapTextWrapperTd = tw.td`
	p-8
`;

export const FixedBottomWrapper = styled.div<{ bottomPx: number }>`
	${tw`
		xl:hidden
		fixed
		w-full
		z-20
	`}
	bottom: ${({ bottomPx }) => bottomPx}px;
`;

export const RelativeBottomWrapper = tw.div`
	invisible
	xl:visible
`;

export const AnswersComponentWrapper = tw.div`
	bg-shade-10 
	p-24
	pb-8
	flex 
	flex-col 
	gap-12
`;

export const StyledScrollPanel = styled(ScrollPanel)`
	&.p-scrollpanel .p-scrollpanel-content {
		${tw`
			no-scrollbar
			flex
			flex-row
			flex-nowrap
			items-start
			gap-8
		`};
	}
`;

export const AnswersTitle = tw(TkaP3Span)``;
