import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const InputGroupWrapper = tw.div`
	grid
	grid-cols-1
	md:grid-cols-2
	gap-24
	pb-24
`;

export const ShortInputsRow = tw.div`
	grid
	grid-cols-6
	gap-16
`;

export const ColSpan2 = tw.div`
	col-span-2
`;
export const ColSpan4 = tw.div`
	col-span-4
`;
