import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment trendOfferSection_TrendOfferFragment on Trend {
		aboutOffersHeadingAndDescription {
			description
			heading
		}
	}
`;
