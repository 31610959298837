import { Icon } from "@components/icon";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade100 } from "@themes/colors";
import { TkaH3Span } from "@themes/font-tags";
import { TimeEstimateProps } from "./time-estimate.interface";
import { Group } from "./time-estimate.styles";

export const TimeEstimate = ({ duration }: TimeEstimateProps) => {
	const { t } = useTkaTranslation("labels");
	return (
		<Group>
			<Icon icon={"timeClockCircle"} tkaColor={shade100} sizeInRem={1} />
			<TkaH3Span>{t("labels.time_estimate", { count: duration })}</TkaH3Span>
		</Group>
	);
};
