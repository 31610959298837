import styled from "styled-components";
import tw from "twin.macro";

export const MainWrapper = styled.div<{
	shouldBeStartedNext?: boolean;
	isSelected?: boolean;
}>`
	${tw`
		grid
		items-center
		gap-8
		p-12
		rounded-10
		shadow-[inset_0_0_0_0.0625rem]
		shadow-shade-20
		cursor-pointer
	`}
	${({ shouldBeStartedNext }) =>
		shouldBeStartedNext && tw`shadow-[inset_0_0_0_0.0625rem] shadow-brand-strong-100`}
	${({ isSelected }) => isSelected && tw`xl:shadow-none`}
`;

export const Group = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

export const Expanded = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 0.5rem;
`;

export const Line = styled.div`
	display: flex;
	justify-content: space-between;
`;
