import { ButtonsWrapper, Wrapper } from "./bottom-navigation-template.styles";
import { ButtonSkeleton } from "../button/button.skeleton";

export const BottomNavigationTemplateSkeleton = ({
	showSecondaryButton,
}: {
	showSecondaryButton?: boolean;
}) => {
	return (
		<Wrapper>
			<ButtonsWrapper>
				{showSecondaryButton && <ButtonSkeleton iconOnly />}
				<ButtonSkeleton />
			</ButtonsWrapper>
		</Wrapper>
	);
};
