import styled from "styled-components";
import tw from "twin.macro";

export const ModalWrapper = tw.div`flex flex-col md:max-w-[85vw]`;

export const SelectionWrapper = tw.div`mt-16 grid gap-24 grid-cols-1 md:grid-cols-2`;

export const OptionWrapper = styled.div`
	${tw`flex flex-col flex-1 self-stretch`};
	max-width: 260px;
`;

export const ModalContentFooter = tw.div`
    flex
    mt-24
    pt-24
    items-center
`;
export const RightButtonWrapper = tw.div`ml-auto`;

export const TimeWrapper = tw.div`flex-1 mt-8 flex items-end`;
