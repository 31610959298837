import tw from "twin.macro";

export const Wrapper = tw.div`
	h-full
	grid
	grid-rows-[1fr_min-content]
	xl:grid-cols-[min-content_1fr]
	bg-shade-5
`;

export const ScreenWrapper = tw.div`
	h-full
	bg-shade-5
	overflow-hidden
`;

export const ScrollWrapper = tw.div`
	overflow-y-auto
	xl:overflow-hidden
	h-full
	flex
	flex-col
`;
