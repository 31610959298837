import graphql from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_QUESTION_FRAGMENT = graphql`
	fragment scaleQuestion_PotentialAnalysisQuestionFragment on PotentialAnalysisQuestion {
		kind
		id
		... on PotentialAnalysisScaleQuestion {
			questionText
			id
			givenAnswer {
				kind
				answerOptionId
			}
			answerOptions {
				answerOptionId
				text
			}
		}
	}
`;

export const SET_ANSWER_IN_SCALE_QUESTION_MUTATION = graphql`
	mutation scaleQuestion_SetAnswerInScaleQuestionMutation(
		$input: SetAnswerInScaleQuestionInput!
	) {
		AvgsPotentialAnalysis {
			setAnswerInScaleQuestion(input: $input) {
				question {
					id
					givenAnswer {
						answerOptionId
					}
				}
			}
		}
	}
`;
