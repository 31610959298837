import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { useWindowSize } from "@hooks/use-window-size";
import { SWIPE_DURATION } from "./carousel.const";
import { CarouselProps } from "./carousel.interface";
import { StyledCarousel } from "./carousel.styles";
import {
	getCarouselOptions,
	getCarouselOptionsHalfWidth,
	getCarouselResponsiveOptions,
} from "./carousel.util";

export function Carousel<Value>({ ...props }: CarouselProps<Value>) {
	const breakpointHelpers = useWindowSize();
	const { isMediumUp, isLargeUp, isXLargeUp, is2XLargeUp } = breakpointHelpers;
	const [page, setPage] = useState(0);

	const defaultOptions = props.halfWidth
		? getCarouselOptionsHalfWidth(breakpointHelpers)
		: getCarouselOptions(breakpointHelpers);
	const responsiveOptions = getCarouselResponsiveOptions(props.halfWidth);

	const [numVisible, setNumVisible] = useState(() => defaultOptions.numVisible);
	const [numScroll, setNumScroll] = useState(() => defaultOptions.numScroll);

	// test and refactor
	const getMaxPage = () => {
		return Math.ceil(props.value.length / numVisible) - 1;
	};
	const slideNext = () => {
		if (page !== getMaxPage()) {
			return setPage((p) => p + 1);
		}
		setPage(0);
	};
	const slidePrev = () => {
		if (page !== 0) {
			return setPage((p) => p - 1);
		}
		setPage(getMaxPage());
	};

	const handlers = useSwipeable({
		onSwipedLeft: slideNext,
		onSwipedRight: slidePrev,
		swipeDuration: SWIPE_DURATION,
		preventScrollOnSwipe: true,
		trackMouse: true,
	});

	useEffect(() => {
		const options = props.halfWidth
			? getCarouselOptionsHalfWidth(breakpointHelpers)
			: getCarouselOptions(breakpointHelpers);
		setNumScroll(options.numScroll);
		setNumVisible(options.numVisible);
	}, [isMediumUp, isLargeUp, isXLargeUp, is2XLargeUp, props, defaultOptions, breakpointHelpers]);

	return (
		<div {...handlers}>
			<StyledCarousel
				{...props}
				numVisible={numVisible}
				numScroll={numScroll}
				page={page}
				responsiveOptions={responsiveOptions}
				onPageChange={(event) => {
					setPage(event.page);
				}}
			/>
		</div>
	);
}
