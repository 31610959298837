import React from "react";
import { Label } from "@components/label";
import { Wrapper } from "@components/socials-button/socials-button.styles";
import { SocialsButtonProps } from "./socials-button.types";

export const SocialsButton = ({ icon, onClick }: SocialsButtonProps) => {
	return (
		<Wrapper>
			<Label size={"big"} iconName={icon} onClick={onClick} severity={"brandMain"} />
		</Wrapper>
	);
};
