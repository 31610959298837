import graphql from "babel-plugin-relay/macro";

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment dropdownCoach_CoachProfileFragment on CoachProfile @inline {
		id
		coach {
			id
			...dropdownUser_UserFragment
		}
	}
`;
