import tw from "twin.macro";

export const InputTextMock = tw.div`
	flex
	gap-4
	items-center
	md:border-shade-40
	md:border
	rounded-4
	h-40
	p-8
	cursor-pointer
`;

export const Wrapper = tw.div`relative`;
export const FilterTextWrapper = tw.div`	
	overflow-hidden 
  text-ellipsis
  whitespace-nowrap
  `;
export const MobileBadgeWrapper = tw.div`absolute top-0 right-0 translate-y-[40%] translate-x-[10%] md:relative md:translate-y-[0] md:translate-x-[0]`;
export const DesktopLabelWrapper = tw.div`shrink-0`;
