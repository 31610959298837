import { TabPanel, TabViewTabChangeEvent } from "primereact/tabview";
import { useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useLocation } from "react-router-dom";
import { Icon } from "@components/icon";
import { TabBar } from "@components/tab-bar";
import { withSuspense } from "@components/with-suspense";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { licenses_Query } from "@relay/licenses_Query.graphql";
import { SearchBar } from "@screens/account/parts/search-bar";
import { SearchBarMobile } from "@screens/account/parts/search-bar/search-bar-mobile";
import {
	Header,
	NoGrowWrapper,
	SearchAndFiltersWrapper,
	SearchBarWrapper,
} from "@screens/offers/offers.styles";

import { Spacing24 } from "@screens/profile/profile.styles";
import { H1Span } from "@themes/font-tags";
import useDebounce from "@utils/useDebounce";
import { LicensesScreenContext } from "./licenses.context";
import { QUERY } from "./licenses.graphql";
import { LicensesScreenSkeleton } from "./licenses.skeleton";
import { Wrapper } from "./licenses.styles";
import { Filters } from "./parts/filter";
import { LicenseSettings } from "./parts/license-settings/license-settings.component";
import { LicensesTab } from "./parts/licenses-tab/licenses-tab.component";
import { ManageLicensesTab } from "./parts/manage-licenses-tab/manage-licenses-tab.component";

//TODO: add-translations
const LicensesScreenComponent = () => {
	const location = useLocation();
	const { state: navigationState } = location;

	const [activeIndex, setActiveIndex] = useState(navigationState?.activeIndex ?? 0);
	const [searchIsVisibleOnMobile, setSearchIsVisibleOnMobile] = useState(false);
	const [settingsBoxIsVisible, setSettingsBoxIsVisible] = useState(
		navigationState?.showSettings ?? false,
	);
	const [searchValue, setSearchValue] = useState<string>("");

	const debouncedSearchValue = useDebounce(searchValue, 500);
	const query = useLazyLoadQuery<licenses_Query>(
		QUERY,
		{ kinds: [] },
		{ fetchPolicy: "store-and-network" },
	);
	const { isMediumUp } = useWindowSize();
	const { isAccountOwner } = usePermissions();

	const [state, setState] = useState<Omit<LicensesScreenContext, "helpers">>({
		filters: {
			kinds: [],
			licensesAvailable: undefined,
			licensesConsumed: undefined,
			text: "",
		},
	});

	useEffect(() => {
		helpers.helpers.setText(debouncedSearchValue);
	}, [debouncedSearchValue]);

	const helpers: Pick<LicensesScreenContext, "helpers"> = {
		helpers: {
			setKinds: (kinds) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, kinds },
				}));
			},
			setText: (text) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, text },
				}));
			},
			toggleLicensesAvailable: () => {
				setState((state) => ({
					...state,
					filters: {
						...state.filters,
						licensesAvailable: !state.filters.licensesAvailable,
						licensesConsumed: false,
					},
				}));
			},
			toggleLicensesConsumed: () => {
				setState((state) => ({
					...state,
					filters: {
						...state.filters,
						licensesConsumed: !state.filters.licensesConsumed,
						licensesAvailable: false,
					},
				}));
			},
			toggleKind: (kind) => {
				const {
					filters: { kinds },
				} = { ...state };
				if (kinds.includes(kind)) {
					const newKinds = [...kinds].filter((k) => k !== kind);
					setState({ ...state, filters: { ...state.filters, kinds: newKinds } });
				} else {
					setState({
						...state,
						filters: { ...state.filters, kinds: [...state.filters.kinds, kind] },
					});
				}
			},
		},
	};

	const contextValue = { ...state, helpers: helpers.helpers };

	const handleTabOnChange = (event: TabViewTabChangeEvent) => {
		setActiveIndex(event.index);
	};

	const handleToggleOnClick = () => {
		setSearchIsVisibleOnMobile(!searchIsVisibleOnMobile);
	};
	const handleOnSettingsClick = () => {
		setSettingsBoxIsVisible(!settingsBoxIsVisible);
	};

	const handleSearchOnChange = (value: string) => {
		setSearchValue(value);
	};

	return (
		<LicensesScreenContext.Provider value={contextValue}>
			<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
				<Wrapper>
					<Header>
						<H1Span>Lizenzen</H1Span>
						<SearchAndFiltersWrapper>
							<SearchBar
								onClick={handleToggleOnClick}
								onChange={handleSearchOnChange}
							/>
							<NoGrowWrapper>
								<Filters />
							</NoGrowWrapper>
							{isAccountOwner && (
								<Icon
									icon={settingsBoxIsVisible ? "close" : "cog"}
									sizeInRem={isMediumUp ? 1.5 : 1}
									onClick={handleOnSettingsClick}
								/>
							)}
						</SearchAndFiltersWrapper>
					</Header>
					<SearchBarWrapper>
						{searchIsVisibleOnMobile && <SearchBarMobile />}
					</SearchBarWrapper>

					{isAccountOwner && settingsBoxIsVisible && (
						<LicenseSettings queryFragmentRef={query} />
					)}
					<TabBar
						disableMaxParentHeight
						activeIndex={activeIndex}
						onTabChange={handleTabOnChange}
					>
						<TabPanel header="Meine Lizenzen">
							<Spacing24 />
							<LicensesTab queryFragmentRef={query} />
						</TabPanel>
						{isAccountOwner && (
							<TabPanel header="Lizenzen verwalten">
								<Spacing24 />
								<ManageLicensesTab queryFragmentRef={query} />
							</TabPanel>
						)}
					</TabBar>
				</Wrapper>
			</ScreenWithProfileMenuLayout>
		</LicensesScreenContext.Provider>
	);
};

export const LicensesScreen = withSuspense(LicensesScreenComponent, LicensesScreenSkeleton);
