import { InputSwitch } from "primereact/inputswitch";
import styled from "styled-components";
import {
	shade20,
	shade5,
	error100,
	error20,
	brandStrong100,
	shade40,
	brandStrong80,
	info100,
	shade0,
} from "@themes/colors";

export const StyledInputSwitch = styled(InputSwitch)`
	/* Base */

	&.p-inputswitch {
		width: 3.125rem;
		min-width: 3.125rem;
		height: 1.625rem;
		min-height: 1.625rem;
	}

	&.p-inputswitch .p-inputswitch-slider:before {
		width: 1.5rem;
		height: 1.5rem;
		margin-top: -0.75rem;
		background: ${shade0};
	}

	/* Not Checked */

	&.p-inputswitch:not(&.p-inputswitch-checked) .p-inputswitch-slider:before {
		margin-left: -0.22rem;
	}

	&.p-inputswitch-slider {
		background: ${shade40};
	}

	/* Checked */

	&.p-inputswitch.p-inputswitch-checked {
		.p-inputswitch-slider {
			background: ${brandStrong100};
		}
	}

	/* Hover */

	&.p-inputswitch {
		&:not(&.p-inputswitch-checked):not(&.p-invalid):hover .p-inputswitch-slider {
			background: ${shade20};
		}

		&.p-inputswitch-checked:not(&.p-invalid):hover .p-inputswitch-slider {
			background: ${brandStrong80};
		}
	}

	/* Disabled */

	&.p-inputswitch.p-disabled .p-inputswitch-slider {
		background: ${shade5};
	}

	/* Focus */

	&.p-inputswitch.p-focus {
		.p-inputswitch-slider {
			box-shadow: none;
			border: 1px solid ${info100};
		}
	}

	/* Invalid */

	&.p-inputswitch.p-invalid .p-inputswitch-slider {
		background: ${error20};
		border: 1px solid ${error100};
	}
	&.p-inputswitch.p-invalid:hover .p-inputswitch-slider {
		background: ${error20};
		border: 1px solid ${error100};
	}
`;
