import { StatisticsBar } from "@components/statistics-bar";
import { StatisticsBarColorVersion } from "@components/statistics-bar/statistics-bar.types";
import { PotentialAnalysisBarProps } from "./potential-analysis-bar.types";

export const PotentialAnalysisBar = ({ step, label, benchmark }: PotentialAnalysisBarProps) => {
	return (
		<StatisticsBar
			step={step}
			maxSteps={6}
			benchmark={benchmark}
			label={label}
			colorVersion={StatisticsBarColorVersion.Green}
		/>
	);
};
