import { graphql } from "babel-plugin-relay/macro";

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment personnelSheetTab_CoachProfileFragment on CoachProfile {
		profileData {
			socials {
				linkedIn
				facebook
				twitter
				website
				others
			}
			description
			jobTitles
			quote
			avatar {
				id
				url
				thumbnail
			}
			basicPersonal {
				lastName
				firstName
				title
				salutation
				email
				phoneNumber
			}
			certificateAnswer
			certificateFileIds {
				id
				name
			}
			coachingStrongPointsTopics
			digitalAffinity1
			digitalAffinity2
			educationFreeText
			educationMoreThan150h
			languages
			methods
			personalPhilosophyCoachingStyle
			personalPhilosophyVisionMission
			referencesPublications
			referencesCoachingExperienceIds {
				id
				name
			}
			studies
			experience {
				branches
				branchesSteps
				coachingFileIds {
					id
					name
				}
				coachingHours
			}
		}
		businessData {
			taxNumber
			insuranceNumber
			iban
			employerNumber
			bic
		}
		baseData {
			nationality
			gender
			birthPlace
			birthDate
			address {
				city
				postalCode
				lineOne
				lineTwo
			}
		}
	}
`;

export const EDIT_MY_PROFILE_DATA_MUTATION = graphql`
	mutation personnelSheetTab_editMyProfileDataMutation($input: EditMyProfileDataInput!) {
		Coaching {
			editMyProfileData(input: $input) {
				coachProfile {
					id
					...personnelSheetTab_CoachProfileFragment
					...profileTab_CoachProfileFragment
				}
			}
		}
	}
`;
export const EDIT_MY_BUSINESS_DATA_MUTATION = graphql`
	mutation personnelSheetTab_editMyBusinessDataMutation($input: EditMyBusinessDataInput!) {
		Coaching {
			editMyBusinessData(input: $input) {
				coachProfile {
					id
					...personnelSheetTab_CoachProfileFragment
					...profileTab_CoachProfileFragment
				}
			}
		}
	}
`;
export const EDIT_MY_BASE_DATA_MUTATION = graphql`
	mutation personnelSheetTab_editMyBaseDataMutation($input: EditMyBaseDataInput!) {
		Coaching {
			editMyBaseData(input: $input) {
				coachProfile {
					id
					...personnelSheetTab_CoachProfileFragment
					...profileTab_CoachProfileFragment
				}
			}
		}
	}
`;
