import { Messages } from "primereact/messages";
import { useEffect, useRef } from "react";
import { StyledMessage } from "./message.styles";
import { MessageProps } from "./message.types";

export const Message = ({ highlighted, severity, summary, detail, content }: MessageProps) => {
	const message = useRef<Messages>(null);
	const prSeverity = severity === "neutral" ? "success" : severity;
	useEffect(() => {
		message.current?.replace({
			sticky: true,
			closable: false,
			severity: prSeverity,
			summary,
			detail,
			content,
		});
	}, [summary, detail, prSeverity]);
	return (
		<StyledMessage ref={message} highlighted={highlighted} neutral={severity === "neutral"} />
	);
};
