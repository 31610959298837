import { MainWrapper } from "./material-cards-collection.styles";
import { MaterialCardSkeleton } from "../material-card/material-card.skeleton";

export const MaterialCardsCollectionSkeleton = ({ rows = 2 }: { rows?: number }) => {
	return (
		<MainWrapper rows={rows}>
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
		</MainWrapper>
	);
};
