/**
 * @generated SignedSource<<7ede8600246510cec26add693bcb06f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type membersTab_UserInAccountGroupQueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"batchEditMembers_QueryFragment" | "inviteMemberModal_QueryFragment" | "membersFilter_QueryFragment" | "userContextMenu_QueryFragment">;
  readonly " $fragmentType": "membersTab_UserInAccountGroupQueryFragment";
};
export type membersTab_UserInAccountGroupQueryFragment$key = {
  readonly " $data"?: membersTab_UserInAccountGroupQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"membersTab_UserInAccountGroupQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./membersTab_UserInAccountGroupRefetchQuery.graphql')
    }
  },
  "name": "membersTab_UserInAccountGroupQueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "membersFilter_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "inviteMemberModal_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "batchEditMembers_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "userContextMenu_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a6ccfd4892edd18fa9a9a0e6c8f563e5";

export default node;
