import styled from "styled-components";
import tw from "twin.macro";
import { TkaH3Span, TkaP3Span } from "@themes/font-tags";

export const CardWrapper = styled.div<{ maxWidth?: number }>`
	${tw`
		min-h-[4rem]
		flex
	`}
	max-width: ${({ maxWidth }) => maxWidth ?? 30}rem;
`;

export const Wrapper = tw.div`
	flex
	flex-row
	gap-12
	w-full
	relative
`;

export const ContentWrapper = tw.div`
	flex
	flex-col
	gap-4
	items-start
`;

export const TitleTaglineWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const RewardImage = tw.img`
	w-[4.376rem]
`;

export const Tagline = tw(TkaP3Span)`
	line-clamp-1
`;

export const Title = tw(TkaH3Span)`
	line-clamp-2
`;
