/**
 * @generated SignedSource<<7f70475485c2df3fe308040cee57e9c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseSettings_QueryFragmentRefetch$variables = {};
export type licenseSettings_QueryFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"licenseSettings_QueryFragment">;
};
export type licenseSettings_QueryFragmentRefetch = {
  response: licenseSettings_QueryFragmentRefetch$data;
  variables: licenseSettings_QueryFragmentRefetch$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "licenseSettings_QueryFragmentRefetch",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "licenseSettings_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "licenseSettings_QueryFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "accountExtensionKind",
                            "value": "Academies"
                          }
                        ],
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "licenseModelKind",
                                "storageKey": null
                              }
                            ],
                            "type": "AcademiesAccountExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(accountExtensionKind:\"Academies\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee095977a0ce40b06cbcdf06c1d0a73c",
    "id": null,
    "metadata": {},
    "name": "licenseSettings_QueryFragmentRefetch",
    "operationKind": "query",
    "text": "query licenseSettings_QueryFragmentRefetch {\n  ...licenseSettings_QueryFragment\n}\n\nfragment licenseSettings_QueryFragment on Query {\n  Viewer {\n    Auth {\n      currentUser {\n        accounts {\n          id\n          extension(accountExtensionKind: Academies) {\n            __typename\n            kind\n            ... on AcademiesAccountExtension {\n              licenseModelKind\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "e7fa6b17ef3d73967dbc6f9d317ff552";

export default node;
