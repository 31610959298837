import tw from "twin.macro";

export const Wrapper = tw.div`
	w-full
	max-w-[57rem]
	mx-auto
	xl:max-w-[75rem]
	p-24
	md:p-40
`;
