import { useFormik } from "formik";
import React, { useId, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { useWindowSize } from "@hooks/use-window-size";
import { type licensesAccountGroupAssignmentCard_deleteGroupAssignmentRuleMutation } from "@relay/licensesAccountGroupAssignmentCard_deleteGroupAssignmentRuleMutation.graphql";
import { type licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation } from "@relay/licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation.graphql";
import { GroupsMultiSelect } from "@screens/account/parts/groups-multi-select";
import { getGroupName } from "@screens/account/parts/utils";
import { shade100, shade20, shade40, shade5, shade60 } from "@themes/colors";
import { TkaH3Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import {
	DELETE_ACCOUNT_GROUP_ASSIGNMENT_MUTATION,
	EDIT_ACCOUNT_GROUP_ASSIGNMENT_MUTATION,
	ACCOUNT_GROUP_ASSIGNMENT_RULE_FRAGMENT,
} from "./licenses-account-group-assignment-card.graphql";
import {
	HeaderWrapper,
	HeaderDataWrapper,
	Wrapper,
	HeaderNameWrapper,
	ExpandButton,
	FormWrapper,
	FormButtonsWrapper,
	ExpandedContentWrapperForm,
	FormButtonsRight,
} from "./licenses-account-group-assignment-card.styles";
import {
	type LicensesAccountGroupAssignmentCardProps,
	type LicensesAccountGroupAssignmentCardFormState,
} from "./licenses-account-group-assignment-card.types";
import { Button } from "../button";
import { Card } from "../card";
import { Icon } from "../icon";
import { InputSwitch } from "../input-switch";
import { LicensesRemoveRuleModal } from "../licenses-remove-rule-modal";
import { ValidatedInputText } from "../validated-input-text";

export const LicensesAccountGroupAssignmentCard = ({
	groupOptions,
	onDelete,
	queryFragmentRef,
}: LicensesAccountGroupAssignmentCardProps) => {
	const data = useFragment(ACCOUNT_GROUP_ASSIGNMENT_RULE_FRAGMENT, queryFragmentRef ?? null);
	const [editRule] =
		useMutation<licensesAccountGroupAssignmentCard_editGroupAssignmentRuleMutation>(
			EDIT_ACCOUNT_GROUP_ASSIGNMENT_MUTATION,
		);
	const [deleteRule] =
		useMutation<licensesAccountGroupAssignmentCard_deleteGroupAssignmentRuleMutation>(
			DELETE_ACCOUNT_GROUP_ASSIGNMENT_MUTATION,
		);

	const accessiblityId = useId();
	const { isMediumUp } = useWindowSize();
	const [active, setActive] = useState(() => data?.active ?? true);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	const form = useFormik<LicensesAccountGroupAssignmentCardFormState>({
		initialValues: {
			name: data?.name ?? "",
			groups:
				data?.userInAccountGroups.map((g) => ({
					id: g.id,
					name: getGroupName(g.id, g.name),
					isBuiltIn: g.isBuiltIn,
				})) ?? [],
		},
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Bitte gib einen Namen ein."),
			groups: Yup.array().min(1, "Bitte wähle mindestens eine Gruppe aus."),
		}),
		onSubmit: (values) => {
			editRule({
				variables: {
					input: {
						assignmentRuleId: data?.id ?? "",
						name: values.name,
						userInAccountGroupIds: values.groups.map((group) => group.id),
						active: active,
					},
				},
				onCompleted: () => {
					setIsExpanded(false);
				},
			});
		},
	});

	const handleActiveChange = (checked: boolean) => {
		editRule({
			variables: {
				input: {
					assignmentRuleId: data?.id ?? "",
					name: data?.name ?? "",
					userInAccountGroupIds: data?.userInAccountGroups.map((g) => g.id) ?? [],
					active: checked,
				},
			},
		});

		setActive(checked);
	};

	const handleDelete = () => {
		deleteRule({
			variables: {
				input: {
					assignmentRuleId: data?.id ?? "",
				},
			},
			onCompleted: () => {
				setShowDeleteModal(false);
				onDelete?.();
			},
		});
	};

	const handleCancel = () => {
		form.resetForm();
		setIsExpanded(false);
	};

	const handleOnRemoveGroup = (id?: string) => {
		const updatedGroups = form?.values?.groups?.filter((group) => group.id !== id);
		form.setFieldValue("groups", updatedGroups);
	};

	const cardBorderColor = isExpanded && active ? shade100 : shade20;
	const cardBackgroundColor = !active ? shade5 : undefined;

	return (
		<>
			{showDeleteModal && (
				<LicensesRemoveRuleModal
					isVisible
					onCancel={() => setShowDeleteModal(false)}
					onSubmit={handleDelete}
					content={<TkaP2Span>Lizenz Zuweisung für die Gruppe</TkaP2Span>}
				/>
			)}
			<Card
				bodyPaddingInRem={1}
				borderColor={cardBorderColor}
				backgroundColor={cardBackgroundColor}
			>
				<Wrapper>
					<HeaderWrapper>
						<HeaderDataWrapper>
							<InputSwitch
								id={`active-${CSS.escape(data?.id ?? "")}`}
								checked={active}
								onChange={handleActiveChange}
							/>
							<HeaderNameWrapper>
								<TkaH3Span tkaColor={!active ? shade60 : undefined}>
									{data?.name}
								</TkaH3Span>
								<TkaP3Span tkaColor={!active ? shade40 : undefined}>
									{active ? "Aktiv" : "Inaktiv"} • {data?.amountEffectedUser ?? 0}{" "}
									Nutzer in der Zuweisungsregel
								</TkaP3Span>
							</HeaderNameWrapper>
						</HeaderDataWrapper>
						<ExpandButton
							type="button"
							id={accessiblityId}
							onClick={() => {
								setIsExpanded((prev) => !prev);
							}}
							aria-controls={accessiblityId}
							aria-expanded={isExpanded ? "true" : "false"}
						>
							<Icon icon={isExpanded ? "arrowUp" : "arrowDown"} sizeInRem={1} />
							<span className="sr-only">
								{isExpanded ? "Zusammenklappen" : "Ausklappen"}
							</span>
						</ExpandButton>
					</HeaderWrapper>

					{isExpanded && (
						<ExpandedContentWrapperForm
							onSubmit={form.handleSubmit}
							role="region"
							aria-labelledby={accessiblityId}
						>
							<FormWrapper background={active ? "grey" : "white"}>
								<ValidatedInputText
									formikConfig={form}
									name="name"
									label="Name der Regel"
									placeholder="..."
									disabled={!active}
								/>
								<GroupsMultiSelect
									formikConfig={form}
									label="Zugewiesene Gruppen"
									options={groupOptions ?? []}
									optionLabel="name"
									name="groups"
									onCloseClick={handleOnRemoveGroup}
									disabled={!active}
								/>
							</FormWrapper>
							<FormButtonsWrapper>
								{isMediumUp && (
									<Button
										type="button"
										label="Abbrechen"
										colorVersion="tertiary"
										minWidthRem={7.5}
										onClick={handleCancel}
									/>
								)}
								<FormButtonsRight fullWidth={!isMediumUp}>
									<Button
										type="button"
										label={isMediumUp ? "Löschen" : undefined}
										iconName="trash"
										colorVersion="error"
										minWidthRem={isMediumUp ? 10 : 3.75}
										onClick={() => {
											setShowDeleteModal(true);
										}}
									/>
									<Button
										type="submit"
										label="Speichern"
										colorVersion="strong"
										minWidthRem={10}
										fillParent={!isMediumUp}
										disabled={!active}
									/>
								</FormButtonsRight>
							</FormButtonsWrapper>
						</ExpandedContentWrapperForm>
					)}
				</Wrapper>
			</Card>
		</>
	);
};
