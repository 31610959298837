/**
 * @generated SignedSource<<6b9be0dae261f8dc1895fedbbc6d5b73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSubmissionStatus = "active" | "failed" | "passed";
import { FragmentRefs } from "relay-runtime";
export type UploadElement_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly element?: {
      readonly file?: {
        readonly id: string;
        readonly name: string;
        readonly url: string | null;
      } | null;
      readonly id?: string;
      readonly taskDescription?: string;
    } | null;
    readonly status?: ContentSubmissionStatus;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly description: string | null;
    readonly indexPath: ReadonlyArray<number>;
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null;
  readonly " $fragmentType": "UploadElement_ContentSubmissionFragment";
};
export type UploadElement_ContentSubmissionFragment$key = {
  readonly " $data"?: UploadElement_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadElement_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UploadAsyncLearnElement",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadElement_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "indexPath",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "element",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "PassedAsyncContentSubmissionDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "element",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "taskDescription",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveAsyncContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "b50f0db00362ab695042116ea0af993c";

export default node;
