import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-relay";
import * as Yup from "yup";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { ValidatedInputChips } from "@components/validated-input-chips";
import { ValidatedInputText } from "@components/validated-input-text";
import { createGroupModal_CreateUserInAccountGroupAndAddOrInviteUsersMutation } from "@relay/createGroupModal_CreateUserInAccountGroupAndAddOrInviteUsersMutation.graphql";
import { AccountContext } from "@screens/account/account.context";
import { H1Span } from "@themes/font-tags";
import { CREATE_USER_IN_ACCOUNT_GROUP_AND_ADD_OR_INVITE_USER_MUTATION } from "./create-group-modal.graphql";
import { TitleWrapper, Wrapper } from "./create-group-modal.styles";
import {
	CreateGroupModalFormState,
	CreateGroupModalProps,
	CreateGroupMutationResponse,
} from "./create-group-modal.types";
import { CreateGroupSuccessModal } from "../create-group-success-modal";

// TODO: add-translations
export const CreateGroupModal = ({ isVisible, onDismiss }: CreateGroupModalProps) => {
	const [
		createUserInAccountGroupAndAddOrInviteUsersMutation,
		isCreatingUserInAccountGroupAndAddOrInviteUsersMutation,
	] = useMutation<createGroupModal_CreateUserInAccountGroupAndAddOrInviteUsersMutation>(
		CREATE_USER_IN_ACCOUNT_GROUP_AND_ADD_OR_INVITE_USER_MUTATION,
	);

	const { membersConnectionIds, groupsConnectionIds } = useContext(AccountContext);

	const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false);
	const [formKey, setFormKey] = useState(0);
	const [createGroupMutationResponse, setCreateGroupMutationResponse] =
		useState<CreateGroupMutationResponse>();

	const handleCreateGroup = (values: CreateGroupModalFormState) => {
		createUserInAccountGroupAndAddOrInviteUsersMutation({
			variables: {
				input: {
					name: values.groupName,
					emails: values.emails,
				},
				groupsConnectionIds,
				membersConnectionIds,
			},
			onCompleted: (response) => {
				setIsVisibleSuccessModal(true);
				setCreateGroupMutationResponse(
					response.AccountMemberManagement
						.createUserInAccountGroupAndAddOrInviteUsersMutation,
				);
			},
		});
	};

	const form = useFormik<CreateGroupModalFormState>({
		initialValues: {
			emails: [],
			groupName: "",
			formKey,
		},
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			emails: Yup.array().of(
				Yup.string()
					.email("Bitte gib eine gültige E-Mail Adresse ein")
					.required("Bitte gib eine E-Mail Adresse ein"),
			),
			groupName: Yup.string().required("Bitte gib einen Gruppennamen ein"),
		}),
		onSubmit: handleCreateGroup,
	});

	const resetForm = () => {
		setFormKey((prev) => prev + 1);
	};
	useEffect(resetForm, [isVisible]);

	const handleOnClose = () => {
		onDismiss?.();
		setIsVisibleSuccessModal(false);
		setCreateGroupMutationResponse(undefined);
	};

	const handleOnCreateAnotherGroup = () => {
		resetForm();
		setIsVisibleSuccessModal(false);
		setCreateGroupMutationResponse(undefined);
	};

	return (
		<>
			{!isVisibleSuccessModal ? (
				<ResponsiveBottomSheetModal
					isVisible={isVisible}
					dismissable={false}
					onDismiss={onDismiss}
				>
					<form onSubmit={form.handleSubmit}>
						<DialogTemplate
							secondaryButtonLabel="Schließen"
							onSecondaryButtonClick={onDismiss}
							primaryButtonLabel="Erstellen"
							primaryButtonIconName="arrowRight"
							primaryButtonLoading={
								isCreatingUserInAccountGroupAndAddOrInviteUsersMutation
							}
						>
							<Wrapper>
								<TitleWrapper>
									<H1Span>Gruppe erstellen</H1Span>
								</TitleWrapper>
								<>
									<ValidatedInputText
										formikConfig={form}
										name="groupName"
										label="Gruppenname*"
										placeholder="z.B. IT, UX, Management,..."
									/>
									<ValidatedInputChips
										addOnBlur
										allowDuplicates={false}
										formikConfig={form}
										name="emails"
										label="Benutzer einladen"
										placeholder="Benutzer E-Mail..."
										icon="user"
									/>
								</>
							</Wrapper>
						</DialogTemplate>
					</form>
				</ResponsiveBottomSheetModal>
			) : (
				<CreateGroupSuccessModal
					isVisible
					onDismiss={handleOnClose}
					onCreateAnotherGroup={handleOnCreateAnotherGroup}
					userInAccountGroupWrapperFragmentRef={
						createGroupMutationResponse?.newGroup.node
					}
				/>
			)}
		</>
	);
};
