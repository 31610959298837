/**
 * @generated SignedSource<<4b231cb28c67536e1a0e7a05c59f511d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type CountryCode = "AT" | "CH" | "DE";
export type EditBusinessBaseDataInput = {
  baseData: BusinessBaseDataInput;
  clientMutationId?: string | null;
};
export type BusinessBaseDataInput = {
  accountData: AccountDataInput;
  billingOffice: string;
  city: string;
  companyLegalForm: CompanyType;
  companyName: string;
  countryCode: CountryCode;
  houseNumber: string;
  invoiceEmail: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  taxData: TaxDataInput;
};
export type TaxDataInput = {
  taxIdentificationNumber: string;
  taxNumber: string;
};
export type AccountDataInput = {
  bic?: string | null;
  iban: string;
};
export type accountTab_EditBusinessBaseDataMutation$variables = {
  input: EditBusinessBaseDataInput;
};
export type accountTab_EditBusinessBaseDataMutation$data = {
  readonly AccountBaseData: {
    readonly editBusinessBaseData: {
      readonly baseData: {
        readonly __typename: "BusinessBaseData";
        readonly " $fragmentSpreads": FragmentRefs<"businessAccountForm_BaseDataFragment" | "privateAccountForm_BaseDataFragment">;
      };
    } | null;
  };
};
export type accountTab_EditBusinessBaseDataMutation = {
  response: accountTab_EditBusinessBaseDataMutation$data;
  variables: accountTab_EditBusinessBaseDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountTab_EditBusinessBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataMutations",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditBusinessBaseDataPayload",
            "kind": "LinkedField",
            "name": "editBusinessBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessBaseData",
                "kind": "LinkedField",
                "name": "baseData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "businessAccountForm_BaseDataFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "privateAccountForm_BaseDataFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountTab_EditBusinessBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataMutations",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditBusinessBaseDataPayload",
            "kind": "LinkedField",
            "name": "editBusinessBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessBaseData",
                "kind": "LinkedField",
                "name": "baseData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "street",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "houseNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countryCode",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingOffice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyLegalForm",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "invoiceEmail",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountData",
                            "kind": "LinkedField",
                            "name": "accountData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bic",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "iban",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaxData",
                            "kind": "LinkedField",
                            "name": "taxData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxIdentificationNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxNumber",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "BusinessBaseData",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "salutation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "type": "PrivateBaseData",
                        "abstractKey": null
                      }
                    ],
                    "type": "baseData",
                    "abstractKey": "__isbaseData"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c8745ec0eaa423578fa3af5a1f99b9b",
    "id": null,
    "metadata": {},
    "name": "accountTab_EditBusinessBaseDataMutation",
    "operationKind": "mutation",
    "text": "mutation accountTab_EditBusinessBaseDataMutation(\n  $input: EditBusinessBaseDataInput!\n) {\n  AccountBaseData {\n    editBusinessBaseData(input: $input) {\n      baseData {\n        __typename\n        ...businessAccountForm_BaseDataFragment\n        ...privateAccountForm_BaseDataFragment\n      }\n    }\n  }\n}\n\nfragment businessAccountForm_BaseDataFragment on baseData {\n  __isbaseData: __typename\n  street\n  houseNumber\n  postalCode\n  city\n  countryCode\n  __typename\n  ... on BusinessBaseData {\n    companyName\n    billingOffice\n    companyLegalForm\n    invoiceEmail\n    phoneNumber\n    accountData {\n      bic\n      iban\n    }\n    taxData {\n      taxIdentificationNumber\n      taxNumber\n    }\n  }\n}\n\nfragment privateAccountForm_BaseDataFragment on baseData {\n  __isbaseData: __typename\n  street\n  houseNumber\n  postalCode\n  city\n  countryCode\n  __typename\n  ... on PrivateBaseData {\n    phoneNumber\n    firstName\n    lastName\n    salutation\n    title\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca1f9b5834a7992bff86156329acedbd";

export default node;
