import tw from "twin.macro";

export const Wrapper = tw.div`
flex
flex-col
md:flex-row
gap-16
mb-[1.5rem]`;

export const LabelWrapper = tw.div`
w-auto 
inline-block
`;

export const TextWrapper = tw.div`
flex
flex-col
max-w-[25rem]
gap-16`;

export const InfoBox = tw.div`
flex
flex-col
h-fit
md:max-w-[16rem]
bg-brand-strong-20
gap-8
p-[0.625rem]
rounded-[0.5rem]`;

export const ItemWrapper = tw.div`
flex
flex-row
gap-8
items-center
`;

export const ButtonWrapper = tw.div`
	flex
	justify-end
`;
