import { useState } from "react";
import PotentialAnalysisImage from "@assets/potential-analysis-modal.svg";
import { Button } from "@components/button";
import { Icon } from "@components/icon";
import { Label } from "@components/label";
import { Spacing4 } from "@components/news-card/news-card.styles";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { useStartPotentialAnalysis } from "@screens/orientation/parts/use-start-potential-analysis/use-start-potential-analysis.hook";
import { brandStrong120 } from "@themes/colors";
import { H1Span, TkaH2Span, TkaP2Span } from "@themes/font-tags";
import { Spacing8 } from "@utils/parse-html/parse-html.styles";
import {
	ButtonWrapper,
	InfoBox,
	ItemWrapper,
	LabelWrapper,
	TextWrapper,
	Wrapper,
} from "./info-modal.styles";

export const InfoModal = () => {
	const [isVisible, setVisible] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const { goToAnalysis } = useStartPotentialAnalysis();

	const handleGoToPotentialAnalysisMAX = () => {
		setIsLoading(true);
		goToAnalysis(false, () => {
			setVisible(false);
			setIsLoading(false);
		});
	};

	const handleOnDismiss = () => {
		setVisible(false);
	};

	return (
		<>
			<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleOnDismiss}>
				<img src={PotentialAnalysisImage} alt={"potential-analysis-image"} />
				<Spacing4 />
				<LabelWrapper>
					<Label severity="brandStrong" label="Kostenfrei" size="smal" />
				</LabelWrapper>
				<Spacing8 />
				<Wrapper>
					<TextWrapper>
						<H1Span>Finde deinen Wunschjob</H1Span>
						<TkaP2Span>
							Mach den ersten Schritt in Richtung deines Wunschjobs: Fülle die
							Potenzialanalyse MAX aus und entdecke, wie du deine Talente voll
							ausschöpfen kannst. So sicherst du dir maßgeschneiderte Jobangebote, die
							perfekt zu dir passen!
						</TkaP2Span>
					</TextWrapper>
					<InfoBox>
						<TkaH2Span tkaColor={brandStrong120}>Weg zum Wunschjob</TkaH2Span>
						<ItemWrapper>
							<Icon icon="circle" sizeInRem={1} tkaColor={brandStrong120} />
							<TkaP2Span tkaColor={brandStrong120}>
								<u>Potenzialanalyse MAX</u> kostenlos ausfüllen und Modul
								freischalten
							</TkaP2Span>
						</ItemWrapper>
						<ItemWrapper>
							<Icon icon="circle" sizeInRem={1} tkaColor={brandStrong120} />
							<TkaP2Span tkaColor={brandStrong120}>
								Interessante Stellen finden
							</TkaP2Span>
						</ItemWrapper>
						<ItemWrapper>
							<Icon icon="circle" sizeInRem={1} tkaColor={brandStrong120} />
							<TkaP2Span tkaColor={brandStrong120}>Wunschjob sichern</TkaP2Span>
						</ItemWrapper>
					</InfoBox>
				</Wrapper>
				<ButtonWrapper>
					<Button
						label="Potenzialanalyse MAX ausfüllen"
						iconName="arrowRight"
						onClick={handleGoToPotentialAnalysisMAX}
						loading={isLoading}
					/>
				</ButtonWrapper>
			</ResponsiveBottomSheetModal>
		</>
	);
};
