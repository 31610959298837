/**
 * @generated SignedSource<<06ec6784f0d862a5fb06ebac13fa1451>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CountryCode = "AT" | "CH" | "DE";
export type SalutationType = "Divers" | "Mr" | "Mrs" | "Unknown";
export type EditPrivateBaseDataInput = {
  baseData: PrivateBaseDataInput;
  clientMutationId?: string | null;
};
export type PrivateBaseDataInput = {
  city: string;
  countryCode: CountryCode;
  firstName: string;
  houseNumber: string;
  lastName: string;
  phoneNumber?: string | null;
  postalCode: string;
  salutation: SalutationType;
  street: string;
  title?: string | null;
};
export type accountTab_EditPrivateBaseDataMutation$variables = {
  input: EditPrivateBaseDataInput;
};
export type accountTab_EditPrivateBaseDataMutation$data = {
  readonly AccountBaseData: {
    readonly editPrivateBaseData: {
      readonly baseData: {
        readonly __typename: "PrivateBaseData";
        readonly " $fragmentSpreads": FragmentRefs<"businessAccountForm_BaseDataFragment" | "privateAccountForm_BaseDataFragment">;
      };
    } | null;
  };
};
export type accountTab_EditPrivateBaseDataMutation = {
  response: accountTab_EditPrivateBaseDataMutation$data;
  variables: accountTab_EditPrivateBaseDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountTab_EditPrivateBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataMutations",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditPrivateBaseDataPayload",
            "kind": "LinkedField",
            "name": "editPrivateBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivateBaseData",
                "kind": "LinkedField",
                "name": "baseData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "businessAccountForm_BaseDataFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "privateAccountForm_BaseDataFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountTab_EditPrivateBaseDataMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountBaseDataMutations",
        "kind": "LinkedField",
        "name": "AccountBaseData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditPrivateBaseDataPayload",
            "kind": "LinkedField",
            "name": "editPrivateBaseData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivateBaseData",
                "kind": "LinkedField",
                "name": "baseData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "street",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "houseNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countryCode",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingOffice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyLegalForm",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "invoiceEmail",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountData",
                            "kind": "LinkedField",
                            "name": "accountData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bic",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "iban",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaxData",
                            "kind": "LinkedField",
                            "name": "taxData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxIdentificationNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxNumber",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "BusinessBaseData",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "salutation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "type": "PrivateBaseData",
                        "abstractKey": null
                      }
                    ],
                    "type": "baseData",
                    "abstractKey": "__isbaseData"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a28e27754a9afdcee078e0386d2ef96e",
    "id": null,
    "metadata": {},
    "name": "accountTab_EditPrivateBaseDataMutation",
    "operationKind": "mutation",
    "text": "mutation accountTab_EditPrivateBaseDataMutation(\n  $input: EditPrivateBaseDataInput!\n) {\n  AccountBaseData {\n    editPrivateBaseData(input: $input) {\n      baseData {\n        __typename\n        ...businessAccountForm_BaseDataFragment\n        ...privateAccountForm_BaseDataFragment\n      }\n    }\n  }\n}\n\nfragment businessAccountForm_BaseDataFragment on baseData {\n  __isbaseData: __typename\n  street\n  houseNumber\n  postalCode\n  city\n  countryCode\n  __typename\n  ... on BusinessBaseData {\n    companyName\n    billingOffice\n    companyLegalForm\n    invoiceEmail\n    phoneNumber\n    accountData {\n      bic\n      iban\n    }\n    taxData {\n      taxIdentificationNumber\n      taxNumber\n    }\n  }\n}\n\nfragment privateAccountForm_BaseDataFragment on baseData {\n  __isbaseData: __typename\n  street\n  houseNumber\n  postalCode\n  city\n  countryCode\n  __typename\n  ... on PrivateBaseData {\n    phoneNumber\n    firstName\n    lastName\n    salutation\n    title\n  }\n}\n"
  }
};
})();

(node as any).hash = "141ba245210dda46e464d26cc3d74c64";

export default node;
