import { graphql } from "babel-plugin-relay/macro";

export const INSTRUCTORS_CONNECTION_FRAGMENT = graphql`
	fragment instructorsComponent_InstructorsConnectionFragment on InstructorsConnection {
		edges {
			node {
				id
				...instructorAbout_InstructorFragment
			}
		}
	}
`;
