/**
 * @generated SignedSource<<e54854211da8b329120a4394e70d4485>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseContextMenu_LicenseFragment$data = {
  readonly id: string;
  readonly learnable?: {
    readonly groupCoaching?: {
      readonly cartClickout: {
        readonly relativeLink: string;
      } | null;
    } | null;
    readonly oneToOneCoaching?: {
      readonly cartClickout: {
        readonly relativeLink: string;
      } | null;
    } | null;
    readonly root?: {
      readonly structureDefinition: {
        readonly extension?: {
          readonly cartClickout?: {
            readonly relativeLink: string;
          } | null;
        };
      };
    } | null;
  } | null;
  readonly " $fragmentType": "licenseContextMenu_LicenseFragment";
};
export type licenseContextMenu_LicenseFragment$key = {
  readonly " $data"?: licenseContextMenu_LicenseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseContextMenu_LicenseFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CartClickout",
    "kind": "LinkedField",
    "name": "cartClickout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "relativeLink",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LearnOpportunityV2",
    "kind": "LinkedField",
    "name": "root",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "structureDefinition",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "extension",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v0/*: any*/),
                    "type": "PublishedRootExtensionImpl",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LearnOpportunityRootStructureDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licenseContextMenu_LicenseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "learnable",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CoachingOffer",
                  "kind": "LinkedField",
                  "name": "groupCoaching",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "PublishedGroupCoachingLearnable",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CoachingOffer",
                  "kind": "LinkedField",
                  "name": "oneToOneCoaching",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "PublishedOneToOneCoachingLearnable",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "PublishedCourseLearnable",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "PublishedMicroLearningLearnable",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LearnableLicenseGrouping",
      "abstractKey": null
    }
  ],
  "type": "LicenseGrouping",
  "abstractKey": "__isLicenseGrouping"
};
})();

(node as any).hash = "50c2d9e832e72332b63782efc710cd64";

export default node;
