import { createContext } from "react";
import { LearnableKind } from "@relay/gridView_Query.graphql";

export type LicensesScreenContext = {
	filters: {
		first?: number;
		last?: number;
		after?: string;
		before?: string;
		kinds: LearnableKind[];
		text?: string;
		licensesAvailable?: boolean;
		licensesConsumed?: boolean;
	};
	helpers: {
		setText: (text?: string) => void;
		setKinds: (kinds: LearnableKind[]) => void;
		toggleKind: (kind: LearnableKind) => void;
		toggleLicensesAvailable: () => void;
		toggleLicensesConsumed: () => void;
	};
};
export const LicensesScreenContext = createContext<LicensesScreenContext>({
	filters: {
		kinds: [],
		licensesAvailable: true,
		licensesConsumed: true,
		text: "",
	},
	helpers: {
		setText: (_) => {},
		setKinds: (_) => {},
		toggleKind: () => {},
		toggleLicensesAvailable: () => {},
		toggleLicensesConsumed: () => {},
	},
});
