import tw from "twin.macro";

export const Wrapper = tw.div`
w-[100%]
p-[1.5rem]
bg-shade-5
rounded-[0.5rem]`;

export const OptionsWrapper = tw.div`
flex
flex-col
md:flex-row
gap-[1rem]`;

export const OptionWrapper = tw.div`
w-[100%]
md:w-[50%]
flex
items-center
justify-between
md:ml-[0.5rem]
bg-shade-0
rounded-[0.5rem]
p-[1rem]`;

export const TextWrapper = tw.div`
flex
flex-col
mr-[1rem]`;
