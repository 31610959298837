import { ButtonSkeleton } from "@components/button/button.skeleton";
import { Skeleton } from "@components/skeleton";
import { useWindowSize } from "@hooks/use-window-size";

import { PreviewWrapper, TitleWrapper, Wrapper } from "./material-overview.styles";
import { MaterialsTabSkeleton } from "../materials-tab/materials-tab.skeleton";

export const MaterialOverviewSkeleton = ({ showDetails }: { showDetails?: boolean }) => {
	const { isXLargeUp } = useWindowSize();

	const overview = (
		<Wrapper>
			<TitleWrapper>
				<Skeleton width="4rem" />
				<Skeleton height="2rem" width="15rem" />
			</TitleWrapper>
			<PreviewWrapper>
				<ButtonSkeleton />
			</PreviewWrapper>
		</Wrapper>
	);

	return isXLargeUp || showDetails ? overview : <MaterialsTabSkeleton />;
};
