/**
 * @generated SignedSource<<7b4d6bc7a6eee0e09b8f3e512aa3440e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createGroupSuccessModal_UserInAccountGroupWrapperFragment$data = {
  readonly group: {
    readonly name: string;
  };
  readonly memberCount: any;
  readonly " $fragmentType": "createGroupSuccessModal_UserInAccountGroupWrapperFragment";
};
export type createGroupSuccessModal_UserInAccountGroupWrapperFragment$key = {
  readonly " $data"?: createGroupSuccessModal_UserInAccountGroupWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createGroupSuccessModal_UserInAccountGroupWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createGroupSuccessModal_UserInAccountGroupWrapperFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserInAccountGroupWrapper",
  "abstractKey": null
};

(node as any).hash = "50e1640f8c470738b1d3a316df336694";

export default node;
