/**
 * @generated SignedSource<<6b52473dfdf714278c998ce75e2e5794>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductExtensionKind = "Academies";
import { FragmentRefs } from "relay-runtime";
export type offerCard_ProductFragment$data = {
  readonly extensions: ReadonlyArray<{
    readonly atGrossPrice?: number;
    readonly chGrossPrice?: number;
    readonly deGrossPrice?: number;
    readonly kind: ProductExtensionKind;
  }>;
  readonly isTaxFree: boolean;
  readonly netPrice: number;
  readonly " $fragmentType": "offerCard_ProductFragment";
};
export type offerCard_ProductFragment$key = {
  readonly " $data"?: offerCard_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offerCard_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offerCard_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTaxFree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extensions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deGrossPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "atGrossPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chGrossPrice",
              "storageKey": null
            }
          ],
          "type": "AcademiesProductExtension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "e3e526d783adfc811ce66ac8e1366c22";

export default node;
