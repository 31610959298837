import { useState } from "react";
import { Card } from "@components/card/card.component";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { brandStrong100, onBrandStrong } from "@themes/colors";
import { TkaH4Span, TkaP3Span } from "@themes/font-tags";
import { CertificateCardProps } from "./certificate-card.interface";
import { Flex, OrderWrapper, TitleWrapper, Wrapper } from "./certificate-card.styles";
import { Button } from "../button";
import { Icon } from "../icon";
import { OrderIhkFlow } from "../order-ihk-flow/order-ihk-flow.component";

export const CertificateCard = ({ rootId, rootName, canOrder, onOrder }: CertificateCardProps) => {
	const { t } = useTkaTranslation("courseScreen");

	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleOrderOnClick = () => {
		if (onOrder) return onOrder();
		setIsModalVisible(true);
	};

	const handleOnDismiss = () => {
		setIsModalVisible(false);
	};

	return (
		<Wrapper noMaxWidth={canOrder}>
			<Card bodyPaddingInRem={1.5} backgroundColor={brandStrong100} showBorder={false}>
				<Flex>
					<Icon icon="certifiedCertificate" tkaColor={onBrandStrong} sizeInRem={3} />
					{canOrder ? (
						<OrderWrapper>
							<TitleWrapper>
								<TkaP3Span bold>Herzlichen Glückwunsch</TkaP3Span>
								<TkaP3Span>
									zum Abschluss deiner IHK-Weiterbildung! Um dein IHK-Zertifikat
									per Post zu erhalten, gib bitte noch schnell deine Adresse ein.
								</TkaP3Span>
							</TitleWrapper>
							<Button
								colorVersion="textlink"
								label="Zertifikat per Post anfordern"
								iconName="arrowRight"
								onClick={handleOrderOnClick}
							/>
						</OrderWrapper>
					) : (
						<TkaP3Span tkaColor={onBrandStrong}>
							{t("course_screen.ihkCertificatePrefix").trim()}&nbsp;
							<TkaH4Span tkaColor={onBrandStrong}>{rootName}</TkaH4Span>
						</TkaP3Span>
					)}
				</Flex>
			</Card>
			<OrderIhkFlow rootId={rootId} isVisible={isModalVisible} onDismiss={handleOnDismiss} />
		</Wrapper>
	);
};
