import tw from "twin.macro";

export const Wrapper = tw.div`
    flex
    flex-col
    gap-32
`;

export const HeaderWrapper = tw.div`
    flex
    flex-col
    gap-24
`;

export const SideMenuWrapper = tw.div`
    h-full
    flex
    flex-col
    overflow-hidden
`;

export const SideMenuHeaderWrapper = tw.div`
    flex
    flex-col
    gap-24
    px-24
`;

export const StickyWrapper = tw.div`
    sticky
    top-0
    z-20
    bg-shade-0
`;
