import graphql from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_QUESTION_FRAGMENT = graphql`
	fragment questionSection_PotentialAnalysisQuestionFragment on PotentialAnalysisQuestion {
		kind
		id
		... on PotentialAnalysisMultipleChoiceQuestion {
			questionText
			answerOptions {
				answerOptionId
			}
			givenAnswers {
				kind
				answerOptionId
			}
		}
		... on PotentialAnalysisMultipleChoiceFreetextQuestion {
			questionText
			answerOptions {
				answerOptionId
				isFreeText
			}
			givenAnswers {
				kind
				answerOptionId
				freeText
			}
		}
		... on PotentialAnalysisSingleChoiceQuestion {
			questionText
			answerOptions {
				answerOptionId
			}
			givenAnswer {
				kind
				answerOptionId
			}
		}
		... on PotentialAnalysisSingleChoiceFreetextQuestion {
			questionText
			answerOptions {
				answerOptionId
				isFreeText
			}
			givenAnswer {
				kind
				answerOptionId
				freeText
			}
		}
		... on PotentialAnalysisScaleQuestion {
			questionText
			answerOptions {
				answerOptionId
			}
			givenAnswer {
				kind
				answerOptionId
			}
		}
		... on PotentialAnalysisTextQuestion {
			questionText
			givenAnswer {
				kind
				text
			}
		}
		...checkboxQuestion_PotentialAnalysisQuestionFragment
		...textQuestion_PotentialAnalysisQuestionFragment
		...scaleQuestion_PotentialAnalysisQuestionFragment
	}
`;
