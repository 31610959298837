export const contentStyle = {
	width: "100%",
	margin: "0px",
	padding: "0px",
	borderRadius: "24px 24px 0px 0px",
	boxShadow: "none",
};

export const style = {
	margin: "0px",
	padding: "0px",
};
