import tw from "twin.macro";

export const TabWrapper = tw.div`
	flex
	flex-col
	gap-32
	xl:py-32
	xl:px-24
	xl:overflow-y-scroll
	no-scrollbar
`;

export const ModalWrapper = tw.div`
	max-w-[25rem]
`;
