import tw from "twin.macro";

export const Wrapper = tw.div`
	xl:w-[4.5rem]
	xl:h-[4.5rem]
	flex
	flex-col
	gap-8
	py-4
	justify-center
	items-center
	text-center
	hover:cursor-pointer
`;
