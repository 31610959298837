import graphql from "babel-plugin-relay/macro";

export const LICENSE_GROUPING_FRAGMENT = graphql`
	fragment licenseContextMenu_LicenseFragment on LicenseGrouping {
		id
		... on LearnableLicenseGrouping {
			learnable {
				... on PublishedGroupCoachingLearnable {
					groupCoaching {
						cartClickout {
							relativeLink
						}
					}
				}
				... on PublishedOneToOneCoachingLearnable {
					oneToOneCoaching {
						cartClickout {
							relativeLink
						}
					}
				}
				... on PublishedCourseLearnable {
					root {
						structureDefinition {
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on PublishedRootExtensionImpl {
										cartClickout {
											relativeLink
										}
									}
								}
							}
						}
					}
				}
				... on PublishedMicroLearningLearnable {
					root {
						structureDefinition {
							... on LearnOpportunityRootStructureDefinition {
								extension {
									... on PublishedRootExtensionImpl {
										cartClickout {
											relativeLink
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
