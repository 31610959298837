/**
 * @generated SignedSource<<28749d1a0f512e91af4e2dfa5d3c2fd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type materialsTab_LearnOpportunityV2Fragment$data = {
  readonly typeDefinition: {
    readonly children?: ReadonlyArray<{
      readonly typeDefinition: {
        readonly children?: ReadonlyArray<{
          readonly attachmentsV2: ReadonlyArray<{
            readonly id: string;
          }>;
        }>;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"materialsTree_LearnOpportunityV2Fragment">;
  readonly " $fragmentType": "materialsTab_LearnOpportunityV2Fragment";
};
export type materialsTab_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: materialsTab_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"materialsTab_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "materialsTab_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeDefinition",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "LearnOpportunityV2",
                          "kind": "LinkedField",
                          "name": "children",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "attachmentsV2",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "id",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityBranchTypeDefinition",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityBranchTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "materialsTree_LearnOpportunityV2Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "ec69a368166cc4889c452e4e0203b92c";

export default node;
