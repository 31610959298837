import { AxiosProgressEvent } from "axios";
import uploadFileService from "./upload-file.service";

class UploadFileForAsyncElementService {
	upload(
		file: File,
		uploadAsyncLearnElementId: string,
		onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
	) {
		return uploadFileService.upload(
			"/upload-file-for-async-element",
			file,
			onUploadProgress,
			(formData) => {
				formData.append("uploadAsyncLearnElementId", uploadAsyncLearnElementId);
			},
		);
	}
}

export default new UploadFileForAsyncElementService();
