import styled from "styled-components";
import tw from "twin.macro";
import { H1Span } from "@themes/font-tags";

export const FeedbackWrapper = tw.div`
flex
flex-col
gap-[1.5rem]
md:gap-[1rem]
md:flex-row
`;

export const HeadlineContentWrapper = tw.div`
flex
flex-col
justify-center
md:min-w-[50%]`;

export const CarouselWrapper = tw.div`
md:ml-auto

md:w-[50%]`;

export const HeadlineWrapper = tw.div`
flex
relative
h-[6rem]
mt-[-1.5rem]
`;

export const StyledTkaH1Span = tw(H1Span)`
text-4xl
ml-[1.75rem]
z-10
md:ml-[3.5rem]
`;

export const AvatarWrapper = tw.div`
flex
mr-[1rem]
`;

export const IconWrapper = styled.div<{ index: number }>`
	position: absolute;
	top: 0;
	left: ${({ index }) => (index > 0 ? `${index * 1.5}rem` : 0)};
	z-index: ${({ index }) => (index > 0 ? 100 - index : 100)};
`;

export const AvatarInfoWrapper = tw.div`
flex
relative
items-center
gap-[5rem]
h-[2.5rem]
md:ml-[3.5rem]
`;

export const StyledIcon = tw.div`
absolute
top-[-1.5rem]
md:left-[1rem]`;
