import React from "react";
import { Skeleton } from "@components/skeleton";
import {
	TitleDescriptionClass,
	WrapperClass,
} from "@screens/avgs-potential-analysis/parts/module-section/module-section.styles";

export const ModuleSectionSkeleton = () => {
	return (
		<div className={WrapperClass}>
			<div className={TitleDescriptionClass}>
				<Skeleton />
			</div>
		</div>
	);
};
