import { useState } from "react";
import { useFragment, useMutation } from "react-relay";

import { ParticipationStatus } from "@relay/participantPresenceCheck_ParticipantFragment.graphql";
import { participantPresenceCheck_RegisterMyParticipationMutation } from "@relay/participantPresenceCheck_RegisterMyParticipationMutation.graphql";
import { shade60 } from "@themes/colors";
import { TkaL1Span, TkaP2Span } from "@themes/font-tags";
import {
	PARTICIPANT_FRAGMENT,
	REGISTER_MY_PARTICIPATION_MUTATION,
} from "./participant-presence-check.graphql";
import {
	LeftWrapper,
	NameWrapper,
	SelectButtonWrapper,
	Wrapper,
} from "./participant-presence-check.styles";
import { ParticipantPresenceCheckProps } from "./participant-presence-check.types";

import { ConfirmParticipantAbsentModal } from "../confirm-participant-absent-modal";
import { ParticipantPresenceSelectButton } from "../participant-presence-select-button";
import { UserAvatar } from "../user-avatar";

// TODO: add-translations
export const ParticipantPresenceCheck = ({
	availabilityId,
	participantFragmentRef,
}: ParticipantPresenceCheckProps) => {
	const participant = useFragment(PARTICIPANT_FRAGMENT, participantFragmentRef ?? null);

	const [registerMyParticipation] =
		useMutation<participantPresenceCheck_RegisterMyParticipationMutation>(
			REGISTER_MY_PARTICIPATION_MUTATION,
		);

	const [isModalVisible, setIsModalVisible] = useState(false);

	if (!participant?.user) return null;

	const updateParticipation = (status: ParticipationStatus) => {
		registerMyParticipation({
			variables: {
				input: {
					availabilityId,
					participantId: participant.user!.id,
					participationStatus: status,
				},
			},
		});
	};

	const handleParticipationStatusOnChange = (status: ParticipationStatus) => {
		if (status === "Absent") {
			setIsModalVisible(true);
			return;
		}
		updateParticipation(status);
	};

	const handleOnDismiss = () => {
		setIsModalVisible(false);
	};

	const handleOnConfirmAbsent = () => {
		updateParticipation("Absent");
		setIsModalVisible(false);
	};

	const status =
		participant.participationStatus === "NotConfirmed"
			? undefined
			: participant.participationStatus;

	return (
		<Wrapper>
			<LeftWrapper>
				<UserAvatar sizeInRem={1.25} userFragmentRef={participant.user} />
				<NameWrapper>
					<TkaP2Span>{participant.user.name}</TkaP2Span>
					<TkaL1Span tkaColor={shade60}>Zugesagt</TkaL1Span>
				</NameWrapper>
			</LeftWrapper>
			<SelectButtonWrapper>
				<ParticipantPresenceSelectButton
					status={status}
					onChange={handleParticipationStatusOnChange}
				/>
			</SelectButtonWrapper>
			<ConfirmParticipantAbsentModal
				name={participant.user.name}
				isVisible={isModalVisible}
				onDismiss={handleOnDismiss}
				onConfirm={handleOnConfirmAbsent}
			/>
		</Wrapper>
	);
};
