import {
	shade0,
	shade5,
	shade10,
	info100,
	brandMain100,
	brandMain80,
	TkaColor,
	brandStrong20,
	brandStrong80,
	brandStrong100,
	brandStrong40,
	onBrandMain,
	onBrandStrong,
	shade20,
	error100,
	success100,
	error20,
	success20,
	error0,
	success0,
} from "@themes/colors";
import { ColorVersion } from "./button.types";

/* Background color */
export const calcDefaultBackgroundColor = (colorVersion: ColorVersion): TkaColor | undefined => {
	switch (colorVersion) {
		case "default":
			return brandMain100;
		case "strong":
			return brandStrong100;
		case "error":
			return error20;
		case "success":
			return success20;
	}
};

export const calcHoverBackgroundColor = (colorVersion: ColorVersion): TkaColor | undefined => {
	switch (colorVersion) {
		case "default":
			return brandMain80;
		case "tertiary":
			return brandStrong20;
		case "strong":
			return brandStrong80;
		case "error":
			return error0;
		case "success":
			return success0;
	}
};

export const calcActiveBackgroundColor = (colorVersion: ColorVersion): TkaColor | undefined => {
	switch (colorVersion) {
		case "default":
			return brandMain80;
		case "tertiary":
			return shade0;
		case "outline":
			return shade10;
		case "strong":
			return brandStrong80;
		case "error":
			return error0;
		case "success":
			return success0;
	}
};

export const calcFocusBackgroundColor = (colorVersion: ColorVersion): TkaColor | undefined => {
	switch (colorVersion) {
		case "default":
			return brandMain100;
		case "strong":
			return brandStrong100;
		case "error":
			return error20;
		case "success":
			return success20;
	}
};

export const calcDisabledBackgroundColor = (colorVersion: ColorVersion): TkaColor | undefined => {
	switch (colorVersion) {
		case "default":
			return shade5;
		case "strong":
			return brandStrong40;
		case "error":
			return error0;
		case "success":
			return success0;
	}
};

/* Content color */
export const calcDefaultColor = (
	colorVersion: ColorVersion,
	textLinkColor?: TkaColor,
): TkaColor => {
	switch (colorVersion) {
		case "default":
			return onBrandMain;
		case "tertiary":
			return brandStrong100;
		case "outline":
			return brandMain100;
		case "strong":
			return onBrandStrong;
		case "error":
			return error100;
		case "success":
			return success100;
		case "textlink":
			return textLinkColor ?? brandMain100;
	}
};

export const calcDisabledColor = (
	colorVersion: ColorVersion,
	textLinkColor?: TkaColor,
): TkaColor => {
	switch (colorVersion) {
		case "default":
			return shade20;
		case "tertiary":
			return brandStrong20;
		case "outline":
			return shade20;
		case "strong":
			return onBrandStrong;
		case "error":
			return error100.withAlpha(0.2);
		case "success":
			return success100.withAlpha(0.2);
		case "textlink":
			return (textLinkColor ?? brandMain100).withAlpha(0.2);
	}
};

/* Content opacity */
export const calcDisabledOpacity = (colorVersion: ColorVersion): number => {
	switch (colorVersion) {
		case "strong":
			return 0.2;
		default:
			return 1;
	}
};

/* Border */
export const calcDefaultBorder = (colorVersion: ColorVersion): string | undefined => {
	switch (colorVersion) {
		case "outline":
			return `box-shadow: 0 0 0 2px ${brandMain100} inset;`;
	}
};

export const calcHoverBorder = (colorVersion: ColorVersion): string | undefined => {
	switch (colorVersion) {
		case "outline":
			return `box-shadow: 0 0 0 2px ${brandMain80} inset;`;
	}
};

export const calcActiveBorder = (colorVersion: ColorVersion): string | undefined => {
	switch (colorVersion) {
		case "outline":
			return `box-shadow: 0 0 0 2px ${brandMain80} inset;`;
	}
};

export const calcFocusBorder = (): string => {
	return `box-shadow: 0 0 0 1px ${info100} inset;`;
};

export const calcDisabledBorder = (colorVersion: ColorVersion): string | undefined => {
	switch (colorVersion) {
		case "outline":
			return `box-shadow: 0 0 0 2px ${shade20} inset;`;
	}
};

export const getColorWithTransparentFallback = (color: TkaColor | undefined) => {
	return color ? color : "transparent";
};

/* Box shadow */
export const calcActiveBoxShadow = (colorVersion: ColorVersion): string | undefined => {
	switch (colorVersion) {
		case "default":
		case "tertiary":
		case "strong":
			return `0 2px 4px 0 ${brandMain100.getColor(0.25)} inset;`;
	}
};
