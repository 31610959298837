import React, { useMemo } from "react";
import { Icon } from "@components/icon";
import { useWindowSize } from "@hooks/use-window-size";
import {
	FilterTextWrapper,
	InputTextMock,
	Wrapper,
} from "@screens/offers/parts/filters/filters.styles";
import { shade100, shade40 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";

export const FiltersSkeleton = () => {
	const { isMediumUp } = useWindowSize();
	const iconColor = useMemo(() => (isMediumUp ? shade40 : shade100), [isMediumUp]);
	const iconSize = useMemo(() => (isMediumUp ? 1.5 : 0.75), [isMediumUp]);
	return (
		<React.Fragment>
			<Wrapper>
				<InputTextMock>
					<Icon icon={"filter"} sizeInRem={iconSize} tkaColor={iconColor} />
					{isMediumUp && (
						<FilterTextWrapper>
							<TkaP2Span tkaColor={shade100}>Filter</TkaP2Span>
						</FilterTextWrapper>
					)}
				</InputTextMock>
			</Wrapper>
		</React.Fragment>
	);
};
