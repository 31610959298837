/**
 * @generated SignedSource<<462990b594bd14e97e186c27cd2c6100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appointments_AvailabilityFragment$data = {
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentCard_AvailabilityFragment" | "appointmentModalsFlow_AvailabilityFragment" | "unconfirmedAppointmentCard_AvailabilityFragment">;
  readonly " $fragmentType": "appointments_AvailabilityFragment";
};
export type appointments_AvailabilityFragment$key = {
  readonly " $data"?: appointments_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointments_AvailabilityFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "appointments_AvailabilityFragment"
};

(node as any).hash = "0da6e66944502c11c72b3dcff1ed2ada";

export default node;
