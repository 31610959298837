import { createContext } from "react";

type ContentSubmissionModalContext = {
	isModalVisible: boolean;

	isFailureModalVisible: boolean;
	setFailureModalVisible: (visible: boolean) => void;

	isSuccessModalVisible: boolean;
	setSuccessModalVisible: (visible: boolean) => void;

	backButtonHidden: boolean;
	setBackButtonHidden: (hidden: boolean) => void;

	nextButtonText?: string;
	setNextButtonText: (text: string | undefined) => void;

	canGoNext: boolean;
	setCanGoNext: (canGoNext: boolean) => void;

	addTryAgainOnClickListener: (listener: () => void) => () => void;
	tryAgainClicked: () => void;

	addShowAnswerOnClickListener: (listener: () => void) => () => void;
	showAnswerClicked: () => void;

	addGoToNextOnClickListener: (listener: () => boolean) => () => void;
	goToNextClicked: () => boolean;

	addGoToPreviousOnClickListener: (listener: () => void) => () => void;
	goToPreviousClicked: () => void;

	addOnGoToNextListener: (listener: () => void) => () => void;
	onGoToNext: () => void;

	loading: boolean;
	setLoading: (loading: boolean) => void;
};

export const ContentSubmissionModalContext = createContext<ContentSubmissionModalContext>({
	isModalVisible: false,

	isFailureModalVisible: false,
	setFailureModalVisible: () => {},

	isSuccessModalVisible: false,
	setSuccessModalVisible: () => {},

	backButtonHidden: false,
	setBackButtonHidden: () => {},

	setNextButtonText: () => {},

	canGoNext: false,
	setCanGoNext: () => {},

	addTryAgainOnClickListener: () => () => {},
	tryAgainClicked: () => {},

	addShowAnswerOnClickListener: () => () => {},
	showAnswerClicked: () => {},

	addGoToNextOnClickListener: () => () => {},
	goToNextClicked: () => false,

	addGoToPreviousOnClickListener: () => () => {},
	goToPreviousClicked: () => {},

	addOnGoToNextListener: () => () => {},
	onGoToNext: () => {},

	loading: false,
	setLoading: () => {},
});
