import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";

export const Wrapper = tw.div`flex gap-32 mt-24`;
export const Grid = tw.div`grid grid-cols-4 gap-24 flex-1`;
export const FullWidthWrapper = tw.div`col-span-4`;
export const HalfWidthButMobileFullWrapper = tw.div`col-span-4 lg:col-span-2`;
export const QuarterWidthButMobileFullWrapper = tw.div`col-span-4 lg:col-span-1`;
export const Hr = styled.hr<{ tkaColor: TkaColor }>`
	${tw`col-span-4`}
	${(p) => `color:${p.tkaColor};`}
`;

export const Spacing12 = tw.div`h-12`;
export const Spacing4 = tw.div`h-4`;

export const FilesGrid = tw.div`grid grid-cols-4 gap-y-8`;
export const MarginRight8 = tw.div`w-8`;

export const RadioOptionWrapper = tw.div`flex flex-row gap-12`;
export const RadioGroupWrapper = tw.div`flex flex-row gap-24 mt-12`;
