/**
 * @generated SignedSource<<dab94f5d55cae3f076451c8e5f2ef38c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetAnswerInSingleChoiceQuestionInput = {
  answerOptions: ReadonlyArray<PotentialAnalysisSelectAnswerOptionInput>;
  clientMutationId?: string | null;
  givenAnswer: PotentialAnalysisSelectedGivenAnswerInput;
  learnPotentialAnalysisId: string;
  questionId: string;
  questionText: string;
};
export type PotentialAnalysisSelectAnswerOptionInput = {
  answerOptionId: number;
  text: string;
};
export type PotentialAnalysisSelectedGivenAnswerInput = {
  answerOptionId: number;
};
export type checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation$variables = {
  input: SetAnswerInSingleChoiceQuestionInput;
};
export type checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation$data = {
  readonly AvgsPotentialAnalysis: {
    readonly setAnswerInSingleChoiceQuestion: {
      readonly question: {
        readonly givenAnswer: {
          readonly answerOptionId: number;
        } | null;
        readonly id: string;
      };
    } | null;
  };
};
export type checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation = {
  response: checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation$data;
  variables: checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AvgsPotentialAnalysisMutations",
    "kind": "LinkedField",
    "name": "AvgsPotentialAnalysis",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SetAnswerInSingleChoiceQuestionPayload",
        "kind": "LinkedField",
        "name": "setAnswerInSingleChoiceQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PotentialAnalysisSingleChoiceQuestion",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PotentialAnalysisSelectedGivenAnswer",
                "kind": "LinkedField",
                "name": "givenAnswer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answerOptionId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a285997b4dd97a532a997cd9ce488be",
    "id": null,
    "metadata": {},
    "name": "checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation checkboxQuestion_SetAnswerInSingleChoiceQuestionMutation(\n  $input: SetAnswerInSingleChoiceQuestionInput!\n) {\n  AvgsPotentialAnalysis {\n    setAnswerInSingleChoiceQuestion(input: $input) {\n      question {\n        id\n        givenAnswer {\n          answerOptionId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a826cb97f857969c246927d748475d0c";

export default node;
