import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { Icon } from "@components/icon";
import { ImageCardAtom } from "@components/ImageCardAtom";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade100, brandStrong100, error100 } from "@themes/colors";
import { TkaP3Span, TkaP2Span, TkaH3Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
	isAfterIndexPath,
	isEqualIndexPath,
} from "@utils/index-path.util";
import { parseHtml } from "@utils/parse-html";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./content-card.graphql";
import { Expanded, Group, Line, MainWrapper } from "./content-card.styles";
import { ContentCardProps } from "./content-card.types";

// TODO: add-translations
export const ContentCard = ({
	rootFinished,
	hasFullAccess,
	headIndexPath,
	isSelected,
	onClick,
	learnOpportunityV2FragmentRef,
}: ContentCardProps) => {
	const learnOpportunityV2 = useFragment(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);

	const { t } = useTkaTranslation("modulesAndLessons");
	const [isExpanded, setIsExpanded] = useState(false);

	const handleOnExpandClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			setIsExpanded(!isExpanded);
		},
		[isExpanded],
	);

	const handleOnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			onClick?.();
		},
		[onClick],
	);

	const branchNumber = getBranchNodeNumberFromIndexPath(learnOpportunityV2.indexPath);
	const contentNumber = getContentNodeNumberFromIndexPath(learnOpportunityV2.indexPath);
	const imageUrl = learnOpportunityV2.image?.url;
	const title = learnOpportunityV2.structureDefinition.title;
	const shortDescription = learnOpportunityV2.shortDescription;
	const duration = Math.ceil(learnOpportunityV2.lengthInSecondsV2 / 60);
	const indexPath = learnOpportunityV2.indexPath;
	const contentKind = learnOpportunityV2.typeDefinition.contentKind;

	const receivablePoints =
		learnOpportunityV2.typeDefinition.extension?.receivableGamificationPoints ?? 0;
	const receivedPoints =
		learnOpportunityV2.typeDefinition.extension?.receivedGamificationPoints ?? 0;
	const needsUnlock = learnOpportunityV2.typeDefinition.extension?.isUnlockNecessaryToStart;
	const shouldBeStartedNext = isEqualIndexPath(indexPath, headIndexPath);

	const renderStatus = () => {
		if (rootFinished || isAfterIndexPath(indexPath, headIndexPath)) {
			if (contentKind === "Async") {
				const status = learnOpportunityV2.typeDefinition.task?.status;
				const label = status === "Evaluated" ? "Bewertet" : "eingereicht";
				return (
					<>
						<TkaP2Span tkaColor={shade100}>{label}</TkaP2Span>
						<Icon icon={"checkCircle"} tkaColor={shade100} sizeInRem={1} />
					</>
				);
			}
			return (
				<>
					{receivablePoints > 0 && (
						<TkaH3Span>
							{receivedPoints}/{receivablePoints}
						</TkaH3Span>
					)}
					<Icon icon={"checkCircle"} tkaColor={shade100} sizeInRem={1} />
				</>
			);
		} else if (isEqualIndexPath(indexPath, headIndexPath)) {
			if (!hasFullAccess && needsUnlock) {
				return (
					<>
						<TkaP2Span tkaColor={error100}>Lizenz benötigt</TkaP2Span>
						<Icon icon={"removeBadge"} tkaColor={error100} sizeInRem={1} />
					</>
				);
			}
			return (
				<>
					<TkaP2Span tkaColor={brandStrong100}>
						{t("modules_and_lessons.startNow")}
					</TkaP2Span>
					<Icon icon={"lockUnlock"} tkaColor={brandStrong100} sizeInRem={1} />
				</>
			);
		}
		return <Icon icon={"lock"} tkaColor={shade100} sizeInRem={1} />;
	};

	return (
		<MainWrapper
			isSelected={isSelected}
			shouldBeStartedNext={shouldBeStartedNext && !rootFinished}
			onClick={handleOnClick}
		>
			<Line>
				<TkaP3Span>
					{t("modules_and_lessons.lesson")} {branchNumber}.{contentNumber}
				</TkaP3Span>
				<Icon
					tkaColor={shade100}
					icon={isExpanded ? "arrowUp" : "arrowDown"}
					sizeInRem={1}
					onClick={handleOnExpandClick}
				/>
			</Line>
			<>
				<TkaH3Span>{title}</TkaH3Span>
				{isExpanded ? (
					<Expanded>
						{imageUrl && <ImageCardAtom imageUrl={imageUrl} imageWidth={"70px"} />}
						{shortDescription && <TkaP3Span>{parseHtml(shortDescription)}</TkaP3Span>}
					</Expanded>
				) : null}
			</>
			<Line>
				<Group>
					{duration ? (
						<>
							<Icon icon={"timeClockCircle"} tkaColor={shade100} sizeInRem={1} />
							<TkaH3Span>ca. {duration} Min</TkaH3Span>
						</>
					) : null}
				</Group>
				<Group>{renderStatus()}</Group>
			</Line>
		</MainWrapper>
	);
};
