import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@hooks/use-window-size";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { ButtonWrapper, TextWrapper, Wrapper } from "./dialog-template.styles";
import { DialogTemplateProps } from "./dialog-template.types";
import { Button } from "../button";
import { Divider } from "../divider";

export const DialogTemplate = ({
	title,
	subtitle,
	secondaryButton,
	secondaryButtonLabel,
	secondaryButtonIconName,
	secondaryButtonColorVersion,
	secondaryButtonStretch,
	onSecondaryButtonClick,
	primaryButton,
	primaryButtonLabel,
	primaryButtonIconName,
	primaryButtonColorVersion,
	primaryButtonStretch,
	primaryButtonDisabled,
	primaryButtonLoading,
	onPrimaryButtonClick,
	navigateTo,
	maxWidthRem = 25,
	buttonsDirection,
	hasSeperator = false,
	children,
}: DialogTemplateProps) => {
	const naviagte = useNavigate();
	const { isMediumUp } = useWindowSize();

	const handlePrimaryButtonOnClick = () => {
		if (onPrimaryButtonClick) {
			onPrimaryButtonClick();
		} else {
			navigateTo && naviagte(navigateTo);
		}
	};

	const hasSecondaryButton = !!(
		secondaryButton ||
		secondaryButtonLabel ||
		secondaryButtonIconName
	);
	const hasPrimaryButton = !!(primaryButton || primaryButtonLabel || primaryButtonIconName);

	const stretchSecondaryButton = !isMediumUp || (secondaryButtonStretch ?? true);
	const stretchPrimaryButton = !isMediumUp || (primaryButtonStretch ?? hasSecondaryButton);
	return (
		<Wrapper maxWidthRem={isMediumUp ? maxWidthRem : undefined}>
			{(title || subtitle) && (
				<TextWrapper>
					{title && <H1Span>{title}</H1Span>}
					{subtitle && <TkaP2Span>{subtitle}</TkaP2Span>}
				</TextWrapper>
			)}
			{children}
			{hasSeperator && <Divider />}
			<ButtonWrapper
				onlyPrimaryButton={!hasSecondaryButton}
				buttonsDirection={buttonsDirection}
			>
				{secondaryButton ??
					(hasSecondaryButton && (
						<Button
							fillParent={stretchSecondaryButton}
							label={secondaryButtonLabel}
							iconName={secondaryButtonIconName}
							colorVersion={secondaryButtonColorVersion ?? "tertiary"}
							type="button"
							onClick={onSecondaryButtonClick}
						/>
					))}
				{primaryButton ??
					(hasPrimaryButton && (
						<Button
							disabled={primaryButtonDisabled}
							loading={primaryButtonLoading}
							fillParent={stretchPrimaryButton}
							label={primaryButtonLabel}
							iconName={primaryButtonIconName}
							colorVersion={primaryButtonColorVersion}
							onClick={handlePrimaryButtonOnClick}
						/>
					))}
			</ButtonWrapper>
		</Wrapper>
	);
};
