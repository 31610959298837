import { useNavigate } from "react-router-dom";

import { Button } from "@components/button";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { Path } from "@router/index";
import { TkaP1Span } from "@themes/font-tags";
import { TitleClass, Wrapper } from "./activate-error-step.styles";

export const ActivateErrorStep = () => {
	const { t } = useTkaTranslation("activateScreen");
	const navigate = useNavigate();
	const handleGoToLoginOnClick = () => {
		navigate(Path.login.path);
	};
	return (
		<Wrapper>
			<span className={TitleClass}>{t("activate_screen.failureTitle")}</span>
			<TkaP1Span>{t("activate_screen.failureBody")}</TkaP1Span>
			<Button
				fillParent
				label={t("activate_screen.successButtonText")}
				iconName="arrowRight"
				onClick={handleGoToLoginOnClick}
			/>
		</Wrapper>
	);
};
