import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { CollapseText } from "@components/collapse-text";
import { QuoteCard } from "@components/quote-card";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { coachScreen_Query } from "@relay/coachScreen_Query.graphql";
import { QUERY_FRAGMENT } from "@screens/coach/coach.graphql";
import {
	AboutMeWrapper,
	Gap24,
	HeaderWrapper,
	Hr,
	QuoteCardWrapper,
	Spacing8,
	VerticalWrapper,
} from "@screens/coach/coach.styles";
import { CoachScreenParams } from "@screens/coach/coach.types";
import { MyCoachings } from "@screens/coach/parts/my-coachings";
import { SocialsCard } from "@screens/coach/parts/socials-card";
import { shade10, shade100, shade80 } from "@themes/colors";
import { TkaH2Span, TkaP2Typography } from "@themes/font-tags";

export const CoachScreen = () => {
	const { t } = useTkaTranslation(["coach"]);
	const { isLargeUp } = useWindowSize();

	const { coachProfileId } = useParams<CoachScreenParams>();

	const query = useLazyLoadQuery<coachScreen_Query>(QUERY_FRAGMENT, {
		coachProfileId: coachProfileId ?? "",
	});
	const quote = query.node?.profileData?.quote ?? "";

	return (
		<ScreenWithNavbarLayout canGoBack={true}>
			<HeaderWrapper>
				<VerticalWrapper>
					{query.node && <SocialsCard coachProfileFragmentRef={query.node} />}
					{!isLargeUp && !!quote && <QuoteCard text={quote} />}
					<AboutMeWrapper>
						<TkaH2Span tkaColor={shade100}>{t("Über mich")}</TkaH2Span>
						<Spacing8 />
						<CollapseText
							truncateCount={800}
							text={query.node?.profileData?.description ?? ""}
							typography={TkaP2Typography}
							textColor={shade80}
						/>
					</AboutMeWrapper>
				</VerticalWrapper>
				{isLargeUp && !!quote && (
					<QuoteCardWrapper>
						<QuoteCard text={quote} />
					</QuoteCardWrapper>
				)}
			</HeaderWrapper>
			<Gap24 />
			<Hr tkaColor={shade10} />
			<Gap24 />
			{coachProfileId && (
				<MyCoachings coachProfileId={coachProfileId} queryFragmentRef={query} />
			)}
		</ScreenWithNavbarLayout>
	);
};
