import { shade20 } from "@themes/colors";
import {
	Wrapper,
	UserWrapper,
	UserDataWrapper,
	IconWrapper,
	NameWrapper,
	LabelsWrapper,
} from "./licenses-single-user-assignment-card.styles";
import { Card } from "../card";
import { Icon } from "../icon";
import { Skeleton } from "../skeleton";

export const LicensesSingleUserAssignmentCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={0.875} borderColor={shade20}>
			<Wrapper>
				<UserWrapper>
					<UserDataWrapper>
						<IconWrapper>
							<Icon icon="user" sizeInRem={1.5} />
						</IconWrapper>
						<Skeleton width="2.5rem" height="2.5rem" borderRadius="2.5rem" />
						<NameWrapper>
							<Skeleton width="5rem" height="1.125rem" />
							<Skeleton width="10rem" height="1.25rem" />
						</NameWrapper>
					</UserDataWrapper>
					<LabelsWrapper>
						<Skeleton width="10rem" height="1.125rem" />
					</LabelsWrapper>
				</UserWrapper>
			</Wrapper>
		</Card>
	);
};
