import { LicensesSingleUserAssignmentCardSkeleton } from "@components/licenses-single-user-assignment-card";
import { AllocationListWrapper, AllocationUserList } from "./user-rules-tab.styles";

export const UserRulesTabSkeleton = () => {
	return (
		<AllocationListWrapper>
			<AllocationUserList>
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
				<LicensesSingleUserAssignmentCardSkeleton />
			</AllocationUserList>
		</AllocationListWrapper>
	);
};
