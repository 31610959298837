import styled from "styled-components";
import { BreakpointInPx } from "@themes/breakpoint";
import { shade0, shade5 } from "@themes/colors";
import { spacing24, spacing40 } from "@themes/spacing";

export const Wrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	background-color: ${shade5};
	padding: ${spacing24.rem()} ${spacing24.rem()} 0;
	min-width: 19rem;
	@media screen and (min-width: ${BreakpointInPx.medium}px) {
		padding: ${spacing40.rem()} ${spacing40.rem()} 0;
	}

	@media screen and (min-width: ${BreakpointInPx.xlarge}px) {
		grid-template-columns: 24.5rem 1fr;
		padding: 0;
	}
`;

export const ContentBackground = styled.div`
	background-color: ${shade0};
	border-radius: 1.25rem 1.25rem 0 0;
	z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@media screen and (min-width: ${BreakpointInPx.xlarge}px) {
		left: 24.5rem;
		border-radius: 1.25rem 0 0 0;
	}
`;
