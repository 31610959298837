import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query profile_Query {
		Viewer {
			Coaching {
				MyCoachProfile {
					id
					...profileTab_CoachProfileFragment
					...personnelSheetTab_CoachProfileFragment
				}
			}
			Auth {
				currentUser {
					user {
						...profileTab_UserFragment
					}
				}
			}
		}
	}
`;
