import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment cancelAppointmentCoachModal_AvailabilityFragment on Availability {
		id
		booking {
			... on BookedForGroup {
				participants {
					user {
						id
						...userAvatarsCollection_UserFragment
					}
				}
				offer {
					description {
						name
					}
				}
				msTeamsLink
			}
			... on BookedOneToOne {
				participant {
					user {
						id
						...userAvatarsCollection_UserFragment
					}
				}
				offer {
					description {
						name
					}
				}
				msTeamsLink
			}
		}
		data {
			startDateTime
			endDateTime
		}
	}
`;

export const CANCEL_BOOKING_AS_COACH_MUTATION = graphql`
	mutation cancelAppointmentCoachModal_CancelBookingAsCoachMutation(
		$input: CancelBookingAsCoachInput!
	) {
		Coaching {
			cancelBookingAsCoach(input: $input) {
				clientMutationId
			}
		}
	}
`;
