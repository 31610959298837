import { graphql } from "babel-plugin-relay/macro";

export const ANONYMIZE_ACCOUNT_AS_MANAGER_MUTATION = graphql`
	mutation confirmDeleteAccountModal_AnonymizeAccountAsManagerMutation(
		$input: AnonymizeAccountAsOwnerInput!
	) {
		AccountMemberManagement {
			anonymizeAccountAsOwner(input: $input) {
				deletedIds
			}
		}
	}
`;
