import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, Document } from "@contentful/rich-text-types";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "@components/button";
import { ValidatedInputSwitch } from "@components/validated-input-switch";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade20, brandMain100 } from "@themes/colors";
import { H1Span, TkaP1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { SignUpAGBStepFormState, SignUpAGBStepProps } from "./sign-up-agb-step.interface";
import {
	ActionButtonsWrapper,
	FormWrapper,
	InputsWrapper,
	SwitchTextWrapper,
	SwitchWrapper,
	TextCenter,
} from "./sign-up-agb-step.styles";

export const SignUpAGBStep = ({
	onNext,
	onPrevious,
	currentStep,
	maxSteps,
}: SignUpAGBStepProps) => {
	const { t } = useTkaTranslation("registerForm");
	const navigate = useNavigate();

	const form = useFormik<SignUpAGBStepFormState>({
		initialValues: {
			agbAccepted: false,
			adsAccepted: false,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			agbAccepted: Yup.bool()
				.oneOf([true])
				.required(t("register_form.section4ValidationRequiredAgb")),
			adsAccepted: Yup.bool().optional(),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	const showStepInfo = maxSteps !== undefined && currentStep !== undefined;

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				{showStepInfo && (
					<TextCenter>
						<TkaP1Span>
							{currentStep}/{maxSteps}
						</TkaP1Span>
					</TextCenter>
				)}
				<TextCenter>
					<H1Span>{t("register_form.section4Title")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<SwitchTextWrapper>
						<SwitchWrapper>
							<ValidatedInputSwitch
								formikConfig={form}
								name={"agbAccepted"}
								required
							/>
						</SwitchWrapper>
						<TkaP2Span>
							{documentToReactComponents(
								t("register_form.section4AgbText") as unknown as Document,
								{
									renderNode: {
										[INLINES.HYPERLINK]: (node, children) => (
											<TkaP2Span
												tkaColor={brandMain100}
												onClick={() => {
													navigate(node.data.uri);
												}}
											>
												{children}
											</TkaP2Span>
										),
										[BLOCKS.PARAGRAPH]: (node, children) => (
											<span>{children}</span>
										),
									},
									renderText: (text) => <span>{text}</span>,
								},
							)}
						</TkaP2Span>
					</SwitchTextWrapper>
					<SwitchTextWrapper>
						<SwitchWrapper>
							<ValidatedInputSwitch
								formikConfig={form}
								name={"adsAccepted"}
								required
							/>
						</SwitchWrapper>
						<TkaP2Span>{t("register_form.section4AdsOptInText")}</TkaP2Span>
					</SwitchTextWrapper>
				</InputsWrapper>
				<ActionButtonsWrapper>
					<Button
						type="button"
						iconName="arrowLeft"
						colorVersion={"tertiary"}
						onClick={onPrevious}
					/>
					<Button
						fillParent
						label={t("register_form.section4ButtonText")}
						iconName="arrowRight"
					/>
				</ActionButtonsWrapper>
				<TkaP3Span tkaColor={shade20}>{t("register_form.section4Legend")}</TkaP3Span>
			</FormWrapper>
		</form>
	);
};
