import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { useFragment, useMutation } from "react-relay";
import { Dropdown } from "@components/dropdown";
import { Icon } from "@components/icon";
import { InputText } from "@components/input-text";
import { useToast } from "@hooks/useToast";
import { profileTab_CoachProfileFragment$key } from "@relay/profileTab_CoachProfileFragment.graphql";
import { profileTab_editMyProfileDataMutation } from "@relay/profileTab_editMyProfileDataMutation.graphql";
import { profileTab_UserFragment$key } from "@relay/profileTab_UserFragment.graphql";
import {
	COACH_PROFILE_FRAGMENT,
	EDIT_MY_PROFILE_DATA_MUTATION,
	USER_FRAGMENT,
} from "@screens/profile/parts/profile-tab/profile-tab.graphql";
import {
	DeleteButtonWrapper,
	DeleteButtonWrapperWrapper,
	FullWidthWrapper,
	Grid,
	HalfWidthWrapper,
	Hr,
	MobileHalfWidthWrapper,
	Wrapper,
} from "@screens/profile/parts/profile-tab/profile-tab.styles";
import { ProfileTabProps } from "@screens/profile/parts/profile-tab/profile-tab.types";
import { ProfileContext } from "@screens/profile/profile.context";
import { error100, shade10, shade100 } from "@themes/colors";
import { TkaH2Span, TkaH3Span } from "@themes/font-tags";
import { UserAvatar } from "src/components/user-avatar";
import { JobTitlesOptions } from "./profile-tab.consts";
import { ProfileChips } from "../personnel-sheet-tab";

export const ProfileTab = ({ userFragmentRef, coachProfileFragmentRef }: ProfileTabProps) => {
	const { setSaveOnClick } = useContext(ProfileContext);
	const [edit, _] = useMutation<profileTab_editMyProfileDataMutation>(
		EDIT_MY_PROFILE_DATA_MUTATION,
	);
	const { showSuccess } = useToast();
	const coachProfileFragment = useFragment<profileTab_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);
	const userFragment = useFragment<profileTab_UserFragment$key>(USER_FRAGMENT, userFragmentRef);
	const formik = useFormik({
		initialValues: {
			salutations: coachProfileFragment.profileData.basicPersonal.salutation ?? undefined,
			title: coachProfileFragment.profileData.basicPersonal.title ?? undefined,
			firstName:
				coachProfileFragment.profileData.basicPersonal.firstName ??
				userFragment.extension?.firstName ??
				undefined,
			lastName:
				coachProfileFragment.profileData.basicPersonal.lastName ??
				userFragment.extension?.lastName ??
				undefined,
			aboutMe: coachProfileFragment.profileData.description ?? undefined,
			jobTitles: coachProfileFragment.profileData.jobTitles ?? [],
			email: coachProfileFragment.profileData.basicPersonal.email,
			phoneNumber: coachProfileFragment.profileData.basicPersonal.phoneNumber,
			quote: coachProfileFragment.profileData.quote ?? undefined,
			socials: {
				twitter: coachProfileFragment.profileData.socials?.twitter ?? undefined,
				facebook: coachProfileFragment.profileData.socials?.facebook ?? undefined,
				linkedIn: coachProfileFragment.profileData.socials?.linkedIn ?? undefined,
				website: coachProfileFragment.profileData.socials?.website ?? undefined,
				others: coachProfileFragment.profileData.socials?.others ?? [],
			},
			avatarId: coachProfileFragment.profileData.avatar?.id ?? undefined,
		},
		onSubmit: () => {},
	});
	//TODO add other
	const options = {
		salutations: [
			{ label: "Herr", value: "Herr" },
			{ label: "Frau", value: "Frau" },
		],
		title: [{ label: "Dr.", value: "Dr." }],
	};
	const labels = {
		salutations: "Anrede",
		title: "Titel",
		firstName: "Vorname",
		lastName: "Nachname",
		aboutMe: "Über mich",
		jobTitles: "Berufsbezeichnung",
		quote: "Zitat",
		quote2: "Dein liebstes Zitat über dich",
		website: "Website",
		twitter: "X / Twitter",
		linkedIn: "LinkedIn",
		facebook: "Facebook",
	};
	const placeholders = {
		jobTitles: "Berufsbezeichnung...",
		aboutMe: `Erfahrung (worin und mit welchen Kund:innen)​
Wertschöpfung/Beitrag (was bringt mir diese Person als Coach)​`,
		quote: "Gibt es ein Zitat über dich, welches du gerne teilen willst ... ",
		facebook: "Deine Facebook Adresse",
		twitter: "Deine X / Twitter Adresse",
		linkedIn: "Deine LinkedIn Adresse",
		website: "Deine Website Adresse",
	};

	const handleSaveOnClick = () => {
		edit({
			variables: {
				input: {
					profileData: {
						jobTitles: formik.values.jobTitles ?? [],
						socials: formik.values.socials,
						quote: formik.values.quote,
						avatarId: formik.values.avatarId,
						description: formik.values.aboutMe,
						basicPersonal: {
							firstName: formik.values.firstName,
							lastName: formik.values.lastName,
							title: formik.values.title,
							salutation: formik.values.salutations,
							email: coachProfileFragment.profileData.basicPersonal.email,
							phoneNumber: coachProfileFragment.profileData.basicPersonal.phoneNumber,
						},

						languages: coachProfileFragment.profileData.languages,
						studies: coachProfileFragment.profileData.studies ?? undefined,
						certificateFileIds: coachProfileFragment.profileData.certificateFileIds.map(
							(e) => e.id,
						),
						certificateAnswer: coachProfileFragment.profileData.certificateAnswer,
						experience: {
							coachingHours:
								coachProfileFragment.profileData.experience.coachingHours,
							coachingFileIds:
								coachProfileFragment.profileData.experience.coachingFileIds.map(
									(e) => e.id,
								),
							branches: coachProfileFragment.profileData.experience.branches,
							branchesSteps:
								coachProfileFragment.profileData.experience.branchesSteps,
						},
						educationFreeText: coachProfileFragment.profileData.educationFreeText ?? "",
						educationMoreThan150h:
							coachProfileFragment.profileData.educationMoreThan150h ?? false,
						coachingStrongPointsTopics: [],
						methods: [],
						digitalAffinity1:
							coachProfileFragment.profileData.digitalAffinity1 ?? false,
						digitalAffinity2:
							coachProfileFragment.profileData.digitalAffinity2 ?? false,
						personalPhilosophyCoachingStyle:
							coachProfileFragment.profileData.personalPhilosophyCoachingStyle ??
							undefined,
						personalPhilosophyVisionMission:
							coachProfileFragment.profileData.personalPhilosophyVisionMission ??
							undefined,
						referencesCoachingExperienceIds:
							coachProfileFragment.profileData.referencesCoachingExperienceIds.map(
								(e) => e.id,
							),
						referencesPublications:
							coachProfileFragment.profileData.referencesPublications ?? undefined,
					},
				},
			},
			onCompleted: () => {
				showSuccess({ summary: "Erfolgreich gespeichert." });
			},
		});
	};

	useEffect(() => {
		setSaveOnClick(() => handleSaveOnClick);
	}, [formik.values]);

	return (
		<Wrapper>
			<UserAvatar userFragmentRef={userFragment} sizeInRem={9.135} />
			<Grid>
				<HalfWidthWrapper>
					<Dropdown
						label={labels.salutations}
						options={options.salutations}
						onChange={(e) => {
							formik.setFieldValue("salutations", e);
						}}
						value={formik.values.salutations}
					/>
				</HalfWidthWrapper>
				<HalfWidthWrapper>
					<InputText
						label={labels.title}
						value={formik.values.title}
						onChange={(e) => {
							formik.setFieldValue("title", e);
						}}
					/>
				</HalfWidthWrapper>
				<MobileHalfWidthWrapper>
					<InputText
						label={labels.firstName}
						value={formik.values.firstName}
						onChange={(e) => {
							formik.setFieldValue("firstName", e);
						}}
					/>
				</MobileHalfWidthWrapper>
				<MobileHalfWidthWrapper>
					<InputText
						label={labels.lastName}
						value={formik.values.lastName}
						onChange={(e) => {
							formik.setFieldValue("lastName", e);
						}}
					/>
				</MobileHalfWidthWrapper>
				<FullWidthWrapper>
					<InputText
						label={labels.aboutMe}
						placeholder={placeholders.aboutMe}
						textArea={true}
						value={formik.values.aboutMe}
						onChange={(e) => {
							formik.setFieldValue("aboutMe", e);
						}}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<ProfileChips
						options={JobTitlesOptions}
						value={formik.values.jobTitles}
						onChange={(e) => {
							formik.setFieldValue("jobTitles", e.value);
						}}
						onCloseClick={(e) => {
							formik.setFieldValue(
								"jobTitles",
								formik.values.jobTitles?.filter((l) => l !== e),
							);
						}}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{labels.quote}</TkaH2Span>
					<InputText
						label={labels.quote2}
						textArea={true}
						value={formik.values.quote}
						placeholder={placeholders.quote}
						onChange={(e) => {
							formik.setFieldValue("quote", e);
						}}
					/>
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />
				<FullWidthWrapper>
					<InputText
						label={labels.website}
						value={formik.values.socials.website}
						placeholder={placeholders.website}
						icon={"website"}
						onChange={(e) => {
							formik.setFieldValue("socials", {
								...formik.values.socials,
								website: e,
							});
						}}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<InputText
						label={labels.twitter}
						placeholder={placeholders.twitter}
						value={formik.values.socials.twitter}
						icon={"twitter"}
						onChange={(e) => {
							formik.setFieldValue("socials", {
								...formik.values.socials,
								twitter: e,
							});
						}}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<InputText
						label={labels.linkedIn}
						placeholder={placeholders.linkedIn}
						value={formik.values.socials.linkedIn}
						icon={"linkedIn"}
						onChange={(e) => {
							formik.setFieldValue("socials", {
								...formik.values.socials,
								linkedIn: e,
							});
						}}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<InputText
						icon={"facebook"}
						label={labels.facebook}
						placeholder={placeholders.facebook}
						value={formik.values.socials.facebook}
						onChange={(e) => {
							formik.setFieldValue("socials", {
								...formik.values.socials,
								facebook: e,
							});
						}}
					/>
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />
				<DeleteButtonWrapperWrapper>
					<DeleteButtonWrapper>
						<Icon icon={"trash"} sizeInRem={1.5} tkaColor={error100} />
						<TkaH3Span tkaColor={error100}>Profile löschen</TkaH3Span>
					</DeleteButtonWrapper>
				</DeleteButtonWrapperWrapper>
			</Grid>
		</Wrapper>
	);
};
