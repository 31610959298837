/**
 * @generated SignedSource<<04c523541f8dc034842c4ea815de27e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appointmentStatusLabel_AvailabilityFragment$data = {
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly " $fragmentType": "appointmentStatusLabel_AvailabilityFragment";
};
export type appointmentStatusLabel_AvailabilityFragment$key = {
  readonly " $data"?: appointmentStatusLabel_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentStatusLabel_AvailabilityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appointmentStatusLabel_AvailabilityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Availability",
  "abstractKey": null
};

(node as any).hash = "216b42d20b5a42959b5a40345b87854e";

export default node;
