import { useFormik } from "formik";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import "yup-phone-lite";
import { ValidatedInputText } from "@components/validated-input-text/validated-input-text.component";
import { ResidenceDropdown } from "@screens/account/parts/account-tab/parts/residence-dropdown";
import { DROPDOWN_OPTIONS_GERMANY_ONLY } from "@screens/account/parts/account-tab/parts/residence-dropdown/residence-dropdown.consts";
import { Spacing12 } from "@utils/parse-html/parse-html.styles";
import { QUERY_FRAGMENT } from "./private-client-form.graphql";
import {
	InputsWrapper,
	RowSpan2,
	RowSpan4,
	RowSpan6,
	RowSpan6Placeholder,
	RowSpan6Wrapper,
} from "./private-client-form.styles";
import {
	PrivateClientForm as PrivateClientFormClass,
	PrivateClientFormProps,
	PrivateClientFormState,
	PrivateClientFormValues,
} from "./private-client-form.types";
import { Message } from "../message";
import { SalutationDropdown } from "../salutation-dropdown";
import { ValidatedCalendar } from "../validated-calendar";

//TODO: add-translations
const PrivateClientFormComponent = (
	{ initialValues, baseDataFragmentRef, onSubmit }: PrivateClientFormProps,
	ref: ForwardedRef<any>,
) => {
	const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef);

	const countryOtherThanGermany = query.countryCode !== "DE" ? "OTHER" : query.countryCode;
	const form = useFormik<PrivateClientFormState>({
		initialValues: {
			salutation: initialValues?.salutation || query?.salutation || undefined,
			title: initialValues?.title || query?.title || "",
			firstName: initialValues?.firstName || query?.firstName || "",
			lastName: initialValues?.lastName || query?.lastName || "",
			street: initialValues?.street || query?.street || "",
			houseNumber: initialValues?.houseNumber || query?.houseNumber || "",
			city: initialValues?.city || query?.city || "",
			postCode: initialValues?.postCode || query?.postalCode || "",
			country: initialValues?.country || countryOtherThanGermany || "DE",
			phoneNumber: initialValues?.phoneNumber || query?.phoneNumber || "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			salutation: Yup.string()
				.test("undefined", "Bitte wähle eine Anrede aus.", (value) => {
					return value != "Unknown";
				})
				.required("Bitte wähle eine Anrede aus."),
			title: Yup.string(),
			firstName: Yup.string().required("Bitte gib einen Vornamen ein."),
			lastName: Yup.string().required("Bitte gib einen Nachnamen ein."),
			street: Yup.string().required("Bitte gib eine Straße ein."),
			houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
			city: Yup.string().required("Bitte gib einen Ort ein."),
			postCode: Yup.string()
				.when("country", {
					is: (value: string) => value === "CH" || value === "AT",
					then: (schema) =>
						schema
							.length(4, "Bitte gib eine gültige Postleitzahl ein")
							.matches(/^[0-9]{4}/, "Bitte gib eine gültige Postleitzahl ein"),
				})
				.when("country", {
					is: "DE",
					then: (schema) =>
						schema
							.length(5, "Bitte gib eine gültige Postleitzahl ein")
							.matches(/^[0-9]{5}/, "Bitte gib eine gültige Postleitzahl ein"),
				})
				.required("Bitte gib eine Postleitzahl ein."),
			country: Yup.mixed()
				.oneOf(["DE", "AT", "CH"], "Bitte wähle ein gültiges Land aus.")
				.test(
					"is-not-other",
					"Bestellungen sind derzeit nur aus Deutschland möglich.",
					(value) => value !== "OTHER",
				)
				.required("Bitte wähle ein Land aus."),
			dateOfBirth: Yup.date().required("Bitte gib ein Geburtsdatum ein."),
			phoneNumber: Yup.string()
				.phone("DE", "Bitte gib eine gültige Telefonnummer ein.")
				.required("Bitte gib eine Telefonnummer ein."),
		}),
		onSubmit: (values) => {
			onSubmit?.(values as PrivateClientFormValues);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
	}));

	return (
		<form onSubmit={form.handleSubmit}>
			<InputsWrapper>
				<RowSpan6Wrapper>
					<RowSpan2>
						<SalutationDropdown
							formikConfig={form}
							name="salutation"
							label="Anrede*"
							placeholder="Anrede"
						/>
					</RowSpan2>
					<RowSpan4>
						<ValidatedInputText
							formikConfig={form}
							name="title"
							label={"Titel"}
							placeholder={"Titel"}
						/>
					</RowSpan4>
				</RowSpan6Wrapper>
				<RowSpan6Placeholder />
				<RowSpan6>
					<ValidatedInputText
						formikConfig={form}
						name="firstName"
						label={"Name*"}
						placeholder={"Name"}
					/>
				</RowSpan6>
				<RowSpan6>
					<ValidatedInputText
						formikConfig={form}
						name="lastName"
						label={"Nachname*"}
						placeholder={"Nachname"}
					/>
				</RowSpan6>
				<RowSpan6Wrapper>
					<RowSpan4>
						<ValidatedInputText
							formikConfig={form}
							name="street"
							label={"Straße*"}
							placeholder={"Straße"}
						/>
					</RowSpan4>
					<RowSpan2>
						<ValidatedInputText
							formikConfig={form}
							name="houseNumber"
							label={"Hausnummer*"}
							placeholder={"Hausnummer"}
						/>
					</RowSpan2>
				</RowSpan6Wrapper>

				<RowSpan6Wrapper>
					<RowSpan4>
						<ValidatedInputText
							formikConfig={form}
							name="city"
							label={"Ort/Stadt*"}
							placeholder={"Ort/Stadt"}
						/>
					</RowSpan4>
					<RowSpan2>
						<ValidatedInputText
							formikConfig={form}
							name="postCode"
							label={"PLZ*"}
							placeholder={"PLZ"}
						/>
					</RowSpan2>
				</RowSpan6Wrapper>
				<RowSpan6>
					<ResidenceDropdown
						formikConfig={form}
						name="country"
						label="Land"
						disabled
						options={DROPDOWN_OPTIONS_GERMANY_ONLY}
					/>
					<Spacing12 />
					{form.values.country === "OTHER" && (
						<Message
							highlighted
							severity="neutral"
							summary="Auslandsbestellungen"
							detail="Aktuell sind Bestellungen nur innerhalb Deutschlands möglich. Möchtest du aus dem Ausland bestellen? Dann wende dich bitte an info@constellation.academy."
						/>
					)}
				</RowSpan6>
				<RowSpan6>
					<ValidatedCalendar
						formikConfig={form}
						name="dateOfBirth"
						label={"Geburtsdatum*"}
						placeholder={"01.01.1990"}
					/>
				</RowSpan6>
				<RowSpan6>
					<ValidatedInputText
						formikConfig={form}
						name="phoneNumber"
						label={"Telefonnummer*"}
						placeholder={"+4917611223344"}
					/>
				</RowSpan6>
			</InputsWrapper>
		</form>
	);
};

export const PrivateClientForm = forwardRef(PrivateClientFormComponent);
export type PrivateClientForm = PrivateClientFormClass;
