import { TkaColor, shade20, shade100, error100 } from "@themes/colors";

export const getIconColor = (hasText: boolean, disabled: boolean): TkaColor => {
	return disabled || !hasText ? shade20 : shade100;
};

export const getLabelColor = (error: boolean, disabled: boolean): TkaColor => {
	if (error) return error100;
	return disabled ? shade20 : shade100;
};
