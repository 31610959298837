import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { Dropdown } from "@components/dropdown";
import { useAuthContext } from "@hooks/use-auth-context";
import { accountsDropdown_CurrentUserFragment$key } from "@relay/accountsDropdown_CurrentUserFragment.graphql";
import { selectCurrentAccountId } from "@slices/AuthSlice";
import { CURRENT_USER_FRAGMENT } from "./accounts-dropdown.graphql";
import { AccountsDropdownProps } from "./accounts-dropdown.types";
import { getAccountsOptions } from "./accounts-dropdown.utils";

export const AccountsDropdown = ({
	label,
	onAccountSelected,
	currentUserFragmentRef,
}: AccountsDropdownProps) => {
	const { accounts } = useFragment<accountsDropdown_CurrentUserFragment$key>(
		CURRENT_USER_FRAGMENT,
		currentUserFragmentRef,
	);

	const { switchAccount } = useAuthContext();

	const currentAccountId = useSelector(selectCurrentAccountId);

	const handleOnChange = (value: string) => {
		switchAccount(value);
		onAccountSelected?.();
	};

	const options = useMemo(() => getAccountsOptions(accounts), [accounts]);

	return (
		<Dropdown
			label={label}
			options={options}
			value={currentAccountId}
			onChange={handleOnChange}
		/>
	);
};
