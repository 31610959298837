import { useFragment } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { success0, success100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT } from "./create-group-success-modal.graphql";
import { CreateGroupSuccessModalProps } from "./create-group-success-modal.types";

export const CreateGroupSuccessModal = ({
	isVisible,
	userInAccountGroupWrapperFragmentRef,
	onDismiss,
	onCreateAnotherGroup,
}: CreateGroupSuccessModalProps) => {
	const group = useFragment(
		USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT,
		userInAccountGroupWrapperFragmentRef ?? null,
	);

	const groupName = group?.group.name ?? "";
	const invitedMembers = group?.memberCount ?? 0;
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Schließen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel="Weitere Gruppe erstellen"
				onPrimaryButtonClick={onCreateAnotherGroup}
			>
				<IconHeadlineButtonTemplate
					iconName="checkCircle"
					iconColor={success100}
					iconBackgroundColor={success0}
					title="Gruppe erfolgreich erstellt"
					subtitle="Ihre neue Gruppe ist jetzt bereit"
				/>

				<TkaP2Span>
					Die Gruppe '{groupName}' wurde erstellt. Es wurden {invitedMembers} Benutzer
					eingeladen. Die Benutzer haben Zugriff auf alle Gruppenressourcen und
					-aktivitäten.
				</TkaP2Span>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
