import { Dropdown } from "primereact/dropdown";
import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";

export const Text = styled(TkaP2Span)<{ invisible?: boolean }>`
	opacity: ${({ invisible }) => (invisible ? 0 : 1)};
	${tw`
        select-none 
        whitespace-nowrap
    `}
`;

export const Spacer = tw.div`w-[5rem]`;

export const Wrapper = styled.div<{ backgroundColor: TkaColor; borderColor: TkaColor }>`
	${tw`
	flex
	items-center
        px-12
        py-8
        cursor-pointer
        rounded-10
        shadow-[inset_0_0_0_0.0625rem]
		shadow-shade-100
        mb-8
		h-[40px]
    `}
	background-color: ${({ backgroundColor }) => backgroundColor};
	box-shadow: 0 0 0 1px ${({ borderColor }) => borderColor} inset;
`;

export const StyledDropdown = styled(Dropdown)<{ isOver?: boolean }>`
	border: none !important;
	background: transparent !important;
	padding: 0 !important;
	width: 100%;

	.p-dropdown-label-empty {
		visibility: visible !important;
		height: 100%;
	}

	.p-dropdown-trigger {
		position: absolute;
		width: 100%;
		visibility: hidden;
	}
`;
