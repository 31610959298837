import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	md:flex-row
	gap-32
	mt-24
`;

export const AvatarWrapper = tw.div`
	self-center
	md:self-start
`;

export const RightColWrapper = tw.div`
	flex
	flex-col
	flex-1
	gap-24
`;

export const AccountTypeWrapper = tw.div`
	flex
	flex-row
	gap-32
	items-center
`;

export const PermanentActionsButton = tw.button`
	flex
	items-center
	gap-8
	text-error-100
	self-start
`;
