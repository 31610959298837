import { useFragment } from "react-relay";
import { dimensionSection_AVGSPotentialAnalysisDimensionFragment$key } from "@relay/dimensionSection_AVGSPotentialAnalysisDimensionFragment.graphql";
import {
	QuestionWrapperClass,
	WrapperClass,
} from "@screens/avgs-potential-analysis/parts/dimension-section/dimension-section.styles";
import { DimensionSectionProps } from "@screens/avgs-potential-analysis/parts/dimension-section/dimension-section.types";
import { QuestionSection } from "@screens/avgs-potential-analysis/parts/question-section";
import { P2Span } from "@themes/font-tags";
import { DIMENSION_SECTION_ID } from "./dimension-section.consts";
import { POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT } from "./dimension-section.graphql";
import { isQuestionAnswered } from "../avgs-potential-analysis-navigation/avgs-potential-analysis-navigation.utils";

export const DimensionSection = ({
	potentialAnalysisDimensionDataFragmentRef,
}: DimensionSectionProps) => {
	const { dimensionText, questions } =
		useFragment<dimensionSection_AVGSPotentialAnalysisDimensionFragment$key>(
			POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT,
			potentialAnalysisDimensionDataFragmentRef,
		);

	const firstUnansweredQuestion = questions?.find((question) => !isQuestionAnswered(question));

	return (
		<div className={WrapperClass} id={DIMENSION_SECTION_ID}>
			<P2Span>{dimensionText}</P2Span>
			<div className={QuestionWrapperClass}>
				{questions.map((question) => (
					<QuestionSection
						key={question.id}
						isActive={question.id === firstUnansweredQuestion?.id}
						potentialAnalysisQuestionFragmentRef={question}
					/>
				))}
			</div>
		</div>
	);
};
