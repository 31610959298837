import { FC, useRef, useState } from "react";
import { DialogTemplate } from "@components/dialog-template";
import { InputText } from "@components/input-text";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import {
	InputIcon,
	InputWrapper,
	Wrapper,
} from "@screens/account/parts/account-tab/parts/delete-account-modals-flow/parts/confirm-delete-account-modal/confirm-delete-account-modal.styles";
import { ConfirmDeleteExceptionModalProps } from "@screens/my-availabilities/parts/confirm-delete-exception-modal/confirm-delete-exception-modal.types";
import { H1Span, TkaP2Span } from "@themes/font-tags";

export const ConfirmDeleteExceptionModal: FC<ConfirmDeleteExceptionModalProps> = ({
	isVisible,
	onDismiss,
	onSuccess,
	calendarWeek,
	deleteException,
	onSubmit,
}) => {
	const [disabled, setDisabled] = useState(true);
	const countdownRef = useRef<NodeJS.Timeout>();
	const [countdown, setCountdown] = useState<number>();
	const [touched, setTouched] = useState(false);
	const [textToBeDeleted, setTextToBeDeleted] = useState("");

	const handlePrimaryOnClick = () => {
		deleteException();
		onSuccess?.();
		onSubmit();
	};
	let status: "default" | "error" | "success" = "default";
	if (textToBeDeleted !== calendarWeek) {
		status = "error";
	} else {
		status = "success";
	}
	if (!touched) {
		status = "default";
	}

	const handleOnChange = (value: string | undefined) => {
		setTouched(true);
		if (countdownRef.current) {
			clearInterval(countdownRef.current);
		}

		if (value === calendarWeek) {
			countdownRef.current = setInterval(() => {
				setCountdown((prevCountdown) => {
					if (prevCountdown === 0) {
						clearInterval(countdownRef.current!);
						setDisabled(false);
						return undefined;
					}
					return (prevCountdown ?? 6) - 1;
				});
			}, 1000);
		} else {
			setDisabled(true);
		}
		setTextToBeDeleted(value || "");
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				maxWidthRem={25}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				secondaryButtonStretch={false}
				primaryButtonDisabled={disabled}
				primaryButtonLabel={`${calendarWeek} endgültig löschen ${
					countdown ? `(${countdown})` : ""
				}`}
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={handlePrimaryOnClick}
				primaryButtonStretch={true}
			>
				<Wrapper>
					<H1Span>Willst du die “{calendarWeek}” wirklich löschen?</H1Span>
					<TkaP2Span>
						Nach Bestätigung wird die "{calendarWeek}" und die damit zusammengehörenden
						Informationen gelöscht. Diese können dann auch nicht wiederhergestellt
						werden.
					</TkaP2Span>
					<InputWrapper>
						{status !== "default" && (
							<InputIcon background={status}>
								<i
									className={`pi ${
										status === "error" ? "pi-times" : "pi-check"
									} text-shade-0`}
								></i>
							</InputIcon>
						)}
						<InputText
							icon={status !== "default" ? "add" : undefined}
							value={textToBeDeleted}
							onChange={handleOnChange}
							name="textToBeDeleted"
							placeholder="Name der KW zur Bestätigung..."
							smallTextBelow={`Schreibe in das Feld „${calendarWeek}“, um die KW endgültig zu löschen. Dieses kann später nicht wiederhergestellt werden.`}
							status={status}
						/>
					</InputWrapper>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
