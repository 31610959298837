/**
 * @generated SignedSource<<bbb062bc967a6c5557e1a1605c7b0a0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePotentialAnalysisClickoutInput = {
  clientMutationId?: string | null;
  shortTest: boolean;
};
export type startPotentialAnalysisModal_ClickoutMutation$variables = {
  input: CreatePotentialAnalysisClickoutInput;
};
export type startPotentialAnalysisModal_ClickoutMutation$data = {
  readonly PotentialAnalysis: {
    readonly createPotentialAnalysisClickout: {
      readonly clickout: string;
    } | null;
  };
};
export type startPotentialAnalysisModal_ClickoutMutation = {
  response: startPotentialAnalysisModal_ClickoutMutation$data;
  variables: startPotentialAnalysisModal_ClickoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PotentialAnalysisMutations",
    "kind": "LinkedField",
    "name": "PotentialAnalysis",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "CreatePotentialAnalysisClickoutPayload",
        "kind": "LinkedField",
        "name": "createPotentialAnalysisClickout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clickout",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "startPotentialAnalysisModal_ClickoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "startPotentialAnalysisModal_ClickoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6ffc16762f7a8a87d4a5e5a279b31df3",
    "id": null,
    "metadata": {},
    "name": "startPotentialAnalysisModal_ClickoutMutation",
    "operationKind": "mutation",
    "text": "mutation startPotentialAnalysisModal_ClickoutMutation(\n  $input: CreatePotentialAnalysisClickoutInput!\n) {\n  PotentialAnalysis {\n    createPotentialAnalysisClickout(input: $input) {\n      clickout\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bea6d5b9d69efb2b4451091e37d0b714";

export default node;
