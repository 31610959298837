import SuccessImage from "@assets/woman_register_success.svg";
import { AsyncContentSubmissionFilePreview } from "@components/async-content-submission-file-preview";
import { Image } from "@components/image";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade100 } from "@themes/colors";
import { ImageSizeRem } from "./async-content-submission-file-success.const";
import { AsyncContentSubmissionFileSuccessProps } from "./async-content-submission-file-success.interface";
import {
	StyledElementContainer,
	SubmissionWrapper,
	Subtitle,
	Title,
} from "./async-content-submission-file-success.styles";

export const AsyncContentSubmissionFileSuccess = ({
	fileName,
	url,
}: AsyncContentSubmissionFileSuccessProps) => {
	const { t } = useTkaTranslation("uploadElementScreen");

	const successTitle = t("upload_element_screen.submissionSuccessTitle");
	const successSubtitle = t("upload_element_screen.submissionSuccessSubtitle");

	return (
		<StyledElementContainer>
			<Image
				src={SuccessImage}
				alt={"success-image"}
				widthRem={ImageSizeRem}
				heightRem={ImageSizeRem}
			/>
			<Title>{successTitle}</Title>
			<Subtitle tkaColor={shade100}>{successSubtitle}</Subtitle>
			<SubmissionWrapper>
				<AsyncContentSubmissionFilePreview fileName={fileName} url={url} />
			</SubmissionWrapper>
		</StyledElementContainer>
	);
};
