import React, { useContext } from "react";
import { Button } from "@components/button";
import { Divider } from "@components/divider";
import { InputSwitch } from "@components/input-switch";
import { usePermissions } from "@hooks/use-permissions";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { type LearnableKind } from "@relay/gridView_Query.graphql";
import { LicensesScreenContext } from "@screens/licenses/licenses.context";
import {
	ButtonWrapper,
	FilterHeadline,
	InputSwitchWrapper,
	Wrapper,
} from "@screens/offers/parts/filters-modal/filters-modal.styles";
import { shade100 } from "@themes/colors";
import { TkaH3Span, TkaP2Span } from "@themes/font-tags";
import { FiltersModalProps } from "./filters-modal.types";

export const FiltersModal = ({ onClose: handleOnClose }: FiltersModalProps) => {
	const { t } = useTkaTranslation("offers");
	const { filters, helpers } = useContext(LicensesScreenContext);
	const { isBusinessAccount } = usePermissions();
	const buildIsChecked = (kind: LearnableKind) => filters.kinds.includes(kind);
	const handleOnChange = {
		coachingOneToOne: () => {
			helpers.toggleKind("GroupCoaching");
		},
		coachingGroup: () => {
			helpers.toggleKind("GroupCoaching");
		},
		educational: () => {
			helpers.toggleKind("Course");
		},
		microLearning: () => {
			helpers.toggleKind("MicroLearning");
		},
		availableLicenses: () => {
			helpers.toggleLicensesAvailable();
		},
		usedLicenses: () => {
			helpers.toggleLicensesConsumed();
		},
	};

	return (
		<Wrapper>
			<FilterHeadline>{t("Filter")}</FilterHeadline>
			<TkaH3Span tkaColor={shade100}>{t("Angebote anzeigen")}</TkaH3Span>
			<InputSwitchWrapper>
				<TkaP2Span tkaColor={shade100}>{t("Coaching")}</TkaP2Span>
				<InputSwitch
					checked={buildIsChecked("OneToOneCoaching")}
					onChange={handleOnChange.coachingOneToOne}
				/>
			</InputSwitchWrapper>
			{isBusinessAccount && (
				<InputSwitchWrapper>
					<TkaP2Span tkaColor={shade100}>{t("Gruppen Coaching")}</TkaP2Span>
					<InputSwitch
						checked={buildIsChecked("GroupCoaching")}
						onChange={handleOnChange.coachingGroup}
					/>
				</InputSwitchWrapper>
			)}
			<InputSwitchWrapper>
				<TkaP2Span tkaColor={shade100}>{t("Weiterbildungen")}</TkaP2Span>
				<InputSwitch
					checked={buildIsChecked("Course")}
					onChange={handleOnChange.educational}
				/>
			</InputSwitchWrapper>
			<InputSwitchWrapper>
				<TkaP2Span tkaColor={shade100}>{t("Micro Learning")}</TkaP2Span>
				<InputSwitch
					checked={buildIsChecked("MicroLearning")}
					onChange={handleOnChange.microLearning}
				/>
			</InputSwitchWrapper>
			<Divider />
			<InputSwitchWrapper>
				<TkaP2Span tkaColor={shade100}>{t("Lizenzen verfügbar")}</TkaP2Span>
				<InputSwitch
					checked={filters.licensesAvailable === true}
					onChange={handleOnChange.availableLicenses}
				/>
			</InputSwitchWrapper>
			<Divider />
			<InputSwitchWrapper>
				<TkaP2Span tkaColor={shade100}>{t("Lizenzen aufgebraucht")}</TkaP2Span>
				<InputSwitch
					checked={filters.licensesConsumed === true}
					onChange={handleOnChange.usedLicenses}
				/>
			</InputSwitchWrapper>
			<ButtonWrapper>
				<Button
					className={"ml-auto"}
					onClick={handleOnClose}
					colorVersion={"tertiary"}
					label={t("Schließen")}
				/>
			</ButtonWrapper>
		</Wrapper>
	);
};
