import tw from "twin.macro";
import { TkaP3Span } from "@themes/font-tags";

export const CardWrapper = tw.div`
w-full
md:w-[11.875rem]
lg:w-[13.75rem]
xl:w-[15.5rem]
overflow-hidden
bg-onBrandMain
h-[20rem]
px-[0.75rem]
pt-[1rem]
pb-[1.5rem]
rounded-[0.75rem]
mx-[0.25rem]
`;

export const CardHeader = tw.div`
flex
items-center
gap-[0.5rem]`;

export const NameWrapper = tw.div`
flex
flex-col`;

export const Text = tw(TkaP3Span)``;
