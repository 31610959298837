import { shade20 } from "@themes/colors";
import {
	HeaderWrapper,
	HeaderDataWrapper,
	Wrapper,
	HeaderNameWrapper,
} from "./licenses-account-group-assignment-card.styles";
import { Card } from "../card";
import { Skeleton } from "../skeleton";

export const LicensesAccountGroupAssignmentCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={1} borderColor={shade20}>
			<Wrapper>
				<HeaderWrapper>
					<HeaderDataWrapper>
						<Skeleton width="3.125rem" height="1.625rem" />
						<HeaderNameWrapper>
							<Skeleton width="8rem" height="1.25rem" />
							<Skeleton width="14rem" height="1.125rem" className="mt-2" />
						</HeaderNameWrapper>
					</HeaderDataWrapper>
				</HeaderWrapper>
			</Wrapper>
		</Card>
	);
};
