/**
 * @generated SignedSource<<e71884d49870ad18154339136d6b32b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type standardInviteForm_QueryFragment$data = {
  readonly AccountMemberManagement: {
    readonly UserInAccountGroupWrappers: {
      readonly userInAccountGroupWrappers: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly group: {
              readonly id: string;
              readonly name: string;
            };
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "standardInviteForm_QueryFragment";
};
export type standardInviteForm_QueryFragment$key = {
  readonly " $data"?: standardInviteForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"standardInviteForm_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "AccountMemberManagement",
  "UserInAccountGroupWrappers",
  "userInAccountGroupWrappers"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "doCalcMemberCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByName"
    },
    {
      "defaultValue": 200,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeOwner"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeUser"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./standardInviteFormRefetchQuery.graphql')
    }
  },
  "name": "standardInviteForm_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountMemberManagementQueries",
      "kind": "LinkedField",
      "name": "AccountMemberManagement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "doCalcMemberCount",
              "variableName": "doCalcMemberCount"
            },
            {
              "kind": "Variable",
              "name": "filterByName",
              "variableName": "filterByName"
            },
            {
              "kind": "Variable",
              "name": "includeOwner",
              "variableName": "includeOwner"
            },
            {
              "kind": "Variable",
              "name": "includeUser",
              "variableName": "includeUser"
            }
          ],
          "concreteType": "UserInAccountGroupWrappersResult",
          "kind": "LinkedField",
          "name": "UserInAccountGroupWrappers",
          "plural": false,
          "selections": [
            {
              "alias": "userInAccountGroupWrappers",
              "args": null,
              "concreteType": "UserInAccountGroupWrapperConnection",
              "kind": "LinkedField",
              "name": "__filterModal_userInAccountGroupWrappers_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserInAccountGroupWrapperEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserInAccountGroupWrapper",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserInAccountGroup",
                          "kind": "LinkedField",
                          "name": "group",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "id",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7fba966bf48690f595774d8c998ab40c";

export default node;
