import { TkaH2Span, TkaP1Span } from "@themes/font-tags";
import { parseHtml } from "@utils/parse-html";
import { TextParagraphProps } from "./text-paragraph.interface";
import { HeadlineWrapper, TextWrapper } from "./text-paragraph.styles";

export const TextParagraph = ({ title, html, headline }: TextParagraphProps) => (
	<div>
		{headline && (
			<HeadlineWrapper>
				<TkaH2Span>{headline}</TkaH2Span>
			</HeadlineWrapper>
		)}
		<TkaH2Span>{title}</TkaH2Span>
		<TextWrapper>
			<TkaP1Span>{parseHtml(html)}</TkaP1Span>
		</TextWrapper>
	</div>
);
