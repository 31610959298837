import { useNavigate } from "react-router-dom";

import { Button } from "@components/button";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { Path } from "@router/index";
import { TkaP1Span, H1Span } from "@themes/font-tags";
import { Wrapper } from "./activate-success-step.styles";

export const ActivateSuccessStep = () => {
	const { t } = useTkaTranslation("activateScreen");
	const navigate = useNavigate();
	const handleGoToLoginOnClick = () => {
		navigate(Path.login.path);
	};
	return (
		<Wrapper>
			<H1Span>{t("activate_screen.successTitle")}</H1Span>
			<TkaP1Span>{t("activate_screen.successBody")}</TkaP1Span>
			<Button
				fillParent
				label={t("activate_screen.successButtonText")}
				iconName="arrowRight"
				onClick={handleGoToLoginOnClick}
			/>
		</Wrapper>
	);
};
