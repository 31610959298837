import {
	brandMain100,
	brandMain40,
	brandMain80,
	brandStrong100,
	brandStrong40,
	error100,
	error20,
	info100,
	info20,
	onBrandMain,
	onBrandStrong,
	shade10,
	shade40,
	shade80,
	success100,
	success20,
} from "@themes/colors";
import { StyledTagProps, LabelSeverity } from "./label.types";

export const getTextColor = (severity: LabelSeverity) => {
	switch (severity) {
		case "brandMain":
			return onBrandMain;
		case "brandStrong":
			return onBrandStrong;
		case "info":
			return info100;
		case "success":
			return success100;
		case "error":
			return error100;
		default:
			return shade80;
	}
};

export const getBackgroundColor = ({ labelSeverity }: StyledTagProps) => {
	switch (labelSeverity) {
		case "brandMain":
			return brandMain100;
		case "brandStrong":
			return brandStrong100;
		case "info":
			return info20;
		case "success":
			return success20;
		case "error":
			return error20;
		default:
			return shade10;
	}
};

export const getCloseIconColor = (severity: LabelSeverity) => {
	switch (severity) {
		case "brandMain":
			return brandMain40;
		case "brandStrong":
			return brandStrong100;
		case "info":
			return info20;
		case "success":
			return success20;
		case "error":
			return error20;
		default:
			return shade10;
	}
};

export const getClosableBackgroundColor = ({
	labelSeverity,
}: Pick<StyledTagProps, "labelSeverity">) => {
	switch (labelSeverity) {
		case "brandMain":
			return brandMain80;
		case "brandStrong":
			return brandStrong40;
		case "info":
			return info100;
		case "success":
			return success100;
		case "error":
			return error100;
		default:
			return shade40;
	}
};
