import { useFormik } from "formik";
import { FC, useContext, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { ValidatedInputChips } from "@components/validated-input-chips";
import { ValidatedInputText } from "@components/validated-input-text";
import { editGroupModal_EditUserInAccountGroupAndAddOrInviteUsersMutation } from "@relay/editGroupModal_EditUserInAccountGroupAndAddOrInviteUsersMutation.graphql";
import { AccountContext } from "@screens/account/account.context";
import { H1Span } from "@themes/font-tags";
import {
	EDIT_USER_IN_ACCOUNT_GROUP_AND_ADD_OR_INVITE_USER_MUTATION,
	USER_IN_ACCOUT_GROUP_FRAGMENT,
} from "./edit-group-modal.graphql";
import { Wrapper } from "./edit-group-modal.styles";
import {
	EditGroupModalFormState,
	EditGroupModalProps,
	EditGroupMutationResponse,
} from "./edit-group-modal.types";
import { TitleWrapper } from "../create-group-modal/create-group-modal.styles";
import { EditGroupSuccessModal } from "../edit-group-success-modal";

// TODO: add-translations
export const EditGroupModal: FC<EditGroupModalProps> = ({
	isVisible,
	onDismiss,
	groupMembersConnectionId,
	userInAccountGroupFragmentRef,
}) => {
	const group = useFragment(USER_IN_ACCOUT_GROUP_FRAGMENT, userInAccountGroupFragmentRef ?? null);

	const [
		editUserInAccountGroupAndAddOrInviteUsersMutation,
		isEditingUserInAccountGroupAndAddOrInviteUsersMutation,
	] = useMutation<editGroupModal_EditUserInAccountGroupAndAddOrInviteUsersMutation>(
		EDIT_USER_IN_ACCOUNT_GROUP_AND_ADD_OR_INVITE_USER_MUTATION,
	);

	const { membersConnectionIds } = useContext(AccountContext);

	const [editGroupMutationResponse, setEditGroupMutationResponse] =
		useState<EditGroupMutationResponse>();

	const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false);

	const groupName = group?.name ?? "";

	const handleEditGroup = (values: EditGroupModalFormState) => {
		const id = group?.id;
		id &&
			editUserInAccountGroupAndAddOrInviteUsersMutation({
				variables: {
					input: {
						id,
						newName: values.groupName,
						emails: values.emails,
					},
					membersConnectionIds: groupMembersConnectionId
						? [groupMembersConnectionId]
						: membersConnectionIds,
				},
				onCompleted: (response) => {
					setIsVisibleSuccessModal(true);
					setEditGroupMutationResponse(
						response.AccountMemberManagement
							.editUserInAccountGroupAndAddOrInviteUsersMutation,
					);
				},
			});
	};

	const form = useFormik<EditGroupModalFormState>({
		initialValues: {
			groupName,
			emails: [],
			reinitialize: isVisible,
		},
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			groupName: Yup.string().required("Bitte gib einen Gruppennamen ein"),
			emails: Yup.array().of(
				Yup.string()
					.email("Bitte gib eine gültige E-Mail Adresse ein")
					.required("Bitte gib eine E-Mail Adresse ein"),
			),
		}),
		onSubmit: handleEditGroup,
	});

	const hanldeOnDismissSuccessModal = () => {
		setIsVisibleSuccessModal(false);
		onDismiss?.();
	};

	return !isVisibleSuccessModal ? (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false} onDismiss={onDismiss}>
			<form onSubmit={form.handleSubmit}>
				<DialogTemplate
					secondaryButtonLabel="Schließen"
					onSecondaryButtonClick={onDismiss}
					primaryButtonLabel="Speichern"
					primaryButtonIconName="arrowRight"
					primaryButtonLoading={isEditingUserInAccountGroupAndAddOrInviteUsersMutation}
				>
					<Wrapper>
						<TitleWrapper>
							<H1Span>{groupName} bearbeiten</H1Span>
						</TitleWrapper>
						<ValidatedInputText
							formikConfig={form}
							name="groupName"
							label="Gruppenname"
							placeholder="z.B. IT, UX, Management,..."
						/>
						<ValidatedInputChips
							addOnBlur
							allowDuplicates={false}
							formikConfig={form}
							name="emails"
							label="Benutzer hinzufügen"
							placeholder="Benutzer E-Mail..."
							icon="user"
						/>
					</Wrapper>
				</DialogTemplate>
			</form>
		</ResponsiveBottomSheetModal>
	) : (
		<EditGroupSuccessModal
			isVisible={isVisibleSuccessModal}
			onDismiss={hanldeOnDismissSuccessModal}
			userInAccountGroupWrapperFragmentRef={editGroupMutationResponse?.group}
		/>
	);
};
