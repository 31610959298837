import { useNavigate } from "react-router-dom";
import { TkaL1Span } from "@themes/font-tags";
import { BottomTextWrapper, ButtonsWrapper, Wrapper } from "./bottom-navigation-template.styles";
import { BottomNavigationTemplateProps } from "./bottom-navigation-template.types";
import { Button } from "../button";

export const BottomNavigationTemplate = ({
	actionButtonLabel,
	actionButtonIconName,
	actionButtonDisabled,
	actionButtonLoading,
	actionButtonColorVersion,
	onActionButtonClick,
	actionButton,
	canGoBack,
	backDisabled,
	onBackClick,
	secondaryButton,
	bottomText,
	bottomNode,
}: BottomNavigationTemplateProps) => {
	const navigate = useNavigate();

	const handleOnBackClick = () => {
		if (onBackClick) {
			return onBackClick();
		}
		navigate(-1);
	};

	return (
		<Wrapper>
			<ButtonsWrapper>
				{secondaryButton ??
					(canGoBack && (
						<Button
							colorVersion="tertiary"
							iconName="arrowLeft"
							disabled={backDisabled}
							onClick={handleOnBackClick}
						/>
					))}
				{actionButton ?? (
					<Button
						label={actionButtonLabel}
						iconName={actionButtonIconName}
						disabled={actionButtonDisabled}
						loading={actionButtonLoading}
						colorVersion={actionButtonColorVersion}
						onClick={onActionButtonClick}
					/>
				)}
			</ButtonsWrapper>
			{bottomNode ??
				(bottomText && (
					<BottomTextWrapper>
						<TkaL1Span>{bottomText}</TkaL1Span>
					</BottomTextWrapper>
				))}
		</Wrapper>
	);
};
