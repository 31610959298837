/**
 * @generated SignedSource<<3919f60e4539f55f5a662454c1288891>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type batchEditMembers_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignGroupModal_QueryFragment">;
  readonly " $fragmentType": "batchEditMembers_QueryFragment";
};
export type batchEditMembers_QueryFragment$key = {
  readonly " $data"?: batchEditMembers_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"batchEditMembers_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./batchEditMembersRefetchQuery.graphql')
    }
  },
  "name": "batchEditMembers_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "assignGroupModal_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ae4109c5fdd8d7e12df425d3080b0b5d";

export default node;
