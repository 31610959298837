import { LabelSkeleton } from "@components/label/label.skeleton";
import { ProgressBar } from "@components/progress-bar";
import { Skeleton } from "@components/skeleton";
import { ImageWidth, ProgressUnit } from "./root-progress.const";
import { ImageTitle, MainWrapper, TaglineTitle } from "./root-progress.styles";

export const RootProgressSkeleton = () => {
	const progressValue = 0;
	return (
		<MainWrapper>
			<ImageTitle>
				<div>
					<Skeleton width={ImageWidth} height="100%" />
				</div>
				<TaglineTitle>
					<LabelSkeleton />
					<Skeleton width="10rem" />
				</TaglineTitle>
			</ImageTitle>
			<ProgressBar
				value={progressValue}
				colorVersion={"profile"}
				labelRight={`${progressValue} ${ProgressUnit}`}
			/>
		</MainWrapper>
	);
};
