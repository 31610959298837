import styled from "styled-components";
import tw from "twin.macro";
import { brandStrong80 } from "@themes/colors";

export const TitleWrapper = styled.div`
	mark {
		background-color: ${brandStrong80};
	}
	${tw`
    flex
	flex-col
	gap-4`}
`;
