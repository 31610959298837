import { Suspense, useContext, useEffect, useState } from "react";
import { useMutation, usePreloadedQuery, useQueryLoader } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@components/button";
import { CertificateCard } from "@components/certificate-card";
import { InstructorsComponent } from "@components/instructors-component";
import { LicenseModal } from "@components/license-modal";
import { OrderIhkFlow } from "@components/order-ihk-flow";
import { TextParagraph } from "@components/text-paragraph";
import { VideoTrailer } from "@components/video-trailer";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { rootOverview_BookTreeMutation } from "@relay/rootOverview_BookTreeMutation.graphql";
import { rootOverview_GetRootOverviewQuery } from "@relay/rootOverview_GetRootOverviewQuery.graphql";
import { BOOK_TREE_MUTATION, QUERY } from "@screens/root/root-overview/root-overview.graphql";
import { LicenseRequiredCard } from "src/components/license-required-card";
import {
	ContentSectionId,
	GoalsSectionId,
	TrailerSectionId,
	TrainerSectionId,
} from "./root-overview.const";
import {
	RootOverviewWithPreloadedQueryProps,
	RootOverviewWithRootIdProps,
} from "./root-overview.interface";
import { RootOverviewSkeleton } from "./root-overview.skeleton";
import {
	CertificateWrapper,
	ContentSection,
	DefaultSection,
	GoalsSection,
	TextParagraphWrapper,
	Title,
	TitlePointsWrapper,
	TrailerSection,
	Wrapper,
} from "./root-overview.styles";
import { RootScreenContext } from "../root.context";

export const RootOverviewWithPreloadedQuery = ({
	queryRef,
}: RootOverviewWithPreloadedQueryProps) => {
	const { t } = useTkaTranslation("courseScreen");
	const { rootId } = useParams();

	const { setSpyEnabled } = useContext(RootScreenContext);
	const [showHasLicenseModal, setShowHasLicenseModal] = useState(false);

	const { node } = usePreloadedQuery<rootOverview_GetRootOverviewQuery>(QUERY, queryRef);
	const [bookTree] = useMutation<rootOverview_BookTreeMutation>(BOOK_TREE_MUTATION);

	const navigate = useNavigate();

	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleOrderOnClick = () => {
		setIsModalVisible(true);
	};

	const handleOnDismiss = () => {
		setIsModalVisible(false);
	};

	const handleShowLicenseModal = () => {
		setShowHasLicenseModal(true);
	};
	const handleLicenseModalOnDismiss = () => {
		setShowHasLicenseModal(false);
	};
	const handleUseLicenseOnClick = () => {
		if (!rootId) return;
		setShowHasLicenseModal(false);
		bookTree({
			variables: {
				input: {
					rootId,
				},
			},
		});
	};

	useEffect(() => {
		setSpyEnabled(true);
		return () => {
			setSpyEnabled(false);
		};
	}, []);

	const title = t("course_screen.overviewTitle");
	const contentTitle = t("course_screen.courseDescriptionTitle");
	const videoTitle = t("course_screen.trailerTitle");
	const goalsTitle = t("course_screen.goalsTitle");

	const isIHK = node?.structureDefinition?.extension?.reducedData?.isIHK;
	const availableLicensesCount =
		node?.structureDefinition?.extension?.extension?.licenseAvailability?.numAvailable ?? 0;
	const canOrderIHK = node?.structureDefinition?.extension?.ihkState === "ReadyToOrder";

	const rootName = node?.structureDefinition?.title ?? "";
	const goalsHtml = node?.structureDefinition?.extension?.learnGoals;

	const videoUrl = node?.structureDefinition?.extension?.trailer?.url;
	const needLicense = node?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const hasInstructors = (node?.instructorsV2?.edges?.length ?? 0) > 0;

	const handleBuyRootOnClick = () => {
		const link = node?.structureDefinition?.extension?.cartClickout?.relativeLink;
		link && navigate(link);
	};

	const ihkCertificate = isIHK && node.id && (
		<CertificateWrapper>
			<CertificateCard
				canOrder={canOrderIHK}
				rootName={rootName}
				rootId={node.id}
				onOrder={handleOrderOnClick}
			/>
		</CertificateWrapper>
	);

	const renderLicenseButton = () => {
		if (!needLicense) return null;
		if (availableLicensesCount === 0) {
			return (
				<Button
					label="Lizenz erwerben"
					colorVersion="outline"
					iconName="shoppingBasket"
					onClick={handleBuyRootOnClick}
				/>
			);
		}
		if (availableLicensesCount > 0) {
			return (
				<Button
					label="Lizenz einlösen"
					colorVersion="outline"
					iconName="loginKey"
					onClick={handleShowLicenseModal}
				/>
			);
		}
		return null;
	};

	return (
		<Wrapper>
			<LicenseModal
				isVisible={showHasLicenseModal}
				onDismiss={handleLicenseModalOnDismiss}
				onBuyClick={handleUseLicenseOnClick}
				variant="hasLicense"
			/>

			<ContentSection id={ContentSectionId}>
				<TitlePointsWrapper>
					<Title>{title}</Title>
					{renderLicenseButton()}
				</TitlePointsWrapper>
				{canOrderIHK && ihkCertificate}
				<TextParagraphWrapper>
					<TextParagraph title={contentTitle} html={node?.description ?? ""} />
				</TextParagraphWrapper>
			</ContentSection>
			{videoUrl && (
				<TrailerSection name={TrailerSectionId} id={TrailerSectionId}>
					<VideoTrailer title={videoTitle} videoUrl={videoUrl} />
				</TrailerSection>
			)}
			{goalsHtml && (
				<GoalsSection name={TrailerSectionId} id={GoalsSectionId}>
					<TextParagraph title={goalsTitle} html={goalsHtml} />
				</GoalsSection>
			)}

			{node?.instructorsV2 && hasInstructors && (
				<DefaultSection name={TrailerSectionId} id={TrainerSectionId}>
					<InstructorsComponent instructorsConnectionFragmentRef={node?.instructorsV2} />
				</DefaultSection>
			)}
			{!canOrderIHK && ihkCertificate}
			{needLicense && (
				<CertificateWrapper>
					<LicenseRequiredCard />
				</CertificateWrapper>
			)}
			{node?.id && (
				<OrderIhkFlow
					rootId={node.id}
					isVisible={isModalVisible}
					onDismiss={handleOnDismiss}
				/>
			)}
		</Wrapper>
	);
};

export const RootOverviewWithRootId = ({ rootId }: RootOverviewWithRootIdProps) => {
	const [queryReference, loadQuery] = useQueryLoader<rootOverview_GetRootOverviewQuery>(QUERY);

	useEffect(() => {
		loadQuery({ id: rootId });
	}, [loadQuery, rootId]);

	return (
		<Suspense fallback={<RootOverviewSkeleton />}>
			{queryReference && <RootOverviewWithPreloadedQuery queryRef={queryReference} />}
		</Suspense>
	);
};

export const RootOverview = () => {
	const { rootId } = useParams();
	return rootId ? <RootOverviewWithRootId rootId={rootId} /> : null;
};
