import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const QuestionWrapperClass = stack({
	gap: "4",
});
export const QuestionTextActiveClass = css({ color: "shade.100" });
export const QuestionTextInActiveClass = css({
	opacity: 0.5,
	color: "shade.100",
});
