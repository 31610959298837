/**
 * @generated SignedSource<<0c1503f04892ebaa48e056f172f35105>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesTab_LicensesTabFragment$data = {
  readonly LicenseGrouping: {
    readonly MyLicenses: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"licensesGroupCard_LicenseGroupingFragment">;
        };
      } | null> | null;
    };
  };
  readonly " $fragmentType": "licensesTab_LicensesTabFragment";
};
export type licensesTab_LicensesTabFragment$key = {
  readonly " $data"?: licensesTab_LicensesTabFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesTab_LicensesTabFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "licensesAvailable"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "licensesConsumed"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "text"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./licensesTabRefetchQuery.graphql')
    }
  },
  "name": "licensesTab_LicensesTabFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LicenseGroupingQueries",
      "kind": "LinkedField",
      "name": "LicenseGrouping",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "kinds",
              "variableName": "kinds"
            },
            {
              "kind": "Variable",
              "name": "licensesAvailable",
              "variableName": "licensesAvailable"
            },
            {
              "kind": "Variable",
              "name": "licensesConsumed",
              "variableName": "licensesConsumed"
            },
            {
              "kind": "Variable",
              "name": "text",
              "variableName": "text"
            }
          ],
          "concreteType": "LicenseGroupingsConnection",
          "kind": "LinkedField",
          "name": "MyLicenses",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LicenseGroupingsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "licensesGroupCard_LicenseGroupingFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "aca0b949feb51eaa8b5bd5b14df7fdbd";

export default node;
