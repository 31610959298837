import { useWindowSize } from "@hooks/use-window-size";
import { CenterWrapper, StyledLogo, Wrapper } from "./navbar.styles";
import { NavbarItemSkeleton } from "../navbar-item/navbar-item.skeleton";
import { Skeleton } from "../skeleton/skeleton.component";

// TODO: remove-translations
export const NavbarSkeleton = () => {
	const { isXLargeUp } = useWindowSize();

	return (
		<Wrapper>
			<StyledLogo />
			<CenterWrapper>
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				{!isXLargeUp && <Skeleton shape="circle" size="2.5rem" />}
			</CenterWrapper>
			{isXLargeUp && <Skeleton shape="circle" size="2.5rem" />}
		</Wrapper>
	);
};
