import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { Wrapper } from "./advance-tree-navigation.styles";

// TODO: add-translations
export const AdvanceTreeNavigationSkeleton = () => {
	return (
		<Wrapper>
			<BottomNavigationTemplateSkeleton showSecondaryButton />
		</Wrapper>
	);
};
