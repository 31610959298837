import { Slider } from "primereact/slider";
import { Tooltip } from "primereact/tooltip";
import styled from "styled-components";
import tw from "twin.macro";
import { shade0, shade10, brandMain100 } from "@themes/colors";
import { spacing24 } from "@themes/spacing";

export const AudioControlWrapper = tw.div`
	flex
    justify-between
	flex-row
	pt-12
	pb-12
	pl-12
	pr-12
`;

export const IconPlaceholder = tw.div`
  min-w-fit
`;

export const TooltipWrapper = styled(Tooltip)`
	.p-tooltip-arrow {
		display: none;
	}

	.p-tooltip-text {
		background: ${shade0};
		padding: ${spacing24.rem()};
	}
`;

export const VolumeSlider = styled(Slider)`
	background: ${shade10} !important;

	.p-slider-range {
		background: ${brandMain100};
	}

	.p-slider-handle {
		background-color: ${brandMain100};
		border-color: ${brandMain100};
	}
`;

export const PlayIconWrapper = styled.div`
	${tw`border border-[3px] border-shade-10 p-16 cursor-pointer`}

	border-radius: 100%;
`;
