import { css } from "@styled-system/css";
import { circle, stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "24",
});

export const navigationWrapperClass = css({
	position: "sticky",
	bottom: "0",
	zIndex: "1",
	xl: {
		gridColumn: "2/3",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
});

export const menuWrapper = css({
	position: "absolute",
	top: "16",
	left: "16",
	zIndex: "99999",
});

export const menuIconWrapper = circle({
	bg: "shade.0",
	w: "[2.5rem]",
	h: "[2.5rem]",
});
