import { AxiosResponse } from "axios";
import { TabPanel, TabPanelHeaderTemplateOptions } from "primereact/tabview";
import { useContext, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { Label } from "@components/label";
import { Spacing4 } from "@components/news-card/news-card.styles";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { usePermissions } from "@hooks/use-permissions";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { H1Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./invite-member-modal.graphql";
import { StyledTabView, TitleWrapper, Wrapper } from "./invite-member-modal.styles";
import { InviteMemberModalProps } from "./invite-member-modal.types";
import { CSVInviteComponent } from "../csv-invite-form/csv-invite.component";
import { CSVInviteComponentRef } from "../csv-invite-form/csv-invite.types";
import { ErrorInviteComponent } from "../error-invite-modal/error-invite.component";
import { SuccessInviteComponent } from "../invite-component/success-invite.component";
import { StandardInviteForm } from "../standard-invite-form/standard-invite-form.component";
import {
	InviteMembersMutationResponse,
	StandardInviteFormRef,
} from "../standard-invite-form/standard-invite-form.types";

// TODO: add-translations
export const InviteMemberModal = ({
	onDismiss,
	isVisible,
	setInviteMemberModalIsVisible,
	selectedUsers,
	selectedGroups,
	queryFragmentRef,
	tabView = true,
	inviteType = "standard",
}: InviteMemberModalProps) => {
	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const formRef = useRef<StandardInviteFormRef>(null);
	const csvRef = useRef<CSVInviteComponentRef>(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const [successModalIsVisible, setSuccessModalIsVisible] = useState(false);
	const [errorModalIsVisible, setErrorModalIsVisible] = useState(false);
	const [buttonLoadingState, setButtonLoadingState] = useState(false);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [inviteMembersMutationResponse, setInviteMembersMutationResponse] = useState<
		InviteMembersMutationResponse | AxiosResponse<any, any>
	>();
	const [errorInfo, setErrorInfo] = useState({ invalidEmails: [], notFoundGroupNames: [] });

	const { refetch } = useContext(RefetchContext);
	const { canInviteUsersViaCSVUpload } = usePermissions();

	const handleOnDismiss = () => {
		onDismiss?.();
	};
	const handleSubmit = () => {
		if (activeIndex === 0 && inviteType === "standard") formRef.current?.submit();
		else {
			csvRef.current?.submit();
			setButtonLoadingState(true);
		}
	};

	const handleOnCloseSuccessModal = () => {
		setButtonLoadingState(false);
		setSuccessModalIsVisible(false);
	};
	const handleOnCloseErrorModal = () => {
		setFileUploaded(false);
		setButtonLoadingState(false);
		setErrorModalIsVisible(false);
	};

	const handleOnUploadError = () => {
		onDismiss?.();
		setErrorModalIsVisible(true);
		setButtonLoadingState(false);
		setFileUploaded(false);
	};

	const handleOnSuccess = () => {
		setButtonLoadingState(false);
		onDismiss?.();
		refetch?.();
		setSuccessModalIsVisible(true);
		setFileUploaded(false);
	};

	const handleOnRetryInvite = () => {
		setButtonLoadingState(false);
		setErrorModalIsVisible(false);
		setFileUploaded(false);
		setInviteMemberModalIsVisible?.(true);
	};

	const handleFileUpload = (successfulUpload: boolean) => {
		setFileUploaded(successfulUpload);
	};

	const inviteComponent =
		inviteType === "standard" ? (
			<StandardInviteForm
				ref={formRef}
				onSuccess={handleOnSuccess}
				selectedUsers={selectedUsers}
				selectedGroups={selectedGroups}
				queryFragmentRef={queryFragment}
				setInviteMembersMutationResponse={setInviteMembersMutationResponse}
			/>
		) : (
			<CSVInviteComponent
				ref={csvRef}
				onError={handleOnUploadError}
				onSuccess={handleOnSuccess}
				onDismiss={handleOnDismiss}
				setInviteMembersMutationResponse={setInviteMembersMutationResponse}
				setErrorInfo={setErrorInfo}
				onFileUpload={handleFileUpload}
				fileUploaded={fileUploaded}
				setButtonLoadingState={setButtonLoadingState}
			/>
		);

	return (
		<>
			<ResponsiveBottomSheetModal
				isVisible={isVisible}
				dismissable={false}
				onDismiss={handleOnDismiss}
			>
				<DialogTemplate
					secondaryButtonLabel="Schließen"
					onSecondaryButtonClick={handleOnDismiss}
					primaryButtonLabel="Hinzufügen"
					primaryButtonColorVersion="strong"
					onPrimaryButtonClick={handleSubmit}
					primaryButtonLoading={buttonLoadingState}
					primaryButtonDisabled={
						(activeIndex === 1 && !fileUploaded) ||
						(inviteType === "csv" && !fileUploaded)
					}
				>
					<TitleWrapper>
						<H1Span>Benutzer hinzufügen</H1Span>
						<>
							{tabView && canInviteUsersViaCSVUpload ? (
								<StyledTabView
									activeIndex={activeIndex}
									onTabChange={(e) => setActiveIndex(e.index)}
								>
									<TabPanel
										headerTemplate={(options) =>
											HeaderTemplate(options, activeIndex, "Standard", 0)
										}
									>
										<StandardInviteForm
											ref={formRef}
											onSuccess={handleOnSuccess}
											selectedUsers={selectedUsers}
											selectedGroups={selectedGroups}
											queryFragmentRef={queryFragment}
											setInviteMembersMutationResponse={
												setInviteMembersMutationResponse
											}
										/>
									</TabPanel>
									<TabPanel
										headerTemplate={(options) =>
											HeaderTemplate(options, activeIndex, "CSV", 1)
										}
									>
										<CSVInviteComponent
											ref={csvRef}
											onError={handleOnUploadError}
											onSuccess={handleOnSuccess}
											onDismiss={handleOnDismiss}
											setInviteMembersMutationResponse={
												setInviteMembersMutationResponse
											}
											setErrorInfo={setErrorInfo}
											onFileUpload={handleFileUpload}
											fileUploaded={fileUploaded}
											setButtonLoadingState={setButtonLoadingState}
										/>
									</TabPanel>
								</StyledTabView>
							) : (
								<Wrapper>
									<Spacing4 />
									{inviteComponent}
								</Wrapper>
							)}
						</>
					</TitleWrapper>
				</DialogTemplate>
			</ResponsiveBottomSheetModal>

			<SuccessInviteComponent
				isVisible={successModalIsVisible}
				inviteMembersResponse={inviteMembersMutationResponse}
				onDismiss={handleOnCloseSuccessModal}
			/>
			<ErrorInviteComponent
				errorInfo={errorInfo}
				isVisible={errorModalIsVisible}
				handleOnClose={handleOnCloseErrorModal}
				handleOnRetry={handleOnRetryInvite}
			/>
		</>
	);
};

const HeaderTemplate = (
	options: TabPanelHeaderTemplateOptions,
	activeIndex: number,
	label: string,
	index: number,
) => {
	return (
		<Label
			label={label}
			size="big"
			severity={activeIndex === index ? "brandMain" : "default"}
			onEventClick={options.onClick}
		/>
	);
};
