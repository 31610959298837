import tw from "twin.macro";

export const Wrapper = tw.div`
	relative
	flex
	flex-col
	gap-32
	items-start
`;

export const CoachWrapper = tw.div`
	self-stretch
	max-w-[20rem]
`;

export const CalendarTimeSlotWrapper = tw.div`
	flex
	flex-col
	lg:flex-row
	gap-32
	self-stretch
`;

export const TitleContentWrapper = tw.div`
	flex
	flex-col
	gap-12
	flex-1
`;

export const CalendarWrapper = tw.div`
	flex
	flex-col
	flex-1
	gap-12
	max-w-[20rem]
`;

export const TimeSlotsWrapper = tw.div`
	flex
	flex-col
	gap-[0.625rem]
`;

export const BookCoachingPreviewWrapper = tw.div`
	p-4
	backdrop-blur-sm
	xl:hidden
`;
