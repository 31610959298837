import { readInlineData, useLazyLoadQuery } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { myDocuments_FileInlineFragment$key } from "@relay/myDocuments_FileInlineFragment.graphql";
import { myDocuments_Query } from "@relay/myDocuments_Query.graphql";
import { ParagraphWrapper, Wrapper } from "@screens/imprint/imprint.styles";
import { FILE_INLINE_FRAGMENT, QUERY } from "@screens/my-documents/my-documents.graphql";
import { brandStrong100 } from "@themes/colors";
import { H1Span, TkaP2Anchor } from "@themes/font-tags";

export const MyDocumentsScreen = () => {
	const { isMediumUp } = useWindowSize();
	const query = useLazyLoadQuery<myDocuments_Query>(QUERY, {});
	const coachingFiles = query.Viewer.Coaching.MyDocuments.map((file) =>
		readInlineData<myDocuments_FileInlineFragment$key>(FILE_INLINE_FRAGMENT, file),
	);
	const nodeFiles = query.Viewer.Learn.myNodeDocuments.map((file) =>
		readInlineData<myDocuments_FileInlineFragment$key>(FILE_INLINE_FRAGMENT, file),
	);
	const files = [...nodeFiles, ...coachingFiles];

	const hasFiles = files.length > 0;
	const listComponent = files.map((file) => (
		<TkaP2Anchor key={file.id} tkaColor={brandStrong100} underline={true} href={file.url ?? ""}>
			{file.name}
		</TkaP2Anchor>
	));

	return (
		<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
			<Wrapper>
				<H1Span>Meine Dokumente</H1Span>

				<EmptyPlaceholder
					isVisible={!hasFiles}
					iconName={"copy"}
					title="Keine Dokumente"
					subtitle="Dir stehen gerade noch keine Dokumente zur Verfügung."
				>
					<ParagraphWrapper>{listComponent}</ParagraphWrapper>
				</EmptyPlaceholder>
			</Wrapper>
		</ScreenWithProfileMenuLayout>
	);
};
