import { MultiSelectChangeEvent } from "primereact/multiselect";
import { ValidatedMultiSelectProps } from "./validated-multi-select.types";
import { MultiSelect } from "../multi-select/multi-select.component";

export function ValidatedMultiSelect<State>({
	formikConfig,
	label,
	options,
	name,
	optionLabel,
	onCloseClick,
	disabled,
	...props
}: ValidatedMultiSelectProps<State>) {
	const allErrorMessages = formikConfig.errors[name] as unknown as string | undefined;
	const errorMessage = Array.isArray(allErrorMessages) ? allErrorMessages[0] : allErrorMessages;

	const hasError = !!(errorMessage && formikConfig.touched[name]);

	const value = formikConfig.values[name] as unknown as string[] | [];
	const updateValue = (updatedValue: MultiSelectChangeEvent) => {
		formikConfig.setFieldTouched(name, true);
		formikConfig.setFieldValue(name, updatedValue.value);
	};

	const textBelow = hasError ? errorMessage : undefined;

	return (
		<MultiSelect
			{...props}
			label={label}
			name={name}
			options={options}
			optionLabel={optionLabel}
			onChange={updateValue}
			value={value}
			onCloseClick={onCloseClick}
			hasError={hasError}
			smallTextBelow={textBelow}
			disabled={disabled}
		/>
	);
}
