import {
	shade0,
	shade10,
	error100,
	brandMain100,
	shade100,
	success100,
	brandStrong20,
	brandStrong100,
	shade20,
	onBrandStrong,
} from "@themes/colors";
import {
	MultipleChoiceGapTextIconStyle,
	MultipleChoiceGapTextStatus,
	MultipleChoiceGapTextStyle,
} from "./multiple-choice-gap-text.interface";

export const multipleChoiceTextStyleMappings: Record<
	MultipleChoiceGapTextStatus,
	MultipleChoiceGapTextStyle
> = {
	[MultipleChoiceGapTextStatus.Default]: {
		backgroundColor: shade0,
		borderColor: shade20,
		textColor: shade100,
	},
	[MultipleChoiceGapTextStatus.Correct]: {
		backgroundColor: success100,
		borderColor: success100,
		textColor: shade0,
	},
	[MultipleChoiceGapTextStatus.ActuallyCorrect]: {
		backgroundColor: brandStrong20,
		borderColor: brandStrong100,
		textColor: shade100,
	},
	[MultipleChoiceGapTextStatus.Wrong]: {
		backgroundColor: error100,
		borderColor: error100,
		textColor: shade0,
	},
	[MultipleChoiceGapTextStatus.Disabled]: {
		backgroundColor: shade0,
		borderColor: shade10,
		textColor: shade10,
	},
};

export const multipleChoiceTextRadioIconMappings: Partial<
	Record<MultipleChoiceGapTextStatus, MultipleChoiceGapTextIconStyle>
> = {
	[MultipleChoiceGapTextStatus.Correct]: {
		icon: "correct",
		iconColor: shade0,
		iconBackgroundColor: success100,
	},
	[MultipleChoiceGapTextStatus.ActuallyCorrect]: {
		icon: "correct",
		iconColor: brandStrong100,
		iconBackgroundColor: onBrandStrong,
	},
};

export const multipleChoiceTextCheckboxIconMappings: Partial<
	Record<MultipleChoiceGapTextStatus, MultipleChoiceGapTextIconStyle>
> = {
	[MultipleChoiceGapTextStatus.Correct]: {
		iconColor: brandMain100,
		iconBackgroundColor: shade0,
	},
	[MultipleChoiceGapTextStatus.ActuallyCorrect]: {
		iconColor: shade0,
		iconBackgroundColor: brandMain100,
	},
};
