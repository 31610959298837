import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment manageLicensesTab_ManageLicensesTabFragment on Query
	@refetchable(queryName: "manageLicensesTabRefetchQuery")
	@argumentDefinitions(
		kinds: { type: "[LearnableKind!]!" }
		licensesAvailable: { type: "Boolean" }
		licensesConsumed: { type: "Boolean" }
		text: { type: "String" }
	) {
		LicenseGrouping {
			LicenseGroupings(
				kinds: $kinds
				licensesAvailable: $licensesAvailable
				licensesConsumed: $licensesConsumed
				text: $text
			) {
				edges {
					node {
						id
						...licensesGroupCard_LicenseGroupingFragment
						...licenseContextMenu_LicenseFragment
					}
				}
			}
		}
	}
`;
