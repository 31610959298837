/**
 * @generated SignedSource<<98dfdfcfad19e60b88502b0d6ec0eec0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetAccountNameUserInput = {
  clientMutationId?: string | null;
  id: string;
  name: string;
};
export type accountTab_SetAccountNameMutation$variables = {
  input: SetAccountNameUserInput;
};
export type accountTab_SetAccountNameMutation$data = {
  readonly Auth: {
    readonly setAccountNameUser: {
      readonly account: {
        readonly name: string;
      };
    } | null;
  };
};
export type accountTab_SetAccountNameMutation = {
  response: accountTab_SetAccountNameMutation$data;
  variables: accountTab_SetAccountNameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountTab_SetAccountNameMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAccountNameUserPayload",
            "kind": "LinkedField",
            "name": "setAccountNameUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountTab_SetAccountNameMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetAccountNameUserPayload",
            "kind": "LinkedField",
            "name": "setAccountNameUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23468558441e0107a6d0263e2c97400d",
    "id": null,
    "metadata": {},
    "name": "accountTab_SetAccountNameMutation",
    "operationKind": "mutation",
    "text": "mutation accountTab_SetAccountNameMutation(\n  $input: SetAccountNameUserInput!\n) {\n  Auth {\n    setAccountNameUser(input: $input) {\n      account {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5507d4ff1def9b92f56783e95451f050";

export default node;
