import { Card } from "@components/card";
import {
	FooterClass,
	HeaderClass,
	JustifyBetweenClass,
	TagsWrapperClass,
	WrapperClass,
} from "@components/offer-card/offer-card.styles";
import { LabelSkeleton } from "../label/label.skeleton";
import { Skeleton } from "../skeleton/skeleton.component";

export const OfferCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={0}>
			<div className={WrapperClass}>
				<div className={HeaderClass}>
					<div className="absolute inset-0">
						<Skeleton width="100%" height="100%"></Skeleton>
					</div>
					<div className={JustifyBetweenClass}>
						<Skeleton width="5rem" />
					</div>
					<div className={JustifyBetweenClass}>
						<div className={TagsWrapperClass}>
							<LabelSkeleton size="big" />
							<LabelSkeleton size="big" />
							<LabelSkeleton size="big" />
						</div>
					</div>
				</div>
				<div className={FooterClass}>
					<div>
						<Skeleton height="2rem" width="15rem" className="mt-4" />
						<Skeleton width="20rem" className="mt-4" />
						<Skeleton width="18rem" className="mt-4" />
					</div>

					<div className={JustifyBetweenClass}>
						<Skeleton width="5rem" />
						<Skeleton width="5rem" />
					</div>
				</div>
			</div>
		</Card>
	);
};
