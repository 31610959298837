/**
 * @generated SignedSource<<7b3d30f3b69153b71a710eca20e68847>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editGroupSuccessModal_UserInAccountGroupWrapperFragment$data = {
  readonly group: {
    readonly name: string;
  };
  readonly memberCount: any;
  readonly newInvitations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    } | null> | null;
  };
  readonly " $fragmentType": "editGroupSuccessModal_UserInAccountGroupWrapperFragment";
};
export type editGroupSuccessModal_UserInAccountGroupWrapperFragment$key = {
  readonly " $data"?: editGroupSuccessModal_UserInAccountGroupWrapperFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editGroupSuccessModal_UserInAccountGroupWrapperFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editGroupSuccessModal_UserInAccountGroupWrapperFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInAccountGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountMemberConnection",
      "kind": "LinkedField",
      "name": "newInvitations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountMemberEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserInAccountGroupWrapper",
  "abstractKey": null
};

(node as any).hash = "876f00a8480e09ba71af90c926839e8f";

export default node;
