import {
	accountContentClass,
	accountOptionClass,
	accountOptionsWrapperClass,
} from "@components/account-selection-panel/account-selection-panel.styles";
import { Skeleton } from "@components/skeleton";

export const AccountSelectionPanelSkeleton = () => {
	const skeletons = Array.from({ length: 3 });

	return (
		<div className={accountOptionsWrapperClass}>
			{skeletons.map((_, index) => (
				<div className={accountOptionClass} key={index}>
					<div className={accountContentClass}>
						<Skeleton width="2rem" height="2rem" borderRadius="100%" />
						<Skeleton width="17rem" />
					</div>
				</div>
			))}
		</div>
	);
};
