import { css } from "@styled-system/css";
import { stack } from "@styled-system/patterns";

export const wrapperClass = stack({
	gap: "16",
});

export const highlightTextClass = css({
	bg: "secondary.40",
	rounded: "6",
});
