import React, { useContext, useMemo, useRef } from "react";
import { BadgeV2 } from "@components/badge-v2";
import { Icon } from "@components/icon";
import { Label } from "@components/label";
import { ResponsiveBottomSheetOverlayPanel as ResponsiveBottomSheetOverlayPanelComponent } from "@components/responsive-bottom-sheet-overlay-panel";
import { HTMLMouseEvent } from "@customTypes/index";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { LearnableKind } from "@relay/gridView_Query.graphql";
import { DashboardScreenContext } from "@screens/dashboard/dashboard.context";
import {
	DesktopLabelWrapper,
	FilterTextWrapper,
	InputTextMock,
	MobileBadgeWrapper,
	Wrapper,
} from "@screens/offers/parts/filters/filters.styles";
import { shade100, shade40 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { FilterModal } from "../filter-modal/filter-modal.component";

export const Filters = () => {
	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanelComponent>(null);
	const handleOpenOnClick = (event: HTMLMouseEvent) => {
		overlayRef.current?.show(event, event.currentTarget);
	};
	const handleOnClose = () => {
		overlayRef.current?.hide();
	};

	const { filters } = useContext(DashboardScreenContext);
	const { t } = useTkaTranslation("offers");
	const { isMediumUp } = useWindowSize();
	const calculatedValue = useMemo(() => {
		const defaultValue = "Filter";
		//TODO add translations
		const translationKeyMap: Record<LearnableKind, string> = {
			GroupCoaching: "Gruppen Coaching",
			OneToOneCoaching: "Coaching",
			Course: "Weiterbildungen",
			MicroLearning: "Micro Learning",
		};
		const kindsValue = filters.kinds
			.map((kind) => {
				return t(translationKeyMap[kind]);
			})
			.join(", ");
		return [kindsValue].filter((e) => e).join(", ") || defaultValue;
	}, [filters.kinds]);
	const badgeCount = filters.kinds.length;

	const iconColor = useMemo(() => (isMediumUp ? shade40 : shade100), [isMediumUp]);
	const iconSize = useMemo(() => (isMediumUp ? 1.5 : 0.75), [isMediumUp]);
	return (
		<React.Fragment>
			<Wrapper>
				<InputTextMock onClick={handleOpenOnClick}>
					<Icon icon={"filter"} sizeInRem={iconSize} tkaColor={iconColor} />
					{isMediumUp && (
						<FilterTextWrapper>
							<TkaP2Span tkaColor={shade100}>{calculatedValue}</TkaP2Span>
						</FilterTextWrapper>
					)}

					{isMediumUp && badgeCount ? (
						<DesktopLabelWrapper>
							<Label label={`+${badgeCount}`} />
						</DesktopLabelWrapper>
					) : (
						<React.Fragment />
					)}
				</InputTextMock>
				{!isMediumUp && badgeCount ? (
					<MobileBadgeWrapper>
						<BadgeV2 value={badgeCount} severity={"brandStrong"} />
					</MobileBadgeWrapper>
				) : (
					<React.Fragment />
				)}
			</Wrapper>
			<ResponsiveBottomSheetOverlayPanelComponent ref={overlayRef}>
				<FilterModal onClose={handleOnClose} />
			</ResponsiveBottomSheetOverlayPanelComponent>
		</React.Fragment>
	);
};
