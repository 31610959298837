/**
 * @generated SignedSource<<e018caa19cdde36276b28f346d7fb0d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appointmentRating_AvailabilityFragment$data = {
  readonly coachProfile: {
    readonly coach: {
      readonly " $fragmentSpreads": FragmentRefs<"participantPreview_UserFragment">;
    } | null;
  } | null;
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly feedback: {
    readonly data: {
      readonly comment: string | null;
      readonly score: number;
    };
  } | null;
  readonly id: string;
  readonly " $fragmentType": "appointmentRating_AvailabilityFragment";
};
export type appointmentRating_AvailabilityFragment$key = {
  readonly " $data"?: appointmentRating_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"appointmentRating_AvailabilityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appointmentRating_AvailabilityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachProfile",
      "kind": "LinkedField",
      "name": "coachProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "coach",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "participantPreview_UserFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachFeedback",
      "kind": "LinkedField",
      "name": "feedback",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CoachingFeedbackData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "comment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "score",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Availability",
  "abstractKey": null
};

(node as any).hash = "691ceb1237635dd8aa58d22d52738196";

export default node;
