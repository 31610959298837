import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	position: "absolute",
	left: "0",
	right: "0",
	bottom: "0",
});

export const spacerClass = css({
	height: "[6.5rem]",
});

export const progressBarWrapperClass = css({
	transform: "[translateY(1.5rem)]",
	zIndex: "-1",
});

export const trackerWrapperClass = css({
	height: "[2.25rem]",
	paddingLeft: "12",
	paddingTop: "4",
	backgroundColor: "shade.5",
	transform: "[translateY(8px)]",
	zIndex: "-1",
	boxShadow: "[0px 4px 8px rgba(0, 0, 0, 0.1)]",
});
