import _, { isArray } from "lodash";
import { ReactElement, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { RouteObject } from "react-router";
import { Navigate, Route as DOMRoute, Routes, useLocation } from "react-router-dom";
import { useAuthContext } from "@hooks/use-auth-context";
import { Permission } from "@relay/PermissionBasedNavigation_Query.graphql";
import { Path } from "@router/index";
import { PlacementContext } from "@screens/placement/placement.context";
import {
	selectFirstLogin,
	selectIsAvgsClient,
	selectIsLoggedIn,
	selectRedirectUrl,
} from "@slices/AuthSlice";
import { RouteDefinition } from "./RouteDefinition";

type FilteredRoutesProps = {
	permissions: readonly Permission[];
	routes: RouteDefinition[];
	forbiddenRoute?: ReactElement;
};

export function FilteredRoutes({ permissions, forbiddenRoute, routes }: FilteredRoutesProps) {
	const firstLogin = useSelector(selectFirstLogin);
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const redirectUrl = useSelector(selectRedirectUrl);
	const isAvgsClient = useSelector(selectIsAvgsClient);

	const { setPlacementLinkId } = useContext(PlacementContext);

	const filteredRoutes = filterByPermission(routes, isLoggedIn, !!firstLogin, isAvgsClient, [
		...(permissions || []),
	]);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const linkId = searchParams.get("linkId");
	const { logout } = useAuthContext();
	const redirectToLogin = !isLoggedIn || !!linkId;

	useEffect(() => {
		if (linkId) {
			setPlacementLinkId(linkId);
			logout();
		}
	}, [linkId]);

	const parsedRedirectUrl = redirectUrl ? new URL(redirectUrl) : undefined;
	const relativeRedirectUrl = parsedRedirectUrl
		? parsedRedirectUrl.pathname + parsedRedirectUrl.search
		: undefined;

	return (
		<Routes>
			{filteredRoutes.map((prd) => {
				return (
					<DOMRoute key={prd.path} path={prd.path} element={prd.element}>
						{renderNestedDomRoutes(prd.children)}
					</DOMRoute>
				);
			})}
			<DOMRoute
				key={"verboten"}
				path={"*"}
				element={
					forbiddenRoute ? (
						forbiddenRoute
					) : (
						<Navigate
							replace
							to={
								redirectToLogin
									? Path.login.path
									: relativeRedirectUrl ?? Path.dashboard.path
							}
						/>
					)
				}
			/>
		</Routes>
	);
}

export type RequiredPermissionsType =
	| Permission[]
	| "first-login"
	| "logged-in"
	| "logged-in-and-logged-out"
	| "only-logged-out";
export type HasRequiredPermissionsType = {
	requiredPermissions: RequiredPermissionsType;
	path?: string;
};

export function filterByPermission<ItemType extends HasRequiredPermissionsType>(
	items: ItemType[],
	isLoggedIn: boolean,
	isFirstLogin: boolean,
	isAvgsClient: boolean,
	permissionsInAccount?: Permission[],
) {
	return items.filter((r) => {
		if (isAvgsClient) {
			return r.path === Path.avgsPotentialAnalysis.withIdPlaceholder().path;
		}
		const firstLogin = r.requiredPermissions === "first-login" && isLoggedIn && isFirstLogin;
		const loggedOutAndRouteRequiresLoggedOut =
			r.requiredPermissions === "only-logged-out" && !isLoggedIn;
		const loggedInAndRouteOnlyRequiredLoggedIn =
			r.requiredPermissions === "logged-in" && isLoggedIn;
		const loggedInOrLoggedOut = r.requiredPermissions === "logged-in-and-logged-out";

		const permissionsAreKnown = permissionsInAccount && isArray(r.requiredPermissions);
		const isLoggedInAndHasAllNecessaryPermissions =
			permissionsAreKnown &&
			(_.difference(r.requiredPermissions, permissionsInAccount).length === 0 ||
				permissionsInAccount.includes("UserInAccountPermission_System_Owner"));

		return (
			firstLogin ||
			loggedInOrLoggedOut ||
			loggedOutAndRouteRequiresLoggedOut ||
			loggedInAndRouteOnlyRequiredLoggedIn ||
			isLoggedInAndHasAllNecessaryPermissions
		);
	});
}

function renderNestedDomRoutes(routes?: RouteObject[]) {
	return routes?.map((route) => (
		<DOMRoute key={route?.path} path={route?.path} element={route?.element}>
			{renderNestedDomRoutes(route.children)}
		</DOMRoute>
	));
}
