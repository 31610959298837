import tw from "twin.macro";

export const PaddingWrapper = tw.div`
	py-8
	px-16
`;

export const ContentWrapper = tw.div`
	flex
	flex-col
	gap-16
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const HeaderImage = tw.img`
	w-full
`;
