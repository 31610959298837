import { Cart, CartSelection, Product } from "./shopping-cart-products.types";

export const cloneCartSelection = (cart: Cart): CartSelection => {
	const products = getProductItems(cart);
	const discountCodes = getCartDiscountItems(cart);
	return {
		selectedDiscountCodes: discountCodes?.map((item) => item.code!) ?? [],
		selectedProducts:
			products?.map((item) => ({
				productId: item.product?.id!,
				amount: item.amount ?? 1,
			})) ?? [],
	};
};

export const getProductItems = (cart: Cart) =>
	cart?.items.filter((item) => item.itemType === "CartProduct");

export const getCartDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.itemType === "CartDiscount");

export const getCartLimitedDiscountItems = (cart: Cart) =>
	cart?.items.filter((item) => item.itemType === "CartLimitedDiscount");

export const getAllProducts = (cart: Cart): Array<Product> => {
	const itemsInCart: Array<Product> =
		cart?.items
			.filter((item) => item.itemType === "CartProduct")
			.map((x) => ({
				id: x.product?.id ?? "",
				title: x.product?.title ?? "",
			})) ?? [];

	const otherAvailableProducts: Array<Product> =
		cart?.otherAvailableProducts.edges?.map((x) => ({
			id: x?.node.id ?? "",
			title: x?.node.title ?? "",
		})) ?? [];

	return itemsInCart.concat(otherAvailableProducts);
};
