import { useMemo } from "react";
import { useFragment } from "react-relay";

import { DividerList } from "@components/divider-list";
import { MaterialCard } from "@components/material-card";
import { MaterialsBranchNodeCard } from "@components/materials-branch-node-card";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { shade60 } from "@themes/colors";
import { TkaP3Span } from "@themes/font-tags";
import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
	isAfterIndexPath,
	isEqualIndexPath,
} from "@utils/index-path.util";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./materials-tree.graphql";
import { ActiveContentWrapper, NoAttachmentsWrapper } from "./materials-tree.styles";
import { BranchNode, ContentNode, MaterialsTreeProps } from "./materials-tree.types";

//TODO: add-translations
export const MaterialsTree = ({
	activeAttachmentId,
	onAttachmentOnClick,
	learnOpportunityV2FragmentRef,
}: MaterialsTreeProps) => {
	const { t } = useTkaTranslation("modulesAndLessons");

	const {
		typeDefinition: { children: branches },
		structureDefinition,
	} = useFragment(LEARN_OPPORTUNITY_V2_FRAGMENT, learnOpportunityV2FragmentRef ?? null);

	const { isXLargeUp } = useWindowSize();

	const headContentId = structureDefinition.viewerTreeState?.headContentId;

	const isStarted = structureDefinition.viewerTreeState?.kind === "IsStarted";
	const isFinished = structureDefinition.viewerTreeState?.kind === "IsFinished";

	const headIndexPath: readonly number[] | undefined = useMemo(() => {
		let indexPath: readonly number[] | undefined = undefined;
		branches?.forEach((branch) => {
			const foundContent = branch.typeDefinition?.children?.find(
				(content) => content.id === headContentId,
			);
			if (foundContent) {
				indexPath = foundContent.indexPath;
			}
		});
		return indexPath;
	}, [headContentId, branches]);

	const renderContentMaterials = (node: ContentNode) => {
		const isUnlocked =
			(isStarted &&
				(isAfterIndexPath(node.indexPath, headIndexPath) ||
					isEqualIndexPath(node.indexPath, headIndexPath))) ||
			isFinished;

		const branchNumber = getBranchNodeNumberFromIndexPath(node.indexPath);
		const contentNumber = getContentNodeNumberFromIndexPath(node.indexPath);

		const title = `${t("modules_and_lessons.lesson")} ${branchNumber}.${contentNumber}`;

		const createAttachmentOnClickHandler = (attachmentId: string) => () => {
			onAttachmentOnClick?.(attachmentId);
		};

		return node.attachmentsV2.map((attachment) => (
			<ActiveContentWrapper key={attachment.id} active={activeAttachmentId === attachment.id}>
				<MaterialCard
					key={attachment.id}
					disabled={!isUnlocked}
					isExpanded
					noBorder={activeAttachmentId === attachment.id && isXLargeUp}
					icon="dataFileBarsDownload"
					title={title}
					description={attachment.title ?? ""}
					fileUrl={attachment.file?.url}
					fileName={attachment.file?.name}
					onClick={createAttachmentOnClickHandler(attachment.id)}
				/>
			</ActiveContentWrapper>
		));
	};

	const renderBranchNodeCard = (node: BranchNode) => {
		const renderAttachments = node.typeDefinition?.children?.map(renderContentMaterials);
		return (
			<MaterialsBranchNodeCard
				key={node.id}
				branchNumber={getBranchNodeNumberFromIndexPath(node.indexPath)}
				branchName={node.structureDefinition.title}
			>
				{renderAttachments}
			</MaterialsBranchNodeCard>
		);
	};

	const branchesWithAttachments = branches?.filter((branch) =>
		branch?.typeDefinition?.children?.some((child) => child.attachmentsV2.length > 0),
	);
	const hasBranchesWithAttachments = !!branchesWithAttachments?.length;

	return hasBranchesWithAttachments ? (
		<DividerList hasDivider>{branchesWithAttachments?.map(renderBranchNodeCard)}</DividerList>
	) : (
		<NoAttachmentsWrapper>
			<TkaP3Span tkaColor={shade60}>Keine Materialien in diesem Kurs</TkaP3Span>
		</NoAttachmentsWrapper>
	);
};
