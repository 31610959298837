import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";

export const Wrapper = tw.div`flex gap-32 mt-24`;
export const Grid = tw.div`grid grid-cols-2 lg:grid-cols-2 gap-24 flex-1`;
export const FullWidthWrapper = tw.div`col-span-2 lg:col-span-2`;
export const HalfWidthWrapper = tw.div`col-span-1`;
export const MobileHalfWidthWrapper = tw.div`col-span-2 lg:col-span-1`;

export const Hr = styled.hr<{ tkaColor: TkaColor }>`
	${tw`col-span-2`}
	${(p) => `color:${p.tkaColor};`}
`;

export const DeleteButtonWrapperWrapper = tw.div`flex col-span-2`;
export const DeleteButtonWrapper = tw.div`flex items-center gap-12 cursor-pointer hover:bg-error-20 flex-grow-0 p-8 rounded-8 transition-colors`;
