import { Slider } from "primereact/slider";
import styled from "styled-components";
import tw from "twin.macro";
import { shade20, shade10, shade100 } from "@themes/colors";
import { spacing12 } from "@themes/spacing";

export const TrackProgressWrapper = tw.div`
	flex
	flex-col
	pr-12
	pl-12
	justify-between
`;

export const AudioPlayerWrapper = tw.div`mx-[1.5rem] md:m-0`;

export const PlayerControlWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	width: 50%;
`;
export const TrackProgress = styled(Slider)`
	width: 100%;
	background: ${shade10} !important;

	.p-slider-range {
		background: ${shade100};
	}

	.p-slider-handle {
		position: relative;
		background-color: ${shade100};
		border-color: ${shade100};
		z-index: 99;
	}

	.p-slider {
		background-color: ${shade100};
	}
`;

export const ProgressTimeDisplay = styled.div`
	color: ${shade20};
	font-size: ${spacing12.rem()};
`;

export const ProgressTimerWrapper = tw.div`
    mt-8
	flex
	flex-row
	justify-between
`;
