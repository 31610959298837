import { classNames } from "primereact/utils";
import { TkaL1Span, TkaP3Label } from "@themes/font-tags";
import { StyledDropdown, Wrapper } from "./dropdown.styles";
import { DropdownChangeEvent, DropdownProps } from "./dropdown.types";
import { getLabelColor } from "./dropdown.util";

export const Dropdown = ({
	helperText,
	label,
	status = "default",
	onChange,
	...props
}: DropdownProps) => {
	const hasError = status === "error";
	const disabled = status === "disabled";
	const labelColor = getLabelColor(hasError, disabled);
	const MyLabel = label && (
		<TkaP3Label tkaColor={labelColor} htmlFor={props.id}>
			{label}
		</TkaP3Label>
	);
	const HelperText = helperText && <TkaL1Span tkaColor={labelColor}>{helperText}</TkaL1Span>;

	const handleOnChange = (event: DropdownChangeEvent) => {
		onChange?.(event.value);
	};

	return (
		<Wrapper>
			{MyLabel}
			<StyledDropdown
				disabled={disabled}
				className={classNames({
					"p-disabled": disabled,
					"p-invalid": hasError,
				})}
				onChange={handleOnChange}
				{...props}
			/>
			{HelperText}
		</Wrapper>
	);
};
