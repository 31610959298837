import { useFragment } from "react-relay";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { basketTimeline_OrderFragment$key } from "@relay/basketTimeline_OrderFragment.graphql";
import { TkaH3Span, TkaP2Span } from "@themes/font-tags";
import { formatCurrency } from "@utils/currency";
import { ORDER_FRAGMENT } from "./basket-timeline.graphql";
import { IconWrapper, ItemWrapper, TextWrapper } from "./basket-timeline.styles";
import { BasketEvent, TimelineProps } from "./basket-timeline.types";
import { Icon } from "../icon";
import { Timeline } from "../timeline";

// TODO: add-translations
export const BasketTimeline = ({
	currentStep,
	orientation = "horizontal",
	orderFragmentRef,
}: TimelineProps) => {
	const order = useFragment<basketTimeline_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	const { t } = useTkaTranslation("basketScreen");

	const customerTypeTitle =
		order.billingDetails?.customerType === "Business"
			? "Geschäftliche Bestellung"
			: "Private Bestelllung";
	const rateCount = order.selectedPaymentMethod?.chosenOption?.rateCount;

	const paymentMethodTitle =
		order.selectedPaymentMethod?.selectedPaymentMethodType === "MonthlyPayment"
			? `In ${rateCount ?? 48} monatlichen Zahlungen`
			: "Einmal Zahlung";

	const events: BasketEvent[] = [
		{
			label: t("basket_screen.basketTimelineBasketLabel"),
			icon: "shoppingBasketThin",
			subtitle:
				currentStep > 1
					? formatCurrency(order.cart?.totals.includingAllDiscounts.grossPrice)
					: undefined,
		},
		{
			label: t("basket_screen.basketTimelineContractLabel"),
			icon: "paragraphTwoColumn",
			subtitle: currentStep > 2 ? customerTypeTitle : undefined,
		},
		{
			label: t("basket_screen.basketTimelinePaymentLabel"),
			icon: "creditCardMastercard1",
			subtitle: currentStep > 3 ? paymentMethodTitle : undefined,
		},
		{
			label: t("basket_screen.basketTimelineOverviewLabel"),
			icon: "shoppingBasketCheck1",
		},
	];

	const renderContent = (event: BasketEvent, index: number) => {
		const finished = index < currentStep - 1;
		const icon = finished ? "checkCircle" : event.icon;
		return (
			<ItemWrapper>
				<IconWrapper finished={finished}>
					<Icon icon={icon} sizeInRem={1.5} />
				</IconWrapper>
				<TextWrapper>
					<TkaH3Span>{event.label}</TkaH3Span>
					{event.subtitle && <TkaP2Span>{event.subtitle}</TkaP2Span>}
				</TextWrapper>
			</ItemWrapper>
		);
	};

	return (
		<Timeline
			events={events}
			orientation={orientation}
			currentStep={currentStep}
			progressBarOffestRem={0.875}
			hideContent={orientation === "horizontal"}
			content={renderContent}
		/>
	);
};
