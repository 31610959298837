import { useMemo } from "react";

import { BusinessTypeDropdownProps } from "./business-type-dropdown.types";
import { getBusinessTypeOptions } from "./business-type-dropdown.utils";
import { ValidatedDropdown } from "../validated-dropdown";

export const BusinessTypeDropdown = <T,>(props: BusinessTypeDropdownProps<T>) => {
	const businessTypeOptions = useMemo(() => getBusinessTypeOptions(), []);

	return <ValidatedDropdown {...props} options={businessTypeOptions} />;
};
