import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";
import { H1Span } from "@themes/font-tags";

export const InputSwitchWrapper = tw.div`flex w-full items-center justify-between`;
export const Wrapper = tw.div`w-full md:w-[18.5rem] gap-24 flex flex-col z-[1000]`;

export const ButtonWrapper = tw.div`flex items-center justify-center md:hidden`;
export const Hr = styled.hr<{ tkaColor: TkaColor }>`
	${(p) => `color:${p.tkaColor};`}
`;
export const FilterHeadline = tw(H1Span)`md:hidden`;
