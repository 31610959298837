import { SideMenuContentProps } from "./side-menu-content.interface";
import { Wrapper } from "./side-menu-content.styles";

export const SideMenuContent = ({
	showInSideMenu,
	sideMenuOnly,
	...props
}: SideMenuContentProps) => {
	return <Wrapper showInSideMenu={showInSideMenu} sideMenuOnly={sideMenuOnly} {...props} />;
};
