import tw from "twin.macro";
import { TkaP2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const LineWrapper = tw.div`
	flex
	gap-16
`;

export const LineLabel = tw(TkaP2Span)`
	min-w-[9rem]
`;

export const IconLabelWrapper = tw.div`
	flex
	gap-8
`;
