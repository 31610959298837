import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	pt-20
	pb-16
	md:p-0
`;

export const OptionsWrapper = tw.div`
	flex
	flex-col
	py-8
`;

export const ItemWrapper = tw.div`
	flex
	flex-row
	gap-12
    md:gap-8
    py-16
    md:py-8
    md:px-16
	hover:opacity-60
	hover:cursor-pointer
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
`;
