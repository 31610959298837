import tw from "twin.macro";

export const InputsWrapper = tw.div`
	grid
	grid-cols-12
	md:gap-x-16
	lg:gap-x-32
	gap-y-24
`;

export const RowSpan6Wrapper = tw.div`
	col-span-12
	lg:col-span-6
	grid
	grid-cols-12
	lg:grid-cols-6
	gap-y-24
	md:gap-16
`;

export const RowSpan6 = tw.div`
	col-span-12
	md:col-span-6
`;

export const RowSpan4 = tw.div`
	col-span-12
	md:col-span-6
	lg:col-span-4
`;

export const RowSpan2 = tw.div`
	col-span-12
	md:col-span-6
	lg:col-span-2
`;

export const RowSpan6Placeholder = tw.div`
	hidden
	lg:block
	col-span-6
`;
