/**
 * @generated SignedSource<<5006c2bd8ece7e7aaf6932ad1c9c9b59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccountMemberKind = "Invitation" | "UserInAccount";
import { FragmentRefs } from "relay-runtime";
export type licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment$data = {
  readonly id: string;
  readonly kind: AccountMemberKind;
  readonly name: string;
  readonly user?: {
    readonly user: {
      readonly id: string;
    };
  };
  readonly " $fragmentType": "licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment";
};
export type licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment$key = {
  readonly " $data"?: licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "licensesNewSingleUserAssignmentForm_AccountMemberInlineFragment"
};

(node as any).hash = "889aef05e8dbb0c073cce00a00a76674";

export default node;
