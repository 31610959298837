import styled from "styled-components";
import { spacing12, spacing16, spacing8 } from "@themes/spacing";

export const ListWrapper = styled.div`
	display: grid;
	gap: ${spacing8.rem()};
	cursor: pointer;
`;

export const MainWrapper = styled.div<{ isEnabled: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${spacing16.rem()};
	opacity: ${(p) => (p.isEnabled ? 1 : 0.5)};
	padding-right: ${spacing12.rem()};
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	flex-grow: 1;
`;

export const IconsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100%;
`;
