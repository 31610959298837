import { TabPanel, TabPanelHeaderTemplateOptions } from "primereact/tabview";
import { useRef, useState } from "react";
import { DialogTemplate } from "@components/dialog-template";
import { LicensesNewAccountGroupAssignmentForm } from "@components/licenses-new-account-group-assignment-form/licenses-new-account-group-assignment-form.component";
import { LicensesNewAccountGroupAssignmentFormRef } from "@components/licenses-new-account-group-assignment-form/licenses-new-account-group-assignment-form.types";
import {
	LicensesNewSingleUserAssignmentForm,
	type LicensesNewSingleUserAssignmentFormRef,
} from "@components/licenses-new-single-user-assignment-form";
import { H1Span } from "@themes/font-tags";
import { StyledTabView, Wrapper } from "./licenses-new-rule-modal.styles";
import { LicensesNewRuleModalProps } from "./licenses-new-rule-modal.types";
import { Label } from "../../../../../../components/label";
import { ResponsiveBottomSheetModal } from "../../../../../../components/responsive-bottom-sheet-modal";

export const LicensesNewRuleModal = ({
	isVisible,
	onDismiss,
	onCreateRule,
	licenseGroupingId,
	groupOptions,
	fragmentRef,
	defaultActiveIndex = 0,
}: LicensesNewRuleModalProps) => {
	const [activeIndex, setActiveIndex] = useState(() => defaultActiveIndex);
	const groupFormRef = useRef<LicensesNewAccountGroupAssignmentFormRef>(null);
	const userFormRef = useRef<LicensesNewSingleUserAssignmentFormRef>(null);

	const handleSuccess = () => {
		onDismiss?.();
		onCreateRule?.();
	};

	const handleSubmit = () => {
		if (activeIndex === 0) {
			groupFormRef.current?.submit();
		}
		if (activeIndex === 1) {
			userFormRef.current?.submit();
		}
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Schließen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel="Erstellen"
				primaryButtonIconName="arrowRight"
				onPrimaryButtonClick={handleSubmit}
				maxWidthRem={34.6}
			>
				<Wrapper>
					<H1Span>Neue Zuweisung</H1Span>
					<StyledTabView
						activeIndex={activeIndex}
						onTabChange={(e) => {
							setActiveIndex(e.index);
						}}
					>
						<TabPanel
							headerTemplate={(options) => {
								return HeaderTemplate(options, activeIndex, "Gruppe", 0);
							}}
						>
							<LicensesNewAccountGroupAssignmentForm
								ref={groupFormRef}
								licenseGroupingId={licenseGroupingId}
								groupOptions={groupOptions}
								onSuccess={handleSuccess}
							/>
						</TabPanel>
						<TabPanel
							headerTemplate={(options) => {
								return HeaderTemplate(options, activeIndex, "Nutzer", 1);
							}}
						>
							<LicensesNewSingleUserAssignmentForm
								ref={userFormRef}
								queryFragmentRef={fragmentRef}
								licenseGroupingId={licenseGroupingId}
								onSuccess={handleSuccess}
							/>
						</TabPanel>
					</StyledTabView>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};

const HeaderTemplate = (
	options: TabPanelHeaderTemplateOptions,
	activeIndex: number,
	label: string,
	index: number,
) => {
	return (
		<Label
			label={label}
			size="big"
			severity={activeIndex === index ? "brandMain" : "default"}
			onEventClick={options.onClick}
		/>
	);
};
