/**
 * @generated SignedSource<<4deba593c222bee19d85695c3b9c3092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentElementsCollection_LearnOpportunityV2Fragment$data = {
  readonly typeDefinition: {
    readonly extension?: {
      readonly elementsSummary?: {
        readonly numExerciseElements: number;
        readonly numFileElements: number;
        readonly sumPodcastWithTimestampLengthInSec: number;
        readonly sumTextLengthInSec: number;
        readonly sumVideoLengthInSec: number;
      } | null;
    };
  };
  readonly " $fragmentType": "contentElementsCollection_LearnOpportunityV2Fragment";
};
export type contentElementsCollection_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: contentElementsCollection_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentElementsCollection_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentElementsCollection_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ElementsSummary",
                      "kind": "LinkedField",
                      "name": "elementsSummary",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numFileElements",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sumTextLengthInSec",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sumVideoLengthInSec",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sumPodcastWithTimestampLengthInSec",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numExerciseElements",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnContentExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "74145aa32a94726f96959e23993c0cfc";

export default node;
