/**
 * @generated SignedSource<<dad3fc7a0f6835550827d2bdd5b6bc07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type header_AuthViewerSchemaFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"avatarWithAccountSwitch_AuthViewerSchemaFragment">;
  readonly " $fragmentType": "header_AuthViewerSchemaFragment";
};
export type header_AuthViewerSchemaFragment$key = {
  readonly " $data"?: header_AuthViewerSchemaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"header_AuthViewerSchemaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "header_AuthViewerSchemaFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "avatarWithAccountSwitch_AuthViewerSchemaFragment"
    }
  ],
  "type": "AuthViewerSchema",
  "abstractKey": null
};

(node as any).hash = "5fae44666d5b3806a6f0d19785ec474f";

export default node;
