import { stack, vstack } from "@styled-system/patterns";

export const WrapperClass = vstack({
	gap: "32",
});

export const QuestionWrapperClass = stack({
	gap: "32",
	maxW: "[25rem]",
});
