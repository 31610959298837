import { Card } from "@components/card/card.component";
import { shade40 } from "@themes/colors";
import { NameWrapper, Wrapper } from "./user-card.styles";
import { Skeleton } from "../skeleton";

export const UserCardSkeleton = () => {
	return (
		<Card bodyPaddingInRem={0.75} borderColor={shade40}>
			<Wrapper>
				<Skeleton width="2.5rem" height="2.5rem" borderRadius="2.5rem" />
				<NameWrapper>
					<Skeleton width="4rem" />
					<Skeleton width="8rem" className="mt-8" />
				</NameWrapper>
			</Wrapper>
		</Card>
	);
};
