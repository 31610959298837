import { grid } from "@styled-system/patterns";

export const cardClass = grid({
	gridTemplateColumns: "[20% 1fr]",
	alignItems: "center",
	px: "16",
	py: "20",
	borderRadius: "12",
	background: "shade.10",
});
