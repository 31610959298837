import { Image as PrimeReactImage } from "primereact/image";
import React, { useEffect, useRef } from "react";
import { ImageProps } from "./image.interface";
import { StyledImage } from "./image.styles";

export const Image = ({ borderRadius, imageStyle, widthRem, heightRem, ...props }: ImageProps) => {
	const ref = useRef<PrimeReactImage>(null);

	useEffect(() => {
		if (props.draggable === false) {
			if (!ref.current) return;
			ref.current.getImage().draggable = false;
		}
	}, []);
	return (
		<StyledImage
			ref={ref}
			{...props}
			imageStyle={{ borderRadius, ...imageStyle }}
			widthRem={widthRem}
			heightRem={heightRem}
			width="100%"
			height="auto"
		/>
	);
};
