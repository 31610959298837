import { FC } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Path } from "@router/paths";
import { TkaH3Span } from "@themes/font-tags";
import { TREND_CARD_FRAGMENT } from "./trend-card.graphql";
import { CardWrapper } from "./trend-card.styles";
import { TrendCardProps } from "./trend-card.types";
import { Icon, TkaIconName } from "../icon";

export const TrendCard: FC<TrendCardProps> = ({ trendFragmentRef }) => {
	const trend = useFragment(TREND_CARD_FRAGMENT, trendFragmentRef ?? null);
	const navigate = useNavigate();

	const handleNavigateOnClick = () => {
		trend?.id && navigate(Path.trends.detail.withId(trend?.id).path);
	};

	return (
		<CardWrapper onClick={handleNavigateOnClick}>
			<Icon icon={trend?.trendData.icon as TkaIconName} sizeInRem={2.5} />
			<TkaH3Span>{trend?.trendData.name}</TkaH3Span>
		</CardWrapper>
	);
};
