import { RowWrapper } from "./profile-menu-row.styled";
import { Skeleton } from "../skeleton";

export const ProfileMenuRowSkeleton = () => {
	return (
		<RowWrapper>
			<Skeleton width="1.5rem" height="1.5rem" />
			<Skeleton width="5rem" />
		</RowWrapper>
	);
};
