import tw from "twin.macro";
import { TkaH3Span } from "@themes/font-tags";

export const CardWrapper = tw.div`
	flex
	md:w-[16rem]
	md:max-w-[16rem]
	lg:w-[22rem]
	lg:max-w-[22rem]
`;

export const Wrapper = tw.div`
	relative
	flex
	flex-col
	gap-16
	w-full
	justify-between
`;

export const TopColGroup = tw.div`
	flex
	flex-col
	items-start
`;

export const ColGroup = tw.div`
	flex
	flex-col
	gap-16
`;

export const Title = tw(TkaH3Span)`
	mt-2
`;

export const ParticipantsWrapper = tw.div`
	flex
	flex-row
	gap-8
`;

export const TimeIconWrapp = tw.div`
	flex
	flex-row
	items-center
	justify-between
	self-stretch
	gap-4
`;
