import styled from "styled-components";
import tw from "twin.macro";
import { TkaH3Span, TkaP3Span } from "@themes/font-tags";

export const CardWrapper = tw.div`
	min-h-[4rem]
	flex
`;

export const Wrapper = tw.div`
	flex
	w-full
	flex-wrap
	md:flex-nowrap
	items-center
	gap-x-12
`;

export const CardImage = tw.img`
	w-[4.375rem]
	h-full
	max-h-[4.375rem]
	object-cover
	rounded-[0.5rem]
`;

export const Content = tw.div`
	flex
	flex-col
	flex-1
	gap-4
`;

export const TitleSubtitleWrapper = tw.div`
	flex
	flex-col
`;

export const TagsRow = tw.div`
	flex
	flex-row
	justify-between
	self-stretch
`;

export const Title = tw(TkaH3Span)`
	line-clamp-2
`;

export const LicensesUsedWrapper = tw.div`
	flex
	flex-row
	gap-4
`;

export const LicenseBarContainer = tw.div`
	relative
	flex
	w-[3.75rem]
	h-[0.5rem]
	mt-[0.5rem]
	mb-[0.5rem]
	rounded-[5.875rem]
	bg-shade-10
	after:absolute
	after:z-10
	after:w-full
	after:h-full
	after:rounded-[5.875rem]
	after:shadow-[inset_-1px_1px_15px_0px_rgba(9,49,71,0.2)]
	after:mix-blend-multiply
	before:absolute
	before:z-10
	before:w-full
	before:h-full
	before:rounded-[5.875rem]
	before:shadow-[inset_0_0_1px_0_rgba(6,31,42,0.3)]
	before:mix-blend-darken
`;

export const UsedLicensesBar = styled.div<{ width: number }>`
	width: ${(props) => props.width}%;
	${tw`
		h-[100%]
		bg-brand-strong-100
		rounded-[5.875rem]
		z-[1]
	`};
`;

export const StyledTkaP3Span = tw(TkaP3Span)`
	ml-[0.25rem]
`;

export const StatusWrapper = tw.div`
	flex
	flex-col
	w-[100%]
	md:flex-row
	md:w-auto
`;

export const StatusInfo = tw.div`
	flex
	md:inline-flex
`;

export const BarWrapper = tw.div`
	flex
	items-center
	gap-[0.5rem]
`;

export const LabelWrapper = styled.div<{ hasPaddingBottom?: boolean }>`
	${tw`
		flex
		flex-col
		w-full
		border-t
		border-shade-10
		md:border-0
		md:flex-row
		md:w-auto
		mt-[0.75rem]
		pt-[0.75rem]
		md:mt-0
		md:pt-0
	`}
	${({ hasPaddingBottom }) => (hasPaddingBottom ? tw`pb-[0.75rem]` : tw`pb-0`)}
`;

export const LabelContainer = tw.div`
	flex
	items-center
	gap-[0.5rem]
	justify-between
`;
