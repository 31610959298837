import React from "react";
import { brandMain100, shade100 } from "@themes/colors";
import { IconMap } from "./icon.const";
import { IconProps } from "./icon.interface";
import { IconWrapper } from "./icon.styles";

export const Icon = ({
	icon,
	tkaColor = shade100,
	active,
	sizeInRem,
	style,
	circleOutlineColor,
	onClick,
	onMouseEnter,
	id,
	opacity,
}: IconProps) => {
	const colorToUse = active ? brandMain100 : tkaColor;

	const selectedIcon = IconMap[icon];
	const Icon = selectedIcon.icon;
	const adjustableStyles = selectedIcon.styles;

	const fillAlsoColor = adjustableStyles.includes("fill-also") ? colorToUse : undefined;

	const svgProps: React.SVGAttributes<SVGElement> = {
		...(sizeInRem && { width: `${sizeInRem}rem`, height: `${sizeInRem}rem` }),
		...(adjustableStyles.includes("stroke") && { stroke: colorToUse.getColor() }),
		...(adjustableStyles.includes("fill") && { fill: colorToUse.getColor() }),
		...style,
		...{ display: "block" },
	};
	return (
		<IconWrapper
			backgroundColor={circleOutlineColor}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			style={{ ...(onClick && { cursor: "pointer" }) }}
			fillAlsoColor={fillAlsoColor}
			id={id}
			opacity={opacity}
		>
			<Icon {...svgProps} />
		</IconWrapper>
	);
};
