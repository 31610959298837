import { graphql } from "babel-plugin-relay/macro";

export const WORD_PACKAGE_LIST_FRAGMENT = graphql`
	fragment wordPackageList_WordInventoryFragment on WordInventory {
		packages {
			wordPackage {
				id
			}
		}
		...wordPackageCard_WordInventoryFragment
	}
`;
