import { css } from "@styled-system/css";
import { flex, stack } from "@styled-system/patterns";

export const panelWrapperClass = stack({
	justifyContent: "center",
	alignItems: "center",
	w: "screen",
	h: "screen",
	bg: "shade.5",
});

export const panelClass = stack({
	bg: "shade.0",
	justifyContent: "center",
	alignItems: "center",
	p: "24",
	borderRadius: "12",
	boxShadow: "flyout",
	mdDown: {
		h: "screen",
		w: "screen",
	},
});

export const nameWrapperClass = css({
	mt: "12",
});

export const dividerClass = css({
	my: "24!",
});

export const textWrapperClass = flex({
	w: "3/4",
	flexDirection: "column",
	alignItems: "center",
	textAlign: "center",
	gap: "4",
});
