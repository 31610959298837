import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	query coachScreen_Query($coachProfileId: ID!) {
		...myCoachings_QueryFragment @arguments(coachProfileId: $coachProfileId)
		node(id: $coachProfileId) {
			... on CoachProfile {
				id
				profileData {
					description
					quote
					jobTitles
					socials {
						facebook
						linkedIn
						twitter
						website
						others
					}
				}
				coach {
					name
					email
					extension(userExtensionKind: Academies) {
						... on AcademiesUserExtension {
							avatar {
								thumbnail
								url
							}
							firstName
							lastName
							position
						}
					}
				}
				...socialsCard_CoachProfileFragment
			}
		}
	}
`;
