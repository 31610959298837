/**
 * @generated SignedSource<<c23d8025b89cee3bb404891f86db946a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type myAvailabilities_OverrideDaysAndTimesFragment$data = ReadonlyArray<{
  readonly calendarWeek: number;
  readonly calendarYear: number;
  readonly dayOfWeek: string;
  readonly timeSlots: ReadonlyArray<string>;
  readonly " $fragmentType": "myAvailabilities_OverrideDaysAndTimesFragment";
}>;
export type myAvailabilities_OverrideDaysAndTimesFragment$key = ReadonlyArray<{
  readonly " $data"?: myAvailabilities_OverrideDaysAndTimesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"myAvailabilities_OverrideDaysAndTimesFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "myAvailabilities_OverrideDaysAndTimesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSlots",
      "storageKey": null
    }
  ],
  "type": "DayAndTimesForCalendarWeek",
  "abstractKey": null
};

(node as any).hash = "1741a3d6408a07bb022ac7e8839178c5";

export default node;
