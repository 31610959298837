import tw from "twin.macro";

export const Wrapper = tw.div`
	grid
	grid-cols-4
	col-span-4
	content-start
	h-full
	gap-32
`;

export const ContentCardsWrapper = tw.div`
	col-span-4
	lg:col-span-2
`;

export const AttachmentsWrapper = tw.div`
	col-span-4
	lg:col-span-2
`;

export const InstructorsWrapper = tw.div`
	col-span-4
`;
