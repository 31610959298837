import { graphql } from "babel-plugin-relay/macro";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment shoppingCartTemplate_AuthViewerSchemaFragment on AuthViewerSchema {
		...avatarWithAccountSwitch_AuthViewerSchemaFragment
		...screenSidemenuLayout_AuthViewerSchemaFragment
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartTemplate_OrderFragment on Order {
		...basketTimeline_OrderFragment
		selectedPaymentMethod {
			paymentMethodId
		}
		...paypalButton_OrderFragment
	}
`;
