import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query shoppingCartOverview_Query($orderId: ID!, $skip: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartOverview_OrderFragment
			}
		}
		Viewer {
			Auth {
				...shoppingCartTemplate_AuthViewerSchemaFragment
			}
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartOverview_OrderFragment on Order {
		id
		...shoppingCartLegalForm_OrderFragment
		...shoppingCartTemplate_OrderFragment
	}
`;

export const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
	mutation shoppingCartOverview_ChangePaymentMethodMutation($input: ChangePaymentMethodInput!) {
		Billing {
			changePaymentMethod(input: $input) {
				order {
					...shoppingCartOverview_OrderFragment
				}
			}
		}
	}
`;

export const PAY_ORDER_MUTATION = graphql`
	mutation shoppingCartOverview_PayOrderMutation($input: PayOrderInput!) {
		Billing {
			payOrder(input: $input) {
				order {
					id
				}
			}
		}
	}
`;
