import { useFormik } from "formik";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { SelectItemOptionsType } from "primereact/selectitem";
import React, { useContext, useEffect } from "react";
import { useFragment, useMutation } from "react-relay";
import styled from "styled-components";
import { Calendar } from "@components/calendar";
import { InputText } from "@components/input-text";
import { Label } from "@components/label";
import { Message } from "@components/message";
import { RadioButton } from "@components/radio-button";
import { useToast } from "@hooks/useToast";
import { personnelSheetTab_CoachProfileFragment$key } from "@relay/personnelSheetTab_CoachProfileFragment.graphql";
import { personnelSheetTab_editMyBaseDataMutation } from "@relay/personnelSheetTab_editMyBaseDataMutation.graphql";
import { personnelSheetTab_editMyBusinessDataMutation } from "@relay/personnelSheetTab_editMyBusinessDataMutation.graphql";
import { personnelSheetTab_editMyProfileDataMutation } from "@relay/personnelSheetTab_editMyProfileDataMutation.graphql";
import {
	header,
	label,
	options,
	placeholder,
} from "@screens/profile/parts/personnel-sheet-tab/personnel-sheet-tab.consts";
import {
	COACH_PROFILE_FRAGMENT,
	EDIT_MY_BASE_DATA_MUTATION,
	EDIT_MY_BUSINESS_DATA_MUTATION,
	EDIT_MY_PROFILE_DATA_MUTATION,
} from "@screens/profile/parts/personnel-sheet-tab/personnel-sheet-tab.graphql";
import {
	FullWidthWrapper,
	Grid,
	HalfWidthButMobileFullWrapper,
	Hr,
	MarginRight8,
	QuarterWidthButMobileFullWrapper,
	RadioGroupWrapper,
	RadioOptionWrapper,
	Spacing12,
	Spacing4,
	Wrapper,
} from "@screens/profile/parts/personnel-sheet-tab/personnel-sheet-tab.styles";
import { ProfileContext } from "@screens/profile/profile.context";
import { shade10, shade100 } from "@themes/colors";
import { H1Span, TkaH2Span, TkaL1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { getLocalDate, parseLocalDate } from "@utils/date-utils";
import { FormState, PersonnelSheetTabProps } from "./personnel-sheet-tab.types";

export const PersonnelSheetTab = ({ coachProfileFragmentRef }: PersonnelSheetTabProps) => {
	const { setSaveOnClick } = useContext(ProfileContext);
	const coachProfileFragment = useFragment<personnelSheetTab_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);
	const [editProfileData] = useMutation<personnelSheetTab_editMyProfileDataMutation>(
		EDIT_MY_PROFILE_DATA_MUTATION,
	);
	const [editBaseData] = useMutation<personnelSheetTab_editMyBaseDataMutation>(
		EDIT_MY_BASE_DATA_MUTATION,
	);
	const [editBusinessData] = useMutation<personnelSheetTab_editMyBusinessDataMutation>(
		EDIT_MY_BUSINESS_DATA_MUTATION,
	);
	const { showSuccess, showError } = useToast();

	const formik = useFormik<FormState>({
		initialValues: {
			email: coachProfileFragment.profileData.basicPersonal.email ?? undefined,
			phoneNumber: coachProfileFragment.profileData.basicPersonal.phoneNumber ?? undefined,
			langugages: [...coachProfileFragment.profileData.languages],
			studies: coachProfileFragment.profileData.studies ?? undefined,
			certificateFiles: [],
			certificateAnswer: coachProfileFragment.profileData.certificateAnswer
				? coachProfileFragment.profileData.certificateAnswer + ""
				: undefined,
			experienceCoachingHours: coachProfileFragment.profileData.experience.coachingHours
				? coachProfileFragment.profileData.experience.coachingHours + ""
				: undefined,
			experienceCoachingFiles: [],
			experienceBranches: [...coachProfileFragment.profileData.experience.branches],
			experienceBranchesSteps: [...coachProfileFragment.profileData.experience.branchesSteps],
			educationFreeText: coachProfileFragment.profileData.educationFreeText ?? "",
			educationMoreThan150h: coachProfileFragment.profileData.educationMoreThan150h ?? false,
			coachingStrongPointsTopics: [
				...coachProfileFragment.profileData.coachingStrongPointsTopics,
			],
			methods: [...coachProfileFragment.profileData.methods],
			digitalAffinity1: coachProfileFragment.profileData.digitalAffinity1 ?? false,
			digitalAffinity2: coachProfileFragment.profileData.digitalAffinity2 ?? false,
			personalPhilosophyCoachingStyle:
				coachProfileFragment.profileData.personalPhilosophyCoachingStyle ?? undefined,
			personalPhilosophyVisionMission:
				coachProfileFragment.profileData.personalPhilosophyVisionMission ?? undefined,
			referencesCoachingExperience: [],
			referencesPublications:
				coachProfileFragment.profileData.referencesPublications ?? undefined,
			dateOfBirth: coachProfileFragment.baseData.birthDate
				? parseLocalDate(coachProfileFragment.baseData.birthDate)
				: undefined,
			placeOfBirth: coachProfileFragment.baseData.birthPlace,
			nationality: coachProfileFragment.baseData.nationality,
			insuranceNumber: coachProfileFragment.businessData.insuranceNumber ?? undefined,
			iban: coachProfileFragment.businessData.iban ?? undefined,
			workeeNumber: coachProfileFragment.businessData.employerNumber ?? undefined,
			addressCountry: "Deutschland",
			addressStreet: coachProfileFragment.baseData.address.lineOne,
			addressHouseNumber: coachProfileFragment.baseData.address.lineTwo,
			addressCity: coachProfileFragment.baseData.address.city,
			addressPostalCode: coachProfileFragment.baseData.address.postalCode,
		},
		onSubmit: () => {},
	});

	const onChange = {
		studies: (e: string | undefined) => {
			formik.setFieldValue("studies", e);
		},
		email: (e?: string) => {
			formik.setFieldValue("email", e);
		},
		phoneNumber: (e?: string) => {
			formik.setFieldValue("phoneNumber", e);
		},
		certificateAnswer: (e?: string) => {
			formik.setFieldValue("certificateAnswer", e);
		},
		educationMoreThan150h: (boo: RadioButtonChangeEvent) => {
			formik.setFieldValue("educationMoreThan150h", boo.value);
		},
		digitalAffinity1: (boo: RadioButtonChangeEvent) => {
			formik.setFieldValue("digitalAffinity1", boo.value);
		},
		digitalAffinity2: (boo: RadioButtonChangeEvent) => {
			formik.setFieldValue("digitalAffinity2", boo.value);
		},
		personalPhilosophyCoachingStyle: (e?: string) => {
			formik.setFieldValue("personalPhilosophyCoachingStyle", e);
		},
		personalPhilosophyVisionMission: (e?: string) => {
			formik.setFieldValue("personalPhilosophyVisionMission", e);
		},
		referencesPublications: (e?: string) => {
			formik.setFieldValue("referencesPublications", e);
		},
		dateOfBirth: (e?: Date) => {
			formik.setFieldValue("dateOfBirth", e);
		},
		placeOfBirth: (e?: string) => {
			formik.setFieldValue("placeOfBirth", e);
		},
		nationality: (e?: string) => {
			formik.setFieldValue("nationality", e);
		},
		insuranceNumber: (e?: string) => {
			formik.setFieldValue("insuranceNumber", e);
		},
		iban: (e?: string) => {
			formik.setFieldValue("iban", e);
		},
		workeeNumber: (e?: string) => {
			formik.setFieldValue("workeeNumber", e);
		},
		addressCity: (e?: string) => {
			formik.setFieldValue("addressCity", e);
		},
		addressPostalCode: (e?: string) => {
			formik.setFieldValue("addressPostalCode", e);
		},
		addressStreet: (e?: string) => {
			formik.setFieldValue("addressStreet", e);
		},
		addressHouseNumber: (e?: string) => {
			formik.setFieldValue("addressHouseNumber", e);
		},
		onReferencesCoachingExperienceFileUpload: (file?: File) => {
			formik.setFieldValue("referencesCoachingExperience", [
				...formik.values.referencesCoachingExperience,
				file,
			]);
		},
		onCertificateFilesFileUpload: (file?: File) => {
			formik.setFieldValue("certificateFiles", [...formik.values.certificateFiles, file]);
		},
		experienceCoachingHours: (e?: string) => {
			formik.setFieldValue("experienceCoachingHours", e);
		},
		experienceCoachingFiles: (file?: File) => {
			formik.setFieldValue("experienceCoachingFiles", [
				...formik.values.experienceCoachingFiles,
				file,
			]);
		},
		langugages: (e: MultiSelectChangeEvent) => {
			formik.setFieldValue("langugages", e.value);
		},
		experienceBranches: (e: MultiSelectChangeEvent) => {
			formik.setFieldValue("experienceBranches", e.value);
		},

		experienceBranchesSteps: (e: MultiSelectChangeEvent) => {
			formik.setFieldValue("experienceBranchesSteps", e.value);
		},
		educationFreeText: (text?: string) => {
			formik.setFieldValue("educationFreeText", text);
		},
		coachingStrongPointsTopics: (e: MultiSelectChangeEvent) => {
			formik.setFieldValue("coachingStrongPointsTopics", e.value);
		},
		methods: (e: MultiSelectChangeEvent) => {
			formik.setFieldValue("methods", e.value);
		},
	};

	const bottomText = {
		experienceBranchesSteps: "Mit welchen Führungsstufen hast du Erfahrung?",
	};

	const handleSaveOnClick = () => {
		editProfileData({
			variables: {
				input: {
					profileData: {
						jobTitles: coachProfileFragment.profileData.jobTitles,
						quote: coachProfileFragment.profileData.quote,
						description: coachProfileFragment.profileData.description,
						avatarId: coachProfileFragment.profileData.avatar?.id,
						socials: coachProfileFragment.profileData.socials,
						basicPersonal: {
							firstName: coachProfileFragment.profileData.basicPersonal.firstName,
							lastName: coachProfileFragment.profileData.basicPersonal.lastName,
							title: coachProfileFragment.profileData.basicPersonal.title,
							salutation: coachProfileFragment.profileData.basicPersonal.salutation,
							email: formik.values.email,
							phoneNumber: formik.values.phoneNumber,
						},
						certificateAnswer: formik.values.certificateAnswer
							? +formik.values.certificateAnswer
							: undefined,
						coachingStrongPointsTopics: formik.values.coachingStrongPointsTopics,
						methods: formik.values.methods,
						digitalAffinity1: formik.values.digitalAffinity1,
						digitalAffinity2: formik.values.digitalAffinity2,
						educationFreeText: formik.values.educationFreeText,
						educationMoreThan150h: formik.values.educationMoreThan150h,
						studies: formik.values.studies,
						personalPhilosophyVisionMission:
							formik.values.personalPhilosophyVisionMission,
						personalPhilosophyCoachingStyle:
							formik.values.personalPhilosophyCoachingStyle,
						referencesPublications: formik.values.referencesPublications,
						certificateFileIds: [],
						experience: {
							branches: formik.values.experienceBranches,
							branchesSteps: formik.values.experienceBranchesSteps,
							coachingFileIds: [],
							coachingHours: formik.values.experienceCoachingHours
								? +formik.values.experienceCoachingHours
								: undefined,
						},
						languages: formik.values.langugages,
						referencesCoachingExperienceIds: [],
					},
				},
			},
			onCompleted: () => {
				const localDate = formik.values.dateOfBirth
					? getLocalDate(formik.values.dateOfBirth)
					: undefined;
				editBaseData({
					variables: {
						input: {
							baseData: {
								nationality: formik.values.nationality ?? "",
								birthDate: localDate?.toString() ?? "",
								gender: coachProfileFragment.baseData.gender,
								birthPlace: formik.values.placeOfBirth ?? "",
								address: {
									city: formik.values.addressCity ?? "",
									lineOne: formik.values.addressStreet ?? "",
									lineTwo: formik.values.addressHouseNumber ?? "",
									postalCode: formik.values.addressPostalCode ?? "",
								},
							},
						},
					},
					onCompleted: () => {
						editBusinessData({
							variables: {
								input: {
									businessData: {
										bic: coachProfileFragment.businessData.bic,
										insuranceNumber: formik.values.insuranceNumber,
										employerNumber: formik.values.workeeNumber,
										iban: formik.values.iban,
										taxNumber: coachProfileFragment.businessData.taxNumber,
									},
								},
							},
							onCompleted: () => {
								showSuccess({ summary: "Erfolgreich gespeichert." });
							},
						});
					},
				});
			},
			onError: () => {
				showError({ summary: "Ein fehler ist aufgetreten." });
			},
		});
	};

	useEffect(() => {
		setSaveOnClick(() => handleSaveOnClick);
	}, [formik.values]);

	return (
		<Wrapper>
			<Grid>
				<FullWidthWrapper>
					<Message
						highlighted={true}
						severity={"neutral"}
						summary={"Relevant für Coaches"}
						detail={
							"Wir arbeiten miteinander auf Augenhöhe, daher kommunizieren wir per du. Wir hoffen das passt auch für dich?"
						}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<H1Span>Grundlegende Informationen</H1Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>Kontaktinformationen*</TkaH2Span>
				</FullWidthWrapper>
				<HalfWidthButMobileFullWrapper>
					<InputText
						label={label.email}
						value={formik.values.email}
						onChange={onChange.email}
					/>
				</HalfWidthButMobileFullWrapper>
				<HalfWidthButMobileFullWrapper>
					<InputText
						label={label.phoneNumber}
						value={formik.values.phoneNumber}
						onChange={onChange.phoneNumber}
					/>
				</HalfWidthButMobileFullWrapper>

				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.langugages}</TkaH2Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<ProfileChips
						options={options.langugages}
						value={formik.values.langugages}
						onChange={onChange.langugages}
						onCloseClick={(e) => {
							formik.setFieldValue(
								"langugages",
								formik.values.langugages.filter((l) => l !== e),
							);
						}}
					/>
				</FullWidthWrapper>

				<Hr tkaColor={shade10} />

				<FullWidthWrapper>
					<H1Span>{"Qualifikationen & Ausbildung*"}</H1Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<Header text={header.studies} />
					<InputText
						textArea
						rows={3}
						label={label.studies}
						placeholder={placeholder.studies}
						value={formik.values.studies}
						onChange={onChange.studies}
					/>
				</FullWidthWrapper>

				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.certificateFiles}</TkaH2Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<InputText
						type={"number"}
						label={label.certificateAnswer}
						placeholder={placeholder.certificateAnswer}
						value={formik.values.certificateAnswer}
						onChange={onChange.certificateAnswer}
					/>
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />

				<FullWidthWrapper>
					<H1Span>Berufserfahrung*</H1Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.experienceCoachingHours}</TkaH2Span>
					<InputText
						type={"number"}
						value={formik.values.experienceCoachingHours}
						label={label.experienceCoachingHours}
						onChange={onChange.experienceCoachingHours}
					/>
				</FullWidthWrapper>

				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.experienceBranches}</TkaH2Span>
				</FullWidthWrapper>

				<FullWidthWrapper>
					<ProfileChips
						options={options.experienceBranches}
						value={formik.values.experienceBranches}
						onChange={onChange.experienceBranches}
						label={label.experienceBranches}
						onCloseClick={(e) => {
							formik.setFieldValue(
								"experienceBranches",
								formik.values.experienceBranches.filter((l) => l !== e),
							);
						}}
					/>
					<Spacing4 />
				</FullWidthWrapper>
				<FullWidthWrapper>
					<ProfileChips
						options={options.experienceBranchesSteps}
						value={formik.values.experienceBranchesSteps}
						onChange={onChange.experienceBranchesSteps}
						label={label.experienceBranchesSteps}
						onCloseClick={(e) => {
							formik.setFieldValue(
								"experienceBranchesSteps",
								formik.values.experienceBranchesSteps.filter((l) => l !== e),
							);
						}}
					/>
					<Spacing4 />
					<TkaL1Span tkaColor={shade100}>{bottomText.experienceBranchesSteps}</TkaL1Span>
				</FullWidthWrapper>

				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.educationFreeText}</TkaH2Span>
					<InputText
						textArea
						rows={3}
						label={label.educationFreeText}
						placeholder={placeholder.educationFreeText}
						value={formik.values.educationFreeText}
						onChange={onChange.educationFreeText}
					/>
				</FullWidthWrapper>

				<FullWidthWrapper>
					<TkaP3Span tkaColor={shade100}>{label.educationMoreThan150h}</TkaP3Span>
					<br />
					<RadioGroupWrapper>
						<RadioOptionWrapper>
							<RadioButton
								value={true}
								checked={formik.values.educationMoreThan150h === true}
								onChange={onChange.educationMoreThan150h}
							/>
							<TkaP2Span tkaColor={shade100}>Ja</TkaP2Span>
						</RadioOptionWrapper>
						<RadioOptionWrapper>
							<RadioButton
								value={false}
								checked={formik.values.educationMoreThan150h === false}
								onChange={onChange.educationMoreThan150h}
							/>
							<TkaP2Span tkaColor={shade100}>Nein</TkaP2Span>
						</RadioOptionWrapper>
					</RadioGroupWrapper>
				</FullWidthWrapper>

				<Hr tkaColor={shade10} />

				<FullWidthWrapper>
					<H1Span>Fachgebiete und Methoden*</H1Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.coachingStrongPointsTopics}</TkaH2Span>{" "}
					<br />
					<ProfileChips
						options={options.coachingStrongPointsTopics}
						value={formik.values.coachingStrongPointsTopics}
						onChange={onChange.coachingStrongPointsTopics}
						label={label.coachingStrongPointsTopics}
						onCloseClick={(e) => {
							formik.setFieldValue(
								"coachingStrongPointsTopics",
								formik.values.coachingStrongPointsTopics.filter((l) => l !== e),
							);
						}}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.methods}</TkaH2Span> <br />
					<ProfileChips
						options={options.methods}
						value={formik.values.methods}
						onChange={onChange.methods}
						label={label.methods}
						onCloseClick={(e) => {
							formik.setFieldValue(
								"methods",
								formik.values.methods.filter((l) => l !== e),
							);
						}}
					/>
				</FullWidthWrapper>

				<Hr tkaColor={shade10} />
				<TkaH2Span tkaColor={shade100}>{header.digitalAffinity1}</TkaH2Span>
				<FullWidthWrapper>
					<TkaP3Span tkaColor={shade100}>{label.digitalAffinity1}</TkaP3Span>
					<br />
					<RadioGroupWrapper>
						<RadioOptionWrapper>
							<RadioButton
								value={true}
								checked={formik.values.digitalAffinity1 === true}
								onChange={onChange.digitalAffinity1}
							/>
							<TkaP2Span tkaColor={shade100}>Ja</TkaP2Span>
						</RadioOptionWrapper>
						<RadioOptionWrapper>
							<RadioButton
								value={false}
								checked={formik.values.digitalAffinity1 === false}
								onChange={onChange.digitalAffinity1}
							/>
							<TkaP2Span tkaColor={shade100}>Nein</TkaP2Span>
						</RadioOptionWrapper>
					</RadioGroupWrapper>
				</FullWidthWrapper>

				<FullWidthWrapper>
					<TkaP3Span tkaColor={shade100}>{label.digitalAffinity2}</TkaP3Span>
					<br />
					<RadioGroupWrapper>
						<RadioOptionWrapper>
							<RadioButton
								value={true}
								checked={formik.values.digitalAffinity2 === true}
								onChange={onChange.digitalAffinity2}
							/>
							<TkaP2Span tkaColor={shade100}>Ja</TkaP2Span>
						</RadioOptionWrapper>
						<RadioOptionWrapper>
							<RadioButton
								value={false}
								checked={formik.values.digitalAffinity2 === false}
								onChange={onChange.digitalAffinity2}
							/>
							<TkaP2Span tkaColor={shade100}>Nein</TkaP2Span>
						</RadioOptionWrapper>
					</RadioGroupWrapper>
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />
				<H1Span>Persönliche Philosophie*</H1Span>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>
						{header.personalPhilosophyCoachingStyle}
					</TkaH2Span>
					<InputText
						textArea
						rows={3}
						label={label.personalPhilosophyCoachingStyle}
						value={formik.values.personalPhilosophyCoachingStyle}
						onChange={onChange.personalPhilosophyCoachingStyle}
						placeholder={placeholder.personalPhilosophyCoachingStyle}
					/>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>
						{header.personalPhilosophyVisionMission}
					</TkaH2Span>
					<InputText
						textArea
						rows={3}
						label={label.personalPhilosophyVisionMission}
						value={formik.values.personalPhilosophyVisionMission}
						onChange={onChange.personalPhilosophyVisionMission}
						placeholder={placeholder.personalPhilosophyVisionMission}
					/>
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />
				<H1Span>Referenzen</H1Span>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.referencesPublications}</TkaH2Span>
					<InputText
						textArea
						rows={3}
						value={formik.values.referencesPublications}
						placeholder={placeholder.referencesPublications}
						onChange={onChange.referencesPublications}
						label={label.referencesPublications}
					/>
				</FullWidthWrapper>
				<Hr tkaColor={shade10} />
				<FullWidthWrapper>
					<H1Span>Für die Coaching Auszahlungen</H1Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.dateOfBirth}</TkaH2Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<Message
						severity={"success"}
						highlighted={true}
						summary={"Erforderliche Daten für die Rechnungserstellung"}
						detail={
							"Bitte fülle die erforderlichen Steuerdaten korrekt und vollständig aus. Dies erlaubt uns dir das Honorar pünktlich zu überweisen."
						}
					/>
				</FullWidthWrapper>
				<HalfWidthButMobileFullWrapper>
					<Calendar
						placeholder={placeholder.dateOfBirth}
						label={label.dateOfBirth}
						value={formik.values.dateOfBirth}
						onChange={onChange.dateOfBirth}
					/>
				</HalfWidthButMobileFullWrapper>
				<HalfWidthButMobileFullWrapper>
					<InputText
						placeholder={placeholder.placeOfBirth}
						label={label.placeOfBirth}
						value={formik.values.placeOfBirth}
						onChange={onChange.placeOfBirth}
					/>
				</HalfWidthButMobileFullWrapper>
				<HalfWidthButMobileFullWrapper>
					<InputText
						placeholder={placeholder.nationality}
						label={label.nationality}
						value={formik.values.nationality}
						onChange={onChange.nationality}
					/>
				</HalfWidthButMobileFullWrapper>
				<HalfWidthButMobileFullWrapper>
					<InputText
						placeholder={placeholder.insuranceNumber}
						label={label.insuranceNumber}
						value={formik.values.insuranceNumber}
						onChange={onChange.insuranceNumber}
					/>
				</HalfWidthButMobileFullWrapper>
				<HalfWidthButMobileFullWrapper>
					<InputText
						placeholder={placeholder.workeeNumber}
						label={label.workeeNumber}
						value={formik.values.workeeNumber}
						onChange={onChange.workeeNumber}
					/>
				</HalfWidthButMobileFullWrapper>
				<HalfWidthButMobileFullWrapper>
					<InputText
						placeholder={placeholder.iban}
						label={label.iban}
						value={formik.values.iban}
						onChange={onChange.iban}
					/>
				</HalfWidthButMobileFullWrapper>
				<FullWidthWrapper>
					<TkaH2Span tkaColor={shade100}>{header.addressCountry}</TkaH2Span>
				</FullWidthWrapper>
				<FullWidthWrapper>
					<InputText status="disabled" label="Land" value="Deutschland" />
				</FullWidthWrapper>
				<QuarterWidthButMobileFullWrapper>
					<InputText
						label={label.addressStreet}
						value={formik.values.addressStreet}
						placeholder={placeholder.addressStreet}
						onChange={onChange.addressStreet}
					/>
				</QuarterWidthButMobileFullWrapper>
				<QuarterWidthButMobileFullWrapper>
					<InputText
						label={label.addressHouseNumber}
						value={formik.values.addressHouseNumber}
						placeholder={placeholder.addressHouseNumber}
						onChange={onChange.addressHouseNumber}
					/>
				</QuarterWidthButMobileFullWrapper>
				<QuarterWidthButMobileFullWrapper>
					<InputText
						label={label.addressCity}
						value={formik.values.addressCity}
						placeholder={placeholder.addressCity}
						onChange={onChange.addressCity}
					/>
				</QuarterWidthButMobileFullWrapper>
				<QuarterWidthButMobileFullWrapper>
					<InputText
						label={label.addressPostalCode}
						value={formik.values.addressPostalCode}
						placeholder={placeholder.addressPostalCode}
						onChange={onChange.addressPostalCode}
					/>
				</QuarterWidthButMobileFullWrapper>
			</Grid>
		</Wrapper>
	);
};

export const Header = ({ text }: { text: string }) => {
	return (
		<>
			<TkaH2Span tkaColor={shade100}>{text}</TkaH2Span>
			<Spacing12 />
		</>
	);
};
export const ProfileChips = ({
	label,
	onChange,
	value,
	options,
	onCloseClick,
}: {
	label?: string;
	onChange: (e: MultiSelectChangeEvent) => void;
	value: any;
	options: SelectItemOptionsType | undefined;
	onCloseClick?: (e?: string) => void;
}) => {
	const selectedItemTemplate = (e?: string) => {
		if (!e) return <div></div>;
		const handleCloseClick = () => {
			onCloseClick?.(e);
		};
		return (
			<div style={{ width: "min-content" }}>
				<Label
					severity={"default"}
					label={e}
					closable={!!onCloseClick}
					size={"big"}
					onCloseClick={handleCloseClick}
				/>
				<MarginRight8 />
			</div>
		);
	};
	return (
		<>
			{label && (
				<>
					<TkaP3Span tkaColor={shade100}>{label}</TkaP3Span> <br />
				</>
			)}
			<StyledMultiSelect
				onChange={onChange}
				value={value}
				options={options}
				display={"chip"}
				filter={true}
				selectedItemTemplate={selectedItemTemplate}
			/>
		</>
	);
};
const StyledMultiSelect = styled(MultiSelect)`
	&.p-multiselect {
		height: 36px;
		display: flex;
	}
	&.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
		display: flex;
		gap: 0.5rem;
	}
`;
