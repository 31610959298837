import { ProgressBar } from "primereact/progressbar";
import styled from "styled-components";
import { TkaColor } from "@themes/colors";
import { TkaL1Span } from "@themes/font-tags";
import { spacing8 } from "@themes/spacing";

export const ProgressBarWrapper = styled.div`
	box-sizing: border-box;
	gap: ${spacing8.rem()};
	display: flex;
	align-items: center;
`;

export const ProgressBarValue = styled(TkaL1Span)`
	height: 0.625rem;
	flex-shrink: 0;
`;

export const StyledProgressBar = styled(ProgressBar)<{
	value: number;
	labelColor?: TkaColor;
	activeColor: TkaColor;
	inactiveColor: TkaColor;
	heightInRem?: number;
	hasBorderRadius: boolean;
	borderRadius?: number;
}>`
	&.p-progressbar {
		flex-grow: 1;
		height: ${(props) => props.heightInRem ?? 0.375}rem;
		background: ${(p) => p.inactiveColor};
		${(p) =>
			!p.hasBorderRadius
				? "border-radius: 0px;"
				: p.borderRadius
				? `border-radius: ${p.borderRadius}rem;`
				: ""}
	}
	.p-progressbar-value {
		background: ${(p) => p.activeColor};
	}
	.p-progressbar-label {
		display: none;
	}
`;
