import { useFragment } from "react-relay";

import { shade60 } from "@themes/colors";
import { TkaL1Span, TkaP2Span } from "@themes/font-tags";
import { USER_FRAGMENT } from "./participant-preview.graphql";
import { NameWrapper, Wrapper } from "./participant-preview.styles";
import { ParticipantPreviewProps } from "./participant-preview.types";
import { Button } from "../button";
import { UserAvatar } from "../user-avatar";

// TODO: add-translations
export const ParticipantPreview = ({
	subtitle,
	canBeRemoved,
	onRemoved,
	userFragmentRef,
}: ParticipantPreviewProps) => {
	const user = useFragment(USER_FRAGMENT, userFragmentRef ?? null);
	if (!user) return null;
	return (
		<Wrapper>
			<UserAvatar sizeInRem={1.25} userFragmentRef={user} />
			<NameWrapper>
				<TkaP2Span>{user.name}</TkaP2Span>
				<TkaL1Span tkaColor={shade60}>{subtitle ?? "Zugesagt"}</TkaL1Span>
			</NameWrapper>
			{canBeRemoved && <Button colorVersion="error" iconName="logout" onClick={onRemoved} />}
		</Wrapper>
	);
};
