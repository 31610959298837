import { FC } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { TextAndMediaSection } from "@components/text-and-media-section";
import { trendMedia_TrendMediaFragment$key } from "@relay/trendMedia_TrendMediaFragment.graphql";
import { Path } from "@router/paths";
import { QUERY_FRAGMENT } from "./trend-media.graphql";
import { TrendMediaProps } from "./trend-media.types";

export const TrendMedia: FC<TrendMediaProps> = ({ queryFragmentRef }) => {
	const data = useFragment<trendMedia_TrendMediaFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef ?? null,
	);
	const navigate = useNavigate();

	const trendTags = data?.trendData.tags.map((tag) => tag.id);
	const handleOnTrendOffersClick = () => {
		navigate(Path.progression.path, { state: { filters: { tagIds: trendTags } } });
	};

	return (
		<TextAndMediaSection
			mediaPosition="left"
			headline={data?.trendData.name}
			text={data?.trendData.description}
			fileURL={data?.trendMedia?.file?.url ?? undefined}
			fileType={data?.trendMedia?.kind}
			primaryButtonLabel={`${data?.trendData.name} Angebote`}
			primaryButtonOnClick={handleOnTrendOffersClick}
		/>
	);
};
