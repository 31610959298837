/**
 * @generated SignedSource<<efb328c87ba47c4573f2398be503ffce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type mostRecentLearnableCard_mostRecentLearnableFragment$data = {
  readonly lastUpdated: string | null;
  readonly learnable: {
    readonly id: string;
    readonly kind?: LearnableKind;
    readonly root?: {
      readonly id: string;
      readonly image: {
        readonly url: string | null;
      } | null;
      readonly nextContentNodeId: string | null;
      readonly structureDefinition: {
        readonly extension?: {
          readonly diploma?: string | null;
        };
        readonly progressPercentage?: number | null;
        readonly title: string;
      };
    } | null;
  };
  readonly " $fragmentType": "mostRecentLearnableCard_mostRecentLearnableFragment";
};
export type mostRecentLearnableCard_mostRecentLearnableFragment$key = {
  readonly " $data"?: mostRecentLearnableCard_mostRecentLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"mostRecentLearnableCard_mostRecentLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "LearnOpportunityV2",
    "kind": "LinkedField",
    "name": "root",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "image",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "structureDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "progressPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "extension",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "diploma",
                        "storageKey": null
                      }
                    ],
                    "type": "PublishedRootExtensionImpl",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LearnOpportunityRootStructureDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nextContentNodeId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "mostRecentLearnableCard_mostRecentLearnableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "learnable",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "PublishedCourseLearnable",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "PublishedMicroLearningLearnable",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/)
          ],
          "type": "PublishedOneToOneCoachingLearnable",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnableWithLastUpdated",
  "abstractKey": null
};
})();

(node as any).hash = "249ea7e83dcd327c1212ae1a671ae5c5";

export default node;
