import { brandStrong100 } from "@themes/colors";
import { CheckboxProps, CheckboxStatus } from "./checkbox.interface";

import { StyledCheckbox } from "./checkbox.styles";

export const Checkbox = ({ status, checked, onChange, value, backgroundColor }: CheckboxProps) => {
	const className = status === CheckboxStatus.Error ? "p-invalid" : "";
	const disabled = status === CheckboxStatus.Disabled;
	return (
		<StyledCheckbox
			className={className}
			disabled={disabled}
			checked={checked}
			value={value}
			onChange={onChange}
			backgroundColor={backgroundColor || brandStrong100}
		/>
	);
};
