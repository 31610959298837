/**
 * @generated SignedSource<<35c1d22fd49661a9e520eda131cdfab5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type IHKCertificateOrderStateKind = "ErrorOccurred" | "NotIHK" | "Ordered" | "ReadyToOrder" | "RewardNotAchievedYet";
import { FragmentRefs } from "relay-runtime";
export type branchNodesTab_LearnOpportunityV2Fragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly extension?: {
      readonly ihkState?: IHKCertificateOrderStateKind;
      readonly reducedData?: {
        readonly isIHK: boolean;
      } | null;
    };
    readonly title: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"branchNodesTree_LearnOpportunityV2Fragment">;
  readonly " $fragmentType": "branchNodesTab_LearnOpportunityV2Fragment";
};
export type branchNodesTab_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: branchNodesTab_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"branchNodesTab_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "branchNodesTab_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedData",
                      "kind": "LinkedField",
                      "name": "reducedData",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isIHK",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ihkState",
                      "storageKey": null
                    }
                  ],
                  "type": "PublishedRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "branchNodesTree_LearnOpportunityV2Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "5be68339024845bf0c8b3e2e2534d26e";

export default node;
