import { TabPanel } from "primereact/tabview";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { BottomNavigationTemplate } from "@components/bottom-navigation-template";
import { TabBar } from "@components/tab-bar";
import { withSuspense } from "@components/with-suspense/with-suspense.component";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { profile_Query } from "@relay/profile_Query.graphql";
import { Path } from "@router/paths";
import { PersonnelSheetTab } from "@screens/profile/parts/personnel-sheet-tab";
import { ProfileTab } from "@screens/profile/parts/profile-tab";
import { ProfileContext } from "@screens/profile/profile.context";
import { Spacing24 } from "@screens/profile/profile.styles";
import { H1Span } from "@themes/font-tags";
import { QUERY } from "./profile.graphql";
import { ProfileScreenSkeleton } from "./profile.skeleton";

const ProfileScreenComponent = () => {
	const {
		Viewer: { Auth, Coaching },
	} = useLazyLoadQuery<profile_Query>(QUERY, {}, { fetchPolicy: "store-and-network" });
	const { t } = useTkaTranslation("myProfile");
	const { isMediumUp, isXLargeUp } = useWindowSize();
	const headerKeys = {
		profile: "Profil",
		form: "Personalbogen für Coaches",
	};
	const [activeIndex, setActiveIndex] = useState(0);
	const navigate = useNavigate();

	const handleBackOnClick = () => {
		navigate(Path.profileMenu.path);
	};
	const [saveOnClick, setSaveOnClick] = useState<() => void>(() => {});
	const contextValue = {
		saveOnClick,
		setSaveOnClick,
	};
	const handleActionButtonOnClick = () => {
		saveOnClick();
	};

	return (
		<ProfileContext.Provider value={contextValue}>
			<ScreenWithProfileMenuLayout
				canGoBack={!isMediumUp}
				bottomContent={
					<BottomNavigationTemplate
						actionButtonLabel={"Speichern"}
						onActionButtonClick={handleActionButtonOnClick}
						canGoBack={!isXLargeUp}
						onBackClick={handleBackOnClick}
					/>
				}
			>
				<H1Span>{t("Mein Profil")}</H1Span>
				<Spacing24 />

				<TabBar
					disableMaxParentHeight
					renderActiveOnly={false}
					activeIndex={activeIndex}
					onTabChange={(e) => {
						setActiveIndex(e.index);
					}}
				>
					<TabPanel header={t(headerKeys.profile)}>
						{Auth.currentUser?.user && Coaching.MyCoachProfile && (
							<ProfileTab
								userFragmentRef={Auth.currentUser.user}
								coachProfileFragmentRef={Coaching.MyCoachProfile}
							/>
						)}
					</TabPanel>
					<TabPanel header={t(headerKeys.form)}>
						{Coaching && Coaching.MyCoachProfile && (
							<PersonnelSheetTab coachProfileFragmentRef={Coaching.MyCoachProfile} />
						)}
					</TabPanel>
				</TabBar>
			</ScreenWithProfileMenuLayout>
		</ProfileContext.Provider>
	);
};

export const ProfileScreen = withSuspense(ProfileScreenComponent, ProfileScreenSkeleton);
