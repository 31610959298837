/**
 * @generated SignedSource<<b19ae4b82fb3942b267ce97ad3e72fa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dropdownUser_UserFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"userAvatar_UserFragment">;
  readonly " $fragmentType": "dropdownUser_UserFragment";
};
export type dropdownUser_UserFragment$key = {
  readonly " $data"?: dropdownUser_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dropdownUser_UserFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "dropdownUser_UserFragment"
};

(node as any).hash = "7643cdf22840b9e744bc7bf7811667f2";

export default node;
