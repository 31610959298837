/**
 * @generated SignedSource<<ea1b5d44279b62ffe7ad969bebdb5ab2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Icon = "arrowLoop" | "bridgeOverRiver" | "computerWithHeart" | "dotsConnected" | "flowerInCircle" | "handWithFlower" | "handsWithHeart" | "lightBulb" | "peopleHighFive" | "personWithStars" | "rocket" | "workingDesk";
import { FragmentRefs } from "relay-runtime";
export type trendUspBoxes_TrendUspBoxesFragment$data = {
  readonly trendData: {
    readonly advantages: ReadonlyArray<{
      readonly description: string;
      readonly heading: string;
      readonly icon: Icon | null;
    }>;
  };
  readonly " $fragmentType": "trendUspBoxes_TrendUspBoxesFragment";
};
export type trendUspBoxes_TrendUspBoxesFragment$key = {
  readonly " $data"?: trendUspBoxes_TrendUspBoxesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendUspBoxes_TrendUspBoxesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "trendUspBoxes_TrendUspBoxesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TrendData",
      "kind": "LinkedField",
      "name": "trendData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Advantage",
          "kind": "LinkedField",
          "name": "advantages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "heading",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "icon",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Trend",
  "abstractKey": null
};

(node as any).hash = "91bd6ae3cfc7e8363faec43c76211411";

export default node;
