/**
 * @generated SignedSource<<5ab3fb5956c57081dac5320c94ef81ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userNotifications_ViewerQueryTypeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"microLearningReward_ViewerQueryTypeFragment">;
  readonly " $fragmentType": "userNotifications_ViewerQueryTypeFragment";
};
export type userNotifications_ViewerQueryTypeFragment$key = {
  readonly " $data"?: userNotifications_ViewerQueryTypeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userNotifications_ViewerQueryTypeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userNotifications_ViewerQueryTypeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "microLearningReward_ViewerQueryTypeFragment"
    }
  ],
  "type": "ViewerQueryType",
  "abstractKey": null
};

(node as any).hash = "bbb95cc272cb3c960085f3c83aacc9ac";

export default node;
