import { useState } from "react";
import { BottomSheetContext } from "./bottom-sheet.context";
import { BottomSheetStyles, ChildrenWrapper, StyledBottomSheet } from "./bottom-sheet.styles";
import { BottomSheetProps } from "./bottom-sheet.types";

export const BottomSheet = ({
	isVisible = false,
	dismissable = true,
	blocking,
	onDismiss,
	children,
}: BottomSheetProps) => {
	const [blockBackdrop, setBlockBackdrop] = useState(false);

	const handleOnDismiss = () => {
		if (!dismissable || blocking || blockBackdrop) return;
		onDismiss?.();
	};

	return (
		<>
			<StyledBottomSheet
				open={isVisible}
				dismissable={dismissable}
				onDismiss={handleOnDismiss}
				sibling={<div data-rsbs-backdrop="true" onClick={handleOnDismiss} />}
				blocking={false}
			>
				<BottomSheetContext.Provider value={{ blockBackdrop, setBlockBackdrop }}>
					<ChildrenWrapper dismissable={dismissable}>{children}</ChildrenWrapper>
				</BottomSheetContext.Provider>
			</StyledBottomSheet>
			<BottomSheetStyles />
		</>
	);
};
