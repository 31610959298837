import { useLazyLoadQuery } from "react-relay";
import { Button } from "@components/button";
import { Icon } from "@components/icon";
import { MaterialCard } from "@components/material-card";
import { withSuspense } from "@components/with-suspense/with-suspense.component";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithNavbarSidemenuLayout } from "@layouts/screen-with-navbar-sidemenu-layout";
import { orientation_Query } from "@relay/orientation_Query.graphql";
import { StartPotentialAnalysisModalButton } from "@screens/orientation/parts/start-potential-analysis-modal/start-potential-analysis-modal.component";
import { useStartPotentialAnalysis } from "@screens/orientation/parts/use-start-potential-analysis/use-start-potential-analysis.hook";
import { brandMain80, shade80 } from "@themes/colors";
import { H1Span, TkaH2Span, TkaL1Span, TkaP2Span } from "@themes/font-tags";
import { formatDateToLocaleString } from "@utils/date-utils";
import { QUERY } from "./orientation.graphql";
import { OrientationScreenSkeleton } from "./orientation.skeleton";
import {
	BarIndicator,
	BottomBarWrapper,
	BottomTextGrid,
	BottomTextLeft,
	BottomTextRight,
	ExplanationTextWrapper,
	HighlightedText,
	LegendItemWrapper,
	LegendWrapper,
	PDFDownloadWrapper,
	SidemenuWrapper,
	StatisticsBarWrapper,
	StatisticsWrapper,
	TextWrapper,
	UnorderedList,
	Wrapper,
} from "./orientation.styles";
import { PotentialAnalysisBar } from "./parts/potential-analysis-bar";
import { PotentialAnalysisVersion } from "./parts/start-potential-analysis-modal/start-potential-analysis-modal.types";

//TODO: add-translations
export const OrientationScreenComponent = () => {
	const { isMediumUp, isXLargeUp } = useWindowSize();
	const {
		Viewer: {
			PotentialAnalysis: { MyPotentialAnalysisResult: mainResult },
		},
	} = useLazyLoadQuery<orientation_Query>(QUERY, {}, { fetchPolicy: "store-and-network" });

	const { goToAnalysis } = useStartPotentialAnalysis();
	const { isBusinessAccount } = usePermissions();

	const hasResult = !!mainResult;
	const isLongAnalysis = !!mainResult?.isLongAnalysis;

	const handleRepeatPotentialAnalysisOnClick = () => {
		goToAnalysis(!isLongAnalysis);
	};

	const handleArrangeConsultationOnClick = () => {
		window.open(process.env.REACT_APP_CONSULTATION_LINK, "_blank");
	};
	const currentAnalysisVersion = isLongAnalysis
		? PotentialAnalysisVersion.Long
		: hasResult
		? PotentialAnalysisVersion.Short
		: undefined;

	const titlePostfix = isLongAnalysis ? "MAX" : hasResult ? "mini" : "";

	return (
		<ScreenWithNavbarSidemenuLayout
			keepSidemenuContentOnMobile
			sideMenu={
				<SidemenuWrapper>
					<H1Span>Potenzialanalyse {titlePostfix}</H1Span>
					<ExplanationTextWrapper>
						<TkaH2Span>Vergleich Selbstbild nach Dimensionen</TkaH2Span>
						{!hasResult ? (
							<TextWrapper>
								<TkaP2Span>
									Möchtest du besser verstehen, wo deine Potenziale liegen? Wie du
									deine persönliche und berufliche Zukunft aktiv gestalten kannst?
									Mit der Potenzialanalyse mini bekommst du inhalb von fünf
									Minuten einen ersten Eindruck, wo du bezüglich deinen Haltungen
									und Zukunftsfähigkeiten im Vergleich zu anderen stehst. Probiere
									es aus, ganz einfach und ohne Registrierung. Mit der
									Potenzialanalyse MAX erhältst du eine umfassende, ganzheitliche
									Auswertung zu deinem Potenzial, was dich ausmacht und was dir
									aktuell wichtig ist.
									<br />
									<br />
									Wir lassen dich mit den Analyseergebnissen auch nicht alleine.
									Nutze unsere unentgeltliche Beratung durch eine:n Expert:in.
								</TkaP2Span>
							</TextWrapper>
						) : (
							<TextWrapper>
								<TkaP2Span>
									In der folgenden Grafik findest du deine eigene Einschätzung im
									Vergleich zu einem Benchmark (▲), den wir gewinnen, indem wir
									den Mittelwert aller bislang vorliegenden Antworten zu Grunde
									legen. Liegt dein Wert rechts davon, könnte das für eine deiner
									Stärken sprechen. Liegt dein Mittelwert links vom Benchmark
									beschreibst du eine vergleichsweise niedrige Ausprägung.
								</TkaP2Span>

								{mainResult.pdf && (
									<PDFDownloadWrapper>
										<MaterialCard
											isExpanded
											noBorder={isXLargeUp}
											onClick={() => {
												window.open(mainResult.pdf!.url!, "_blank");
											}}
											icon={"downloadBottom"}
											title={"Potenzialanalyse"}
											description={formatDateToLocaleString(
												mainResult.pdf.uploadDateTime,
											)}
										/>
									</PDFDownloadWrapper>
								)}
							</TextWrapper>
						)}
					</ExplanationTextWrapper>
				</SidemenuWrapper>
			}
			bottomContent={
				<BottomBarWrapper>
					{hasResult && (
						<Button
							label={isMediumUp ? "Potenzialanalyse wiederholen" : undefined}
							iconName={"synchronizeArrowClock"}
							colorVersion="tertiary"
							onClick={handleRepeatPotentialAnalysisOnClick}
						/>
					)}
					<StartPotentialAnalysisModalButton
						currentAnalysisVersion={currentAnalysisVersion}
					/>
				</BottomBarWrapper>
			}
			canGoBack={!isMediumUp}
		>
			<Wrapper>
				<StatisticsWrapper>
					<StatisticsBarWrapper>
						{mainResult?.categoryResults.map((categoryResult) => {
							return (
								<PotentialAnalysisBar
									key={categoryResult.name}
									label={categoryResult.name}
									step={categoryResult.result ?? 0}
									benchmark={categoryResult.benchmark ?? undefined}
								/>
							);
						})}
					</StatisticsBarWrapper>
					<LegendWrapper>
						<LegendItemWrapper>
							<BarIndicator />
							<TkaL1Span tkaColor={shade80}>Selbsteinschätzung</TkaL1Span>
						</LegendItemWrapper>
						<LegendItemWrapper>
							<Icon icon="arrowTop" sizeInRem={1.25} />
							<TkaL1Span tkaColor={shade80}>Benchmark zu Anderen</TkaL1Span>
						</LegendItemWrapper>
					</LegendWrapper>
				</StatisticsWrapper>

				{hasResult && (
					<BottomTextGrid>
						<BottomTextLeft hasFullWidth={!isBusinessAccount}>
							<TkaP2Span tkaColor={brandMain80}>
								<b>In dieser Auswertung siehst du:</b>
								<UnorderedList>
									<li>
										Deine ausgeprägten, stärker wahrnehmbaren Haltungen und
										Zukunftsfähigkeiten&nbsp;
										<HighlightedText>(Wertung von 4 bis 6)</HighlightedText>
									</li>
									<li>
										Deine weniger stark ausgeprägten, eher weniger wahrnehmbaren
										Haltungen und Zukunftsfähigkeiten&nbsp;
										<HighlightedText>(Wertung von 1 bis 2)</HighlightedText>
									</li>
								</UnorderedList>
								<br />
								<b>Wenn du Bilanz ziehst:</b>
								<UnorderedList>
									<li>In welchen Bereichen möchtest du weiterhin so bleiben?</li>
									<li>
										In welchen Bereichen möchtest du künftig mehr Aufmerksamkeit
										schenken?
									</li>
								</UnorderedList>
								<br />
								Wünschst du zum besseren Verständnis der Ergebnisse ein&nbsp;
								<u>unentgeltliches Beratungsgespräch</u> mit unseren Expert:innen?
								{!isLongAnalysis && (
									<>
										<br />
										Oder magst du vorher die <u>Potenzialanalyse MAX</u>{" "}
										ausfüllen, die dir zu allen Bereichen ein ausgeprägteres
										Profil liefert?
									</>
								)}
							</TkaP2Span>
						</BottomTextLeft>
						{!isBusinessAccount && (
							<BottomTextRight>
								<TkaP2Span tkaColor={brandMain80} bold>
									Jetzt kostenlos beraten lassen
								</TkaP2Span>
								<Button
									colorVersion="outline"
									label="Zum Beratungsgespräch"
									iconName="arrowRight"
									onClick={handleArrangeConsultationOnClick}
								/>
							</BottomTextRight>
						)}
					</BottomTextGrid>
				)}
			</Wrapper>
		</ScreenWithNavbarSidemenuLayout>
	);
};
export const OrientationScreen = withSuspense(
	OrientationScreenComponent,
	OrientationScreenSkeleton,
);
