import { graphql } from "babel-plugin-relay/macro";

export const COACHING_OFFER_FRAGMENT = graphql`
	fragment coachingOfferCard_CoachingOfferFragment on CoachingOffer {
		id
		description {
			name
			description
			shortDescription
			tags {
				id
				data {
					name
				}
			}
			image {
				thumbnail
				url
			}
			product {
				...offerCard_ProductFragment
			}
		}
		setting {
			kind
		}
		associatedCoaches {
			coach {
				name
			}
		}
		licenseAvailability {
			... on LicenseAvailabilityFreeLicensesAvailable {
				numAvailable
			}
		}
	}
`;
