import { flex, stack } from "@styled-system/patterns";

export const accountOptionsWrapperClass = stack({
	mt: "16",
	gap: "4",
	w: "full",
	overflowY: "auto",
	p: "4",
	scrollbarWidth: "thin",
	maxHeight: "full",
});

export const accountOptionClass = flex({
	border: "1px solid",
	borderColor: "shade.20",
	borderRadius: "12",
	p: "12",
	pr: "32",
	justifyContent: "space-between",
	cursor: "pointer",
	_hover: {
		bg: "shade.80",
		color: "shade.0",
		transition: "[all 0.2s ease]",
		fill: "shade.0",
		"& svg": {
			fill: "shade.0",
		},
	},
});

export const accountContentClass = flex({
	gap: "12",
	alignItems: "center",
});
