import { useRef } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { Card } from "@components/card/card.component";
import { Icon } from "@components/icon";
import { UserAvatar } from "@components/user-avatar";
import { HTMLMouseEvent } from "@customTypes/index";
import { usePermissions } from "@hooks/use-permissions";
import { UserContextMenuRef } from "@screens/account/parts/members-tab/parts/user-context-menu/user-context-menu.types";
import { selectCurrentUserId } from "@slices/CurrentUserSlice";
import { shade40 } from "@themes/colors";
import { TkaH3Span, TkaP3Span } from "@themes/font-tags";
import { USER_FRAGMENT } from "./user-card.graphql";
import {
	ContextMenuWrapper,
	NameWrapper,
	Wrapper,
	LabelsWrapper,
	NameAndGroupsWrapper,
} from "./user-card.styles";
import { UserCardProps } from "./user-card.types";
import { Checkbox } from "../checkbox";
import { Label } from "../label";

//TODO: add-translations
export const UserCard = ({
	isSelected = false,
	showContextMenu,
	onSelectToggeled,
	userFragmentRef,
	children,
}: UserCardProps) => {
	const ref = useRef<UserContextMenuRef>(null);
	const userInAccountWrapper = useFragment(USER_FRAGMENT, userFragmentRef ?? null);
	const {
		user: { user },
	} = userInAccountWrapper;

	const userId = useSelector(selectCurrentUserId);

	const { isAccountOwner } = usePermissions();

	const handleOpenContextMenu = (event: HTMLMouseEvent) => {
		ref.current?.toggle(event, event?.currentTarget);
	};

	const isOwner = userInAccountWrapper.isManager;

	const handleOnCheckboxChanged = () => {
		onSelectToggeled?.(user);
	};

	const title = isOwner ? "Manager:In" : "Benutzer:In";
	const canBeEdited = (isAccountOwner && user?.id !== userId) || showContextMenu;

	return (
		<>
			<Card bodyPaddingInRem={0.75} borderColor={shade40}>
				<Wrapper>
					{user && (
						<>
							<Checkbox checked={isSelected} onChange={handleOnCheckboxChanged} />
							<UserAvatar userFragmentRef={user} />
						</>
					)}
					<NameWrapper>
						<TkaP3Span>{title}</TkaP3Span>
						<NameAndGroupsWrapper>
							<TkaH3Span>{user?.name}</TkaH3Span>
							<LabelsWrapper>
								{userInAccountWrapper.groups.map((group: any) => (
									<Label
										key={group.id}
										size="smal"
										label={group.name}
										severity="info"
									/>
								))}
							</LabelsWrapper>
						</NameAndGroupsWrapper>
					</NameWrapper>
					{canBeEdited && (
						<ContextMenuWrapper>
							<Icon
								icon="contextMenu"
								sizeInRem={1}
								onClick={handleOpenContextMenu}
							/>
						</ContextMenuWrapper>
					)}
				</Wrapper>
			</Card>
			{children?.(ref)}
		</>
	);
};
