import styled from "styled-components";
import tw from "twin.macro";
import { brandStrong120 } from "@themes/colors";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-16
	p-16
`;
export const ListWrapper = tw.div`flex flex-col gap-12`;
export const ListItem = tw.div`flex gap-8 items-start `;
export const ReferenceNumberWrapper = styled.span`
	color: ${brandStrong120.getColor(1)};
	font-weight: bold;
`;
export const CenterWrapper = tw.div`flex items-center justify-center`;
