import { useId } from "react";
import { GlobalCalendarStyle, GrowDiv, SmallTextBellow, StyledLabel } from "./calendar.styles";
import { CalendarSkeletonProps } from "./calendar.types";
import { getLabelColor } from "./calendar.util";
import { InputTextSkeleton } from "../input-text/input-text.skeleton";
import { Skeleton } from "../skeleton";

export const CalendarSkeleton = ({ label, smallTextBellow, inline }: CalendarSkeletonProps) => {
	const fieldId = useId();
	const labelColor = getLabelColor(false, false);

	return (
		<div>
			{label && (
				<StyledLabel tkaColor={labelColor} htmlFor={fieldId}>
					{label}
				</StyledLabel>
			)}
			<GrowDiv>
				{inline ? (
					<Skeleton width="22rem" height="20rem" borderRadius="1rem" />
				) : (
					<InputTextSkeleton />
				)}
			</GrowDiv>
			{smallTextBellow && (
				<SmallTextBellow tkaColor={labelColor}>{smallTextBellow}</SmallTextBellow>
			)}
			<GlobalCalendarStyle />
		</div>
	);
};
