import tw from "twin.macro";

export const CardWrapper = tw.div`
flex
flex-col
justify-center
items-center
h-[10rem]
gap-[1rem]
p-[1.5rem]
rounded-[0.75rem]
border-[1px]
border-shade-10
flex-1
cursor-pointer
`;
