import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { H1Span, TkaH2Span, TkaP2Anchor, TkaP2Span } from "@themes/font-tags";
import { CONTACT_EMAIL } from "./imprint.consts";
import { ParagraphWrapper, Wrapper } from "./imprint.styles";

//TODO: add-translations
export const ImprintScreen = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
			<Wrapper>
				<H1Span>Impressum</H1Span>
				<ParagraphWrapper>
					<TkaH2Span>Angaben gemäß § 5 TMG</TkaH2Span>
					<TkaP2Span>
						CONSTELLATION ACADEMY digital GmbH
						<br />
						Beuthstrasse 8<br />
						10117 Berlin
					</TkaP2Span>
				</ParagraphWrapper>
				<ParagraphWrapper>
					<TkaP2Span>
						Handelsregister: HRB 255433 B
						<br />
						Registergericht: Amtsgericht Charlottenburg
					</TkaP2Span>
				</ParagraphWrapper>
				<ParagraphWrapper>
					<TkaH2Span>Vertreten durch:</TkaH2Span>
					<TkaP2Span>Raphael Joshua Baruch</TkaP2Span>
				</ParagraphWrapper>
				<ParagraphWrapper>
					<TkaH2Span>Kontakt:</TkaH2Span>
					<TkaP2Span>
						E-Mail:&nbsp;
						<TkaP2Anchor underline href={`mailto:${CONTACT_EMAIL}`}>
							{CONTACT_EMAIL}
						</TkaP2Anchor>
					</TkaP2Span>
				</ParagraphWrapper>
				<ParagraphWrapper>
					<TkaH2Span>Umsatzsteuer-ID</TkaH2Span>
					<TkaP2Span>
						Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
						DE362348477
					</TkaP2Span>
				</ParagraphWrapper>
				<ParagraphWrapper>
					<TkaH2Span>EU-Streitschlichtung</TkaH2Span>
					<TkaP2Span>
						Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
						(OS) bereit:&nbsp;
						<TkaP2Anchor
							underline
							target="_blank"
							href="https://ec.europa.eu/consumers/odr/"
						>
							https://ec.europa.eu/consumers/odr/
						</TkaP2Anchor>
						.<br />
						Unsere E-Mail-Adresse finden Sie oben im Impressum.
					</TkaP2Span>
				</ParagraphWrapper>
				<ParagraphWrapper>
					<TkaH2Span>Verbraucherstreitbeilegung/ Universalschlichtungsstelle</TkaH2Span>
					<TkaP2Span>
						Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
						einer Verbraucherschlichtungsstelle teilzunehmen.
					</TkaP2Span>
				</ParagraphWrapper>
			</Wrapper>
		</ScreenWithProfileMenuLayout>
	);
};
