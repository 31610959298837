import styled from "styled-components";
import tw from "twin.macro";

export const ItemWrapper = tw.div`
	flex
	flex-row
	gap-12
	items-center
`;

export const IconWrapper = styled.div<{ finished: boolean }>`
	${tw`
		flex
		items-center
		justify-center
		p-8
		rounded-12
		border
		border-shade-40
	`}
	${({ finished }) => (finished ? tw`bg-brand-strong-100 border-brand-strong-100` : "")}
`;

export const TextWrapper = tw.div`
	flex
	flex-col
`;
