import { TabPanel } from "primereact/tabview";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFragment, useQueryLoader } from "react-relay";
import { appointmentOverviewModal_Query } from "@relay/appointmentOverviewModal_Query.graphql";
import { selectCurrentUserId } from "@slices/CurrentUserSlice";
import { H1Span } from "@themes/font-tags";
import { isDateInNextMinutes } from "@utils/date-utils";
import { AVAILABILITY_FRAGMENT, QUERY } from "./appointment-overview-modal.graphql";
import { LabelsWrapper, TitleWrapper, Wrapper } from "./appointment-overview-modal.styles";
import { AppointmentOverviewModalProps } from "./appointment-overview-modal.types";
import { AppointmentDetails } from "./parts/appointment-details";
import { AppointmentPresence } from "./parts/appointment-presence";
import { AppointmentRating } from "./parts/appointment-rating";
import { AppointmentStatusLabel } from "../appointment-status-label";
import { Label } from "../label";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";
import { TabBar } from "../tab-bar";

// TODO: add-translations
export const AppointmentOverviewModal = ({
	isVisible = false,
	onDismiss,
	onCancelAppointmentAsParticipant,
	onCancelAppointmentAsCoach,
	onAppointmentRated,
	availabilityFragmentRef,
}: AppointmentOverviewModalProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const [query, loadQuery] = useQueryLoader<appointmentOverviewModal_Query>(QUERY);
	const userId = useSelector(selectCurrentUserId);

	useEffect(() => {
		loadQuery({ filterByGroupIds: [] });
	}, []);

	const isCoach = availability.coachProfile?.coach?.id === userId;

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const notConfirmeParticipants = participants.filter(
		(participant) => participant.participationStatus !== "NotConfirmed",
	);

	const canEditPresence = isDateInNextMinutes(availability.data.startDateTime, 5);

	const presenceTabPrefix = "Anwesenheitscheck";
	const presenceTabStatus = ` (${notConfirmeParticipants.length}/${participants.length})`;
	const presenceTabTitle = `${presenceTabPrefix}${canEditPresence ? presenceTabStatus : ""}`;

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<Wrapper>
				<TitleWrapper>
					<H1Span>{availability?.booking.offer?.description?.name}</H1Span>
					<LabelsWrapper>
						<AppointmentStatusLabel availabilityFragmentRef={availability} />
						{availability?.booking.offer?.description?.tags.map((tag) => (
							<Label key={tag.id} label={tag.data.name} />
						))}
					</LabelsWrapper>
				</TitleWrapper>
				<TabBar>
					<TabPanel header="Details">
						{query && (
							<AppointmentDetails
								onClose={onDismiss}
								onCancelAppointmentAsParticipant={onCancelAppointmentAsParticipant}
								onCancelAppointmentAsCoach={onCancelAppointmentAsCoach}
								availabilityFragmentRef={availability}
								queryFragmentRef={query}
							/>
						)}
					</TabPanel>
					{!isCoach && (
						<TabPanel header="Bewerte deine Session">
							<AppointmentRating
								onClose={onDismiss}
								onAppointmentRated={onAppointmentRated}
								availabilityFragmentRef={availability}
							/>
						</TabPanel>
					)}
					{isCoach && (
						<TabPanel header={presenceTabTitle}>
							<AppointmentPresence
								onClose={onDismiss}
								availabilityFragmentRef={availability}
							/>
						</TabPanel>
					)}
				</TabBar>
			</Wrapper>
		</ResponsiveBottomSheetModal>
	);
};
