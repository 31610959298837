import tw from "twin.macro";

export const Wrapper = tw.div`
   relative
`;

export const AvatarWrapper = tw.div`
    cursor-pointer
`;

export const FullScreenWrapper = tw.div`
    fixed
    inset-0
    flex
    flex-col
    z-50
    bg-shade-0
`;

export const ContentWrapper = tw.div`
    flex-1
    p-24
    overflow-y-auto
`;
