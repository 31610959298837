import { PathParams } from "@thekeytechnology/epic-ui";
import { MouseEventHandler, useContext, useEffect, useRef, useState } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { useParams } from "react-router-dom";
import { Button } from "@components/button";
import { Icon } from "@components/icon";
import { Label } from "@components/label";
import { ResponsiveBottomSheetOverlayPanel } from "@components/responsive-bottom-sheet-overlay-panel";
import { ContextMenuWrapper } from "@components/user-card/user-card.styles";
import { withSuspense } from "@components/with-suspense";
import { usePermissions } from "@hooks/use-permissions";
import { useWindowSize } from "@hooks/use-window-size";
import { groupDetails_Query } from "@relay/groupDetails_Query.graphql";
import { groupDetails_QueryFragment$key } from "@relay/groupDetails_QueryFragment.graphql";
import { groupDetailsAccountMembersRefetchQuery } from "@relay/groupDetailsAccountMembersRefetchQuery.graphql";
import { GroupsPath } from "@router/paths/profile";
import { AccountContext } from "@screens/account";
import { TkaH2Span } from "@themes/font-tags";

import useDebounce from "@utils/useDebounce";
import { GroupDetailsContext } from "./group-details.context";
import { QUERY, QUERY_FRAGMENT } from "./group-details.graphql";
import { BottomBatchEditSpacer, HeaderWrapper, Wrapper } from "./group-details.styles";
import { BatchEditGroupMembers } from "./parts/batch-edit-group-members";
import { GroupDetailsContextMenu } from "./parts/group-details-context-menu";
import { GroupMembersList } from "./parts/group-members-list/group-members-list.component";
import { GroupMembersListRef } from "./parts/group-members-list/group-members-list.types";
import { SearchWrapper, ButtonsWrapper } from "../groups-tab/groups-tab.styles";
import { useGroupName } from "../hooks/use-group-name";
import {
	LabelsWrapper,
	MembersAndLabelsWrapper,
	SearchBarMobileWrapper,
} from "../members-tab/members-tab.styles";
import { SelectedMember } from "../members-tab/members-tab.types";
import { InviteMemberModal } from "../members-tab/parts/invite-member-modal";
import { RefetchContext } from "../refetch-context/refetch.context";
import { SearchBar } from "../search-bar";
import { SearchBarMobile } from "../search-bar/search-bar-mobile";

export const GroupDetailsComponent = () => {
	const { groupId: optionalGroupId } = useParams<PathParams<typeof GroupsPath>>();
	const groupId = optionalGroupId ?? "";
	const query = useLazyLoadQuery<groupDetails_Query>(
		QUERY,
		{ id: groupId },
		{ fetchPolicy: "network-only" },
	);

	const { data: fragment } = usePaginationFragment<
		groupDetailsAccountMembersRefetchQuery,
		groupDetails_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const ref = useRef<GroupMembersListRef>(null);

	const contextMenuRef = useRef<ResponsiveBottomSheetOverlayPanel>(null);

	const { addMembersConnectionId } = useContext(AccountContext);
	const { isAccountOwner } = usePermissions();
	const { isMediumUp } = useWindowSize();

	const [selectedMembers, setSelectedMembers] = useState<SelectedMember[]>([]);
	const [searchIsVisibleOnMobile, setSearchIsVisibleOnMobile] = useState(false);
	const [isVisibleInviteMemberModal, setIsVisibleInviteMemberModal] = useState(false);
	const [searchByNameOrEmail, setSearchByNameOrEmail] = useState<string>("");
	const [numberOfConfirmedMembers, setNumberOfConfirmedMembers] = useState<number>(0);
	const [numberOfUnconfirmedMembers, setNumberOfUnconfirmedMembers] = useState<number>(0);

	const debouncedSearchValue = useDebounce(searchByNameOrEmail, 500);

	const accountMembersConnection = fragment.AccountMemberManagement.AccountMembers.accountMembers;
	const accountMembersConnectionId = accountMembersConnection.__id;

	useEffect(() => {
		accountMembersConnectionId && addMembersConnectionId(accountMembersConnectionId);
	}, [accountMembersConnectionId]);

	const handleToggleOnClick = () => {
		if (isMediumUp) return;
		setSearchIsVisibleOnMobile(!searchIsVisibleOnMobile);
	};

	const handleToggleMember = (member: SelectedMember) => {
		const memberIndex = selectedMembers.findIndex(
			(selectedMember) => selectedMember.id === member.id,
		);
		if (memberIndex !== -1) {
			const copy = selectedMembers.slice();
			copy.splice(memberIndex, 1);
			setSelectedMembers(copy);
		} else {
			setSelectedMembers([...selectedMembers, member]);
		}
	};

	const handleClearSelectedMembers = () => {
		setSelectedMembers([]);
	};

	const handleAddUserClick = () => {
		setIsVisibleInviteMemberModal(true);
	};

	const handleOnDismissInviteMemberModal = () => {
		setIsVisibleInviteMemberModal(false);
	};

	const handleContextMenuOnClick: MouseEventHandler<HTMLDivElement> = (event) => {
		contextMenuRef.current?.toggle(event, event.currentTarget);
	};

	const handleSearchOnChange = (text: string) => {
		setSearchByNameOrEmail(text);
	};

	const handleRefetch = () => {
		ref.current?.refetch();
	};

	useEffect(() => {
		handleRefetch();
	}, [searchByNameOrEmail]);

	const groupName = useGroupName(
		groupId,
		query.AccountMemberManagement?.UserInAccountGroupWrapper?.group?.name,
	);

	const canEditGroup =
		query.AccountMemberManagement.UserInAccountGroupWrapper?.group?.isBuiltIn === false;
	const isBatchEditVisible = selectedMembers.length > 1;

	return (
		<RefetchContext.Provider value={{ refetch: handleRefetch }}>
			<GroupDetailsContext.Provider
				value={{
					selectedMembers,
					toogleMember: handleToggleMember,
					clearSelectedMembers: handleClearSelectedMembers,
					numberOfConfirmedMembers,
					numberOfUnconfirmedMembers,
					setNumberOfConfirmedMembers,
					setNumberOfUnconfirmedMembers,
				}}
			>
				<Wrapper>
					<HeaderWrapper>
						<MembersAndLabelsWrapper>
							<TkaH2Span>{groupName}</TkaH2Span>
							<LabelsWrapper>
								<Label label={`${numberOfConfirmedMembers} Mitglieder`} />
								<Label label={`${numberOfUnconfirmedMembers} unbestätigt`} />
							</LabelsWrapper>
						</MembersAndLabelsWrapper>
						<SearchWrapper>
							<SearchBar
								value={searchByNameOrEmail}
								onChange={handleSearchOnChange}
								onClick={handleToggleOnClick}
							/>
						</SearchWrapper>
						{canEditGroup && (
							<ContextMenuWrapper>
								<Icon
									icon="contextMenu"
									sizeInRem={1}
									onClick={handleContextMenuOnClick}
								/>
							</ContextMenuWrapper>
						)}
						{searchIsVisibleOnMobile && (
							<SearchBarMobileWrapper>
								<SearchBarMobile
									value={searchByNameOrEmail}
									onChange={handleSearchOnChange}
								/>
							</SearchBarMobileWrapper>
						)}
						{isAccountOwner && (
							<ButtonsWrapper>
								<Button
									fillParent
									colorVersion="outline"
									label="Hinzufügen"
									iconName="add"
									onClick={handleAddUserClick}
								/>
							</ButtonsWrapper>
						)}
					</HeaderWrapper>
					<BatchEditGroupMembers groupMembersConnectionId={accountMembersConnectionId} />
					<GroupMembersList
						selectedMembers={selectedMembers}
						handleToggleMember={handleToggleMember}
						debouncedSearchValue={debouncedSearchValue}
						ref={ref}
					/>
					{isBatchEditVisible && <BottomBatchEditSpacer />}
					<InviteMemberModal
						onDismiss={handleOnDismissInviteMemberModal}
						isVisible={isVisibleInviteMemberModal}
						tabView={false}
						selectedGroups={[{ id: groupId, name: groupName }]}
						queryFragmentRef={query}
					/>
					<GroupDetailsContextMenu
						ref={contextMenuRef}
						groupMembersConnectionId={accountMembersConnectionId}
						userInAccountGroupFragmentRef={
							query.AccountMemberManagement.UserInAccountGroupWrapper?.group
						}
					/>
				</Wrapper>
			</GroupDetailsContext.Provider>
		</RefetchContext.Provider>
	);
};

export const GroupDetails = withSuspense(GroupDetailsComponent, null);
