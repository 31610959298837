import { Toast } from "primereact/toast";
import styled from "styled-components";
import {
	error0,
	error100,
	info0,
	info100,
	success0,
	success100,
	warning0,
	warning100,
} from "@themes/colors";
import { TkaP2Typography, TkaH3Typography } from "@themes/font-tags";
import { spacing24 } from "@themes/spacing";

export const TkToast = styled(Toast)`
	opacity: 1;

	top: ${spacing24.rem()} !important;
	padding: 0 ${spacing24.rem()} !important;

	.p-toast-message {
		border-radius: 4px !important;

		&-icon {
			display: none;
		}

		&-text {
			margin: 0 0 0 var(--spacing-12) !important;

			& ~ div {
				display: flex;
				height: 100%;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				& > button {
					height: max-content;

					& > span {
						transform: scale(1.3);
					}
				}
			}
		}

		.p-toast-message-content {
			padding: 5px 0;
			display: flex;
			border-radius: 4px !important;
			align-items: center;

			.p-toast-summary {
				font-weight: 700;
			}

			.p-toast-detail {
				margin: 0;
				line-height: 20px;
			}
		}

		&.p-toast-message-success {
			border-width: 0 !important;
			color: ${success100} !important;

			.p-toast-icon-close {
				color: ${success100} !important;

				&:focus {
					box-shadow: 0 0 0 0.2rem ${success100};
				}
			}

			.p-toast-message-content {
				background-color: ${success0};

				.p-toast-summary {
					${TkaH3Typography};
					color: ${success100};
				}

				.p-toast-detail {
					${TkaP2Typography};
					color: ${success100};
				}
			}
		}

		&.p-toast-message-info {
			border-width: 0 !important;
			color: ${info100} !important;

			.p-toast-icon-close {
				color: ${info100} !important;

				&:focus {
					box-shadow: 0 0 0 0.2rem ${info100};
				}
			}

			.p-toast-message-content {
				background-color: ${info0};

				.p-toast-summary {
					${TkaH3Typography};
					color: ${info100};
				}

				.p-toast-detail {
					${TkaP2Typography};
					color: ${info100};
				}
			}
		}

		&.p-toast-message-error {
			border-width: 0 !important;
			color: ${error100} !important;

			.p-toast-icon-close {
				color: ${error100} !important;

				&:focus {
					box-shadow: 0 0 0 0.2rem ${error100};
				}
			}

			.p-toast-message-content {
				background-color: ${error0};

				.p-toast-summary {
					${TkaP2Typography};
					color: ${error100};
				}

				.p-toast-detail {
					${TkaP2Typography};
					color: ${error100};
				}
			}
		}

		&.p-toast-message-warn {
			border-width: 0 !important;
			color: ${warning100} !important;

			.p-toast-icon-close {
				color: ${warning100} !important;

				&:focus {
					box-shadow: 0 0 0 0.2rem ${warning100};
				}
			}

			.p-toast-message-content {
				background-color: ${warning0};

				.p-toast-summary {
					${TkaH3Typography};
					color: ${warning100};
				}

				.p-toast-detail {
					${TkaP2Typography};
					color: ${warning100};
				}
			}
		}
	}
`;
