import { graphql } from "babel-plugin-relay/macro";

export const WORD_NOTE_FRAGMENT = graphql`
	fragment editWordNoteModal_WordNoteFragment on WordNote {
		wordId
		note
	}
`;

export const ADD_WORD_NOTE_MUTATION = graphql`
	mutation editWordNoteModal_AddWordMutation($input: AddWordNoteInput!) {
		Vocab {
			addWordNote(input: $input) {
				inventory {
					...wordPackageList_WordInventoryFragment
				}
			}
		}
	}
`;
