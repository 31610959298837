import { AxiosResponse } from "axios";
import { InviteMembersMutationResponse } from "../standard-invite-form/standard-invite-form.types";

export enum SuccessfulInvite {
	INVITEUSERS = "inviteUsers",
	INVITEUSERSWITHGROUP = "inviteUsersWithGroup",
}

export type SuccessModalProps = {
	isVisible: boolean;
	inviteMembersResponse?: InviteMembersMutationResponse | AxiosResponse<any, any>;
	onDismiss?: () => void;
};
