import { FC, useRef } from "react";
import { useFragment } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { H1Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./assign-group-modal.graphql";
import { AssignGroupModalProps } from "./assign-group-modal.types";
import { StandardInviteForm } from "../standard-invite-form/standard-invite-form.component";
import { StandardInviteFormRef } from "../standard-invite-form/standard-invite-form.types";

export const AssignGroupModal: FC<AssignGroupModalProps> = ({
	isVisible,
	onDismiss,
	onSuccess,
	selectedMembers,
	queryFragmentRef,
}) => {
	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);
	const ref = useRef<StandardInviteFormRef>(null);

	const handleSubmit = () => {
		ref.current?.submit();
	};
	return (
		<>
			<ResponsiveBottomSheetModal
				isVisible={isVisible}
				dismissable={false}
				onDismiss={onDismiss}
			>
				<DialogTemplate
					secondaryButtonLabel="Schließen"
					onSecondaryButtonClick={onDismiss}
					primaryButtonLabel="Gruppe zuweisen"
					primaryButtonIconName="arrowRight"
					onPrimaryButtonClick={handleSubmit}
				>
					<H1Span>Gruppe zuweisen</H1Span>
					<StandardInviteForm
						selectedUsers={selectedMembers}
						onSuccess={onSuccess}
						ref={ref}
						queryFragmentRef={queryFragment}
					/>
				</DialogTemplate>
			</ResponsiveBottomSheetModal>
		</>
	);
};
