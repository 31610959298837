import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@components/button";
import { ValidatedInputText } from "@components/validated-input-text/validated-input-text.component";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { H1Span, TkaP1Span } from "@themes/font-tags";
import { SignUpNameStepFormState, SignUpNameStepProps } from "./sign-up-name-step.interface";
import {
	ActionButtonsWrapper,
	FormWrapper,
	InputsWrapper,
	TextCenter,
} from "./sign-up-name-step.styles";

export const SignUpNameStep = ({
	onNext,
	onPrevious,
	currentStep,
	maxSteps,
}: SignUpNameStepProps) => {
	const { t } = useTkaTranslation("registerForm");

	const form = useFormik<SignUpNameStepFormState>({
		initialValues: {
			firstName: "",
			lastName: "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			firstName: Yup.string().required(
				t("register_form.section2ValidationRequiredFirstName"),
			),
			lastName: Yup.string().required(t("register_form.section2ValidationRequiredLastName")),
		}),
		onSubmit: (values) => {
			onNext?.(values);
		},
	});

	const showStepInfo = maxSteps !== undefined && currentStep !== undefined;

	return (
		<form onSubmit={form.handleSubmit}>
			<FormWrapper>
				{showStepInfo && (
					<TextCenter>
						<TkaP1Span>
							{currentStep}/{maxSteps}
						</TkaP1Span>
					</TextCenter>
				)}
				<TextCenter>
					<H1Span>{t("register_form.section2Title")}</H1Span>
					<H1Span>{t("register_form.section2Title2")}</H1Span>
				</TextCenter>
				<InputsWrapper>
					<ValidatedInputText
						formikConfig={form}
						name="firstName"
						type="default"
						status="default"
						label={t("register_form.section2LabelFirstName")}
						placeholder={t("register_form.section2PlaceholderFirstName")}
					/>
					<ValidatedInputText
						formikConfig={form}
						name="lastName"
						type="default"
						status="default"
						label={t("register_form.section2LabelLastName")}
						placeholder={t("register_form.section2PlaceholderLastName")}
					/>
				</InputsWrapper>
				<ActionButtonsWrapper>
					<Button
						type="button"
						iconName="arrowLeft"
						colorVersion={"tertiary"}
						onClick={onPrevious}
					/>
					<Button
						fillParent
						label={t("register_form.section2ButtonText")}
						iconName="arrowRight"
					/>
				</ActionButtonsWrapper>
			</FormWrapper>
		</form>
	);
};
