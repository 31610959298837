import { type FC } from "react";
import { Icon } from "@components/icon";
import { TkaP1Span } from "@themes/font-tags";
import { Item, List } from "./trend-check-list.styles";

type TrendCheckListProps = {
	items: string[];
};

export const TrendCheckList: FC<TrendCheckListProps> = ({ items }) => {
	return (
		<List>
			{items.map((item) => (
				<Item key={item}>
					<Icon icon="greenCheckRibbon" sizeInRem={1.625} />
					<TkaP1Span>{item}</TkaP1Span>
				</Item>
			))}
		</List>
	);
};
