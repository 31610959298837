import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment groupsTab_QueryFragment on Query
	@refetchable(queryName: "groupsTabRefetchQuery")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 200 }
		after: { type: "String", defaultValue: null }
		includeOwner: { type: "Boolean", defaultValue: true }
		includeUser: { type: "Boolean", defaultValue: true }
		doCalcMemberCount: { type: "Boolean", defaultValue: true }
		filterByName: { type: "String" }
	) {
		AccountMemberManagement {
			UserInAccountGroupWrappers(
				includeOwner: $includeOwner
				includeUser: $includeUser
				doCalcMemberCount: $doCalcMemberCount
				filterByName: $filterByName
			) {
				count
				userInAccountGroupWrappers(first: $first, after: $after)
					@connection(key: "groupsTab_userInAccountGroupWrappers") {
					__id
					edges {
						node {
							memberCount
							group {
								id
								name
								isBuiltIn
							}
							...groupCard_UserInAccountGroupWrapperFragment
						}
					}
				}
			}
		}
	}
`;

export const USER_IN_ACCOUNT_GROUP_QUERY_FRAGMENT = graphql`
	fragment groupsTab_UserInAccountGroupQueryFragment on Query
	@refetchable(queryName: "groupsTab_UserInAccountGroupRefetchQuery") {
		...batchEditGroups_QueryFragment
		...groupCard_QueryFragment
	}
`;
