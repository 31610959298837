import { graphql } from "babel-plugin-relay/macro";

export const INSTRUCTOR_FRAGMENT = graphql`
	fragment instructorAbout_InstructorFragment on Instructor {
		name
		... on InstructorImpl {
			description
			shortDescription
			image {
				url
			}
		}
	}
`;
