import { useMemo } from "react";
import { readInlineData } from "react-relay";
import { COACH_PROFILE_FRAGMENT } from "./dropdown-coach.graphql";
import { DropdownCoachProps } from "./dropdown-coach.types";
import { DropdownUser } from "../dropdown-user";

export const DropdownCoach = ({
	placeholder,
	label,
	selectedCoachProfileId,
	onChange,
	coachProfileFragmentsRef,
}: DropdownCoachProps) => {
	const coachProfiles = coachProfileFragmentsRef?.map((fragment) =>
		readInlineData(COACH_PROFILE_FRAGMENT, fragment),
	);

	const selectedCoachProfile = coachProfiles?.find(
		(coachProfile) => coachProfile.id === selectedCoachProfileId,
	);

	const userFragments = useMemo(() => {
		return coachProfiles?.map((coach) => coach?.coach!).filter(Boolean) ?? [];
	}, [coachProfiles]);

	const handleOnChange = (userId: string) => {
		const newCoachProfile = coachProfiles?.find(
			(coachProfile) => coachProfile.coach?.id === userId,
		);
		if (!newCoachProfile || !onChange) return;
		onChange(newCoachProfile.id);
	};

	return (
		<DropdownUser
			label={label}
			placeholder={placeholder}
			selectedUserId={selectedCoachProfile?.coach?.id}
			onChange={handleOnChange}
			userFragmentsRef={userFragments}
		/>
	);
};
