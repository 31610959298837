/**
 * @generated SignedSource<<bdeeb7f2df8e159ebb0f5cead508e449>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetAnswerInTextQuestionInput = {
  clientMutationId?: string | null;
  givenAnswer: PotentialAnalysisTextGivenAnswerInput;
  learnPotentialAnalysisId: string;
  questionId: string;
  questionText: string;
};
export type PotentialAnalysisTextGivenAnswerInput = {
  text: string;
};
export type textQuestion_SetAnswerInTextQuestionMutation$variables = {
  input: SetAnswerInTextQuestionInput;
};
export type textQuestion_SetAnswerInTextQuestionMutation$data = {
  readonly AvgsPotentialAnalysis: {
    readonly setAnswerInTextQuestion: {
      readonly question: {
        readonly givenAnswer: {
          readonly text: string;
        } | null;
        readonly id: string;
      } | null;
    } | null;
  };
};
export type textQuestion_SetAnswerInTextQuestionMutation = {
  response: textQuestion_SetAnswerInTextQuestionMutation$data;
  variables: textQuestion_SetAnswerInTextQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AvgsPotentialAnalysisMutations",
    "kind": "LinkedField",
    "name": "AvgsPotentialAnalysis",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SetAnswerInTextQuestionPayload",
        "kind": "LinkedField",
        "name": "setAnswerInTextQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PotentialAnalysisTextQuestion",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PotentialAnalysisTextGivenAnswer",
                "kind": "LinkedField",
                "name": "givenAnswer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "textQuestion_SetAnswerInTextQuestionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "textQuestion_SetAnswerInTextQuestionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "118e3efcb4f6f9c4a9d35f1f801da352",
    "id": null,
    "metadata": {},
    "name": "textQuestion_SetAnswerInTextQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation textQuestion_SetAnswerInTextQuestionMutation(\n  $input: SetAnswerInTextQuestionInput!\n) {\n  AvgsPotentialAnalysis {\n    setAnswerInTextQuestion(input: $input) {\n      question {\n        id\n        givenAnswer {\n          text\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe9e123ea055cb862ef643767a06eb21";

export default node;
