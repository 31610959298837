import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
`;

export const ButtonWrapper = tw.div`
	flex
	justify-end
	md:justify-end
	w-full
	md:w-auto
	md:ml-[0.5rem]
	mt-[1rem]
	md:mt-[0.5rem]
`;

export const LabelsWrapper = tw.span`
	flex
	flex-row
	flex-wrap
	md:flex-nowrap
	gap-[0.5rem]
	mt-[0.25rem]
`;

export const HeaderWrapper = tw.div`
	flex
	flex-wrap
	md:flex-nowrap
	w-full
	mb-[1rem]

`;

export const MembersAndLabelsWrapper = tw.div`
	flex-col 
	flex-grow
`;

export const SearchAndFiltersWrapper = tw.div`
	flex 
	gap-8 
	items-center 
	md:mt-[0.25rem]
`;

export const SearchBarMobileWrapper = tw.div`
	mt-16
	w-full
`;

export const BottomBatchEditSpacer = tw.div`
	h-[4rem]
	md:hidden
`;

export const MemberCardsWrapper = tw.div`
flex
flex-col
gap-[0.5rem]`;
