import { Divider } from "@components/divider";
import { DropdownSkeleton } from "@components/dropdown";
import { InputTextSkeleton } from "@components/input-text/input-text.skeleton";
import { TkaH2Span } from "@themes/font-tags";
import {
	ColSpan2,
	ColSpan4,
	InputGroupWrapper,
	RowPlaceholder,
	ShortInputsRow,
	Wrapper,
} from "./private-account-form.styles";

export const PrivateAccountFormSkeleton = () => {
	return (
		<Wrapper>
			<InputGroupWrapper>
				<ShortInputsRow>
					<ColSpan2>
						<DropdownSkeleton />
					</ColSpan2>
					<ColSpan4>
						<InputTextSkeleton />
					</ColSpan4>
				</ShortInputsRow>
				<RowPlaceholder />
				<InputTextSkeleton />
				<InputTextSkeleton />
				<ShortInputsRow>
					<ColSpan4>
						<InputTextSkeleton />
					</ColSpan4>
					<ColSpan2>
						<InputTextSkeleton />
					</ColSpan2>
				</ShortInputsRow>
				<ShortInputsRow>
					<ColSpan4>
						<InputTextSkeleton />
					</ColSpan4>
					<ColSpan2>
						<InputTextSkeleton />
					</ColSpan2>
				</ShortInputsRow>
				<DropdownSkeleton />
				<InputTextSkeleton />
			</InputGroupWrapper>
			<Divider />
			<TkaH2Span>Kontodaten</TkaH2Span>
			<InputGroupWrapper>
				<InputTextSkeleton />
				<InputTextSkeleton />
			</InputGroupWrapper>
		</Wrapper>
	);
};
