import { Panel } from "primereact/panel";
import styled from "styled-components";
import tw from "twin.macro";

export const StyledPanel = styled(Panel)<{
	hasChildren: boolean;
	collapsed?: boolean;
	disabled?: boolean;
}>`
	&.p-panel {
		${tw`
			flex
			flex-col
			border
			rounded-12
			bg-shade-0
		`}
		${({ collapsed }) => (collapsed ? tw`border-shade-20` : tw`border-shade-100`)}
		${({ disabled }) => (disabled ? tw`opacity-60` : "")}
	}
	&.p-panel > .p-toggleable-content > .p-panel-content {
		${tw`border-0 bg-transparent`}
		${({ hasChildren }) => (hasChildren ? tw`pb-32 px-16 pt-8` : tw`p-0`)}
	}
`;

export const HeaderWrapper = styled.div<{ disabled?: boolean }>`
	${tw`
		flex-1
		flex
		flex-row
		gap-24
		items-center
		justify-between
		p-16
		hover:cursor-pointer
	`}
	${({ disabled }) => (disabled ? tw`hover:cursor-default` : "")}
`;

export const ExpandedContentWrapper = tw.div`
	flex
	flex-col
`;
