import { useContext } from "react";
import { useMutation } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation } from "@relay/removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation.graphql";
import { RefetchContext } from "@screens/account/parts/refetch-context/refetch.context";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { REMOVE_USERS_FROM_USER_IN_ACCOUNT_GROUP_MUTATION } from "./remove-members-from-group-modal.graphql";
import { Wrapper } from "./remove-members-from-group-modal.styles";
import { RemoveMembersFromGroupModalProps } from "./remove-members-from-group-modal.types";

// TODO: add-translations
export const RemoveMembersFromGroupModal = ({
	isVisible,
	groupId,
	selectedMembers,
	groupMembersConnectionId,
	onDismiss,
	onSuccess,
}: RemoveMembersFromGroupModalProps) => {
	const [removeUsersFromUserFromAccountGroup] =
		useMutation<removeMembersFromGroupModal_RemoveUsersFromUserInAccountGroupMutation>(
			REMOVE_USERS_FROM_USER_IN_ACCOUNT_GROUP_MUTATION,
		);

	const { refetch } = useContext(RefetchContext);

	if (!selectedMembers || selectedMembers.length === 0) return null;

	const handleRemoveUsersOnClick = () => {
		if (selectedMembers.length > 0) {
			removeUsersFromUserFromAccountGroup({
				variables: {
					input: {
						groupId,
						userIds: selectedMembers.map((selectedMembers) => selectedMembers.id),
					},
					connections: [groupMembersConnectionId],
				},
				onCompleted: () => {
					refetch();
					onSuccess?.();
				},
			});
		}
	};

	const title =
		selectedMembers.length > 1
			? `${selectedMembers.length} Mitglieder aus der Gruppe entfernen?`
			: `Mitglied “${selectedMembers[0].name}” aus der Gruppe entfernen?`;
	const subtitle =
		selectedMembers.length > 1
			? `Willst du wirklich ${selectedMembers.length} Mitglieder entfernen? Die Mitglieder können dann nicht mehr auf Ressourcen und Aktivitäten der Gruppe zugreifen.`
			: `Willst du “${selectedMembers[0].name}” wirklich entfernen? Das Mitglied kann dann nicht mehr auf Ressourcen und Aktivitäten der Gruppe zugreifen.`;

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel={
					selectedMembers.length > 1 ? "Mitglieder entfernen" : "Mitglied entfernen"
				}
				primaryButtonIconName="trash"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={handleRemoveUsersOnClick}
			>
				<Wrapper>
					<H1Span>{title}</H1Span>
					<TkaP2Span>{subtitle}</TkaP2Span>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
