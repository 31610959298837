import { Messages } from "primereact/messages";
import styled from "styled-components";
import tw from "twin.macro";
import { TkaP2Typography, TkaH3Typography } from "@themes/font-tags";

export const StyledMessage = styled(Messages)<{ highlighted?: boolean; neutral?: boolean }>`
	.p-message {
		${tw`
			rounded-4
			m-0
		`}
		${({ highlighted }) => (highlighted ? tw`border-l-4` : tw`border-0`)}

		&-icon {
			${tw`hidden`}
		}

		.p-message-wrapper {
			${tw`
				flex 
				flex-col  
				items-start 
				px-12 
				py-4
			`}
		}

		.p-message-summary {
			${TkaH3Typography};
			${tw`block`}
		}
		.p-message-detail {
			${TkaP2Typography};
			${tw`block ml-0`}
		}

		&.p-message-success {
			${({ neutral }) =>
				neutral ? tw`bg-shade-5 border-shade-60` : tw`bg-success-0 border-success-100`}
			.p-message-summary {
				${({ neutral }) => (neutral ? tw`text-shade-60` : tw`text-success-100`)}
			}
			.p-message-detail {
				${({ neutral }) => (neutral ? tw`text-shade-60` : tw`text-success-100`)}
			}
		}

		&.p-message-error {
			${tw`bg-error-0 border-error-100`}
			.p-message-summary {
				${tw`text-error-100`}
			}
			.p-message-detail {
				${tw`text-error-100`}
			}
		}

		&.p-message-warn {
			${tw`bg-warning-0 border-warning-100`}
			.p-message-summary {
				${tw`text-warning-100`}
			}
			.p-message-detail {
				${tw`text-warning-100`}
			}
		}
	}
`;
