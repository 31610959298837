import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
`;

export const TabWrapper = tw.div`
	pt-24
`;
