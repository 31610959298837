import graphql from "babel-plugin-relay/macro";

export const VIEWER_QUERY_TYPE_FRAGMENT = graphql`
	fragment microLearningReward_ViewerQueryTypeFragment on ViewerQueryType {
		Auth {
			currentUser {
				user {
					extension(userExtensionKind: Academies) {
						... on AcademiesUserExtension {
							potentialAnalysisMaxRewardAcknowledged
						}
					}
				}
			}
		}
		PotentialAnalysis {
			MyPotentialAnalysisResult {
				isLongAnalysis
			}
		}
	}
`;

export const ACKNOWLEDGE_POTENTIAL_ANALYSIS_MAX_REWARD_MUTATION = graphql`
	mutation microLearningReward_AcknowledgePotentialAnalysisMaxRewardMutation(
		$input: AcknowledgePotentialAnalysisMaxRewardInput!
	) {
		PotentialAnalysis {
			acknowledgePotentialAnalysisMaxReward(input: $input) {
				clientMutationId
			}
		}
	}
`;
