import { LicensesAccountGroupAssignmentCardSkeleton } from "@components/licenses-account-group-assignment-card/licenses-account-group-assignment-card.skeleton";
import { AllocationListWrapper } from "./group-rules-tab.styles";

export const GroupRulesTabComponentSkeleton = () => {
	return (
		<AllocationListWrapper>
			<LicensesAccountGroupAssignmentCardSkeleton />
			<LicensesAccountGroupAssignmentCardSkeleton />
			<LicensesAccountGroupAssignmentCardSkeleton />
			<LicensesAccountGroupAssignmentCardSkeleton />
			<LicensesAccountGroupAssignmentCardSkeleton />
		</AllocationListWrapper>
	);
};
