import graphql from "babel-plugin-relay/macro";

export const TREND_CARD_FRAGMENT = graphql`
	fragment trendCard_trendFragment on Trend {
		id
		trendData {
			visible
			icon
			name
		}
	}
`;
