import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment infoModalCorporate_QueryFragment on Query {
		Placement {
			permissionCartClickOut
		}
	}
`;

export const GRANT_PLACEMENT_PERMISSION_AND_REDIRECT_MUTATION = graphql`
	mutation infoModalCorporate_GrantPlacementPermissionAndRedirectMutation(
		$input: GrantPlacementPermissionAndRedirectInput!
	) {
		Placement {
			grantPlacementPermissionAndRedirect(input: $input) {
				placementUrl
			}
		}
	}
`;
