/**
 * @generated SignedSource<<98be44e8b9b79f7f0f0e47355cb99800>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Icon = "arrowLoop" | "bridgeOverRiver" | "computerWithHeart" | "dotsConnected" | "flowerInCircle" | "handWithFlower" | "handsWithHeart" | "lightBulb" | "peopleHighFive" | "personWithStars" | "rocket" | "workingDesk";
import { FragmentRefs } from "relay-runtime";
export type trendCard_trendFragment$data = {
  readonly id: string;
  readonly trendData: {
    readonly icon: Icon;
    readonly name: string;
    readonly visible: boolean;
  };
  readonly " $fragmentType": "trendCard_trendFragment";
};
export type trendCard_trendFragment$key = {
  readonly " $data"?: trendCard_trendFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendCard_trendFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "trendCard_trendFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrendData",
      "kind": "LinkedField",
      "name": "trendData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visible",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Trend",
  "abstractKey": null
};

(node as any).hash = "248dd46a7c21b2eaf98baf8733b38b15";

export default node;
