import { useEffect, useMemo, useState } from "react";
import { BreakpointInPx } from "@themes/breakpoint";
import { Helpers, WindowSize } from "./use-window-size.interface";

export const useWindowSize = (): Helpers => {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return useMemo(() => {
		const { width, height } = windowSize;
		return {
			windowWidth: width,
			windowHeight: height,
			isMediumUp: width >= BreakpointInPx.medium,
			isLargeUp: width >= BreakpointInPx.large,
			isXLargeUp: width >= BreakpointInPx.xlarge,
			is2XLargeUp: width >= BreakpointInPx.xxlarge,
		};
	}, [windowSize.width]);
};
