import { classNames } from "primereact/utils";
import { ValidatedInputSwitchProps } from "./validated-input-switch.interface";
import { InputSwitch } from "../input-switch/input-switch.component";

export function ValidatedInputSwitch<State>({
	formikConfig,
	className,
	...props
}: ValidatedInputSwitchProps<State>) {
	const errorMessage = formikConfig.errors[props.name] as unknown as string | undefined;
	const hasError = !!(errorMessage && formikConfig.touched[props.name]);

	const checked = formikConfig.values[props.name] as unknown as boolean;

	const onChange = (checked: boolean) => {
		formikConfig.setFieldTouched(props.name, true);
		formikConfig.setFieldValue(props.name, checked);
	};

	return (
		<InputSwitch
			{...props}
			checked={checked}
			onChange={onChange}
			className={classNames({ "p-invalid": hasError, [className as string]: true })}
		/>
	);
}
