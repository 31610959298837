import { useFragment, useMutation } from "react-relay";
import { cancelAppointmentModal_CancelBookingAsParticipantMutation } from "@relay/cancelAppointmentModal_CancelBookingAsParticipantMutation.graphql";
import { error0, error100, shade60 } from "@themes/colors";
import { TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import {
	AVAILABILITY_FRAGMENT,
	CANCEL_BOOKING_AS_PARTICIPANT_MUTATION,
} from "./cancel-appointment-modal.graphql";
import {
	HeaderWrapper,
	RowWrapper,
	UsersCountWrapper,
	Wrapper,
} from "./cancel-appointment-modal.styles";
import { CancelAppointmentModalProps } from "./cancel-appointment-modal.types";
import { DialogTemplate } from "../dialog-template";
import { Icon } from "../icon";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { LinkDisplay } from "../link-display";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";
import { UserAvatarsCollection } from "../user-avatars-collection/user-avatars-collection.component";

// TODO: add-translations
export const CancelAppointmentModal = ({
	isVisible = false,
	onCancel,
	onAppointmentCanceled,
	availabilityFragmentRef,
}: CancelAppointmentModalProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const [cancelBookingAsParticipant] =
		useMutation<cancelAppointmentModal_CancelBookingAsParticipantMutation>(
			CANCEL_BOOKING_AS_PARTICIPANT_MUTATION,
		);

	const handleCancelOnClick = () => {
		cancelBookingAsParticipant({
			variables: {
				input: {
					availabilityId: availability.id,
				},
			},
			onCompleted: onAppointmentCanceled,
		});
	};

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			false,
			"long",
		);
	};

	const msTeamsLink = availability.booking.msTeamsLink;

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const users = participants.map((participant) => participant.user!).filter(Boolean);

	const participantsLabel = `${users.length} Teilnehmer`;
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onCancel}
				primaryButtonLabel="Ja, Termin absagen"
				primaryButtonIconName="calendarDisable"
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={handleCancelOnClick}
			>
				<Wrapper>
					<HeaderWrapper>
						<IconHeadlineButtonTemplate
							iconName="calendarDisable"
							iconColor={error100}
							iconBackgroundColor={error0}
							title="Wirklich absagen?"
							subtitle="Dein Coach bereitet sich auf die Session mit dir vor, willst du wirklich absagen? Absagen sind bis zu 24 Stunden vor dem Terminstart möglich."
						/>
					</HeaderWrapper>
					<RowWrapper>
						<Icon icon="calendar" sizeInRem={1.5} />
						<TkaP2Span>{renderTime()}</TkaP2Span>
					</RowWrapper>
					<RowWrapper>
						<Icon icon="microsoftTeams" sizeInRem={1.5} />
						<LinkDisplay name="Teams Call" link={msTeamsLink ?? ""} />
					</RowWrapper>
					<RowWrapper>
						<Icon icon="participants" sizeInRem={1.5} />
						<UsersCountWrapper>
							<UserAvatarsCollection userFragmentsRef={users} />
							<TkaP3Span tkaColor={shade60}>{participantsLabel}</TkaP3Span>
						</UsersCountWrapper>
					</RowWrapper>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
