import tw from "twin.macro";
import { ReactComponent as LogoSVG } from "@assets/logo.svg";

export const HeaderWrapper = tw.div`
   relative
   min-w-[19rem]
   min-h-[4rem]
   flex
   justify-center
   items-center
`;

export const LeftItem = tw.div`
	absolute
	left-16
	my-auto
`;

export const RightItem = tw.div`
	absolute
	right-16
	my-auto
`;

export const StyledLogo = tw(LogoSVG)`
	hover:cursor-pointer
`;
