import tw from "twin.macro";
import { TkaP3Anchor } from "@themes/font-tags";

export const FooterStyledBar = tw.div`
	w-full
	pt-12
	pb-12
	flex
	justify-center
	bg-shade-5
`;

export const FooterItem = tw(TkaP3Anchor)`
	ml-8
	mr-8
`;
