import graphql from "babel-plugin-relay/macro";

export const INVITATION_QUERY = graphql`
	query loginSteps_InvitationByTokenQuery($token: String!, $skip: Boolean!) {
		Auth {
			InvitationByToken(token: $token) @skip(if: $skip) {
				invitingUserName
				accountName
			}
		}
	}
`;

export const CREATE_CLICKOUT_BY_LINKID_MUTATION = graphql`
	mutation loginSteps_CreateClickoutByLinkIdMutation($input: CreateClickOutByLinkIdInput!) {
		Placement {
			createClickOutByLinkId(input: $input) {
				placementUrl
			}
		}
	}
`;
