import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { InlineIcon } from "@components/inline-icon/inline-icon.component";
import { Message } from "@components/message";
import { UploadFileCard } from "@components/upload-file-card";
import UploadedFileCardComponent from "@components/uploaded-file-card/uploaded-file-card.component";
import { useToast } from "@hooks/useToast";
import InviteOrAddUsersToUserInAccountGroupsViaCsvService from "@services/invite-or-add-users-to-user-in-account-groups-via-csv.service";
import { TkaP3Label } from "@themes/font-tags";
import { CSVInviteComponentRef, CSVInviteComponentProps } from "./csv-invite.types";
import { UploadWrapper, MessageWrapper } from "../invite-component/invite.component.styles";

export const CSVInviteComponent = forwardRef<CSVInviteComponentRef, CSVInviteComponentProps>(
	function CSVInviteComponent(
		{
			setInviteMembersMutationResponse,
			onError,
			onSuccess,
			setErrorInfo,
			onFileUpload,
			fileUploaded,
			onDismiss,
			setButtonLoadingState,
		},
		ref,
	) {
		const [uploading, setUploading] = useState(false);
		const [uploadedFile, setUploadedFile] = useState<File>();

		const { showError } = useToast();

		useEffect(() => {
			setButtonLoadingState(false);
		}, []);

		const handleFileSelected = (file?: File) => {
			if (uploading) return;
			if (file && file?.size > 4000000) {
				showError({ detail: "Datei zu groß" });
				return;
			}
			if (file?.type !== "text/csv") {
				showError({ detail: "Falscher Dateityp - nur CSV Dateien möglich" });
				return;
			}
			setUploading(true);
			onFileUpload(true);
			setUploadedFile(file);
		};

		useImperativeHandle(ref, () => ({
			submit: () => {
				if (!uploadedFile) return;
				InviteOrAddUsersToUserInAccountGroupsViaCsvService.upload(uploadedFile)
					.then((response) => {
						setUploading(false);
						if (
							response.data.invalidEmails?.length > 0 ||
							response.data.notFoundGroupNames?.length > 0
						) {
							onError?.();
							setErrorInfo({
								invalidEmails: response.data.invalidEmails,
								notFoundGroupNames: response.data.notFoundGroupNames,
							});
							return;
						}
						setInviteMembersMutationResponse?.(response);
						onSuccess?.();
					})
					.catch(() => {
						setUploading(false);
						setUploadedFile(undefined);
						onFileUpload(false);
						onDismiss?.();
						showError({ detail: "Fehler beim Upload" });
					});
			},
		}));

		const handleDeleteClick = () => {
			setUploading(false);
			onFileUpload(false);
			setUploadedFile(undefined);
		};

		return (
			<>
				<UploadWrapper>
					<TkaP3Label>CSV Upload</TkaP3Label>
					{fileUploaded ? (
						<UploadedFileCardComponent
							fileName={uploadedFile?.name}
							fileSize={uploadedFile?.size}
							onDeleteClick={handleDeleteClick}
						/>
					) : (
						<UploadFileCard onFileUpload={handleFileSelected} />
					)}
				</UploadWrapper>
				<MessageWrapper>
					<Message
						severity="neutral"
						summary="Demo CSV"
						detail="Um sicherzugehen, dass alles funktioniert, kannst du dir vorher unsere Demodatei laden und diese befüllen. Dort kannst du auch direkt die Gruppen angeben."
					/>
					<a
						href={`${process.env.PUBLIC_URL}/demofile.csv`}
						target="_blank"
						rel="noopener noreferrer"
						download="CSV Demodatei"
					>
						<InlineIcon
							icon={"downloadBottom"}
							text={"Demodatei herunterladen"}
							sizeInRem={1}
						/>
					</a>
				</MessageWrapper>
			</>
		);
	},
);
