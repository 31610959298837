import { Image } from "primereact/image";
import { type FC } from "react";
import { shade0 } from "@themes/colors";
import { TkaB1Span, TkaP2Span } from "@themes/font-tags";
import {
	DecorativeBanner,
	Headline,
	ImageWrapper,
	TextContent,
	Wrapper,
} from "./card-banner.styles";
import { type CardBannerProps } from "./card-banner.types";
import { Button } from "../button";

export const CardBanner: FC<CardBannerProps> = ({
	title,
	content,
	decorativeBannerText,
	buttonLabel,
	buttonIconName,
	buttonOnClick,
	src,
	alt,
	width = "100%",
	height = "auto",
}) => {
	return (
		<Wrapper>
			<DecorativeBanner>
				<TkaB1Span tkaColor={shade0} bold>
					{decorativeBannerText}
				</TkaB1Span>
			</DecorativeBanner>
			<TextContent>
				<Headline>{title}</Headline>
				<TkaP2Span>{content}</TkaP2Span>
				<Button label={buttonLabel} iconName={buttonIconName} onClick={buttonOnClick} />
			</TextContent>
			<ImageWrapper>
				<Image src={src} alt={alt} width={width} height={height} />
			</ImageWrapper>
		</Wrapper>
	);
};
