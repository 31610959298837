import { AppointmentStatus } from "@utils/appointment-utils";
import { LabelSeverity } from "../label/label.types";

// TODO: add-translations
export const getStatusLabel = (status: AppointmentStatus): string => {
	switch (status) {
		case AppointmentStatus.Running:
			return "Läuft gerade";
		case AppointmentStatus.Confirmed:
			return "Bestätigt";
		case AppointmentStatus.Ended:
			return "Beendet";
		default:
			return "Unbekannt";
	}
};

export const getLabelSeverity = (status: AppointmentStatus): LabelSeverity => {
	switch (status) {
		case AppointmentStatus.Running:
		case AppointmentStatus.Confirmed:
			return "success";
		case AppointmentStatus.Ended:
		default:
			return "info";
	}
};
