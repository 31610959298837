import { Dropdown } from "primereact/dropdown";
import styled from "styled-components";
import tw from "twin.macro";
import { shade20, shade5, error100, shade100, shade40, info100 } from "@themes/colors";
import { TkaP2Typography } from "@themes/font-tags";

export const StyledDropdown = styled(Dropdown)`
	border-color: ${shade40} !important;

	& .p-dropdown-label {
		${TkaP2Typography}
		color: ${shade100};
	}

	&:hover {
		border-color: ${shade20} !important;
	}

	& .p-dropdown-trigger {
		color: ${shade100};
	}

	& .p-dropdown-label.p-inputtext {
		color: ${shade100};

		&.p-placeholder {
			color: ${shade20};
		}
	}

	/* Disabled */

	&.p-disabled {
		background-color: ${shade5};
		border: none;
		cursor: not-allowed !important;

		& .p-dropdown-label {
			color: ${shade20};
		}

		.p-dropdown-trigger {
			color: ${shade20};
		}
	}

	/* Focused */

	&.p-dropdown.p-focus {
		box-shadow: none;
		border-color: ${info100} !important;
	}

	/* Invalid */

	&.p-dropdown.p-invalid {
		border-color: ${error100} !important;

		.p-dropdown-label {
			color: ${shade100};
		}
	}
`;

export const Wrapper = tw.div`
	flex
	flex-col
	gap-4
`;
