import tw from "twin.macro";

export const Wrapper = tw.div`
  relative
  flex
  justify-between
  items-center
  flex-col
  lg:flex-row
  w-full
  bg-brand-strong-100
  rounded-[12px]
  py-20
  px-[50px]
  mt-[61px]
`;

export const TextContent = tw.div`
  flex
  flex-col
  p-24
  gap-16
  basis-1/2
`;

export const Headline = tw.h2`
  text-[40px]
  font-bold
  leading-[45.6px]
`;

export const DecorativeBanner = tw.div`
  absolute
  top-0
  left-1/2
  -translate-x-1/2
  -translate-y-1/2
  flex
  items-center
  justify-center
  text-center
  bg-brand-main-100
  p-[45px]
  rounded-[20px_20px_100px_100px]
`;

export const ImageWrapper = tw.div`
  basis-1/2
  flex
  justify-end
`;
