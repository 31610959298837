import React, { useMemo, useState } from "react";
import { shade80, brandStrong100 } from "@themes/colors";
import { TkaP2Typography } from "@themes/font-tags";
import { CollapseTextProps } from "./collapse-text.interface";
import { Text } from "./collapse-text.styles";

export const CollapseText = ({ truncateCount, text, typography, textColor }: CollapseTextProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const isNecessary = useMemo(() => truncateCount < text.length, [truncateCount, text]);

	const useTypography = typography || TkaP2Typography;

	const wrapperStyles = isExpanded ? { cursor: "pointer" } : {};
	const toggleIsExpanded = () => {
		setIsExpanded((boo) => !boo);
	};
	const visibleText = isExpanded
		? text
		: `${truncateCount < text.length ? text.slice(0, truncateCount).concat("...") : text}`;

	return (
		<div
			style={wrapperStyles}
			onClick={() => {
				isExpanded && toggleIsExpanded();
			}}
		>
			<Text typography={useTypography} tkaColor={textColor || shade80}>
				{visibleText}
			</Text>
			{!isExpanded && isNecessary && (
				<Text
					typography={useTypography}
					tkaColor={brandStrong100}
					onClick={toggleIsExpanded}
				>
					mehr anzeigen
				</Text>
			)}
		</div>
	);
};
