import { AuthTemplate } from "@components/auth-template";
import { ForgotPasswordSteps } from "@components/forgot-password-steps";

export const ForgotPasswordScreen = () => {
	return (
		<AuthTemplate hideImage>
			<ForgotPasswordSteps />
		</AuthTemplate>
	);
};
