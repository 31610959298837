import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { useMutation } from "react-relay";
import * as Yup from "yup";
import { Wrapper } from "@components/input-text/input-text.styles";
import { licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation } from "@relay/licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation.graphql";
import { GroupsMultiSelect } from "@screens/account/parts/groups-multi-select";
import { Spacing24 } from "@screens/profile/profile.styles";
import { success100 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { CREATE_NEW_ACCOUNT_GROUP_ASSIGNMENT_RULE_MUTATION } from "./licenses-new-account-group-assignment-form.graphql";
import {
	LicensesNewAccountGroupAssignmentFormRef,
	LicensesNewAccountGroupAssignmentFormProps,
	LicensesNewAccountGroupAssignmentFormState,
} from "./licenses-new-account-group-assignment-form.types";
import { Message } from "../message";
import { ValidatedInputText } from "../validated-input-text";

export const LicensesNewAccountGroupAssignmentForm = forwardRef<
	LicensesNewAccountGroupAssignmentFormRef,
	LicensesNewAccountGroupAssignmentFormProps
>(function LicensesNewAccountGroupAssignmentForm(
	{ licenseGroupingId, groupOptions, onSuccess },
	ref,
) {
	const [createLicenseRule] =
		useMutation<licensesNewAccountGroupAssignmentForm_createNewGroupAssignmentRuleMutation>(
			CREATE_NEW_ACCOUNT_GROUP_ASSIGNMENT_RULE_MUTATION,
		);

	const form = useFormik<LicensesNewAccountGroupAssignmentFormState>({
		initialValues: {
			name: "",
			groups: [],
		},
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Bitte gib einen Namen ein"),
			groups: Yup.array().min(1, "Bitte wähle mindestens eine Gruppe aus"),
		}),
		onSubmit: (values) => {
			createLicenseRule({
				variables: {
					input: {
						licenseGroupingId,
						name: values.name,
						active: true,
						userInAccountGroupIds: values.groups.map((group) => group.id),
					},
				},
				onCompleted: () => {
					form.resetForm();
					form.setErrors({});
					onSuccess?.();
				},
			});
		},
	});

	useImperativeHandle(ref, () => ({
		submit: () => {
			form.submitForm();
		},
	}));

	const handleOnRemoveGroup = (id?: string) => {
		const updatedGroups = form?.values?.groups?.filter((group) => group.id !== id);
		form.setFieldValue("groups", updatedGroups);
	};

	return (
		<form onSubmit={form.handleSubmit}>
			<Wrapper>
				<Message
					highlighted
					severity="success"
					content={
						<TkaP2Span tkaColor={success100}>
							Gruppenzuweisungen gewähren Zugriff auf den Lizenz-Pool. Dieser kann nur
							so lange ausgeschöpft werden, wie sich darin Lizenzen befinden.
						</TkaP2Span>
					}
				/>
				<Spacing24 />
				<ValidatedInputText
					formikConfig={form}
					label="Name der Regel"
					name="name"
					placeholder="Name"
				/>
				<Spacing24 />
				<GroupsMultiSelect
					formikConfig={form}
					label="Zugewiesene Gruppen"
					name="groups"
					options={groupOptions ?? []}
					optionLabel="name"
					onCloseClick={(id) => handleOnRemoveGroup(id)}
				/>
			</Wrapper>
		</form>
	);
});
