import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-16
`;

export const Item = tw.div`
	flex
	flex-row
	gap-8
	items-center
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
`;
