import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { licensesSingleUserAssignmentCard_deleteSingleUserAssignmentRuleMutation } from "@relay/licensesSingleUserAssignmentCard_deleteSingleUserAssignmentRuleMutation.graphql";
import { licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation } from "@relay/licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation.graphql";
import { shade100, shade20 } from "@themes/colors";
import { TkaH2Span, TkaH3Span, TkaP2Span, TkaP3Label } from "@themes/font-tags";
import {
	ACCOUNT_MEMBER_FRAGMENT,
	DELETE_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
	EDIT_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
	SINGLE_USER_ASSIGNMENT_RULE_FRAGMENT,
} from "./licenses-single-user-assignment-card.graphql";
import {
	AllocationButton,
	AllocationButtonWrapper,
	AllocationWrapper,
	IconWrapper,
	LabelsWrapper,
	NameWrapper,
	UserWrapper,
	UserDataWrapper,
	Wrapper,
} from "./licenses-single-user-assignment-card.styles";
import { type LicensesSingleUserAssignmentCardProps } from "./licenses-single-user-assignment-card.types";
import { Avatar } from "../avatar";
import { Button } from "../button";
import { Card } from "../card";
import { Icon } from "../icon";
import { Label } from "../label";
import { LicensesRemoveRuleModal } from "../licenses-remove-rule-modal";

export const LicensesSingleUserAssignmentCard = ({
	userRef,
	licenseCount: defaultLicenseCount,
	onLicenseCountChange,
	onDelete,
	onSubmit,
	showSubmitButton = true,
	showUserIcon = true,
	showModalOnDelete = true,
	showGroups = true,
	queryFragmentRef,
}: LicensesSingleUserAssignmentCardProps) => {
	const data = useFragment(SINGLE_USER_ASSIGNMENT_RULE_FRAGMENT, queryFragmentRef ?? null);
	const userData = useFragment(ACCOUNT_MEMBER_FRAGMENT, userRef ?? null);

	const [editRule] =
		useMutation<licensesSingleUserAssignmentCard_editSingleUserAssignmentRuleMutation>(
			EDIT_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
		);
	const [deleteRule] =
		useMutation<licensesSingleUserAssignmentCard_deleteSingleUserAssignmentRuleMutation>(
			DELETE_SINGLE_USER_ASSIGNMENT_RULE_MUTATION,
		);

	const [licenseCount, setLicenseCount] = useState(
		() => data?.maxLicenseAmount ?? defaultLicenseCount ?? 1,
	);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const userGroups = userData?.user?.groups.filter((group) => !group.isBuiltIn) ?? [];
	const canDecrease = licenseCount > 1;
	const deletable = (data?.licensesUsedByUser ?? 0) === 0;

	const handleSubmit = () => {
		if (!queryFragmentRef) {
			onSubmit?.(licenseCount);
			return;
		}

		if (!data?.id) return;

		editRule({
			variables: {
				input: {
					userId: data.userId,
					assignmentRuleId: data.id,
					maxLicenseAmount: licenseCount,
				},
			},
			onCompleted: () => {
				onSubmit?.(licenseCount);
			},
		});
	};

	const handleDelete = () => {
		if (!data?.id) return;

		deleteRule({
			variables: {
				input: {
					assignmentRuleId: data.id,
				},
			},
			onCompleted: () => {
				setShowDeleteModal(false);
				onDelete?.();
			},
		});
	};

	const handleLicenseCountChange = (newCount: number) => {
		onLicenseCountChange?.(newCount);
	};

	const handleLicenseCountDecreaseOrDeleteButtonClick = () => {
		if (!canDecrease && deletable) {
			if (showModalOnDelete) {
				setShowDeleteModal(true);
				return;
			}

			onDelete?.();
			return;
		}

		const newCount = licenseCount - 1;
		setLicenseCount(newCount);
		handleLicenseCountChange(newCount);
	};

	const handleLicenseCountIncrease = () => {
		const newCount = licenseCount + 1;
		setLicenseCount(newCount);
		handleLicenseCountChange(newCount);
	};

	return (
		<>
			{showDeleteModal && (
				<LicensesRemoveRuleModal
					isVisible
					onCancel={() => setShowDeleteModal(false)}
					onSubmit={handleDelete}
					content={<TkaP2Span>Lizenz Zuweisung für den Nutzer</TkaP2Span>}
				/>
			)}
			<Card bodyPaddingInRem={0.875} borderColor={shade20}>
				<Wrapper>
					<UserWrapper>
						<UserDataWrapper>
							{showUserIcon && (
								<IconWrapper>
									<Icon icon="user" sizeInRem={1.5} />
								</IconWrapper>
							)}
							<Avatar name={userData?.name} sizeInRem={2.5} />
							<NameWrapper>
								<TkaP3Label>
									{userData?.isManager ? "Manager:In" : "Benutzer:In"}
								</TkaP3Label>
								<TkaH3Span>{userData?.name}</TkaH3Span>
							</NameWrapper>
						</UserDataWrapper>
						{showGroups && userGroups.length > 0 && (
							<LabelsWrapper>
								{userGroups.map((group) => (
									<Label
										key={group.id}
										label={group.name}
										size="smal"
										severity="info"
									/>
								))}
							</LabelsWrapper>
						)}
					</UserWrapper>
					<AllocationWrapper>
						<AllocationButtonWrapper>
							<AllocationButton
								type="button"
								onClick={handleLicenseCountDecreaseOrDeleteButtonClick}
								disabled={!canDecrease && !deletable}
							>
								<Icon
									tkaColor={!canDecrease && !deletable ? shade20 : shade100}
									icon={!canDecrease && deletable ? "trash" : "remove"}
									sizeInRem={0.5625}
								/>
								<span className="sr-only">Entfernen</span>
							</AllocationButton>
							<TkaH2Span>
								{data?.licensesUsedByUser ?? 0}/{licenseCount}
							</TkaH2Span>
							<AllocationButton type="button" onClick={handleLicenseCountIncrease}>
								<Icon tkaColor={shade100} icon="add" sizeInRem={0.5625} />
								<span className="sr-only">Hinzufügen</span>
							</AllocationButton>
						</AllocationButtonWrapper>
						{showSubmitButton && (
							<Button
								label="Zuweisen"
								onClick={handleSubmit}
								disabled={licenseCount === (data?.maxLicenseAmount ?? 1)}
							/>
						)}
					</AllocationWrapper>
				</Wrapper>
			</Card>
		</>
	);
};
