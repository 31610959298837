import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div<{ noMaxWidth?: boolean }>`
	${tw`
		flex
		max-w-[24rem]
		items-center
	`}
	${({ noMaxWidth }) => noMaxWidth && tw`max-w-none`}
`;

export const Flex = tw.div`
	flex
	gap-24
	items-center
`;

export const OrderWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
`;
