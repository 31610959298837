import tw from "twin.macro";
import { TkaH2Span } from "@themes/font-tags";

export const NavigateWrapper = tw.div`
	flex
	flex-row
	gap-12
	items-center
	hover:cursor-pointer
`;

export const NavigateTitle = tw(TkaH2Span)`
	grow
`;
