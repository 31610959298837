import { graphql } from "babel-plugin-relay/macro";
import { useContext } from "react";
import { useFragment } from "react-relay";
import { ReactComponent as FailureModalIcon } from "@assets/modal-icon-failure.svg";
import { Button } from "@components/button";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider/content-submission-modal-context-provider.context";
import { ShowAnswerButton } from "@components/show-answer-button/ShowAnswerButton.component";
import { SuccessModal } from "@components/success-modal";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { ContentSubmissionFailureModal_ContentSubmissionFragment$key } from "@relay/ContentSubmissionFailureModal_ContentSubmissionFragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaP2Span, H1Span } from "@themes/font-tags";
import { ContentSubmissionFailureModalProps } from "./content-submission-failure-modal.interface";
import {
	ButtonsWrapper,
	ContentWrapper,
	Flex,
	IconAndBadgeWrapper,
	MessageContainer,
} from "./content-submission-failure-modal.styles";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionFailureModal_ContentSubmissionFragment on ContentSubmission {
		...ShowAnswerButton_ContentSubmissionFragment
	}
`;

export const ContentSubmissionFailureModal = ({
	isVisible,
	onHide,
	contentSubmissionFragmentRef,
}: ContentSubmissionFailureModalProps) => {
	const { t } = useTkaTranslation("failureEvaluationModal");
	const Icon = <FailureModalIcon />;
	const title = t("failure_evaluation_modal.title");
	const description = t("failure_evaluation_modal.description");

	const contentSubmission =
		useFragment<ContentSubmissionFailureModal_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const { tryAgainClicked } = useContext(ContentSubmissionModalContext);

	const { isMediumUp } = useWindowSize();

	return (
		<SuccessModal isVisible={isVisible} onHide={onHide} displayMode={"failure"}>
			<Flex>
				<ContentWrapper>
					<IconAndBadgeWrapper>{Icon}</IconAndBadgeWrapper>
					<MessageContainer>
						<H1Span>{title}</H1Span>
						<TkaP2Span tkaColor={shade100}>{description}</TkaP2Span>
					</MessageContainer>
				</ContentWrapper>
				<ButtonsWrapper>
					<ShowAnswerButton contentSubmissionFragmentRef={contentSubmission} />
					<Button
						fillParent={!isMediumUp}
						minWidthRem={10}
						label={t("failure_evaluation_modal.tryAgain")}
						onClick={tryAgainClicked}
					/>
				</ButtonsWrapper>
			</Flex>
		</SuccessModal>
	);
};
