import React from "react";
import { Icon } from "@components/icon";
import { IconWrapper } from "@screens/offers/parts/tags/parts/toggle-extend-collapse/toggle-extend-collapse.styles";
import { ToggleExtendCollapseProps } from "./toggle-extend-collapse.types";

export const ToggleExtendCollapse = ({
	isCollapsed,
	onToggle: handleOnClick,
}: ToggleExtendCollapseProps) => {
	return (
		<IconWrapper isCollapsed={isCollapsed} onClick={handleOnClick}>
			<Icon sizeInRem={0.5} icon={"playArrow"} />
		</IconWrapper>
	);
};
