import { Card } from "@components/card/card.component";
import { MaterialIcon } from "@components/material-icon/material-icon.component";
import { shade5 } from "@themes/colors";
import { TkaH3Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { Flex, FlexColumn } from "./material-card.style";
import { MaterialCardProps } from "./material-card.types";
import { Icon } from "../icon";

export const MaterialCard = ({
	icon,
	title,
	description,
	disabled,
	isExpanded,
	bodyPadding,
	noBorder,
	fileUrl,
	fileName,
	onClick,
	deleteIcon,
	deleteOnClick,
}: MaterialCardProps) => {
	const DownloadIcon =
		isExpanded &&
		(disabled ? (
			<Icon icon="lock" sizeInRem={1} />
		) : (
			<MaterialIcon icon={"downloadBottom"} showBorder={false} hasCursorPointer={true} />
		));
	const Title = isExpanded ? TkaP2Span : TkaH3Span;
	const Description = isExpanded ? TkaH3Span : TkaP3Span;

	const handleOnClick = () => {
		onClick?.();
	};

	return (
		<Card
			showBorder={isExpanded && !noBorder}
			bodyPaddingInRem={bodyPadding ?? 0.75}
			onClick={!disabled ? handleOnClick : undefined}
			hasCursorPointer={!disabled && !!onClick}
		>
			<Flex disabled={disabled}>
				<MaterialIcon
					icon={icon}
					showBorder={!isExpanded}
					{...(isExpanded ? { backgroundColor: shade5 } : {})}
				/>
				<FlexColumn>
					<Title>{title}</Title>
					<Description>{description}</Description>
				</FlexColumn>
				{fileUrl && !disabled ? (
					<a
						href={fileUrl ?? ""}
						target="_blank"
						rel="noopener noreferrer"
						download={fileName}
					>
						{DownloadIcon}
					</a>
				) : deleteIcon ? (
					<div onClick={deleteOnClick}>
						<MaterialIcon showBorder={false} icon={"trash"} />
					</div>
				) : (
					DownloadIcon
				)}
			</Flex>
		</Card>
	);
};
