import { FC, useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { TrendCard } from "@components/trend-card";
import { useWindowSize } from "@hooks/use-window-size";
import { trendOverview_trendFragment$key } from "@relay/trendOverview_trendFragment.graphql";
import { brandStrong100 } from "@themes/colors";
import { TREND_OVERVIEW_FRAGMENT } from "./trend-overview.graphql";
import { ButtonContainer, TrendWrapper, Wrapper } from "./trend-overview.styles";
import { TrendOverviewProps } from "./trend-overview.types";

export const TrendOverview: FC<TrendOverviewProps> = ({ queryFragmentRef }) => {
	const { isLargeUp } = useWindowSize();
	const [numberOfVisibleTrends, setNumberOfVisibleTrends] = useState(isLargeUp ? 10 : 8);

	const additionalTrends = isLargeUp ? 5 : 4;
	const loadMore = () => {
		setNumberOfVisibleTrends((prevVisibleTrends) => prevVisibleTrends + additionalTrends);
	};

	const data = useFragment<trendOverview_trendFragment$key>(
		TREND_OVERVIEW_FRAGMENT,
		queryFragmentRef ?? null,
	);

	const visibleTrends = data?.CustomerJourney?.trend?.edges
		?.map((edge) => {
			if (edge?.node.trendData.visible) {
				return { id: edge?.node.id, name: edge?.node.trendData.name, node: edge?.node };
			}
		})
		.filter(Boolean);

	const showMoreButton = visibleTrends && visibleTrends.length > numberOfVisibleTrends;
	return (
		<Wrapper>
			<TrendWrapper>
				{visibleTrends?.slice(0, numberOfVisibleTrends).map((trend, i) => (
					<TrendCard key={`${i}_${trend?.name}`} trendFragmentRef={trend?.node} />
				))}
			</TrendWrapper>
			{showMoreButton && (
				<ButtonContainer>
					<Button
						textLinkColor={brandStrong100}
						colorVersion={"textlink"}
						label="Mehr anzeigen"
						onClick={loadMore}
					/>
				</ButtonContainer>
			)}
		</Wrapper>
	);
};
