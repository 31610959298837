import styled from "styled-components";
import { spacing16, spacing4, spacing8 } from "@themes/spacing";

export const Wrapper = styled.div`
	padding: ${spacing8.rem()};
	width: 100%;
`;

export const Spacing16 = styled.div`
	height: ${spacing16.rem()};
`;
export const Spacing4 = styled.div`
	height: ${spacing4.rem()};
`;
