import tw from "twin.macro";

export const HeaderWrapper = tw.div`
flex
flex-wrap
md:flex-nowrap
w-full
mb-[1rem]
items-center
`;

export const BottomBatchEditSpacer = tw.div`
	h-[4rem]
	md:hidden
`;

export const Wrapper = tw.div`
	flex
	flex-col
`;

export const MembersWrapper = tw.div`
flex
flex-col
gap-[0.5rem]`;
