import { graphql } from "babel-plugin-relay/macro";

export const PARTICIPANT_FRAGMENT = graphql`
	fragment participantPresenceCheck_ParticipantFragment on Participant {
		user {
			id
			name
			...userAvatar_UserFragment
		}
		participationStatus
	}
`;

export const REGISTER_MY_PARTICIPATION_MUTATION = graphql`
	mutation participantPresenceCheck_RegisterMyParticipationMutation(
		$input: RegisterParticipationInput!
	) {
		Coaching {
			registerParticipation(input: $input) {
				availability {
					...appointmentOverviewModal_AvailabilityFragment
				}
			}
		}
	}
`;
