import { Timeline } from "primereact/timeline";
import styled from "styled-components";
import tw from "twin.macro";
import { brandMain100, brandMain40 } from "@themes/colors";

export const StyledTimeline = styled(Timeline)<{
	currentStep: number;
	animationTime: number;
	progressBarOffestRem?: number;
	hideContent?: boolean;
}>`
	&.p-timeline-horizontal .p-timeline-event {
		${tw`min-h-min`}
	}
	&.p-timeline-horizontal .p-timeline-event-connector {
		${tw`h-[0.3125rem] mx-[-0.1rem]`}
	}
	&.p-timeline-vertical .p-timeline-event-connector {
		${tw`w-[0.3125rem] my-[-0.1rem]`}
	}
	&.p-timeline-horizontal .p-timeline-event-separator {
		${({ progressBarOffestRem }) =>
			progressBarOffestRem ? `transform: translateX(${progressBarOffestRem}rem);` : ""}
	}
	&.p-timeline-vertical .p-timeline-event-separator {
		${({ progressBarOffestRem }) =>
			progressBarOffestRem ? `transform: translateY(${progressBarOffestRem}rem);` : ""}
	}
	&.p-timeline-horizontal
		.p-timeline-event:nth-child(-n + ${({ currentStep }) => currentStep - 1})
		.p-timeline-event-connector {
		${tw`bg-brand-main-100`}
		background-position: left bottom;
	}
	&.p-timeline-vertical
		.p-timeline-event:nth-child(-n + ${({ currentStep }) => currentStep - 1})
		.p-timeline-event-connector {
		${tw`bg-brand-main-100`}
		background-position: left top;
	}
	&.p-timeline-horizontal .p-timeline-event-connector {
		background: linear-gradient(to right, ${brandMain100} 50%, ${brandMain40} 50%);
		background-size: 200% 100%;
		background-position: right bottom;
	}
	&.p-timeline-vertical .p-timeline-event-connector {
		background: linear-gradient(to bottom, ${brandMain100} 50%, ${brandMain40} 50%);
		background-size: 100% 200%;
		background-position: left bottom;
	}
	& .p-timeline-event-connector {
		${tw`bg-brand-main-40`}
		transition: all ${({ animationTime }) => animationTime}ms ease;
	}
	& .p-timeline-event-opposite {
		${tw`hidden`}
	}
	& .p-timeline-event-content {
		${({ hideContent }) => (hideContent ? tw`hidden` : "")}
	}
`;

export const StyledMarker = styled.div<{ accomplished: boolean }>`
	${tw`
		w-[0.75rem]
		h-[0.75rem]
		rounded-6
		z-10
	`}
	${({ accomplished }) => (accomplished ? tw`bg-brand-main-100` : tw`bg-brand-main-40`)}
`;
