/**
 * @generated SignedSource<<19704d76c0689e92a883b91846a604aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookCoachingInput = {
  availabilityId: string;
  clientMutationId?: string | null;
  coachingId: string;
};
export type bookAppointment_BookCoachingMutation$variables = {
  input: BookCoachingInput;
};
export type bookAppointment_BookCoachingMutation$data = {
  readonly Coaching: {
    readonly bookCoaching: {
      readonly availability: {
        readonly booking: {
          readonly offer?: {
            readonly description: {
              readonly name: string;
            } | null;
            readonly id: string;
          } | null;
        };
        readonly " $fragmentSpreads": FragmentRefs<"bookingSuccessModal_AvailabilityFragment">;
      };
    } | null;
  };
};
export type bookAppointment_BookCoachingMutation = {
  response: bookAppointment_BookCoachingMutation$data;
  variables: bookAppointment_BookCoachingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CoachingOfferDescription",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AcademiesTagData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bookAppointment_BookCoachingMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoachingMutations",
        "kind": "LinkedField",
        "name": "Coaching",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BookCoachingPayload",
            "kind": "LinkedField",
            "name": "bookCoaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Availability",
                "kind": "LinkedField",
                "name": "availability",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "booking",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoachingOffer",
                            "kind": "LinkedField",
                            "name": "offer",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoachingOfferDescription",
                                "kind": "LinkedField",
                                "name": "description",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "BookedOneToOne",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "bookingSuccessModal_AvailabilityFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bookAppointment_BookCoachingMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoachingMutations",
        "kind": "LinkedField",
        "name": "Coaching",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BookCoachingPayload",
            "kind": "LinkedField",
            "name": "bookCoaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Availability",
                "kind": "LinkedField",
                "name": "availability",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "booking",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoachingOffer",
                            "kind": "LinkedField",
                            "name": "offer",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "BookedOneToOne",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoachingOffer",
                            "kind": "LinkedField",
                            "name": "offer",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "BookedForGroup",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AvailabilityData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDateTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDateTime",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa133167e7bd12eaf9e12119d529d5c6",
    "id": null,
    "metadata": {},
    "name": "bookAppointment_BookCoachingMutation",
    "operationKind": "mutation",
    "text": "mutation bookAppointment_BookCoachingMutation(\n  $input: BookCoachingInput!\n) {\n  Coaching {\n    bookCoaching(input: $input) {\n      availability {\n        booking {\n          __typename\n          ... on BookedOneToOne {\n            offer {\n              id\n              description {\n                name\n              }\n            }\n          }\n        }\n        ...bookingSuccessModal_AvailabilityFragment\n        id\n      }\n    }\n  }\n}\n\nfragment bookingSuccessModal_AvailabilityFragment on Availability {\n  booking {\n    __typename\n    ... on BookedOneToOne {\n      offer {\n        description {\n          name\n          tags {\n            __typename\n            id\n            data {\n              name\n            }\n          }\n        }\n        id\n      }\n    }\n    ... on BookedForGroup {\n      offer {\n        description {\n          name\n          tags {\n            __typename\n            id\n            data {\n              name\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n  data {\n    startDateTime\n    endDateTime\n  }\n}\n"
  }
};
})();

(node as any).hash = "4460e8eab2ea9fb9f56bdf72491dfb43";

export default node;
