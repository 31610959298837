import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	h-screen
`;

export const ContentWrapper = tw.div`
	flex-1
`;
