/**
 * @generated SignedSource<<0b6a37a54a98fe11240644539cf39d60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type IHKCertificateOrderStateKind = "ErrorOccurred" | "NotIHK" | "Ordered" | "ReadyToOrder" | "RewardNotAchievedYet";
export type OrderIHKCertificateInput = {
  clientMutationId?: string | null;
  rootId: string;
  userData: IHKCertificateOrderUserDataInput;
};
export type IHKCertificateOrderUserDataInput = {
  city: string;
  country: string;
  dateOfBirth: string;
  firstName: string;
  houseNumber: string;
  lastName: string;
  postalCode: string;
  salutation?: string | null;
  street: string;
  title?: string | null;
};
export type orderIhkModal_OrderIHKCertificateMutation$variables = {
  input: OrderIHKCertificateInput;
};
export type orderIhkModal_OrderIHKCertificateMutation$data = {
  readonly Learn: {
    readonly orderIHKCertificate: {
      readonly root: {
        readonly structureDefinition: {
          readonly extension?: {
            readonly ihkState?: IHKCertificateOrderStateKind;
          };
        };
      };
    } | null;
  };
};
export type orderIhkModal_OrderIHKCertificateMutation = {
  response: orderIhkModal_OrderIHKCertificateMutation$data;
  variables: orderIhkModal_OrderIHKCertificateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ihkState",
      "storageKey": null
    }
  ],
  "type": "PublishedRootExtensionImpl",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "orderIhkModal_OrderIHKCertificateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnMutations",
        "kind": "LinkedField",
        "name": "Learn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrderIHKCertificatePayload",
            "kind": "LinkedField",
            "name": "orderIHKCertificate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "root",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "structureDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "extension",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "LearnOpportunityRootStructureDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "orderIhkModal_OrderIHKCertificateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnMutations",
        "kind": "LinkedField",
        "name": "Learn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrderIHKCertificatePayload",
            "kind": "LinkedField",
            "name": "orderIHKCertificate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "root",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "structureDefinition",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "extension",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "LearnOpportunityRootStructureDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37ce5413599ac9fc2e68cb78fb5d5436",
    "id": null,
    "metadata": {},
    "name": "orderIhkModal_OrderIHKCertificateMutation",
    "operationKind": "mutation",
    "text": "mutation orderIhkModal_OrderIHKCertificateMutation(\n  $input: OrderIHKCertificateInput!\n) {\n  Learn {\n    orderIHKCertificate(input: $input) {\n      root {\n        structureDefinition {\n          __typename\n          ... on LearnOpportunityRootStructureDefinition {\n            extension {\n              __typename\n              ... on PublishedRootExtensionImpl {\n                ihkState\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28a7c2322029311b60ac3dd7d43d01d2";

export default node;
