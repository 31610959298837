import Lottie from "react-lottie";
import * as animationData from "./loading.json";

export const LottieControl = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	return <Lottie options={defaultOptions} height="100%" width="100%" />;
};
