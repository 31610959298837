import { CheckboxChangeEvent } from "primereact/checkbox";
import { TkaColor } from "@themes/colors";

export interface CheckboxProps {
	checked: boolean;
	value?: any;
	onChange?: (event: CheckboxChangeEvent) => void;
	status?: CheckboxStatus;
	backgroundColor?: TkaColor;
}

export enum CheckboxStatus {
	Default = "default",
	Disabled = "disabled",
	Error = "error",
}
