import graphql from "babel-plugin-relay/macro";

export const CREATE_USER_IN_ACCOUNT_GROUP_AND_ADD_OR_INVITE_USER_MUTATION = graphql`
	mutation createGroupModal_CreateUserInAccountGroupAndAddOrInviteUsersMutation(
		$input: CreateUserInAccountGroupAndAddOrInviteUsersMutationInput!
		$groupsConnectionIds: [ID!]!
		$membersConnectionIds: [ID!]!
	) {
		AccountMemberManagement {
			createUserInAccountGroupAndAddOrInviteUsersMutation(input: $input) {
				newGroup @appendEdge(connections: $groupsConnectionIds) {
					node {
						...groupCard_UserInAccountGroupWrapperFragment
						...createGroupSuccessModal_UserInAccountGroupWrapperFragment
						newInvitations {
							edges @appendEdge(connections: $membersConnectionIds) {
								node {
									...membersTab_AccountMemberFragment
								}
							}
						}
					}
				}
			}
		}
	}
`;
