import { css, cva } from "@styled-system/css";
import { hstack } from "@styled-system/patterns";

export const wrapperClass = cva({
	base: {
		display: "flex",
		flexDirection: "column",
		gap: "4",
	},
	variants: {
		isBlured: {
			true: {
				opacity: "0.5",
				pointerEvents: "none",
				_hover: {
					cursor: "default",
				},
			},
		},
	},
});

export const cardContentClass = hstack({
	gap: "24",
	px: "16",
	py: "8",
	minH: "[3.75rem]",
});

export const labelClass = css({
	flex: "1",
	color: "shade.100",
	textAlign: "left",
});

export const inputWrapperClass = css({
	flex: "1",
});
