import { DayOfWeek, LocalTime } from "@js-joda/core";
import {
	CalendarItem,
	CalendarWeekAndYear,
	DetailedCalendarItem,
	TimeSlotsMap,
} from "./my-availabilities.types";

export function removeOffset<T extends CalendarItem>(entries: T[]): T[];
export function removeOffset<T extends DetailedCalendarItem>(entries: T[]): T[];
export function removeOffset<T extends CalendarItem = CalendarItem>(entries: T[]): T[] {
	const offset = new Date().getTimezoneOffset();
	const convertedEntries: T[] = [];
	entries.forEach((entry) => {
		entry.timeSlots.forEach((timeSlot) => {
			const localTime = LocalTime.parse(timeSlot);
			const timeWithOffset = localTime.plusMinutes(offset);

			let dayName = entry.dayOfWeek;

			if (timeWithOffset.isAfter(localTime)) {
				dayName = DayOfWeek.valueOf(entry.dayOfWeek).minus(1).name();
			}

			if ("calendarYear" in entry && "calendarWeek" in entry) {
				const castedEntries = convertedEntries as unknown as DetailedCalendarItem[];
				const dayAndTime = castedEntries.find((d) => {
					const isSameDay = d.dayOfWeek === dayName;
					const sameYear = d.calendarYear === entry.calendarYear;
					const sameWeek = d.calendarWeek === entry.calendarWeek;
					return isSameDay && sameWeek && sameYear;
				});

				if (dayAndTime) {
					dayAndTime.timeSlots.push(timeWithOffset.toString());
				} else {
					convertedEntries.push({
						...entry,
						timeSlots: [timeWithOffset.toString()],
						dayOfWeek: dayName,
					});
				}
			} else {
				const dayAndTime = convertedEntries.find((d) => d.dayOfWeek === dayName);

				if (dayAndTime) {
					dayAndTime.timeSlots.push(timeWithOffset.toString());
				} else {
					convertedEntries.push({
						...entry,
						timeSlots: [timeWithOffset.toString()],
						dayOfWeek: dayName,
					});
				}
			}
		});
	});
	return convertedEntries;
}

export function addOffset<T extends CalendarItem>(entries: T[]): T[];
export function addOffset<T extends DetailedCalendarItem>(entries: T[]): T[];
export function addOffset<T extends CalendarItem = CalendarItem>(entries: T[]): T[] {
	const offset = new Date().getTimezoneOffset();
	const convertedEntries: T[] = [];
	entries.forEach((entry) => {
		entry.timeSlots.forEach((timeSlot) => {
			const localTime = LocalTime.parse(timeSlot);
			const timeWithOffset = localTime.minusMinutes(offset);

			let dayName = entry.dayOfWeek;

			if (timeWithOffset.isBefore(localTime)) {
				dayName = DayOfWeek.valueOf(entry.dayOfWeek).plus(1).name();
			}

			if ("calendarYear" in entry && "calendarWeek" in entry) {
				const castedEntries = convertedEntries as unknown as DetailedCalendarItem[];
				const dayAndTime = castedEntries.find((d) => {
					const isSameDay = d.dayOfWeek === dayName;
					const sameYear = d.calendarYear === entry.calendarYear;
					const sameWeek = d.calendarWeek === entry.calendarWeek;
					return isSameDay && sameWeek && sameYear;
				});
				if (dayAndTime) {
					dayAndTime.timeSlots.push(timeWithOffset.toString());
				} else {
					convertedEntries.push({
						...entry,
						timeSlots: [timeWithOffset.toString()],
						dayOfWeek: dayName,
					});
				}
			} else {
				const dayAndTime = convertedEntries.find((d) => d.dayOfWeek === dayName);
				if (dayAndTime) {
					dayAndTime.timeSlots.push(timeWithOffset.toString());
				} else {
					convertedEntries.push({
						...entry,
						timeSlots: [timeWithOffset.toString()],
						dayOfWeek: dayName,
					});
				}
			}
		});
	});
	return convertedEntries;
}

export function toTimeSlotsMap<T extends CalendarItem = CalendarItem>(entries: T[]): TimeSlotsMap {
	const map: TimeSlotsMap = {};
	entries?.map((daySchedule) => {
		daySchedule.timeSlots.forEach((timeSlot) => {
			let day = DayOfWeek.valueOf(daySchedule.dayOfWeek);
			const localTime = LocalTime.parse(timeSlot);

			if (localTime.isAfter(localTime)) {
				day = day.plus(1);
			}

			const dayName = day.name();
			map[dayName] = map[dayName] ?? [];
			map[dayName].push(localTime);
		});
	});
	return map;
}

/**
 * Sort Array By Calendar Week from the earliest to the latest
 * @param arr
 */
export function sortByCalendarWeeks<T extends CalendarWeekAndYear>(arr: T[]): T[] {
	return arr.sort((a, b) => a.calendarYear - b.calendarYear + (a.calendarWeek - b.calendarWeek));
}
