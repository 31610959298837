import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query myDocuments_Query {
		Viewer {
			Coaching {
				MyDocuments {
					...myDocuments_FileInlineFragment
				}
			}
			Learn {
				myNodeDocuments {
					...myDocuments_FileInlineFragment
				}
			}
		}
	}
`;

export const FILE_INLINE_FRAGMENT = graphql`
	fragment myDocuments_FileInlineFragment on File @inline {
		id
		name
		url
		thumbnail
	}
`;
