/**
 * @generated SignedSource<<e2a53e83f45f9a072daf4dce942bebf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type myAvailabilities_AvailabilityScheduleFragment$data = {
  readonly __typename: "AvailabilitySchedule";
  readonly data: {
    readonly __typename: "ScheduleData";
    readonly blockoutDates: ReadonlyArray<{
      readonly from: string;
      readonly to: string;
      readonly " $fragmentSpreads": FragmentRefs<"blockoutDateCard_BlockoutDateFragment">;
    }>;
    readonly daysAndTimes: ReadonlyArray<{
      readonly dayOfWeek: string;
      readonly timeSlots: ReadonlyArray<string>;
    }>;
    readonly overrideDaysAndTimesForCalendarWeek: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"myAvailabilities_OverrideDaysAndTimesFragment">;
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "myAvailabilities_AvailabilityScheduleFragment";
};
export type myAvailabilities_AvailabilityScheduleFragment$key = {
  readonly " $data"?: myAvailabilities_AvailabilityScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"myAvailabilities_AvailabilityScheduleFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "myAvailabilities_AvailabilityScheduleFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailabilityScheduleDayAndTimes",
          "kind": "LinkedField",
          "name": "daysAndTimes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayOfWeek",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timeSlots",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BlockoutDate",
          "kind": "LinkedField",
          "name": "blockoutDates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "from",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "to",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "blockoutDateCard_BlockoutDateFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DayAndTimesForCalendarWeek",
          "kind": "LinkedField",
          "name": "overrideDaysAndTimesForCalendarWeek",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "myAvailabilities_OverrideDaysAndTimesFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AvailabilitySchedule",
  "abstractKey": null
};
})();

(node as any).hash = "89cf865a353cfe2d9e331b07da03e8dd";

export default node;
