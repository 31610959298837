import { useState } from "react";
import { useFragment } from "react-relay";
import { Calendar } from "@components/calendar";
import { DialogTemplate } from "@components/dialog-template";
import { Message } from "@components/message";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { H1Span, TkaP2Span } from "@themes/font-tags";
import { parseDateTime } from "@utils/date-utils";
import { BLOCKOUT_DATE_FRAGMENT } from "./blockout-date-modal.graphql";

import { TitleWrapper, Wrapper } from "./blockout-date-modal.styles";
import { BlockoutDateModalProps } from "./blockout-date-modal.types";

// TODO: add-translations
export const BlockoutDateModal = ({
	isVisible,
	onDismiss,
	onSave,
	onCreate,
	blockoutDateFragmentRef = null,
}: BlockoutDateModalProps) => {
	const blockoutDate = useFragment(BLOCKOUT_DATE_FRAGMENT, blockoutDateFragmentRef);

	const [fromDate, setFromDate] = useState<Date | undefined>(
		blockoutDate?.from ? parseDateTime(blockoutDate.from) : undefined,
	);
	const [toDate, setToDate] = useState<Date | undefined>(
		blockoutDate?.to ? parseDateTime(blockoutDate.to) : undefined,
	);
	const [dateError, setDateError] = useState<string | undefined>();

	const isEditing = !!blockoutDate;

	const handleOnPrimaryButtonClick = () => {
		if (!fromDate || !toDate) return;
		if (fromDate >= toDate) {
			setDateError(`"Von"- muss vor "Bis"-Zeitpunkt liegen.`);
			return;
		}
		const callback = isEditing ? onSave : onCreate;
		callback?.(fromDate, toDate);
		onDismiss?.();
	};

	const handleOnSecondaryButtonClick = () => {
		onDismiss?.();
	};

	const handleFormDateOnChange = (date?: Date) => {
		if (date && toDate && date < toDate) {
			setDateError(undefined);
		}
		setFromDate(date);
	};
	const handleToDateOnChange = (date?: Date) => {
		if (date && fromDate && date > fromDate) {
			setDateError(undefined);
		}
		setToDate(date);
	};

	const title = isEditing ? "Abwesenheit bearbeiten" : "Neue Abwesenheit";

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				primaryButtonLabel={isEditing ? "Speichern" : "Hinzufügen"}
				primaryButtonIconName={!isEditing ? "add" : undefined}
				primaryButtonStretch={false}
				onPrimaryButtonClick={handleOnPrimaryButtonClick}
				secondaryButtonLabel="Abbrechen"
				secondaryButtonStretch={false}
				onSecondaryButtonClick={handleOnSecondaryButtonClick}
			>
				<Wrapper>
					<TitleWrapper>
						<H1Span>{title}</H1Span>
						<TkaP2Span>
							Nutze die Abwesenheiten um z.B. deinen Urlaub einzutragen. In diesen
							Zeitfenstern ist es nicht möglich dich zu buchen.
						</TkaP2Span>
					</TitleWrapper>
					<Message
						highlighted
						severity="warn"
						summary="Terminbuchungen"
						detail="Bestehende Terminbuchungen werden durch das Verändern deiner Verfügbarkeiten nicht beeinflusst."
					/>
					<Calendar
						showTime
						label="Von"
						status={dateError ? "error" : undefined}
						smallTextBellow={dateError}
						value={fromDate}
						onChange={handleFormDateOnChange}
					/>
					<Calendar
						showTime
						label="Bis"
						status={dateError ? "error" : undefined}
						smallTextBellow={dateError}
						value={toDate}
						onChange={handleToDateOnChange}
					/>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
