import { createContext } from "react";
import { SelectedMember } from "../members-tab/members-tab.types";

type GroupDetailsContext = {
	selectedMembers: SelectedMember[];
	toogleMember: (member: SelectedMember) => void;
	clearSelectedMembers: () => void;
	numberOfConfirmedMembers?: number;
	numberOfUnconfirmedMembers?: number;
	setNumberOfConfirmedMembers?: (number: number) => void;
	setNumberOfUnconfirmedMembers?: (number: number) => void;
};

export const GroupDetailsContext = createContext<GroupDetailsContext>({
	selectedMembers: [],
	toogleMember: () => {},
	clearSelectedMembers: () => {},
	numberOfConfirmedMembers: 0,
	numberOfUnconfirmedMembers: 0,
	setNumberOfConfirmedMembers: () => {},
	setNumberOfUnconfirmedMembers: () => {},
});
