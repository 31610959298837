import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { TkaColor } from "@themes/colors";

export interface RadioButtonProps {
	checked?: boolean;
	value?: any;
	onChange?: (event: RadioButtonChangeEvent) => void;
	status?: RadioButtonStatus;
	icon?: JSX.Element;
	iconBackgroundColor?: TkaColor;
}

export enum RadioButtonStatus {
	Default = "default",
	Disabled = "disabled",
	Error = "error",
}
