import { useContext } from "react";
import { Icon } from "@components/icon";
import { InputText } from "@components/input-text";
import { useWindowSize } from "@hooks/use-window-size";
import { useTkaTranslation } from "@hooks/useTkaTranslation";
import { OffersScreenContext } from "@screens/offers/offers.context";
import { shade100, shade40 } from "@themes/colors";
import { SearchBarProps } from "./search-bar.types";

export const SearchBar = ({ onClick: handleOnClick }: SearchBarProps) => {
	const { t } = useTkaTranslation("offers");
	const placeholder = t("Suchen");
	const {
		filters: { text: value },
		helpers: { setText },
	} = useContext(OffersScreenContext);
	const handleOnChange = (e?: string) => {
		setText(e);
	};
	const { isMediumUp } = useWindowSize();
	const iconColor = isMediumUp ? shade40 : shade100;
	const iconSize = isMediumUp ? 1.5 : 0.75;
	if (!isMediumUp)
		return (
			<Icon
				icon={"search"}
				sizeInRem={iconSize}
				tkaColor={iconColor}
				onClick={handleOnClick}
			/>
		);

	return (
		<InputText
			icon={"search"}
			placeholder={placeholder + "..."}
			value={value}
			onChange={handleOnChange}
		/>
	);
};
