import styled from "styled-components";
import tw from "twin.macro";
import { shade80 } from "@themes/colors";
import { spacing12, spacing4 } from "@themes/spacing";

export const HeadlineWrapper = styled.div`
	margin-bottom: ${spacing4.rem()};
`;

export const TextWrapper = styled.div`
	margin-top: ${spacing12.rem()};
	color: ${shade80};
`;

export const StyledOl = tw.ol`
	list-decimal
	pl-20
`;
export const StyledUl = tw.ol`
	list-disc
	pl-20
`;
