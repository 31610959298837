import { Modal } from "@components/modal";
import { error20, brandMain20 } from "@themes/colors";
import { SuccessModalProps } from "./success-modal.types";

export const SuccessModal = ({ children, isVisible, onHide, displayMode }: SuccessModalProps) => {
	const backgroundColor = displayMode == "success" ? brandMain20 : error20;
	return (
		<Modal
			isVisible={isVisible}
			onHide={onHide}
			modal={false}
			position={"bottom"}
			backgroundColor={backgroundColor}
		>
			{children}
		</Modal>
	);
};
