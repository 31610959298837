import tw from "twin.macro";
import { TkaH3Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	flex
	flex-row
	items-center
	gap-12
	p-12
	pr-24
`;

export const GroupName = tw(TkaH3Span)`
	flex-1	
`;
