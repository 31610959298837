import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	pt-20
	pb-16
	md:p-0
`;

export const OptionsWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const ItemWrapper = styled.button<{ dangerous?: boolean }>`
	${tw`
		flex
		flex-row
		items-center
		gap-12
		md:gap-8
		py-8
		px-16
		hover:opacity-60
		hover:cursor-pointer
	`}
	${({ dangerous }) => dangerous && tw`text-error-100`}
`;
