import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useRefetchableFragment } from "react-relay";
import { Spacing16 } from "@components/news-card/news-card.styles";
import { RadioButton } from "@components/radio-button";
import { licenseSettings_EditLicenseModelMutation } from "@relay/licenseSettings_EditLicenseModelMutation.graphql";
import { Kind } from "@relay/licenseSettings_QueryFragment.graphql";
import { selectCurrentAccountId } from "@slices/AuthSlice";
import { shade100, shade60 } from "@themes/colors";
import { TkaH3Span, TkaP2Span } from "@themes/font-tags";
import { AccountData, getLicenseModelKindForAccount } from "@utils/licenses-utils";
import { EDIT_LICENSE_MODEL_MUTATION, QUERY_FRAGMENT } from "./license-settings.graphql";
import { OptionWrapper, OptionsWrapper, TextWrapper, Wrapper } from "./license-settings.styles";
import { LicenseSettingsProps } from "./license-settings.types";

export const LicenseSettings: FC<LicenseSettingsProps> = ({ queryFragmentRef }) => {
	const [editLicenseModel] = useMutation<licenseSettings_EditLicenseModelMutation>(
		EDIT_LICENSE_MODEL_MUTATION,
	);
	const [data, refetch] = useRefetchableFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const currentAccountId: string | undefined = useSelector(selectCurrentAccountId);
	const accountData = data?.Viewer?.Auth?.currentUser?.accounts;
	const licenseModelKind =
		(accountData &&
			getLicenseModelKindForAccount(accountData as AccountData, currentAccountId)) ??
		"FreeForAll";
	const [selectedOption, setSelectedOption] = useState<Kind>(licenseModelKind as Kind);

	const handleOnChange = (kind: Kind) => {
		setSelectedOption(kind);
		editLicenseModel({
			variables: {
				input: {
					licenseModelKind: kind,
				},
			},
			onCompleted: () => {
				refetch({}, { fetchPolicy: "store-and-network" });
			},
		});
	};

	return (
		<Wrapper>
			<TkaH3Span>Wer darf die Lizenzen nutzen? </TkaH3Span>
			<Spacing16 />
			<OptionsWrapper>
				<OptionWrapper>
					<TextWrapper>
						<TkaP2Span tkaColor={shade100}>Jeder im Konto</TkaP2Span>
						<TkaP2Span tkaColor={shade60}>
							Jeder darf die Lizenzen ohne Einschränkung nutzen. Die bestehenden
							Regeln werden ignoriert.
						</TkaP2Span>
					</TextWrapper>
					<RadioButton
						value={"FreeForAll"}
						checked={selectedOption === "FreeForAll"}
						onChange={() => handleOnChange("FreeForAll")}
					/>
				</OptionWrapper>
				<OptionWrapper>
					<TextWrapper>
						<TkaP2Span tkaColor={shade100}>Nur auf Zuweisung</TkaP2Span>
						<TkaP2Span tkaColor={shade60}>
							Innerhalb der Lizenzen werden die Regeln für die Nutzung festgelegt.
						</TkaP2Span>
					</TextWrapper>
					<RadioButton
						value={"Assigment"}
						checked={selectedOption === "Assigment"}
						onChange={() => handleOnChange("Assigment")}
					/>
				</OptionWrapper>
			</OptionsWrapper>
		</Wrapper>
	);
};
