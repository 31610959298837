import graphql from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_QUESTION_FRAGMENT = graphql`
	fragment textQuestion_PotentialAnalysisQuestionFragment on PotentialAnalysisQuestion {
		kind
		id
		... on PotentialAnalysisTextQuestion {
			questionText
			kind
			givenAnswer {
				text
				kind
			}
		}
	}
`;

export const SET_ANSWER_IN_TEXT_QUESTION_MUTATION = graphql`
	mutation textQuestion_SetAnswerInTextQuestionMutation($input: SetAnswerInTextQuestionInput!) {
		AvgsPotentialAnalysis {
			setAnswerInTextQuestion(input: $input) {
				question {
					id
					givenAnswer {
						text
					}
				}
			}
		}
	}
`;
