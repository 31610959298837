import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as ArrowLeft } from "@assets/arrow_left.svg";
import { BreakpointInPx } from "@themes/breakpoint";
import { shade0, shade10 } from "@themes/colors";
import { Button, ButtonProps } from "./button";
import { ProgressBar } from "./progress-bar";

export const AlternativeNavigationHeightRem = 4.5;

export interface AlternativeNavigationOrganismProps {
	back?: (e: React.MouseEvent<HTMLDivElement>) => void;
	button?: ButtonProps;
	progressBarValue?: number;
	nextButton?: React.ReactNode;
}

export const AlternativeNavigationOrganism = ({
	back,
	button,
	progressBarValue,
	nextButton,
}: AlternativeNavigationOrganismProps) => {
	const MobileAlternativeSection = () => {
		const C2AButton = button && (
			<ButtonWrapper>{nextButton ?? <Button fillParent {...button} />}</ButtonWrapper>
		);
		const BackButton = back && (
			<ArrowLeftWrapper onClick={back}>
				<ArrowLeft />
			</ArrowLeftWrapper>
		);
		return (
			<>
				{BackButton}
				{C2AButton}
			</>
		);
	};

	return (
		<Wrapper>
			{progressBarValue && <ProgressBar colorVersion={"course"} value={progressBarValue} />}
			<Flex>
				<MobileAlternativeSection />
			</Flex>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: ${AlternativeNavigationHeightRem}rem;
	width: 100%;
	background-color: ${shade0};
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	box-shadow: 0px -6px 10px rgba(9, 46, 88, 0.1);
	padding: 0;

	position: fixed;
	bottom: 0;
	left: 0;
	@media screen and (min-width: ${BreakpointInPx.xlarge}px) {
		position: relative;
		bottom: unset;
	}
`;
const Flex = styled.div`
	flex: 1;
	display: flex;
	gap: 1rem;
	justify-content: space-around;
	align-items: center;
	padding: 0 1rem;

	@media screen and (min-width: ${BreakpointInPx.medium}px) {
		justify-content: flex-end;
	}
`;

const ArrowLeftWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${shade10};
	border-radius: 100%;
	padding: 0.75rem;
	cursor: pointer;
`;

const ButtonWrapper = tw.div`
	flex-1
	md:flex-none
	min-w-[10rem]
`;
