import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	md:flex-row
	gap-8
	md:items-center
	justify-between
`;

export const NameWrapper = tw.div`
	flex
	flex-col
	flex-1
	whitespace-nowrap
`;

export const LeftWrapper = tw.div`
	flex
	flex-row
	gap-8
	items-center
`;

export const SelectButtonWrapper = tw.div`
	ml-[1.5rem]
	md:ml-0
`;
