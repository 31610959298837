import graphql from "babel-plugin-relay/macro";

export const CREATE_ORDER_MUTATION = graphql`
	mutation createOrder_CreateOrderMutation($input: CreateOrderInput!) {
		Billing {
			createOrder(input: $input) {
				order {
					id
					cart {
						items {
							... on CartProduct {
								product {
									id
									title
								}
							}
						}
						totals {
							includingAllDiscounts {
								grossPrice
								netPrice
							}
						}
					}
				}
			}
		}
	}
`;
