import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ImageCardAtom } from "@components/ImageCardAtom";
import { Label } from "@components/label";
import { ProgressBar } from "@components/progress-bar";
import { RootProgress_LearnOpportunityV2Fragment$key } from "@relay/RootProgress_LearnOpportunityV2Fragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaH3Span } from "@themes/font-tags";
import { ImageWidth, ProgressUnit } from "./root-progress.const";
import { RootProgressProps } from "./root-progress.interface";
import { ImageTitle, MainWrapper, TaglineTitle } from "./root-progress.styles";

const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment RootProgress_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		image {
			url
		}
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				progressPercentage
				extension {
					... on PublishedRootExtensionImpl {
						extension {
							... on PublishedConstellationRootExtensionExtension {
								contractPartner {
									data {
										name
										linkToAGB
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
export const RootProgress = ({ learnOpportunityV2FragmentRef }: RootProgressProps) => {
	const rootData = useFragment<RootProgress_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);

	const progressValue = rootData?.structureDefinition?.progressPercentage ?? 0;
	const contractPartner = rootData?.structureDefinition?.extension?.extension?.contractPartner;

	const handleContractPartnerOnClick = () => {
		if (!contractPartner?.data.linkToAGB) return;
		window.open(contractPartner.data.linkToAGB, "_blank");
	};

	return (
		<MainWrapper>
			<ImageTitle>
				{rootData?.image?.url && (
					<div>
						<ImageCardAtom imageUrl={rootData.image.url} imageWidth={ImageWidth} />
					</div>
				)}
				<TaglineTitle>
					{contractPartner && (
						<Label
							label={contractPartner.data.name}
							onClick={handleContractPartnerOnClick}
						/>
					)}
					<TkaH3Span tkaColor={shade100}>
						{rootData?.structureDefinition?.title}
					</TkaH3Span>
				</TaglineTitle>
			</ImageTitle>
			<ProgressBar
				value={progressValue}
				colorVersion={"profile"}
				labelRight={`${progressValue} ${ProgressUnit}`}
			/>
		</MainWrapper>
	);
};
