/**
 * @generated SignedSource<<c1d4d77f4b4f1a3bb18b8ea30fd4a4e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PotentialAnalysisGivenAnswerKind = "SelectedWithFreetext" | "Text" | "selected";
export type PotentialAnalysisQuestionDataKind = "MULTIPLE_CHOICE" | "MULTIPLE_CHOICE_FREETEXT" | "SCALE" | "SINGLE_CHOICE" | "SINGLE_CHOICE_FREETEXT" | "TEXT";
import { FragmentRefs } from "relay-runtime";
export type checkboxQuestion_PotentialAnalysisQuestionFragment$data = {
  readonly answerOptions?: ReadonlyArray<{
    readonly answerOptionId: number;
    readonly isFreeText: boolean;
    readonly text: string;
  }>;
  readonly givenAnswer?: {
    readonly answerOptionId: number;
    readonly freeText: string | null;
    readonly kind: PotentialAnalysisGivenAnswerKind;
  } | null;
  readonly givenAnswers?: ReadonlyArray<{
    readonly answerOptionId: number;
    readonly freeText: string | null;
    readonly kind: PotentialAnalysisGivenAnswerKind;
  }>;
  readonly id: string;
  readonly kind: PotentialAnalysisQuestionDataKind;
  readonly questionText?: string;
  readonly " $fragmentType": "checkboxQuestion_PotentialAnalysisQuestionFragment";
};
export type checkboxQuestion_PotentialAnalysisQuestionFragment$key = {
  readonly " $data"?: checkboxQuestion_PotentialAnalysisQuestionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkboxQuestion_PotentialAnalysisQuestionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionText",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerOptionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PotentialAnalysisFreetextAnswerOption",
  "kind": "LinkedField",
  "name": "answerOptions",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFreeText",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "freeText",
    "storageKey": null
  },
  (v0/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PotentialAnalysisSelectAnswerOption",
  "kind": "LinkedField",
  "name": "answerOptions",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "checkboxQuestion_PotentialAnalysisQuestionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisSelectedWithFreetextGivenAnswer",
          "kind": "LinkedField",
          "name": "givenAnswers",
          "plural": true,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PotentialAnalysisMultipleChoiceFreetextQuestion",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisSelectedGivenAnswer",
          "kind": "LinkedField",
          "name": "givenAnswers",
          "plural": true,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PotentialAnalysisMultipleChoiceQuestion",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisSelectedWithFreetextGivenAnswer",
          "kind": "LinkedField",
          "name": "givenAnswer",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PotentialAnalysisSingleChoiceFreetextQuestion",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PotentialAnalysisSelectedGivenAnswer",
          "kind": "LinkedField",
          "name": "givenAnswer",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PotentialAnalysisSingleChoiceQuestion",
      "abstractKey": null
    }
  ],
  "type": "PotentialAnalysisQuestion",
  "abstractKey": "__isPotentialAnalysisQuestion"
};
})();

(node as any).hash = "e22ef55359bbe0f8c959e0b7b4b0e2f3";

export default node;
