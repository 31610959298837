import { PathParams } from "@thekeytechnology/epic-ui";
import React, { Suspense, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { AvatarWithProfileMenu } from "@components/avatar-with-profile-menu";
import { Divider } from "@components/divider";
import { Icon } from "@components/icon";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";

import { avgsPotentialAnalysis_Query } from "@relay/avgsPotentialAnalysis_Query.graphql";
import { AvgsPotentialAnalysisPath } from "@router/paths";
import { IntroductorySection } from "@screens/avgs-potential-analysis/parts/introductory-section";
import {
	AvgsPotentialAnalysisContext,
	AvgsPotentialAnalysisContextProvider,
} from "./avgs-potential-analysis.context";
import { QUERY } from "./avgs-potential-analysis.graphql";
import { AvgsPotentialAnalysisSkeleton } from "./avgs-potential-analysis.skeleton";
import {
	menuIconWrapper,
	menuWrapper,
	navigationWrapperClass,
	wrapperClass,
} from "./avgs-potential-analysis.styles";
import { AvgsPotentialAnalysisFinishedModal } from "./parts/avgs-potential-analysis-finished-modal";
import { AvgsPotentialAnalysisNavigation } from "./parts/avgs-potential-analysis-navigation";
import { ModuleSection } from "./parts/module-section";

const AvgsPotentialAnalysisComponent = () => {
	const { potentialAnalysisId } = useParams<PathParams<typeof AvgsPotentialAnalysisPath>>();

	const {
		AvgsPotentialAnalysis: { LearnPotentialAnalysis },
		Viewer: { Auth },
	} = useLazyLoadQuery<avgsPotentialAnalysis_Query>(
		QUERY,
		{ id: potentialAnalysisId! },
		{ fetchPolicy: "store-and-network" },
	);

	const { currentModuleIndex } = useContext(AvgsPotentialAnalysisContext);

	return (
		<ScreenWithNavbarLayout
			singlePageMode
			bottomContent={
				<div className={navigationWrapperClass}>
					<AvgsPotentialAnalysisNavigation
						learnPotentialAnalysisFragmentRef={LearnPotentialAnalysis}
					/>
				</div>
			}
		>
			<div className={wrapperClass}>
				<IntroductorySection />
				<Divider />
				<ModuleSection
					potentialAnalysisModuleDataFragmentRef={
						LearnPotentialAnalysis?.modules[currentModuleIndex]
					}
				/>
			</div>
			<div className={menuWrapper}>
				<AvatarWithProfileMenu authViewerSchemaFragmentRef={Auth}>
					<div className={menuIconWrapper}>
						<Icon icon="burgerMenu" sizeInRem={1} />
					</div>
				</AvatarWithProfileMenu>
			</div>
			<AvgsPotentialAnalysisFinishedModal isVisible={LearnPotentialAnalysis?.isFinished} />
		</ScreenWithNavbarLayout>
	);
};

export const AvgsPotentialAnalysisScreen = () => {
	return (
		<AvgsPotentialAnalysisContextProvider>
			<Suspense fallback={<AvgsPotentialAnalysisSkeleton />}>
				<AvgsPotentialAnalysisComponent />
			</Suspense>
		</AvgsPotentialAnalysisContextProvider>
	);
};
