import { shade100, shade20 } from "@themes/colors";
import { Wrapper } from "./control-button.styles";
import { ControlButtonProps } from "./control-button.types";
import { Icon } from "../icon";

export const ControlButton = ({ iconName, disabled = false, onClick }: ControlButtonProps) => {
	const iconColor = disabled ? shade20 : shade100;
	const onClickHandler = !disabled ? onClick : undefined;
	return (
		<Wrapper disabled={disabled} onClick={onClickHandler}>
			<Icon icon={iconName} tkaColor={iconColor} sizeInRem={0.7} />
		</Wrapper>
	);
};
