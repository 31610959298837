/**
 * @generated SignedSource<<38b941c61499c5fa110237a34f2ff26c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetAnswerInMultipleChoiceFreeTextQuestionInput = {
  answerOptions: ReadonlyArray<PotentialAnalysisFreeTextAnswerOptionInput>;
  clientMutationId?: string | null;
  givenAnswer: ReadonlyArray<PotentialAnalysisSelectedWithFreetextGivenAnswerInput>;
  learnPotentialAnalysisId: string;
  questionId: string;
  questionText: string;
};
export type PotentialAnalysisFreeTextAnswerOptionInput = {
  answerOptionId: number;
  isFreeText: boolean;
  text: string;
};
export type PotentialAnalysisSelectedWithFreetextGivenAnswerInput = {
  answerOptionId: number;
  text?: string | null;
};
export type checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation$variables = {
  input: SetAnswerInMultipleChoiceFreeTextQuestionInput;
};
export type checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation$data = {
  readonly AvgsPotentialAnalysis: {
    readonly setAnswerInMultipleChoiceFreeTextQuestion: {
      readonly question: {
        readonly givenAnswers: ReadonlyArray<{
          readonly answerOptionId: number;
          readonly freeText: string | null;
        }>;
        readonly id: string;
      } | null;
    } | null;
  };
};
export type checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation = {
  response: checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation$data;
  variables: checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AvgsPotentialAnalysisMutations",
    "kind": "LinkedField",
    "name": "AvgsPotentialAnalysis",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SetAnswerInMultipleChoiceFreeTextQuestionPayload",
        "kind": "LinkedField",
        "name": "setAnswerInMultipleChoiceFreeTextQuestion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PotentialAnalysisMultipleChoiceFreetextQuestion",
            "kind": "LinkedField",
            "name": "question",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PotentialAnalysisSelectedWithFreetextGivenAnswer",
                "kind": "LinkedField",
                "name": "givenAnswers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answerOptionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "freeText",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "025a3af89b07debd36b12ff8bceddeef",
    "id": null,
    "metadata": {},
    "name": "checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation checkboxQuestion_SetAnswerInMultipleChoiceFreeTextQuestionMutation(\n  $input: SetAnswerInMultipleChoiceFreeTextQuestionInput!\n) {\n  AvgsPotentialAnalysis {\n    setAnswerInMultipleChoiceFreeTextQuestion(input: $input) {\n      question {\n        id\n        givenAnswers {\n          answerOptionId\n          freeText\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "208761ac61a5bdf5cab3b7af9b574947";

export default node;
