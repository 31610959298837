import { MarkMistakesElement_ContentSubmissionFragment$key } from "@relay/MarkMistakesElement_ContentSubmissionFragment.graphql";

export interface MarkMistakesElementProps {
	contentSubmissionFragmentRef: MarkMistakesElement_ContentSubmissionFragment$key;
}

export enum MarkMistakeElementStatus {
	Correct,
	ActuallyCorrect,
	Wrong,
	Default,
}
