import tw from "twin.macro";

export const Wrapper = tw.div`
	md:min-w-[25rem]
	flex
	flex-col
	gap-16
`;

export const ContentWrapper = tw.div`
  flex
  flex-col
  gap-12
`;

export const IconWrapper = tw.div`
  flex
  flex-row
  items-center
  gap-8
`;

export const IconCircle = tw.div`
  w-20 
  h-20 
  flex 
  rounded-full 
  items-center 
  justify-center
  bg-error-100
  border-0
`;
