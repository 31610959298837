import tw from "twin.macro";

export const Wrapper = tw.div`
	flex
	flex-col
	gap-24
	min-w-[min(45rem, 85vw)]
`;

export const TitleWrapper = tw.div`
	flex
	flex-col
	gap-4
`;

export const InputsWrapper = tw.div`
	grid
	grid-cols-12
	md:gap-x-16
	lg:gap-x-32
	gap-y-24
`;

export const RowSpan6Wrapper = tw.div`
	col-span-12
	lg:col-span-6
	grid
	grid-cols-12
	lg:grid-cols-6
	gap-y-24
	md:gap-16
`;

export const RowSpan6 = tw.div`
	col-span-12
	md:col-span-6
`;

export const RowSpan10 = tw.div`
	col-span-12
	md:col-span-6
	lg:col-span-10
`;

export const RowSpan12 = tw.div`
	col-span-12
`;

export const RowSpan2 = tw.div`
	col-span-12
	md:col-span-6
	lg:col-span-2
`;
