import { MouseEventHandler, useContext, useRef } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Card } from "@components/card";
import { Checkbox } from "@components/checkbox";
import { Icon } from "@components/icon";
import { Path } from "@router/paths";
import { useGroupName } from "@screens/account/parts/hooks/use-group-name";
import { shade40 } from "@themes/colors";

import { QUERY_FRAGMENT, USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT } from "./group-card.graphql";
import { GroupName, Wrapper } from "./group-card.styles";
import { GroupCardProps } from "./group-card.types";
import { GroupsTabContext } from "../../groups-tab.context";
import { GroupContextMenu } from "../group-context-menu";
import { GroupContextMenuRef } from "../group-context-menu/group-context-menu.types";

export const GroupCard: React.FC<GroupCardProps> = ({
	userInAccountGroupWrapperFragmentRef,
	queryFragmentRef,
}) => {
	const groupWrapper = useFragment(
		USER_IN_ACCOUT_GROUP_WRAPPER_FRAGMENT,
		userInAccountGroupWrapperFragmentRef ?? null,
	);

	const queryFragment = useFragment(QUERY_FRAGMENT, queryFragmentRef ?? null);

	const { selectedGroups, toogleGroup } = useContext(GroupsTabContext);
	const navigate = useNavigate();

	const overlayRef = useRef<GroupContextMenuRef>(null);

	const groupId = groupWrapper?.group.id;

	const handleOnShowContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		overlayRef.current?.toggle(event, event.currentTarget);
	};

	const handleOnCheckboxChanged = () => {
		groupWrapper?.group && toogleGroup(groupWrapper?.group);
	};

	const handleOnClick: MouseEventHandler<HTMLDivElement> = (event) => {
		if (event.defaultPrevented) return;
		groupId && navigate(Path.profileMenu.account.groups.withId(groupId).path);
	};

	const groupName = useGroupName(groupId ?? "", groupWrapper?.group.name);

	const memberCount = groupWrapper?.memberCount ?? 0;
	const isSelected = selectedGroups.some((group) => group.id === groupWrapper?.group.id);

	return (
		<>
			<Card bodyPaddingInRem={0} borderColor={shade40} onClick={handleOnClick}>
				<Wrapper>
					<Checkbox checked={isSelected} onChange={handleOnCheckboxChanged} />
					<GroupName>{`${groupName} (${memberCount})`}</GroupName>
					<Icon icon="contextMenu" sizeInRem={1} onClick={handleOnShowContextMenu} />
				</Wrapper>
			</Card>
			<GroupContextMenu
				ref={overlayRef}
				userInAccountGroupWrapperFragmentRef={groupWrapper}
				queryFragmentRef={queryFragment}
			/>
		</>
	);
};
