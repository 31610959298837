import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
  grid 
  gap-8 
  grid-flow-row 
  
  grid-cols-1 
  md:grid-cols-2 
  lg:grid-cols-4 
`;

export const TitleWrapper = tw.div`
  order-1
  md:order-2
  p-24
`;

export const Title = tw.h2`
  text-[40px] 
  leading-[114%] 
  font-bold
`;

export const ItemWrapper = styled.div<{ order?: number }>`
	order: ${({ order }) => order};
	${tw`
    flex 
    gap-16 
    bg-onBrandMain 
    rounded-12 
    px-32
    py-24 
  `}
`;

export const IconWrapper = tw.div`
  flex
  w-40 
  h-40
`;

export const TextWrapper = tw.div`
  flex 
  flex-col 
  gap-12
`;

export const ItemTitle = tw.h3`
  text-[48px] 
  leading-[90%] 
  font-bold
`;
