import { createGlobalStyle } from "styled-components";

export const HubspotGlobalStyle = createGlobalStyle<{ bottomMarginRem?: number }>`
  #hubspot-messages-iframe-container {
    margin-bottom: ${({ bottomMarginRem }) => bottomMarginRem ?? 4.7}rem;
    max-height:  calc(100% - ${({ bottomMarginRem }) => bottomMarginRem ?? 4.7}rem);
  }
`;

export const HubspotHiddenGlobalStyle = createGlobalStyle`
  #hubspot-messages-iframe-container {
   visibility: hidden;
  }
`;
