/**
 * @generated SignedSource<<586e4e5aafd7ed1056215fca5e953ef2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type groupCard_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"groupContextMenu_QueryFragment">;
  readonly " $fragmentType": "groupCard_QueryFragment";
};
export type groupCard_QueryFragment$key = {
  readonly " $data"?: groupCard_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"groupCard_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./groupCardRefetchQuery.graphql')
    }
  },
  "name": "groupCard_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "groupContextMenu_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "51d10bc48851c89c2fadd97b1989dd11";

export default node;
