/**
 * @generated SignedSource<<c80a77ed496ab8597c5113266b353e2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyTypeInput = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type UpdateBusinessBillingDetailsAndInitiateCheckoutInput = {
  city: string;
  clientMutationId?: string | null;
  company: string;
  companyDetails?: string | null;
  companyType: CompanyTypeInput;
  houseNumber: string;
  invoiceEmail?: string | null;
  orderId: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  taxIdentificationNumber: string;
};
export type shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation$variables = {
  input: UpdateBusinessBillingDetailsAndInitiateCheckoutInput;
};
export type shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation$data = {
  readonly Billing: {
    readonly updateBusinessBillingDetailsAndInitiateCheckout: {
      readonly clientSecret: string;
      readonly order: {
        readonly " $fragmentSpreads": FragmentRefs<"shoppingCartInvoice_OrderFragment">;
      };
    } | null;
  };
};
export type shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation = {
  response: shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation$data;
  variables: shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientSecret",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UpdateBusinessBillingDetailsAndInitiateCheckoutPayload",
            "kind": "LinkedField",
            "name": "updateBusinessBillingDetailsAndInitiateCheckout",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "shoppingCartInvoice_OrderFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UpdateBusinessBillingDetailsAndInitiateCheckoutPayload",
            "kind": "LinkedField",
            "name": "updateBusinessBillingDetailsAndInitiateCheckout",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "order",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "billingDetails",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customerType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "street",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "houseNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phoneNumber",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "salutation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dateOfBirth",
                            "storageKey": null
                          }
                        ],
                        "type": "PrivateBillingDetails",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "company",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyDetails",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "companyType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "invoiceEmail",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "taxIdentificationNumber",
                            "storageKey": null
                          }
                        ],
                        "type": "BusinessBillingDetails",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Cart",
                    "kind": "LinkedField",
                    "name": "cart",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartTotals",
                        "kind": "LinkedField",
                        "name": "totals",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Price",
                            "kind": "LinkedField",
                            "name": "includingAllDiscounts",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grossPrice",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "selectedPaymentMethod",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "selectedPaymentMethodType",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MonthlyOption",
                            "kind": "LinkedField",
                            "name": "chosenOption",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rateCount",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MonthlySelectedPaymentMethod",
                        "abstractKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paymentMethodId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Purchaser",
                    "kind": "LinkedField",
                    "name": "purchaser",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "946e3abe0a1a88bc32c4cf595bc6574f",
    "id": null,
    "metadata": {},
    "name": "shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation",
    "operationKind": "mutation",
    "text": "mutation shoppingCartInvoice_UpdateBusinessBillingDetailsAndInitiateCheckoutMutation(\n  $input: UpdateBusinessBillingDetailsAndInitiateCheckoutInput!\n) {\n  Billing {\n    updateBusinessBillingDetailsAndInitiateCheckout(input: $input) {\n      clientSecret\n      order {\n        ...shoppingCartInvoice_OrderFragment\n        id\n      }\n    }\n  }\n}\n\nfragment basketTimeline_OrderFragment on Order {\n  id\n  cart {\n    totals {\n      includingAllDiscounts {\n        grossPrice\n      }\n    }\n  }\n  billingDetails {\n    __typename\n    customerType\n  }\n  selectedPaymentMethod {\n    __typename\n    selectedPaymentMethodType\n    ... on MonthlySelectedPaymentMethod {\n      chosenOption {\n        rateCount\n      }\n    }\n  }\n}\n\nfragment paypalButton_OrderFragment on Order {\n  id\n  cart {\n    totals {\n      includingAllDiscounts {\n        grossPrice\n      }\n    }\n  }\n  purchaser {\n    userId\n  }\n}\n\nfragment shoppingCartInvoice_OrderFragment on Order {\n  billingDetails {\n    __typename\n    customerType\n    street\n    houseNumber\n    city\n    postalCode\n    country\n    phoneNumber\n    ... on PrivateBillingDetails {\n      title\n      salutation\n      firstName\n      lastName\n      dateOfBirth\n    }\n    ... on BusinessBillingDetails {\n      company\n      companyDetails\n      companyType\n      invoiceEmail\n      taxIdentificationNumber\n    }\n  }\n  ...shoppingCartTemplate_OrderFragment\n}\n\nfragment shoppingCartTemplate_OrderFragment on Order {\n  ...basketTimeline_OrderFragment\n  selectedPaymentMethod {\n    __typename\n    paymentMethodId\n  }\n  ...paypalButton_OrderFragment\n}\n"
  }
};
})();

(node as any).hash = "4347d6fc312ab3b8d379d59286c8c0ad";

export default node;
