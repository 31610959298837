import { createContext } from "react";

export type IPlacementContext = {
	placementLinkId: string;
	setPlacementLinkId: (linkId: string) => void;
};

export const PlacementContext = createContext<IPlacementContext>({
	placementLinkId: "",
	setPlacementLinkId: () => {},
});
