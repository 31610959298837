import { DialogTemplate } from "@components/dialog-template";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { colorShade100Class } from "@themes/color-classes";
import { success0, success100 } from "@themes/colors";

import { P2Span } from "@themes/font-tags";
import { highlightTextClass, wrapperClass } from "./avgs-potential-analysis-finished-modal.styles";
import { AvgsPotentialAnalysisFinishedModalProps } from "./avgs-potential-analysis-finished-modal.types";

export const AvgsPotentialAnalysisFinishedModal = ({
	isVisible,
}: AvgsPotentialAnalysisFinishedModalProps) => {
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<DialogTemplate maxWidthRem={20}>
				<div className={wrapperClass}>
					<IconHeadlineButtonTemplate
						iconName="checkBadge"
						iconColor={success100}
						iconBackgroundColor={success0}
						title="Herzlichen Dank für Ihre Teilnahme!"
					/>
					<P2Span className={colorShade100Class}>
						Sie haben die Befragung erfolgreich{" "}
						<P2Span className={highlightTextClass}>abgeschlossen</P2Span>. Die
						Ergebnisse erhalten Sie von Ihrem Job-Coach im Rahmen Ihres nächsten
						Termins. <br />
						<br /> Falls bisher noch kein weiterer Termin mit Ihrem Job-Coach angesetzt
						ist, melden Sie sich gerne direkt bei Ihrem Ansprechpartner. <br />
						<br /> Wir wünschen Ihnen viel Erfolg!
					</P2Span>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
