import { SelectButton as PrSelectButton } from "primereact/selectbutton";
import styled from "styled-components";
import tw from "twin.macro";
import { TkaColor } from "@themes/colors";
import { TkaP2Typography } from "@themes/font-tags";

export const Wrapper = tw.div`
	flex
	flex-col
	items-start
`;

export const Background = tw.div`
	p-4
	bg-shade-5
	rounded-8
	shrink
`;

export const StyledButton = styled(PrSelectButton)<{
	selectedBackgorundColor: TkaColor;
	selectedTextColor: TkaColor;
	disableUnselected: boolean;
}>`
	& .p-button:focus {
		${tw`shadow-none`}
	}
	&.p-selectbutton .p-button {
		${tw`
			border-0 
			bg-transparent
			px-12
			py-4
		`}
	}
	&.p-selectbutton .p-button .p-button-label {
		${TkaP2Typography}
		${({ disableUnselected }) => (disableUnselected ? tw`text-shade-40` : tw`text-shade-100`)}
	}
	&.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
		${({ disableUnselected }) => (disableUnselected ? tw`bg-shade-5` : tw`bg-shade-10`)}
	}
	&.p-selectbutton .p-button.p-highlight:hover {
		background: ${({ selectedBackgorundColor }) => selectedBackgorundColor};
	}
	&.p-selectbutton .p-button.p-highlight {
		background: ${({ selectedBackgorundColor }) => selectedBackgorundColor};
	}
	&.p-selectbutton .p-button.p-highlight .p-button-label {
		color: ${({ selectedTextColor }) => selectedTextColor};
	}
`;
