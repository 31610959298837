/**
 * @generated SignedSource<<f22e042308a26d2fd0d7e4d05caa37c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddWordNoteInput = {
  clientMutationId?: string | null;
  note: string;
  wordId: string;
};
export type editWordNoteModal_AddWordMutation$variables = {
  input: AddWordNoteInput;
};
export type editWordNoteModal_AddWordMutation$data = {
  readonly Vocab: {
    readonly addWordNote: {
      readonly inventory: {
        readonly " $fragmentSpreads": FragmentRefs<"wordPackageList_WordInventoryFragment">;
      };
    } | null;
  };
};
export type editWordNoteModal_AddWordMutation = {
  response: editWordNoteModal_AddWordMutation$data;
  variables: editWordNoteModal_AddWordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wordId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editWordNoteModal_AddWordMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VocabMutations",
        "kind": "LinkedField",
        "name": "Vocab",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AddWordNotePayload",
            "kind": "LinkedField",
            "name": "addWordNote",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WordInventory",
                "kind": "LinkedField",
                "name": "inventory",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "wordPackageList_WordInventoryFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editWordNoteModal_AddWordMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VocabMutations",
        "kind": "LinkedField",
        "name": "Vocab",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AddWordNotePayload",
            "kind": "LinkedField",
            "name": "addWordNote",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WordInventory",
                "kind": "LinkedField",
                "name": "inventory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PackageUnlock",
                    "kind": "LinkedField",
                    "name": "packages",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordPackage",
                        "kind": "LinkedField",
                        "name": "wordPackage",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "language",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Word",
                            "kind": "LinkedField",
                            "name": "associatedWords",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "word",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "translation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "explanation",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordProficiency",
                    "kind": "LinkedField",
                    "name": "proficiencies",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "proficiencyLevel",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordNote",
                    "kind": "LinkedField",
                    "name": "notes",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "note",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b084d3a78b17bd20b815d3075fb2140",
    "id": null,
    "metadata": {},
    "name": "editWordNoteModal_AddWordMutation",
    "operationKind": "mutation",
    "text": "mutation editWordNoteModal_AddWordMutation(\n  $input: AddWordNoteInput!\n) {\n  Vocab {\n    addWordNote(input: $input) {\n      inventory {\n        ...wordPackageList_WordInventoryFragment\n        id\n      }\n    }\n  }\n}\n\nfragment editWordNoteModal_WordNoteFragment on WordNote {\n  wordId\n  note\n}\n\nfragment wordCard_WordFragment on Word {\n  id\n  word\n  translation\n  explanation\n}\n\nfragment wordCard_WordNoteFragment on WordNote {\n  wordId\n  note\n  ...editWordNoteModal_WordNoteFragment\n}\n\nfragment wordCard_WordProficiencyFragment on WordProficiency {\n  wordId\n  proficiencyLevel\n}\n\nfragment wordPackageCard_WordInventoryFragment on WordInventory {\n  packages {\n    wordPackage {\n      id\n      name\n      language\n      associatedWords {\n        id\n        ...wordCard_WordFragment\n      }\n    }\n  }\n  proficiencies {\n    proficiencyLevel\n    wordId\n    ...wordCard_WordProficiencyFragment\n  }\n  notes {\n    wordId\n    ...wordCard_WordNoteFragment\n  }\n}\n\nfragment wordPackageList_WordInventoryFragment on WordInventory {\n  packages {\n    wordPackage {\n      id\n    }\n  }\n  ...wordPackageCard_WordInventoryFragment\n}\n"
  }
};
})();

(node as any).hash = "cfbe4b55952940e35551d9a3942275c0";

export default node;
