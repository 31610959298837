/**
 * @generated SignedSource<<f699b9de66119aed013352872d839b2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordCard_WordNoteFragment$data = {
  readonly note: string;
  readonly wordId: string;
  readonly " $fragmentSpreads": FragmentRefs<"editWordNoteModal_WordNoteFragment">;
  readonly " $fragmentType": "wordCard_WordNoteFragment";
};
export type wordCard_WordNoteFragment$key = {
  readonly " $data"?: wordCard_WordNoteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"wordCard_WordNoteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "wordCard_WordNoteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wordId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editWordNoteModal_WordNoteFragment"
    }
  ],
  "type": "WordNote",
  "abstractKey": null
};

(node as any).hash = "fd6357daa5cf0c9818aa099b822f1a91";

export default node;
