import tw from "twin.macro";
import { ListWrapper } from "./focus-content-menu.styles";
import { Skeleton } from "../skeleton";

export const FocusContentMenuSkeleton = () => {
	return (
		<ListWrapper>
			<ItemWrapper>
				<Skeleton width="100%" height="2.87rem" borderRadius="0.625rem" />
			</ItemWrapper>
			<ItemWrapper>
				<Skeleton width="100%" height="2.87rem" borderRadius="0.625rem" />
			</ItemWrapper>
			<ItemWrapper>
				<Skeleton width="100%" height="2.87rem" borderRadius="0.625rem" />
			</ItemWrapper>
			<ItemWrapper>
				<Skeleton width="100%" height="2.87rem" borderRadius="0.625rem" />
			</ItemWrapper>
		</ListWrapper>
	);
};

const ItemWrapper = tw.div`
	mx-24
`;
