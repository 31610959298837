import { useMemo } from "react";
import { shade10 } from "@themes/colors";
import { Bar, LegendWrapper, Wrapper } from "./statistics-bar.styles";
import { Skeleton } from "../skeleton";

export const StatisticsBarSkeleton = () => {
	const random = useMemo(() => Math.random() * 100, []);
	return (
		<Wrapper>
			<Skeleton width="7rem" />
			<Bar widthPercent={random} offset={0} backgroundColor={shade10}>
				<Skeleton width="2rem" />
			</Bar>
			<LegendWrapper>
				{Array.from(Array(10).keys()).map((step) => (
					<Skeleton key={step} width="1rem" />
				))}
			</LegendWrapper>
		</Wrapper>
	);
};
