import { BottomNavigationTemplateSkeleton } from "@components/bottom-navigation-template/bottom-navigation-template.skeleton";
import { Skeleton } from "@components/skeleton";
import { StatisticsBarSkeleton } from "@components/statistics-bar/statistics-bar.skeleton";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithNavbarSidemenuLayout } from "@layouts/screen-with-navbar-sidemenu-layout";
import { H1Span, TkaH2Span } from "@themes/font-tags";
import { StatisticsWrapper, TextWrapper, Wrapper } from "./orientation.styles";

//TODO: add-translations
export const OrientationScreenSkeleton = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScreenWithNavbarSidemenuLayout
			sideMenu={
				<>
					<H1Span>Potenzialanalyse</H1Span>
					<TkaH2Span>Vergleich Selbstbild nach Dimensionen</TkaH2Span>

					<TextWrapper>
						<Skeleton width="100%" />
						<Skeleton width="90%" />
						<Skeleton width="95%" />
						<Skeleton width="80%" />
						<Skeleton width="65%" />
						<Skeleton width="30%" />
					</TextWrapper>
				</>
			}
			bottomContent={<BottomNavigationTemplateSkeleton />}
			canGoBack={!isMediumUp}
		>
			<Wrapper>
				<StatisticsWrapper>
					<StatisticsBarSkeleton />
					<StatisticsBarSkeleton />
					<StatisticsBarSkeleton />
					<StatisticsBarSkeleton />
					<StatisticsBarSkeleton />
					<StatisticsBarSkeleton />
					<StatisticsBarSkeleton />
				</StatisticsWrapper>
			</Wrapper>
		</ScreenWithNavbarSidemenuLayout>
	);
};
