import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query shoppingCartProducts_Query($orderId: ID!, $skip: Boolean!, $isNotLoggedIn: Boolean!) {
		node(id: $orderId) @skip(if: $skip) {
			... on Order {
				...shoppingCartProducts_OrderFragment
			}
		}
		AccountBaseData @skip(if: $isNotLoggedIn) {
			AccountBaseData {
				...shoppingCartProducts_BaseDataFragment
			}
		}
		Viewer {
			Auth {
				...shoppingCartTemplate_AuthViewerSchemaFragment
			}
		}
	}
`;

export const BASE_DATA_FRAGMENT = graphql`
	fragment shoppingCartProducts_BaseDataFragment on baseData {
		... on PrivateBaseData {
			countryCode
		}
		... on BusinessBaseData {
			countryCode
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment shoppingCartProducts_OrderFragment on Order {
		id
		cart {
			taxTotals {
				discountTotal
				grossPrice
				hasTaxFreeProducts
				netPrice
				taxTotal
			}
			selection {
				selectedProducts {
					product {
						id
					}
					amount
				}
			}
			items {
				itemType
				... on CartLimitedDiscount {
					title
					value {
						kind
						... on DiscountActionPercentageCalcType {
							percentage
						}
						... on DiscountActionEuroCalcType {
							euro
						}
					}
				}
				... on CartDiscount {
					validUntil
					code
					title
					value {
						kind
						... on DiscountActionPercentageCalcType {
							percentage
						}
						... on DiscountActionEuroCalcType {
							euro
						}
					}
				}
				... on CartGlobalDiscount {
					title
					value {
						kind
						... on DiscountActionPercentageCalcType {
							percentage
						}
						... on DiscountActionEuroCalcType {
							euro
						}
					}
				}
				... on CartProduct {
					amount
					product {
						id
						title
						netPrice
						isTaxFree
						extensions {
							kind
							... on AcademiesProductExtension {
								deGrossPrice
								atGrossPrice
								chGrossPrice
							}
						}
						data {
							... on LicenseProductData {
								licenseDefinition {
									data {
										... on TreeLicenseDefinitionData {
											rootInfo {
												image {
													url
												}
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
										... on CoachingLicenseDefinitionData {
											coachingInfo {
												image {
													url
												}
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			totals {
				appliedDiscountPercentage
				monthlyOptions {
					monthlyRate {
						grossPrice
					}
				}
			}
			otherAvailableProducts {
				edges {
					node {
						id
						title
						data {
							... on LicenseProductData {
								licenseDefinition {
									data {
										... on TreeLicenseDefinitionData {
											rootInfo {
												image {
													url
												}
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
										... on CoachingLicenseDefinitionData {
											coachingInfo {
												image {
													url
												}
												contractPartner {
													data {
														name
														linkToAGB
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		...shoppingCartTemplate_OrderFragment
	}
`;

export const UPDATE_CART_MUTATION = graphql`
	mutation shoppingCartProducts_CreateOrderMutation($input: UpdateCartInput!) {
		Billing {
			updateCart(input: $input) {
				order {
					cart {
						selection {
							selectedProducts {
								product {
									id
								}
								amount
							}
						}
						items {
							itemType
							... on CartProduct {
								amount
								product {
									id
									title
								}
							}
							... on CartDiscount {
								validUntil
								code
							}
						}
						taxTotals {
							netPrice
						}
					}
					...shoppingCartProducts_OrderFragment
				}
			}
		}
	}
`;
