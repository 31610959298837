import tw from "twin.macro";

export const Wrapper = tw.div`
	flex-1
	flex
	flex-col
	md:max-w-[min(40rem, 80vw)]
	bg-shade-5
`;

export const ContentWrapper = tw.div`
	relative
	md:static
	flex-1
	flex
	flex-col
	justify-between
	gap-24
	bg-shade-0
	p-24
	md:px-40
	md:py-32
	rounded-t-24
	md:rounded-none
`;

export const TextWrapper = tw.div`
	flex
	flex-col
	mt-24
	md:mt-0
	gap-24
	text-center
	md:text-start
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-col
	md:flex-row
	gap-16
`;

export const CookieIconWrapper = tw.div`
	absolute
	top-[-0.5rem]
	left-[calc(50%-1.5rem)]
	md:top-24
	md:left-[-1.5rem]
`;

export const CloseIconWrapper = tw.div`
	absolute
	hidden
	md:block
	top-16
	right-16
`;
