import { Calendar } from "primereact/calendar";
import styled, { createGlobalStyle } from "styled-components";
import tw from "twin.macro";
import { TkaP3Label, TkaP2Typography, TkaL1Span, TkaH3Typography } from "@themes/font-tags";

export const StyledLabel = tw(TkaP3Label)`
	block
	mb-4
`;

export const GrowDiv = styled.div`
	${tw`
		flex 
		w-full
	`}
	&.p-input-icon-left > i {
		${tw`mt-[-0.75rem]`}
	}
	&.p-input-icon-left > .p-inputtext {
		${tw`pl-40`}
	}
`;

export const StyledCalendar = styled(Calendar)<{
	error: boolean;
}>`
	${tw`w-full`}

	&.p-calendar .p-inputtext {
		${TkaP2Typography}
		${tw`text-shade-100 rounded-4`}
		${(props) => (props.error ? tw`border-error-100` : tw`border-shade-40`)};

		&:disabled {
			${tw`bg-shade-5 text-shade-20 border-none`}
		}

		&:enabled:hover {
			${(props) => (props.error ? tw`border-error-100` : tw`border-shade-20`)};
			${tw`text-shade-100`}
		}

		&:enabled:focus {
			${(props) => (props.error ? tw`border-error-100` : tw`border-info-100`)};
			${tw`shadow-none`}
		}

		::-webkit-input-placeholder {
			${tw`text-shade-20`}
		}
		:-moz-placeholder {
			${tw`text-shade-20`}
		}
		::-moz-placeholder {
			${tw`text-shade-20`}
		}
		:-ms-input-placeholder {
			${tw`text-shade-20`}
		}
	}
`;

export const GlobalCalendarStyle = createGlobalStyle`
	.p-datepicker {
		${TkaP2Typography}
		${tw`text-shade-80 rounded-12`}
	}
	.p-datepicker .p-datepicker-header .p-datepicker-title {
		${TkaH3Typography}
		${tw`text-shade-100`}
	}
	.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
		${TkaH3Typography}
		${tw`text-shade-100`}
	}
	.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
		${TkaH3Typography}
		${tw`text-shade-100`}
	}
	.p-datepicker table th > span {
		${TkaH3Typography}
		${tw`text-shade-100`}
	}
	.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
		${tw`bg-brand-strong-40`}
	}
	.p-datepicker table td {
		${tw`p-4`}
	}
	.p-datepicker table td > span {
		${TkaP2Typography}
		${tw`w-[2.2rem] h-[2.2rem]`}
	}
	.p-datepicker table td > span.p-highlight {
		${TkaH3Typography}
		${tw`bg-brand-strong-100 text-shade-100`}
	}
	.p-datepicker table td > span.p-disabled {
		${tw`text-shade-40 opacity-100`}
	}
	.p-datepicker .p-monthpicker .p-monthpicker-month {
		${TkaP2Typography}
		${tw`text-shade-100`}
	}
	.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
		${TkaH3Typography}
		${tw`bg-brand-strong-100 text-shade-100`}
	}
	.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
		${tw`bg-brand-strong-40`}
	}
	.p-datepicker .p-yearpicker .p-yearpicker-year {
		${TkaP2Typography}
		${tw`text-shade-100`}
	}
	.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
		${TkaH3Typography}
		${tw`bg-brand-strong-100 text-shade-100`}
	}
	.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
		${tw`bg-brand-strong-40`}
	}
`;

export const SmallTextBellow = styled(TkaL1Span)`
	${tw`block mt-4`}
`;
