import { FC } from "react";
import { MarginRight8 } from "@screens/profile/parts/personnel-sheet-tab/personnel-sheet-tab.styles";
import { brandMain100, error100 } from "@themes/colors";
import { TkaP2Span, TkaP3Label } from "@themes/font-tags";
import { StyledMultiSelect, Wrapper } from "./multi-select.styles";
import { MultiSelectBaseProps } from "./multi-select.types";
import { getLabelColor } from "./multi-select.utils";
import { SmallTextBelow } from "../input-text/input-text.styles";
import { Label } from "../label";

export const MultiSelect: FC<MultiSelectBaseProps> = ({
	inline,
	label,
	onChange,
	value,
	options,
	optionLabel,
	onCloseClick,
	placeholder,
	smallTextBelow,
	hasError,
	disabled,
	...props
}) => {
	const selectedItemTemplate = (e?: { id: string; name: string }) => {
		if (!e) return <TkaP2Span>{placeholder}</TkaP2Span>;
		const handleCloseClick = () => {
			onCloseClick?.(e.id);
		};
		return (
			<div style={{ width: "min-content" }}>
				<Label
					severity={"default"}
					label={e.name}
					closable={!!onCloseClick}
					size={"big"}
					onCloseClick={handleCloseClick}
				/>
				<MarginRight8 />
			</div>
		);
	};

	const labelColor = getLabelColor(hasError ?? false, disabled ?? false);

	return (
		<Wrapper>
			{label && (
				<>
					<TkaP3Label tkaColor={labelColor}>{label}</TkaP3Label>
				</>
			)}
			<StyledMultiSelect
				onChange={onChange}
				value={value}
				options={options}
				optionLabel={optionLabel}
				display={"chip"}
				filter={true}
				selectedItemTemplate={selectedItemTemplate}
				inline={inline}
				error={hasError}
				disabled={disabled}
				{...props}
			/>
			{smallTextBelow && (
				<SmallTextBelow tkaColor={hasError ? error100 : brandMain100}>
					{smallTextBelow}
				</SmallTextBelow>
			)}
		</Wrapper>
	);
};
