import { EmptyPlaceholder } from "@components/empty-placeholder";
import { LicensesGroupCardSkeleton } from "@components/licenses-group-card/licenses-group-card.skeleton";
import { Skeleton } from "@components/skeleton";
import { useWindowSize } from "@hooks/use-window-size";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout ";
import { Header, NoGrowWrapper, SearchAndFiltersWrapper } from "@screens/offers/offers.styles";
import { H1Span } from "@themes/font-tags";
import { Wrapper } from "./licenses.styles";
import { ListWrapper } from "./parts/licenses-tab/licenses-tab.styles";

//TODO: add-translations
export const LicensesScreenSkeleton = () => {
	const { isMediumUp } = useWindowSize();

	return (
		<ScreenWithProfileMenuLayout canGoBack={!isMediumUp}>
			<Wrapper>
				<Header>
					<H1Span>Meine Lizenzen</H1Span>
					<SearchAndFiltersWrapper>
						<Skeleton width="100px" height="30px" />
						<NoGrowWrapper>
							<Skeleton width="100%" height="30px" />
						</NoGrowWrapper>
						<Skeleton width="30px" height="30px" />
					</SearchAndFiltersWrapper>
				</Header>
				<ListWrapper>
					<EmptyPlaceholder
						title="Keine Lizenzen gefunden"
						subtitle="Schau dich gerne in unserem Angebotskatalog um, um die für dich passenden Angebote zu finden."
						iconName="loginKey"
						buttonLabel="Zu den Angeboten"
					>
						<LicensesGroupCardSkeleton />
						<LicensesGroupCardSkeleton />
						<LicensesGroupCardSkeleton />
						<LicensesGroupCardSkeleton />
						<LicensesGroupCardSkeleton />
						<LicensesGroupCardSkeleton />
					</EmptyPlaceholder>
				</ListWrapper>
			</Wrapper>
		</ScreenWithProfileMenuLayout>
	);
};
