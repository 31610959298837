import tw from "twin.macro";

export const FormWrapper = tw.div`
	flex
	flex-col
	gap-32
`;

export const InvitationTitleWrapper = tw.div`
	flex
	flex-col
	items-center
	text-center
	gap-12
`;

export const TextCenter = tw.div`
	flex
	flex-col
	items-center
	text-center
	gap-0
`;

export const TextRight = tw.div`
	flex
	flex-col
	items-end
	text-right
	gap-0
`;

export const InputsWrapper = tw.div`
	flex
	flex-col
	gap-12
`;
