import { useContext } from "react";
import { useFragment } from "react-relay";
import { moduleSection_AVGSPotentialAnalysisModuleFragment$key } from "@relay/moduleSection_AVGSPotentialAnalysisModuleFragment.graphql";
import { AvgsPotentialAnalysisContext } from "@screens/avgs-potential-analysis/avgs-potential-analysis.context";
import { DimensionSection } from "@screens/avgs-potential-analysis/parts/dimension-section/dimension-section.component";
import {
	TitleDescriptionClass,
	WrapperClass,
} from "@screens/avgs-potential-analysis/parts/module-section/module-section.styles";
import { ModuleSectionProps } from "@screens/avgs-potential-analysis/parts/module-section/module-section.types";
import { H1Span } from "@themes/font-tags";
import { POTENTIAL_ANALYSIS_MODULE_DATA_FRAGMENT } from "./module-section.graphql";

export const ModuleSection = ({ potentialAnalysisModuleDataFragmentRef }: ModuleSectionProps) => {
	const learnPotentialAnalysis =
		useFragment<moduleSection_AVGSPotentialAnalysisModuleFragment$key>(
			POTENTIAL_ANALYSIS_MODULE_DATA_FRAGMENT,
			potentialAnalysisModuleDataFragmentRef ?? null,
		);

	const { currentDimensionIndex } = useContext(AvgsPotentialAnalysisContext);

	const currentDimension = learnPotentialAnalysis?.dimensions[currentDimensionIndex];
	if (!currentDimension) return null;
	return (
		<div className={WrapperClass}>
			<div className={TitleDescriptionClass}>
				<H1Span>{learnPotentialAnalysis?.moduleText ?? ""}</H1Span>
				<DimensionSection
					key={learnPotentialAnalysis?.moduleId ?? "" + currentDimension?.dimensionId}
					potentialAnalysisDimensionDataFragmentRef={currentDimension}
				/>
			</div>
		</div>
	);
};
