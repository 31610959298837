/**
 * @generated SignedSource<<1bcb702637183c4b5882ea2c4f772d1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentKind = "Async" | "ELearning";
import { FragmentRefs } from "relay-runtime";
export type contentOverview_LearnOpportunityV2Fragment$data = {
  readonly description: string | null;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly indexPath: ReadonlyArray<number>;
  readonly instructorsV2: {
    readonly " $fragmentSpreads": FragmentRefs<"instructorsComponent_InstructorsConnectionFragment">;
  };
  readonly lengthInSecondsV2: number;
  readonly root: {
    readonly " $fragmentSpreads": FragmentRefs<"branchNodesTab_LearnOpportunityV2Fragment">;
  } | null;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly contentKind?: ContentKind;
    readonly extension?: {
      readonly receivableGamificationPoints?: number;
      readonly receivedGamificationPoints?: number;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"AttachmentsCollection_AttachmentsCollectionFragment" | "asyncContentOverview_LearnOpportunityV2Fragment" | "contentElementsCollection_LearnOpportunityV2Fragment" | "eLearningContentOverview_LearnOpportunityV2Fragment">;
  readonly " $fragmentType": "contentOverview_LearnOpportunityV2Fragment";
};
export type contentOverview_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: contentOverview_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentOverview_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contentKind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "extension",
    "plural": false,
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receivableGamificationPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receivedGamificationPoints",
            "storageKey": null
          }
        ],
        "type": "LearnContentExtensionImpl",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentOverview_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InstructorsConnection",
      "kind": "LinkedField",
      "name": "instructorsV2",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "instructorsComponent_InstructorsConnectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "indexPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "LearnAsyncContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "branchNodesTab_LearnOpportunityV2Fragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lengthInSecondsV2",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachmentsCollection_AttachmentsCollectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentElementsCollection_LearnOpportunityV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "eLearningContentOverview_LearnOpportunityV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "asyncContentOverview_LearnOpportunityV2Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "0af270283e5bafabb6d93bd78c8696d1";

export default node;
