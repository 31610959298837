/**
 * @generated SignedSource<<6fc7c0ddd7478948399b63a9ab4ea28f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type trends_Query$variables = {};
export type trends_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"trendOverview_trendFragment">;
};
export type trends_Query = {
  response: trends_Query$data;
  variables: trends_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "trends_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "trendOverview_trendFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "trends_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerJourneyQueries",
        "kind": "LinkedField",
        "name": "CustomerJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrendConnection",
            "kind": "LinkedField",
            "name": "trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TrendEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Trend",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrendData",
                        "kind": "LinkedField",
                        "name": "trendData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "visible",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "icon",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c856aae2ea6d63bcf70a1b3cf46a1a1",
    "id": null,
    "metadata": {},
    "name": "trends_Query",
    "operationKind": "query",
    "text": "query trends_Query {\n  ...trendOverview_trendFragment\n}\n\nfragment trendCard_trendFragment on Trend {\n  id\n  trendData {\n    visible\n    icon\n    name\n  }\n}\n\nfragment trendOverview_trendFragment on Query {\n  CustomerJourney {\n    trend {\n      edges {\n        node {\n          id\n          trendData {\n            name\n            visible\n          }\n          ...trendCard_trendFragment\n        }\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "0e49e3b7a8c7e74ed0e35f614162f4a3";

export default node;
