import tw from "twin.macro";
import { TkaH3Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	flex
	flex-row
	gap-12
	min-h-[3rem]
`;

export const ImageWrapper = tw.div`
	self-stretch
`;

export const Content = tw.div`
	flex
	flex-col
	gap-4
`;

export const Title = tw(TkaH3Span)`
	line-clamp-2
`;
