import { useFragment } from "react-relay";
import { error0, error100, shade60 } from "@themes/colors";
import { TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { formatAppointmentDateTimeRange } from "@utils/appointment-utils";
import { AVAILABILITY_FRAGMENT } from "./cancel-appointment-coach-modal.graphql";
import {
	HeaderWrapper,
	RowWrapper,
	UsersCountWrapper,
	Wrapper,
} from "./cancel-appointment-coach-modal.styles";
import { CancelAppointmentCoachModalProps } from "./cancel-appointment-coach-modal.types";
import { DialogTemplate } from "../dialog-template";
import { Icon } from "../icon";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";
import { LinkDisplay } from "../link-display";
import { ResponsiveBottomSheetModal } from "../responsive-bottom-sheet-modal";
import { UserAvatarsCollection } from "../user-avatars-collection/user-avatars-collection.component";

// TODO: add-translations
export const CancelAppointmentCoachModal = ({
	isVisible = false,
	onCancel,
	onNext,
	availabilityFragmentRef,
}: CancelAppointmentCoachModalProps) => {
	const availability = useFragment(AVAILABILITY_FRAGMENT, availabilityFragmentRef);

	const renderTime = () => {
		return formatAppointmentDateTimeRange(
			availability.data.startDateTime,
			availability.data.endDateTime,
			true,
			"long",
		);
	};

	const msTeamsLink = availability.booking.msTeamsLink;

	const participants = availability?.booking.participant
		? [availability?.booking.participant]
		: availability?.booking.participants ?? [];

	const users = participants.map((participant) => participant.user!).filter(Boolean);

	const participantsLabel = `${users.length} Teilnehmer`;

	const offerName = availability.booking.offer?.description?.name;
	const subTitle = `Willst du den Termin “${offerName}” wirklich absagen? Nach der Absage werden alle Teilnehmer benachrichtigt, du kannst dann einen neuen Termin vorschlagen.`;

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				secondaryButtonStretch={false}
				onSecondaryButtonClick={onCancel}
				primaryButtonLabel="Weiter"
				primaryButtonIconName="arrowRight"
				primaryButtonStretch={false}
				onPrimaryButtonClick={onNext}
			>
				<Wrapper>
					<HeaderWrapper>
						<IconHeadlineButtonTemplate
							iconName="calendarDisable"
							iconColor={error100}
							iconBackgroundColor={error0}
							title="Wirklich absagen?"
							subtitle={subTitle}
						/>
					</HeaderWrapper>
					<RowWrapper>
						<Icon icon="calendar" sizeInRem={1.5} />
						<TkaP2Span>{renderTime()}</TkaP2Span>
					</RowWrapper>
					<RowWrapper>
						<Icon icon="microsoftTeams" sizeInRem={1.5} />
						<LinkDisplay name="Teams Call" link={msTeamsLink ?? ""} />
					</RowWrapper>
					<RowWrapper>
						<Icon icon="participants" sizeInRem={1.5} />
						<UsersCountWrapper>
							<UserAvatarsCollection userFragmentsRef={users} />
							<TkaP3Span tkaColor={shade60}>{participantsLabel}</TkaP3Span>
						</UsersCountWrapper>
					</RowWrapper>
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
