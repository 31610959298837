export enum BranchTypeOptions {
	adviceAndConsulting = "adviceAndConsulting",
	analysisAndStatistic = "analysisAndStatistic",
	financeAccountingAndControlling = "financeAccountingAndControlling",
	graphicsAndDesign = "graphicsAndDesign",
	marketingAndAdvertising = "marketingAndAdvertising",
	healthMedicineAndSocialAffairs = "healthMedicineAndSocialAffairs",
	personnelAndHr = "personnelAndHr",
	processPlanningAndQualityAssurance = "processPlanningAndQualityAssurance",
	itAndSoftwareDevelopment = "itAndSoftwareDevelopment",
	legalServices = "legalServices",
	productManagement = "productManagement",
	otherFieldsOfActivity = "otherFieldsOfActivity",
}
